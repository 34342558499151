import { DashSpinner } from '@mirage/shared/dash-spinner';
import { ShowAfterMinimumDelay } from '@mirage/shared/dash-spinner/DashSpinnerAutofitContainer';
import i18n from '@mirage/translations';
import styles from './FullPageSpinner.module.css';
import { useReportingIfVisibleForTooLong } from './useReportingIfVisibleForTooLong';

export const FullPageSpinner: React.FC<{
  // `spinnerId` appears in both Sentry and datadog. Any human-readable id
  // that allows us to quickly understand the source of the spinner easily
  // is good. No need for any rigid naming conventions here.
  spinnerId: string;

  reportingIntervalMs?: number;
}> = ({ spinnerId, reportingIntervalMs }) => {
  useReportingIfVisibleForTooLong(spinnerId, reportingIntervalMs);

  return (
    <ShowAfterMinimumDelay>
      <div className={styles.fullPage}>
        <DashSpinner size={128} aria-valuetext={i18n.t('loading')} />
      </div>
    </ShowAfterMinimumDelay>
  );
};
