// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2516
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'expand.dash_left_sidebar'
// Description: Fires when a user clicks the expand icon when the left sidebar is collapsed to expand it.
// Owner: dash-web
export type Expand_DashLeftSidebar = PAPEventGeneric<
  'dash',
  'expand',
  'dash_left_sidebar',
  never
>;

export function PAP_Expand_DashLeftSidebar(
  properties?: Expand_DashLeftSidebar['properties'],
): Expand_DashLeftSidebar {
  return <Expand_DashLeftSidebar>{
    class: 'dash',
    action: 'expand',
    object: 'dash_left_sidebar',
    properties,
  };
}
