// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgGetGuru({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.303 20.292l-4.699 1.338c-3.516.996-7.126-1.12-8.09-4.792l-1.276-4.855c-.965-3.64 1.089-7.406 4.605-8.401l4.7-1.338c3.516-.996 7.125 1.151 8.09 4.792l1.276 4.854c.964 3.641-1.09 7.406-4.606 8.402z"
        fill="#03D6A1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.609 16.153a4.09 4.09 0 002.365-.155c.964-.374 1.867-1.183 2.116-2.116.373-.187.716-.436 1.058-.685.03 1.712-1.4 3.205-2.894 3.765a4.825 4.825 0 01-1.743.343c-.404 0-.809-.063-1.182-.156a5.428 5.428 0 01-2.21-1.276c-2.178-2.053-2.271-5.508-.186-7.686a4.925 4.925 0 013.578-1.556c2.396.031 3.859 1.836 3.921 1.93a.5.5 0 01-.062.715h-.031a.458.458 0 01-.654-.093s-1.245-1.525-3.174-1.556h-.03a4.094 4.094 0 00-2.833 1.245 4.199 4.199 0 00-1.213 3.08 4.435 4.435 0 003.174 4.201zm1.742-3.983c.031.374.467.467.84.467.53 0 1.058-.156 1.494-.405-.467-.31-.996-.497-1.556-.497-.436 0-.622.124-.685.186a.353.353 0 00-.093.25zm3.205-.498c.374-.28.747-.59 1.058-.933.218-.187.498-.218.654-.031a.527.527 0 01.03.715c-.373.405-.808.747-1.244 1.09a5.18 5.18 0 01-.871.56 3.997 3.997 0 01-2.054.56c-1.307-.031-1.774-.778-1.774-1.463 0-.342.125-.716.374-.964.31-.343.778-.498 1.4-.498.902 0 1.774.342 2.427.964z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgGetGuru;
