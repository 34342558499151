import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { _currentLocationStateAtom } from './atoms';

/**
 * Sets the currentLocationAtom to be the current pathname.
 * Should only be called once it the app, preferably high up in the tree.
 */
export const useSetCurrentLocation = () => {
  const { pathname } = useLocation();
  const setCurrentRoute = useSetAtom(_currentLocationStateAtom);

  useEffect(() => {
    setCurrentRoute(pathname);

    return () => {
      setCurrentRoute(null);
    };
  }, [pathname, setCurrentRoute]);
};
