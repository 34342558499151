import { LazyArtworkIcon } from '@dropbox/dash-component-library';
import { Button } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { UIIcon } from '@dropbox/dig-icons';
import { CopyLine, ShareArrowLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { $generateHtmlFromNodes } from '@lexical/html';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Click_ExportButton } from '@mirage/analytics/events/types/click_export_button';
import { parseMarkdownContent } from '@mirage/mosaics/ComposeAssistant/data/markdown-utils';
import { createDoc } from '@mirage/service-cloud-docs';
import { tagged } from '@mirage/service-logging';
import { openURL } from '@mirage/service-platform-actions';
import { toValidFileName } from '@mirage/service-stacks/service/utils';
import { hideSnackbar, showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import { $getRoot } from 'lexical';
import { memo, useCallback } from 'react';
import styles from './ComposeEditor.module.css';

const logger = tagged('ComposeEditor/ExportButton');

interface ExportButtonProps {
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
  disabled: boolean;
}
export const ExportButton = memo(
  ({ logComposeEvent, disabled }: ExportButtonProps) => {
    const [editor] = useLexicalComposerContext();
    const handleSelection = useCallback(
      (selection: 'text' | 'markdown' | 'paper') => {
        logComposeEvent(
          PAP_Click_ExportButton({
            actionType: selection,
          }),
        );
        editor.getEditorState().read(() => {
          switch (selection) {
            case 'text':
              {
                const htmlString = $generateHtmlFromNodes(editor, null);
                const textString = $getRoot().getTextContent();
                const data = [
                  new ClipboardItem({
                    'text/plain': new Blob([textString], {
                      type: 'text/plain',
                    }),
                    'text/html': new Blob([htmlString], { type: 'text/html' }),
                  }),
                ];
                navigator.clipboard.write(data);
                showSnackbar({
                  title: i18n.t('compose_editor_export_copied_completion'),
                });
              }
              break;
            case 'markdown':
              {
                const markdownString = $convertToMarkdownString(TRANSFORMERS);
                const data = [
                  new ClipboardItem({
                    'text/plain': new Blob([markdownString], {
                      type: 'text/plain',
                    }),
                  }),
                ];
                navigator.clipboard.write(data);
                showSnackbar({
                  title: i18n.t('compose_editor_export_copied_completion'),
                });
              }
              break;
            case 'paper':
              {
                showSnackbar({
                  title: i18n.t('compose_editor_export_progress_saving_paper'),
                  showProgressBar: true,
                  timeoutMs: 0, // disable auto dismissal
                  hideCloseButton: true,
                });

                const markdownString = $convertToMarkdownString(TRANSFORMERS);
                const { title, body } = parseMarkdownContent(markdownString);
                const fileName = toValidFileName(title || 'Untitled');
                createDoc('paper', fileName, {
                  import_format: {
                    '.tag': 'markdown',
                  },
                  contents: body, // paper doc auto-adds file name as title to the resulting doc
                })
                  .then((response) => {
                    openURL(response.url);
                    hideSnackbar();
                    return undefined;
                  })
                  .catch((err) => {
                    logger.error('failed to create paper doc', err);
                    showSnackbar({
                      title: i18n.t('compose_editor_export_error_saving_paper'),
                    });
                  });
              }
              break;
            default:
              selection satisfies never;
              logger.error('unsupported action', selection);
              break;
          }
        });
      },
      [editor, logComposeEvent],
    );
    return (
      <Menu.Wrapper onSelection={handleSelection}>
        {({ getContentProps, getTriggerProps }) => (
          <>
            <Button
              {...getTriggerProps()}
              variant="borderless"
              size="medium"
              withIconStart={<UIIcon src={ShareArrowLine} />}
              className={styles.editorExportButton}
              disabled={disabled}
            >
              {i18n.t('compose_editor_export_action')}
            </Button>
            <Menu.Content {...getContentProps()} placement="bottom-start">
              <Menu.Segment>
                <Menu.ActionItem
                  withTitle={i18n.t('compose_editor_export_action_copy_text')}
                  value="text"
                  withRightAccessory={<UIIcon src={CopyLine} />}
                />
                <Menu.ActionItem
                  withTitle={i18n.t(
                    'compose_editor_export_action_copy_markdown',
                  )}
                  value="markdown"
                  withRightAccessory={<UIIcon src={CopyLine} />}
                />
              </Menu.Segment>
              <Menu.Segment>
                <Menu.ActionItem
                  withTitle={i18n.t('compose_editor_export_action_save_paper')}
                  value="paper"
                  withRightAccessory={<LazyArtworkIcon icon="Paper" />}
                />
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
    );
  },
);
ExportButton.displayName = 'ExportButton';
