/**
 * Generic indexed DB store with string keys and string values only.
 * Provides auto-expiration to remove old values.
 *
 * Replaces and supersedes these third-party libraries:
 *   - idb-keyval (no support for non-string types here)
 *   - dexie (no support for indexes here)
 *   - localforage (no localStorage support here)
 *   - idbcache
 *   - idb
 *
 * Why use this file?
 *
 * 1. No dependencies on any typescript libraries (no imports). If you need a
 *    new feature, just update the code directly.
 *
 * 2. Large storage capacity. Local storage limit is about 5 MB.
 *
 * 3. Auto-expiration - no need to worry about evictions. Cleanup expired
 *    entries quickly and automatically.
 *
 * 4. Simple API - get/set any string value using one async call.
 *
 * 5. No need to worry about nulls and undefineds - treats empty strings and
 *    empty values the same.
 *
 * 6. No need to worry about supporting different data types - always use
 *    strings and let the user decide how to serialize/deserialize the data.
 *    Side note: In localStorage, the keys can be strings or integers, but the
 *    values are always strings.
 *
 * 7. This store can be used to replace cookies and localStorage entirely,
 *    thus simplifying your code greatly.
 */

import { LargeKvStore } from './interface';

/**
 * Coding style notes:
 *
 * 1. The DB is auto-initialized on first use.
 *
 * 2. Within each transaction, we will await each operation so that if any
 *    error occurs, we will be able to see the error getting thrown.
 *
 * 3. Auto-vacuuming should be a relatively fast operation unless you have a
 *    very large number of keys in the DB.
 */

// CAVEAT: The Electron main process does not have indexed db (bummer).

const DB_NAME = 'DropboxKeyValueStore';
const STORE_NAME = 'KeyValue';
const EXPIRATION_TIME_KEY_SUFFIX = '\0ExpireSec'; // seconds

// How long to wait after DB init before starting vacuum job.
const START_VACUUM_DELAY_MS = 10_000; // milliseconds

// Play safe by not allowing any entries to live on forever.
// Else we could unknowingly waste a lot of user disk space.
export const MAX_TTL_SECONDS = daysInSeconds(60); // max: 60 days
export const DEFAULT_TTL_SECONDS = daysInSeconds(20); // default: 20 days

/** Treats an empty string as an empty value. */
function asString(a: unknown): string {
  if (typeof a === 'string') {
    return a;
  }
  return a === undefined || a === null ? '' : a + '';
}

export function isExpirationTimeKey(key: string): boolean {
  return key.endsWith(EXPIRATION_TIME_KEY_SUFFIX);
}

export function getExpirationTimeKey(key: string): string {
  return key + EXPIRATION_TIME_KEY_SUFFIX;
}

export function fromExpirationTimeKey(key: string): string {
  return key.slice(0, key.length - EXPIRATION_TIME_KEY_SUFFIX.length);
}

function nowInSeconds(): number {
  return Math.floor(Date.now() / 1000);
}

export function hoursInSeconds(hours: number): number {
  return Math.floor(hours * 3600);
}

export function daysInSeconds(days: number): number {
  return Math.floor(days * 86400);
}

function asPromise<T>(request: IDBRequest<T>): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
}

function asTxnPromise(store: IDBObjectStore): Promise<void> {
  const txn = store.transaction;
  return new Promise((resolve, reject) => {
    txn.oncomplete = () => resolve();
    txn.onabort = txn.onerror = () => reject(txn.error);
  });
}

function isExpired(expiration: unknown): boolean {
  // If expiration time is not set, then the entry is already expired.
  return !expiration || Number(expiration) < nowInSeconds(); // past
}

function maskOutExpiredValue(value: unknown, expiration: unknown): string {
  const v = asString(value);
  return v && isExpired(expiration) ? '' : v;
}

async function getKeyPair(
  store: IDBObjectStore,
  key: string,
): Promise<[string, string]> {
  const [value, expiration] = await Promise.all([
    asPromise(store.get(key)),
    asPromise(store.get(getExpirationTimeKey(key))),
  ]);
  return [asString(value), asString(expiration)];
}

async function deleteKeyPair(
  store: IDBObjectStore,
  key: string,
): Promise<void> {
  await Promise.all([
    asPromise(store.delete(key)),
    asPromise(store.delete(getExpirationTimeKey(key))),
  ]);
}

function getInputTtlSeconds(ttlSeconds: number): number {
  return ttlSeconds > 0
    ? Math.min(ttlSeconds, MAX_TTL_SECONDS)
    : DEFAULT_TTL_SECONDS;
}

async function setOrDelete(
  store: IDBObjectStore,
  key: string,
  value: string,
  ttlSeconds: number,
): Promise<void> {
  if (value) {
    const expirationKey = getExpirationTimeKey(key);
    const expirationPromise = asPromise(
      store.put(nowInSeconds() + ttlSeconds, expirationKey),
    );
    await Promise.all([asPromise(store.put(value, key)), expirationPromise]);
  } else {
    await deleteKeyPair(store, key);
  }
}

let autoVacuum = true;

/** Expose for testing only. */
export function setAutoVacuum(b: boolean): void {
  autoVacuum = b;
}

let dbPromise: Promise<IDBDatabase> | null = null;

function getDb(): Promise<IDBDatabase> {
  if (!dbPromise) {
    const request = indexedDB.open(DB_NAME);
    request.onupgradeneeded = () =>
      request.result.createObjectStore(STORE_NAME);
    dbPromise = asPromise(request);

    if (autoVacuum) {
      // Wait long enough until users usually become inactive.
      setTimeout(async () => {
        await idbKvStore.vacuum();
      }, START_VACUUM_DELAY_MS);
    }
  }
  return dbPromise;
}

async function getStore(mode: IDBTransactionMode): Promise<IDBObjectStore> {
  const db = await getDb();
  const txn = db.transaction(STORE_NAME, mode);
  return txn.objectStore(STORE_NAME);
}

async function rawMap(store: IDBObjectStore): Promise<Map<string, string>> {
  const [keys, values] = await Promise.all([
    asPromise(store.getAllKeys()),
    asPromise(store.getAll()),
  ]);

  return new Map<string, string>(
    keys.map((key, index) => [asString(key), asString(values[index])]),
  );
}

/** Vacuuming requires getting all entries, so do both together. */
async function getEntriesWithVacuuming(): Promise<
  [[string, string][], Promise<void>]
> {
  const store = await getStore('readonly');
  const kvMap = await rawMap(store);

  const result: [string, string][] = [];
  const expiredKeys: IDBValidKey[] = [];

  for (const [k, v] of kvMap.entries()) {
    // Exclude expiration timestamp entries.
    if (isExpirationTimeKey(k)) {
      // If owning key does not exist, then this timestamp is orphaned.
      if (!kvMap.has(fromExpirationTimeKey(k))) {
        expiredKeys.push(k);
      }
      continue;
    }

    // Exclude expired stuff.
    const expirationKey = getExpirationTimeKey(k);
    const expiration = kvMap.get(expirationKey);

    if (isExpired(expiration)) {
      expiredKeys.push(k);
      expiredKeys.push(expirationKey);
      continue;
    }

    // Still active, so add to result.
    result.push([k, v]);
  }

  // Vacuum expired entries in the background if needed.
  // Most of the time, this would be a no-op.
  const vacuumPromise = expiredKeys.length
    ? getStore('readwrite').then(async (store) => {
        await Promise.all(
          expiredKeys.map((key) => asPromise(store.delete(key))),
        );
        await asTxnPromise(store);
      })
    : Promise.resolve();

  return [result, vacuumPromise];
}

/** Public interface for all operations. */
class IdbKvStore implements LargeKvStore {
  /** Get a value by its key. */
  public async get(key: string): Promise<string> {
    const store = await getStore('readonly');
    const [value, expiration] = await getKeyPair(store, key);
    return maskOutExpiredValue(value, expiration);
  }

  /** Check if a key exists. */
  public async has(key: string): Promise<boolean> {
    return Boolean(await this.get(key));
  }

  /** Get the key's expiration time in seconds, or 0 for no expiry or expired. */
  public async getExpirationSeconds(key: string): Promise<number> {
    const store = await getStore('readonly');
    const expiration = asString(
      await asPromise(store.get(getExpirationTimeKey(key))),
    );
    return expiration ? Number(expiration) : 0;
  }

  /** Set a value with a key with an optional TTL. */
  public async set(
    key: string,
    value: string,
    ttlSeconds: number,
  ): Promise<void> {
    const store = await getStore('readwrite');
    ttlSeconds = getInputTtlSeconds(ttlSeconds);
    await setOrDelete(store, key, value, ttlSeconds);
    await asTxnPromise(store);
  }

  /** Get multiple values by their keys. */
  public async multiGet(keys: string[]): Promise<string[]> {
    const store = await getStore('readonly');
    const promises = [];

    for (const key of keys) {
      promises.push(asPromise(store.get(key)));
      promises.push(asPromise(store.get(getExpirationTimeKey(key))));
    }

    const values = await Promise.all(promises);
    const newValues: string[] = [];

    for (let i = 0; i < values.length; i += 2) {
      const value = values[i];
      const expiration = values[i + 1];
      newValues.push(maskOutExpiredValue(value, expiration));
    }

    return newValues;
  }

  /** Set multiple values atomically, which is also faster. */
  public async multiSet(
    entries: [string, string][],
    ttlSeconds: number,
  ): Promise<void> {
    const store = await getStore('readwrite');
    ttlSeconds = getInputTtlSeconds(ttlSeconds);
    await Promise.all(
      entries.map(([k, v]) => setOrDelete(store, k, v, ttlSeconds)),
    );
    await asTxnPromise(store);
  }

  /** Update a value atomically using its old value. */
  public async update(
    key: string,
    updater: (oldValue: string) => string | Promise<string>,
    ttlSeconds: number,
  ): Promise<string> {
    const store = await getStore('readwrite');
    const [value, expiration] = await getKeyPair(store, key);
    const v = maskOutExpiredValue(value, expiration);
    const newValue = await updater(v);
    ttlSeconds = getInputTtlSeconds(ttlSeconds);
    await setOrDelete(store, key, newValue, ttlSeconds);
    await asTxnPromise(store);
    return newValue;
  }

  /** Delete a particular key from the store. */
  public async delete(key: string): Promise<void> {
    const store = await getStore('readwrite');
    await deleteKeyPair(store, key);
    await asTxnPromise(store);
  }

  /** Delete multiple keys at once. */
  public async multiDelete(keys: string[]): Promise<void> {
    const store = await getStore('readwrite');
    await Promise.all(keys.map((key) => deleteKeyPair(store, key)));
    await asTxnPromise(store);
  }

  /** Clear all values in the store. */
  public async clear(): Promise<void> {
    const store = await getStore('readwrite');
    await asPromise(store.clear());
    await asTxnPromise(store);
  }

  /** Get all entries inside the store with no filtering. */
  public async rawMap(): Promise<Map<string, string>> {
    const store = await getStore('readonly');
    return await rawMap(store);
  }

  /**
   * Get all [key, value] entries in the store.
   * Also vacuums expired entries automatically.
   */
  public async entries(): Promise<[string, string][]> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [entries, _vacuumPromise] = await getEntriesWithVacuuming();
    return entries;
  }

  /** Remove all expired entries. */
  public async vacuum(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_entries, vacuumPromise] = await getEntriesWithVacuuming();
    await vacuumPromise;
  }

  /** Get all keys in the store. */
  public async keys(): Promise<string[]> {
    const entries = await this.entries();
    return entries.map(([k, _]) => k);
  }

  /** Get all values in the store. */
  public async values(): Promise<string[]> {
    const entries = await this.entries();
    return entries.map(([_, v]) => v);
  }

  /** Get the number of unfiltered keys in the store. */
  public async rawSize(): Promise<number> {
    const store = await getStore('readonly');
    const allKeys = await asPromise(store.getAllKeys());
    return allKeys.length;
  }

  /** Get the number of unexpired keys in the store. */
  public async size(): Promise<number> {
    const entries = await this.entries();
    return entries.length;
  }
}

export const idbKvStore = new IdbKvStore();
