import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import styles from './Conversation.module.css';
import { Loader } from './Loader';
import { RailHeader, RailHeaderProps } from './RailHeader';

type ConversationProps = {
  children: React.ReactNode;
};

type SingleTurnMessageListProps = {
  children: React.ReactNode;
};

type MultiTurnMessageListProps = {
  children: React.ReactNode;
  className?: string;
  // Make it easy for users to scroll to the bottom anytime they want.
  scrollToBottomRef?: React.MutableRefObject<(() => void) | null | undefined>;
};

const Conversation: React.FC<ConversationProps> & {
  RailHeader: React.FC<RailHeaderProps>;
  SingleTurnMessageList: React.FC<SingleTurnMessageListProps>;
  MultiTurnMessageList: React.FC<MultiTurnMessageListProps>;
  Loader: React.FC<{ content?: string | JSX.Element }>;
} = ({ children }: ConversationProps) => {
  return <div className={styles.conversationContainer}>{children}</div>;
};

const BaseMessageList: React.FC = ({ children }) => (
  <div className={styles.baseMessageList}>{children}</div>
);

const MultiTurnMessageList: React.FC<MultiTurnMessageListProps> = ({
  children,
  className,
  scrollToBottomRef,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollToBottomRef) {
      scrollToBottomRef.current = () => {
        ref.current?.scrollTo(0, ref.current.scrollHeight);
      };
    }
  }, [scrollToBottomRef]);

  return (
    <div
      ref={ref}
      className={classnames(styles.multiTurnMessageListStyled, className)}
    >
      {children}
    </div>
  );
};

Conversation.RailHeader = RailHeader;
Conversation.SingleTurnMessageList = BaseMessageList;
Conversation.MultiTurnMessageList = MultiTurnMessageList;
Conversation.Loader = Loader;

export { Conversation };
