import { IconButton } from '@dropbox/dig-components/buttons';
import { Overlay } from '@dropbox/dig-components/overlay';
import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { InfoSmallLine } from '@dropbox/dig-icons/dist/mjs/assets';
import i18n from '@mirage/translations';
import { useRef, useState } from 'react';
import graphic from './BrowserExtensionIcon.gif';
import styles from './DisableNewTabTooltipIcon.module.css';

export const DisableNewTabTooltipIcon: React.FC = () => {
  const [open, setOpen] = useState(false);
  const closeTimer = useRef<ReturnType<typeof setTimeout>>();
  const triggerRef = useRef<HTMLDivElement | null>(null);

  const clearTimer = () => {
    if (closeTimer.current) {
      clearTimeout(closeTimer.current);
      closeTimer.current = undefined;
    }
  };

  const keepOpen = () => {
    clearTimer();
    setOpen(true);
  };

  const scheduleClose = () => {
    clearTimer();

    closeTimer.current = setTimeout(() => {
      closeTimer.current = undefined;
      setOpen(false);
    }, 1000);
  };

  return (
    <>
      <IconButton
        variant="transparent"
        className={styles.tooltipContent}
        ref={triggerRef}
        onMouseEnter={keepOpen}
        onMouseLeave={scheduleClose}
      >
        <UIIcon src={InfoSmallLine} />
      </IconButton>

      {open && (
        <Overlay
          anchorRef={triggerRef}
          placement="top"
          offsetDistance={8}
          onMouseEnter={keepOpen}
          onMouseLeave={scheduleClose}
        >
          <div className={styles.overlay}>
            <div className={styles.graphic}>
              <img
                src={graphic}
                width="362"
                height="169"
                alt={i18n.t('disable_dash_new_tab_tooltip_graphic_alt')}
              />
            </div>

            <div className={styles.text}>
              <Title size="small">
                {i18n.t('disable_dash_new_tab_tooltip_title')}
              </Title>
              <Text color="faint">
                {i18n.t('disable_dash_new_tab_tooltip_text')}
                <ol className={styles.ol}>
                  <li>{i18n.t('disable_dash_new_tab_tooltip_step_1')}</li>
                  <li>{i18n.t('disable_dash_new_tab_tooltip_step_2')}</li>
                  <li>{i18n.t('disable_dash_new_tab_tooltip_step_3')}</li>
                </ol>
              </Text>
            </div>
            <div className={styles.tooltipArrowContainer} />
          </div>
        </Overlay>
      )}
    </>
  );
};
