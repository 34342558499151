import * as React from "react";

import { Card } from "@dropbox/dig-components/card";
import { Title } from "@dropbox/dig-components/typography";
import { Box } from "@dropbox/dig-foundations";

import { Contents } from "./Contents";

import styles from "./Header.module.css";

/**
 * A classname to be applied to an element that should reveal actions in the header.
 *
 * Consider as a private API of the Card component that should only be used by the Card's internals.
 */
export const HEADER_ACTIONS_REVEALER_CLASSNAME = styles.headerActionRevealer;

interface HeaderActionsCreator {
  ({ revealableClassname }: { revealableClassname: string }): React.ReactNode;
}

export interface HeaderProps {
  title: React.ReactNode;
  /**
   * Actions that will be presented at the right of the card's header. Can be passed a function that
   * will receive a CSS class that can be used on actions that don't need to be visible by default.
   * Those actions will be revealed only when the card is hovered, is visibly focused, or has
   * children that are visibly focused.
   *
   * To reduce visual noise, prefer hiding actions not necessary for cursory interaction with the
   * card.
   */
  actions?: React.ReactNode | HeaderActionsCreator;
  /**
   * Whether the header has margin below it to separate it from the card's contents. Should
   * generally be true but can be set to false if the header is the only piece of content in the
   * card (or should appear to be).
   *
   * Really only intended for that use case. If you're going for specialized margin for the header,
   * please check in with the Design Foundations team — we can probably help.
   *
   * @default true
   */
  withMargin?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  actions,
  withMargin = true,
}) => {
  return (
    <Contents
      className={styles.header}
      marginBottom={withMargin ? "Micro Medium" : undefined}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Title
          size="small"
          weightVariant="emphasized"
          className={styles.digTitleXSmall}
          // Do we need some sort of mechanism to allow for heading tags? How do we make it easy to
          // use w/o letting our heading ordering become the wild west?
          tagName="div"
        >
          {title}
        </Title>

        <Card.Actions>
          {typeof actions === "function"
            ? actions({ revealableClassname: styles.revealableHeaderAction })
            : actions}
        </Card.Actions>
      </Box>
    </Contents>
  );
};
