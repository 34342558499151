import { Menu } from '@dropbox/dig-components/menu';
import { useIsActivityFeedOnlyMineFilterEnabled } from '@mirage/service-feed/hooks/features';
import {
  ActivityFeedApplication,
  ActivityFeedFilters,
} from '@mirage/service-feed/types';
import { useCallback } from 'react';
import {
  ApplicationFilterMenuSegment,
  SelectedApplicationsChip,
} from './ApplicationsFilter';
import { OnlyMineFilterMenuSegment } from './OnlyMineFilterMenuSegment';

export type OnlyMineMenuSelection = {
  segmentKey: 'onlyMine';
  isOnlyMine: boolean;
};

export type ApplicationsMenuSelection = {
  segmentKey: 'applications';
  application: ActivityFeedApplication;
  allApplicationOptions: ActivityFeedApplication[];
};

export type ActivityFeedFilterMenuSelection =
  | OnlyMineMenuSelection
  | ApplicationsMenuSelection;

type FiltersMenuProps = {
  filters: ActivityFeedFilters;
  setFilters: (filters: ActivityFeedFilters) => void;
};

export const FiltersMenu = ({ filters, setFilters }: FiltersMenuProps) => {
  const isOnlyMineFilterEnabled = useIsActivityFeedOnlyMineFilterEnabled();

  const setApplications = useCallback(
    (applications?: ActivityFeedApplication[]) => {
      setFilters({ ...filters, applications });
    },
    [filters, setFilters],
  );

  const handleApplicationsChange = useCallback(
    (
      application: ActivityFeedApplication,
      allApplicationOptions: ActivityFeedApplication[],
    ) => {
      const applications = Array.from(
        filters.applications || allApplicationOptions, // if the user has not ever selected any application filters, we default to all applications
      );
      const existingIdx = applications.indexOf(application);
      if (existingIdx !== -1) {
        applications.splice(existingIdx, 1);
      } else {
        applications.push(application);
      }
      // if they've selected all, we treat it as clearing the application filters (the default)
      if (applications.length == allApplicationOptions.length) {
        setApplications(undefined);
      } else {
        setApplications(applications);
      }
    },
    [filters.applications, setApplications],
  );

  const handleOnlyMineChange = useCallback(
    (isOnlyMine: boolean) => {
      setFilters({ ...filters, isOnlyMine });
    },
    [filters, setFilters],
  );

  // when using Menu.Wrapper, the onSelection callback has to happen at the top level instead of the individual menu segments
  const onSelection = useCallback(
    (selection: ActivityFeedFilterMenuSelection) => {
      switch (selection.segmentKey) {
        case 'onlyMine':
          handleOnlyMineChange(selection.isOnlyMine);
          break;
        case 'applications':
          handleApplicationsChange(
            selection.application,
            selection.allApplicationOptions,
          );
          break;
      }
    },
    [handleOnlyMineChange, handleApplicationsChange],
  );

  return (
    <Menu.Wrapper
      shouldPropagateClickOutsideMouseEvents
      closeOnSelection={false}
      onSelection={onSelection}
    >
      {({ getContentProps, getTriggerProps, triggerRef }) => (
        <>
          <SelectedApplicationsChip
            activeApplications={filters.applications}
            menuTriggerProps={getTriggerProps()}
            menuTriggerRef={triggerRef}
          />
          <Menu.Content
            {...getContentProps()}
            minWidth={240}
            placement="bottom-end"
          >
            {isOnlyMineFilterEnabled && (
              <OnlyMineFilterMenuSegment isOnlyMine={filters.isOnlyMine} />
            )}
            {
              <ApplicationFilterMenuSegment
                activeApplications={filters.applications}
                onSetActiveApplications={setApplications}
              />
            }
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
