import type { PreviousQuery } from '@mirage/shared/search/cache-result';

export function dedupePreviousQueries(
  results: PreviousQuery[],
): PreviousQuery[] {
  const seen = new Set();
  return results.filter(({ uuid, query }) => {
    if (seen.has(uuid) || seen.has(query)) return false;
    seen.add(uuid);
    seen.add(query);
    return true;
  });
}
