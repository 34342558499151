import { CONNECTOR_TYPES } from '@mirage/shared/search/search-filters';

import type { Connector } from '@mirage/service-dbx-api/service';
import type { ScoredResult } from '@mirage/service-search/service';

export const getConnectorsFromBrowserHistoryResults = (
  results: ScoredResult[],
): Connector[] => {
  return (
    results
      // Filter out non-browser history results
      .filter((result) => result.recordType['.tag'] === 'browser_tab')
      // Map into the connector format that filters expects
      .map((result) => {
        return {
          id_attrs: {
            id: result.connectorInfo.connectorId,
            type: result.connectorInfo.connectorName,
          },
          branding: {
            display_name: result.brandedSiteInfo?.name,
            icon_src: result.brandedSiteInfo?.icon?.lightUrl,
          },
          type: CONNECTOR_TYPES.BROWSER_HISTORY,
        };
      })
  );
};
