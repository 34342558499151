import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { useTheme } from '@dropbox/dig-foundations';
import i18n from '@mirage/translations';
import styles from './CalendarEmptyStateCard.module.css';

const DARK_BACKGROUND_IMAGE =
  'https://assets.dropbox.com/images/dashweb/emptystates/calendar_empty_state_dark.png';

const BACKGROUND_IMAGE =
  'https://assets.dropbox.com/images/dashweb/emptystates/calendar_empty_state.png';

type CalendarEmptyStateCardProps = {
  onClick: () => void;
  onDismiss: () => void;
};

export const CalendarEmptyStateCard = ({
  onClick,
  onDismiss,
}: CalendarEmptyStateCardProps) => {
  const { mode } = useTheme();
  const isDarkMode = mode === 'dark';
  const panelSrc = isDarkMode ? DARK_BACKGROUND_IMAGE : BACKGROUND_IMAGE;

  return (
    <div className={styles.container}>
      <div className={styles.assetContainer}>
        <img src={panelSrc} alt="" />
      </div>
      <div className={styles.contentContainer}>
        <Text size="medium" tagName="p" className={styles.message}>
          {i18n.t('calendar_empty_state_info_text')}
        </Text>
        <div className={styles.actions}>
          <Button variant="opacity" onClick={onClick}>
            {i18n.t('add_calendar')}
          </Button>
          <Button variant="borderless" onClick={onDismiss}>
            {i18n.t('dismiss')}
          </Button>
        </div>
      </div>
    </div>
  );
};
