import { Text } from '@dropbox/dig-components/typography';
import { DashSpinner } from '@mirage/shared/dash-spinner';
import i18n from '@mirage/translations';
import styles from './Loader.module.css';

export const Loader: React.FC<{ content?: string | JSX.Element }> = ({
  content = <DefaultContent />,
}) => {
  return (
    <div className={styles.loadingContainer}>
      {typeof content === 'string' ? (
        <Text size="small" color="faint">
          {content}
        </Text>
      ) : (
        content
      )}
    </div>
  );
};

const DefaultContent: React.FC = () => {
  return (
    <div className={styles.styledLoadingAnimationAndTextContainer}>
      <DashSpinner size={80} />
      <Text size="large" color="faint">
        {i18n.t('working_on_it')}
      </Text>
    </div>
  );
};
