/**
 * @GENERATED from Figma node 3638:52288
 */

import * as React from "react";
export type IconProps = Omit<React.SVGProps<SVGSVGElement>, "color">;
const ShortcutDark = (props: IconProps, ref: React.Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    focusable={false}
    role="presentation"
    ref={ref}
    {...props}
  >
    <path
      fill="#978F86"
      d="M9.773 9.742a79 79 0 0 0 0 4.516h4.452a79 79 0 0 0 0-4.516zM14.137 16.194H9.863C10.125 20.129 10.821 22 12 22s1.875-1.871 2.137-5.806M16.194 12c0 .788-.01 1.536-.03 2.258h5.693c.19-1.5.19-3.017 0-4.516h-5.692c.018.722.029 1.47.029 2.258M16.082 7.806h5.36c-.934-3.053-3.058-4.881-6.405-5.53a16.8 16.8 0 0 1 1.045 5.53M7.918 7.806c.035-1.889.388-3.759 1.046-5.53-3.348.645-5.472 2.477-6.405 5.53zM7.918 16.194h-5.36c.934 3.053 3.057 4.882 6.405 5.53a16.8 16.8 0 0 1-1.045-5.53M16.082 16.194a16.8 16.8 0 0 1-1.046 5.53c3.348-.645 5.472-2.477 6.405-5.53zM9.863 7.806h4.274C13.875 3.871 13.179 2 12 2s-1.875 1.871-2.137 5.806M2.143 9.742a17.9 17.9 0 0 0 0 4.516h5.692a87 87 0 0 1 0-4.516z"
    />
  </svg>
);
const ForwardRef = React.forwardRef(ShortcutDark);
export { ForwardRef as ShortcutDark };
