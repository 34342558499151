import { tagged } from '@mirage/service-logging';
import { typeahead } from '@mirage/service-typeahead-search/service/types';

const logger = tagged('typeahead-scoring/file-type');

const DEBUG = false;
DEBUG satisfies false;

export function fileTypeScore(
  result: typeahead.TaggedResult,
  _config: typeahead.Config,
): number | null {
  if (result.type === typeahead.ResultType.Recommendation) {
    const fileTypeInfo = result.result.fileTypeInfo;

    // Penalize certain file types for being noisy
    if (fileTypeInfo?.id === 'email') {
      if (DEBUG)
        logger.debug(
          `Penalizing email result: ${result.result.title} with File Type Score: 0.0`,
        );
      return 0.0;
    }
  }

  // returning null will ignore this score in the final calculation
  return null;
}
