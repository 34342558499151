import type { IconProps } from './IconProps';

export function MicrosoftSuite({ size = 36 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8893_20138)">
        <path
          d="M1.52197 2.52148H6.73936V7.73888H1.52197V2.52148Z"
          fill="#F35325"
        />
        <path
          d="M7.26074 2.52148H12.4781V7.73888H7.26074V2.52148Z"
          fill="#81BC06"
        />
        <path
          d="M1.52173 8.26074H6.73912V13.4781H1.52173V8.26074Z"
          fill="#05A6F0"
        />
        <path
          d="M7.26099 8.26074H12.4784V13.4781H7.26099V8.26074Z"
          fill="#FFBA08"
        />
      </g>
      <path
        d="M20.8125 3.72222C20.8125 3.18528 21.2478 2.75 21.7847 2.75H27.1319C27.6689 2.75 28.1042 3.18528 28.1042 3.72222V11.9861C28.1042 12.5231 27.6689 12.9583 27.1319 12.9583H21.7847C21.2478 12.9583 20.8125 12.5231 20.8125 11.9861V3.72222Z"
        fill="#1066B5"
      />
      <path
        d="M20.8125 3.72222C20.8125 3.18528 21.2478 2.75 21.7847 2.75H27.1319C27.6689 2.75 28.1042 3.18528 28.1042 3.72222V11.9861C28.1042 12.5231 27.6689 12.9583 27.1319 12.9583H21.7847C21.2478 12.9583 20.8125 12.5231 20.8125 11.9861V3.72222Z"
        fill="url(#paint0_linear_8893_20138)"
      />
      <rect
        x="24.4585"
        y="7.61133"
        width="3.64583"
        height="3.88889"
        fill="#32A9E7"
      />
      <rect
        x="20.8125"
        y="7.61133"
        width="3.64583"
        height="3.88889"
        fill="#167EB4"
      />
      <path
        d="M28.5077 7.36795C28.7179 7.53217 28.8042 7.8735 28.8042 7.8735L28.1042 8.26239V7.15527C28.1574 7.15527 28.2975 7.20372 28.5077 7.36795Z"
        fill="#135298"
      />
      <path
        d="M28.5903 12.6282C28.8334 12.2976 28.8334 11.9865 28.8334 11.9865V8.09762C28.8334 8.09762 28.8334 7.90319 28.8042 7.87402C28.7788 7.89098 28.7799 7.88449 28.7799 7.88449L20.3264 12.6185C20.3264 12.6185 20.3264 12.6671 20.5695 12.8323C20.8222 12.9587 21.0556 12.9587 21.0556 12.9587H27.8611C27.8611 12.9587 28.3181 12.9636 28.5903 12.6282Z"
        fill="url(#paint1_linear_8893_20138)"
      />
      <path
        d="M20.312 12.6184C20.0831 12.2979 20.0835 11.9864 20.0835 11.9864V7.85449L28.6002 12.6184C28.6002 12.6184 28.5072 12.7411 28.3474 12.8274C28.1043 12.9587 27.8613 12.9587 27.8613 12.9587H21.0557C21.0557 12.9587 20.5356 12.9246 20.312 12.6184Z"
        fill="url(#paint2_linear_8893_20138)"
      />
      <rect
        x="24.4585"
        y="3.72266"
        width="3.64583"
        height="3.88889"
        fill="#58D9FD"
      />
      <rect
        x="20.8125"
        y="3.72266"
        width="3.64583"
        height="3.88889"
        fill="#32A9E7"
      />
      <path
        d="M20.0835 6.76009C20.0835 5.95468 20.7364 5.30176 21.5418 5.30176H23.0002C23.8056 5.30176 24.4585 5.95468 24.4585 6.76009V10.4059C24.4585 11.2113 23.8056 11.8643 23.0002 11.8643H20.0835V6.76009Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M17.1667 5.66656C17.1667 5.12961 17.602 4.69434 18.139 4.69434H23.0001C23.537 4.69434 23.9723 5.12961 23.9723 5.66656V10.5277C23.9723 11.0646 23.537 11.4999 23.0001 11.4999H18.139C17.602 11.4999 17.1667 11.0646 17.1667 10.5277V5.66656Z"
        fill="url(#paint3_linear_8893_20138)"
      />
      <path
        d="M22.2708 7.87941V7.81882C22.2708 6.7685 21.5151 6.03125 20.4537 6.03125C19.3865 6.03125 18.625 6.77355 18.625 7.82892V7.88951C18.625 8.93984 19.3807 9.67708 20.4479 9.67708C21.5094 9.67708 22.2708 8.93479 22.2708 7.87941ZM21.4113 7.88951C21.4113 8.58636 21.019 9.00548 20.4537 9.00548C19.8884 9.00548 19.4903 8.57626 19.4903 7.87941V7.81882C19.4903 7.12197 19.8826 6.70285 20.4479 6.70285C21.0075 6.70285 21.4113 7.13207 21.4113 7.82892V7.88951Z"
        fill="white"
      />
      <path
        d="M3.72343 27.9372C2.74011 27.9372 1.86237 27.4947 1.28662 26.8019L7.81912 24.0605L12.8986 26.9204C12.4509 27.5045 11.7748 27.9372 11.0104 27.9338C9.85738 27.9372 5.42091 27.9372 3.72343 27.9372Z"
        fill="url(#paint4_linear_8893_20138)"
      />
      <path
        d="M10.8411 22.8506L7.81909 24.0611L12.8986 26.921C13.2239 26.4967 13.4165 25.9693 13.4165 25.3979C13.4165 24.0019 12.2662 22.8688 10.843 22.8581C10.8424 22.8556 10.8418 22.8531 10.8411 22.8506Z"
        fill="url(#paint5_linear_8893_20138)"
      />
      <path
        d="M3.41699 21.803L7.81951 24.0611L10.8415 22.8505C10.4113 21.1653 8.85476 19.917 7.00037 19.917C5.56241 19.917 4.30354 20.6676 3.61006 21.7905C3.54518 21.7928 3.48081 21.7969 3.41699 21.803Z"
        fill="url(#paint6_linear_8893_20138)"
      />
      <path
        d="M1.28647 26.8021L7.81898 24.0608L3.41646 21.8027C1.82625 21.9537 0.583252 23.2662 0.583252 24.8629C0.583252 25.5981 0.846812 26.2731 1.28647 26.8021Z"
        fill="url(#paint7_linear_8893_20138)"
      />
      <path
        d="M28.25 21.0833H17.75V19.3333C17.75 19.011 18.011 18.75 18.3333 18.75H27.6667C27.989 18.75 28.25 19.011 28.25 19.3333V21.0833Z"
        fill="url(#paint8_linear_8893_20138)"
      />
      <path
        d="M17.75 21.083V28.6663C17.75 28.9886 18.011 29.2497 18.3333 29.2497H27.6667C27.989 29.2497 28.25 28.9886 28.25 28.6663V21.083H17.75Z"
        fill="url(#paint9_linear_8893_20138)"
      />
      <path
        d="M22.125 23.7087H21.5417C21.3807 23.7087 21.25 23.578 21.25 23.417V22.8337C21.25 22.6727 21.3807 22.542 21.5417 22.542H22.125C22.286 22.542 22.4167 22.6727 22.4167 22.8337V23.417C22.4167 23.578 22.286 23.7087 22.125 23.7087Z"
        fill="white"
      />
      <path
        d="M24.4583 23.7087H23.8749C23.7139 23.7087 23.5833 23.578 23.5833 23.417V22.8337C23.5833 22.6727 23.7139 22.542 23.8749 22.542H24.4583C24.6193 22.542 24.7499 22.6727 24.7499 22.8337V23.417C24.7499 23.578 24.6193 23.7087 24.4583 23.7087Z"
        fill="white"
      />
      <path
        d="M26.7917 23.7087H26.2084C26.0474 23.7087 25.9167 23.578 25.9167 23.417V22.8337C25.9167 22.6727 26.0474 22.542 26.2084 22.542H26.7917C26.9527 22.542 27.0834 22.6727 27.0834 22.8337V23.417C27.0834 23.578 26.9527 23.7087 26.7917 23.7087Z"
        fill="white"
      />
      <path
        d="M22.125 25.7497H21.5417C21.3807 25.7497 21.25 25.619 21.25 25.458V24.8747C21.25 24.7137 21.3807 24.583 21.5417 24.583H22.125C22.286 24.583 22.4167 24.7137 22.4167 24.8747V25.458C22.4167 25.619 22.286 25.7497 22.125 25.7497Z"
        fill="white"
      />
      <path
        d="M19.7917 25.7497H19.2084C19.0474 25.7497 18.9167 25.619 18.9167 25.458V24.8747C18.9167 24.7137 19.0474 24.583 19.2084 24.583H19.7917C19.9527 24.583 20.0834 24.7137 20.0834 24.8747V25.458C20.0834 25.619 19.9527 25.7497 19.7917 25.7497Z"
        fill="white"
      />
      <path
        d="M24.4583 25.7497H23.8749C23.7139 25.7497 23.5833 25.619 23.5833 25.458V24.8747C23.5833 24.7137 23.7139 24.583 23.8749 24.583H24.4583C24.6193 24.583 24.7499 24.7137 24.7499 24.8747V25.458C24.7499 25.619 24.6193 25.7497 24.4583 25.7497Z"
        fill="white"
      />
      <path
        d="M22.125 27.7917H21.5417C21.3807 27.7917 21.25 27.661 21.25 27.5V26.9167C21.25 26.7557 21.3807 26.625 21.5417 26.625H22.125C22.286 26.625 22.4167 26.7557 22.4167 26.9167V27.5C22.4167 27.661 22.286 27.7917 22.125 27.7917Z"
        fill="white"
      />
      <path
        d="M19.7917 27.7917H19.2084C19.0474 27.7917 18.9167 27.661 18.9167 27.5V26.9167C18.9167 26.7557 19.0474 26.625 19.2084 26.625H19.7917C19.9527 26.625 20.0834 26.7557 20.0834 26.9167V27.5C20.0834 27.661 19.9527 27.7917 19.7917 27.7917Z"
        fill="white"
      />
      <path
        d="M24.4583 27.7917H23.8749C23.7139 27.7917 23.5833 27.661 23.5833 27.5V26.9167C23.5833 26.7557 23.7139 26.625 23.8749 26.625H24.4583C24.6193 26.625 24.7499 26.7557 24.7499 26.9167V27.5C24.7499 27.661 24.6193 27.7917 24.4583 27.7917Z"
        fill="white"
      />
      <path
        d="M26.7917 25.7497H26.2084C26.0474 25.7497 25.9167 25.619 25.9167 25.458V24.8747C25.9167 24.7137 26.0474 24.583 26.2084 24.583H26.7917C26.9527 24.583 27.0834 24.7137 27.0834 24.8747V25.458C27.0834 25.619 26.9527 25.7497 26.7917 25.7497Z"
        fill="white"
      />
      <path
        opacity="0.05"
        d="M20.0833 18.75V19.7708C20.0833 19.8513 20.0176 19.9167 19.9374 19.9167C19.8572 19.9167 19.7916 19.8513 19.7916 19.7708V18.75H19.2083V19.7708C19.2083 20.1727 19.5352 20.5 19.9374 20.5C20.3396 20.5 20.6666 20.1727 20.6666 19.7708V18.75H20.0833Z"
        fill="black"
      />
      <path
        opacity="0.07"
        d="M20.2292 18.75V19.7708C20.2292 19.9315 20.0986 20.0625 19.9376 20.0625C19.7766 20.0625 19.6459 19.9315 19.6459 19.7708V18.75H19.3542V19.7708C19.3542 20.0925 19.6159 20.3542 19.9376 20.3542C20.2593 20.3542 20.5209 20.0925 20.5209 19.7708V18.75H20.2292Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M26.2083 18.75V19.7708C26.2083 19.8513 26.1426 19.9167 26.0624 19.9167C25.9822 19.9167 25.9166 19.8513 25.9166 19.7708V18.75H25.3333V19.7708C25.3333 20.1727 25.6602 20.5 26.0624 20.5C26.4646 20.5 26.7916 20.1727 26.7916 19.7708V18.75H26.2083Z"
        fill="black"
      />
      <path
        opacity="0.07"
        d="M26.3542 18.75V19.7708C26.3542 19.9315 26.2236 20.0625 26.0626 20.0625C25.9016 20.0625 25.7709 19.9315 25.7709 19.7708V18.75H25.4792V19.7708C25.4792 20.0925 25.7409 20.3542 26.0626 20.3542C26.3843 20.3542 26.6459 20.0925 26.6459 19.7708V18.75H26.3542Z"
        fill="black"
      />
      <path
        d="M26.0625 20.2083C25.821 20.2083 25.625 20.0123 25.625 19.7708V18.3125C25.625 18.071 25.821 17.875 26.0625 17.875C26.304 17.875 26.5 18.071 26.5 18.3125V19.7708C26.5 20.0123 26.304 20.2083 26.0625 20.2083Z"
        fill="#54DAFF"
      />
      <path
        d="M19.9375 20.2083C19.696 20.2083 19.5 20.0123 19.5 19.7708V18.3125C19.5 18.071 19.696 17.875 19.9375 17.875C20.179 17.875 20.375 18.071 20.375 18.3125V19.7708C20.375 20.0123 20.179 20.2083 19.9375 20.2083Z"
        fill="#54DAFF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8893_20138"
          x1="20.8125"
          y1="7.85417"
          x2="28.1042"
          y2="7.85417"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#064484" />
          <stop offset="1" stopColor="#0F65B5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8893_20138"
          x1="28.8334"
          y1="10.4066"
          x2="20.0834"
          y2="10.4066"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#44DCFD" />
          <stop offset="0.453125" stopColor="#259ED0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8893_20138"
          x1="20.0835"
          y1="10.4066"
          x2="28.8335"
          y2="10.4066"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#259ED0" />
          <stop offset="1" stopColor="#44DCFD" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8893_20138"
          x1="17.1667"
          y1="8.09711"
          x2="23.9723"
          y2="8.09711"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#064484" />
          <stop offset="1" stopColor="#0F65B5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8893_20138"
          x1="2.35819"
          y1="27.4025"
          x2="11.5039"
          y2="26.8449"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2086B8" />
          <stop offset="1" stopColor="#46D3F6" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8893_20138"
          x1="10.14"
          y1="25.3979"
          x2="12.6988"
          y2="23.6099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1694DB" />
          <stop offset="1" stopColor="#62C3FE" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_8893_20138"
          x1="3.99686"
          y1="20.4517"
          x2="9.9415"
          y2="23.9012"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D3D78" />
          <stop offset="1" stopColor="#063B83" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_8893_20138"
          x1="0.446727"
          y1="25.5313"
          x2="6.42373"
          y2="23.3116"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#16589B" />
          <stop offset="1" stopColor="#1464B7" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_8893_20138"
          x1="22.7142"
          y1="18.2722"
          x2="23.31"
          y2="22.2628"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.042" stopColor="#076DB4" />
          <stop offset="0.85" stopColor="#0461AF" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_8893_20138"
          x1="22.3102"
          y1="18.8713"
          x2="24.0404"
          y2="33.9726"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33BEF0" />
          <stop offset="1" stopColor="#0A85D9" />
        </linearGradient>
        <clipPath id="clip0_8893_20138">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(1 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
