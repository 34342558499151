import pqueue from 'p-queue';

import type { KVStorage, RawStorage } from '@mirage/storage';

type Transformer<T> = (previous: T | undefined) => T | undefined;

export default class CachedStorage<Shape> implements KVStorage<Shape> {
  private cache: Partial<Shape> = {};
  private adapter: RawStorage<Partial<Shape>>;

  // since we are making no assumptions on a synchronous implementation of the
  // underlying storage, we need to sequence all operations
  private queue: pqueue = new pqueue({ concurrency: 1 });

  constructor(adapter: RawStorage<Partial<Shape>>) {
    this.adapter = adapter;

    // when we are instantiated, read the current written value using the
    // serialization adapter to make sure that we populate our in-memory cache
    // if we get back a null-like value, populate the cache with a default
    // empty state
    this.sequence(async () => {
      this.cache = (await adapter.read()) || {};
    });
  }

  get<K extends keyof Shape>(key: K): Promise<Shape[K] | undefined> {
    return this.sequence(() => this.cache[key]);
  }

  async set<K extends keyof Shape>(key: K, value: Shape[K]): Promise<void> {
    await this.sequence(() => this.unsafe_set(key, value));
  }

  async delete<K extends keyof Shape>(key: K): Promise<void> {
    await this.sequence(() => {
      delete this.cache[key];
      return this.adapter.write(this.cache);
    });
  }

  private unsafe_set<K extends keyof Shape>(
    key: K,
    value: Shape[K],
  ): Promise<void> {
    this.cache[key] = value;
    return this.adapter.write(this.cache);
  }

  getAll(): Promise<Partial<Shape> | undefined> {
    return this.sequence(() => this.cache);
  }

  clear(): Promise<void> {
    return this.sequence(async () => {
      await this.adapter.clear();
      this.cache = {};
    });
  }

  protected sequence<R>(callback: () => R): Promise<Awaited<R>> {
    return this.queue.add(callback) as Promise<Awaited<R>>;
  }

  protected atomic_transform_key<K extends keyof Shape>(
    k: K,
    transformer: Transformer<Shape[K]>,
  ): Promise<void> {
    return this.sequence(() => {
      return this.unsafe_set(k, transformer(this.cache[k]) as Shape[K]);
    });
  }
}
