import { dash_feed } from '@dropbox/api-v2-client/types';
import { useRef } from 'react';
import { useInView } from 'react-intersection-observer';

export type OnInViewHandler = (
  activity: dash_feed.ActivityItem,
  position: number,
) => void;
export type OnInViewCallbacks = {
  onScrollIntoView?: OnInViewHandler;
  onInView?: OnInViewHandler;
};

// This hook is used to trigger a callback when an element is in view
// onInView is called when the element is in view -- it also fires when the element is initially rendered in view
// onScrollIntoView is called when the element is in view -- it does not fire when the element is initially rendered in view (requires scrolling into view)
export const useOnInViewTrigger = (
  { onInView, onScrollIntoView }: OnInViewCallbacks,
  { activity, index }: { activity: dash_feed.ActivityItem; index: number },
) => {
  // Need to track when view state is initially processed
  // because it will fire the `onChange` event for every element
  // on render and we only need to know when initially
  // out-of-view elements enter view port
  const hasProcessedInitialViewStateRef = useRef(false);

  const handleOnChange = (inView: boolean) => {
    if (inView) {
      onInView?.(activity, index);
      if (hasProcessedInitialViewStateRef.current) {
        onScrollIntoView?.(activity, index);
      }
    } else {
      hasProcessedInitialViewStateRef.current = true;
    }
  };

  const { ref } = useInView({
    triggerOnce: true,
    onChange: handleOnChange,
  });

  return ref;
};
