import * as React from "react";
import {
  Avatar,
  type AvatarWithGuestProps,
} from "@dropbox/dig-components/avatar";
import { atoms } from "@dropbox/dig-foundations";
import classNames from "classnames";
import css from "./Avatar.module.css";

import { MetadataContext } from "./context";

export type AvatarProps = AvatarWithGuestProps & {
  size?: never;
};
const atomStyles = atoms({ flexBasis: "auto", flexGrow: 0, flexShrink: 0 });
/**
 * This is a wrapper around the DIG `Avatar` component that handles the size prop internally.
 */
export const _Avatar: React.FC<AvatarProps> = ({ className, ...props }) => {
  const { size } = React.useContext(MetadataContext);
  const cls = classNames(atomStyles, className, css.avatarRoot, {
    [css.sizeMedium]: size === "medium",
  });
  return (
    <Avatar
      {...props}
      className={cls}
      size={size === "small" ? "xsmall" : "small"}
    />
  );
};
