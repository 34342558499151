import { tagged } from '@mirage/service-logging';
import { namespace } from '@mirage/service-operational-metrics';
import * as scoring from '@mirage/service-typeahead-search/service/scoring';
import {
  relevant,
  TYPEAHEAD_SEARCH_SOURCES,
} from '@mirage/service-typeahead-search/service/sources';
import {
  CacheKey,
  TypeaheadCache,
} from '@mirage/service-typeahead-search/service/typeahead-cache';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import instrument from '@mirage/service-typeahead-search/service/utils/instrument';
import { managedShortcuts } from '@mirage/service-typeahead-search/service/utils/managed-shortcuts';
import { PreviousQuery } from '@mirage/shared/search/cache-result';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';

import type { ExtractTypePredicate } from '@mirage/shared/util/ts-util-types';
import type { Observable } from 'rxjs';
import ResultType = typeahead.ResultType;

const logger = tagged('service-typeahead-search/search');
const metrics = namespace('typeahead-search');
const DEBUG = false;
DEBUG satisfies false;

/**
 * Returns an observable of arrays of typeahead search results
 * It will handle collecting, deduplication, scoring, grouping, limiting, and
 * sorting of the results in each emitted array.
 *
 * There's a diagram of the data flow in confluence:
 * https://dropbox.atlassian.net/wiki/spaces/Dash/pages/1569784549/service-typeahead-search+-+search+function+diagram
 */
export default function search(
  untrimmedQuery: string,
  cache: TypeaheadCache,
  auxiliarySources: typeahead.Source[], // sources from consuming context
  config: typeahead.Config,
  limit: number = 5,
): Observable<typeahead.ScoredResult[]> {
  // sanitize query
  const query = untrimmedQuery.trim();

  // result grouping parameters
  const RECENT_QUERIES_LIMIT = query.length > 0 ? 1 : limit;
  const LIMIT = query?.startsWith('/') ? managedShortcuts.length : limit;

  // TODO: We should just make this a hard limit like it is in SERP. Too
  // dangerous to have it just warn. No source should be producting more than
  // 100 candiadtes, there's no reason for that.
  //
  // warn if there are too many items returned from the sources
  const WARN_TOO_MANY_RESULTS_THRESHOLD = 100;
  // set recommendation limit to 200 to match our candidate limit from that source
  const WARN_TOO_MANY_RECOMENDATIONS_THRESHOLD = 200;

  // time-based cutoffs aren't likely to be immediately relevant, but this gives us
  // future optionality for async sources that take time to emit
  const [cutoff, end] = intervals([50, 1_000]);

  // analyze cache (quicky!)
  const t0 = performance.now();
  const cacheAnalysis = getCacheAnalysis(cache);
  const t1 = performance.now();
  const cacheAnalysisMs = t1 - t0;
  if (cacheAnalysisMs > 200) {
    const rounded = Math.round(cacheAnalysisMs);
    logger.warn(
      `typeahead cache analysis time is unexpectedly high: ${rounded}ms`,
    );
  }
  metrics.stats('search/cacheAnalysis', t1 - t0, {
    name: 'cacheAnalysis',
  });

  // find relevant sources
  const callable = relevant(
    untrimmedQuery,
    [...TYPEAHEAD_SEARCH_SOURCES, ...auxiliarySources],
    config,
    cacheAnalysis,
  );

  function runner(
    source: typeahead.Source,
  ): Observable<typeahead.TaggedResult> {
    const perSourceUUIDCountSet = new Set<string>();
    const WARN_THRESHOLD =
      source.id === typeahead.SourceId.Recommendations
        ? WARN_TOO_MANY_RECOMENDATIONS_THRESHOLD
        : WARN_TOO_MANY_RESULTS_THRESHOLD;
    let warned = false;

    return (
      source
        .search(query, config, cache)
        .pipe(op.subscribeOn(rx.asyncScheduler))
        // swallow source-level failures
        .pipe(
          op.catchError((e) => {
            logger.error(`search source error`, e);
            return rx.EMPTY;
          }),
        )
        // warn if there are too many results returned, but don't do anything
        // to stop it as nondeterministically missing data is worse than a
        // slow response
        .pipe(
          op.tap((item) => {
            perSourceUUIDCountSet.add(item?.uuid);

            if (!warned && perSourceUUIDCountSet.size > WARN_THRESHOLD) {
              warned = true;
              logger.warn(
                `More than ${WARN_THRESHOLD} items emitted from the source ${source.id}!`,
              );
            }
          }),
        )
      // uncomment this line to add some delay for easier development
      // .pipe(rx.delay(500))
    );
  }

  const sources$ = rx.from(callable).pipe(op.mergeMap(runner));

  // instrument latency of source aggregation
  const isources$ = instrument(sources$, (stats) => {
    if (DEBUG) logger.debug(`sources`, stats);

    metrics.stats('search/sources', stats.duration, {
      name: 'sources',
    });
  });

  // filter out dropboxer-only url-shortcuts for non-dropboxers
  const filtered$ = isources$.pipe(
    op.filter(
      (result) =>
        result.type !== ResultType.URLShortcut ||
        !result.result.dropboxersOnly ||
        (result.result.dropboxersOnly && config.isDropboxer),
    ),
  );

  // apply scores score to candidates (titleMatchScore, lastClickedScore, etc.)
  let scored$ = filtered$.pipe(op.map(scoring.score(query, config)));

  if (DEBUG) {
    const DEBUG_LINES = 3;
    let i = 0;
    scored$ = scored$.pipe(
      op.tap((scoredResult: typeahead.ScoredResult) => {
        if (i < DEBUG_LINES) {
          logger.debug(`scoredResult ${i + 1}:`, scoredResult);
          i++;
        }
      }),
    );
  }

  // instrument latency of scoring
  const iscored$ = instrument(scored$, (stats) => {
    if (DEBUG) logger.debug(`scored`, stats);
    scored$
      .pipe(op.take(1))
      .pipe(
        op.tap((scoredResult) =>
          logger.debug('search/scoring', scoredResult.type),
        ),
      );

    metrics.stats('search/scoring', stats.duration, {
      name: 'scoring',
    });
  });

  // dedupe based on UUID across all sources
  // This converts from Observable<typeahead.ScoredResult> to Observable<typeahead.ScoredResult[]>!
  const deduped$ = iscored$.pipe(
    rx.distinct(({ uuid }) => uuid),
    op.scan((acc, value) => [...acc, value], [] as typeahead.ScoredResult[]),
    op.map(dedupeResultsBasedOnURL),
  );

  // instrument latency of dedupe
  const ideduped$ = instrument(deduped$, (stats) => {
    if (DEBUG) logger.debug(`dedupe`, stats);

    metrics.stats('search/dedupe', stats.duration, {
      name: 'dedupe',
    });
  });

  /**
   * sort results by score
   */
  const sorted$ = ideduped$.pipe(
    op.map((results) => [...results].sort(sortScoredResults)),
  );

  // instrument latency of sorting
  const isorted$ = instrument(sorted$, (stats) => {
    if (DEBUG) logger.debug(`sorted`, stats);

    metrics.stats('search/sorting', stats.duration, {
      name: 'sorting',
    });
  });

  // group results by type into tiers
  const grouped$ = isorted$
    .pipe(
      op.map((sortedResults) => {
        // suggested-search
        const suggested = sortedResults.filter(isSuggestedQuery)?.[0] as
          | ExtractTypePredicate<typeof isSuggestedQuery>
          // this can be undefined in practice, so this just forces the type to match reality
          | undefined;

        // shortcuts
        const shortcuts = sortedResults.filter(isURLShortcut);

        // recent queries
        const queries = sortedResults
          .filter(isRecentQuery)
          .slice(0, RECENT_QUERIES_LIMIT);

        // all other sources
        const rest = sortedResults.filter(
          (result) =>
            !isSuggestedQuery(result) &&
            !isURLShortcut(result) &&
            !isRecentQuery(result) &&
            !isEventResult(result, config.isFilterOutEvents),
        );

        // combine all but the suggested-search
        const combinedAndLimited = [...shortcuts, ...queries, ...rest]
          .slice(0, LIMIT)
          // sort by score again to ensure they are all in the correct order post merge
          .sort(sortScoredResults);

        // then clamp the suggested query to the first or second position depending on it's score
        // WARNING, THE RESULTS CANNOT BE SORTED AGAIN AFTER THIS POINT!
        return clampSuggestedQueryMaxPosition(suggested, combinedAndLimited);
      }),
    )
    // if the search produces no results, send that to the UI
    .pipe(op.defaultIfEmpty([]));

  // instrument latency of grouping/tiering logic
  const igrouped$ = instrument(grouped$, (stats) => {
    if (DEBUG) logger.debug(`grouped`, stats);

    metrics.stats('search/grouping', stats.duration, {
      name: 'grouping',
    });
  });

  // define intervals for emitting results
  // TODO: consider using P95-99 latency of slowest emitting search source
  // for defining these values to prevent "jumpy" typeahead results
  const segmentation$ = igrouped$
    // perform result segmentation to prevent killing the UI with too many
    // intermediate result states and re-renders
    .pipe(
      op.connect((shared$) => {
        return rx.merge(
          shared$.pipe(op.takeUntil(cutoff)).pipe(op.auditTime(50)),
          shared$.pipe(op.skipUntil(cutoff)).pipe(op.auditTime(100)),
        );
      }),
    )
    .pipe(op.takeUntil(end));

  // instrument latency of segmentation logic
  const isegmentation$ = instrument(segmentation$, (stats) => {
    if (DEBUG) logger.debug(`segmented`, stats);

    metrics.stats('search/segmentation', stats.duration, {
      name: 'segmentation',
    });
  });

  // instrument aggregate search stream metrics
  return instrument(isegmentation$, (stats) => {
    if (DEBUG) logger.debug(`typeahead search`, stats);

    // it's possible we did not emit any results, do not pollute our ttfr values
    if (stats.first !== -1) metrics.stats('search/ttfr', stats.first);
    metrics.stats('search/duration', stats.duration);
    metrics.counter('search/status', 1, {
      status: stats.failed ? 'error' : 'success',
    });
  });
}

// In the predicates some sources wish to be included/excluded based on the
// state of the local cache. For example: with an empty query, if the user
// has recent queries we want to the null state to show recent queries, but if
// the user doesn't have recent queries we want the null state to show
// "recommendations".
//
// WARN:
// We should keep the analysis to a minimum! If we need more, we probably need
// a better pattern for a source to be included based on the state of another
// source
export function getCacheAnalysis(
  cache: TypeaheadCache,
): typeahead.CacheAnalysis {
  // <1ms right now. We should implement cache.some() if perf becomes a concern
  const recentQueries = cache.all(CacheKey.RecentQueries) as PreviousQuery[];
  const hasRecentQueries = recentQueries.length > 0;
  return { hasRecentQueries };
}

function isSuggestedQuery(result: typeahead.ScoredResult) {
  return result?.type === ResultType.SuggestedQuery;
}

function isRecentQuery(result: typeahead.ScoredResult) {
  return result?.type === ResultType.PreviousQuery;
}

function isURLShortcut(result: typeahead.ScoredResult) {
  return result?.type === ResultType.URLShortcut;
}

function isEventResult(
  result: typeahead.ScoredResult,
  filter: boolean,
): result is typeahead.ScoredSearchResult | typeahead.ScoredRecommendation {
  if (!filter) return false;
  return (
    (result?.type === ResultType.SearchResult ||
      result?.type === ResultType.Recommendation) &&
    result?.result?.recordType?.['.tag'] === 'event'
  );
}

function sortScoredResults(
  left: typeahead.ScoredResult,
  right: typeahead.ScoredResult,
): number {
  return right.score - left.score;
}

function intervals(periods: Array<number>): Array<Observable<void>> {
  return periods.map((period) =>
    rx
      .interval(period)
      .pipe(op.take(1))
      .pipe(op.map(() => undefined)),
  );
}

// These results have a URL parameter we want to dedupe on
const isURLResult = (result: typeahead.ScoredResult) => {
  return (
    result.type === ResultType.SearchResult ||
    result.type === ResultType.Recommendation ||
    result.type === ResultType.StackItem
  );
};

// Dedupe results based on URL with preference to higher scored results
const dedupeResultsBasedOnURL = (
  results: typeahead.ScoredResult[],
): typeahead.ScoredResult[] => {
  const seenResults: { [key: string]: typeahead.ScoredResult } = {};

  results.forEach((result) => {
    if (isURLResult(result) && result.result.url) {
      try {
        const parsedUrl = new URL(result.result.url);
        const cleanUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}${parsedUrl.pathname}`;
        if (
          !seenResults[cleanUrl] ||
          seenResults[cleanUrl].score < result.score
        ) {
          seenResults[cleanUrl] = result;
        }
      } catch (e) {
        logger.warn('Invalid url during dedupe');
      }
    } else {
      if (
        !seenResults[result.uuid] ||
        seenResults[result.uuid].score < result.score
      ) {
        seenResults[result.uuid] = result;
      }
    }
  });

  return Object.values(seenResults).sort(sortScoredResults);
};
// exported for unit testing only
export const _dedupeResultsBasedOnURL = dedupeResultsBasedOnURL;

/**
 * Clamps the SuggestedQuery (the SERP CTA) to either the first or the second
 * position depending on it's score.
 *
 * In the unlikely case of a tie, the suggested query will be first
 */
function clampSuggestedQueryMaxPosition(
  suggestedQuery: typeahead.ScoredSuggestedQuery | undefined,
  otherResults: typeahead.ScoredResult[],
) {
  if (suggestedQuery == null) {
    // if the suggestedQuery doesn't exist for some reason, just return the otherResults
    return otherResults;
  } else if (suggestedQuery.score <= 0.1) {
    // if the suggestedQuery score is at/below 0.1, it means we should keep the
    // suggestedQuery at the end of the list.
    // this isn't ideal, but since the suggestedQuery score is set by a growthbook flag
    // doing this avoids needing multiple feature flags for this one feature
    // while still allowing experimentation with a control group
    return [...otherResults, suggestedQuery];
  } else if (suggestedQuery.score >= (otherResults[0]?.score ?? 0)) {
    // if the suggestedQuery is the highest scoring result (or it's a tie), then suggested query is first
    return [suggestedQuery, ...otherResults];
  } else {
    // otherwise it's second
    return [
      ...otherResults.slice(0, 1),
      suggestedQuery,
      ...otherResults.slice(1),
    ];
  }
}
