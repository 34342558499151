import { stacks } from '@dropbox/api-v2-client';
import { LinkComponent, LinkComponentProps } from '@mirage/link-list/Link/Link';
import { DroppableItemizedSection } from './DroppableItemizedSection';

interface DragDroppableSectionLinkComponentProps {
  sectionId: string;
  index: number;
  item: stacks.StackItemShortcut;
}

export const DragDroppableSectionLinkComponent: React.FC<
  LinkComponentProps & DragDroppableSectionLinkComponentProps
> = (props) => {
  return (
    <DroppableItemizedSection
      sectionId={props.sectionId}
      link={props.link}
      index={props.index}
    >
      <LinkComponent {...props} enableDrag />
    </DroppableItemizedSection>
  );
};
