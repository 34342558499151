// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1671
import type { PAPEventGeneric } from '../base/event';
import type { ActionEntryEntryPoint } from '../enums/action_entry_entry_point';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceEntryPoint } from '../enums/dash_surface_entry_point';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'shown.dash_connect_app_page'
// Description: When the cloud connector page loads.
// Owner: connector-foundations
export type Shown_DashConnectAppPage = PAPEventGeneric<
  'dash',
  'shown',
  'dash_connect_app_page',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // what dash product the cloud connector is launched from [dash_desktop, dash_webapp, etc]
    dashSurfaceEntryPoint?: DashSurfaceEntryPoint;
    // Where a cloud connector app page is launched from within the dash product
    actionEntryEntryPoint?: ActionEntryEntryPoint;
    // whether the user is currently in an onboarding flow when they perform the action being logged.
    inOnboardingFlow?: boolean;
  }
>;

export function PAP_Shown_DashConnectAppPage(
  properties?: Shown_DashConnectAppPage['properties'],
): Shown_DashConnectAppPage {
  return <Shown_DashConnectAppPage>{
    class: 'dash',
    action: 'shown',
    object: 'dash_connect_app_page',
    properties,
  };
}
