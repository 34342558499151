// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2809
import type { PAPEventGeneric } from '../base/event';
import type { FeatureLine } from '../enums/feature_line';

// Event_name: 'initiate.delete_dash_stack'
// Description: Fires when a user starts the process of deleting a dash stack.
// Owner: dash-web
export type Initiate_DeleteDashStack = PAPEventGeneric<
  'stacks',
  'initiate',
  'delete_dash_stack',
  {
    // Feature Line for dash
    featureLine?: FeatureLine;
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
  }
>;

export function PAP_Initiate_DeleteDashStack(
  properties?: Initiate_DeleteDashStack['properties'],
): Initiate_DeleteDashStack {
  return <Initiate_DeleteDashStack>{
    class: 'stacks',
    action: 'initiate',
    object: 'delete_dash_stack',
    properties,
  };
}
