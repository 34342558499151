// @generated by bzl gen
// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/ml_capabilities/common.proto (package ml_capabilities, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { BytesValue, Message, proto3 } from '@bufbuild/protobuf';

/**
 * @generated from enum ml_capabilities.KeyProvider
 */
export enum KeyProvider {
  /**
   * OpenAI API Key
   * https://platform.openai.com/docs/api-reference/authentication
   *
   * @generated from enum value: OPENAI = 0;
   */
  OPENAI = 0,

  /**
   * Dropbox OS Key provided by AMP
   * https://docs.dbxos.com/documentation/openai
   *
   * @generated from enum value: AMP = 1;
   */
  AMP = 1,

  /**
   * Specify the secret name associated with the OpenAI key in the ApiKey.key param
   *
   * @generated from enum value: SECRET_SAUCE = 2;
   */
  SECRET_SAUCE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(KeyProvider)
proto3.util.setEnumType(KeyProvider, 'ml_capabilities.KeyProvider', [
  { no: 0, name: 'OPENAI' },
  { no: 1, name: 'AMP' },
  { no: 2, name: 'SECRET_SAUCE' },
]);

/**
 * Workaround for Stone's incompatibility with 'google.protobuf.Any.
 *
 * @generated from message ml_capabilities.Config
 */
export class Config extends Message<Config> {
  /**
   * @generated from oneof ml_capabilities.Config.config_type
   */
  configType:
    | {
        /**
         * @generated from field: string str_config = 1;
         */
        value: string;
        case: 'strConfig';
      }
    | {
        /**
         * @generated from field: bool bool_config = 2;
         */
        value: boolean;
        case: 'boolConfig';
      }
    | {
        /**
         * @generated from field: uint32 uint32_config = 3;
         */
        value: number;
        case: 'uint32Config';
      }
    | {
        /**
         * @generated from field: uint64 uint64_config = 4;
         */
        value: bigint;
        case: 'uint64Config';
      }
    | {
        /**
         * @generated from field: int32 int32_config = 5;
         */
        value: number;
        case: 'int32Config';
      }
    | {
        /**
         * @generated from field: int64 int64_config = 6;
         */
        value: bigint;
        case: 'int64Config';
      }
    | {
        /**
         * @generated from field: float float_config = 7;
         */
        value: number;
        case: 'floatConfig';
      }
    | {
        /**
         * @generated from field: double double_config = 8;
         */
        value: number;
        case: 'doubleConfig';
      }
    | {
        /**
         * Workaround for Stone's incompatibility with 'bytes'.
         *
         * @generated from field: google.protobuf.BytesValue bytes_config = 9;
         */
        value: BytesValue;
        case: 'bytesConfig';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Config>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.Config';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'str_config',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'config_type',
    },
    {
      no: 2,
      name: 'bool_config',
      kind: 'scalar',
      T: 8 /* ScalarType.BOOL */,
      oneof: 'config_type',
    },
    {
      no: 3,
      name: 'uint32_config',
      kind: 'scalar',
      T: 13 /* ScalarType.UINT32 */,
      oneof: 'config_type',
    },
    {
      no: 4,
      name: 'uint64_config',
      kind: 'scalar',
      T: 4 /* ScalarType.UINT64 */,
      oneof: 'config_type',
    },
    {
      no: 5,
      name: 'int32_config',
      kind: 'scalar',
      T: 5 /* ScalarType.INT32 */,
      oneof: 'config_type',
    },
    {
      no: 6,
      name: 'int64_config',
      kind: 'scalar',
      T: 3 /* ScalarType.INT64 */,
      oneof: 'config_type',
    },
    {
      no: 7,
      name: 'float_config',
      kind: 'scalar',
      T: 2 /* ScalarType.FLOAT */,
      oneof: 'config_type',
    },
    {
      no: 8,
      name: 'double_config',
      kind: 'scalar',
      T: 1 /* ScalarType.DOUBLE */,
      oneof: 'config_type',
    },
    {
      no: 9,
      name: 'bytes_config',
      kind: 'message',
      T: BytesValue,
      oneof: 'config_type',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): Config {
    return new Config().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): Config {
    return new Config().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): Config {
    return new Config().fromJsonString(jsonString, options);
  }

  static equals(
    a: Config | PlainMessage<Config> | undefined,
    b: Config | PlainMessage<Config> | undefined,
  ): boolean {
    return proto3.util.equals(Config, a, b);
  }
}

/**
 * @generated from message ml_capabilities.ModelInfo
 */
export class ModelInfo extends Message<ModelInfo> {
  /**
   * ie. "openai", "kserve" or "predict". "huggingface" is deprecated.
   *
   * @generated from field: string model_source = 1;
   */
  modelSource = '';

  /**
   * ie. "blip2-flan-t5-xxl" or "flan-alpaca-xxl".
   *
   * @generated from field: string model_name = 2;
   */
  modelName = '';

  constructor(data?: PartialMessage<ModelInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.ModelInfo';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'model_source',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 2, name: 'model_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ModelInfo {
    return new ModelInfo().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ModelInfo {
    return new ModelInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ModelInfo {
    return new ModelInfo().fromJsonString(jsonString, options);
  }

  static equals(
    a: ModelInfo | PlainMessage<ModelInfo> | undefined,
    b: ModelInfo | PlainMessage<ModelInfo> | undefined,
  ): boolean {
    return proto3.util.equals(ModelInfo, a, b);
  }
}

/**
 * @generated from message ml_capabilities.ApiKey
 */
export class ApiKey extends Message<ApiKey> {
  /**
   * @generated from field: string key = 1;
   */
  key = '';

  /**
   * @generated from field: ml_capabilities.KeyProvider provider = 2;
   */
  provider = KeyProvider.OPENAI;

  constructor(data?: PartialMessage<ApiKey>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.ApiKey';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'key', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 2,
      name: 'provider',
      kind: 'enum',
      T: proto3.getEnumType(KeyProvider),
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ApiKey {
    return new ApiKey().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ApiKey {
    return new ApiKey().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ApiKey {
    return new ApiKey().fromJsonString(jsonString, options);
  }

  static equals(
    a: ApiKey | PlainMessage<ApiKey> | undefined,
    b: ApiKey | PlainMessage<ApiKey> | undefined,
  ): boolean {
    return proto3.util.equals(ApiKey, a, b);
  }
}

/**
 * @generated from message ml_capabilities.CapabilitiesConfig
 */
export class CapabilitiesConfig extends Message<CapabilitiesConfig> {
  /**
   * Please specify the auth key for the resource you're calling
   * (ie. OpenAI API Key, AMP token, SecretSauce Key reference).
   * See KeyProvider message for more details.
   *
   * @generated from field: ml_capabilities.ApiKey api_key = 1;
   */
  apiKey?: ApiKey;

  /**
   * Request timeout in seconds
   *
   * @generated from field: uint32 timeout_secs = 2;
   */
  timeoutSecs = 0;

  /**
   * Optional. Riviera CallerID's feature field; see documentation in drl/x-dropbox-feature-attribution.
   * Default is "atlas/ml_capabilities/${capability_name}".
   *
   * @generated from field: string feature = 3;
   */
  feature = '';

  constructor(data?: PartialMessage<CapabilitiesConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CapabilitiesConfig';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'api_key', kind: 'message', T: ApiKey },
    {
      no: 2,
      name: 'timeout_secs',
      kind: 'scalar',
      T: 13 /* ScalarType.UINT32 */,
    },
    { no: 3, name: 'feature', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CapabilitiesConfig {
    return new CapabilitiesConfig().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CapabilitiesConfig {
    return new CapabilitiesConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CapabilitiesConfig {
    return new CapabilitiesConfig().fromJsonString(jsonString, options);
  }

  static equals(
    a: CapabilitiesConfig | PlainMessage<CapabilitiesConfig> | undefined,
    b: CapabilitiesConfig | PlainMessage<CapabilitiesConfig> | undefined,
  ): boolean {
    return proto3.util.equals(CapabilitiesConfig, a, b);
  }
}

/**
 * @generated from message ml_capabilities.FileId
 */
export class FileId extends Message<FileId> {
  /**
   * @generated from oneof ml_capabilities.FileId.id
   */
  id:
    | {
        /**
         * @generated from field: string file_id = 1;
         */
        value: string;
        case: 'fileId';
      }
    | {
        /**
         * @generated from field: string shared_link = 2;
         */
        value: string;
        case: 'sharedLink';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<FileId>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.FileId';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'file_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'id',
    },
    {
      no: 2,
      name: 'shared_link',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'id',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): FileId {
    return new FileId().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): FileId {
    return new FileId().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): FileId {
    return new FileId().fromJsonString(jsonString, options);
  }

  static equals(
    a: FileId | PlainMessage<FileId> | undefined,
    b: FileId | PlainMessage<FileId> | undefined,
  ): boolean {
    return proto3.util.equals(FileId, a, b);
  }
}

/**
 * @generated from message ml_capabilities.TextResult
 */
export class TextResult extends Message<TextResult> {
  /**
   * List of text. Clients can specify `n` to generate multiple results per input.
   * An empty list denotes an issue with obtaining the specific result.
   *
   * @generated from field: repeated string text = 1;
   */
  text: string[] = [];

  constructor(data?: PartialMessage<TextResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.TextResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'text',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): TextResult {
    return new TextResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): TextResult {
    return new TextResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): TextResult {
    return new TextResult().fromJsonString(jsonString, options);
  }

  static equals(
    a: TextResult | PlainMessage<TextResult> | undefined,
    b: TextResult | PlainMessage<TextResult> | undefined,
  ): boolean {
    return proto3.util.equals(TextResult, a, b);
  }
}

/**
 * @generated from message ml_capabilities.ChatContent
 */
export class ChatContent extends Message<ChatContent> {
  /**
   * @generated from oneof ml_capabilities.ChatContent.content
   */
  content:
    | {
        /**
         * @generated from field: string text = 1;
         */
        value: string;
        case: 'text';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ChatContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.ChatContent';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'text',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'content',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ChatContent {
    return new ChatContent().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ChatContent {
    return new ChatContent().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ChatContent {
    return new ChatContent().fromJsonString(jsonString, options);
  }

  static equals(
    a: ChatContent | PlainMessage<ChatContent> | undefined,
    b: ChatContent | PlainMessage<ChatContent> | undefined,
  ): boolean {
    return proto3.util.equals(ChatContent, a, b);
  }
}

/**
 * System messages provide context to the assistant and are not user visible.
 *
 * @generated from message ml_capabilities.SystemMessage
 */
export class SystemMessage extends Message<SystemMessage> {
  /**
   * @generated from field: ml_capabilities.ChatContent content = 1;
   */
  content?: ChatContent;

  constructor(data?: PartialMessage<SystemMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.SystemMessage';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'content', kind: 'message', T: ChatContent },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): SystemMessage {
    return new SystemMessage().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): SystemMessage {
    return new SystemMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): SystemMessage {
    return new SystemMessage().fromJsonString(jsonString, options);
  }

  static equals(
    a: SystemMessage | PlainMessage<SystemMessage> | undefined,
    b: SystemMessage | PlainMessage<SystemMessage> | undefined,
  ): boolean {
    return proto3.util.equals(SystemMessage, a, b);
  }
}

/**
 * Messsage from the user prompting the system, typically human.
 *
 * @generated from message ml_capabilities.UserMessage
 */
export class UserMessage extends Message<UserMessage> {
  /**
   * @generated from field: ml_capabilities.ChatContent content = 1;
   */
  content?: ChatContent;

  constructor(data?: PartialMessage<UserMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.UserMessage';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'content', kind: 'message', T: ChatContent },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): UserMessage {
    return new UserMessage().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): UserMessage {
    return new UserMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): UserMessage {
    return new UserMessage().fromJsonString(jsonString, options);
  }

  static equals(
    a: UserMessage | PlainMessage<UserMessage> | undefined,
    b: UserMessage | PlainMessage<UserMessage> | undefined,
  ): boolean {
    return proto3.util.equals(UserMessage, a, b);
  }
}

/**
 * Message generated by the AI assistant.
 *
 * @generated from message ml_capabilities.AssistantMessage
 */
export class AssistantMessage extends Message<AssistantMessage> {
  /**
   * @generated from field: ml_capabilities.ChatContent content = 1;
   */
  content?: ChatContent;

  constructor(data?: PartialMessage<AssistantMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.AssistantMessage';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'content', kind: 'message', T: ChatContent },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): AssistantMessage {
    return new AssistantMessage().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): AssistantMessage {
    return new AssistantMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): AssistantMessage {
    return new AssistantMessage().fromJsonString(jsonString, options);
  }

  static equals(
    a: AssistantMessage | PlainMessage<AssistantMessage> | undefined,
    b: AssistantMessage | PlainMessage<AssistantMessage> | undefined,
  ): boolean {
    return proto3.util.equals(AssistantMessage, a, b);
  }
}

/**
 * @generated from message ml_capabilities.ChatMessage
 */
export class ChatMessage extends Message<ChatMessage> {
  /**
   * @generated from oneof ml_capabilities.ChatMessage.message_type
   */
  messageType:
    | {
        /**
         * @generated from field: ml_capabilities.SystemMessage system_message = 1;
         */
        value: SystemMessage;
        case: 'systemMessage';
      }
    | {
        /**
         * @generated from field: ml_capabilities.UserMessage user_message = 2;
         */
        value: UserMessage;
        case: 'userMessage';
      }
    | {
        /**
         * @generated from field: ml_capabilities.AssistantMessage assistant_message = 3;
         */
        value: AssistantMessage;
        case: 'assistantMessage';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ChatMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.ChatMessage';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'system_message',
      kind: 'message',
      T: SystemMessage,
      oneof: 'message_type',
    },
    {
      no: 2,
      name: 'user_message',
      kind: 'message',
      T: UserMessage,
      oneof: 'message_type',
    },
    {
      no: 3,
      name: 'assistant_message',
      kind: 'message',
      T: AssistantMessage,
      oneof: 'message_type',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ChatMessage {
    return new ChatMessage().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ChatMessage {
    return new ChatMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ChatMessage {
    return new ChatMessage().fromJsonString(jsonString, options);
  }

  static equals(
    a: ChatMessage | PlainMessage<ChatMessage> | undefined,
    b: ChatMessage | PlainMessage<ChatMessage> | undefined,
  ): boolean {
    return proto3.util.equals(ChatMessage, a, b);
  }
}

/**
 * @generated from message ml_capabilities.ChatDialog
 */
export class ChatDialog extends Message<ChatDialog> {
  /**
   * Required. Prior messages in the conversation to be used as context for
   * generation, including both prompts and assistant responses.
   *
   * @generated from field: repeated ml_capabilities.ChatMessage messages = 1;
   */
  messages: ChatMessage[] = [];

  constructor(data?: PartialMessage<ChatDialog>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.ChatDialog';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'messages',
      kind: 'message',
      T: ChatMessage,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ChatDialog {
    return new ChatDialog().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ChatDialog {
    return new ChatDialog().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ChatDialog {
    return new ChatDialog().fromJsonString(jsonString, options);
  }

  static equals(
    a: ChatDialog | PlainMessage<ChatDialog> | undefined,
    b: ChatDialog | PlainMessage<ChatDialog> | undefined,
  ): boolean {
    return proto3.util.equals(ChatDialog, a, b);
  }
}

/**
 * @generated from message ml_capabilities.ChatGeneration
 */
export class ChatGeneration extends Message<ChatGeneration> {
  /**
   * @generated from field: ml_capabilities.ChatMessage chat_message = 1;
   */
  chatMessage?: ChatMessage;

  constructor(data?: PartialMessage<ChatGeneration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.ChatGeneration';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'chat_message', kind: 'message', T: ChatMessage },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ChatGeneration {
    return new ChatGeneration().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ChatGeneration {
    return new ChatGeneration().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ChatGeneration {
    return new ChatGeneration().fromJsonString(jsonString, options);
  }

  static equals(
    a: ChatGeneration | PlainMessage<ChatGeneration> | undefined,
    b: ChatGeneration | PlainMessage<ChatGeneration> | undefined,
  ): boolean {
    return proto3.util.equals(ChatGeneration, a, b);
  }
}

/**
 * @generated from message ml_capabilities.ChatResult
 */
export class ChatResult extends Message<ChatResult> {
  /**
   * @generated from field: repeated ml_capabilities.ChatGeneration generations = 1;
   */
  generations: ChatGeneration[] = [];

  constructor(data?: PartialMessage<ChatResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.ChatResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'generations',
      kind: 'message',
      T: ChatGeneration,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ChatResult {
    return new ChatResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ChatResult {
    return new ChatResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ChatResult {
    return new ChatResult().fromJsonString(jsonString, options);
  }

  static equals(
    a: ChatResult | PlainMessage<ChatResult> | undefined,
    b: ChatResult | PlainMessage<ChatResult> | undefined,
  ): boolean {
    return proto3.util.equals(ChatResult, a, b);
  }
}

/**
 * @generated from message ml_capabilities.EmbeddingResult
 */
export class EmbeddingResult extends Message<EmbeddingResult> {
  /**
   * An empty list denotes an issue with obtaining the specific result.
   *
   * @generated from field: repeated float values = 1;
   */
  values: number[] = [];

  constructor(data?: PartialMessage<EmbeddingResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.EmbeddingResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'values',
      kind: 'scalar',
      T: 2 /* ScalarType.FLOAT */,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): EmbeddingResult {
    return new EmbeddingResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): EmbeddingResult {
    return new EmbeddingResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): EmbeddingResult {
    return new EmbeddingResult().fromJsonString(jsonString, options);
  }

  static equals(
    a: EmbeddingResult | PlainMessage<EmbeddingResult> | undefined,
    b: EmbeddingResult | PlainMessage<EmbeddingResult> | undefined,
  ): boolean {
    return proto3.util.equals(EmbeddingResult, a, b);
  }
}

/**
 * @generated from message ml_capabilities.ImageGenerationResult
 */
export class ImageGenerationResult extends Message<ImageGenerationResult> {
  /**
   * @generated from oneof ml_capabilities.ImageGenerationResult.result
   */
  result:
    | {
        /**
         * base64-encoded result image bytes.
         *
         * @generated from field: google.protobuf.BytesValue image_bytes = 1;
         */
        value: BytesValue;
        case: 'imageBytes';
      }
    | {
        /**
         * url to the result image.
         *
         * @generated from field: string url = 2;
         */
        value: string;
        case: 'url';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ImageGenerationResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.ImageGenerationResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'image_bytes',
      kind: 'message',
      T: BytesValue,
      oneof: 'result',
    },
    {
      no: 2,
      name: 'url',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'result',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ImageGenerationResult {
    return new ImageGenerationResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ImageGenerationResult {
    return new ImageGenerationResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ImageGenerationResult {
    return new ImageGenerationResult().fromJsonString(jsonString, options);
  }

  static equals(
    a: ImageGenerationResult | PlainMessage<ImageGenerationResult> | undefined,
    b: ImageGenerationResult | PlainMessage<ImageGenerationResult> | undefined,
  ): boolean {
    return proto3.util.equals(ImageGenerationResult, a, b);
  }
}
