import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { APIv2Callable } from '@mirage/service-dbx-api/service';
import { ONE_DAY_IN_MILLIS } from '@mirage/shared/util/constants';
import { register, unregister } from '@mirage/shared/util/jobs';
import { getValueChangedFunc } from '@mirage/shared/util/value-changed';
import WithDefaults from '@mirage/storage/with-defaults';
import { EventEmitter } from 'eventemitter3';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import { getPublicSharingStatus } from './api';

import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

export type Service = ReturnType<typeof provideStackAdminService>;

export type StoredStackAdminSettings = {
  isPublicSharingAllowed: boolean;
  lastRefresh: number;
};

const REFRESH_JOB_NAME = 'stack-admin-settings-refresh';
const REFRESH_INTERVAL = ONE_DAY_IN_MILLIS;
const CACHE_TTL = ONE_DAY_IN_MILLIS;

interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

export default function provideStackAdminService(
  rawStorage: KVStorage<StoredStackAdminSettings>,
  { callApiV2 }: DbxApiServiceContract,
) {
  const adapter = new WithDefaults(rawStorage, {
    isPublicSharingAllowed: true,
    lastRefresh: 0,
  });
  const emitter = new EventEmitter<{ update: void }>();
  const isPublicSharingAllowedChanged = getValueChangedFunc<boolean>();

  async function refreshPublicSharingStatus() {
    const response = await getPublicSharingStatus(callApiV2);
    if (response !== undefined) {
      await adapter.set('lastRefresh', Date.now());

      if (isPublicSharingAllowedChanged(response.status)) {
        await adapter.set('isPublicSharingAllowed', response.status);
        emitter.emit('update');
      }
    }
  }

  async function latestSetting(): Promise<boolean> {
    return await adapter.get('isPublicSharingAllowed');
  }

  function isPublicSharingAllowed(
    withRefresh: boolean = false,
  ): Observable<boolean> {
    if (withRefresh) {
      refreshPublicSharingStatus();
    }

    const changes$ = rx.fromEvent(emitter, 'update') as Observable<void>;

    return rx
      .from(latestSetting())
      .pipe(op.mergeWith(changes$.pipe(op.concatMap(latestSetting))));
  }

  function startUpdateStackAdminSettings() {
    register(
      REFRESH_JOB_NAME,
      REFRESH_INTERVAL,
      true /* run on start */,
      async () => {
        if (Date.now() - (await adapter.get('lastRefresh')) > CACHE_TTL) {
          await refreshPublicSharingStatus();
        }
      },
    );
  }

  function cancelUpdateStackAdminSettings() {
    unregister(REFRESH_JOB_NAME);
  }

  return services.provide(
    ServiceId.STACK_ADMIN_SETTINGS,
    {
      isPublicSharingAllowed,
      refreshPublicSharingStatus,
      startUpdateStackAdminSettings,
      cancelUpdateStackAdminSettings,
    },
    [ServiceId.DBX_API],
  );
}
