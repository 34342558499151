import { useEffect } from "react";

import { useIsDropboxer } from "@mirage/service-auth/useDropboxAccount";
import Sentry from "@mirage/shared/sentry";
import { replayIntegration } from "@sentry/react";

export function useInitSentryReplay() {
  const isDropboxer = useIsDropboxer();

  useEffect(() => {
    if (isDropboxer && Sentry && Sentry.addIntegration) {
      if (Sentry.getIntegrationByName?.("Replay")) {
        return;
      }

      // Only enable replay for Dropboxers due to privacy requirements
      Sentry.addIntegration(
        replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true,

          // Allow recording of PAP event request details
          networkDetailAllowUrls: [
            "https://api.dropboxapi.com/2/pap_event_logging/log_events",
          ],
          networkCaptureBodies: true,
          networkResponseHeaders: ["X-Dropbox-Request-Id"],
        }),
      );
    }
  }, [isDropboxer]);
}
