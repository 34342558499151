import { Badge } from '@dropbox/dig-components/badge';
import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Close_DashSunsetModal } from '@mirage/analytics/events/types/close_dash_sunset_modal';
import { PAP_Interact_DashSunsetModal } from '@mirage/analytics/events/types/interact_dash_sunset_modal';
import { PAP_Shown_DashSunsetModal } from '@mirage/analytics/events/types/shown_dash_sunset_modal';
import { isUserIneligibleAtom } from '@mirage/eligibility/useEligibilityCheck';
import { InfoModal } from '@mirage/mosaics/Modals';
import { useOnboardingValues } from '@mirage/service-onboarding/hooks';
import { openURL } from '@mirage/service-platform-actions';
import { DASH_MARKETING_PAGE } from '@mirage/shared/urls';
import i18n from '@mirage/translations';
import { useAtomValue } from 'jotai';
import * as React from 'react';
import styles from './WelcomeModal.module.css';

const MODAL_ONBOARDING_KEY = 'seenUIMigrationV2Modal';

// Always opens on its first render
// When dismissed it will never show again
// TODO: Replace jotai behavior with Prompt
export default function WelcomeModal({ size }: { size: 'small' | 'standard' }) {
  const [open, setOpen] = React.useState(false);
  const isUserIneligible = useAtomValue(isUserIneligibleAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { getOnboardingValue, setOnboardingValue } = useOnboardingValues();
  const exposureLoggedRef = React.useRef(false);

  React.useEffect(() => {
    const getModalState = async () => {
      const hasSeenModal = await getOnboardingValue(MODAL_ONBOARDING_KEY);
      if (isUserIneligible) {
        setOpen(false);
        return;
      }
      const isOpen = !hasSeenModal;
      if (!exposureLoggedRef.current) {
        exposureLoggedRef.current = true;
        reportPapEvent(
          PAP_Shown_DashSunsetModal({
            featureLine: 'onboarding',
          }),
        );
      }
      setOpen(isOpen);
    };
    getModalState();
  }, [getOnboardingValue, isUserIneligible, reportPapEvent]);

  const onCloseModal = React.useCallback(() => {
    reportPapEvent(
      PAP_Close_DashSunsetModal({
        featureLine: 'onboarding',
      }),
    );
    setOpen(false);
    setOnboardingValue(MODAL_ONBOARDING_KEY, true);
  }, [reportPapEvent, setOnboardingValue]);

  const onSunsetButtonClick = React.useCallback(() => {
    reportPapEvent(
      PAP_Interact_DashSunsetModal({
        featureLine: 'onboarding',
      }),
    );
    openURL(`${DASH_MARKETING_PAGE}?tk=tmp_dfi_sunset`);
    onCloseModal();
  }, [onCloseModal, reportPapEvent]);

  return (
    <InfoModal
      open={open}
      bodyClassName={styles.welcomeModalBody}
      width={size}
      title={
        <div className={styles.welcomeModalTitle}>
          <Badge size="xsmall" variant="attention">
            {i18n.t('migration_update_title_chip')}
          </Badge>
          <span>{i18n.t('migration_update_title')}</span>
        </div>
      }
      titleSize="medium"
      body={
        <div className={styles.bodyContainer}>
          <Text>
            <span className={styles.bold}>
              {i18n.t('ui_update_intro_blurb_part_1')}&nbsp;
            </span>
            {i18n.t('ui_update_intro_blurb_part_2')}
          </Text>
        </div>
      }
      actions={[
        <Button
          variant="primary"
          key="welcome-modal-close-button"
          onClick={onSunsetButtonClick}
        >
          {i18n.t('learn_more_sunset_button_text')}
        </Button>,
      ]}
      onClose={onCloseModal}
      withClose={true}
    />
  );
}
