import { DashSpinner } from '@mirage/shared/dash-spinner';
import i18n from '@mirage/translations';
import { ReactNode, useEffect, useState } from 'react';
import styles from './DashSpinnerAutofitContainer.module.css';

export const ShowAfterMinimumDelay: React.FC<{
  delayMs?: number;
  children: ReactNode;
}> = ({ delayMs = 300, children }) => {
  // Avoid flickering the spinner by showing it too quickly.
  const [show, setShow] = useState(false);

  useEffect(() => {
    let canceled = false;

    const timer = setTimeout(() => {
      if (!canceled) {
        setShow(true);
      }
    }, delayMs);

    return () => {
      clearTimeout(timer);
      canceled = true;
    };
  }, [delayMs]);

  return show ? <>{children}</> : null;
};

export const DashSpinnerAutofitContainer: React.FC = () => {
  return (
    <ShowAfterMinimumDelay>
      <div className={styles.spinnerContainer}>
        <DashSpinner size={60} aria-valuetext={i18n.t('loading')} />
      </div>
    </ShowAfterMinimumDelay>
  );
};
