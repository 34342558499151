import { dash_feed, users } from '@dropbox/api-v2-client';
import {
  OnInViewHandler,
  useOnInViewTrigger,
} from '../../hooks/useOnInViewTrigger';
import { CompactActivityListItem } from '../ActivityListItem/CompactActivityListItem/CompactActivityListItem';
import { ExpandedActivityListItem } from '../ActivityListItem/ExpandedActivityListItem/ExpandedActivityListItem';

type ActivityListItemProps = {
  activity: dash_feed.ActivityItem;
  currentAccount?: users.FullAccount;
  index: number;
  onInView?: OnInViewHandler;
  onScrollIntoView?: OnInViewHandler;
  useExpandedActivityListItem?: boolean;
  enableSummary?: boolean;
  className?: string;
};

export const ActivityListItem = ({
  activity,
  currentAccount,
  index,
  onInView,
  onScrollIntoView,
  useExpandedActivityListItem = false,
  enableSummary = false,
  className,
}: ActivityListItemProps) => {
  const onViewRef = useOnInViewTrigger(
    { onInView, onScrollIntoView },
    { activity, index },
  );
  const ActivityListItem = useExpandedActivityListItem
    ? ExpandedActivityListItem
    : CompactActivityListItem;

  return (
    <div ref={onViewRef} className={className}>
      <ActivityListItem
        activity={activity}
        currentAccount={currentAccount}
        index={index}
        enableSummary={enableSummary}
      />
    </div>
  );
};

export const ActivityListItemSkeleton = ({
  useExpandedActivityListItem = false,
}: {
  useExpandedActivityListItem?: boolean;
}) => {
  if (useExpandedActivityListItem) {
    return <ExpandedActivityListItem.Skeleton />;
  } else {
    return <CompactActivityListItem.Skeleton />;
  }
};
