// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2105
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'click.calendar_attachment'
// Description: Fires when the user clicks the paper clip to add a suggestion as an attachment
// Owner: dash-web
export type Click_CalendarAttachment = PAPEventGeneric<
  'dash',
  'click',
  'calendar_attachment',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // stormcrow feature name
    feature?: string;
    // Meeting IDs that are uuidv4s as generated by neo4j. Used to tie together Dash meetings and related content.
    meetingId?: string;
    // Generated random request id for tracking suggestions in Dash
    dashRequestId?: string;
    // A hashed integer for unique predictions recommended in Dash
    predictionIdHash?: number;
    // zero-indexed position of a calendar item
    itemPosition?: number;
    // 0 indexed position of the calendar event/item
    calendarItemPosition?: number;
    // An entry point string, used to describe how users are triggering the event as relevant (e.g. browse_file_row_context_menu)
    entryPoint?: string;
  }
>;

export function PAP_Click_CalendarAttachment(
  properties?: Click_CalendarAttachment['properties'],
): Click_CalendarAttachment {
  return <Click_CalendarAttachment>{
    class: 'dash',
    action: 'click',
    object: 'calendar_attachment',
    properties,
  };
}
