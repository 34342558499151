import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { Service } from '@mirage/service-search/service';

import type { Connector } from '@mirage/service-dbx-api/service';
import type { SearchOptions } from '@mirage/service-search/service';
import type { SearchFilter } from '@mirage/shared/search/search-filters';

const service = services.get<Service>(ServiceId.SEARCH);

export function performSearch(
  searchQuery: string,
  connections: Connector[],
  filters: SearchFilter[],
  options: SearchOptions,
) {
  return service.performSearch(searchQuery, connections, filters, options);
}
