import * as React from "react";
import classNames from "classnames";
import css from "./SimpleTruncate.module.css";
import { useResizeObserver } from "@react-hookz/web";
import { Tooltip, type ControlProps } from "@dropbox/dig-components/tooltips";

export interface SimpleTruncateProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  className?: string;
  /**
   * Props to pass to the tooltip control component.
   * The tooltip will only be shown if the content is overflowing, even if the `open` prop here is true.
   */
  tooltipControlProps?: Partial<Omit<ControlProps, "triggerRef">>;

  /**
   * Will be called when the label detects that its text content is overflowing its container.
   */
  onOverflowChange?: ({
    target,
    isOverflowing,
  }: {
    target: HTMLElement;
    isOverflowing: boolean;
  }) => void;

  /**
   * Number of lines to truncate to. If provided, will truncate to the specified number of lines.
   * Entering any number less than 1 will default to 1.
   *
   * @default 1
   */
  lines?: number;

  children?: string;
}

/**
 * A Truncate utility only does tail truncation, and optional multiline truncation with a callback for overflow detection.
 *
 * Can show a tooltip on demand.
 *
 * This **only supports** `string` children. Passing an element is not supported (hence the more restrictive `children` prop).
 *
 * This doesn't use `DIG`'s `Truncate` component because at the time of creation it does not support knowing if the contents have been truncated in the multi-line usage,
 * and it is not possible to control the `Tooltip`'s open state directly from the `Truncate` component.
 */
export const SimpleTruncate: React.FC<
  React.PropsWithChildren<SimpleTruncateProps>
> = ({
  children,
  className,
  tooltipControlProps,
  onOverflowChange,
  lines: _lines = 1,
  ...props
}) => {
  const lines = Math.max(1, _lines);
  const ref = React.useRef<HTMLSpanElement>(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const isMultiLine = lines && lines > 1;
  const handleResize = (entry: ResizeObserverEntry) => {
    const { target } = entry;
    if (target instanceof HTMLElement) {
      const isOverflowing = isMultiLine
        ? target.clientHeight < target.scrollHeight
        : target.clientWidth < target.scrollWidth;
      setIsOverflowing(isOverflowing);
      if (onOverflowChange) onOverflowChange({ target: target, isOverflowing });
    }
  };
  useResizeObserver(ref, handleResize);

  const multiLineTruncate = isMultiLine
    ? ({
        "--truncate-multiple-lines": lines,
      } as React.CSSProperties)
    : undefined;
  return (
    <>
      {isOverflowing && (
        <Tooltip.Control
          open={tooltipControlProps?.open ?? false}
          triggerRef={ref}
          placement="top"
          isPortaled={true}
          auto={true}
          shouldReturnFocus={false}
          openDelay={1000}
          autoHide={true}
          {...tooltipControlProps}
        >
          {children}
        </Tooltip.Control>
      )}
      <span {...props} className={classNames(css.root, className)}>
        <span
          ref={ref}
          style={multiLineTruncate}
          className={classNames(css.overflowEllipsis, {
            [css.isMultiLineTruncate]: isMultiLine,
          })}
        >
          {children}
        </span>
      </span>
    </>
  );
};
