import { Text } from '@dropbox/dig-components/typography';
import styles from './ConversationMessageLoadingText.module.css';

export const ConversationMessageLoadingText = ({ text }: { text: string }) => {
  return (
    <div className={styles.ConversationMessageLoadingText}>
      <Text tagName="div">{text}</Text>
      <div className={styles.loader}></div>
      <div className={styles.loader}></div>
      <div className={styles.loader}></div>
    </div>
  );
};
