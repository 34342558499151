import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useRef } from 'react';

/**
 * Caches the returned object so that if this hook is called again with a new
 * object that deep-equals the last returned value, the hook returns an object
 * that shallow-equals the last returned value.  This lets us use objects as
 * react hook dependencies without worrying about how often they are updated
 * (react does shallow-object comparisons for hook dependencies).
 *
 * This is very similar to useMemo, but guarantees the object will be stable
 * (useMemo can choose to re-compute it).
 */
export const useStableObject = <O>(object: O): O => {
  const cached = useRef<O | null>(null);
  if (cached.current === null || !isEqual(object, cached.current)) {
    cached.current = object;
  }
  return cached.current;
};

/**
 * Allows you to access the previous value of a prop or state.
 *
 * It will be compared by identity, so please memoize objects and arrays
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

/**
 * Returns a stabilized callback reference which delegates to the most recent unstable callback
 *
 * This is similar to useCallback, but allows unstableCallback to access the most recent values from the closure.
 *
 * Example:
 * ```jsx
 * const latestValueFromProps = props.value
 * const stabilizedCallback = useStableCallback(() => console.log(latestValueFromProps));
 * useEffect(() => {
 *   stabilizedCallback();
 * }, [transitionHook]); // eslint won't complain, but react will only ever call this effect once because stabilizedCallback never changes
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export function useStableCallback<T extends Function>(unstableCallback: T): T {
  const ref = useRef<T>(unstableCallback);

  // wrapping this in a useMemo ensures we are compatible with react concurrent
  // mode when we eventually upgrade to a version that supports it
  useMemo(() => {
    ref.current = unstableCallback;
  }, [unstableCallback]);

  const callback = useCallback(function (...args) {
    return ref.current?.(...args);
  }, []);

  return callback as unknown as T;
}
