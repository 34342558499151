import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { getCurrentAccount } from '@mirage/service-auth';
import { getCachedOrFetchFeatureValue } from '@mirage/service-experimentation';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { tagged } from '@mirage/service-logging';
import { ONE_MINUTE_IN_MILLIS } from '@mirage/shared/util/constants';
import { register, unregister } from '@mirage/shared/util/jobs';
import WithDefaults from '@mirage/storage/with-defaults';
import _ from 'lodash';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import {
  LastViewedStackInfo,
  RecentBrowserContent,
  RecentConnectorContent,
  RecentContent,
} from '../types';
import { browse, getRecentContent } from './api';

import type { APIv2Callable } from '@mirage/service-dbx-api/service';
import type { LogoutServiceConsumerContract } from '@mirage/service-logout';
import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

const SYNC_RECENT_ENTITIES_JOB_NAME = 'recent-entities-sync';
const SYNC_INTERVAL_MS = ONE_MINUTE_IN_MILLIS * 3;
const SYNC_RECENT_BROWSER_HISTORY_JOB_NAME = 'recent-browser-history-sync';
const SYNC_BROWSER_HISTORY_INTERVAL_MS = ONE_MINUTE_IN_MILLIS;

export type RecentData<Content extends RecentContent> = {
  isLoading: boolean;
  content: Content[];
};

export type Service = ReturnType<typeof provideRecentContentService>;

export type StoredRecentContent = {
  // Old local storage keys were content, contentV2
  browserHistoryV3: RecentBrowserContent[];
  recentContentV3: RecentConnectorContent[];
  recentContentV4: RecentConnectorContent[];
  lastViewedStackInfo: LastViewedStackInfo;
  lastBrowserHistoryFetchTimestamp?: number; // Timestamp of the last browser history fetch
  lastRecentEntitiesFetchTimestamp?: number; // Timestamp of the last recent entities fetch
  lastRecentEntitiesV4FetchTimestamp?: number; // Timestamp of the last recent entities fetch
};

type DbxApiServiceContract = {
  callApiV2: APIv2Callable;
};

const logger = tagged('recent-content');

export default function provideRecentContentService(
  rawStorage: KVStorage<StoredRecentContent>,
  { callApiV2 }: DbxApiServiceContract,
  logoutService: LogoutServiceConsumerContract,
) {
  const adapter = new WithDefaults(rawStorage, {
    browserHistoryV3: [],
    recentContentV3: [],
    recentContentV4: [],
    lastViewedStackInfo: {},
    lastBrowserHistoryFetchTimestamp: 0,
    lastRecentEntitiesFetchTimestamp: 0,
  });

  const loadingBrowserHistory$ = new rx.BehaviorSubject<boolean>(true);
  const browserHistoryContent$ = new rx.BehaviorSubject<RecentBrowserContent[]>(
    [],
  );
  const loadingRecentEntities$ = new rx.BehaviorSubject<boolean>(true);
  const recentRecentEntities$ = new rx.BehaviorSubject<
    RecentConnectorContent[]
  >([]);
  const lastViewedStackInfo$ = new rx.BehaviorSubject<LastViewedStackInfo>({});

  // Load data from storage. In theory this could race with a network fetch but
  // local read should almost always be faster. We could probably clean this up
  // by having the storage adapter expose an observable instead.
  adapter
    .get('browserHistoryV3')
    .then((history) => browserHistoryContent$.next(history));
  adapter
    .get('recentContentV3')
    .then((content) => recentRecentEntities$.next(content));
  adapter
    .get('recentContentV4')
    .then((content) => recentRecentEntities$.next(content));
  adapter
    .get('lastViewedStackInfo')
    .then((info) => lastViewedStackInfo$.next(info));

  const browserHistory$ = rx
    .combineLatest([browserHistoryContent$, loadingBrowserHistory$])
    .pipe(op.map(([content, isLoading]) => ({ content, isLoading })));

  const recentEntities$ = rx
    .combineLatest([recentRecentEntities$, loadingRecentEntities$])
    .pipe(op.map(([content, isLoading]) => ({ content, isLoading })));

  async function refreshBrowserHistory() {
    const currentTime = Date.now();
    const lastFetchTime =
      (await adapter.get('lastBrowserHistoryFetchTimestamp')) || 0;

    // In the case user opens multiple tabs / refreshes page, don't fetch again
    if (currentTime - lastFetchTime < SYNC_BROWSER_HISTORY_INTERVAL_MS) {
      loadingBrowserHistory$.next(false);
      return;
    }

    try {
      loadingBrowserHistory$.next(true);
      const recentBrowserHistory = await getRecentContent(callApiV2);
      if (recentBrowserHistory) {
        await adapter.set('browserHistoryV3', recentBrowserHistory);
        browserHistoryContent$.next(recentBrowserHistory);
      }
    } finally {
      loadingBrowserHistory$.next(false);
      adapter.set('lastBrowserHistoryFetchTimestamp', currentTime);
    }
  }

  async function refreshRecents() {
    const currentTime = Date.now();
    const isV4Recents = convertFeatureValueToBool(
      await getCachedOrFetchFeatureValue('dash_2024_06_05_august_revision'),
    );
    const recentsAdapter = isV4Recents ? 'recentContentV4' : 'recentContentV3';
    const lastFetchTimeKey = isV4Recents
      ? 'lastRecentEntitiesV4FetchTimestamp'
      : 'lastRecentEntitiesFetchTimestamp';
    const lastFetchTime = (await adapter.get(lastFetchTimeKey)) || 0;

    // In the case user opens multiple tabs / refreshes page, don't fetch again
    if (currentTime - lastFetchTime < SYNC_INTERVAL_MS) {
      loadingRecentEntities$.next(false);
      return;
    }

    try {
      loadingRecentEntities$.next(true);
      let recents: RecentConnectorContent[] = [];
      const account = await getCurrentAccount();

      const recentsResults =
        isV4Recents && account
          ? await browse(callApiV2, [
              {
                filter: {
                  '.tag': 'person_filter',
                  emails: [account.email],
                  match_last_modifier: true,
                },
              },
            ])
          : await browse(callApiV2);
      recents = [...recents, ...recentsResults];

      logger.info(
        `Fetched ${recents.length} recent connector entities. By connector:`,
        JSON.stringify(
          Object.fromEntries(
            _.chain(recents)
              .groupBy((c) => c.connectorInfo.connectorName)
              .entries()
              .map(([name, content]) => [name, content.length])
              .value(),
          ),
          null,
          2,
        ),
      );

      if (recents.length > 0) {
        const orderedRecents = _.orderBy(recents, (c) => c.sortValue, 'desc');
        await adapter.set(recentsAdapter, orderedRecents);
        recentRecentEntities$.next(orderedRecents);
      }
    } finally {
      loadingRecentEntities$.next(false);
      adapter.set(lastFetchTimeKey, currentTime);
    }
  }

  async function refreshRecentContent() {
    await Promise.all([refreshRecents(), refreshBrowserHistory()]);
  }

  function recentBrowserHistory(): Observable<
    RecentData<RecentBrowserContent>
  > {
    return browserHistory$;
  }

  function recentEntities(): Observable<RecentData<RecentConnectorContent>> {
    return recentEntities$;
  }

  function latestLastViewedStackInfo(): Observable<LastViewedStackInfo> {
    return lastViewedStackInfo$;
  }

  async function reportViewedStack(namespaceId: string): Promise<void> {
    const currentInfo = await adapter.get('lastViewedStackInfo');
    currentInfo[namespaceId] = Date.now();
    await adapter.set('lastViewedStackInfo', currentInfo);
    lastViewedStackInfo$.next(currentInfo);
  }

  async function startSyncRecentEntities() {
    register(SYNC_RECENT_ENTITIES_JOB_NAME, SYNC_INTERVAL_MS, true, () =>
      refreshRecents(),
    );
    register(
      SYNC_RECENT_BROWSER_HISTORY_JOB_NAME,
      SYNC_BROWSER_HISTORY_INTERVAL_MS,
      true,
      () => refreshBrowserHistory(),
    );
  }

  async function cancelSyncRecentEntities() {
    unregister(SYNC_RECENT_ENTITIES_JOB_NAME);
    unregister(SYNC_RECENT_BROWSER_HISTORY_JOB_NAME);
  }

  async function tearDown() {
    await cancelSyncRecentEntities();
    await adapter.clear();
  }

  logoutService.registerLogoutCallback(ServiceId.RECENT_CONTENT, async () => {
    logger.debug('Handling logout in recent content service');
    await tearDown();
    logger.debug('Done handling logout in recent content service');
  });

  return services.provide(
    ServiceId.RECENT_CONTENT,
    {
      recentBrowserHistory,
      recentEntities,
      refreshRecentContent,
      latestLastViewedStackInfo,
      reportViewedStack,
      startSyncRecentEntities,
      cancelSyncRecentEntities,
    },
    [ServiceId.DBX_API],
  );
}
