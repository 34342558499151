import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const CONNECT_QUERY_PARAM = 'connect';

export const useConnectQueryParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const connect = useMemo(() => {
    const connect = searchParams.get(CONNECT_QUERY_PARAM);

    return connect;
  }, [searchParams]);

  const removeConnect = () => {
    setSearchParams((params) => {
      params.delete(CONNECT_QUERY_PARAM);
      return params;
    });
  };

  return {
    connect,
    removeConnect,
  };
};
