// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgSentry({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 3.667C2 2.747 2.746 2 3.667 2h16.666C21.253 2 22 2.746 22 3.667v16.666c0 .92-.746 1.667-1.667 1.667H3.667C2.747 22 2 21.254 2 20.333V3.667z"
        fill="#584674"
      />
      <path
        d="M13.693 5.718c-.124-.219-.3-.4-.511-.526a1.343 1.343 0 00-1.38 0c-.21.126-.386.307-.511.526L9.315 9.301c1.51.798 2.796 2 3.73 3.486a10.642 10.642 0 011.6 4.99h-1.387a9.138 9.138 0 00-1.415-4.265 8.471 8.471 0 00-3.228-2.959l-1.829 3.348a4.837 4.837 0 011.853 1.56c.484.676.8 1.47.92 2.309H6.371a.22.22 0 01-.106-.037.237.237 0 01-.077-.087.252.252 0 01-.003-.23l.883-1.589a3.183 3.183 0 00-1.01-.604l-.873 1.59a1.5 1.5 0 00-.139 1.104c.048.185.129.357.24.508.11.151.249.277.406.372.207.123.44.189.679.19h4.363a6.485 6.485 0 00-.518-3.085 6.104 6.104 0 00-1.884-2.417l.694-1.272a7.485 7.485 0 012.395 2.953 7.972 7.972 0 01.698 3.821h3.697a11.965 11.965 0 00-1.189-5.751 11.2 11.2 0 00-3.74-4.358l1.403-2.543a.233.233 0 01.14-.112.22.22 0 01.175.026c.16.092 6.094 11.053 6.205 11.18a.252.252 0 01-.09.33.218.218 0 01-.114.03h-1.43c.018.405.018.808 0 1.211h1.436c.182.001.363-.036.531-.109.169-.073.322-.181.451-.317.13-.137.232-.299.3-.477a1.533 1.533 0 00-.081-1.287l-6.12-11.092z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgSentry;
