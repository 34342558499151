import { Text } from '@dropbox/dig-components/typography';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import * as React from 'react';
import gif from './DashSpinner.gif';
import styles from './DashSpinner.module.css';

/**
 * Embedded as a gif because:
 * 1. For spinners, we don't want it to take 1-2 seconds to load from an
 *    external url.
 * 2. Cannot be SVG because SVGs don't support animations.
 */
export const DashSpinnerImage = gif;

/** Actual size of gif is 64x64 pixels. */
export const DashSpinner: React.FC<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > & { size?: number; displayLoadingMessage?: boolean }
> = ({ displayLoadingMessage, ...props }) => {
  const imgRef = React.useRef<HTMLImageElement>(null);

  // Make it easy for the user to specify a desired pixel size.
  const sizeProps = props.size
    ? { width: props.size, height: props.size }
    : undefined;

  const [imgReady, setImgReady] = React.useState(imgRef.current?.complete);

  return (
    <div className={styles.spinnerWrapper}>
      {!!displayLoadingMessage && (
        <Text
          className={classnames(styles.placeholder, {
            [styles.visible]: !imgReady,
          })}
        >
          {i18n.t('logging_in_loading_message')}
        </Text>
      )}
      <img
        {...props}
        {...sizeProps}
        ref={imgRef}
        className={classnames(props.className, styles.spinner, {
          [styles.visible]: imgReady,
        })}
        src={DashSpinnerImage}
        onLoad={() => setImgReady(true)}
        alt={i18n.t('logging_in_loading_message')}
      />
    </div>
  );
};
