// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2912
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { FeatureLine } from '../enums/feature_line';
import type { StackAccessLevel } from '../enums/stack_access_level';

// Event_name: 'close.dash_share_modal'
// Description: When a user closes the dash share modal
// Owner: content-ingest
export type Close_DashShareModal = PAPEventGeneric<
  'stacks',
  'close',
  'dash_share_modal',
  {
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // The access level settings for a stack
    stackAccessLevel?: StackAccessLevel;
    // True is Public link sharing is disabled, False otherwise
    isPublicLinkDisabled?: boolean;
    // True if public sharing is allowed, false otherwise
    isPublicSharingAllowed?: boolean;
  }
>;

export function PAP_Close_DashShareModal(
  properties?: Close_DashShareModal['properties'],
): Close_DashShareModal {
  return <Close_DashShareModal>{
    class: 'stacks',
    action: 'close',
    object: 'dash_share_modal',
    properties,
  };
}
