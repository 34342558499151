import * as dbxApiService from '@mirage/service-dbx-api';
import { AnswerResponse } from '@mirage/service-dbx-api/service/answers';
import { useCallback, useState } from 'react';

const useImplicitAnswer = () => {
  const [answer, setAnswer] = useState<AnswerResponse | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const handleFetchImplicitAnswer = useCallback(async (query: string) => {
    setAnswer(undefined);
    setLoading(true);

    try {
      const res = await dbxApiService.fetchImplicitAnswer(query);
      setAnswer(res);
      setLoading(false);
    } catch {
      // Silently handle errors for now. We just won't display answer card if there's an error here.
    }
  }, []);

  return { answer, answerLoading: loading, handleFetchImplicitAnswer };
};

export default useImplicitAnswer;
