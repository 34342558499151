import { tagged } from '@mirage/service-logging';
import { nonEmpty, sleepMs } from '@mirage/shared/util/tiny-utils';
import { StacksCache } from './cache';

import type { stacks, version_tree } from '@dropbox/api-v2-client';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';

const logger = tagged('sync');

// Fallback sync loop to keep
const SYNC_JOB_INTERVAL_MS = 900_000; // 15 minutes

// Duration to delay before sync'ing again.
const SYNC_DELAY_MS = 3000; // 3s

// Controls how quickly to load stacks. Lowering this value loads the stacks
// faster, but also increases the chance of getting rate-limited by the server.
export const LOAD_STACK_DELAY_MS = 50;

type DbxApiServiceContract = {
  callApiV2: APIv2Callable;
};

export class StacksSync {
  private interval: NodeJS.Timeout | undefined;
  private isSyncing = false;
  private syncAgain = false;
  private delayedSyncTimeout: NodeJS.Timeout | undefined;

  // Allow user writes to stop and cancel active syncs.
  private numActiveUserWrites = 0;
  private numTotalUserWrites = 0;
  private beginSyncNumTotalUserWrites = 0;

  // Callback for updating the bolt data. This is exposed as an optional
  // property to avoid a circular dependency with the sync logic.
  public resetAllBoltData:
    | ((boltData: (stacks.BoltData | undefined)[]) => void)
    | undefined;

  public constructor(
    private readonly cache: StacksCache,
    private dbxApiService: DbxApiServiceContract,
  ) {}

  public get started() {
    return Boolean(this.interval);
  }

  public startSyncJob() {
    if (this.interval) return;

    this.interval = setInterval(() => {
      // Not urgent, so use scheduleDelayedSync() instead of syncToLatest().
      this.scheduleDelayedSync();
    }, SYNC_JOB_INTERVAL_MS);
  }

  public stopSyncJob() {
    if (!this.interval) return;

    clearInterval(this.interval);
    this.interval = undefined;
  }

  /**
   * Wait for server data propagation before sync'ing. Calling this function
   * repeatedly is safe and will just cancel previous requests.
   */
  public scheduleDelayedSync() {
    this.syncAgain = false;

    if (this.delayedSyncTimeout) {
      clearTimeout(this.delayedSyncTimeout);
    }

    this.delayedSyncTimeout = setTimeout(() => {
      this.delayedSyncTimeout = undefined;
      this.syncToLatest();
    }, SYNC_DELAY_MS);
  }

  /**
   * Inform us that the user is going to write some data to the server.
   * This isn't needed to local cache writes.
   * Make sure to call releaseUserWriteLock() in a finally block.
   * Make sure to await on this call.
   */
  public async acquireUserWriteLock(): Promise<() => void> {
    this.numTotalUserWrites++;

    while (this.numActiveUserWrites > 0) {
      // Using a sleep loop simplifies the implementation greatly.
      await sleepMs(10);
    }

    this.numActiveUserWrites++;

    // Returns the release function to force the caller to await.
    return () => this.releaseUserWriteLock();
  }

  /**
   * Only call this in a finally block after you call acquireUserWriteLock().
   * Made private to force the user to await on acquireUserWriteLock().
   */
  private releaseUserWriteLock() {
    this.numActiveUserWrites--;

    // Schedule sync only after no more user writes are pending.
    if (!this.isLockedByUserWrite()) {
      // Auto re-sync after few seconds to allow new data to propagate among
      // distributed servers first.
      this.scheduleDelayedSync();
    }
  }

  public isLockedByUserWrite() {
    return this.numActiveUserWrites > 0;
  }

  /**
   * Sync the stacks data to the latest from server.
   * Note that this checks for the full metadata, but not the full dataset.
   * Checking the full metadata should always sync the data accurately.
   *
   * It is ok to call this function repeatedly as this function will dedupe
   * sync requests automatically.
   */
  public async syncToLatest(hlcMicros?: number) {
    // If sync job is not started yet, that means we are still loading all the stacks.
    if (!this.interval) return;

    if (this.isLockedByUserWrite() || this.isSyncing) {
      logger.debug(
        `Syncing again later due to isLockedByUserWrite(${this.isLockedByUserWrite()}) or isSyncing(${
          this.isSyncing
        })`,
      );
      this.syncAgain = true;
      return;
    }

    this.isSyncing = true;
    this.syncAgain = false;
    this.beginSyncNumTotalUserWrites = this.numTotalUserWrites;

    try {
      await this.syncToLatestWithoutChecking(hlcMicros);
    } catch (e) {
      logger.warn('Error in syncToLatestWithoutChecking (will sync again):', e);
      this.syncAgain = true;
    } finally {
      this.isSyncing = false;

      if (this.syncAgain) {
        this.scheduleDelayedSync();
      }
    }
  }

  /** Any write lock or intervening writes will abort the current sync. */
  private shouldAbortSync(): boolean {
    if (
      this.isLockedByUserWrite() ||
      this.beginSyncNumTotalUserWrites !== this.numTotalUserWrites
    ) {
      // Let releaseUserWriteLock() do the sync again.
      this.syncAgain = false;
      return true;
    }

    return false;
  }

  private async syncToLatestWithoutChecking(hlcMicros?: number) {
    // Get list of all stacks' latest max_hlc's.
    const hlc = hlcMicros ? { unix_micros: hlcMicros } : undefined;
    const response = await this.dbxApiService.callApiV2('stacksQueryStacks', {
      fields: { max_hlc: true },
      hlc,
    });

    // We will always have the full set of latest bolt data here, so just
    // update all the bolt channels at once.
    this.resetAllBoltData?.([
      response.bolt_data,
      ...(response.stacks?.map((s) => s.bolt_data) ?? []),
    ]);

    const newStacks = response.stacks ?? [];
    const newStackByNsId = StacksSync.mapStackByNsId(newStacks);

    const oldStacks = (await this.cache.getStacks()) ?? [];
    const oldStackByNsId = StacksSync.mapStackByNsId(oldStacks);

    const publishedStacks = (await this.cache.getPublishedStacks()) ?? [];
    const publishedStackByNsId = new Map(
      publishedStacks.map((s) => [nonEmpty(s.namespace_id, 'namespace_id'), s]),
    );

    // Find all stacks to remove from cache.
    const stackNsIdsToRemove = new Set(
      Array.from(oldStackByNsId.keys()).filter(
        (nsId) => !newStackByNsId.has(nsId) && !publishedStackByNsId.has(nsId),
      ),
    );

    // Find all stacks to refresh. This includes new stacks.
    const stackNsIdsToRefresh = Array.from(newStackByNsId.keys()).filter(
      (nsId) =>
        // Not going to remove this stack.
        !stackNsIdsToRemove.has(nsId) &&
        // Stack does not already exist, so add it.
        (!oldStackByNsId.has(nsId) ||
          // Refresh if the new hlc is greater.
          // Doing this will avoid overwriting local edits while waiting for
          // the server response.
          (oldStackByNsId.get(nsId)?.max_hlc_micros ?? 0) <
            (newStackByNsId.get(nsId)?.max_hlc_micros ?? 0) ||
          // Refresh if the stack pin status is changed.
          // Note that we are doing the full stack update even when only the
          // pin status has changed. Doing so keeps the code simple and also
          // avoids needing the client to make partial updates, which might
          // cause the stacks data to go out of sync with the server.
          oldStackByNsId.get(nsId)?.user_data?.is_pinned !==
            newStackByNsId.get(nsId)?.user_data?.is_pinned),
    );

    if (this.shouldAbortSync()) {
      return;
    }

    this.cache.setUserBoltData(response.bolt_data);

    // Perform updates concurrently.
    await Promise.all([
      this.removeStacksFromCache(Array.from(stackNsIdsToRemove)),
      this.refreshStacks(stackNsIdsToRefresh, hlc),
    ]);
  }

  private static mapStackByNsId(
    stacks: stacks.Stack[],
  ): Map<string, stacks.Stack> {
    return new Map(
      stacks?.map((s) => [nonEmpty(s.namespace_id, 'namespace_id'), s]),
    );
  }

  private async removeStacksFromCache(nsIds: string[]) {
    if (!nsIds.length) return;

    for (const nsId of nsIds) {
      this.cache.removeStack(nsId);
      this.cache.setStackItems(nsId, undefined);
    }
  }

  private async refreshStacks(nsIds: string[], hlc?: version_tree.Hlc) {
    let isFirst = true;

    // Important: Load stacks one by one. Otherwise one rogue (e.g. huge) stack
    // will prevent all the stacks from being loaded.
    for (const nsId of nsIds) {
      // Avoid overloading the server and getting the user throttled.
      if (isFirst) {
        isFirst = false;
      } else {
        await sleepMs(LOAD_STACK_DELAY_MS);
      }

      if (this.shouldAbortSync()) {
        return;
      }

      const response = await this.dbxApiService.callApiV2('stacksQueryStacks', {
        fields: { all: true },
        namespace_ids: [nsId],
        hlc,
      });

      if (this.shouldAbortSync()) {
        return;
      }

      for (const stack of response.stacks ?? []) {
        const nsId = nonEmpty(stack.namespace_id, 'stack.namespace_id');

        // Allow the first cancellation to cancel everything.
        if (this.shouldAbortSync()) {
          return;
        }

        this.cache.setStack(stack);

        // Don't prefetch all items if not needed yet.
        const oldItems = await this.cache.getStackItems(nsId);
        if (oldItems === undefined) return;

        try {
          const response = await this.dbxApiService.callApiV2(
            'stacksListStackItems',
            {
              namespace_id: nsId,
              hlc,
            },
          );

          if (this.shouldAbortSync()) {
            return;
          }

          this.cache.setStackItems(nsId, response.items);
        } catch (e) {
          // An error here is non-blocking, so don't let it fail the entire
          // update loop.
        }
      }
    }
  }
}
