import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgZoom({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 50.667 50.667"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.333 50.667c13.992 0 25.334-11.343 25.334-25.334S39.325 0 25.333 0 0 11.342 0 25.333s11.342 25.334 25.333 25.334z"
        fill="#2196f3"
      />
      <path
        clipRule="evenodd"
        d="M14.866 32.574h16.755V20.288c0-1.851-1.5-3.351-3.351-3.351H11.515v12.286c0 1.851 1.5 3.351 3.351 3.351zm18.988-4.467l6.702 4.467V16.937l-6.701 4.468z"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgZoom;
