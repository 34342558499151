export const enum MetricPageName {
  HOME_V2 = 'home_v2', // V5 startpage - March 2024 revision
  ALL_STACKS = 'all_stacks',
  STACK_DETAILS = 'stack_details',
}

export const AGGREGATED_PAGE_MODULE = 'e2e';

export const enum HomePageModule {
  CALENDAR_V2 = 'calendar_v2',
  SUGGESTED_APPS = 'suggested_apps',
  RECENTS_V2 = 'recents_v2',
  SUGGESTED_STACKS_V2 = 'suggested_stacks_v2',
  CONNECT_APPS = 'connect_apps',
}

export const enum StackDetailsModule {
  STACKS_DATA = 'stacks_data',
  SUGGESTED_ITEMS = 'suggested_items',
  SHARING = 'sharing',
  VIEW_SHARED_STACK = 'view_shared_stack',
}
