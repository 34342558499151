// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3191
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { StackAccessLevel } from '../enums/stack_access_level';

// Event_name: 'select.dash_learn_more'
// Description: User takes an action to learn more about Dash
// Owner: otc-startpage
export type Select_DashLearnMore = PAPEventGeneric<
  'dash',
  'select',
  'dash_learn_more',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The stack id that represents the stack
    stackId?: string;
    // marks if the content is shared content
    isShared?: boolean;
    // User_id associated with the creator of the content being acted upon
    creatorId?: string;
    // The access level settings for a stack
    stackAccessLevel?: StackAccessLevel;
    // Whether the user is interacting with their welcome stack
    isWelcomeStack?: boolean;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
  }
>;

export function PAP_Select_DashLearnMore(
  properties?: Select_DashLearnMore['properties'],
): Select_DashLearnMore {
  return <Select_DashLearnMore>{
    class: 'dash',
    action: 'select',
    object: 'dash_learn_more',
    properties,
  };
}
