// eslint-disable-next-line no-restricted-imports
import { Tooltip } from '@dropbox/dig-components/tooltips';

export type DigTooltipProps = Parameters<typeof Tooltip>[0] & {
  disabled?: boolean;
};
/**
 * Wrapper around the DIG Tooltip component that checks for both
 * `disabled` and empty string for `title`, and will not render
 * the tooltip if either is true. This prevents an empty tooltip
 * from appearing.
 */
export const DigTooltip: React.FC<DigTooltipProps> & {
  Control: typeof Tooltip.Control;
} = ({ disabled, title, children, ...props }) => {
  return disabled || !title ? (
    <>{children}</>
  ) : (
    <Tooltip
      // Add some defaults we want globally, but can be overridden.
      openDelay={1000}
      shouldCloseOnClick
      autoHide
      title={title}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

// Add exports from DIG tooltip that other files need, so that we don't need to
// add exceptions to `.dependency-cruiser.js`.
DigTooltip.Control = Tooltip.Control;
