// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2530
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'shown.stacks_page'
// Description: Impression event for when the stacks page is displayed to the user
// Owner: dash-web
export type Shown_StacksPage = PAPEventGeneric<
  'stacks',
  'shown',
  'stacks_page',
  never
>;

export function PAP_Shown_StacksPage(
  properties?: Shown_StacksPage['properties'],
): Shown_StacksPage {
  return <Shown_StacksPage>{
    class: 'stacks',
    action: 'shown',
    object: 'stacks_page',
    properties,
  };
}
