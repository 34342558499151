import { dash } from '@dropbox/api-v2-client';
import { Overlay } from '@dropbox/dig-components/overlay';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { CheckmarkCircleFill } from '@dropbox/dig-icons/assets';
import { PersonMetadata } from '@mirage/search/SearchResults/Metadata/PersonMetadata';
import { buildCuratorUser } from '@mirage/service-dbx-api/service/utils';
import i18n from '@mirage/translations';
import { format } from 'date-fns';
import { RefObject } from 'react';
import styles from './VerifiedPopup.module.css';

type Props = {
  verification: dash.Curation;
  anchorRef: RefObject<HTMLElement>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

export function VerifiedPopup({
  verification,
  anchorRef,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
}: Props) {
  const verifiedDate = verification.updated_at_ms
    ? new Date(verification.updated_at_ms)
    : undefined;

  return (
    <Overlay
      placement="top"
      anchorRef={anchorRef}
      auto={true}
      className={styles.popupContainer}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.infoContainer}>
        <div className={styles.titleContainer}>
          <UIIcon
            src={CheckmarkCircleFill}
            className={styles.verifiedIcon}
            isOpticallyAligned={true}
            aria-label={i18n.t('verified')}
          />
          <Text isBold={true}>{i18n.t('verified')}</Text>
        </div>
        <div className={styles.metadataContainer}>
          {verification.updated_by && (
            <Text color="subtle" className={styles.subtitle}>
              <PersonMetadata
                i18nKey="by"
                person={buildCuratorUser(verification.updated_by)}
              />
            </Text>
          )}
          {verifiedDate && (
            <Text color="subtle">{format(verifiedDate, 'MMM d, yyyy')}</Text>
          )}
        </div>
        <Text className={styles.blurb} id="verified-search-result-tooltip">
          {i18n.t('verified_search_result_blurb')}
        </Text>
      </div>
      {/* TODO: Re-add button when url for learn more is created */}
      {/* <div className={styles.footerContainer}>
        <Button variant="opacity">
          {i18n.t('verified_search_result_learn_more')}
        </Button>
      </div> */}
    </Overlay>
  );
}
