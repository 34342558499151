import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashExtensionUpsell } from '@mirage/analytics/events/types/open_dash_extension_upsell';
import { PAP_Shown_DashExtensionUpsell } from '@mirage/analytics/events/types/shown_dash_extension_upsell';
import {
  browserNameForCurrentBrowser,
  openExtensionLinkForCurrentBrowser,
  SvgForCurrentBrowserWebExtension,
} from '@mirage/settings/utils/webExtensionsHelpers';
import { extensionConnectedStateAtom } from '@mirage/shared/atoms/extensionConnection';
import { EmptyStateCard } from '@mirage/shared/two-column-grid/EmptyStateCard/EmptyStateCard';
import i18n from '@mirage/translations';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';

const VALID_BROWSER_EXTENSIONS = ['Chrome', 'Edge'];

export const BrowserExtensionModule = () => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const extensionConnection = useAtomValue(extensionConnectedStateAtom);
  const hasBrowserExtensionInstalled = extensionConnection.connected;
  const browserName = browserNameForCurrentBrowser();

  useEffect(() => {
    if (
      hasBrowserExtensionInstalled ||
      !VALID_BROWSER_EXTENSIONS.includes(browserName)
    ) {
      return;
    }

    reportPapEvent(
      PAP_Shown_DashExtensionUpsell({
        dashActionSurface: 'stacks_page',
        actionSurfaceComponent: 'recents',
        featureLine: 'extension_upsell',
      }),
    );
  }, []);

  if (
    hasBrowserExtensionInstalled ||
    !VALID_BROWSER_EXTENSIONS.includes(browserName)
  ) {
    return <></>;
  }

  const handleClick = () => {
    openExtensionLinkForCurrentBrowser();

    reportPapEvent(
      PAP_Open_DashExtensionUpsell({
        dashActionSurface: 'stacks_page',
        actionSurfaceComponent: 'recents',
        featureLine: 'extension_upsell',
      }),
    );
  };

  return (
    <EmptyStateCard
      message={i18n.t('browser_extension_install_message')}
      actionText={i18n.t('add_browser_extension', { browserName })}
      onClick={handleClick}
    >
      <SvgForCurrentBrowserWebExtension />
    </EmptyStateCard>
  );
};
