/* eslint @typescript-eslint/no-explicit-any: 0 */

export type ErrorClass<T extends Error> = new (...args: any[]) => T;

export function getIdOrName<T extends Error>(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  clazz: ErrorClass<T> | Function,
): string {
  // for our custom error instances, extract the id property in the event that
  // the name is obfuscated due to compiler minification

  // RJM: This was converted from logic in other places, not sure if both
  // are required.
  const c: { id?: string; name: string } = clazz;
  const z: { id?: string; name: string } = clazz.constructor;

  return c.id || c.name || z.id || z.name || 'UNKNOWN_ERROR';
}
