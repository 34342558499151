import { urlToContentID } from '@mirage/shared/util/urlRulesUtil';
import { Array, Number, Optional, Record, Static, String } from 'runtypes';

export type LinkSummarySuccess = {
  type: 'success';
  response: LinkSummaryResponse;
  content: LinkContent;
  created: number;
};
export type LinkSummaryError = {
  type: 'error';
  description: string;
};

export const LinkSummaryResponse = Record({
  short_summary: String,
  key_points: Array(
    Record({
      question: String,
      answer: String,
      quote: Optional(String),
    }),
  ),
});
export type LinkSummaryResponse = Static<typeof LinkSummaryResponse>;

export interface LinkContent {
  title: string;
  text: string;
  url: string;
}

export const AnswerResponse = Record({
  text: String,
  quote: Optional(String),
  timestamp: Optional(Number),
});
export type AnswerResponse = Static<typeof AnswerResponse>;

export const Question = Record({
  text: String,
  timestamp: Optional(Number),
});
export type Question = Static<typeof Question>;

export interface QuestionAnswer {
  id: string;
  question: Question;
  answer: AnswerResponse | undefined;
}

export function isSummarySupported(url: string | undefined) {
  return !!url && !!getSummarizableLink(url);
}

export type SummarizableLink = SummarizableLinkPaper | SummarizableLinkGdoc;

export interface SummarizableLinkPaper {
  type: 'paper:scl';
  url: string;
}

export interface SummarizableLinkGdoc {
  type: 'gdoc';
  docID: string;
}

/**
 * Parse a URL and return a SummarizableLink if it is summarizable.
 *
 * @param url URL to parse
 * @returns SummarizableLink, or undefined if url is not summarizable.
 */
export function getSummarizableLink(url: string): SummarizableLink | undefined {
  const contentID = urlToContentID(url);
  if (contentID.startsWith('paper:scl')) {
    return {
      type: 'paper:scl',
      url,
    };
  }
  if (contentID.startsWith('gdoc')) {
    // NOTE: this regex is similar to the one in urlToContentID but content IDs are case insensitive.
    // Google Doc API requires case sensitive IDs.
    const gdocRegex = /\/d\/(.+)\//;
    const matches = gdocRegex.exec(url);
    if (matches) {
      return {
        type: 'gdoc',
        docID: matches[1],
      };
    }
  }

  // Google Drive URLs look like this:
  // https://drive.google.com/open?id=[file-id]&authuser=0
  // https://drive.google.com/file/d/[file-id]/view
  if (url.toLowerCase().startsWith('https://drive.google.com/')) {
    const fileMatches = /\/d\/(.+)\//.exec(url);
    if (fileMatches) {
      return {
        type: 'gdoc',
        docID: fileMatches[1],
      };
    }
    const openMatches = /id=([-\w]+)/.exec(url);
    if (openMatches) {
      return {
        type: 'gdoc',
        docID: openMatches[1],
      };
    }
  }
  return undefined;
}
