// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2527
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'cancel.stacks_description'
// Description: Fires when a user clicks the Cancel button to cancel editing the stacks description
// Owner: dash-web
export type Cancel_StacksDescription = PAPEventGeneric<
  'stacks',
  'cancel',
  'stacks_description',
  {
    // The stack id that represents the stack
    stackId?: string;
    // marks if the content is shared content
    isShared?: boolean;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
  }
>;

export function PAP_Cancel_StacksDescription(
  properties?: Cancel_StacksDescription['properties'],
): Cancel_StacksDescription {
  return <Cancel_StacksDescription>{
    class: 'stacks',
    action: 'cancel',
    object: 'stacks_description',
    properties,
  };
}
