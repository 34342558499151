import { dash_feed, users } from '@dropbox/api-v2-client';
import { isStackActivityObject } from '@mirage/service-feed/util/helpers';
import { EditFileActivityAction } from './FileActivityAction';
import {
  AddStackItemActivityAction,
  EditStackActivityAction,
  RemoveStackItemActivityAction,
  ShareStackActivityAction,
} from './StacksActivityAction';

type ActivityActionProps = {
  activity: dash_feed.ActivityItem;
  currentAccount?: users.FullAccount;
  position: number;
};

export const ActivityAction = ({
  activity,
  currentAccount,
  position,
}: ActivityActionProps) => {
  const objectAction = activity.action?.object_action;
  switch (objectAction?.['.tag']) {
    case 'edit':
      return (
        <EditActivityAction
          currentAccount={currentAccount}
          activity={activity}
          action={objectAction}
          position={position}
        />
      );
    case 'share':
      return (
        <ShareActivityAction
          activity={activity}
          action={objectAction}
          position={position}
        />
      );
    case 'add_child':
      return (
        <AddChildActivityAction
          activity={activity}
          action={objectAction}
          position={position}
        />
      );
    case 'remove_child':
      return (
        <RemoveChildActivityAction
          activity={activity}
          action={objectAction}
          position={position}
        />
      );
    default:
      return null;
  }
};

type EditActivityActionProps = {
  activity: dash_feed.ActivityItem;
  action: dash_feed.object_action_unionEdit;
  currentAccount?: users.FullAccount;
  position: number;
};

const EditActivityAction = ({
  activity,
  action,
  currentAccount,
  position,
}: EditActivityActionProps) => {
  if (isStackActivityObject(activity)) {
    return (
      <EditStackActivityAction
        activity={activity}
        action={action}
        position={position}
      />
    );
  } else {
    return (
      <EditFileActivityAction
        currentAccount={currentAccount}
        activity={activity}
        action={action}
        position={position}
      />
    );
  }
};

type ShareActivityActionProps = {
  activity: dash_feed.ActivityItem;
  action: dash_feed.object_action_unionShare;
  position: number;
};

const ShareActivityAction = ({
  activity,
  action,
  position,
}: ShareActivityActionProps) => {
  if (isStackActivityObject(activity)) {
    return (
      <ShareStackActivityAction
        activity={activity}
        action={action}
        position={position}
      />
    );
  } else {
    return null; // currently only support stacks in share action
  }
};

type AddChildActivityActionProps = {
  activity: dash_feed.ActivityItem;
  action: dash_feed.object_action_unionAddChild;
  position: number;
};

const AddChildActivityAction = ({
  activity,
  action,
  position,
}: AddChildActivityActionProps) => {
  if (isStackActivityObject(activity)) {
    return (
      <AddStackItemActivityAction
        activity={activity}
        action={action}
        position={position}
      />
    );
  } else {
    // currently only support stacks in add child action
    return null;
  }
};

type RemoveChildActivityActionProps = {
  activity: dash_feed.ActivityItem;
  action: dash_feed.object_action_unionRemoveChild;
  position: number;
};

const RemoveChildActivityAction = ({
  activity,
  action,
  position,
}: RemoveChildActivityActionProps) => {
  if (isStackActivityObject(activity)) {
    return (
      <RemoveStackItemActivityAction
        activity={activity}
        action={action}
        position={position}
      />
    );
  } else {
    // currently only support stacks in remove child action
    return null;
  }
};
