import type { IconProps } from './IconProps';

function DashIcon({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.25 8.49967L16.4339 4.28564L19.7418 7.49996L3.5579 11.714L0.25 8.49967Z"
        fill="#1E1919"
      />
      <path
        d="M12.0245 0L3.93311 2.10757L7.23987 5.32188L15.3324 3.21431L12.0245 0Z"
        fill="#0061FE"
      />
      <path
        d="M4.66014 12.7855L12.7527 10.678L16.0594 13.8923L7.96804 15.9998L4.66014 12.7855Z"
        fill="#0061FE"
      />
    </svg>
  );
}

export default DashIcon;
