import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type { dash_connectors } from '@dropbox/api-v2-client';
import type {
  ConnectorConnections,
  Service,
} from '@mirage/service-connectors/service';

const service = services.get<Service>(ServiceId.DASH_CONNECTORS);

/**
 * Fetch connections from api server.
 * Prefer to use getCachedOrListConnections() instead if possible.
 */
export function listConnections(): Promise<dash_connectors.Connection[]> {
  return rx.firstValueFrom(service.listConnections());
}

/** This data is regularly refreshed on an interval. */
export function getCachedOrListConnections(): Promise<
  dash_connectors.Connection[]
> {
  return rx.firstValueFrom(service.getCachedOrListConnections());
}

/**
 * Fetch connectors from api server.
 * Prefer to use getCachedOrListConnectors() instead if possible.
 */
export function listConnectors(): Promise<dash_connectors.Connector[]> {
  return rx.firstValueFrom(service.listConnectors());
}

/** This data is regularly refreshed on an interval. */
export function getCachedOrListConnectors(): Promise<
  dash_connectors.Connector[]
> {
  return rx.firstValueFrom(service.getCachedOrListConnectors());
}

export function getCachedConnectorConnections(): Promise<ConnectorConnections> {
  return rx.firstValueFrom(service.getCachedConnectorConnections());
}

export function refreshConnectionsList(): Promise<void> {
  return rx.firstValueFrom(service.refreshConnectionsList());
}

export function startRefreshConnectionsJob(): Promise<void> {
  return rx.firstValueFrom(service.startRefreshConnectionsJob());
}

export function cancelRefreshConnectionsJob(): Promise<void> {
  return rx.firstValueFrom(service.cancelRefreshConnectionsJob());
}

export const listenForConnections = service.listenForConnections;
