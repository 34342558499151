// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2725
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'open.dash_connect_app_page_skip'
// Description: User clicked the “skip” button from onboarding page
// Owner: otc
export type Open_DashConnectAppPageSkip = PAPEventGeneric<
  'dash',
  'open',
  'dash_connect_app_page_skip',
  never
>;

export function PAP_Open_DashConnectAppPageSkip(
  properties?: Open_DashConnectAppPageSkip['properties'],
): Open_DashConnectAppPageSkip {
  return <Open_DashConnectAppPageSkip>{
    class: 'dash',
    action: 'open',
    object: 'dash_connect_app_page_skip',
    properties,
  };
}
