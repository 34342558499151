/**
 * Default settings for our framer-motion animations.
 */
export const MOTION_SETTINGS = {
  initial: 'collapsed',
  animate: 'open',
  exit: 'collapsed',
  // Transition params do not take in CSS variables, as they are JS triggered
  // animations, so we need to hardcode the values here.
  // var(--duration__micro) = 333ms
  // var(--easing__transition) = cubic-bezier(0.66, 0, 0, 1)
  transition: { duration: 0.333, ease: [0.66, 0, 0, 1] },
  variants: {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
  },
};
