import { getUserOnboardingState } from '@mirage/service-onboarding';

export const hasUserCompletedOnboarding = async (): Promise<boolean> => {
  const onboardingState = await getUserOnboardingState();

  return (
    !!onboardingState &&
    String(onboardingState).toLowerCase().endsWith(':complete')
  );
};
