import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Click_DashRenameLink } from '@mirage/analytics/events/types/click_dash_rename_link';
import { PAP_Delete_DashStack } from '@mirage/analytics/events/types/delete_dash_stack';
import { PAP_Leave_DashStack } from '@mirage/analytics/events/types/leave_dash_stack';
import { PAP_Pin_DashStack } from '@mirage/analytics/events/types/pin_dash_stack';
import { PAP_Unpin_DashStack } from '@mirage/analytics/events/types/unpin_dash_stack';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { tagged } from '@mirage/service-logging';
import {
  logPageLoadMilestone,
  logPageLoadMilestoneOnce,
} from '@mirage/service-operational-metrics/page-load';
import { useLastViewedStackInfo } from '@mirage/service-recent-content/hooks/useLastViewedStackInfo';
import { LastViewedStackInfo } from '@mirage/service-recent-content/types';
import {
  StackFilterOption,
  StackSortPreference,
} from '@mirage/service-settings/service/types';
import {
  deleteStack,
  leaveStack,
  listAllStackItemsFromCache,
  listAllStackItemsOneByOne,
  listStacksIncrementally,
  previewPublicStack,
  previewStack,
  subscribeToStackItemsUpdates,
  subscribeToStackMutation,
  subscribeToStacksUpdates,
  updateStackItem,
} from '@mirage/service-stacks';
import { StackMutationEvent } from '@mirage/service-stacks/service';
import {
  StackItem,
  StackItemShortcutWithMetadata,
} from '@mirage/service-stacks/service/types';
import {
  ALWAYS_TRUE,
  asShortcut,
  getPredicateFromFilterPreference,
  isStackItemCreator,
  isStackItemShortcut,
  sortStackItemsBySortKey,
  sortStacksByPinStatusAndHlc,
  sortStacksBySortOption,
  stackDerivePAPProps,
  stackForDiffing,
  stackGetShareId,
  stackItemComputeNameFromFields,
  stacksForDiffing,
} from '@mirage/service-stacks/service/utils';
import { metadataForUrls } from '@mirage/service-url-metadata';
import { UrlMetadata } from '@mirage/service-url-metadata/types';
import Sentry from '@mirage/shared/sentry';
import { showSnackbar } from '@mirage/shared/snackbar';
import { ONE_MINUTE_IN_MILLIS } from '@mirage/shared/util/constants';
import { sleepMs } from '@mirage/shared/util/tiny-utils';
import { useIsCompanyStacksEnabled } from '@mirage/stacks/useIsCompanyStacksEnabled';
import { mergeAndAggregateUniqueStacksByNamespaceId } from '@mirage/stacks/utils';
import i18n from '@mirage/translations';
import { useDeepCompareEffect, useDeepCompareMemo } from '@react-hookz/web';
import { atom, useAtom, useAtomValue } from 'jotai';
import { uniqBy } from 'lodash';
import isEqual from 'lodash/isEqual';
import partition from 'lodash/partition';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { dedupedAtom } from '../shared/util/jotai';
import { TabSuggestion } from './AddStackItemMenuContent/types';
import { isStackItemWithUrl, isStackOwner } from './Helpers/Utils';

import type {
  DropboxResponseError,
  stacks,
  users,
} from '@dropbox/api-v2-client/types';
import type { Subscription } from 'rxjs';

const logger = tagged('stacks-hooks');

// exported for testing only
export const unsortedStacksAtom = dedupedAtom<stacks.Stack[] | undefined>(
  undefined,
);

// exported for testing only
export const publishedContentsAtom = atom<stacks.PublishedContent[]>([]);

const allStackItemsAtom = dedupedAtom<
  { [nsid: string]: stacks.StackItem[] } | undefined
>(undefined);

const isDeleteStackModalOpenAtom = atom(false);

const stackToDeleteAtom = atom<stacks.Stack | undefined>(undefined);

const stackItemToEditAtom = atom<
  { stack: stacks.Stack; item: stacks.StackItemShortcut } | undefined
>(undefined);

export const useDeleteStackModal = (): {
  showDeleteConfirmationModal: boolean;
  setShowDeleteConfirmationModal: (x: boolean) => void;
  onDelete: () => void;
  setStackToDelete: (stack: stacks.Stack | undefined) => void;
  stackToDelete: stacks.Stack | undefined;
} => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useAtom(
    isDeleteStackModalOpenAtom,
  );
  const [stackToDelete, setStackToDelete] = useAtom(stackToDeleteAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();

  const navigate = useNavigate();
  const location = useLocation();

  const onDelete = () => {
    if (!stackToDelete || !stackToDelete.namespace_id) return;
    reportPapEvent(
      PAP_Delete_DashStack({
        ...stackDerivePAPProps(stackToDelete),
        featureLine: 'stacks',
      }),
    );
    // Don't navigate if we delete from the homepage or stacks page
    if (
      location.pathname !== '/' &&
      location.pathname !== '/home' &&
      location.pathname !== '/stacks'
    ) {
      navigate('/stacks');
    }
    deleteStack(stackToDelete.namespace_id);
  };

  return {
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
    onDelete,
    setStackToDelete,
    stackToDelete,
  };
};

export const useEditStackItemModal = (): {
  stackItemAndStackToEdit:
    | { stack: stacks.Stack; item: stacks.StackItemShortcut }
    | undefined;
  setStackItemAndStackToEdit: (
    input: { stack: stacks.Stack; item: stacks.StackItemShortcut } | undefined,
  ) => void;
  onEditItemAndStack: (
    item: stacks.StackItemShortcut,
    stack: stacks.Stack,
  ) => void;
} => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const [stackItemAndStackToEdit, setStackItemAndStackToEdit] =
    useAtom(stackItemToEditAtom);

  const onEditItemAndStack = useCallback(
    (item: stacks.StackItemShortcut, stack: stacks.Stack) => {
      reportPapEvent(
        PAP_Click_DashRenameLink({
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
        }),
      );
      setStackItemAndStackToEdit({ item, stack });
    },
    [reportPapEvent, setStackItemAndStackToEdit],
  );

  return {
    stackItemAndStackToEdit,
    setStackItemAndStackToEdit,
    onEditItemAndStack,
  };
};

export function useInitStacksData() {
  logPageLoadMilestoneOnce('useInitStacksData start');
  useInitUnsortedStacks();
  useInitStackItems();
}

function useInitUnsortedStacks() {
  const [stacks, setStacks] = useAtom(unsortedStacksAtom);

  // A local copy of the stacks data allow us to decouple the subscription
  // from the data update if changed.
  const [localStacks, setLocalStacks] = useState<stacks.Stack[]>();

  // Subscribe to updates.
  useEffect(() => {
    let canceled = false;

    logPageLoadMilestone(`useInitUnsortedStacks subscribeToStacksUpdates`);

    const stacksSubscription = subscribeToStacksUpdates((rawStacks) => {
      if (canceled || !rawStacks) return;

      // Set local stacks to be copied to the atom below.
      setLocalStacks(rawStacks);
    });

    return () => {
      canceled = true;
      stacksSubscription.unsubscribe();
    };
  }, []);

  // Fetch initial data.
  useEffect(() => {
    let canceled = false;

    async function init() {
      logPageLoadMilestone(
        `useInitUnsortedStacks listStacksIncrementally start`,
      );
      const rawStacks = await listStacksIncrementally();
      if (canceled) return;

      logPageLoadMilestone(
        `useInitUnsortedStacks setLocalStacks: numStacks=${rawStacks.length}`,
      );
      setLocalStacks(rawStacks);
    }

    void init();

    return () => {
      canceled = true;
    };
  }, []);

  // Copy local stacks to stacks atom. Do this in a separate useEffect from
  // above to avoid unnecessary sub/unsub. Note that we only copy from
  // localStacks to unsortedStacksAtom, but never the other way round.
  useEffect(() => {
    const oldStacks = stacksForDiffing(stacks);
    const newStacks = stacksForDiffing(localStacks);

    if (!isEqual(newStacks, oldStacks)) {
      logPageLoadMilestone(`useInitUnsortedStacks setStacks from localStacks`);
      setStacks(localStacks);
    }
  }, [stacks, localStacks, setStacks]);

  return stacks;
}

export function useSortedStacks() {
  const stacks = useUnsortedStacksWithCompanyStacks();
  return useMemo(() => stacks && sortStacksByPinStatusAndHlc(stacks), [stacks]);
}

export const STACK_IS_PINNED = (stack: stacks.Stack) =>
  stack.user_data?.is_pinned ?? false;

export const STACK_NOT_PINNED = (stack: stacks.Stack) =>
  !stack.user_data?.is_pinned;

export function usePinnedStacksWithItems() {
  return useStacksByPredicate(STACK_IS_PINNED);
}

export function useSortedStacksWithItems(
  predicate: (stack: stacks.Stack) => boolean = ALWAYS_TRUE,
) {
  const { stacks, stackItemsMap } = useStacksByPredicate(predicate);
  const sortedStacks = useMemo(
    () => stacks && sortStacksByPinStatusAndHlc(stacks),
    [stacks],
  );
  return { stacks: sortedStacks, stackItemsMap };
}

export function useLastViewedStacksWithItems(
  lastViewedInfo: LastViewedStackInfo,
  predicate: (stack: stacks.Stack) => boolean = ALWAYS_TRUE,
) {
  const { stacks, stackItemsMap } = useStacksByPredicate(predicate);
  const sortedStacks = useMemo(
    () =>
      stacks && sortStacksByPinStatusAndHlc(stacks, undefined, lastViewedInfo),
    [stacks, lastViewedInfo],
  );
  return { stacks: sortedStacks, stackItemsMap };
}

export function useSortedStacksWithItemsBySortOption(
  sortPreference?: StackSortPreference,
  filterPreference?: StackFilterOption,
) {
  const predicate = useMemo(
    () => getPredicateFromFilterPreference(filterPreference),
    [filterPreference],
  );

  // Use existing cached sorted stacks and items
  const { stacks, stackItemsMap } = useSortedStacksWithItems(predicate);
  const lastViewedStackInfo = useLastViewedStackInfo();

  // Pre-cache the stacks by the available sort options
  const sortedStacks = useMemo(
    () =>
      stacks &&
      sortStacksBySortOption(stacks, sortPreference, lastViewedStackInfo),
    [stacks, sortPreference, lastViewedStackInfo],
  );

  return {
    stacks: sortedStacks,
    stackItemsMap,
  };
}

export function useStackByNsId(namespaceId: string | undefined) {
  const nsMatches = useCallback(
    (stack: stacks.Stack) => stack.namespace_id === namespaceId,
    [namespaceId],
  );
  return useStackByPredicate(namespaceId, nsMatches);
}

type StackData = {
  stack: stacks.Stack | null;
  items: stacks.StackItem[];
  error?: Error | DropboxResponseError<stacks.PreviewPublicStackError>;
};

export function usePreviewPublicStack(shareId: string | undefined) {
  const [stackData, setStackData] = useState<StackData | null>(null);

  useEffect(() => {
    if (shareId === undefined) {
      setStackData({ stack: null, items: [] });
      return;
    }

    let canceled = false;

    async function getPublicStack() {
      let stackData: StackData = { stack: null, items: [] };
      try {
        stackData = await previewPublicStack(shareId as string);
      } catch (e) {
        logger.error('Failed to retrieve public stack preview data', e);
        stackData.error = e as
          | Error
          | DropboxResponseError<stacks.PreviewPublicStackError>;
      }

      if (!canceled) {
        setStackData(stackData);
      }
    }

    void getPublicStack();

    return () => {
      canceled = true;
    };
  }, [shareId]);

  return stackData;
}

export function useStackByShareId(shareId: string | undefined) {
  const shareIdMatches = useCallback(
    (stack: stacks.Stack) => stackGetShareId(stack) === shareId,
    [shareId],
  );
  return useStackByPredicate(shareId, shareIdMatches);
}

export function useStacksByNsIds(namespaceIds: string[]) {
  const predicate = useCallback(
    (stack: stacks.Stack) => namespaceIds.includes(stack.namespace_id ?? ''),
    [namespaceIds],
  );
  return useStacksByPredicate(predicate);
}

function useInitStackItems() {
  // const stacks = useUnsortedStacks(); // Note: don't use merged content yet, since we don't fetch company pinned stack, items
  const stacks = useUnsortedStacksWithCompanyStacks();
  const [stackItemsMap, setStackItemsMap] = useAtom(allStackItemsAtom);

  // A local copy of the stacks data allow us to decouple the subscription
  // from the data update if changed.
  const [localStackItemsMap, setLocalStackItemsMap] = useState<{
    [nsid: string]: stacks.StackItem[];
  }>();

  // Subscribe to new data.
  useEffect(() => {
    let canceled = false;

    logPageLoadMilestone(`useInitStackItems subscribeToStackItemsUpdates`);

    const stackItemsSubscription = subscribeToStackItemsUpdates(
      ({ namespaceId, items }) => {
        if (!canceled) {
          sortStackItemsBySortKey(items);

          setLocalStackItemsMap((oldMap) => {
            return { ...oldMap, [namespaceId]: items };
          });
        }
      },
    );

    return () => {
      canceled = true;
      stackItemsSubscription.unsubscribe();
    };
  }, []);

  // Copy local items to items atom. Do this in a separate useEffect from
  // above to avoid unnecessary sub/unsub.
  useEffect(() => {
    if (!isEqual(stackItemsMap, localStackItemsMap)) {
      logPageLoadMilestone(
        `useInitStackItems setStackItemsMap from localStackItemsMap`,
      );
      setStackItemsMap(localStackItemsMap);
    }
  }, [localStackItemsMap, setStackItemsMap, stackItemsMap]);

  // Fetch initial data.
  useEffect(() => {
    async function initStackItems() {
      const itemsByNsId = await listAllStackItemsFromCache();

      if (itemsByNsId !== undefined) {
        logPageLoadMilestone(
          `useInitStackItems setLocalStackItemsMap: numStacks=${
            Object.keys(itemsByNsId).length
          }`,
        );
        setLocalStackItemsMap(itemsByNsId);
      }
    }

    // Don't await, let it run in the background.
    void initStackItems();
  }, []);

  // Fetch new data on stacks changes.
  useEffect(() => {
    // We'll need to refetch data whenever the stacks change.
    if (stacks === undefined) return;

    // Don't await, let it run in the background.
    logPageLoadMilestone(`useInitStackItems listAllStackItemsOneByOne`);
    void listAllStackItemsOneByOne();
  }, [stacks]);

  return { stacks, stackItemsMap };
}

export function useStacksByPredicate(
  predicate: (stack: stacks.Stack) => boolean,
) {
  const sortedStacks = useSortedStacks();
  const [stacks, setStacks] = useState<stacks.Stack[] | undefined>(undefined);
  const stackItemsMap = useAtomValue(allStackItemsAtom);

  useEffect(() => {
    // Filtered stacks can be undefined
    const filteredStacks = sortedStacks?.filter(predicate);

    const oldStacks = stacksForDiffing(stacks);
    const newStacks = stacksForDiffing(filteredStacks);

    if (!isEqual(oldStacks, newStacks)) {
      setStacks(filteredStacks);
    }
  }, [predicate, sortedStacks, stacks]);

  return { stacks, stackItemsMap };
}

function useStackByPredicate(
  shareId: string | undefined,
  predicate: (stack: stacks.Stack) => boolean,
) {
  const stacks = useUnsortedStacksWithCompanyStacks();
  const stackItemsMap = useAtomValue(allStackItemsAtom);

  const [stack, setStack] = useState<stacks.Stack>();
  const [items, setItems] = useState<stacks.StackItem[]>();

  useEffect(() => {
    if (!shareId) {
      setStack(undefined);
      setItems([]);
      return;
    }

    let canceled = false;

    const rawStack = stacks?.find(predicate);

    if (!canceled) {
      const oldStack = stack ? stackForDiffing(stack) : undefined;
      const newStack = rawStack ? stackForDiffing(rawStack) : undefined;

      if (!isEqual(newStack, oldStack)) {
        setStack(rawStack);
      }
    }

    return () => {
      canceled = true;
    };
  }, [stacks, shareId, predicate, stack]);

  useEffect(() => {
    const newItems =
      shareId && stack?.namespace_id
        ? stackItemsMap?.[stack?.namespace_id]
        : undefined;

    if (!isEqual(newItems, items)) {
      setItems(newItems);
    }
  }, [shareId, items, stack, stackItemsMap]);

  return { stack, items };
}

export function useReportToggleStackIsPinned() {
  const { reportPapEvent } = useMirageAnalyticsContext();

  const reportToggleStackIsPinned = useCallback(
    (stack: stacks.Stack) => {
      if (stack.user_data?.is_pinned) {
        reportPapEvent(
          PAP_Unpin_DashStack({
            ...stackDerivePAPProps(stack),
            actionSurfaceComponent: 'stacks',
            featureLine: 'stacks',
          }),
        );
      } else {
        reportPapEvent(
          PAP_Pin_DashStack({
            ...stackDerivePAPProps(stack),
            actionSurfaceComponent: 'stacks',
            featureLine: 'stacks',
          }),
        );
      }
    },
    [reportPapEvent],
  );

  return { reportToggleStackIsPinned };
}

export function useLeaveStack(
  stack: stacks.Stack | null,
  currentAccount: users.FullAccount | undefined,
) {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const navigate = useNavigate();

  const onLeaveStack = useCallback(async () => {
    if (!currentAccount || !stack) return;

    reportPapEvent(
      PAP_Leave_DashStack({
        ...stackDerivePAPProps(stack),
        featureLine: 'stacks',
      }),
    );

    // Don't navigate if we leave from the homepage
    if (
      location.pathname !== '/' &&
      location.pathname !== '/home' &&
      location.pathname !== '/stacks'
    ) {
      showSnackbar({ title: i18n.t('leave_stack') });
      navigate('/stacks');
    }

    leaveStack(stack.namespace_id as string, currentAccount.email);
  }, [currentAccount, navigate, reportPapEvent, stack]);

  if (!currentAccount || !stack?.namespace_id || isStackOwner(stack)) {
    return undefined;
  }

  return onLeaveStack;
}

export const useStackMutationForRequestId = (requestId: string) => {
  const [tempStack, setTempStack] = useState<stacks.Stack | null>(null);
  const [tempStackItems, setTempStackItems] = useState<stacks.StackItem[]>([]);
  const [createdNamespaceId, setCreatedNamespaceId] = useState<string | null>(
    null,
  );

  const resetMutationState = useCallback(() => {
    setTempStack(null);
    setTempStackItems([]);
    setCreatedNamespaceId(null);
  }, []);

  useEffect(() => {
    const handleStackMutation = (event: StackMutationEvent) => {
      if (event.requestId !== requestId) {
        return; // Ignore unrelated events
      }

      if (event.eventType === 'optimisticEdit') {
        setTempStack(event.stack);
        setTempStackItems(event.stackItemsForStack);
      } else if (event.eventType === 'stackCreated') {
        setCreatedNamespaceId(event.namespaceId);
      }
    };

    const subscription = subscribeToStackMutation(handleStackMutation);

    return () => {
      // Cleanup subscription when the component unmounts or requestId changes
      subscription.unsubscribe();
    };
  }, [requestId]);

  return {
    tempStack,
    tempStackItems,
    createdNamespaceId,
    resetMutationState,
  };
};

export const useDefaultItemsToAdd = (
  stackItems: stacks.StackItemShortcut[] | null,
  contentSuggestions: TabSuggestion[],
): TabSuggestion[] => {
  return useMemo(() => {
    const itemUrls = stackItems
      ? new Set(stackItems.map((item) => item.url))
      : new Set();
    const itemTitles = stackItems
      ? new Set(stackItems.map((item) => stackItemComputeNameFromFields(item)))
      : new Set();

    return contentSuggestions
      ? uniqBy(
          contentSuggestions.filter(
            (suggestion) =>
              !(
                itemUrls.has(suggestion.url ?? '') ||
                itemTitles.has(suggestion.title ?? '')
              ),
          ),
          (suggestion) => suggestion.url,
        )
      : [];
  }, [contentSuggestions, stackItems]);
};

interface ProcessedItems {
  hydratedItem: StackItemShortcutWithMetadata;
  updatedItem: stacks.StackItem | null;
}

export const useEnrichedStackItems = (
  stackNamespaceId?: string,
  stackItems?: stacks.StackItem[],
) => {
  const linkEnrichmentGate = useFeatureFlagValue(
    'dash_2024_07_29_link_enrichment',
    false /* includeExposureLogging */,
  );
  const linkEnrichmentEnabled = convertFeatureValueToBool(linkEnrichmentGate);

  const [hydratedItems, setHydratedItems] = useState<StackItem[]>([]);
  const currentAccount = useDropboxAccount();
  const isUpdatingItems = useRef(false);

  const [urlItems, nonUrlItems] = useDeepCompareMemo(
    () =>
      partition(
        stackItems,
        (item) => isStackItemShortcut(item) && isStackItemWithUrl(item),
      ),
    [stackItems],
  );

  const urlsToHydrate = useDeepCompareMemo(
    () => urlItems.map((item) => item.url.split('#')[0]),
    [urlItems],
  );

  const processItem = useCallback(
    (
      item: stacks.StackItemShortcut & {
        url: string;
      },
      itemMetadata: UrlMetadata,
    ): ProcessedItems => {
      // Ensure metadata exists for the item
      if (!itemMetadata) {
        return {
          hydratedItem: item,
          updatedItem: null,
        };
      }

      const itemOwner = isStackItemCreator(currentAccount?.email, item);

      // If the item is owned by the current user, use the metadata title
      // returned from `metadataForUrls`. Otherwise, use the metadata title
      // that is on the item server side.
      // There is a gap here with favicons and thumbnails, as we don't store
      // them on the server side. We should consider storing them in the future.
      // If we had that data server side, we would filter out all unowned stack
      // items from this processing step.
      const metadataTitle = itemOwner
        ? itemMetadata?.title
        : item.metadata_title;

      const hydratedItem: StackItemShortcutWithMetadata = {
        ...item,
        metadata_title: metadataTitle,
        faviconUrl: itemMetadata?.faviconUrl,
        thumbnailUrl: itemMetadata?.thumbnailUrl,
      };

      let updatedItem: ProcessedItems['updatedItem'] = null;
      // If the metadata title is different from the existing title, update the stack item/
      // Only update the item if the user is the owner of the item.
      if (itemOwner && metadataTitle && metadataTitle !== item.metadata_title) {
        updatedItem = {
          ...item,
          metadata_title: metadataTitle,
        };
      }

      // If the contentLastModified exists and is newer than item.last_modified_time_utc_sec,
      // update the item. We just set the metadata title to the same value to force the last modified update.
      else if (
        itemOwner &&
        itemMetadata.contentLastModifiedMs &&
        itemMetadata.contentLastModifiedMs >
          (item.last_modified_time_utc_sec ?? 0) * 1000
      ) {
        updatedItem = {
          ...item,
          metadata_title: metadataTitle,
        };
      }

      return { hydratedItem, updatedItem };
    },
    [currentAccount?.email],
  );

  const hydrateItems = useCallback(
    async (metadataMap: { [key: string]: UrlMetadata }) => {
      if (!stackNamespaceId) {
        return;
      }

      const processedItems = urlItems.map((item) =>
        processItem(item, metadataMap[item.url.split('#')[0]]),
      );

      const itemsToUpdate = processedItems.filter(
        (data): data is ProcessedItems & { updatedItem: stacks.StackItem } =>
          data.updatedItem !== null,
      );

      isUpdatingItems.current = true;
      for (const { updatedItem } of itemsToUpdate) {
        try {
          await updateStackItem(stackNamespaceId, updatedItem);
        } catch (e) {
          // DASHWEB-4155: Let's see why this is happening (temporary)
          Sentry.withScope((scope) => {
            const stackItemUrl =
              asShortcut(updatedItem)?.url || 'UNDEFINED_URL';
            // Set the extra data
            scope.setContext('update_stack_item error context', {
              stackItemUrl,
              originalException: e,
            });
            scope.setTag('stackItemUrl', stackItemUrl);

            // Capture the message
            Sentry.captureMessage(
              '[hydrateItems] update_stack_item FAILED',
              'error',
              { originalException: e },
              scope,
            );
          });
        }
        // Sleep to avoid rate limiting on update endpoint
        await sleepMs(100);
      }
      isUpdatingItems.current = false;

      const newHydratedItems = processedItems.map((data) => data.hydratedItem);
      const allItems: StackItem[] = nonUrlItems.concat(newHydratedItems);
      setHydratedItems(allItems);
    },
    [nonUrlItems, stackNamespaceId, urlItems, processItem],
  );

  useDeepCompareEffect(() => {
    if (!linkEnrichmentEnabled) {
      return;
    }

    const fetchAndHydrate = (): Subscription | undefined => {
      // If we are currently updating items, don't fetch metadata
      if (isUpdatingItems.current) {
        return;
      }
      const sub = metadataForUrls(urlsToHydrate).subscribe(hydrateItems);
      return sub;
    };

    let sub = fetchAndHydrate();

    // // Set up interval for refreshing every 2 minutes
    const intervalId = setInterval(() => {
      if (sub) {
        sub.unsubscribe(); // Unsubscribe from the previous subscription
      }
      sub = fetchAndHydrate(); // Create a new subscription
    }, ONE_MINUTE_IN_MILLIS * 2);

    return () => {
      clearInterval(intervalId);
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [urlsToHydrate, hydrateItems, linkEnrichmentEnabled]);

  useDeepCompareEffect(() => {
    // Reset hydratedItems every time stackItems changes
    setHydratedItems(stackItems ?? []);

    // Set constructed stack items to be stackItems if gate is off
    if (!linkEnrichmentEnabled) {
      setHydratedItems(stackItems ?? []);
    }
  }, [linkEnrichmentEnabled, stackItems]);

  return hydratedItems;
};

const isPublishedStack = (
  publishedContent: stacks.PublishedContent,
): publishedContent is stacks.PublishedContentStack =>
  publishedContent?.['.tag'] === 'stack' &&
  publishedContent.publish_data?.is_published === true;

export const filteredPublishedContentsAtom = atom((get) =>
  get(publishedContentsAtom).filter(isPublishedStack),
);

// Feature gated hook that will return company stacks along with a user's own stacks w./ merged data
// will return just user's unsorted
export function useUnsortedStacksWithCompanyStacks() {
  const isCompanyStacksEnabled = useIsCompanyStacksEnabled();
  const unsortedStacks = useAtomValue(unsortedStacksAtom); // non-published stacks
  const companyStacks = useAtomValue(filteredPublishedContentsAtom);

  // combines user stacks and company pinned stacks
  const mergedStacks = useMemo(() => {
    return mergeAndAggregateUniqueStacksByNamespaceId(
      unsortedStacks || [],
      companyStacks,
    );
  }, [unsortedStacks, companyStacks]);

  return isCompanyStacksEnabled ? mergedStacks : unsortedStacks;
}

const STACK_IS_WELCOME_STACK = (stack: stacks.Stack) =>
  stack?.stack_data?.creation_type?.['.tag'] === 'welcome_stack';

export function useWelcomeStacks() {
  const stacks = useAtomValue(unsortedStacksAtom) || [];
  return stacks.filter(STACK_IS_WELCOME_STACK);
}

export function useHydrateCompanyStacksPreviewData() {
  const unsortedStacks = useAtomValue(unsortedStacksAtom);
  const isCompanyStacksEnabled = useIsCompanyStacksEnabled();

  const callPreviewStacksForNotCached = useCallback(
    (contents: stacks.PublishedContent[]) => {
      if (!isCompanyStacksEnabled) {
        return;
      }
      contents?.forEach((c) => {
        if (c['.tag'] === 'stack' && c.namespace_id) {
          const shareId = stackGetShareId(c);
          const stack = unsortedStacks?.find(
            (s) => s.namespace_id === c.namespace_id,
          );
          if (!stack && shareId) {
            previewStack(shareId, { refresh: true, fetchInBackground: true });
          }
        }
      });
    },
    [isCompanyStacksEnabled, unsortedStacks, stackGetShareId, previewStack],
  );

  return { callPreviewStacksForNotCached };
}
