// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2525
import type { PAPEventGeneric } from '../base/event';
import type { FeatureLine } from '../enums/feature_line';

// Event_name: 'click.dash_new_link'
// Description: Fires when a user clicks the add items button to add items to a stack
// Owner: dash-web
export type Click_DashNewLink = PAPEventGeneric<
  'stacks',
  'click',
  'dash_new_link',
  {
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // Whether the user is interacting with their welcome stack
    isWelcomeStack?: boolean;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // unique session id for creating stacks on dash
    createStackSessionId?: string;
    // This is a session that persists when the Add to Stack button is currently being displayed and ends when a link has been added, or the process is cancelled by clicking away, or page is closed.
    dashNewLinkSessionId?: string;
  }
>;

export function PAP_Click_DashNewLink(
  properties?: Click_DashNewLink['properties'],
): Click_DashNewLink {
  return <Click_DashNewLink>{
    class: 'stacks',
    action: 'click',
    object: 'dash_new_link',
    properties,
  };
}
