import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Cancel_DashRenameLink } from '@mirage/analytics/events/types/cancel_dash_rename_link';
import { PAP_Click_DashRenameLink } from '@mirage/analytics/events/types/click_dash_rename_link';
import { PAP_Rename_DashLink } from '@mirage/analytics/events/types/rename_dash_link';
import { PAP_View_DashStackDetails } from '@mirage/analytics/events/types/view_dash_stack_details';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { publishEvent } from '@mirage/service-feedback';
import { SurveyEvent } from '@mirage/service-feedback/types';
import { markDashUserStackRecipientCompleteIfNecessary } from '@mirage/service-onboarding';
import {
  MetricPageName,
  StackDetailsModule,
} from '@mirage/service-operational-metrics/module/constants';
import {
  perfTimeSinceLastNavigateAtom,
  useRecordModuleLatency,
} from '@mirage/service-operational-metrics/module/module';
import {
  getStacksDataCachedTags,
  useStacksDataCachedTags,
} from '@mirage/service-operational-metrics/module/tags';
import { useReportViewedStack } from '@mirage/service-recent-content/hooks/useLastViewedStackInfo';
import { updateStackItem } from '@mirage/service-stacks';
import {
  DEFAULT_SECTION_ID,
  stackDerivePAPProps,
  stackGetShareId,
  stackItemGetName,
  stackItemSetName,
} from '@mirage/service-stacks/service/utils';
import { showSnackbar } from '@mirage/shared/snackbar';
import { TwoColumnGrid } from '@mirage/shared/two-column-grid/TwoColumnGrid';
import {
  activeStackAtom,
  activeStackHasWritePermissionsAtom,
  activeStackItemsAtom,
  activeStackItemShortcutsAtom,
  activeStackMutationRequestIdAtom,
  activeStackSearchItemsAtom,
  activeStackSearchItemsNoResultsQueryAtom,
  activeStackSectionsAtom,
  activeStackSessionIdAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import i18n from '@mirage/translations';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AddStackItemBoxAugRev } from '../AddStackItemMenuContent/AddStackItemBox';
import { EditStackItemModal } from '../EditStackItemModal';
import { isStackOwner } from '../Helpers/Utils';
import { useSortedStacks } from '../hooks';
import Actions, { HeaderActions, ShareStackButton } from './Actions';
import { EmptyStateBox } from './Connectors/EmptyStateBox';
import { DndProvider } from './DragAndDrop/Provider';
import styles from './FullScreenStack.module.css';
import Header from './Header';
import { useAddLinkToStack } from './hooks';
import { SharingModule } from './Modules/SharingModule';
import { PasteStackItemAnywhere } from './PasteStackItemAnywhere';
import { AddItemButtonAugRev } from './Sections/AddItemButton';
import {
  useContentSuggestions,
  useOpenSuggestedLink,
  useSectionsNavigation,
  useShownContentSuggestions,
} from './Sections/hooks';
import { Navigation } from './Sections/Navigation';
import { Sections } from './Sections/Sections';
import { SectionsHeader } from './Sections/SectionsHeader';
import { Suggestions } from './Sections/Suggestions';
import { StackDetailsTourTooltip } from './StackDetailsTourTooltip';
import { UserInfo } from './types';

import type { stacks, users } from '@dropbox/api-v2-client/types';

type FullScreenStackV2Props = {
  currentAccount: users.FullAccount;
  mutationRequestId?: string;
  sessionId?: string;
  setShowSharingModule: Dispatch<SetStateAction<boolean>>;
  setShowSuggestions: Dispatch<SetStateAction<boolean>>;
};

export default function FullScreenStackV2({
  mutationRequestId,
  sessionId,
  currentAccount,
  setShowSharingModule,
  setShowSuggestions,
}: FullScreenStackV2Props) {
  const { reportPapEvent } = useMirageAnalyticsContext();

  const [stackItemToEdit, setStackItemToEdit] = useState<
    stacks.StackItemShortcut | undefined
  >(undefined);

  const isAugRev = useStackPageAugustRevisionEnabled();

  const hasSentViewEventForStack = useRef<string | undefined>(undefined);
  const hasSentViewEventForStackItems = useRef<string | undefined>(undefined);
  const { scrollToSection, resetSectionRefs } = useSectionsNavigation();
  const setMutationRequestId = useSetAtom(activeStackMutationRequestIdAtom);
  const setSessionId = useSetAtom(activeStackSessionIdAtom);
  // used to determine whether to show empty state when search is active
  const searchResults = useAtomValue(activeStackSearchItemsAtom);

  const triggerEditStackRef = useRef<HTMLInputElement>(null);
  const triggerAddToStackRef = useRef<HTMLDivElement>(null);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const stack = useAtomValue(activeStackAtom);
  const items = useAtomValue(activeStackItemsAtom);
  const itemShortcut = useAtomValue(activeStackItemShortcutsAtom);
  const { defaultSection, otherSections } = useAtomValue(
    activeStackSectionsAtom,
  );
  const defaultUserInfo: UserInfo = {
    displayName: currentAccount.name.display_name,
    profilePhotoUrl: currentAccount.profile_photo_url,
  };
  const { contentSuggestions, isFetchingContentSuggestions } =
    useContentSuggestions();
  const handleShowContentSuggestions = useShownContentSuggestions();
  const handleOpenSuggestedLink = useOpenSuggestedLink();
  const addLinkToStack = useAddLinkToStack();
  const activeStackSearchItemsNoResultsQuery = useAtomValue(
    activeStackSearchItemsNoResultsQueryAtom,
  );

  const tags = useStacksDataCachedTags();
  const { markModuleLoadComplete } = useRecordModuleLatency(
    MetricPageName.STACK_DETAILS,
    StackDetailsModule.VIEW_SHARED_STACK,
    tags || getStacksDataCachedTags,
    /* excludeFromE2E= */ true,
  );
  const perfTimeSinceLastNavigate = useAtomValue(perfTimeSinceLastNavigateAtom);
  const isFirstPageLoad = perfTimeSinceLastNavigate === 0;

  const allStacks = useSortedStacks();

  useEffect(() => {
    setTooltipOpen(false);

    // The tooltip relies on Tooltip.Control, which does not automatically move when the UI Refreshes
    // Closing and opening it manually resolves this issue.
    setTimeout(() => {
      setTooltipOpen(true);
    }, 500);
  }, [stack?.namespace_id]);

  useEffect(() => {
    setMutationRequestId(mutationRequestId || null);
  }, [mutationRequestId, setMutationRequestId]);

  useEffect(() => {
    setSessionId(sessionId ?? null);
  }, [sessionId, setSessionId]);

  // Note that this will become false right as the stack is created,
  // so this is not a reliable way to determine if you're on the new stack creation page
  const isNewStack = !stack?.namespace_id || !items;

  const hasWritePermissions = useAtomValue(activeStackHasWritePermissionsAtom);
  const isOwner = stack ? isStackOwner(stack) : false;

  const renameStackItem = useCallback(
    async (itemToEdit: stacks.StackItem, newName: string) => {
      if (isNewStack) {
        return;
      }

      const namespaceId = stack.namespace_id;

      reportPapEvent(
        PAP_Rename_DashLink({
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
        }),
      );

      await updateStackItem(
        namespaceId ?? '',
        stackItemSetName(itemToEdit, newName),
      );
    },
    [isNewStack, reportPapEvent, stack],
  );

  const onEditItem = (item: stacks.StackItemShortcut) => {
    if (isNewStack) return;
    reportPapEvent(
      PAP_Click_DashRenameLink({
        ...stackDerivePAPProps(stack),
        featureLine: 'stacks',
      }),
    );
    setStackItemToEdit(item);
  };

  useEffect(() => {
    resetSectionRefs(stack?.namespace_id ?? '');
  }, [stack?.namespace_id, resetSectionRefs]);

  useEffect(() => {
    if (!isOwner) {
      markDashUserStackRecipientCompleteIfNecessary();
    }
  }, [isOwner]);

  useReportViewedStack(stack?.namespace_id);

  useEffect(() => {
    if (isNewStack) return;
    if (
      hasSentViewEventForStack.current === stack.namespace_id ||
      hasSentViewEventForStackItems.current === items.toString()
    ) {
      return;
    }
    if (stack.namespace_id) {
      reportPapEvent(
        PAP_View_DashStackDetails({
          ...stackDerivePAPProps(stack),
          stackSize: items.length,
          featureLine: 'stacks',
        }),
      );
      // Publish Sprig event for non-new stacks
      publishEvent(SurveyEvent.OpenStackPage);
    }
    hasSentViewEventForStack.current = stack.namespace_id;
    hasSentViewEventForStackItems.current = items.toString();
  }, [reportPapEvent, stack, items, isNewStack]);

  const showSuggestions = hasWritePermissions;

  useEffect(() => {
    setShowSuggestions(showSuggestions ?? false);
  }, [showSuggestions, setShowSuggestions]);

  const showSharingModule =
    hasWritePermissions && stack && stackGetShareId(stack);

  useEffect(() => {
    setShowSharingModule(!!showSharingModule);
  }, [showSharingModule, setShowSharingModule]);

  useEffect(() => {
    // Log only for first page loads. If we log for navigates after page loads,
    // the latency will be very small, and hence is not really worth measuring.
    if (isNewStack || !stack || isOwner || !isFirstPageLoad) return;

    markModuleLoadComplete();
  }, [isFirstPageLoad, isNewStack, isOwner, markModuleLoadComplete, stack]);

  return (
    <div className={styles.containerV2}>
      <DndProvider>
        <TwoColumnGrid
          version={isAugRev ? 'august' : 'standard'}
          mainRowGap="0"
          sidebarRowGap={isAugRev ? 'Micro Medium' : undefined}
          headerChildren={
            <>
              <Header
                stack={stack}
                editData={{
                  mutationRequestId,
                  sessionId,
                  canEdit: hasWritePermissions ?? false,
                }}
                triggerEditStackRef={triggerEditStackRef}
                defaultUserInfo={defaultUserInfo}
                withImmediateAction={
                  isAugRev ? (
                    <HeaderActions
                      stack={stack}
                      currentAccount={currentAccount}
                      menuPlacement="bottom-end"
                      mutationRequestId={mutationRequestId}
                      sessionId={sessionId}
                    />
                  ) : (
                    <Actions
                      showShareLabeledIcon
                      stack={stack}
                      currentAccount={currentAccount}
                      menuPlacement="bottom-end"
                      triggerEditStackRef={
                        hasWritePermissions ? triggerEditStackRef : undefined
                      }
                      mutationRequestId={mutationRequestId}
                      sessionId={sessionId}
                    />
                  )
                }
                additionalContainerStyles={
                  isAugRev ? styles.headerBottomSpacing : undefined
                }
                withRightAction={
                  isAugRev && (
                    <>
                      {!isNewStack && (
                        <ShareStackButton
                          stack={stack}
                          disabled={!currentAccount}
                        />
                      )}
                      {hasWritePermissions && defaultSection && (
                        <AddItemButtonAugRev
                          align="right"
                          variant="primary"
                          contentSuggestions={contentSuggestions}
                          loadingSuggestions={isFetchingContentSuggestions}
                          section={defaultSection}
                          // Emphasize add button when there are no stack items yet.
                          emphasize={!items || items.length === 0}
                        />
                      )}
                    </>
                  )
                }
              />
              {!isAugRev && (
                <SectionsHeader namespaceId={stack?.namespace_id ?? ''} />
              )}
            </>
          }
          sidebarChildren={
            <>
              <Navigation scrollToSection={scrollToSection} />
              {showSharingModule && <SharingModule stack={stack} />}
              {!isAugRev && showSuggestions && <Suggestions />}
            </>
          }
          mainChildren={
            // Need to manually manage gap for main children due to DND empty sections
            <>
              {isAugRev && (
                <SectionsHeader namespaceId={stack?.namespace_id ?? ''} />
              )}
              {items?.length || searchResults !== null ? (
                <Sections
                  namespaceId={stack?.namespace_id ?? ''}
                  onEditItem={onEditItem}
                />
              ) : (
                <>
                  <EmptyStateBox
                    hasWritePermissions={hasWritePermissions ?? false}
                  />
                  {otherSections && otherSections?.length > 0 && (
                    <Sections
                      namespaceId={stack?.namespace_id ?? ''}
                      onEditItem={onEditItem}
                    />
                  )}
                </>
              )}
              {isAugRev && hasWritePermissions && defaultSection && (
                <div className={styles.addBoxWrapper}>
                  <AddStackItemBoxAugRev
                    // Force re-render when the stack changes
                    key={stack?.namespace_id}
                    stack={stack}
                    existingItems={itemShortcut}
                    sectionIdToAddItem={defaultSection.id || DEFAULT_SECTION_ID}
                    contentSuggestions={contentSuggestions}
                    loadingSuggestions={isFetchingContentSuggestions}
                    addLinkToStack={addLinkToStack}
                    createStackSessionId={sessionId || ''}
                    includeHeader
                    onOpenLink={handleOpenSuggestedLink}
                    onShowLinks={handleShowContentSuggestions}
                    ref={triggerAddToStackRef}
                    focusOnLoad
                    recommendedEmptyState
                    providedInputString={
                      activeStackSearchItemsNoResultsQuery || undefined
                    }
                  />
                  <StackDetailsTourTooltip
                    open={tooltipOpen}
                    stepKey="add-more-files"
                    triggerRef={triggerAddToStackRef}
                  />
                </div>
              )}
            </>
          }
        />
      </DndProvider>
      {stackItemToEdit && (
        <EditStackItemModal
          originalStackItemName={stackItemGetName(stackItemToEdit, allStacks)}
          isOpen={true}
          onCancel={() => {
            if (!isNewStack) {
              reportPapEvent(
                PAP_Cancel_DashRenameLink({
                  ...stackDerivePAPProps(stack),
                  featureLine: 'stacks',
                }),
              );
            }
            setStackItemToEdit(undefined);
          }}
          onSubmit={async (data: { name?: string }) => {
            if (!stackItemToEdit || !data.name || !items) return;

            const itemToEdit = items.find(
              (item) =>
                item['.tag'] === 'shortcut' &&
                item.api_file_id === stackItemToEdit.api_file_id,
            );

            if (!itemToEdit) return;

            renameStackItem(itemToEdit, data.name);
            setStackItemToEdit(undefined);
            showSnackbar({ title: i18n.t('stack_item_updated') });
          }}
        />
      )}
      {hasWritePermissions && <PasteStackItemAnywhere />}
    </div>
  );
}
