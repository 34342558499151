// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1017
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'finish.dash_login'
// Description: Triggered when:
// * a logged-in user starts the Dash process
// ** manually starting Dash, e.g. from Start Menu or Finder
// ** automatically starting Dash, e.g. on computer reboot
// * a new user completes the sign-in flow
// NOT TRIGGERED when a user opens a hidden dash window (e.g. by pressing Command+E)
// Owner: connector-foundations
export type Finish_DashLogin = PAPEventGeneric<
  'dash',
  'finish',
  'dash_login',
  {
    // Whether a user is returning from a logged out session (rather than a new user).
    returningUser?: boolean;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
  }
>;

export function PAP_Finish_DashLogin(
  properties?: Finish_DashLogin['properties'],
): Finish_DashLogin {
  return <Finish_DashLogin>{
    class: 'dash',
    action: 'finish',
    object: 'dash_login',
    properties,
  };
}
