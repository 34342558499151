import useSettings from './useSettings';

export function useStackItemCollapseDescriptionSettings() {
  const { settings, set: setSettings } = useSettings([
    'stackItemByIdCollapseDescription',
  ]);
  const stackItemCollapseDesriptions =
    settings?.stackItemByIdCollapseDescription ?? {};

  const setCollapseDescription = (apiFileId: string, state?: boolean) => {
    const updatedStackItemCollapseDescriptions = {
      ...stackItemCollapseDesriptions,
    };

    if (state !== undefined) {
      updatedStackItemCollapseDescriptions[apiFileId] = state;
    } else {
      delete updatedStackItemCollapseDescriptions[apiFileId];
    }

    setSettings(
      'stackItemByIdCollapseDescription',
      updatedStackItemCollapseDescriptions,
    );
  };

  const isDescriptionCollapsed = (apiFileId: string | undefined) => {
    if (apiFileId === undefined) {
      return true;
    }

    return typeof stackItemCollapseDesriptions[apiFileId] === 'boolean'
      ? stackItemCollapseDesriptions[apiFileId]
      : false;
  };

  return {
    isDescriptionCollapsed,
    setCollapseDescription,
    isReady: settings !== null,
  };
}
