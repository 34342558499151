import './GlobalScrollbar.module.css';

import EventLog from '@mirage/analytics/components/EventLog';
import {
  useAllStacksAugustRevisionEnabled,
  useIsStartPageAugustRevisionEnabled,
  useStackPageAugustRevisionEnabled,
} from '@mirage/august-revision-hook';
import { useComponentConfig } from '@mirage/component-config';
import {
  LaunchKeyboardShortcutBanner,
  shouldShowLaunchShortcutBanner,
} from '@mirage/education/LaunchKeyboardShortcutBanner';
import { ChecklistButton, OpenTypeaheadAtom } from '@mirage/growth';
import useOnboardingChecklist from '@mirage/growth/onboarding/getting-started-checklist/useOnboardingChecklist';
import { GlobalBanners } from '@mirage/mosaics/GlobalBanners';
import {
  focusSearchInput,
  KEYBOARD_NAV_SCROLL_CONTAINER_ID,
} from '@mirage/mosaics/GlobalNav/KeyboardNavigation';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import keymapActionsService from '@mirage/service-keymap-actions';
import { useHotkeys } from '@mirage/service-keymap-actions/useHotkeys';
import { useOnboardingValues } from '@mirage/service-onboarding/hooks';
import { logPageLoadMilestoneOnce } from '@mirage/service-operational-metrics/page-load';
import { usePageScrollListener } from '@mirage/shared/hooks/usePageScrollListener';
import { useSetCurrentLocation } from '@mirage/shared/location/atomHooks';
import { useSidebarWidth } from '@mirage/shared/responsive/breakpoints';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { showSnackbar } from '@mirage/shared/snackbar';
import { USER_AGENT_INFO } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FlyoutPanel } from '../FlyoutPanel/FlyoutPanel';
import { useFlyoutPanel } from '../FlyoutPanel/useFlyoutPanel';
import { GlobalModalContainer } from '../GlobalModalContainer';
import { globalNavIsMobileAtom } from '../GlobalNav/atoms';
import { GlobalNav } from '../GlobalNav/GlobalNav';
import { TopNav } from '../TopNav';
import styles from './Layout.module.css';

import type { BetaBadgePosition } from '../GlobalNav/GlobalNav';

type LayoutProps = {
  globalNavAlwaysCollapsed: boolean;
  betaBadgePosition?: BetaBadgePosition;
  hideTopNav?: boolean;
  children: React.ReactNode;
};

const HOME_ROUTE = '/';

export const Layout = ({
  globalNavAlwaysCollapsed,
  betaBadgePosition = 'none',
  hideTopNav,
  children,
}: LayoutProps) => {
  logPageLoadMilestoneOnce('Layout start');

  const { platform, surface } = EnvCtx;
  const sidebarWidth = useSidebarWidth();
  const isMobileSize = useIsMobileSize();
  useSetCurrentLocation();
  const navigate = useNavigate();
  const onboardingProps = useOnboardingChecklist();
  const { onboardingValues } = useOnboardingValues();
  const { pathname } = useLocation();
  const { homeOnEscape, analyticsLogsOpen, toggleAnalyticsLogs } =
    useComponentConfig();
  const childPageRef = React.useRef<HTMLDivElement>(null);
  usePageScrollListener(childPageRef);
  const globalNavIsMobile = useAtomValue(globalNavIsMobileAtom);
  const { closeFlyoutPanel, flyoutPanelView } = useFlyoutPanel();
  const location = useLocation();

  const isAugustStackPageEnabled = useStackPageAugustRevisionEnabled();
  const isAugustStartPageEnabled = useIsStartPageAugustRevisionEnabled();
  const isAugustAllStacksPageEnabled = useAllStacksAugustRevisionEnabled();
  const augustRevisionEnabled =
    isAugustStackPageEnabled ||
    isAugustStartPageEnabled ||
    isAugustAllStacksPageEnabled;

  const iOSOrAndroid =
    // iPhone/iPad can be tested on the Mac iOS xcode emulator easily.
    USER_AGENT_INFO.os.name === 'iOS' ||
    USER_AGENT_INFO.device.model === 'iPad' ||
    // Seems like Androids have the same problem for some reason.
    USER_AGENT_INFO.os.name === 'Android';

  const handleCheckForUpdates = () => {
    showSnackbar({
      title: i18n.t('checking_for_updates'),
    });
    keymapActionsService.checkForUpdates();
  };

  const handleEscape = () => {
    if (flyoutPanelView !== 'closed') {
      closeFlyoutPanel();
      focusSearchInput();
      return;
    }
    if (pathname !== HOME_ROUTE && homeOnEscape) {
      navigate(HOME_ROUTE);
      return;
    }
    keymapActionsService.escape();
  };

  const showLaunchShortcutBanner = shouldShowLaunchShortcutBanner(
    surface,
    onboardingValues?.launchKeyboardShortcutUseCount ?? 0,
  );

  React.useEffect(() => {
    childPageRef.current?.scrollTo(0, 0);
  }, [location]);

  const setOpenTypeaheadListener = useSetAtom(OpenTypeaheadAtom);
  const onOmniboxFocus = React.useCallback(
    (event: React.KeyboardEvent | string | void) => {
      if (typeof event === 'string' || !event) return;

      const target = event.target as HTMLElement;
      // Avoid cases where we might want to actually type "/" instead of triggering focus omnibox
      // ex. (adding "/" in a description of a stack etc.)
      if (
        target.tagName === 'INPUT' ||
        target.tagName === 'TEXTAREA' ||
        target.isContentEditable
      ) {
        return;
      }

      // Assume if a modal is open, we don't want to open typeahead in the background
      if (document.body.classList.contains('dig-Modal-body--open')) {
        return;
      }

      setOpenTypeaheadListener(true);
    },
    [setOpenTypeaheadListener],
  );

  useHotkeys({
    platform,
    pathname,
    onEscape: handleEscape,
    onCheckForUpdates: handleCheckForUpdates,
    onOmniboxFocus,
    isDesktop: surface === 'desktop',
  });

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.iOS]: iOSOrAndroid,
      })}
    >
      <div
        className={styles.twoColumns}
        style={{ gridTemplateColumns: `${sidebarWidth}px auto` }}
      >
        <div className={styles.leftSide}>
          <GlobalNav
            betaBadgePosition={betaBadgePosition}
            alwaysCollapsed={globalNavAlwaysCollapsed}
          />
        </div>

        <div
          className={classNames(styles.rightSide, {
            [styles.augustRevision]: augustRevisionEnabled,
            [styles.mobile]: globalNavIsMobile,
          })}
          id={
            augustRevisionEnabled ? KEYBOARD_NAV_SCROLL_CONTAINER_ID : undefined
          }
          ref={augustRevisionEnabled ? childPageRef : undefined}
        >
          {!hideTopNav && <TopNav />}

          <div
            className={classNames(
              styles.content,
              showLaunchShortcutBanner && styles.withBanner,
            )}
          >
            <div className={styles.container}>
              <div
                className={classNames(
                  styles.canvasContainer,
                  isMobileSize && styles.mobile,
                )}
              >
                <GlobalBanners />
                <div
                  className={styles.canvas}
                  id={
                    !augustRevisionEnabled
                      ? KEYBOARD_NAV_SCROLL_CONTAINER_ID
                      : undefined
                  }
                  ref={!augustRevisionEnabled ? childPageRef : undefined}
                >
                  {children}
                </div>

                {showLaunchShortcutBanner && (
                  <div className={styles.banner}>
                    <LaunchKeyboardShortcutBanner
                      isDarwin={platform === 'darwin'}
                    />
                  </div>
                )}
              </div>
            </div>

            {analyticsLogsOpen && <EventLog onClose={toggleAnalyticsLogs} />}

            <GlobalModalContainer />
          </div>
        </div>
      </div>

      <FlyoutPanel />
      <ChecklistButton
        className={styles.onboardingChecklist}
        {...onboardingProps}
      />
    </div>
  );
};
