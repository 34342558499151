// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgAsana({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.218 12.585c-2.643 0-4.787 2.105-4.787 4.703 0 2.603 2.144 4.708 4.787 4.708 2.638 0 4.782-2.109 4.782-4.708 0-2.594-2.14-4.703-4.782-4.703zm-12.436 0C3.144 12.585 1 14.694 1 17.293 1 19.896 3.144 22 5.782 22c2.643 0 4.787-2.109 4.787-4.707.004-2.6-2.14-4.708-4.787-4.708zm11-5.878c0 2.6-2.14 4.704-4.782 4.704-2.643 0-4.782-2.105-4.782-4.704C7.218 4.11 9.358 2 12 2c2.643 0 4.782 2.109 4.782 4.707z"
        fill="url(#asana_svg__paint0_radial)"
      />
      <defs>
        <radialGradient
          id="asana_svg__paint0_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(-90.449 13.127 1.137) scale(12.2733 13.2697)"
        >
          <stop stopColor="#FFC01B" />
          <stop offset={0.6} stopColor="#FF75A1" />
          <stop offset={0.999} stopColor="#FF7878" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default SvgAsana;
