import { ButtonProps, IconButtonProps } from '@dropbox/dig-components/buttons';
import { Box } from '@dropbox/dig-foundations';
import { forwardRef, Ref } from 'react';
import { useIsHoverFriendly } from '../responsive/hover';
import { useIsMobileSize } from '../responsive/mobile';
import { DigTooltip, DigTooltipProps } from '../util/DigTooltip';

export type CoreTooltipProps = Omit<DigTooltipProps, 'children'>;

export const BaseButtonWithTooltip = forwardRef(
  (
    {
      tooltipProps,
      Component,
      ...props
    }: {
      tooltipProps: CoreTooltipProps;
      Component: React.ElementType;
    } & (IconButtonProps | ButtonProps),
    ref?: Ref<HTMLDivElement>,
  ) => {
    const isHoverFriendly = useIsHoverFriendly();
    const isMobileSize = useIsMobileSize();
    let Btn = <Component aria-label={tooltipProps.title} {...props} />;

    // A disabled [Icon]Button will not trigger user interactions, so we need
    // to wrap it with a Box that will handle the tabIndex and focus styles,
    // along with triggering the tooltip.
    // https://dig.pp.dropbox.com/library/components/tooltips/engineering/#disabled-elements
    if (props.disabled) {
      Btn = (
        <Box
          as="span"
          display="inline-block"
          borderRadius="Medium"
          boxShadow={{
            focusVisible: 'Focus Ring',
          }}
          outlineWidth="0"
          tabIndex={0}
        >
          {Btn}
        </Box>
      );
    }
    const tooltip = (
      <DigTooltip
        // Allow faster show for icon buttons.
        openDelay={200}
        {...tooltipProps}
        title={!isHoverFriendly || isMobileSize ? '' : tooltipProps.title}
      >
        {Btn}
      </DigTooltip>
    );
    return ref !== undefined ? <div ref={ref}>{tooltip}</div> : tooltip;
  },
);
BaseButtonWithTooltip.displayName = 'BaseButtonWithTooltip';
