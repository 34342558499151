// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2956
import type { PAPEventGeneric } from '../base/event';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'dismiss.dash_calendar_modal'
// Description: Fires when the add calendar modal is dismissed by the user
// Owner: otc
export type Dismiss_DashCalendarModal = PAPEventGeneric<
  'dash',
  'dismiss',
  'dash_calendar_modal',
  {
    // Set to true when operation is successful, but set to false if unsuccessful.
    isSuccess?: boolean;
    // the start time of the event in ms
    startTimeMs?: number;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
  }
>;

export function PAP_Dismiss_DashCalendarModal(
  properties?: Dismiss_DashCalendarModal['properties'],
): Dismiss_DashCalendarModal {
  return <Dismiss_DashCalendarModal>{
    class: 'dash',
    action: 'dismiss',
    object: 'dash_calendar_modal',
    properties,
  };
}
