import * as React from "react";
import classnames from "classnames";
import { Box, vars, atoms } from "@dropbox/dig-foundations";
import { Text } from "@dropbox/dig-components/typography";
import css from "./LabelGroup.module.css";

type SpacingVars = Extract<
  keyof typeof vars.Spacing,
  `Micro ${string}` | `Macro ${string}`
>;

export interface LabelGroupProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "color"> {
  /**
   * The label of the Label Group
   */
  withLabel: React.ReactNode;
  /**
   * This is meant to hold any accessory for the label group. Ideally an icon or an image.
   */
  withStartAccessory: React.ReactNode;
  /**
   * The sub text of the label group.
   * - will be rendered under the `label` in `vertical` direction.
   * - will be rendered next to the `label` in `horizontal` direction.
   */
  withSubText?: React.ReactNode;
  /**
   * The meta text of the label group.
   * This should ideally be the `Metadata` component, but this prop will accept any `ReactNode`
   */
  withMeta?: React.ReactNode;
  /**
   * The gap prop sets the spacing between the startAccessory and the label content. If necessary, you may override the default value using  Global spacing tokens.
   * @default Micro Large
   */
  accessoryGap?: SpacingVars;
  /**
   * The gap prop sets the spacing between each element within the label content container. If necessary, you may override the default value using Global spacing tokens.
   * @default Micro XSmall
   */
  labelGap?: SpacingVars;
  /**
   * The vertical alignment of the component.
   * - `offsetTop` is best suited for cases where you need a slight optical adjustment to provide better visual balance between the startAccessory and the label content.
   * - Does not have any effect when the direction is set to horizontal.
   *
   * @default top
   */
  verticalAlignment?: "top" | "offsetTop" | "center";
  /**
   * The component allows for both vertical and horizontal layouts.
   * @default vertical
   */
  direction?: "vertical" | "horizontal";

  /**
   * Any `children` passed to this component will be rendered below the label content.
   * - Only rendered when the direction is set to vertical.
   */
  children?: React.ReactNode;
}

const largeLabelLineHeight = atoms({ lineHeight: "Label Large" });

/**
 * An opinionated layout representing a single content object.
 *
 * **When to use**
 * - When representing an object with an accompanying label and additional supporting content
 * - When `@dropbox/dig-components` `LabelGroup` component doesn’t meet your content or layout requirements
 * - Within a container component like `Box`, `List.Item`, `Table.Row`, or `ContentRow`.
 *
 * **When not to use**
 * - If you need need a layout that is more complex than provided by the component
 */
export const LabelGroup: React.FC<LabelGroupProps> = ({
  withLabel,
  withStartAccessory,
  withMeta: withMetaText,
  withSubText,
  className,
  direction = "vertical",
  verticalAlignment = "top",
  children,
  labelGap: _labelGap,
  accessoryGap = "Micro Large",
  style,
  ...props
}) => {
  const labelGap: LabelGroupProps["labelGap"] = _labelGap
    ? _labelGap
    : direction === "vertical"
      ? "Micro XSmall"
      : "Micro Medium";

  const gapStyles = {
    "--label-group-start-gap": vars.Spacing[accessoryGap],
    "--label-group-accessory-gap": vars.Spacing[labelGap],
  } as React.CSSProperties;

  const shouldShowChildren = Boolean(children && direction === "vertical");
  return (
    <Box
      className={classnames(css.root, className, {
        [css.vAlignCenter]: verticalAlignment === "center",
        [css.vAlignOffsetTop]: verticalAlignment === "offsetTop",
      })}
      style={{ ...style, ...gapStyles }}
      {...props}
    >
      <Box className={classnames(css.startAccessory)}>{withStartAccessory}</Box>
      <Box className={classnames(css.main)} color="Text Base" fontFamily="Text">
        <Box
          className={classnames(css.labels, {
            [css.vertical]: direction === "vertical",
            [css.horizontal]: direction === "horizontal",
          })}
        >
          <Box className={css.label}>
            <Text
              variant="label"
              isBold
              size="medium"
              className={classnames(largeLabelLineHeight, css.labelText)}
            >
              {withLabel}
            </Text>
          </Box>
          {withSubText && (
            <Box>
              <Text
                variant="label"
                size="medium"
                color="subtle"
                className={classnames(largeLabelLineHeight, css.labelText)}
              >
                {withSubText}
              </Text>
            </Box>
          )}
          {withMetaText && <Box>{withMetaText}</Box>}
        </Box>
        {shouldShowChildren && (
          <Box className={classnames(css.children)}>{children}</Box>
        )}
      </Box>
    </Box>
  );
};
