import { Text } from '@dropbox/dig-components/typography';
import { sortKeyCompare } from '@mirage/shared/util/tiny-utils';
import classnames from 'classnames';
import { Children, isValidElement, ReactElement } from 'react';
import { LinkComponentProps } from '../Link/Link';
import { Link, ListItemSize, OrderedLink } from '../types';
import styles from './LinkList.module.css';

const isOrderedLink = (link: Link): link is OrderedLink => {
  return (link as OrderedLink)?.sortKey !== undefined && link?.id !== undefined;
};

export type LinkComponentChild = ReactElement<LinkComponentProps>;

export interface LinkListProps {
  children?: LinkComponentChild[] | LinkComponentChild;
  className?: string;
  skipOrdering?: boolean;
  numLinksToShow?: number;
  listItemSize: ListItemSize;
}

/**
 * A vertical list of links that will show all links that can fit the container. Links that do not
 * fit in the vertical space of the container will overflow into new columns are hidden. Tabbing
 * focus through the initial set of links will continue on through the columns bringing the focused
 * column into view.
 *
 * We may incorporate a sort of dot nav in the future to make it easier to browse through the
 * columns like a carousel.
 *
 * As of 2023-02-15, LinkList is well covered by other stories, particularly the Spotlight component
 * on the start page, and is a simple enough building block that having its own stories would be
 * duplicative. We can add stories down the line if necessary.
 */
export const LinkList: React.FC<LinkListProps> = ({
  children,
  listItemSize,
  className,
  numLinksToShow,
  skipOrdering = false,
}) => {
  const validChildren: LinkComponentChild[] = Children.toArray(children).filter(
    isValidElement,
  ) as LinkComponentChild[]; // Casting should be safe since we typeguard the children prop itself
  // Sort children if ordering is not skipped and based on whether they have a sortKey
  const sortedChildren = !skipOrdering
    ? validChildren.sort((a, b) => {
        const linkA = a.props.link;
        const linkB = b.props.link;
        if (isOrderedLink(linkA) && isOrderedLink(linkB)) {
          return sortKeyCompare(linkA.sortKey, linkB.sortKey);
        }
        return 0; // If either link doesn't have a sortKey, they are considered equal for sorting purposes
      })
    : validChildren;

  const visibleChildren = numLinksToShow
    ? sortedChildren.slice(0, numLinksToShow)
    : sortedChildren;
  const overflowChildren =
    numLinksToShow && sortedChildren.length > numLinksToShow
      ? sortedChildren.slice(numLinksToShow)
      : [];

  const overflowComponent = () => {
    return (
      <div className={styles.overFlowGoToStack}>
        <Text className={styles.overFlowText} color="faint">
          {`+ ${overflowChildren.length} more`}
        </Text>
      </div>
    );
  };

  return (
    <div
      data-testid="stackLinkListItem"
      className={classnames(
        styles.list,
        listItemSize >= ListItemSize.Large && styles.listWithGap,
        listItemSize >= ListItemSize.Large && styles.marchListWithGap,
        listItemSize === ListItemSize.Small && styles.smallListWithGap,
        className,
      )}
    >
      {
        <>
          {visibleChildren.map((child, index) => {
            return (
              <div
                key={
                  child.props.link?.id ?? child.props.link?.url + '@' + index
                }
                className={styles.linkRow}
              >
                {child}
              </div>
            );
          })}
          {overflowChildren.length > 0 && overflowComponent()}
        </>
      }
    </div>
  );
};
