// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3292
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { CalendarItemCategory } from '../enums/calendar_item_category';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';

// Event_name: 'expand.calendar_item_list'
// Description: Sent when a user expands a list of calendar events.
// Owner: dash-web
export type Expand_CalendarItemList = PAPEventGeneric<
  'dash',
  'expand',
  'calendar_item_list',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // Possible groupings of calendar events.
    calendarItemCategory?: CalendarItemCategory;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
  }
>;

export function PAP_Expand_CalendarItemList(
  properties?: Expand_CalendarItemList['properties'],
): Expand_CalendarItemList {
  return <Expand_CalendarItemList>{
    class: 'dash',
    action: 'expand',
    object: 'calendar_item_list',
    properties,
  };
}
