// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2125
import type { PAPEventGeneric } from '../base/event';
import type { CustomizeOption } from '../enums/customize_option';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'open.dash_start_page'
// Description: Fires when a user clicks the Dash logo to launch start page
// Owner: dash-web
export type Open_DashStartPage = PAPEventGeneric<
  'stacks',
  'open',
  'dash_start_page',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The setting selected for a customization option
    customizeOption?: CustomizeOption;
  }
>;

export function PAP_Open_DashStartPage(
  properties?: Open_DashStartPage['properties'],
): Open_DashStartPage {
  return <Open_DashStartPage>{
    class: 'stacks',
    action: 'open',
    object: 'dash_start_page',
    properties,
  };
}
