import { IconButton } from '@dropbox/dig-components/buttons';
import { Split, Stack } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CopyLine,
  ThumbsDownFill,
  ThumbsDownLine,
  ThumbsUpFill,
  ThumbsUpLine,
} from '@dropbox/dig-icons/assets';
import { FeedbackOptions } from '@mirage/shared/types';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { memo, useState } from 'react';
import styles from './ConversationMessageActions.module.css';

interface ConversationMessageActionsProps {
  onClickFeedback: (feedback: FeedbackOptions) => void;
  onCopyMessage?: () => void;
}
export const ConversationMessageActions = memo(
  ({ onCopyMessage, onClickFeedback }: ConversationMessageActionsProps) => {
    const [recordedFeedback, setRecordedFeedback] = useState<
      FeedbackOptions | undefined
    >();
    const handleClickFeedback = (feedback: FeedbackOptions) => {
      if (recordedFeedback === feedback) {
        setRecordedFeedback(undefined);
      } else {
        setRecordedFeedback(feedback);
        onClickFeedback(feedback);
      }
    };
    const positiveIcon =
      recordedFeedback === FeedbackOptions.Positive
        ? ThumbsUpFill
        : ThumbsUpLine;
    const negativeIcon =
      recordedFeedback === FeedbackOptions.Negative
        ? ThumbsDownFill
        : ThumbsDownLine;
    return (
      <Stack className={styles.ConversationMessageActionsContainer}>
        <Split>
          <Split.Item>
            <DigTooltip title={i18n.t('helpful')}>
              <IconButton
                size="small"
                variant="borderless"
                onClick={() => handleClickFeedback(FeedbackOptions.Positive)}
                aria-label={i18n.t('helpful')}
                aria-pressed={recordedFeedback === FeedbackOptions.Positive}
              >
                <UIIcon
                  className={styles.ConversationMessageActionsButton}
                  src={positiveIcon}
                />
              </IconButton>
            </DigTooltip>
            <DigTooltip title={i18n.t('not_helpful')}>
              <IconButton
                size="small"
                variant="borderless"
                onClick={() => handleClickFeedback(FeedbackOptions.Negative)}
                aria-label={i18n.t('not_helpful')}
                aria-pressed={recordedFeedback === FeedbackOptions.Negative}
              >
                <UIIcon
                  className={styles.ConversationMessageActionsButton}
                  src={negativeIcon}
                />
              </IconButton>
            </DigTooltip>
          </Split.Item>
          {onCopyMessage && (
            <Split.Item>
              <DigTooltip title={i18n.t('copy')}>
                <IconButton
                  size="small"
                  variant="borderless"
                  onClick={onCopyMessage}
                  aria-label={i18n.t('copy')}
                >
                  <UIIcon
                    className={styles.ConversationMessageActionsButton}
                    src={CopyLine}
                  />
                </IconButton>
              </DigTooltip>
            </Split.Item>
          )}
        </Split>
      </Stack>
    );
  },
);
ConversationMessageActions.displayName = 'ConversationMessageActions';
