// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2236
import type { PAPEventGeneric } from '../base/event';
import type { CalendarEmptyStateType } from '../enums/calendar_empty_state_type';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'shown.calendar_empty_state'
// Description: Fires when the calendar empty state is displayed when the user does not have a calendar connected
// Owner: dash-web
export type Shown_CalendarEmptyState = PAPEventGeneric<
  'dash',
  'shown',
  'calendar_empty_state',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // the type of calendar empty state shown
    calendarEmptyStateType?: CalendarEmptyStateType;
  }
>;

export function PAP_Shown_CalendarEmptyState(
  properties?: Shown_CalendarEmptyState['properties'],
): Shown_CalendarEmptyState {
  return <Shown_CalendarEmptyState>{
    class: 'dash',
    action: 'shown',
    object: 'calendar_empty_state',
    properties,
  };
}
