import { SessionManager } from '@mirage/analytics/session/session-manager';
import React, { createContext, useContext } from 'react';
import { PAPEvent } from './events/base/event';
import { StackAccessLevel } from './events/enums/stack_access_level';

export type ReportPapEventFn = (
  event: PAPEvent,
  flush?: boolean,
) => Promise<void> | void;

export interface ExtensionProperty {
  isExtensionInstalled: boolean;
  isNewTabEnabled: boolean | undefined;
}

export type StackDerivedPAPProps = {
  stackId: string;
  isOwner: boolean;
  isShared: boolean;
  isPinned: boolean;
  createdTs?: number;
  lastModifiedTs?: number;
  isAutoGenerated: boolean;
  numSharedUsers: number;
  numSections: number;
  stackAccessLevel: StackAccessLevel;
  isWelcomeStack: boolean;
  isPublished?: boolean;
  isArchived?: boolean;
  isCloned?: boolean;
};

export type AnalyticsContextType = {
  reportPapEvent: ReportPapEventFn;
  explicitAnswersSessionManager: SessionManager;
  explicitAnswerDwellManager: SessionManager;
  implicitAnswerDwellManager: SessionManager;
  searchSessionManager: SessionManager;
  searchAttemptSessionManager: SessionManager;
};

const AnalyticsContext = createContext<AnalyticsContextType | undefined>(
  undefined,
);

export const useMirageAnalyticsContext = (): AnalyticsContextType => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('Missing MirageAnalyticsProvider wrapper in JSX tree');
  }
  return context;
};

export const MirageAnalyticsProvider: React.FC<AnalyticsContextType> = ({
  children,
  ...props
}) => {
  return (
    <AnalyticsContext.Provider value={props}>
      {children}
    </AnalyticsContext.Provider>
  );
};
