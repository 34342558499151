import { USER_AGENT_INFO } from './tiny-utils';

// This matches the list we use in PAP.
// Ref: packages/analytics/events/enums/operating_system.ts
export type OperatingSystem = 'mac' | 'windows' | 'linux' | 'ios' | 'android';

// Export for testing only. Please use getOperatingSystem() instead.
export function detectOperatingSystem(
  osName = USER_AGENT_INFO.os.name,
): OperatingSystem | undefined {
  if (!osName) {
    return undefined;
  }

  switch (osName.toLowerCase()) {
    case 'mac os':
      return 'mac';
    case 'windows':
      return 'windows';
    case 'android':
      return 'android';
    case 'ios':
      return 'ios';
    // The list of supported linux distroes is not exhaustive but not important.
    case 'linux':
    case 'cent os':
    case 'fedora':
    case 'redhat':
    case 'ubuntu':
      return 'linux';
  }

  return undefined;
}

const operatingSystem = detectOperatingSystem();

export function getOperatingSystem(): OperatingSystem | undefined {
  return operatingSystem;
}
