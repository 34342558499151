import { dash_ml, stacks } from '@dropbox/api-v2-client';
import {
  GlobeLine,
  LockLine,
  StacksFill,
  TeamLine,
} from '@dropbox/dig-icons/assets';
import { DashSuggestionSource } from '@mirage/analytics/events/enums/dash_suggestion_source';
import { FavIcon } from '@mirage/link-list/Favicon/Favicon';
import { ListItemSize } from '@mirage/link-list/types';
import { fetchSearchResults, SearchResult } from '@mirage/service-dbx-api';
import {
  isStackItemShortcut,
  stackItemComputeNameFromFields,
} from '@mirage/service-stacks/service/utils';
import { CancelationError } from '@mirage/shared/util/cancellable';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import { getTimeAgoString } from '@mirage/shared/util/time';
import { isDefined, nonNil } from '@mirage/shared/util/tiny-utils';
import {
  urlToDomainName,
  urlToService,
} from '@mirage/shared/util/urlRulesUtil';
import { SVGProps } from 'react';
import * as React from 'react';
import { TabSuggestion } from '../AddStackItemMenuContent/types';
import { StackAccessLevel } from '../ShareModal/Types';
import { LinkWithMLScore } from '../types';

export const EMOJI_CLEARED_SENTINEL_VALUE = 'emoji_cleared';
export const DEFAULT_STACK_NAME = 'DROPBOX_DEFAULT_STACK';

export const ALL_STACKS_GRID_TWO_COLUMN_BREAKPOINT = 648;
export const ALL_STACKS_GRID_THREE_COLUMN_BREAKPOINT = 951;
export const ALL_STACKS_GRID_TWO_COLUMN_WIDTH = 600;

export const stackIsPinned = (stack: stacks.Stack): boolean => {
  return stack.user_data?.is_pinned || false;
};

export const stackGetName = (stack: stacks.Stack): string => {
  return nonNil(stack.stack_data?.name, 'stack_data.name');
};

export const stackGetNamespaceId = (stack: stacks.Stack): string => {
  return nonNil(stack.namespace_id, 'namespace_id');
};

export const stackGetEmoji = (
  stackOrEmoji: stacks.Stack | string | undefined,
) => {
  if (!stackOrEmoji) return undefined;
  const emoji =
    typeof stackOrEmoji === 'string'
      ? stackOrEmoji
      : stackOrEmoji.stack_data?.emoji;
  return emoji && emoji !== EMOJI_CLEARED_SENTINEL_VALUE ? emoji : '';
};

export const stackGetFavicon = (
  stackOrEmoji: stacks.Stack | string | undefined,
) => {
  const emoji = stackGetEmoji(stackOrEmoji);
  return emoji ? () => <span data-icontype="emoji">{emoji}</span> : StacksFill;
};

export const stackHasWritePermissions = (stack: stacks.Stack): boolean => {
  const tag = stack?.permission?.['.tag'];
  return tag === 'owner' || tag === 'write';
};

export const isStackOwner = (stack: stacks.Stack): boolean => {
  const tag = stack?.permission?.['.tag'];
  return tag === 'owner';
};

export const isStackShared = (stack: stacks.Stack): boolean => {
  return (
    (stack.sharing_data?.members?.length || 0) > 0 ||
    stack.sharing_data?.shared_link?.url !== undefined
  );
};

export const stackIsDefault = (stack: stacks.Stack): boolean => {
  return stack?.stack_data?.name === DEFAULT_STACK_NAME;
};

export const isStackItemWithUrl = (
  item: stacks.StackItemShortcut,
): item is stacks.StackItemShortcut & { url: string } => {
  return !!item.url;
};

export const suggestedStackToLinkWithMLScore = (
  suggestedStack: stacks.AutoStackSuggestion,
): LinkWithMLScore[] => {
  // create a mapping from item name to item hash id
  const titleToItemHashId: Record<string, number> =
    suggestedStack.content_items?.reduce<Record<string, number>>(
      (acc, item) => {
        if (item.title && item.item_id_hash) {
          acc[item.title] = item.item_id_hash;
        }
        return acc;
      },
      {},
    ) || {};

  return (
    suggestedStack.items
      ?.filter(isStackItemShortcut)
      .map((item) => {
        const computedName = stackItemComputeNameFromFields(item);
        if (computedName && item.url) {
          // Autostacks does not have item level scores
          return {
            url: item.url,
            title: computedName,
            score: 0,
            itemIdHash: titleToItemHashId?.[computedName],
            predictionIdHash: suggestedStack.prediction_id_hash,
            dashSuggestionSource: 'content_suggestion' as DashSuggestionSource,
            // stacks.getAutoStackSuggestions does not return requestId
            dashRequestId: '',
          };
        }
        return undefined;
      })
      .filter(isDefined) || []
  );
};

export const contentSuggestionToLinkWithMLScore = (
  suggestion: dash_ml.SuggestedContent,
  dashRequestId?: string,
): LinkWithMLScore & { title: string } => {
  const url = suggestion.content?.url || '';
  return {
    url,
    title: suggestion.content?.title || '',
    itemIdHash: suggestion.content?.item_id_hash,
    predictionIdHash: suggestion.prediction_id_hash,
    predictionId: suggestion.prediction_id,
    rank: suggestion.rank,
    score: suggestion.score ?? 0,
    dashSuggestionSource: 'content_suggestion',
    dashRequestId,
  };
};

export const membersFromStack = (stack: stacks.Stack) => {
  const members = stack.sharing_data?.members?.map(
    (member: stacks.StackSharingMember) => {
      const subject: stacks.StackSharingSubject | undefined = member.subject;
      if (
        subject?.['.tag'] === 'user' &&
        subject.email &&
        subject.display_name
      ) {
        return {
          email: subject.email,
          display_name: subject.display_name,
          profile_photo_url: subject.profile_photo_url,
        } as stacks.StackSharingUser;
      }

      if (subject?.['.tag'] === 'group' && subject.display_name) {
        return {
          display_name: subject.display_name,
        } as stacks.StackSharingGroup;
      }
    },
  );
  return members?.filter(isDefined) || [];
};

export const getStackItemMetadata = (
  item: stacks.StackItemShortcut,
): string[] | undefined => {
  return item.last_modified_time_utc_sec
    ? [
        item.url ? urlToService(item.url) || urlToDomainName(item.url) : '',
        // TODO: need to add translation with i18n.t()
        `Edited ${getTimeAgoString(
          item.last_modified_time_utc_sec * 1000,
          false,
          true,
        )}`,
      ]
    : undefined;
};

export const iconSrcForAccessLevel = (
  accessLevel: StackAccessLevel,
  isPublicSharingAllowed?: boolean,
): ((props: SVGProps<SVGSVGElement>) => JSX.Element) => {
  let accessLevelToCheck = accessLevel;
  if (
    accessLevelToCheck === StackAccessLevel.PUBLIC &&
    !isPublicSharingAllowed
  ) {
    accessLevelToCheck = StackAccessLevel.TEAM;
  }

  switch (accessLevel) {
    case StackAccessLevel.PUBLIC:
      return GlobeLine;
    case StackAccessLevel.INVITED:
      return LockLine;
    case StackAccessLevel.TEAM:
      return TeamLine;
  }
};

export const stackIsArchived = (stack: stacks.Stack | null): boolean => {
  return stack?.stack_data?.archive_data?.is_archived || false;
};

export const stackIsNotArchived = (stack: stacks.Stack | null): boolean => {
  return !stackIsArchived(stack);
};

export const stackIsMatch = (
  stack: stacks.Stack | null,
  inputString: string,
) => {
  if (!inputString || !stack) {
    return false;
  }
  return (
    stack.stack_data?.name?.toLowerCase().includes(inputString.toLowerCase()) ||
    false
  );
};

export const stackIsPublished = (
  stack: stacks.Stack,
  contents: stacks.PublishedContent[],
) => {
  const isPublished = contents.find(
    (c) => c['.tag'] === 'stack' && c.namespace_id === stack.namespace_id,
  );
  return !!isPublished;
};

export const fetchSearchResultsFromAPI = async (inputString: string) => {
  const trimmedInput = inputString.trim().toLocaleLowerCase();
  const links: TabSuggestion[] = [];
  if (trimmedInput.length > 0) {
    let result: SearchResult[] | undefined;
    try {
      result = await fetchSearchResults(trimmedInput, []);
    } catch (e) {
      if (e instanceof CancelationError) {
        return;
      }
      throw e;
    }

    if (result) {
      for (const item of result) {
        links.push({
          title: item.title,
          url: item.url ?? '',
          score: item.score ?? 0,
          icon: (
            <FavIcon
              src={faviconSrcForSrcUrl(item.url ?? '')}
              size={ListItemSize.Small}
            />
          ),
          connectorDisplayName: item.connectorInfo?.displayName,
        });
      }
    }
  }

  return links;
};
