// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2733
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { Environment } from '../enums/environment';
import type { FeatureRolloutRing } from '../enums/feature_rollout_ring';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'exposure.dash_growthbook'
// Description: Exposure event for dash growthbook features.
// https://www.dropbox.com/scl/fi/nin92keeedjgnb53k757b/GrowthBook-Dash-Exposure-Logging.paper?rlkey=aesboixcm3ntzu4j9vlpd5mbc&dl=0
// Owner: dash-web
export type Exposure_DashGrowthbook = PAPEventGeneric<
  'dash',
  'exposure',
  'dash_growthbook',
  {
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // stormcrow feature name
    feature?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // Experiment variant that the user was exposed to
    experimentVariant?: string;
    // feature rollout ring. Should be in sync with feature_rollout_ring in https://app.dropboxexperiment.com/attributes
    featureRolloutRing?: FeatureRolloutRing;
  }
>;

export function PAP_Exposure_DashGrowthbook(
  properties?: Exposure_DashGrowthbook['properties'],
): Exposure_DashGrowthbook {
  return <Exposure_DashGrowthbook>{
    class: 'dash',
    action: 'exposure',
    object: 'dash_growthbook',
    properties,
  };
}
