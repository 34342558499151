import { dash_feed } from '@dropbox/api-v2-client';
import {
  ContentRowAvatar,
  ContentRowIcon,
} from '@mirage/dash-component-library/components/ContentRow';
import { getInitialsFromDisplayName } from '@mirage/shared/account';

type ActivityThumbnailProps = {
  activity: dash_feed.ActivityItem;
};

export const ActivityThumbnail = ({ activity }: ActivityThumbnailProps) => {
  let avatar = null;
  if (activity.actor) {
    avatar = (
      <ContentRowAvatar src={activity.actor.avatar_img}>
        {getInitialsFromDisplayName(activity.actor.name!)}
      </ContentRowAvatar>
    );
  } else {
    avatar = <ContentRowAvatar isGuest={!activity.actor} />;
  }

  return <ContentRowIcon icon={avatar} />;
};
