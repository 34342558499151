import * as React from "react";
import { TShirtSizes } from "@dropbox/dig-foundations";
export type SizeContext<T extends TShirtSizes> = {
  size: T;
};

export type UseSizeProps<T extends TShirtSizes> = {
  size?: T;
};

type SizeFactoryProps<T extends TShirtSizes> = {
  defaultSizeContext: { size: T };
};

/**
 * Use this factory to create a size context and hook for your component.
 * This will allow you to set the size for an entire tree of the same component without having to specify the prop value for each component.
 */
export const sizeFactory = <T extends TShirtSizes>({
  defaultSizeContext,
}: SizeFactoryProps<T>) => {
  const SizeContext = React.createContext<SizeContext<T>>(defaultSizeContext);
  /**
   * Hook which will use either the size provided as a prop, or the size from the context.
   */
  const useSize = ({ size }: UseSizeProps<T>): T => {
    const context = React.useContext(SizeContext);
    return size || context.size;
  };
  /**
   * The provider which will provide the size context to the children.
   * Will only affect children of the component that exports this Provider.
   */
  const SizeProvider: React.FC<
    React.PropsWithChildren<SizeFactoryProps<T>["defaultSizeContext"]>
  > = ({ size, children }) => {
    return (
      <SizeContext.Provider value={{ size }}>{children}</SizeContext.Provider>
    );
  };
  return { useSize, SizeProvider };
};
