import { asterixOut, transformJsonResponse } from "@alwaysmeticulous/redaction";
import { RecorderMiddleware } from "@alwaysmeticulous/sdk-bundles-api";
import { dash, dcs } from "@dropbox/api-v2-client";
import { StoredCalendarEvents } from "@mirage/service-calendar-events/service";

import { DEFAULT_REDACTED_URL } from "./utils";

const redactEvent = (event: dash.SearchResult) => {
  return {
    ...event,
    title: event.title ? asterixOut(event.title) : undefined,
    url: event.url ? DEFAULT_REDACTED_URL : undefined,
    description: event.description ? asterixOut(event.description) : undefined,
  };
};

export const redactCalendarEvents = (
  calendarEvents: StoredCalendarEvents,
): StoredCalendarEvents => {
  return {
    ...calendarEvents,
    events: calendarEvents.events?.map((event) => redactEvent(event)),
  };
};

export const redactGetCalendarEventsResponse: RecorderMiddleware =
  transformJsonResponse<dcs.GetCalendarEventsResponse>({
    urlRegExp: /dcs\/get_calendar_events/,
    transform: (data) => ({
      ...data,
      events: data?.events?.map((event) => redactEvent(event)),
    }),
  });
