import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';

export interface Service {
  checkForUpdates(): void;
  selectAll(): void;
  cut(): void;
  copy(): void;
  paste(): void;
  escape(): void;
  overrideDefaultBehavior: () => boolean;
}

export type ServiceAdapter = {
  checkForUpdates(): Promise<void>;
  selectAll?: () => Promise<void>;
  cut?: () => Promise<void>;
  copy?: () => Promise<void>;
  paste?: () => Promise<void>;
  escape?: () => Promise<void>;
};

export default function register(adapter: ServiceAdapter) {
  async function checkForUpdates() {
    if (!adapter.checkForUpdates) return Promise.resolve();
    return adapter.checkForUpdates();
  }

  async function selectAll() {
    if (!adapter.selectAll) return Promise.resolve();
    return adapter.selectAll();
  }

  async function cut() {
    if (!adapter.cut) return Promise.resolve();
    return adapter.cut();
  }

  async function copy() {
    if (!adapter.copy) return Promise.resolve();
    return adapter.copy();
  }

  async function paste() {
    if (!adapter.paste) return Promise.resolve();
    return adapter.paste();
  }

  async function escape() {
    if (!adapter.escape) return Promise.resolve();
    return adapter.escape();
  }

  function overrideDefaultBehavior() {
    if (!adapter.copy || !adapter.paste) {
      return false;
    }
    return true;
  }

  services.provide<Service>(
    ServiceId.KEYMAP_ACTIONS,
    {
      checkForUpdates,
      selectAll,
      cut,
      copy,
      paste,
      escape,
      overrideDefaultBehavior,
    },
    [],
  );
}
