import { USER_AGENT_INFO } from './tiny-utils';

// This matches the list we use in PAP.
// Ref: packages/analytics/events/enums/browser_name.ts
export type BrowserType = 'chrome' | 'edge' | 'safari' | 'firefox';

// Export for testing only. Please use getBrowserType() instead.
export function detectBrowserType(
  browserName = USER_AGENT_INFO.browser.name,
): BrowserType | undefined {
  if (!browserName) {
    return undefined;
  }

  const browserNameLC = browserName.toLocaleLowerCase();
  switch (browserNameLC) {
    case 'chrome':
    case 'edge':
    case 'safari':
    case 'firefox':
      return browserNameLC;
  }

  return undefined;
}

const browserType = detectBrowserType();

export function getBrowserType(): BrowserType | undefined {
  return browserType;
}
