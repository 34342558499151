// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgConfluence({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.725 16.67c-.207.338-.44.73-.637 1.041a.636.636 0 00.213.866l4.137 2.546a.637.637 0 00.882-.216c.165-.277.379-.637.611-1.022 1.639-2.705 3.287-2.374 6.26-.955l4.102 1.951a.637.637 0 00.856-.318l1.97-4.455a.637.637 0 00-.319-.834 495.533 495.533 0 01-4.137-1.967C11.088 10.6 6.35 10.774 2.725 16.671z"
        fill="url(#confluence_svg__paint0_linear)"
      />
      <path
        d="M21.274 6.56c.207-.337.44-.728.637-1.04a.636.636 0 00-.213-.866L17.56 2.11a.638.638 0 00-.907.21c-.166.276-.379.636-.611 1.021-1.64 2.705-3.288 2.374-6.26.955l-4.09-1.941a.637.637 0 00-.855.318l-1.97 4.455a.636.636 0 00.318.834c.866.407 2.587 1.219 4.137 1.967 5.588 2.704 10.327 2.523 13.951-3.367z"
        fill="url(#confluence_svg__paint1_linear)"
      />
      <defs>
        <linearGradient
          id="confluence_svg__paint0_linear"
          x1={21.007}
          y1={22.437}
          x2={8.491}
          y2={15.245}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.18} stopColor="#0052CC" />
          <stop offset={1} stopColor="#2684FF" />
        </linearGradient>
        <linearGradient
          id="confluence_svg__paint1_linear"
          x1={-5275.98}
          y1={15015}
          x2={-4842.65}
          y2={15512.3}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.18} stopColor="#0052CC" />
          <stop offset={1} stopColor="#2684FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgConfluence;
