// @generated by bzl gen
// Simple type that can be exported to metaserver/js without pulling in dependencies.
// keep in sync ml_platform/capabilities/capability_check_api_v2.proto

// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/ml_capabilities_types/capability_check_api_v2.proto (package ml_capabilities_types, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { ProductCapabilityBatchStatus } from './capability_check_pb';

/**
 * @generated from message ml_capabilities_types.CapabilityNotAllowedError
 */
export class CapabilityNotAllowedError extends Message<CapabilityNotAllowedError> {
  /**
   * @generated from field: ml_capabilities_types.ProductCapabilityBatchStatus status = 1;
   */
  status?: ProductCapabilityBatchStatus;

  constructor(data?: PartialMessage<CapabilityNotAllowedError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities_types.CapabilityNotAllowedError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'status', kind: 'message', T: ProductCapabilityBatchStatus },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CapabilityNotAllowedError {
    return new CapabilityNotAllowedError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CapabilityNotAllowedError {
    return new CapabilityNotAllowedError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CapabilityNotAllowedError {
    return new CapabilityNotAllowedError().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | CapabilityNotAllowedError
      | PlainMessage<CapabilityNotAllowedError>
      | undefined,
    b:
      | CapabilityNotAllowedError
      | PlainMessage<CapabilityNotAllowedError>
      | undefined,
  ): boolean {
    return proto3.util.equals(CapabilityNotAllowedError, a, b);
  }
}
