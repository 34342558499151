// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2038
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashAnswerFeedback } from '../enums/dash_answer_feedback';
import type { DashAnswersFeedbackTag } from '../enums/dash_answers_feedback_tag';
import type { DashEntrypoint } from '../enums/dash_entrypoint';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'click.dash_answers_tagged_feedback'
// Description: User clicks on dash answer feedback tag 'incorrect answer', 'irrelevant sources', etc.
// Owner: dash-web
export type Click_DashAnswersTaggedFeedback = PAPEventGeneric<
  'dash',
  'click',
  'dash_answers_tagged_feedback',
  {
    // The ID of the Dash answers session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashAnswersSessionId?: string;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // The contents of a search query
    queryString?: string;
    // The LLM-generated answer string returned from dash answers (Q&A) service.
    // This is L0 data, and will be copied to restricted_otc hive table according to this TDD: https://www.dropbox.com/scl/fi/0r38gxrobgr6yvrft4yzu/OTCUS-Machine-Learning-Restricted-Logging-via-PAP-TDD.paper?dl=0&rlkey=7yvsba510fyeufi8cjea9juqk
    answerString?: string;
    // **DEPRECATED** - this should have been a string.
    // Dash Answers - this will be a randomly generated ID associated with each answer, used for logging purposes to track success/dwell time on answers page.
    dashAnswerId?: number;
    // Feedback on the answer provided by dash answer service. Positive, negative, etc.
    dashAnswerFeedback?: DashAnswerFeedback;
    // The tag associated with Dash answers feedback. Users will choose from pre-selected tags like 'Incorrect sources', 'Irrelevant answer', etc.
    dashAnswersFeedbackTag?: DashAnswersFeedbackTag;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // Dash Answers - this will be a randomly generated ID associated with each answer, used for logging purposes to track success/dwell time on answers page.
    answerId?: string;
    // unique id for a /dash/search query. Used by the ML team to get feedback on search result ranking
    searchRequestId?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // The entry point for a dash action. How a user got to a specific surface to perform an action.
    dashEntrypoint?: DashEntrypoint;
    // Dash Answers - number of questions asked in an answers session. This is a COUNT.
    numQuestionsAsked?: number;
    // An entry point string, used to describe how users are triggering the event as relevant (e.g. browse_file_row_context_menu)
    entryPoint?: string;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // unique id for a /dash/conversation query. Used by the ML team to get feedback on answer quality. This is used to associate backend events with user actions on the front end. This is an opaque ID that is just used for correlating actions and has no other significance.
    dashAnswerRequestId?: string;
    // A zero-indexed position of an answer in the multi-answer card
    answerPositionIndex?: number;
    // Freeform text submitted when choosing "other" in secondary feedback.
    dashAnswersFeedbackFreeformText?: string;
  }
>;

export function PAP_Click_DashAnswersTaggedFeedback(
  properties?: Click_DashAnswersTaggedFeedback['properties'],
): Click_DashAnswersTaggedFeedback {
  return <Click_DashAnswersTaggedFeedback>{
    class: 'dash',
    action: 'click',
    object: 'dash_answers_tagged_feedback',
    properties,
  };
}
