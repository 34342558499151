import * as ConnectorIcons from '@mirage/shared/icons/connector-icons';

import type { EntityType } from '@mirage/shared/connectors/entityTypes';

export function getEntityIcon(entityType: EntityType) {
  switch (entityType) {
    case 'gdoc':
      return ConnectorIcons.GoogleDocs;
    case 'gsheet':
      return ConnectorIcons.GoogleSheets;
    case 'gslide':
      return ConnectorIcons.GoogleSlides;
  }
}
