// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1343
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';
import type { StackAccessLevel } from '../enums/stack_access_level';

// Event_name: 'open.dash_share_modal'
// Description: Fires when user opens the sharing modal
// Owner: content-ingest
export type Open_DashShareModal = PAPEventGeneric<
  'stacks',
  'open',
  'dash_share_modal',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The stack id that represents the stack
    stackId?: string;
    // User_id associated with the creator of the content being acted upon
    creatorId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // True is Public link sharing is disabled, False otherwise
    isPublicLinkDisabled?: boolean;
    // True if public sharing is allowed, false otherwise
    isPublicSharingAllowed?: boolean;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // The access level settings for a stack
    stackAccessLevel?: StackAccessLevel;
  }
>;

export function PAP_Open_DashShareModal(
  properties?: Open_DashShareModal['properties'],
): Open_DashShareModal {
  return <Open_DashShareModal>{
    class: 'stacks',
    action: 'open',
    object: 'dash_share_modal',
    properties,
  };
}
