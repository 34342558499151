import type { IconProps } from './IconProps';

export function GoogleSuite({ size = 36 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8334 8.13008C13.8334 7.65046 13.7937 7.30046 13.7078 6.9375H8.11914V9.10229H11.3995C11.3334 9.64027 10.9763 10.4505 10.1826 10.9949L10.1715 11.0673L11.9385 12.4089L12.0609 12.4208C13.1852 11.4032 13.8334 9.906 13.8334 8.13008"
        fill="#2684FC"
      />
      <path
        d="M8.11922 13.8329C9.72634 13.8329 11.0755 13.3144 12.061 12.42L10.1827 10.994C9.68006 11.3375 9.00544 11.5774 8.11922 11.5774C6.54516 11.5774 5.2092 10.5598 4.73296 9.15332L4.66315 9.15913L2.82579 10.5526L2.80176 10.6181C3.78058 12.5236 5.79116 13.8329 8.11922 13.8329Z"
        fill="#00AC47"
      />
      <path
        d="M4.73285 9.15414C4.6072 8.79118 4.53447 8.40226 4.53447 8.00043C4.53447 7.59855 4.6072 7.20968 4.72624 6.84672L4.72291 6.76942L2.86252 5.35352L2.80165 5.38189C2.39823 6.17264 2.16675 7.06062 2.16675 8.00043C2.16675 8.94024 2.39823 9.82818 2.80165 10.6189L4.73285 9.15414"
        fill="#FFBA00"
      />
      <path
        d="M8.11922 4.42253C9.23692 4.42253 9.99088 4.89568 10.4208 5.29107L12.1007 3.68366C11.069 2.74385 9.72634 2.16699 8.11922 2.16699C5.79116 2.16699 3.78058 3.47624 2.80176 5.38178L4.72635 6.84661C5.2092 5.44013 6.54516 4.42253 8.11922 4.42253"
        fill="#EA4335"
      />
      <path
        d="M27.0557 12.2222H29.0001C29.4605 12.2222 29.8334 11.8569 29.8334 11.4059V5.55566L27.0557 7.59644V12.2222Z"
        fill="#34A853"
      />
      <path
        d="M19.0001 12.2222H20.9445V7.59644L18.1667 5.55566V11.4059C18.1667 11.8569 18.5397 12.2222 19.0001 12.2222Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9443 7.77706V4.16602L23.9998 6.3882L27.0553 4.16602V7.77706L23.9998 9.99925L20.9443 7.77706Z"
        fill="#EA4335"
      />
      <path
        d="M27.0557 4.16815V7.77738L29.8334 5.69513V4.5846C29.8334 3.55528 28.6577 2.96739 27.8334 3.58512L27.0557 4.16815Z"
        fill="#FBBC04"
      />
      <path
        d="M18.1667 4.5846V5.69513L20.9445 7.77738V4.16815L20.1667 3.58512C19.3424 2.96739 18.1667 3.55528 18.1667 4.5846Z"
        fill="#C5221F"
      />
      <path
        d="M12.3954 29.7008C12.5938 29.5802 12.7629 29.4143 12.8805 29.2031L13.1157 28.7884L14.2403 26.79C14.3579 26.5789 14.4167 26.3451 14.4167 26.1113H10.3738L11.234 27.8458L12.3954 29.7008Z"
        fill="#EA4335"
      />
      <path
        d="M2.55347 28.2002L3.11943 29.2031C3.23703 29.4143 3.40609 29.5802 3.60454 29.7008L5.62583 26.1113H1.58325C1.58325 26.3451 1.64205 26.5789 1.75965 26.79L2.55347 28.2002Z"
        fill="#0066DA"
      />
      <path
        d="M7.99993 21.8883L5.97864 18.2988C5.78019 18.4195 5.61113 18.5854 5.49353 18.7965L1.75965 25.4326C1.64421 25.6392 1.58341 25.8731 1.58325 26.1113H5.62583L7.99993 21.8883Z"
        fill="#00AC47"
      />
      <path
        d="M10.3742 26.1113H5.62603L3.60474 29.7008C3.80319 29.8215 4.03104 29.8818 4.26625 29.8818H11.734C11.9692 29.8818 12.1971 29.8139 12.3955 29.7008L10.3742 26.1113Z"
        fill="#2684FC"
      />
      <path
        d="M8.00005 21.8887L10.0213 18.2991C9.82289 18.1785 9.59503 18.1182 9.35983 18.1182H6.64027C6.40507 18.1182 6.17721 18.186 5.97876 18.2991L8.00005 21.8887Z"
        fill="#00832D"
      />
      <path
        d="M12.3733 22.1146L10.5064 18.7965C10.3888 18.5854 10.2197 18.4195 10.0213 18.2988L8 21.8883L10.3741 26.1113H14.4093C14.4093 25.8775 14.3505 25.6437 14.2329 25.4326L12.3733 22.1146Z"
        fill="#FFBA00"
      />
      <path
        d="M24.5537 22.9959L24.8885 23.4723L25.4036 23.0989V25.8031H25.9573V22.249H25.4937L24.5537 22.9959Z"
        fill="#1E88E5"
      />
      <path
        d="M23.6653 23.9233C23.8713 23.7431 24.0001 23.4726 24.0001 23.1893C24.0001 22.6228 23.4979 22.1592 22.8926 22.1592C22.3776 22.1592 21.9269 22.4811 21.811 22.9576L22.3518 23.0992C22.4033 22.8803 22.6351 22.7258 22.8926 22.7258C23.2017 22.7258 23.4464 22.9318 23.4464 23.1893C23.4464 23.4469 23.2017 23.6529 22.8926 23.6529H22.5707V24.2195H22.8926C23.2403 24.2195 23.5365 24.4642 23.5365 24.7475C23.5365 25.0437 23.2532 25.2754 22.9055 25.2754C22.5965 25.2754 22.3261 25.0823 22.2874 24.8119L21.7466 24.902C21.8367 25.43 22.3389 25.8292 22.9184 25.8292C23.5751 25.8292 24.1031 25.3398 24.1031 24.7346C24.0902 24.4127 23.9228 24.1165 23.6653 23.9233Z"
        fill="#1E88E5"
      />
      <path
        d="M27.2579 29.8334H20.7678V27.2451H27.2579V29.8334Z"
        fill="#FBC02D"
      />
      <path
        d="M29.8464 27.2449V20.7549H27.2581V27.2449H29.8464Z"
        fill="#4CAF50"
      />
      <path
        d="M27.258 20.7553V18.167H19.1454C18.6046 18.167 18.1667 18.6048 18.1667 19.1457V27.2582H20.755V20.7553H27.258Z"
        fill="#1E88E5"
      />
      <path
        d="M27.2581 27.2451V29.8334L29.8464 27.2451H27.2581Z"
        fill="#E53935"
      />
      <path
        d="M28.8806 18.167H27.2581V20.7553H29.8464V19.1328C29.8464 18.5919 29.4214 18.167 28.8806 18.167Z"
        fill="#1565C0"
      />
      <path
        d="M19.1455 29.8334H20.768V27.2451H18.1797V28.8676C18.1797 29.4085 18.6046 29.8334 19.1455 29.8334Z"
        fill="#1565C0"
      />
    </svg>
  );
}
