import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';

export const useAugustRevisionEnabled = (
  includeExposureLogging = false,
): boolean => {
  const value = useFeatureFlagValue(
    'dash_2024_06_05_august_revision',
    includeExposureLogging,
  );

  return convertFeatureValueToBool(value);
};

export const useIsStartPageAugustRevisionEnabled = () => {
  const value = useFeatureFlagValue(
    'dash_2024_07_04_start_page_august_revision',
  );

  return convertFeatureValueToBool(value);
};

export const useAllStacksAugustRevisionEnabled = () => {
  const value = useFeatureFlagValue(
    'dash_2024_07_04_all_stacks_august_revision',
    false,
  );

  return convertFeatureValueToBool(value);
};

export const useStackPageAugustRevisionEnabled = () => {
  const value = useFeatureFlagValue(
    'dash_2024_07_04_stack_page_august_revision',
    false,
  );

  return convertFeatureValueToBool(value);
};
