import { Badge } from '@dropbox/dig-components/badge';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { useIsDfbUser } from '@mirage/shared/hooks/useIsDfbUser';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import styles from './BetaBadge.module.css';

interface Props {
  className?: string;
  excludeDfb?: boolean;
}

export const BetaBadge = ({ className, excludeDfb }: Props) => {
  const isDfbUser = useIsDfbUser();
  const removeBetaLabels = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_10_08_remove_beta_labels_for_dfb'),
  );

  // If supposed to render conditionally, don't render anything
  // until value is fetched. This prevents the badge flash.
  if (excludeDfb && isDfbUser === undefined) {
    return null;
  }

  // If only show for DfI and user is DfB, don't show
  if (excludeDfb && isDfbUser && removeBetaLabels) {
    return null;
  }

  return (
    <Badge className={classnames(styles.badge, className)}>
      {i18n.t('beta')}
    </Badge>
  );
};
