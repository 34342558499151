import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import i18n from '@mirage/translations';

export const CloneConfirmationModal = ({
  isOpen,
  onConfirm,
  onCancel,
}: {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <Modal
      open={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={onCancel}
      data-testid="CloneConfirmationModal"
    >
      <Modal.Header hasBottomSpacing="title-standard">
        <Modal.Title>{i18n.t('confirm_clone_stack_title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{i18n.t('confirm_clone_stack_body')}</Modal.Body>

      <Modal.Footer>
        <Button variant="opacity" onClick={onCancel}>
          {i18n.t('cancel')}
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {i18n.t('clone_stack')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
