export const CLICK_KEYS = {
  // Hours: 1, 4, 8
  LAST_1_HOUR: 'last1h',
  LAST_4_HOURS: 'last4h',
  LAST_8_HOURS: 'last8h',
  // Days: 1, 3, 7
  LAST_1_DAY: 'last1d',
  LAST_3_DAYS: 'last3d',
  LAST_7_DAYS: 'last7d',
} as const;

type ClickKey = (typeof CLICK_KEYS)[keyof typeof CLICK_KEYS];

export type ClickMap = {
  [key in ClickKey]: number;
};

export function generateClickMap(timestamps: number[]): ClickMap {
  const now = Date.now();

  const clickMap: ClickMap = {
    last1h: 0,
    last4h: 0,
    last8h: 0,
    last1d: 0,
    last3d: 0,
    last7d: 0,
  };

  timestamps.forEach((date) => {
    const diffInMs = now - date;
    const diffInHours = diffInMs / (1000 * 60 * 60);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    if (diffInHours <= 1) clickMap.last1h++;
    if (diffInHours <= 4) clickMap.last4h++;
    if (diffInHours <= 8) clickMap.last8h++;
    if (diffInDays <= 1) clickMap.last1d++;
    if (diffInDays <= 3) clickMap.last3d++;
    if (diffInDays <= 7) clickMap.last7d++;
  });

  return clickMap;
}
