import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { CommentLine } from '@dropbox/dig-icons/assets';
import i18n from '@mirage/translations';
import { QueryInput } from './QueryInput';
import styles from './SingleTurnQueryInput.module.css';

export const enum InputModes {
  initial,
  followUp, // Show input in follow-up mode, with cancel button
  hidden, // Hide input
}

type SingleTurnQueryInputProps = {
  mode: InputModes;
  onSubmit: (query: string) => void;
  onChange?: (query: string) => void;
  onChangeInputMode: (mode: InputModes) => void;
  buttonLabel: string;
  placeholder?: string;
  textInput?: JSX.Element;
  minHeight?: number;
};

export const SingleTurnQueryInput = ({
  mode,
  onSubmit,
  onChangeInputMode,
  onChange,
  buttonLabel,
  placeholder,
  textInput,
}: SingleTurnQueryInputProps) => {
  const showInput = mode === InputModes.followUp || mode === InputModes.initial;
  return (
    <>
      {showInput && (
        <>
          {textInput || (
            <QueryInput
              onSubmit={onSubmit}
              placeholder={placeholder}
              onChange={onChange}
              loading={false}
            />
          )}
          {mode === InputModes.followUp && (
            <div className={styles.cancelContainer}>
              <Button
                onClick={() => onChangeInputMode(InputModes.hidden)}
                variant="transparent"
              >
                {i18n.t('cancel')}
              </Button>
            </div>
          )}
        </>
      )}

      {mode === InputModes.hidden && (
        <div className={styles.tryAgainButtonContainer}>
          <Button
            className={styles.styledButton}
            variant="primary"
            size="large"
            withIconStart={<UIIcon src={CommentLine} />}
            onClick={() => onChangeInputMode(InputModes.followUp)}
          >
            {buttonLabel}
          </Button>
        </div>
      )}
    </>
  );
};
