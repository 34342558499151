import { Avatar } from '@dropbox/dig-components/avatar';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import styles from './UserNameCardChip.module.css';

interface Props {
  name: string;
}

/**
 * This is an asset that renders name dynamically, so it doesn't follow usual DIG sizing
 */
export const UserNameCardChip: React.FC<Props> = ({ name }) => {
  return (
    <Box
      flexDirection="column"
      backgroundColor="Identity Yellow"
      borderRadius="Circular"
      padding="12"
      justifyContent="center"
      alignItems="center"
    >
      <div className={styles.cardContainer}>
        <Avatar
          hasNoOutline
          isGuest
          // avatarColorPalette(name) will return colorPalette based on name, however, we have hardcoded background color
          // for the container, so we need to set specific colors to make it look good
          colorPalette={{
            background: 'var(--dig-color__identity__on-yellow)',
            foreground: '#CE911E', // NO DIG color variable, but color from design's SVG
          }}
          // There is no DIG size for this
          style={{ width: '59px', height: '59px' }}
        />
        <Text className={styles.name}>{name}</Text>
      </div>
    </Box>
  );
};
