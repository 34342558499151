// Source of truth for onboarding versions supported by the dash web app.
export enum ONBOARDING_VERSION {
  // single-step & single-step-mobile allow users to skip connecting a connector.
  SINGLE_STEP = 'single-step',
  SINGLE_STEP_MOBILE = 'single-step-mobile',

  // single-step-b shows an educational modal when user skips connecting a connector.
  SINGLE_STEP_B = 'single-step-b',

  // single-step-c requires the user to connect at least one connector.
  SINGLE_STEP_C = 'single-step-c',

  // one-click redirects users once they click to connect Dropbox.
  ONE_CLICK = 'one-click',

  // Renders Google- and Microsoft-suite connectors as groups, so user can connect all group connectors at once.
  ECOSYSTEM_CONNECTORS = 'ecosystem-connectors',

  // JTBD
  SURVEY_JOBS_TO_BE_DONE = 'jtbd',

  // For DfB users
  TEAM = 'team',
}
