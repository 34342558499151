import { Button } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { LookMagnifyingGlassSpot } from '@dropbox/dig-illustrations';
import styles from './Error.module.css';

type ErrorProps = {
  title: string;
  subtitle: string;
  actionText: string;
  hideAction?: boolean;
  onActionClick: () => void;
};

export const Error = ({
  title,
  subtitle,
  actionText,
  hideAction,
  onActionClick,
}: ErrorProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <LookMagnifyingGlassSpot />
      </div>
      <Title className={styles.title} size="small">
        {title}
      </Title>
      <Text color="subtle">{subtitle}</Text>
      {!hideAction && (
        <Button
          onClick={onActionClick}
          className={styles.actionButton}
          variant="primary"
        >
          {actionText}
        </Button>
      )}
    </div>
  );
};
