import {
  connectionsByTypeAtom,
  useConnections,
} from '@mirage/shared/atoms/connections';
import { connectorsAtom } from '@mirage/shared/atoms/connectors';
import {
  getConnectionMetadata,
  sortConnectionsByAuthTypeAndDisplayName,
  sortConnectorsByDisplayName,
} from '@mirage/shared/connectors/util';
import { UIConnection, UIConnector } from '@mirage/shared/types';
import { isDefined } from '@mirage/shared/util/tiny-utils';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';

export const useOnboardingConnectors = () => {
  // These render on top of the list
  const [alreadyConnectedTypes, setAlreadyConnectedTypes] = useState<
    string[] | null
  >(null);
  // This is shown after alreadyConnectedTypes, and the order should not change when connections are added
  const [sortedConnectorTypes, setSortedConnectorTypes] = useState<string[]>(
    [],
  );
  const { loaded: connectionLoaded, data: connections } = useConnections();
  const { loaded: connectorsLoaded, data: connectors } =
    useAtomValue(connectorsAtom);
  const [connectorsSortedByDisplayName, setConnectorsSortedByDisplayName] =
    useState<UIConnector[]>([]);
  const connectionsByType = useAtomValue(connectionsByTypeAtom);

  useEffect(() => {
    // Set initial list of connectors & connections once on page load and store "type" in sorted order in state
    if (
      alreadyConnectedTypes === null &&
      connectionLoaded &&
      connectorsLoaded
    ) {
      // iterate through connections
      const connectionTypes = connections
        .map((c) => c.id_attributes?.connector?.type)
        .filter(isDefined);
      setAlreadyConnectedTypes(connectionTypes);
      // sort connectors alphabetically
      const sortedConnectors = sortConnectorsByDisplayName(connectors);
      setConnectorsSortedByDisplayName(sortedConnectors);
      setSortedConnectorTypes(
        sortedConnectors
          .map((c) => c.id_attrs?.type)
          .filter(isDefined)
          .filter((type) => !connectionTypes.includes(type)),
      );
    }
  }, [
    connectorsLoaded,
    connectionLoaded,
    connections,
    alreadyConnectedTypes,
    connectors,
    connectorsSortedByDisplayName,
  ]);

  // Get connections that are already connected on page load time
  const topConnections = useMemo(() => {
    return sortConnectionsByAuthTypeAndDisplayName(connections).filter((c) => {
      const { type } = getConnectionMetadata(c);
      return type && alreadyConnectedTypes?.includes(type);
    });
  }, [alreadyConnectedTypes, connections]);

  // These are initially just UIConnectors, but can contain connections as user connect connectors after page load
  const restOfConnectorOrConnection: (UIConnection | UIConnector)[] =
    useMemo(() => {
      return sortedConnectorTypes
        .map((type) => {
          if (connectionsByType[type]) {
            // UIConnection case
            return connectionsByType[type];
          } else {
            // UIConnector case
            return connectorsSortedByDisplayName.find(
              (c) => c.id_attrs?.type === type,
            );
          }
        })
        .filter(isDefined);
    }, [
      sortedConnectorTypes,
      connectionsByType,
      connectorsSortedByDisplayName,
    ]);

  // Combine connections and connectors as a single array in the same order as initial page load time
  const orderedConnectors: (UIConnection | UIConnector)[] = useMemo(() => {
    return ([] as (UIConnection | UIConnector)[])
      .concat(topConnections)
      .concat(restOfConnectorOrConnection);
  }, [topConnections, restOfConnectorOrConnection]);

  return orderedConnectors;
};
