import { stacks } from '@dropbox/api-v2-client';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Cancel_DashRenameLink } from '@mirage/analytics/events/types/cancel_dash_rename_link';
import { PAP_Cancel_DeleteDashStack } from '@mirage/analytics/events/types/cancel_delete_dash_stack';
import { PAP_Initiate_DeleteDashStack } from '@mirage/analytics/events/types/initiate_delete_dash_stack';
import { PAP_Rename_DashLink } from '@mirage/analytics/events/types/rename_dash_link';
import { stackDerivePAPProps, updateStackItem } from '@mirage/service-stacks';
import {
  stackItemGetName,
  stackItemSetName,
} from '@mirage/service-stacks/service/utils';
import { showSnackbar } from '@mirage/shared/snackbar';
import { DeleteConfirmationModal } from '@mirage/stacks/DeleteConfirmationModal';
import { EditStackItemModal } from '@mirage/stacks/EditStackItemModal';
import { stackGetName } from '@mirage/stacks/Helpers/Utils';
import {
  useDeleteStackModal,
  useEditStackItemModal,
  useSortedStacks,
} from '@mirage/stacks/hooks';
import i18n from '@mirage/translations';
import { useCallback, useEffect } from 'react';

export const GlobalModalContainer = () => {
  // Container to host all global modals that need to work for web and hornet
  const { reportPapEvent } = useMirageAnalyticsContext();
  const allStacks = useSortedStacks();

  // Delete stack modal
  const {
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
    onDelete,
    setStackToDelete,
    stackToDelete,
  } = useDeleteStackModal();
  const onCancel = () => {
    setStackToDelete(undefined);
    setShowDeleteConfirmationModal(false);
  };

  const { stackItemAndStackToEdit, setStackItemAndStackToEdit } =
    useEditStackItemModal();

  const renameStackItem = useCallback(
    async (itemToEdit: stacks.StackItem, newName: string) => {
      const stack = stackItemAndStackToEdit?.stack;
      if (!stack) return;
      const namespaceId = stack.namespace_id;

      reportPapEvent(
        PAP_Rename_DashLink({
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
        }),
      );

      await updateStackItem(
        namespaceId ?? '',
        stackItemSetName(itemToEdit, newName),
      );
    },
    [reportPapEvent, stackItemAndStackToEdit],
  );

  useEffect(() => {
    if (stackToDelete) {
      reportPapEvent(
        PAP_Initiate_DeleteDashStack({
          ...stackDerivePAPProps(stackToDelete),
          featureLine: 'stacks',
        }),
      );
    }
  }, [stackToDelete, reportPapEvent]);

  return (
    <div>
      <DeleteConfirmationModal
        onCancel={() => {
          if (stackToDelete) {
            reportPapEvent(
              PAP_Cancel_DeleteDashStack({
                ...stackDerivePAPProps(stackToDelete),
                featureLine: 'stacks',
              }),
            );
          }
          onCancel();
        }}
        isOpen={showDeleteConfirmationModal}
        onSubmit={() => {
          onDelete();
          let title = i18n.t('stack_successfully_deleted');
          if (stackToDelete) {
            const stackName = stackGetName(stackToDelete);
            title = i18n.t('stack_successfully_deleted_with_stackname', {
              stackName,
            });
          }
          showSnackbar({ title });
          onCancel();
        }}
      />
      {stackItemAndStackToEdit && (
        <EditStackItemModal
          originalStackItemName={stackItemGetName(
            stackItemAndStackToEdit.item,
            allStacks,
          )}
          isOpen={true}
          onCancel={() => {
            reportPapEvent(
              PAP_Cancel_DashRenameLink({
                ...stackDerivePAPProps(stackItemAndStackToEdit.stack),
                featureLine: 'stacks',
              }),
            );
            setStackItemAndStackToEdit(undefined);
          }}
          onSubmit={async (data: { name?: string }) => {
            if (!stackItemAndStackToEdit.item || !data.name) return;
            renameStackItem(stackItemAndStackToEdit.item, data.name);
            setStackItemAndStackToEdit(undefined);
            showSnackbar({ title: i18n.t('stack_item_updated') });
          }}
        />
      )}
    </div>
  );
};
