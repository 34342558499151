import { jaccard } from './jaccard';
// attempts to do some level of similarity scoring between the query and the
// result's title using a jaccard score that heavily indexes on full word
// matches and slightly on charachter matches
export default function titleMatch(_query: string, _title: string): number {
  const title = _title.toLowerCase();
  const query = _query.toLowerCase();

  const fullWord = jaccard(query, title, (value) => value.split(/\s+/));

  const charMatches = jaccard(query, title, (value) =>
    value.replace(/\s+/g, '').split(''),
  );

  return fullWord * 0.95 + charMatches * 0.05;
}
