import { isDropboxerAccount } from '@mirage/shared/account';
import { FeatureValue } from './features';

import type { users } from '@dropbox/api-v2-client';
import type { ExperimentationAttributes } from '@mirage/service-experimentation/service';

export const createExperimentationAttributes = (
  installId: string | undefined,
  sessionId: string | undefined,
  account?: users.FullAccount,
  featureRing?: string,
): ExperimentationAttributes => {
  return {
    device_id: installId,
    session_id: sessionId,
    id: account?.account_id, // Deprecated
    team_id: account?.team?.id, // Deprecated
    public_user_id:
      account?.public_account_id && atob(account?.public_account_id),
    public_team_id:
      account?.team?.public_team_id && atob(account?.team?.public_team_id),
    country: account?.country,
    locale: account?.locale,
    employee: isDropboxerAccount(account),
    dbx51_email_regex: account?.email,
    feature_rollout_ring: featureRing,
  };
};

/**
 * DS is requesting growthbook attributes of ON/OFF/CONTROL
 * Simple helper to convert string to boolean
 * @param value
 */
export function convertFeatureValueToBool(value: FeatureValue | string) {
  if (value === undefined) return false;
  if (typeof value === 'boolean') return value;
  if (typeof value === 'string') return value === 'ON';
  return false;
}
