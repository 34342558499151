import { nonNil } from '@mirage/shared/util/tiny-utils';
import isEqual from 'lodash/isEqual';
import { Subject } from 'rxjs';
import { StacksStore } from './store';

import type { LargeKvStore } from '../../storage/large-kv-store/interface';
import type { stacks } from '@dropbox/api-v2-client';

/** Combine the StacksStore with change notifications. */
export class StacksCache {
  private readonly stacksStore: StacksStore;

  public constructor(
    largeKvStore: LargeKvStore,
    public readonly stacksSubject = new Subject<stacks.Stack[]>(),
    public readonly stackItemsSubject = new Subject<{
      namespaceId: string;
      items: stacks.StackItem[];
    }>(),
    public readonly publishedContentSubject = new Subject<
      stacks.PublishedContent[]
    >(),
  ) {
    this.stacksStore = new StacksStore(largeKvStore);
  }

  public async setUserBoltData(
    boltData: stacks.BoltData | undefined,
  ): Promise<void> {
    await this.stacksStore.setUserBoltData(boltData);
  }

  public async getUserBoltData(): Promise<stacks.BoltData | undefined> {
    return await this.stacksStore.getUserBoltData();
  }

  private async stacksUpdated(stacks: stacks.Stack[]): Promise<stacks.Stack[]> {
    this.stacksSubject.next(stacks);
    return stacks;
  }

  public async getStacks(): Promise<stacks.Stack[] | undefined> {
    return await this.stacksStore.getStacks();
  }

  /**
   * Ensure you don't diff stacks before updating cache values. Perform that
   * check when updating in memory cache (jotai atom, e.g.) instead. Otherwise
   * observable will not trigger across tabs in same browser.
   * @param stacks
   * @returns Promise<stacks>
   */
  public async setStacks(stacks: stacks.Stack[]): Promise<stacks.Stack[]> {
    const dedupedStacks = await this.stacksStore.setStacks(stacks);
    return await this.stacksUpdated(dedupedStacks ?? []);
  }

  public async getStack(
    namespaceId: string,
  ): Promise<stacks.Stack | undefined> {
    return await this.stacksStore.getStack(namespaceId);
  }

  /**
   * Ensure you don't diff the stack before updating cache values. Perform that
   * check when updating in memory cache (jotai atom, e.g.) instead. Otherwise
   * observable will not trigger across tabs in same browser.
   * @param stack
   * @returns Promise<stack>
   */
  public async setStack(stack: stacks.Stack): Promise<stacks.Stack[]> {
    const stacks = await this.stacksStore.setStack(stack);
    return await this.stacksUpdated(stacks);
  }

  public async removeStack(namespaceId: string): Promise<stacks.Stack[]> {
    const stacks = await this.stacksStore.removeStack(namespaceId);
    return await this.stacksUpdated(stacks);
  }

  public async stackItemsUpdated(
    namespaceId: string,
    items: stacks.StackItem[],
  ): Promise<void> {
    this.stackItemsSubject.next({ namespaceId, items });
  }

  public async getStackItems(
    namespaceId: string,
  ): Promise<stacks.StackItem[] | undefined> {
    return this.stacksStore.getStackItems(namespaceId);
  }

  public async multiGetStackItems(
    namespaceIds: string[],
  ): Promise<{ [nsid: string]: stacks.StackItem[] }> {
    return this.stacksStore.multiGetStackItems(namespaceIds);
  }

  public async setStackItems(
    namespaceId: string,
    items: stacks.StackItem[] | undefined,
  ): Promise<void> {
    const oldItems = await this.getStackItems(namespaceId);
    if (isEqual(items, oldItems)) {
      return;
    }

    await this.stacksStore.setStackItems(namespaceId, items);
    await this.stackItemsUpdated(namespaceId, items ?? []);
  }

  public async publishedContentsUpdated(
    contents: stacks.PublishedContent[],
  ): Promise<void> {
    await this.publishedContentSubject.next(contents);
  }

  public async setPublishedContents(
    contents: stacks.PublishedContent[],
  ): Promise<stacks.PublishedContent[] | undefined> {
    const result = await this.stacksStore.setPublishedContents(contents);
    await this.publishedContentsUpdated(result ?? []);
    return result;
  }

  public async getPublishedContents(): Promise<
    stacks.PublishedContent[] | undefined
  > {
    return await this.stacksStore.getPublishedContents();
  }

  public async getPublishedStacks(): Promise<stacks.Stack[] | undefined> {
    const publishedContent = await this.stacksStore.getPublishedContents();
    return (publishedContent ?? [])
      .filter((content) => content['.tag'] === 'stack')
      .map((content) => content as stacks.Stack);
  }

  public async setPublishStack(
    stack: stacks.Stack,
  ): Promise<stacks.PublishedContent[] | undefined> {
    const content = await this.stacksStore.setPublishedStack(stack);
    await this.publishedContentsUpdated(content);
    return content;
  }

  public async unpublishStack(
    namespaceId: string,
  ): Promise<stacks.PublishedContent[]> {
    const content = await this.stacksStore.unpublishStack(namespaceId);
    await this.publishedContentsUpdated(content);
    return content;
  }

  public async getPublishedStack(
    namespaceId: string,
  ): Promise<stacks.Stack | undefined> {
    const contents = await this.getPublishedStacks();
    return contents?.find((c) => c.namespace_id === namespaceId);
  }

  // ---------------------------------------------------
  // Additional helper methods unrelated to StacksStore.
  // ---------------------------------------------------

  public async requireStack(namespaceId: string) {
    return nonNil(
      await this.getStack(namespaceId),
      `stack with nsid ${namespaceId}`,
    );
  }

  public async requireStackItems(namespaceId: string) {
    return nonNil(
      await this.getStackItems(namespaceId),
      `stack items for nsid ${namespaceId}`,
    );
  }

  public async revertStack(oldStack: stacks.Stack, newStack: stacks.Stack) {
    const storedStack = await this.getStack(newStack.namespace_id ?? '');

    if (isEqual(storedStack, newStack)) {
      this.setStack(oldStack);
    }
  }

  public async revertStackItems(
    namespaceId: string,
    oldItems: stacks.StackItem[],
    newItems: stacks.StackItem[],
  ) {
    const storedItems = await this.getStackItems(namespaceId);

    if (isEqual(storedItems, newItems)) {
      this.setStackItems(namespaceId, oldItems);
    }
  }
}
