import * as React from "react";
import { UIIcon, type UIIconProps as DigUIIconProps } from "@dropbox/dig-icons";
import { atoms } from "@dropbox/dig-foundations";
import classNames from "classnames";
import css from "./UIIcon.module.css";

import { MetadataContext } from "./context";

export type UIIconProps = DigUIIconProps & {
  size?: never;
};
const atomStyles = atoms({ flexBasis: "auto", flexGrow: 0, flexShrink: 0 });

/**
 * This is a wrapper around the DIG `UIIcon` component that handles the size prop internally.
 */
export const _UIIcon: React.FC<UIIconProps> = ({ className, ...props }) => {
  const { size } = React.useContext(MetadataContext);
  const cls = classNames(atomStyles, className, css.uiIconRoot, {
    [css.iconMedium]: size === "medium",
  });
  return <UIIcon {...props} className={cls} size={"small"} />;
};
