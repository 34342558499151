import { Text } from '@dropbox/dig-components/typography';
import { BlocksMini } from '@dropbox/dig-illustrations';
import * as React from 'react';
import styles from './NoContentPlaceholder.module.css';

export const NoContentPlaceholder = ({ message }: { message: string }) => {
  return (
    <div className={styles.noContentContainer}>
      <div className={styles.iconContainer}>
        <BlocksMini altText={message} />
      </div>
      <Text color="faint">{message}</Text>
    </div>
  );
};
