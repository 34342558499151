import {
  CalendarIcon,
  type ContentIconProps,
} from '@dropbox/dash-component-library';
import { format } from 'date-fns';

type Props = {
  eventDate: Date;
  size?: ContentIconProps['size'];
  sourceIcon?: React.ReactNode;
};

export default function EventIcon({
  eventDate,
  size = 'medium',
  sourceIcon,
}: Props) {
  return (
    <CalendarIcon
      size={size}
      date={eventDate}
      dateFormatterFn={(date: Date) => ({
        namedDay: format(date, 'EEE').toUpperCase(),
        numericDay: format(date, 'dd'),
      })}
      badge={sourceIcon}
    />
  );
}
