import useSprigSurvey from './useSprigSurvey';

import type { DashSurface } from '@mirage/analytics/events/enums/dash_surface';

type SurveyProviderProps = {
  surface: DashSurface;
  environmentId: string;
  disabled?: boolean;
  publicAccountId?: string;
};

export const SurveyProvider: React.FC<SurveyProviderProps> = ({ ...props }) => {
  // Connect service-feedback to sprig
  useSprigSurvey(props);
  return null;
};
