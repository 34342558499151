// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1997
import type { PAPEventGeneric } from '../base/event';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashAnnotationMode } from '../enums/dash_annotation_mode';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { EndReason } from '../enums/end_reason';
import type { FeatureLine } from '../enums/feature_line';

// Event_name: 'finish.search_attempt'
// Description: A period of time where the user was typing a search query in Dash. The period ends when (a) the user hits Escape, (b) the user manually clears the query, or (c) the associated Dash Session or Search Session times out.
// Owner: otc
export type Finish_SearchAttempt = PAPEventGeneric<
  'dash',
  'finish',
  'search_attempt',
  {
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // the end time of the event in ms
    endTimeMs?: number;
    // The reason the session ended
    endReason?: EndReason;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The surface in which an action was taken on Dash.
    dashActionSurface?: DashActionSurface;
    // The annotation mode (if user is annotating).
    dashAnnotationMode?: DashAnnotationMode;
  }
>;

export function PAP_Finish_SearchAttempt(
  properties?: Finish_SearchAttempt['properties'],
): Finish_SearchAttempt {
  return <Finish_SearchAttempt>{
    class: 'dash',
    action: 'finish',
    object: 'search_attempt',
    properties,
  };
}
