import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { useConnections } from '@mirage/shared/atoms/connections';
import {
  connectorsSortedByDisplayNameAtom,
  useConnectors,
} from '@mirage/shared/atoms/connectors';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import { useAtomValue } from 'jotai';
import { EcosystemConnectors } from './EcosystemConnectors';
import { OnboardingConnectors } from './OnboardingConnectors';

export const EcosystemConnectorsList = () => {
  const { loaded: connectionsLoaded } = useConnections();
  const { loaded: connectorsLoaded } = useConnectors();
  const connectorsSortedByDisplayName = useAtomValue(
    connectorsSortedByDisplayNameAtom,
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      style={{ gap: '16px' }}
    >
      <Box>
        <EcosystemConnectors
          browserExtensionData={[]}
          title={i18n.t('onboarding_connectors_section_recommended')}
        />
      </Box>
      <Box>
        <OnboardingConnectors
          browserExtensionData={[]}
          connectors={connectorsSortedByDisplayName}
          showDescriptions
          showSnackbar={showSnackbar}
          title={i18n.t('onboarding_connectors_section_all')}
        />
      </Box>
      {connectorsLoaded && connectionsLoaded && (
        <Text size="large" color="faint" style={{ textAlign: 'center' }}>
          {i18n.t('more_apps_coming_soon')}
        </Text>
      )}
    </Box>
  );
};
