import { stacks } from '@dropbox/api-v2-client';
import { Button } from '@dropbox/dig-components/buttons';
import { Modal, ModalWidth } from '@dropbox/dig-components/modal';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { DashStackIcon } from '@mirage/dash-component-library/components/DashStackIcon';
import { useIsPublicSharingAllowed } from '@mirage/service-stack-admin-settings/hooks';
import {
  createStackItem,
  listStackItemsFromCache,
} from '@mirage/service-stacks';
import { getTitleOfUrl } from '@mirage/service-stacks/service/url-title';
import { ALWAYS_TRUE, asShortcut } from '@mirage/service-stacks/service/utils';
import { showSnackbar } from '@mirage/shared/snackbar';
import { useModal } from '@mirage/shared/util/showModal';
import { sleepMs } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import { FC, useMemo, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { FullScreenStackV2IncomingState } from '../FullScreenStack/types';
import { useSortedStacks } from '../hooks';
import { accessLevelIconSrcForMemberCount } from '../ShareModal/Utils';
import { InitialStackItemInfo } from '../types';
import styles from './StackChooser.module.css';

type StackChooserModalProps = {
  open: boolean;
  onRequestClose: (stack?: stacks.Stack) => void;
  width?: ModalWidth;
  shouldCloseOnOverlayClick?: boolean;
  onSelectStack: (stack: stacks.Stack) => void;
  stackPredicate: (stack: stacks.Stack) => boolean;
  navigate: NavigateFunction;
  itemsToAdd?: InitialStackItemInfo[];
  // Temporary prop to use the search term as the stack title for the tmp full page create
  searchTerm?: string;
};

export const StackChooserModal: FC<StackChooserModalProps> = ({
  open,
  onRequestClose,
  width,
  shouldCloseOnOverlayClick,
  onSelectStack,
  stackPredicate,
  navigate,
  itemsToAdd,
  searchTerm,
}) => {
  const { searchText, setSearchText, allStacks, filteredStacks } =
    useStackChooserData(stackPredicate);
  const isPublicSharingAllowed = useIsPublicSharingAllowed();

  const redirectToCreateStack = () => {
    const items: stacks.StackItem[] =
      itemsToAdd?.map((item) => ({
        url: item.url,
        metadata_title: item.metadata_title,
        '.tag': 'shortcut',
      })) ?? [];

    const state: FullScreenStackV2IncomingState = {
      name: searchTerm || '',
      colorIndex: 0,
      items: items,
    };
    onRequestClose();
    navigate('/stacks/new', { state });
  };

  return (
    <Modal
      open={open}
      onRequestClose={() => onRequestClose()}
      width={width}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <Modal.Header>
        {allStacks.length > 0 && (
          <SearchBarHeader
            searchText={searchText}
            setSearchText={setSearchText}
            showRecentStacksLabel={filteredStacks.length > 0}
          />
        )}
      </Modal.Header>
      <Modal.Body>
        {filteredStacks.map((stack) => (
          <div key={stack.namespace_id} className={styles.stackRowContainer}>
            <SelectableStack
              stack={stack}
              onClick={async () => {
                // Always close on selection.
                onRequestClose(stack);
                // Avoid UI flicker
                await sleepMs(10);
                onSelectStack(stack);
              }}
              isPublicSharingAllowed={isPublicSharingAllowed}
            />
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <CreateStackButton onClick={redirectToCreateStack} />
      </Modal.Footer>
    </Modal>
  );
};

function useStackChooserData(stackPredicate: (stack: stacks.Stack) => boolean) {
  const [searchText, setSearchText] = useState('');

  const sortedStacks = useSortedStacks();
  const allStacks = useMemo(
    () => sortedStacks?.filter((stack) => stackPredicate(stack)) ?? [],
    [sortedStacks, stackPredicate],
  );

  // Each stack title must include all query terms (separated by spaces)
  const filteredStacks = useMemo(() => {
    let searchedStacks = allStacks;
    if (searchText) {
      const queryParts = searchText
        .split(' ')
        .map((queryPart) => queryPart.toLowerCase());
      searchedStacks = allStacks.filter((stack) => {
        const lowerCaseStackName = stack.stack_data?.name?.toLowerCase() ?? '';
        for (const queryPart of queryParts) {
          if (!lowerCaseStackName.includes(queryPart)) {
            return false;
          }
        }
        return true;
      });
    }
    return searchedStacks.slice(0, 5);
  }, [allStacks, searchText]);

  return { searchText, setSearchText, allStacks, filteredStacks };
}

const SearchBarHeader: FC<{
  searchText: string;
  setSearchText: (s: string) => void;
  showRecentStacksLabel: boolean;
}> = ({ searchText, setSearchText, showRecentStacksLabel }) => {
  return (
    <div className={styles.headerRow}>
      <TextInput
        placeholder={i18n.t('search_stacks')}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      {showRecentStacksLabel && (
        <Text size="small" color="faint">
          {i18n.t('recent_stacks')}
        </Text>
      )}
    </div>
  );
};

const SelectableStack: FC<{
  stack: stacks.Stack;
  onClick: () => void;
  isPublicSharingAllowed?: boolean;
}> = ({ stack, onClick, isPublicSharingAllowed }) => {
  return (
    <Button
      variant="borderless"
      onClick={onClick}
      className={styles.stackButton}
    >
      <div className={styles.stackRow}>
        <DashStackIcon
          colorIndex={stack.stack_data?.color_index ?? 0}
          emoji={stack.stack_data?.emoji}
          size="small"
        />
        <UIIcon
          src={accessLevelIconSrcForMemberCount(
            stack.sharing_data?.members?.length ?? 0,
            stack.sharing_data?.shared_link?.access_level,
            stack.publish_data?.is_published,
            isPublicSharingAllowed,
          )}
          size="small"
          className={styles.accessIcon}
        />
        <Text className={styles.stackText}>{stack.stack_data?.name}</Text>
      </div>
    </Button>
  );
};

const CreateStackButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button
      variant="outline"
      className={styles.createStackButton}
      onClick={onClick}
    >
      {i18n.t('create_stack')}
    </Button>
  );
};

export function useShowStackChooserModal(navigate: NavigateFunction) {
  const { showModal, hideModal } = useModal();

  return ({
    metadata_title,
    url,
    callback,
    searchTerm,
  }: {
    metadata_title?: string;
    url: string;
    callback: (stack?: stacks.Stack) => void;
    searchTerm?: string;
  }) => {
    const onStackChooserClose = (stack?: stacks.Stack) => {
      if (!stack) {
        // If a stack is passed in, it is logged elsewhere
        callback(undefined);
      }
      hideModal();
    };

    showModal(
      <StackChooserModal
        open={true}
        onRequestClose={onStackChooserClose}
        navigate={navigate}
        width={400}
        shouldCloseOnOverlayClick={true}
        onSelectStack={async (stack) => {
          const nsId = stack.namespace_id ?? '';

          // Check stack to make sure item does not already exist.
          const existingItems = await listStackItemsFromCache(nsId);

          if (existingItems?.find((item) => asShortcut(item).url === url)) {
            showSnackbar({
              title: i18n.t('stack_item_already_exists'),
            });
            callback(undefined);
            return;
          }

          showSnackbar({
            title: i18n.t('adding_stack_item'),
            timeoutMs: 0,
          });

          // This could take a few seconds...
          try {
            await createStackItem(nsId, url, metadata_title);
            callback(stack);
            showSnackbar({
              title: i18n.t('stack_item_added'),
            });
          } catch {
            callback(undefined);
            showSnackbar({
              title: i18n.t('failed_to_add_stack_item'),
            });
          }
        }}
        stackPredicate={ALWAYS_TRUE}
        itemsToAdd={[
          {
            metadata_title:
              metadata_title ?? getTitleOfUrl(url, metadata_title),
            url,
          },
        ]}
        searchTerm={searchTerm}
      />,
    );
  };
}
