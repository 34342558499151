import { Text } from '@dropbox/dig-components/typography';
import { Split } from '@dropbox/dig-foundations';
import { Box } from '@dropbox/dig-foundations';

export const ModuleHeader = ({
  title,
  actions,
}: {
  title: string;
  actions?: React.ReactNode;
}) => {
  return (
    <Split paddingX="12" alignY="center">
      <Split.Item marginRight="auto">
        <Box
          as={Text}
          tagName="h2"
          margin="0"
          isBold
          style={{ lineHeight: 'var(--dig-spacing__macro__medium)' }}
        >
          {title}
        </Box>
      </Split.Item>
      <Split.Item>{actions}</Split.Item>
    </Split>
  );
};
