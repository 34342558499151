import { stacks } from '@dropbox/api-v2-client';
import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text, Title } from '@dropbox/dig-components/typography';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Select_StacksAction } from '@mirage/analytics/events/types/select_stacks_action';
import { PAP_View_DashStack } from '@mirage/analytics/events/types/view_dash_stack';
import {
  createUxaElementId,
  dispatchElementClicked,
} from '@mirage/analytics/uxa';
import { listPublishedContent, unpublishStack } from '@mirage/service-stacks';
import { stackDerivePAPProps } from '@mirage/service-stacks/service/utils';
import { DashSpinnerAutofitContainer } from '@mirage/shared/dash-spinner/DashSpinnerAutofitContainer';
import { showSnackbar } from '@mirage/shared/snackbar';
import { publishedContentsAtom } from '@mirage/stacks/hooks';
import { isPublishedContentsLoading } from '@mirage/stacks/useInitPublishContentsData';
import i18n from '@mirage/translations';
import { useAtom, useAtomValue } from 'jotai';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PublishedContentEmptyState } from './PublishedContentEmptyState';
import PublishedContentListTable from './PublishedContentTable';

export const ListPublishedContent = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [stackToUnpublish, setStackToUnpublish] = useState<
    stacks.Stack | undefined
  >(undefined);

  const isLoading = useAtomValue(isPublishedContentsLoading);

  const [publishedContents, setPublishedContents] = useAtom(
    publishedContentsAtom,
  );

  const data = React.useMemo(() => {
    return publishedContents;
  }, [publishedContents]);

  const onClickUnPublish = useCallback((stack: stacks.Stack) => {
    // display confirmation dialog
    setShowConfirmationModal(true);
    setStackToUnpublish(stack);
  }, []);

  const onUnpublishConfirm = useCallback(async () => {
    setShowConfirmationModal(false);
    if (!stackToUnpublish || !stackToUnpublish.sharing_id) {
      return;
    }
    showSnackbar({
      title: i18n.t('unpublish_stack_inprogress', {
        stackName: stackToUnpublish.stack_data?.name,
      }),
      timeoutMs: 0,
    });
    try {
      await unpublishStack(stackToUnpublish?.sharing_id);
      const listResponse = await listPublishedContent();
      setPublishedContents(listResponse || []);
      showSnackbar({
        title: i18n.t('unpublish_stack_success', {
          stackName: stackToUnpublish.stack_data?.name,
        }),
      });
      reportPapEvent(
        PAP_Select_StacksAction({
          ...stackDerivePAPProps(stackToUnpublish),
          stacksAction: 'unpublish_dash_stack',
          dashActionSurface: 'published_content_page',
          featureLine: 'stacks',
          actionSurfaceComponent: 'published_content',
        }),
      );
    } catch {
      showSnackbar({
        title: i18n.t('unpublish_stack_failure', {
          stackName: stackToUnpublish.stack_data?.name,
        }),
      });
    }
    setStackToUnpublish(undefined);
  }, [stackToUnpublish]);

  const navigate = useNavigate();
  const { reportPapEvent } = useMirageAnalyticsContext();

  const onSelectStack = useCallback(
    (stack: stacks.Stack) => {
      reportPapEvent(
        PAP_View_DashStack({
          actionSurfaceComponent: 'published_content',
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
          dashActionSurface: 'published_content_page',
        }),
      );
      dispatchElementClicked(
        createUxaElementId('stack', {
          featureLine: 'stacks',
        }),
      );
      navigate(`/stacks/${stack.sharing_id}`);
    },
    [navigate, reportPapEvent],
  );

  const onCloseModal = useCallback(() => {
    setShowConfirmationModal(false);
    setStackToUnpublish(undefined);
  }, []);

  return (
    <div>
      {data.length > 0 ? (
        <PublishedContentListTable
          content={data}
          onClickUnpublish={onClickUnPublish}
          onSelectStack={onSelectStack}
        />
      ) : isLoading ? (
        <DashSpinnerAutofitContainer />
      ) : (
        <PublishedContentEmptyState />
      )}
      {showConfirmationModal && (
        <ConfirmUnpublishModal
          onClose={onCloseModal}
          onUnpublishConfirm={onUnpublishConfirm}
          open={showConfirmationModal}
        />
      )}
    </div>
  );
};

type Props = {
  open: boolean;
  onClose: () => void;
  onUnpublishConfirm: () => void;
};

export function ConfirmUnpublishModal({
  open,
  onClose,
  onUnpublishConfirm,
}: Props) {
  return (
    <Modal
      open={open}
      onRequestClose={onClose}
      isCentered
      withCloseButton={i18n.t('close')}
    >
      <Modal.Header>
        <Title>{i18n.t('unpublish_stack_confirm_title')}</Title>
      </Modal.Header>
      <Modal.Body>
        <Text>{i18n.t('unpublish_stack_confirm_subtitle')}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={onClose}>
          {i18n.t('cancel')}
        </Button>
        <Button variant="primary" onClick={onUnpublishConfirm}>
          {i18n.t('unpublish')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
