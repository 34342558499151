import capitalize from 'lodash/capitalize';

// E.g. https://www.dropbox.com/scl/fi/abcd/Best-Paper-Doc.paper?dl=0&rlkey=ab12
// --> Best Paper Doc
function getTitleOfPaperDoc(url: string): string {
  const match = /dropbox\.com\/scl\/fi\/.*\/([^?]*)\.papert?\?/.exec(url);
  if (match && match[1]) {
    return match[1].replace(/-/g, ' ');
  }

  return '';
}

// E.g. https://app.dropboxer.net/binder/my-good-binder/ --> My good binder
function getTitleOfPaperBinder(url: string): string {
  const match = /dropboxer\.net\/binder\/([^/]*)\/?/.exec(url);
  if (match && match[1]) {
    const s = match[1].replace(/-/g, ' ');
    return capitalize(s);
  }
  return '';
}

function getTitleFromDropbox(url: string): string {
  const regexs = [
    // Dropboxer only
    {
      regex: /https:\/\/(\w+).pp.dropbox.com/,
      cleanup: capitalize,
    },
    // preview URLs
    {
      regex: /https:\/\/www.dropbox.com\/.*?preview=([^&]+).*/,
      cleanup: (s: string) => s.replace(/\+/g, ' '),
    },
    // sharing URLs (at least some kinds)
    {
      regex: /https:\/\/www.dropbox.com\/scl\/fi\/[^/]+\/([^?/]+).*/,
    },
  ];

  for (const { regex, cleanup } of regexs) {
    const match = regex.exec(url);
    if (match && match[1]) {
      return cleanup ? cleanup(match[1]) : match[1];
    }
  }

  if (url.includes('dropbox.com/')) {
    const lastPath = url.split('/').pop()?.trim();
    if (lastPath) {
      return decodeURIComponent(lastPath);
    }
  }

  return '';
}

export function getTitleFromDomain(url: string): string {
  const domainName = url.replace(/.+\/\/|www.|\..+/g, '');
  return capitalize(domainName);
}

/** Get the HTML title of any given URL. */
export function getTitleOfUrl(url: string, name?: string): string {
  // If the given name is the much-dreaded 'Dropbox', then replace it with
  // something much better.
  if (name) {
    name = name.trim();
    if (name.toLocaleLowerCase() !== 'dropbox') {
      return name;
    }
  }

  url = url.trim();

  if (!url.startsWith('http')) {
    url = 'https://' + url;
  }

  return (
    getTitleOfPaperDoc(url) ||
    getTitleOfPaperBinder(url) ||
    getTitleFromDropbox(url) ||
    getTitleFromDomain(url) ||
    name ||
    url ||
    'Untitled' // basically unused, since url can never be empty
  );
}

function parsePaperDocTitle(url: string, metadataTitle: string): string {
  const match = /dropbox\.com\/scl\/fi\/.*\/([^?]*)\.papert?\?/.exec(url);
  if (match && metadataTitle.endsWith('.paper')) {
    return metadataTitle.slice(0, metadataTitle.lastIndexOf('.paper'));
  }
  return '';
}

export function parseDropboxMetadataTitle(
  url: string,
  metadataTitle: string,
): string {
  if (
    !metadataTitle ||
    metadataTitle.startsWith('Untitled') ||
    metadataTitle === 'Dropbox'
  ) {
    // Known bad Dropbox metadata title, fall back to Dropbox url parsing
    return (
      getTitleOfPaperDoc(url) ||
      getTitleOfPaperBinder(url) ||
      getTitleFromDropbox(url) ||
      getTitleFromDomain(url) ||
      metadataTitle
    );
  }

  // Drop .paper from good Dropbox metadata titles
  return parsePaperDocTitle(url, metadataTitle) || metadataTitle;
}
