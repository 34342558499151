// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgOutreach({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.328 2c3.22 0 8.067 1.008 10.342 3.29.577.583 1.33 1.714 1.33 3.394 0 2.971-2.363 7.516-4.953 10.168C15.7 20.214 13.566 22 10.677 22c-2.817 0-4.97-1.945-5.582-2.564C2.715 17.013 1 13.353 1 9.922c0-2.157.613-4.05 2.152-5.623C4.588 2.83 7.056 2 10.329 2zm3.99 11.83c1.085-1.096 1.382-2.458 1.382-3.2 0-.549-.14-1.344-.805-2.016-1.032-1.044-2.152-1.327-3.395-1.327-1.557 0-2.607.495-3.307 1.203-.543.548-1.015 1.415-1.015 2.635 0 1.22.63 2.387 1.33 3.094.647.655 1.645 1.132 2.677 1.132s2.047-.424 3.133-1.52z"
        fill="#5952FF"
      />
    </svg>
  );
}

export default SvgOutreach;
