import { Button } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { OnboardingHeader } from '@mirage/growth/components/OnboardingHeader';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import FloatingAppTilesPage from '../floating-app-tiles-page/FloatingAppTilesPage';
import grantPermissionStyles from './GrantPermissionPage.module.css';
import styles from './Onboarding.module.css';
import { PageContainer, PrivacyFooter } from './OnboardingComponents';

type GrantPermissionPageProps = {
  buttonText: string;
  loading?: boolean;
  onClick?: () => void;
  onSkip?: () => void;
};

export default function GrantPermissionPage({
  loading = false,
  buttonText,
  onClick,
  onSkip,
}: GrantPermissionPageProps) {
  const isMobile = useIsMobileSize();

  return (
    <PageContainer>
      <div className={styles.leftPanel}>
        <OnboardingHeader />
        <Box>
          <Box marginBottom="60">
            <Title
              size="large"
              weightVariant="emphasized"
              className={styles.title}
            >
              {i18n.t('onboarding_one_click_title')}
            </Title>
            <Text color="faint">{i18n.t('onboarding_one_click_blurb')}</Text>
          </Box>
          <div
            className={classnames(styles.buttonsContainer, {
              [grantPermissionStyles.mobileButtonPadding]: isMobile,
            })}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box marginBottom="Macro Small" width="100%">
                <Button
                  variant="primary"
                  size="xlarge"
                  fullWidth={true}
                  onClick={onClick}
                  isLoading={loading}
                >
                  {buttonText}
                </Button>
              </Box>
              {onSkip && (
                <Button
                  variant="transparent"
                  hasNoUnderline={false}
                  onClick={onSkip}
                >
                  {i18n.t('onboarding_add_later_btn')}
                </Button>
              )}
            </Box>
          </div>
        </Box>
        <PrivacyFooter />
      </div>
      {!isMobile && (
        <div
          className={classnames(
            styles.rightPanel,
            grantPermissionStyles.noPadding,
          )}
        >
          <FloatingAppTilesPage numberOfTiles={16} />
        </div>
      )}
    </PageContainer>
  );
}
