import { dash } from '@dropbox/api-v2-client';
import { UIIcon } from '@dropbox/dig-icons';
import { Twinkle2Line } from '@dropbox/dig-icons/assets';
import { useFlyoutPanel } from '@mirage/mosaics/FlyoutPanel/useFlyoutPanel';
import { ResultAction } from '@mirage/mosaics/Results/ResultActions';
import { ChatEntryPoint } from '@mirage/mosaics/SummaryQnaPanel/SummaryQna';
import { DisplayActionTypes } from '@mirage/search/SearchResults/ResultRow/types';
import {
  type NormalizeResultInputType,
  SummaryQnAEnum,
  useSummarizableReasons,
} from './utils';

export const SummarizeAction = ({
  result,
  summaryQnAType,
  entrypoint,
  resultPosition,
  resultPositionNoCta,
  summarizable,
}: {
  result?: NormalizeResultInputType;
  summaryQnAType: SummaryQnAEnum;
  entrypoint: ChatEntryPoint;
  resultPosition: number;
  resultPositionNoCta: number;
  summarizable?: dash.SummarizableType['.tag'];
}) => {
  const { summarize } = useFlyoutPanel();
  const getSummarizableReason = useSummarizableReasons();
  const notSummarizable =
    typeof result === 'object' && summarizable !== 'yes_summarizable';

  return (
    <ResultAction
      action={{
        key: 'summarize',
        title: getSummarizableReason(summarizable),
        type: DisplayActionTypes.IconButton,
        icon: <UIIcon src={Twinkle2Line} />,
        disabled: notSummarizable,
        onClick: () => {
          if (result) {
            summarize(
              result,
              summaryQnAType,
              entrypoint,
              resultPosition,
              resultPositionNoCta,
            );
          }
        },
      }}
    />
  );
};
