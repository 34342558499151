import pqueue from 'p-queue';

import type { KVStorage } from '@mirage/storage';

export default class WithOverrides<Shape> implements KVStorage<Shape> {
  private storage: KVStorage<Shape>;
  private queue: pqueue = new pqueue({ concurrency: 1 });

  constructor(wrapping: KVStorage<Shape>, overrides: Partial<Shape>) {
    this.storage = wrapping;

    // on init, forcefully overwrite provided keys in `overrides` so they are
    // persisted for subsequent calls, doing this in a single function call to
    // ensure that all overrides are written before any subsequent reads happen
    this.queue.add(async () => {
      for (const [key, value] of Object.entries(overrides)) {
        await this.storage.set(
          key as keyof Shape,
          value as unknown as Shape[keyof Shape],
        );
      }
    });
  }

  get<K extends keyof Shape>(key: K): Promise<Shape[K] | undefined> {
    return this.queue.add(() => this.storage.get(key)) as Promise<
      Shape[K] | undefined
    >;
  }

  set<K extends keyof Shape>(key: K, value: Shape[K]): Promise<void> {
    return this.queue.add(() => this.storage.set(key, value));
  }

  getAll(): Promise<Shape | undefined> {
    return this.queue.add(() => this.storage.getAll()) as Promise<
      Shape | undefined
    >;
  }

  async delete<K extends keyof Shape>(key: K): Promise<void> {
    await this.queue.add(() => this.storage.delete(key));
  }

  clear(): Promise<void> {
    return this.storage.clear();
  }
}
