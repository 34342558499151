import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { KVStorage } from '@mirage/storage';
import * as rx from 'rxjs';
import { IpcProfilingStorage, Service } from './service';

const service = services.get<Service>(ServiceId.IPC_PROFILING);

// Enable super-fast local caching with subscription.
let ipcProfilingEnabled: boolean | undefined;

export async function getIpcProfilingEnabled() {
  if (ipcProfilingEnabled !== undefined) {
    return ipcProfilingEnabled;
  }

  // This is a permanent subscription to auto-update the boolean within this
  // process. Init on first use.
  subscribeToIpcProfilingEnabled((enabled) => {
    ipcProfilingEnabled = enabled;
    enabled ? attachIpcProfiler() : detachIpcProfiler();
  });

  ipcProfilingEnabled = await rx.firstValueFrom(
    service.getIpcProfilingEnabled(),
  );

  ipcProfilingEnabled ? attachIpcProfiler() : detachIpcProfiler();

  return ipcProfilingEnabled;
}

export async function setIpcProfilingEnabled(enabled: boolean) {
  ipcProfilingEnabled = enabled;
  return await rx.firstValueFrom(service.setIpcProfilingEnabled(enabled));
}

export function subscribeToIpcProfilingEnabled(
  callback: (enabled: boolean) => void,
) {
  return service.ipcProfilingEnabledObservable().subscribe(callback);
}

export async function getAllIpcProfilingStats() {
  return await rx.firstValueFrom(service.getAllIpcProfilingStats());
}

export async function clearIpcProfilingStats() {
  return await rx.firstValueFrom(service.clearIpcProfilingStats());
}

// Attach IPC profiler early to catch IPCs during services init.
export async function earlyInitIpcProfilingForBackground(
  ipcProfilingStore: KVStorage<IpcProfilingStorage>,
) {
  if (await ipcProfilingStore.get('enableIpcProfiling')) {
    attachIpcProfiler();
  }

  // Init the local boolean. This cannot be used to block the above code
  // because the IPC profiling service is not ready at this point.
  void getIpcProfilingEnabled();
}

// Attach IPC profiler early to catch IPCs for the UI. This will require the
// mirage service for IPC profiling to be available before it will initiate.
export async function earlyInitIpcProfilingForUI() {
  const enabled = await getIpcProfilingEnabled();
  if (enabled) {
    attachIpcProfiler();
  }
}

function attachIpcProfiler() {
  const timingLogger: services.TimingLogger = async (...profile) => {
    if (!ipcProfilingEnabled) {
      return;
    }

    // Prevent infinite loop with logging self.
    const serviceId = profile[0];
    if (serviceId === ServiceId.IPC_PROFILING) return;

    return await rx.firstValueFrom(service.recordIpcProfile(...profile));
  };

  services.attachTimingLogger(timingLogger);
}

function detachIpcProfiler() {
  services.removeTimingLogger();
}
