import { useMemo } from 'react';
import useConnectors from './useConnectors';

export enum ActivationTypes {
  Fetching = 'fetching',
  NoConnectors = 'no_connectors',
  SomeConnectors = 'some_connectors',
}

// Magic number of connectors for user to have good experience
const MIN_NUM_CONNECTORS = 4;

// TODO: account for extension as connector type
export const useActivationForConnectors = () => {
  const { connectors, refreshConnectionsList, hasFinishedInitialFetch } =
    useConnectors();

  const activationType = useMemo(() => {
    if (!hasFinishedInitialFetch) {
      return ActivationTypes.Fetching;
    }

    if (!connectors.length) {
      return ActivationTypes.NoConnectors;
    }

    if (connectors.length > 0 && connectors.length < MIN_NUM_CONNECTORS) {
      return ActivationTypes.SomeConnectors;
    }

    return undefined;
  }, [connectors.length, hasFinishedInitialFetch]);

  return {
    connectors,
    activationType,
    noConnectors: activationType === ActivationTypes.NoConnectors,
    someConnectors: activationType === ActivationTypes.SomeConnectors,
    hasFinishedInitialFetch,
    refreshConnectionsList,
  };
};
