import {
  deleteComposeApiSession,
  loadComposeApiSessions,
  loadComposeSessions,
  saveComposeApiSession,
  saveComposeSessions,
  syncComposeSessions,
} from '@mirage/service-compose';
import { tagged } from '@mirage/service-logging';
import { ComposeSession } from '@mirage/shared/compose/compose-session';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

const logger = tagged('ComposeSessionsContext');

export interface ComposeSessionsContextInterface {
  sessions: ComposeSession[] | undefined;
  saveSession: (
    session: ComposeSession,
    updateCurrentSession: (session: ComposeSession) => void,
  ) => void;
  deleteSession: (session: ComposeSession) => void;
}
export const ComposeSessionsContext =
  createContext<ComposeSessionsContextInterface | null>(null);
export const useComposeSessionsContext = () => {
  const context = useContext(ComposeSessionsContext);
  if (!context) {
    throw new Error(
      'useComposeSessionsContext must be used within a ComposeSessionsContextProvider',
    );
  }
  return context;
};

interface ComposeSessionsContextProviderProps {
  children: React.ReactNode;
}
export const ComposeSessionsContextProvider = ({
  children,
}: ComposeSessionsContextProviderProps) => {
  const [sessions, setSessions] = useState<ComposeSession[] | undefined>(
    undefined,
  );
  useEffect(() => {
    const loadSessions = async () => {
      try {
        const localSessions = await loadComposeSessions();
        setSessions(localSessions); // Display local sessions immediately
        const apiSessions = await loadComposeApiSessions();
        const updatedSessions = await syncComposeSessions(
          localSessions,
          apiSessions,
        );
        setSessions(updatedSessions); // Update UI after sync
      } catch (error) {
        logger.error('Error loading or synchronizing sessions', error);
      }
    };
    loadSessions();
  }, []);

  const saveSession = useCallback(
    async (
      session: ComposeSession,
      updateCurrentSession: (session: ComposeSession) => void,
    ) => {
      let sessionData = {
        ...session,
      };
      let shouldSaveToApi = false;
      if (!session.dataId) {
        sessionData = {
          ...sessionData,
          lastUpdated: Date.now(),
        };
        const savedSession = await throttledSaveComposeApiSession(sessionData);
        if (savedSession?.dataId) {
          sessionData = {
            ...sessionData,
            dataId: savedSession.dataId,
          };
          updateCurrentSession(sessionData);
        }
      }
      setSessions((prevSessions) => {
        if (prevSessions === undefined) {
          logger.warn('Attempted to save session before loading completes');
          return prevSessions;
        }
        const sessionsToSave = [];
        let found = false;
        for (const s of prevSessions) {
          if (s.id === sessionData.id) {
            found = true;
            if (!areSessionsDataEqual(s, session)) {
              sessionsToSave.push(sessionData);
              shouldSaveToApi = true; // Session changed, needs to be saved to API
            } else {
              sessionsToSave.push(s);
            }
          } else {
            sessionsToSave.push(s);
          }
        }
        if (!found) {
          sessionsToSave.push(sessionData); // New session, needs to be saved to API
          shouldSaveToApi = true;
        }
        throttledSaveComposeSessions(sessionsToSave);
        return sessionsToSave;
      });
      if (shouldSaveToApi && sessionData.dataId) {
        try {
          await throttledSaveComposeApiSession(sessionData);
        } catch (error) {
          logger.error('Error saving session to API', error);
        }
      }
    },
    [],
  );

  const deleteSession = useCallback(async (session: ComposeSession) => {
    setSessions((prevSessions) => {
      if (prevSessions === undefined) {
        logger.warn('Attempted to remove session before loading completes');
        return prevSessions;
      }
      const updated = prevSessions.filter((s) => s.id !== session.id);
      throttledSaveComposeSessions(updated);
      return updated;
    });
    if (session.dataId) {
      try {
        await deleteComposeApiSession(session);
      } catch (error) {
        logger.error('Error deleting session from API', error);
      }
    }
  }, []);

  return (
    <ComposeSessionsContext.Provider
      value={{ sessions, saveSession, deleteSession }}
    >
      {children}
    </ComposeSessionsContext.Provider>
  );
};

function areSessionsDataEqual(
  a: Omit<ComposeSession, 'lastUpdated'>,
  b: Omit<ComposeSession, 'lastUpdated'>,
): boolean {
  return (
    a.id === b.id &&
    isEqual(a.messagesHistory, b.messagesHistory) &&
    isEqual(a.sources, b.sources) &&
    isEqual(a.artifacts, b.artifacts)
  );
}

const SAVE_THROTTLE_MS = 100;
const throttledSaveComposeSessions = throttle(
  saveComposeSessions,
  SAVE_THROTTLE_MS,
  { trailing: true },
);
const throttledSaveComposeApiSession = throttle(
  saveComposeApiSession,
  SAVE_THROTTLE_MS,
  { trailing: true },
);
