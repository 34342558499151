import classnames from 'classnames';
import React from 'react';
import styles from './ContentIcon.module.css';

export type ContentIconSize = 'small' | 'standard' | 'large' | 'xlarge';
export const SUB_ICON_SIZE = 12;

export function getArtworkSize(size: ContentIconSize) {
  switch (size) {
    case 'small':
      return 16;
    case 'standard':
      return 20;
    case 'large':
    case 'xlarge':
    default:
      return 24;
  }
}

type ContentIconProps = {
  baseIcon: React.ReactNode;
  subIcon?: React.ReactNode;
  size?: ContentIconSize;
  hasTheme?: boolean;
};

export default function ContentIcon({
  baseIcon,
  subIcon,
  size = 'standard',
  hasTheme = false,
}: ContentIconProps) {
  return (
    <div
      className={classnames(styles.iconContainer, styles[size], {
        [styles.themedIcon]: hasTheme,
      })}
    >
      {baseIcon}
      {subIcon && size !== 'small' && (
        <div className={classnames(styles.subIconContainer, styles[size])}>
          {subIcon}
        </div>
      )}
    </div>
  );
}
