// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1492
import type { PAPEventGeneric } from '../base/event';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'expand.dash_search_right_panel'
// Description: This event will be triggered whenever the right side panel in Dash desktop is opened.
// Owner: otc-growth
export type Expand_DashSearchRightPanel = PAPEventGeneric<
  'dash',
  'expand',
  'dash_search_right_panel',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The surface in which an action was taken on Dash.
    dashActionSurface?: DashActionSurface;
  }
>;

export function PAP_Expand_DashSearchRightPanel(
  properties?: Expand_DashSearchRightPanel['properties'],
): Expand_DashSearchRightPanel {
  return <Expand_DashSearchRightPanel>{
    class: 'dash',
    action: 'expand',
    object: 'dash_search_right_panel',
    properties,
  };
}
