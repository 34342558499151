import { createUxaElementId } from '@mirage/analytics/uxa';

import type { FeatureLine } from '@mirage/analytics/events/enums/feature_line';
import type { AnalyticsHtmlAttributes } from '@mirage/analytics/uxa';
import type { SearchResult } from '@mirage/service-dbx-api';

export function getActionButtonAnalytics(
  searchResult: SearchResult,
  action: string,
  featureLine?: FeatureLine,
): AnalyticsHtmlAttributes {
  return {
    'data-uxa-log': createUxaElementId(`${action}_button`, {
      actionSurfaceComponent: 'result_row',
      featureLine: featureLine || 'search',
    }),
    'data-uxa-entity-id': searchResult.uuid,
    'data-uxa-trace-id': searchResult.analyticsTraceId,
  };
}
