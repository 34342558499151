// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/98
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'exposure.stormcrow'
// Description: PAP event definition for stormcrow exposures
// Owner: customer-analytics-platform
export type Exposure_Stormcrow = PAPEventGeneric<
  'experimentation',
  'exposure',
  'stormcrow',
  {
    // stormcrow feature name
    feature?: string;
    // Experiment variant that the user was exposed to
    experimentVariant?: string;
    // Stormcrow population id
    populationId?: string;
    // GID of identity
    identityGid?: string;
    // Client host id
    hostId?: string;
    // app id
    appId?: string;
    // key/value pairs of metadata represented as a JSON string. This field should only be used for logging exposure data. The metadata is metadata sent from the stormcrow service and the client is meant to log it back to the service at the time of exposure. Refer diff (https://phabricator.dropboxer.net/D808117?id=2767145#inline-5035085)
    stormcrowMetadataJson?: string;
  }
>;

export function PAP_Exposure_Stormcrow(
  properties?: Exposure_Stormcrow['properties'],
): Exposure_Stormcrow {
  return <Exposure_Stormcrow>{
    class: 'experimentation',
    action: 'exposure',
    object: 'stormcrow',
    properties,
  };
}
