// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3134
import type { PAPEventGeneric } from '../base/event';
import type { FeatureLine } from '../enums/feature_line';

// Event_name: 'initiate.search_for_stack'
// Description: Searches for a stack name on the stacks homepage
// Owner: dash-web
export type Initiate_SearchForStack = PAPEventGeneric<
  'stacks',
  'initiate',
  'search_for_stack',
  {
    // Feature Line for dash
    featureLine?: FeatureLine;
  }
>;

export function PAP_Initiate_SearchForStack(
  properties?: Initiate_SearchForStack['properties'],
): Initiate_SearchForStack {
  return <Initiate_SearchForStack>{
    class: 'stacks',
    action: 'initiate',
    object: 'search_for_stack',
    properties,
  };
}
