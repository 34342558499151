// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1995
import type { PAPEventGeneric } from '../base/event';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { EndReason } from '../enums/end_reason';
import type { FeatureLine } from '../enums/feature_line';

// Event_name: 'finish.dash_session'
// Description: A period of time where the user was interacting with Dash. The period ends after 60 seconds of not using Dash. (Note: the more generic "session ID" represents each time the user runs Dash on their computer, not a time period where they're using Dash".)
// Owner: otc
export type Finish_DashSession = PAPEventGeneric<
  'dash',
  'finish',
  'dash_session',
  {
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // the end time of the event in ms
    endTimeMs?: number;
    // The reason the session ended
    endReason?: EndReason;
    // Set to true if the user has performed a query
    hasPerformedQuery?: boolean;
    // Set to true if the user has scrolled through their results.
    hasScrolled?: boolean;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
  }
>;

export function PAP_Finish_DashSession(
  properties?: Finish_DashSession['properties'],
): Finish_DashSession {
  return <Finish_DashSession>{
    class: 'dash',
    action: 'finish',
    object: 'dash_session',
    properties,
  };
}
