// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgMsOutlook({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 4.667C8.25 3.747 8.996 3 9.917 3h9.166c.92 0 1.667.746 1.667 1.667v14.166c0 .92-.746 1.667-1.667 1.667H9.917c-.92 0-1.667-.746-1.667-1.667V4.667z"
        fill="#1066B5"
      />
      <path
        d="M8.25 4.667C8.25 3.747 8.996 3 9.917 3h9.166c.92 0 1.667.746 1.667 1.667v14.166c0 .92-.746 1.667-1.667 1.667H9.917c-.92 0-1.667-.746-1.667-1.667V4.667z"
        fill="url(#ms-outlook_svg__paint0_linear)"
      />
      <path fill="#32A9E7" d="M14.5 11.333h6.25V18H14.5z" />
      <path fill="#167EB4" d="M8.25 11.333h6.25V18H8.25z" />
      <path
        d="M21.442 10.917c.36.281.508.866.508.866l-1.2.667v-1.898c.091 0 .331.083.692.365z"
        fill="#135298"
      />
      <path
        d="M21.583 19.933c.417-.566.417-1.1.417-1.1v-6.666s0-.334-.05-.384c-.044.03-.042.018-.042.018L7.417 19.917s0 .083.416.366c.434.217.834.217.834.217h11.666s.784.008 1.25-.567z"
        fill="url(#ms-outlook_svg__paint1_linear)"
      />
      <path
        d="M7.392 19.917C6.999 19.367 7 18.833 7 18.833V11.75l14.6 8.167s-.16.21-.433.358c-.417.225-.834.225-.834.225H8.667s-.892-.058-1.275-.583z"
        fill="url(#ms-outlook_svg__paint2_linear)"
      />
      <path fill="#58D9FD" d="M14.5 4.667h6.25v6.667H14.5z" />
      <path fill="#32A9E7" d="M8.25 4.667h6.25v6.667H8.25z" />
      <path
        d="M7 9.875a2.5 2.5 0 012.5-2.5H12a2.5 2.5 0 012.5 2.5v6.25a2.5 2.5 0 01-2.5 2.5H7v-8.75z"
        fill="#000"
        fillOpacity={0.3}
      />
      <path
        d="M2 8c0-.92.746-1.667 1.667-1.667H12c.92 0 1.667.747 1.667 1.667v8.333c0 .92-.746 1.667-1.667 1.667H3.667C2.747 18 2 17.254 2 16.333V8z"
        fill="url(#ms-outlook_svg__paint3_linear)"
      />
      <path
        d="M10.75 11.793v-.104c0-1.8-1.295-3.064-3.115-3.064-1.83 0-3.135 1.273-3.135 3.082v.104c0 1.8 1.295 3.064 3.125 3.064 1.82 0 3.125-1.273 3.125-3.082zm-1.473.018c0 1.194-.673 1.913-1.642 1.913-.97 0-1.652-.736-1.652-1.93v-.105c0-1.194.673-1.913 1.642-1.913.96 0 1.652.736 1.652 1.93v.105z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="ms-outlook_svg__paint0_linear"
          x1={8.25}
          y1={11.75}
          x2={20.75}
          y2={11.75}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#064484" />
          <stop offset={1} stopColor="#0F65B5" />
        </linearGradient>
        <linearGradient
          id="ms-outlook_svg__paint1_linear"
          x1={22}
          y1={16.125}
          x2={7}
          y2={16.125}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#44DCFD" />
          <stop offset={0.453} stopColor="#259ED0" />
        </linearGradient>
        <linearGradient
          id="ms-outlook_svg__paint2_linear"
          x1={7}
          y1={16.125}
          x2={22}
          y2={16.125}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#259ED0" />
          <stop offset={1} stopColor="#44DCFD" />
        </linearGradient>
        <linearGradient
          id="ms-outlook_svg__paint3_linear"
          x1={2}
          y1={12.167}
          x2={13.667}
          y2={12.167}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#064484" />
          <stop offset={1} stopColor="#0F65B5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgMsOutlook;
