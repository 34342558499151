// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3211
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';

// Event_name: 'create.dash_stacks_clone'
// Description: Event triggered whenever the user creates a stack clone
// Owner: content-ingest
export type Create_DashStacksClone = PAPEventGeneric<
  'stacks',
  'create',
  'dash_stacks_clone',
  {
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
  }
>;

export function PAP_Create_DashStacksClone(
  properties?: Create_DashStacksClone['properties'],
): Create_DashStacksClone {
  return <Create_DashStacksClone>{
    class: 'stacks',
    action: 'create',
    object: 'dash_stacks_clone',
    properties,
  };
}
