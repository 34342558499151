import i18n from '@mirage/translations';

import type { ContentTypeFilter } from '@mirage/shared/search/search-filters';

// 0 active filters, show "type"
// if 1 active filter, show active filter label
// if >1 active filters, show "type" with count
export const getContentTypeFilterTitle = (
  activeFilters: ContentTypeFilter[],
) => {
  switch (activeFilters.length) {
    case 0:
      return i18n.t('content_type_filter_title');
    case 1:
      return activeFilters[0].parameters.label;
    default:
      return i18n.t('content_type_filter_title_with_count', {
        count: activeFilters.length,
      });
  }
};
