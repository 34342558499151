import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Click_ContentTypeButton } from '@mirage/analytics/events/types/click_content_type_button';
import {
  ContextInputContentTypeMessage,
  ContextInputSourcesMessage,
} from '@mirage/mosaics/ComposeAssistant/components/chat/ContextInputMessage';
import { SourcesContentCache } from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import {
  ComposeAssistantDraftConfig,
  ComposeSource,
} from '@mirage/shared/compose/compose-session';
import { memo, useState } from 'react';

interface ContextInputMessagesProps {
  sources: ComposeSource[];
  sourcesContentCache: SourcesContentCache;
  removeSource: (source: ComposeSource) => void;
  draftConfig: ComposeAssistantDraftConfig;
  setDraftConfig: (draftConfig: ComposeAssistantDraftConfig) => void;
  onCompleteInputs?: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  handleClickAdd: () => void;
}
export const ContextInputMessages = memo(
  ({
    sources,
    sourcesContentCache,
    removeSource,
    handleClickAdd,
    draftConfig,
    setDraftConfig,
    onCompleteInputs,
    logComposeEvent,
  }: ContextInputMessagesProps) => {
    const [didSelectContentType, setDidSelectContentType] = useState(false);
    return (
      <>
        <ContextInputContentTypeMessage
          stepIndex={1}
          currentContentType={draftConfig.contentType}
          onContentTypeChange={(contentType) => {
            setDidSelectContentType(true);
            setDraftConfig({
              ...draftConfig,
              contentType,
            });
            logComposeEvent(
              PAP_Click_ContentTypeButton({
                composeContentType: contentType,
              }),
            );
          }}
          didSelectContentType={didSelectContentType}
        />
        {(draftConfig.contentType || didSelectContentType) && (
          <ContextInputSourcesMessage
            logComposeEvent={logComposeEvent}
            stepIndex={2}
            sources={sources}
            sourcesContentCache={sourcesContentCache}
            handleClickAdd={handleClickAdd}
            removeSource={removeSource}
            onDone={onCompleteInputs}
          />
        )}
      </>
    );
  },
);
ContextInputMessages.displayName = 'ContextInputMessages';
