// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3016
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'change.dash_new_link_search_query'
// Description: Perform a search query inside the Add to
// Owner: dash-web
export type Change_DashNewLinkSearchQuery = PAPEventGeneric<
  'stacks',
  'change',
  'dash_new_link_search_query',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // unique session id for creating stacks on dash
    createStackSessionId?: string;
    // This is a session that persists when the Add to Stack button is currently being displayed and ends when a link has been added, or the process is cancelled by clicking away, or page is closed.
    dashNewLinkSessionId?: string;
    // The length of a query
    queryLength?: number;
    // Session id for starting a search for stack links to add
    dashLinkSearchSessionId?: string;
  }
>;

export function PAP_Change_DashNewLinkSearchQuery(
  properties?: Change_DashNewLinkSearchQuery['properties'],
): Change_DashNewLinkSearchQuery {
  return <Change_DashNewLinkSearchQuery>{
    class: 'stacks',
    action: 'change',
    object: 'dash_new_link_search_query',
    properties,
  };
}
