import { DashAnswersFeedbackTag } from '@mirage/analytics/events/enums/dash_answers_feedback_tag';
import i18n from '@mirage/translations';

export type SecondaryFeedbackOption = {
  key: DashAnswersFeedbackTag;
  value: string;
};

export const answersSecondaryFeedbackOptions: SecondaryFeedbackOption[] = [
  {
    key: 'incorrect_answer',
    value: i18n.t('answers_feedback_incorrect'),
  },
  {
    key: 'incorrect_sources',
    value: i18n.t('answers_feedback_incorrect_sources'),
  },
  {
    key: 'misunderstood_question',
    value: i18n.t('answers_feedback_misunderstood'),
  },
];

export const summaryQnaSecondaryFeedbackOptions = (
  skipQuestions: string[] = [],
): SecondaryFeedbackOption[] => {
  const feedbackOptions: SecondaryFeedbackOption[] = [
    {
      key: 'irrelevant_answer',
      value: i18n.t('doc_summary_qna_feedback_irrelevant_answer'),
    },
    {
      key: 'incorrect_answer',
      value: i18n.t('doc_summary_qna_feedback_incorrect'),
    },
    {
      key: 'key_details_missing',
      value: i18n.t('doc_summary_qna_feedback_key_details_missing'),
    },
    {
      key: 'verbose',
      value: i18n.t('doc_summary_qna_feedback_verbose'),
    },
    {
      key: 'misunderstood_question',
      value: i18n.t('doc_summary_qna_feedback_misunderstood'),
    },
    {
      key: 'other',
      value: i18n.t('doc_summary_qna_feedback_other_option'),
    },
  ];
  return feedbackOptions.filter((option) => {
    if (!skipQuestions.includes(option.key)) return option;
  });
};
