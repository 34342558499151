import { ml_capabilities } from '@dropbox/api-v2-client/types/dropbox_types';
import { ContentCacheLoaded } from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import { LLMResponse } from '@mirage/mosaics/ComposeAssistant/data/llm/llm-apis';
import { AssistantResponse } from '@mirage/mosaics/ComposeAssistant/data/llm/llm-types';
import {
  handleReadSourceCall,
  handleWriteDocCall,
} from '@mirage/mosaics/ComposeAssistant/data/llm/tools/compose-tools';
import { handleSearchDashCall } from '@mirage/mosaics/ComposeAssistant/data/llm/tools/search-tools';
import { tagged } from '@mirage/service-logging';

const logger = tagged('ComposeAssistant/llm-tools');

export async function handleToolCall(
  response: LLMResponse,
  toolCall: ml_capabilities.ToolCall,
  indexedCachedSources: Map<number, ContentCacheLoaded>,
  onResponse: (response: AssistantResponse) => void,
): Promise<ml_capabilities.ChatMessage[]> {
  if (toolCall.function === undefined) {
    throw new Error(
      `Missing function for Chat completion response: ${toolCall.function}`,
    );
  }
  if (toolCall.function.arguments === undefined) {
    throw new Error(
      `Missing arguments for Chat completion response: ${toolCall.function.arguments}`,
    );
  }
  const toolName = toolCall.function.name;
  const toolCallArgs = {
    toolCallID: toolCall.id,
    argumentsString: toolCall.function.arguments,
    responseText: response.responseText,
    responseMessage: response.responseMessage,
    indexedCachedSources,
    onResponse,
  };
  switch (toolName) {
    case 'write_doc':
      return handleWriteDocCall(toolCallArgs);
    case 'read_source':
      return handleReadSourceCall(toolCallArgs);
    case 'search_dash':
      return await handleSearchDashCall(toolCallArgs);
    default:
      logger.error('unexpected tool call', toolCall.function.name);
      throw new Error(`Unexpected tool call: ${toolCall.function.name}`);
  }
}
