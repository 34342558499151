// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1294
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'initiate.dash_search'
// Description: Select search bar to search for stacks or links
// Owner: content-ingest
export type Initiate_DashSearch = PAPEventGeneric<
  'stacks',
  'initiate',
  'dash_search',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
  }
>;

export function PAP_Initiate_DashSearch(
  properties?: Initiate_DashSearch['properties'],
): Initiate_DashSearch {
  return <Initiate_DashSearch>{
    class: 'stacks',
    action: 'initiate',
    object: 'dash_search',
    properties,
  };
}
