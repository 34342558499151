import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { getFeatureRolloutRingFromString } from '@mirage/analytics/events/enums/feature_rollout_ring-utils';
import { PAP_Exposure_DashGrowthbook } from '@mirage/analytics/events/types/exposure_dash_growthbook';
import { PAP_Exposure_Stormcrow } from '@mirage/analytics/events/types/exposure_stormcrow';
import { useFeatureRingSettings } from '@mirage/service-feature-ring-settings/hooks/useFeatureRingSettings';
import { useEffect } from 'react';
import { useFeatureFlags } from './useInitFeatureFlags';

import type { FeatureName, FeatureValue } from './features';

export const useFeatureFlagValue = (
  feature: FeatureName,
  includeExposureLogging = true,
): FeatureValue => {
  const featureFlags = useFeatureFlags();
  const {
    source,
    value: serverValue,
    featureName,
    overrideValue,
  } = featureFlags[feature];
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { getFeatureRingFromCacheForLogging } = useFeatureRingSettings();

  const resolvedValue = overrideValue ?? serverValue;

  useEffect(() => {
    const experimentVariant = resolvedValue?.toString();
    const shouldLogExposure =
      includeExposureLogging &&
      experimentVariant !== undefined &&
      experimentVariant?.toLowerCase() !== 'off';

    // Automatically log exposure to PAP if the feature flag is coming from Growthbook
    if (source === 'growthbook' && shouldLogExposure) {
      getFeatureRingFromCacheForLogging().then((featureRing) => {
        reportPapEvent(
          PAP_Exposure_DashGrowthbook({
            feature: featureName,
            experimentVariant,
            featureRolloutRing: featureRing
              ? getFeatureRolloutRingFromString(featureRing)
              : undefined,
          }),
        );
      });
    }

    // Log exposure to PAP if the feature flag is coming from Stormcrow
    if (source === 'stormcrow' && shouldLogExposure) {
      reportPapEvent(
        PAP_Exposure_Stormcrow({
          feature: featureName,
          experimentVariant,
        }),
      );
    }
  }, [
    resolvedValue,
    source,
    includeExposureLogging,
    featureName,
    reportPapEvent,
    getFeatureRingFromCacheForLogging,
  ]);

  return resolvedValue;
};
