// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgLinkedin({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.478 2C2.662 2 2 2.642 2 3.433v17.135C2 21.358 2.662 22 3.478 22h17.044c.816 0 1.478-.641 1.478-1.432V3.433C22 2.642 21.338 2 20.522 2H3.478zM8.55 9.67v8.997H5.512V9.67H8.55zm.2-2.782c0 .863-.659 1.554-1.718 1.554h-.02c-1.019 0-1.678-.69-1.678-1.554 0-.883.68-1.555 1.719-1.555 1.038 0 1.678.672 1.697 1.555zm4.518 11.779H10.23s.04-8.153 0-8.997h3.037v1.274c.404-.613 1.126-1.485 2.737-1.485 1.998 0 3.496 1.286 3.496 4.05v5.158h-3.037v-4.813c0-1.21-.44-2.034-1.538-2.034-.839 0-1.339.556-1.558 1.094-.08.191-.1.46-.1.73v5.023z"
        fill="#0072B1"
      />
    </svg>
  );
}

export default SvgLinkedin;
