import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { BoxEmptySpot } from '@dropbox/dig-illustrations';
import { createUxaElementId } from '@mirage/analytics/uxa';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import styles from './ActivityListEmptyState.module.css';

type Props = {
  className?: string;
  clearFilters: () => void;
};

export const ActivityListEmptyStateResetFilters = ({
  className,
  clearFilters,
}: Props) => {
  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.assetContainer}>
        <div className={styles.iconContainer}>
          <BoxEmptySpot />
        </div>
      </div>
      <div className={styles.textContentContainer}>
        <div>
          <Text className={styles.message} size="medium" tagName="p">
            {i18n.t('activity_feed_empty_state_text_reset_filters')}
          </Text>
          <Button
            variant="opacity"
            onClick={clearFilters}
            data-uxa-log={createUxaElementId('empty_state_clear_filters', {
              actionSurfaceComponent: 'activity_feed',
              featureLine: 'activity_feed',
            })}
          >
            {i18n.t('activity_feed_empty_state_text_reset_filters_button')}
          </Button>
        </div>
      </div>
    </div>
  );
};
