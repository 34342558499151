// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2577
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'cancel.dash_rename_link'
// Description: Fires when a user cancels the Rename Link button to open the stacks link name modal
// Owner: dash-web
export type Cancel_DashRenameLink = PAPEventGeneric<
  'dash',
  'cancel',
  'dash_rename_link',
  {
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
  }
>;

export function PAP_Cancel_DashRenameLink(
  properties?: Cancel_DashRenameLink['properties'],
): Cancel_DashRenameLink {
  return <Cancel_DashRenameLink>{
    class: 'dash',
    action: 'cancel',
    object: 'dash_rename_link',
    properties,
  };
}
