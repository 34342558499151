import { Button } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box, Stack } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Close_DashPrivacyModal } from '@mirage/analytics/events/types/close_dash_privacy_modal';
import { PAP_Open_DashConnectAppPageContinue } from '@mirage/analytics/events/types/open_dash_connect_app_page_continue';
import { PAP_Open_DashPrivacyModal } from '@mirage/analytics/events/types/open_dash_privacy_modal';
import { PAP_Shown_DashConnectAppPage } from '@mirage/analytics/events/types/shown_dash_connect_app_page';
import {
  createUxaElementId,
  dispatchElementClicked,
} from '@mirage/analytics/uxa';
import { OnboardingHeader } from '@mirage/growth/components/OnboardingHeader';
import { listConnections } from '@mirage/service-connectors';
import { fetchConnectorsWithVisibilityFilters } from '@mirage/settings/utils/connectorMetadataService';
import {
  useConnections,
  useSetConnections,
} from '@mirage/shared/atoms/connections';
import { useSetConnectors } from '@mirage/shared/atoms/connectors';
import i18n, { Trans } from '@mirage/translations';
import { useWindowSize } from '@react-hookz/web';
import classnames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PrivacyModal } from './PrivacyModal';
import styles from './TeamOnboarding.module.css';
import { TeamOnboardingConnectorList } from './TeamOnboardingConnectorList';
import { useFinishOnboarding } from './utils';

export const TeamOnboarding = () => {
  const connections = useConnections();
  const setConnections = useSetConnections();
  const setConnectors = useSetConnectors();
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const finishOnboarding = useFinishOnboarding(reportPapEvent, 'team_v1', true);
  // Use specific width for isMobile check compared to `useIsMobileSize` because
  //   we need a width that toggles two vs one columns
  const isMobile = useWindowSize().width < 850;
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const fromDesktop = params.get('from') === 'desktop';
  // secret url param to use to test against the dev desktop app
  const useDevDesktopApp = params.get('useDevDesktopApp') === 'true';

  const haveNoConnections = connections.data.length === 0;

  useEffect(() => {
    reportPapEvent(PAP_Shown_DashConnectAppPage({ inOnboardingFlow: true }));
  }, [reportPapEvent]);

  useEffect(() => {
    async function doFetchConnectors() {
      setConnectors((atom) => ({ ...atom, loading: true }));
      const connectors = await fetchConnectorsWithVisibilityFilters();
      setConnectors({
        data: connectors.map((c) => ({ ...c, loading: false })),
        loading: false,
        loaded: true,
      });
    }
    doFetchConnectors();

    async function doFetchConnections() {
      setConnections((atom) => ({ ...atom, loading: true }));
      const connections = await listConnections();
      setConnections({
        data: connections.map((c) => ({
          ...c,
          loading: false,
          syncing: false,
        })),
        loading: false,
        loaded: true,
      });
    }
    doFetchConnections();
  }, [setConnectors, setConnections]);

  const handleFinishOnboarding = async () => {
    if (haveNoConnections) {
      // this case should not happen
      return;
    } else {
      reportPapEvent(PAP_Open_DashConnectAppPageContinue());
    }

    finishOnboarding();
    // Loading desktop app url scheme will trigger native popup for user to confirm opening external app, but it doesn't
    // navigate user away from current page.
    // Because of that, doing this does not interfere with `navigate()` call that happens inside `finishOnboarding()`.
    if (fromDesktop) {
      if (useDevDesktopApp) {
        window.location.href =
          'dropbox-dash-canary-dev://?from=onboardingFinished';
      } else {
        window.location.href = 'dropbox-dash://?from=onboardingFinished';
      }
    }
  };

  const privacyLinkClicked = () => {
    setShowPrivacyModal(true);
    dispatchElementClicked(
      createUxaElementId('team_onboarding_privacy_link', {}),
    );
    reportPapEvent(PAP_Open_DashPrivacyModal());
  };

  const privacyModalClosed = () => {
    setShowPrivacyModal(false);
    reportPapEvent(PAP_Close_DashPrivacyModal());
  };

  const buttonContainerHeight = 90;
  const gapForConnectors = 24;
  const marginBottom = buttonContainerHeight;

  return (
    <Box
      className={classnames([isMobile && styles.isMobile])}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      style={{ marginBottom: `${marginBottom + gapForConnectors}px` }}
    >
      <Stack
        className={styles.onboardingHeaderContainer}
        gap="Macro XSmall"
        justify="center"
        align="center"
        display="flex"
      >
        <OnboardingHeader justifyCenter={true} />
        <Title size="medium" weightVariant="emphasized">
          {i18n.t('team_onboarding_title')}
        </Title>
        <Stack display="flex" justify="center" align="center">
          <Text tagName="div" color="subtle" className={styles.atlasGrotesk}>
            {i18n.t('team_onboarding_description')}
          </Text>
          <Text tagName="div" color="subtle">
            <Trans
              i18nKey={'team_onboarding_privacy_prompt'}
              components={{
                pButton: (
                  <Button
                    className={classnames(
                      styles.privacyLinkButton,
                      styles.atlasGrotesk,
                    )}
                    variant="transparent"
                    onClick={privacyLinkClicked}
                  />
                ),
              }}
            />
          </Text>
        </Stack>
      </Stack>
      <TeamOnboardingConnectorList snackbarMarginBottom={marginBottom} />
      <div className={styles.bottomContainer}>
        <Box
          display="flex"
          flexGrow={1}
          width={'100%'}
          justifyContent={'center'}
          padding="Micro XLarge"
          borderColor="Border Subtle"
          borderTop="Solid"
        >
          <Button
            className={styles.continueButton}
            size="xlarge"
            variant="primary"
            onClick={handleFinishOnboarding}
            disabled={haveNoConnections}
          >
            {i18n.t('continue_button')}
          </Button>
        </Box>
      </div>
      {showPrivacyModal && <PrivacyModal onClose={privacyModalClosed} />}
    </Box>
  );
};
