import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { searchQueryIdAtom } from '../atoms/searchQueryId';

export default function useSearchQueryId() {
  const [searchQueryId, setSearchQueryId] = useAtom(searchQueryIdAtom);

  const createSearchQueryId = useCallback(() => {
    const searchQueryUuid = uuid();
    setSearchQueryId(searchQueryUuid);
    return searchQueryUuid;
  }, [setSearchQueryId]);

  const clearSearchQueryId = useCallback(() => {
    setSearchQueryId('');
  }, [setSearchQueryId]);

  return {
    searchQueryUuid: searchQueryId,
    clearSearchQueryId,
    createSearchQueryId,
  };
}
