import {
  CREATE_EXCEL_ACTION_UUID,
  CREATE_POWERPOINT_ACTION_UUID,
  CREATE_STACK_ACTION_UUID,
  CREATE_WORD_ACTION_UUID,
} from '@mirage/shared/search/url-shortcut';

import type { CachedURLShortcut } from '@mirage/shared/search/url-shortcut';

// these will eventually be moved to server. This is a temp solution
const iconUrlPrefix =
  'https://cfl.dropboxstatic.com/static/go/src/dropbox/dash/connectors/metadata/icons/';

// These are hard-coded shortcuts
// Previously on Tesla, IT team would modify and place a JSON file with this array locally on dropboxers computers.
// Since we want this available on web too, hard-code here.
// local open commands for shortcuts that support it are defined in src/main/adapters/local-shortcut-launchers.ts
export const managedShortcuts: CachedURLShortcut[] = [
  {
    uuid: 'd9963578-5c21-5a41-b764-ea49b3d68444',
    hotwords: ['/who'],
    urlTemplate: 'https://os.dropbox.com/people?s={{q}}',
    titleTemplate: 'Search for {{q}} at Dropbox',
    dropboxersOnly: true,
    defaultQuery: 'name',
  },
  {
    uuid: 'b148b3ec-b7cc-5c3d-8a83-184e0cfd259f',
    hotwords: ['/drl'],
    urlTemplate: 'https://dbx.link/{{q}}',
    titleTemplate: 'Open drl/{{q}}',
    dropboxersOnly: true,
  },
  {
    uuid: '082cab37-8552-571f-92dc-4afbe2ce7113',
    hotwords: ['/jira'],
    urlTemplate: 'https://jira.dropboxer.net/issues/?jql=text%20~%20{{q}}',
    titleTemplate: 'Search for {{q}} in Jira',
    dropboxersOnly: true,
    icon: {
      lightUrl: `${iconUrlPrefix}jira.svg`,
      darkUrl: `${iconUrlPrefix}jira.svg`,
    },
  },
  {
    uuid: '0b79ee9e-5e26-5795-9ea8-56b87a566ac4',
    hotwords: ['/dxw'],
    urlTemplate: 'https://www.dropbox.com/search/work?path=/&query={{q}}',
    titleTemplate: 'Search for {{q}} in your Work Dropbox',
    dropboxersOnly: true,
    icon: {
      lightUrl: `${iconUrlPrefix}dropbox.svg`,
      darkUrl: `${iconUrlPrefix}dropbox.svg`,
    },
  },
  {
    uuid: '6e60a9a4-693b-5ca7-b225-e7ff5d0a3c1e',
    hotwords: ['/dxp'],
    urlTemplate: 'https://www.dropbox.com/search/personal?path=/&query={{q}}',
    titleTemplate: 'Search for {{q}} in your Personal Dropbox',
    dropboxersOnly: true,
    icon: {
      lightUrl: `${iconUrlPrefix}dropbox.svg`,
      darkUrl: `${iconUrlPrefix}dropbox.svg`,
    },
  },
  {
    uuid: '69fed5a6-641c-53c6-a29e-459b4cda3792',
    hotwords: ['/irond'],
    urlTemplate:
      'https://ironcladapp.com/dashboard/5835c27406f40b01008e5db6?p=0&search={{q}}',
    titleTemplate: 'Search for {{q}} in the Ironclad Dashboard',
    dropboxersOnly: true,
  },
  {
    uuid: 'd01f5cbe-f06d-5d0a-8e5b-3b8487cf8356',
    hotwords: ['/ironr'],
    urlTemplate: 'https://ironcladapp.com/records?search={{q}}',
    titleTemplate: 'Search for the {{q}} Ironclad Record',
    dropboxersOnly: true,
  },
  {
    uuid: '6106d8d6-9b35-5c66-84e3-38155f609860',
    hotwords: ['/code'],
    urlTemplate: 'https://sourcegraph.pp.dropbox.com/search?q={{q}}',
    titleTemplate: 'Search for {{q}} in Sourcegraph',
    dropboxersOnly: true,
  },
  {
    uuid: 'ec30542c-e30d-580f-9fc5-6f919396bc33',
    hotwords: ['/sl'],
    urlTemplate:
      'https://www.dropbox.com/admin/shared_content_links?sckey={{q}}',
    titleTemplate: 'Search for {{q}} in Sharedlink',
    dropboxersOnly: true,
  },
  {
    uuid: '58094a7a-0ad5-5211-aa60-5b99406eb770',
    hotwords: ['/ns'],
    urlTemplate: 'https://www.dropbox.com/admin/share_detail?ns_id={{q}}',
    titleTemplate: 'Search for {{q}} in Namespaces',
    dropboxersOnly: true,
  },
  {
    uuid: '189c22cc-b7dd-5f49-bc47-1abdb31d4b95',
    hotwords: ['/hs'],
    urlTemplate: 'https://dropbox.highspot.com/search?q={{q}}',
    titleTemplate: 'Search for {{q}} in Highspot',
    dropboxersOnly: true,
  },
  {
    uuid: 'fa2a3461-2e35-577e-a1cc-098ba0669e6f',
    hotwords: ['/cp'],
    urlTemplate: 'https://olympus.pp.dropbox.com/c-priori/{{q}}?source=DASH',
    titleTemplate:
      'Search C Priori for {{q}} (use + for multiple domains, e.g. abc.com+def.com)',
    dropboxersOnly: true,
  },
  {
    uuid: '5cea42f2-afe2-497d-a50f-ff94e8dc7390',
    hotwords: ['/new paper'],
    urlTemplate: 'https://paper.new',
    titleTemplate: 'Create a new {{q}}',
    defaultQuery: 'Dropbox Paper Doc',
    dropboxersOnly: false,
    icon: {
      lightUrl: `${iconUrlPrefix}dropboxpaper.svg`,
      darkUrl: `${iconUrlPrefix}dropboxpaper_dark.svg`,
    },
    supportedConnectors: ['dropbox'],
  },
  {
    uuid: '5cea42f2-afe2-497d-a50f-ff94e8dc7391',
    hotwords: [
      '/new google sheet',
      '/new google sheets',
      '/new sheet',
      '/new sheets',
    ],
    urlTemplate: 'https://sheets.new',
    titleTemplate: 'Create a new {{q}}',
    defaultQuery: 'Google Sheet',
    dropboxersOnly: false,
    icon: {
      lightUrl: `${iconUrlPrefix}google_sheet.svg`,
      darkUrl: `${iconUrlPrefix}google_sheet_dark.svg`,
    },
    supportedConnectors: ['googledrive'],
  },
  {
    uuid: '5cea42f2-afe2-497d-a50f-ff94e8dc7392',
    hotwords: [
      '/new google slide',
      '/new google slides',
      '/new slide',
      '/new slides',
    ],
    urlTemplate: 'https://slides.new',
    titleTemplate: 'Create a new {{q}}',
    defaultQuery: 'Google Slide',
    dropboxersOnly: false,
    icon: {
      lightUrl: `${iconUrlPrefix}google_slide.svg`,
      darkUrl: `${iconUrlPrefix}google_slide_dark.svg`,
    },
    supportedConnectors: ['googledrive'],
  },
  {
    uuid: '5cea42f2-afe2-497d-a50f-ff94e8dc7393',
    hotwords: ['/new google doc', '/new google docs', '/new doc', '/new docs'],
    urlTemplate: 'https://docs.new',
    titleTemplate: 'Create a new {{q}}',
    defaultQuery: 'Google Doc',
    dropboxersOnly: false,
    icon: {
      lightUrl: `${iconUrlPrefix}google_doc.svg`,
      darkUrl: `${iconUrlPrefix}google_doc_dark.svg`,
    },
    supportedConnectors: ['googledrive'],
  },
  {
    uuid: CREATE_STACK_ACTION_UUID,
    hotwords: ['/new stack'],
    urlTemplate: 'https://dash.ai/stacks/new', // not used
    titleTemplate: 'Create a new {{q}}',
    defaultQuery: 'Stack',
    dropboxersOnly: false,
  },
  {
    uuid: CREATE_WORD_ACTION_UUID,
    hotwords: ['/new word', '/new ms word', '/new microsoft word'],
    urlTemplate: 'https://word.new',
    titleTemplate: 'Create a new {{q}}',
    defaultQuery: 'Microsoft Word Document',
    dropboxersOnly: false,
    icon: {
      lightUrl: `${iconUrlPrefix}ms_word.svg`,
      darkUrl: `${iconUrlPrefix}ms_word_dark.svg`,
    },
    supportedConnectors: ['onedrive', 'sharepoint'],
  },
  {
    uuid: CREATE_EXCEL_ACTION_UUID,
    hotwords: ['/new excel', '/new ms excel', '/new microsoft excel'],
    urlTemplate: 'https://excel.new',
    titleTemplate: 'Create a new {{q}}',
    defaultQuery: 'Microsoft Excel Spreadsheet',
    dropboxersOnly: false,
    icon: {
      lightUrl: `${iconUrlPrefix}ms_excel.svg`,
      darkUrl: `${iconUrlPrefix}ms_excel_dark.svg`,
    },
    supportedConnectors: ['onedrive', 'sharepoint'],
  },
  {
    uuid: CREATE_POWERPOINT_ACTION_UUID,
    hotwords: [
      '/new powerpoint',
      '/new ppt',
      '/new ms ppt',
      '/new microsoft powerpoint',
    ],
    urlTemplate: 'https://powerpoint.new',
    titleTemplate: 'Create a new {{q}}',
    defaultQuery: 'Microsoft PowerPoint Presentation',
    dropboxersOnly: false,
    icon: {
      lightUrl: `${iconUrlPrefix}ms_powerpoint.svg`,
      darkUrl: `${iconUrlPrefix}ms_powerpoint_dark.svg`,
    },
    supportedConnectors: ['onedrive', 'sharepoint'],
  },
  {
    uuid: '11cced79-f35d-40d3-9348-2670958caf39',
    hotwords: ['/g', '/google'],
    urlTemplate: 'https://www.google.com/search?q={{q}}',
    titleTemplate: 'Search for {{q}} on Google',
    dropboxersOnly: false,
    icon: {
      lightUrl: `${iconUrlPrefix}google.svg`,
      darkUrl: `${iconUrlPrefix}google_dark.svg`,
    },
  },
  {
    uuid: 'ddcb550d-ccc2-4210-87cb-ad7ba0482f80',
    hotwords: ['/b', '/bing'],
    urlTemplate: 'https://www.bing.com/search?q={{q}}',
    titleTemplate: 'Search for {{q}} on Bing',
    dropboxersOnly: false,
    icon: {
      lightUrl: `${iconUrlPrefix}microsoft.svg`,
      darkUrl: `${iconUrlPrefix}microsoft.svg`,
    },
  },
] as const;
