// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgChrome({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12c0 5.522-4.478 10-10 10S2 17.522 2 12 6.478 2 12 2s10 4.478 10 10z"
        fill="#4CAF50"
      />
      <path
        d="M12 2v10l4 2-4.421 8H12c5.526 0 10-4.474 10-10 0-5.527-4.474-10-10-10z"
        fill="#FFC107"
      />
      <path
        d="M22 12c0 5.522-4.478 10-10 10S2 17.522 2 12 6.478 2 12 2s10 4.478 10 10z"
        fill="#0F9D58"
      />
      <path
        d="M12 2v10l4 2-4.421 8H12c5.526 0 10-4.474 10-10 0-5.527-4.474-10-10-10z"
        fill="#FFBA00"
      />
      <path
        d="M20.92 7.5H12V14l-1.5-.5-6.92-6.87h-.01A9.972 9.972 0 0112 2c3.9 0 7.275 2.24 8.92 5.5z"
        fill="#DB4437"
      />
      <path d="M11.579 22l4.466-8.03L14 12.5 11.579 22z" fill="#558B2F" />
      <path d="M20.933 7.5H12l-.79 2.29 9.723-2.29z" fill="#F9A825" />
      <path
        d="M3.579 6.632l4.422 7.431L10.5 13.5 3.579 6.632z"
        fill="#DD2C00"
      />
      <path
        d="M16.5 12a4.501 4.501 0 01-9 0 4.501 4.501 0 019 0z"
        fill="#fff"
      />
      <path d="M15.5 12a3.5 3.5 0 11-7 0 3.5 3.5 0 117 0z" fill="#2196F3" />
    </svg>
  );
}

export default SvgChrome;
