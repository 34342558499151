import { Button } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine, EditLine } from '@dropbox/dig-icons/assets';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Click_VoiceMenuDropdown } from '@mirage/analytics/events/types/click_voice_menu_dropdown';
import { PAP_Click_VoiceMenuOption } from '@mirage/analytics/events/types/click_voice_menu_option';
import {
  DEFAULT_PRECONFIGURED_VOICE_ID,
  isPreConfiguredVoiceID,
  PRE_CONFIGURED_VOICE_IDS,
} from '@mirage/shared/compose/compose-session';
import { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import i18n from '@mirage/translations';
import { memo } from 'react';
import styles from './VoiceSelector.module.css';

interface VoiceSelectorProps {
  customVoices: ComposeVoice[] | undefined;
  currentVoiceID: string | undefined;
  onChangeVoiceID: (voiceID: string) => void;
  onOpenVoiceSettings: (voiceID: string | undefined) => void;
  onCreateNewVoice: () => void;
  disabled: boolean;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
}
export const VoiceSelector = memo(
  ({
    customVoices,
    currentVoiceID,
    onChangeVoiceID,
    onCreateNewVoice,
    onOpenVoiceSettings,
    disabled,
    logComposeEvent,
  }: VoiceSelectorProps) => {
    return (
      <Menu.Wrapper
        onSelection={(value) => {
          if (value === NEW_STYLE_ID) {
            onCreateNewVoice();
          } else {
            onChangeVoiceID(value);
          }
          logComposeEvent(
            PAP_Click_VoiceMenuOption({
              actionType: value,
            }),
          );
        }}
      >
        {({ getContentProps, getTriggerProps }) => (
          <>
            <Button
              {...getTriggerProps()}
              variant="borderless"
              withDropdownIcon
              hasNoUnderline
              disabled={disabled}
              onClickCapture={() => {
                logComposeEvent(PAP_Click_VoiceMenuDropdown());
              }}
            >
              {getVoiceLabels(currentVoiceID, customVoices || []).title}
            </Button>
            <Menu.Content {...getContentProps()}>
              <Menu.Segment>
                {PRE_CONFIGURED_VOICE_IDS.map((voiceID) => {
                  const labels = getVoiceLabels(voiceID, customVoices || []);
                  return (
                    <Menu.SelectItem
                      className={styles.voiceRow}
                      key={voiceID}
                      value={voiceID}
                      selected={voiceID === currentVoiceID}
                      withTitle={labels.title}
                      withSubtitle={labels.description}
                    />
                  );
                })}
              </Menu.Segment>
              {(customVoices || []).length > 0 && (
                <Menu.Segment>
                  {customVoices?.map((voice) => (
                    <Menu.SelectItem
                      className={styles.voiceRow}
                      key={voice.id}
                      value={voice.id}
                      selected={voice.id === currentVoiceID}
                      withRightAccessory={
                        <IconButtonWithTooltip
                          variant="borderless"
                          tooltipProps={{
                            title: i18n.t('edit'),
                          }}
                          onClick={(e: React.MouseEvent) => {
                            onOpenVoiceSettings(voice.id);
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          className={styles.editVoiceButton}
                        >
                          <UIIcon src={EditLine} />
                        </IconButtonWithTooltip>
                      }
                      withTitle={voice.name}
                    />
                  ))}
                </Menu.Segment>
              )}
              <Menu.Segment>
                <Menu.SelectItem
                  className={styles.voiceRow}
                  value={NEW_STYLE_ID}
                  withLeftAccessory={<UIIcon src={AddLine} />}
                  withTitle={i18n.t('compose_context_form_create_style')}
                />
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
    );
  },
);
VoiceSelector.displayName = 'VoiceSelector';

const NEW_STYLE_ID = 'NEW_STYLE';

interface VoiceLabels {
  title: string;
  description: string | undefined;
}
export function getVoiceLabels(
  voiceID: string | undefined,
  customVoices: ComposeVoice[],
): VoiceLabels {
  const v = voiceID || DEFAULT_PRECONFIGURED_VOICE_ID;
  if (isPreConfiguredVoiceID(v)) {
    switch (v) {
      case 'preset_informative':
        return {
          title: i18n.t('compose_preset_informative_style_label_title'),
          description: i18n.t(
            'compose_preset_informative_style_label_description',
          ),
        };
      case 'preset_persuasive':
        return {
          title: i18n.t('compose_preset_persuasive_style_label_title'),
          description: i18n.t(
            'compose_preset_persuasive_style_label_description',
          ),
        };
      case 'preset_instructional':
        return {
          title: i18n.t('compose_preset_instructional_style_label_title'),
          description: i18n.t(
            'compose_preset_instructional_style_label_description',
          ),
        };
      case 'preset_narrative':
        return {
          title: i18n.t('compose_preset_narrative_style_label_title'),
          description: i18n.t(
            'compose_preset_narrative_style_label_description',
          ),
        };
      case 'preset_informal':
        return {
          title: i18n.t('compose_preset_informal_style_label_title'),
          description: i18n.t(
            'compose_preset_informal_style_label_description',
          ),
        };
      case 'preset_analytical':
        return {
          title: i18n.t('compose_preset_analytical_style_label_title'),
          description: i18n.t(
            'compose_preset_analytical_style_label_description',
          ),
        };
      default:
        v satisfies never;
        throw new Error(`Unsupported voice type: ${v}`);
    }
  }
  const customVoice = customVoices.find((voice) => voice.id === v);
  return {
    title: customVoice?.name || '',
    description: undefined,
  };
}
