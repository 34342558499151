// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgMsTeams({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.875 10.523c0-.312.253-.565.565-.565h6.996c.311 0 .564.253.564.565v4.123a4.063 4.063 0 01-8.125 0v-4.123z"
        fill="url(#ms-teams_svg__paint0_linear)"
      />
      <path
        d="M7.625 9.474c0-.423.343-.766.766-.766h8.468c.423 0 .766.343.766.766v6.11a5 5 0 01-10 0v-6.11z"
        fill="url(#ms-teams_svg__paint1_linear)"
      />
      <circle cx={19.083} cy={7.25} r={2.083} fill="#34439E" />
      <circle
        cx={19.083}
        cy={7.25}
        r={2.083}
        fill="url(#ms-teams_svg__paint2_linear)"
      />
      <circle
        cx={13.667}
        cy={6}
        r={2.5}
        fill="url(#ms-teams_svg__paint3_linear)"
      />
      <path
        d="M6.375 9.958a2.5 2.5 0 012.5-2.5H12c.64 0 1.042.854 1.484 1.25h.681c.213.368.335.795.335 1.25v6.25a2.5 2.5 0 01-2.5 2.5H8.718s-.468-.625-.635-1.041H6.375V9.958z"
        fill="#000"
        fillOpacity={0.3}
      />
      <path
        d="M2 7.667C2 6.747 2.746 6 3.667 6H12c.92 0 1.667.746 1.667 1.667V16c0 .92-.746 1.667-1.667 1.667H3.667C2.747 17.667 2 16.92 2 16V7.667z"
        fill="url(#ms-teams_svg__paint4_linear)"
      />
      <path
        d="M10.125 8.708h-5v1.147h1.872v5.103h1.256V9.855h1.872V8.708z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="ms-teams_svg__paint0_linear"
          x1={13.875}
          y1={10.419}
          x2={22.099}
          y2={15.793}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#364088" />
          <stop offset={1} stopColor="#6E7EE1" />
        </linearGradient>
        <linearGradient
          id="ms-teams_svg__paint1_linear"
          x1={7.625}
          y1={13.961}
          x2={17.625}
          y2={13.961}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#515FC4" />
          <stop offset={1} stopColor="#7084EA" />
        </linearGradient>
        <linearGradient
          id="ms-teams_svg__paint2_linear"
          x1={17}
          y1={5.386}
          x2={21.025}
          y2={8.218}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#364088" />
          <stop offset={1} stopColor="#6E7EE1" />
        </linearGradient>
        <linearGradient
          id="ms-teams_svg__paint3_linear"
          x1={11.881}
          y1={4.214}
          x2={15.095}
          y2={7.964}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4858AE" />
          <stop offset={1} stopColor="#4E60CE" />
        </linearGradient>
        <linearGradient
          id="ms-teams_svg__paint4_linear"
          x1={2}
          y1={11.833}
          x2={13.667}
          y2={11.833}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2A3887" />
          <stop offset={1} stopColor="#4C56B9" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgMsTeams;
