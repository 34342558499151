import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { OpenLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { PAP_Shown_DashCard } from '@mirage/analytics/events/types/shown_dash_card';
import { LinkComponent, LinkList } from '@mirage/link-list';
import { ListItemSize } from '@mirage/link-list/types';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { MetricPageName } from '@mirage/service-operational-metrics/module/constants';
import { useRecordModuleLatency } from '@mirage/service-operational-metrics/module/module';
import {
  getFreshLoginTags,
  useFreshLoginTags,
} from '@mirage/service-operational-metrics/module/tags';
import {
  RecentConnectorContent,
  RecentContent,
} from '@mirage/service-recent-content/types';
import { useCustomizableModuleIsHidden } from '@mirage/service-settings/useCustomizableModuleSettings';
import {
  CardHeaderType,
  TwoColumnGridCard,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import { getTimeAgoString } from '@mirage/shared/util/time';
import i18n from '@mirage/translations';
import { useEffect } from 'react';
import { AsyncLinkListCard } from './AsyncCard';
import styles from './RecentsByConnectorCard.module.css';
import { useImpressionLogging } from './useImpressionLogging';

const actionSurfaceComponent = 'per_connector';
const featureLine = 'per_connector';

// Return the JSX components for sorting.
export const getRecentsByConnectorCardList = ({
  isLoading,
  topContentByConnector,
}: {
  isLoading: boolean;
  topContentByConnector: {
    key: string;
    name: string;
    content: RecentConnectorContent[];
  }[];
}) => {
  return topContentByConnector.map(({ key: id, name, content }) => {
    return {
      id,
      jsx: (
        <RecentsByConnectorCard
          key={id}
          isLoading={isLoading}
          connectorName={name}
          dashConnectorId={id}
          links={content.slice(0, 4)}
        />
      ),
    };
  });
};

type RecentsByConnectorCardProps = {
  isLoading: boolean;
  connectorName: string;
  dashConnectorId: string;
  links: RecentContent[];
};

export const RecentsByConnectorCard: React.FC<RecentsByConnectorCardProps> = (
  props,
) => {
  const isHidden = useCustomizableModuleIsHidden(props.dashConnectorId);

  return isHidden ? null : <RecentsByConnectorCardActual {...props} />;
};

const RecentsByConnectorCardActual: React.FC<RecentsByConnectorCardProps> = ({
  isLoading,
  connectorName,
  dashConnectorId,
  links,
}) => {
  const freshLoginTags = useFreshLoginTags();
  const contentRowEnabled = !!useFeatureFlagValue(
    'dash_use_dash_components',
    false,
  );

  // The connector cards are very slow, and since they appear near the bottom
  // of page, and won't appear in the new Startpage V8, so exclude them from
  // the final E2E calculation.
  const { markModuleLoadComplete } = useRecordModuleLatency(
    MetricPageName.HOME_V2,
    `from_connector:${dashConnectorId}`,
    freshLoginTags || getFreshLoginTags,
    /* excludeFromE2E */ true,
  );

  useEffect(() => {
    if (!isLoading) {
      markModuleLoadComplete();
    }
  }, [isLoading, markModuleLoadComplete]);

  useImpressionLogging(
    !isLoading && links.length > 0,
    dashConnectorId,
    PAP_Shown_DashCard({
      actionSurfaceComponent,
      featureLine,
      dashCardType: 'recents_by_connector',
      dashConnectorId,
    }),
  );

  const emptyCard = links.length === 0 && !isLoading;

  if (contentRowEnabled) {
    return (
      <TwoColumnGridCard
        settingId={`connector:${dashConnectorId}`}
        cardTypeProps={{
          cardType: CardHeaderType.INLINE_SUBTITLE,
          actionSurfaceComponent,
          featureLine,
          title: i18n.t('updates_from_connector', { connector: connectorName }),
        }}
        subtitle={
          isLoading ? undefined : i18n.t('num_items', { count: links.length })
        }
        collapseBtnSize="large"
        isAlwaysCollapsed={emptyCard ? false : undefined}
        showDividerLine={emptyCard ? false : undefined}
      >
        <LinkList listItemSize={ListItemSize.Large} numLinksToShow={4}>
          {links.map((link) => (
            <LinkComponent
              key={link.url}
              link={{
                title: link.title,
                url: link.url,
              }}
              listItemSize={ListItemSize.Large}
              metadata={
                link.visit_ms
                  ? [getTimeAgoString(link.visit_ms, true, true)]
                  : []
              }
              openPapEvents={[
                PAP_Open_DashLink({
                  actionSurfaceComponent,
                  featureLine,
                }),
              ]}
              accessoryComponent={
                <IconButton variant="transparent">
                  <UIIcon src={OpenLine} className={styles.rightIcon} />
                </IconButton>
              }
            ></LinkComponent>
          ))}
        </LinkList>
      </TwoColumnGridCard>
    );
  }

  return (
    <AsyncLinkListCard
      settingId={`connector:${dashConnectorId}`}
      title={i18n.t('updates_from_connector', { connector: connectorName })}
      subtitle={
        isLoading ? undefined : i18n.t('num_items', { count: links.length })
      }
      links={links}
      showLoading={false}
      loadingRowCount={4}
      showSubtitleWhenExpanded={!isLoading && links.length === 0}
      actionSurfaceComponent={actionSurfaceComponent}
      featureLine={featureLine}
      dashConnectorId={dashConnectorId}
      isAlwaysCollapsed={emptyCard ? false : undefined}
      showDividerLine={emptyCard ? false : undefined}
    />
  );
};
