// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3489
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashStackFilterName } from '../enums/dash_stack_filter_name';
import type { DashSurfaceEntryPoint } from '../enums/dash_surface_entry_point';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { StartPageAction } from '../enums/start_page_action';

// Event_name: 'shown.tab_panel'
// Description: event that captures generic activities of what is shown in the tab panel
// https://capture.dropbox.com/GtCUDkVzCy42pbiC
// Owner: dash-web
export type Shown_TabPanel = PAPEventGeneric<
  'dash',
  'shown',
  'tab_panel',
  {
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // what dash product the cloud connector is launched from [dash_desktop, dash_webapp, etc]
    dashSurfaceEntryPoint?: DashSurfaceEntryPoint;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Boolean event property that is set to true for Dash for Business (dfb) users.
    isDfbUserV2?: boolean;
    // event property to capture general actions on start page
    startPageAction?: StartPageAction;
    // Whether the displayed screen is displaying the "no content found" view
    isEmpty?: boolean;
    // filters for stacks
    dashStackFilterName?: DashStackFilterName;
  }
>;

export function PAP_Shown_TabPanel(
  properties?: Shown_TabPanel['properties'],
): Shown_TabPanel {
  return <Shown_TabPanel>{
    class: 'dash',
    action: 'shown',
    object: 'tab_panel',
    properties,
  };
}
