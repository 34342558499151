import * as React from "react";

import { Box, BoxProps, PolymorphicRef } from "@dropbox/dig-foundations";
import classNames from "classnames";

import styles from "./Contents.module.css";

export type Spacing = Extract<BoxProps<"div">["padding"], string>;

/** A version of the Contents that bleeds into the apparent horizontal padding of the card. */
interface HasBreakout {
  /**
   * How much the contents should bleed into the horizontal padding of the card.
   */
  breakout: "xsmall" | "small" | "standard" | "large" | "xlarge";
  /**
   * Whether to also bleed into the bottom padding of the card (by the same degree as the horizontal
   * bleed).
   */
  breakoutBottom?: boolean;
  /**
   * React children of the contents. If a breakout is used, can be a function that will be passed
   * the amount of spacing that the breakout bleeds into the horizontal padding of the card. This
   * can be used to optically align content within a `Card.Contents` to the rest of the card while
   * allowing the contents element to still bleed into the `Card`'s padding for treatments like
   * hover and focus states.
   */
  children?:
    | React.ReactNode
    | ((opts: { breakoutSpacing: Spacing }) => React.ReactNode);
}

/** A version of the Contents that conforms to the apparent horizontal padding of the card. */
interface DoesNotHaveBreakout {
  breakout?: undefined;
  children?: React.ReactNode;
}

export type ContentsProps<C extends React.ElementType = "div"> = BoxProps<C> &
  (HasBreakout | DoesNotHaveBreakout) & {
    contentsRef?: PolymorphicRef<C>;
  };

/**
 * Content within a `Card`'s main body.
 *
 * Contents are typically contained within the visible horizontal padding of the card but can break
 * out into that padding by passing a `breakout` prop to this component. See docs for `breakout` and
 * `children` for more details.
 */
export function Contents<C extends React.ElementType = "div">({
  className,
  breakout,
  breakoutBottom,
  as,
  children,
  contentsRef,
  ...rest
}: ContentsProps<C>) {
  const breakoutSpacing: Spacing = (() => {
    switch (breakout) {
      case undefined:
        return "0";
      case "xsmall":
        return "Micro XSmall";
      case "small":
        return "Micro Small";
      case "standard":
        return "Micro Medium";
      case "large":
        return "Micro Large";
      case "xlarge":
        return "Micro XLarge";
    }
  })();

  return (
    <Box
      ref={contentsRef}
      // TS does _not_ like the extended polymorphism here. We're getting the type safety for the
      // end user though so I think this is livable. I messed with it for a bit to get it to accept
      // just a spread of our rest params but it's not happy. If you wanna try to fix it, godspeed.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      as={as as any}
      className={classNames(
        {
          [styles.xsmallBreakout]: breakout === "xsmall",
          [styles.smallBreakout]: breakout === "small",
          [styles.standardBreakout]: breakout === "standard",
          [styles.largeBreakout]: breakout === "large",
          [styles.xlargeBreakout]: breakout === "xlarge",
          [styles.xsmallBottomBreakout]:
            breakoutBottom && breakout === "xsmall",
          [styles.smallBottomBreakout]: breakoutBottom && breakout === "small",
          [styles.standardBottomBreakout]:
            breakoutBottom && breakout === "standard",
          [styles.largeBottomBreakout]: breakoutBottom && breakout === "large",
          [styles.xlargeBottomBreakout]:
            breakoutBottom && breakout === "xlarge",
        },
        styles.contents,
        className,
      )}
      {...rest}
    >
      {typeof children === "function"
        ? children({ breakoutSpacing })
        : children}
    </Box>
  );
}
