import { Avatar } from '@dropbox/dig-components/avatar';
import { Chip } from '@dropbox/dig-components/chip';
import { UIIcon } from '@dropbox/dig-icons';
import { PersonMultipleLine } from '@dropbox/dig-icons/assets';
import { getInitialsFromPerson, getPersonTitle } from '@mirage/shared/account';
import { SearchFilterType } from '@mirage/shared/search/search-filters';
import { KeyCodes } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import { useRef, useState } from 'react';
import styles from './PeopleFilterDropdown.module.css';
import { PeopleFilterTypeahead } from './PeopleFilterTypeahead';

import type {
  PersonFilter,
  PersonObject,
} from '@mirage/shared/search/search-filters';

type PersonFilterProps = {
  onSelect: (value: PersonFilter, query: string) => void;
  selectedPerson: PersonFilter | undefined;
  onOpenDropdown: () => void;
  currentAccount?: PersonObject;
};

export const PersonFilterDropdown = ({
  onSelect,
  selectedPerson,
  onOpenDropdown,
  currentAccount,
}: PersonFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const selectedPersonInitials = getInitialsFromPerson(
    selectedPerson?.parameters,
  );
  const selectedPersonTitle = getPersonTitle(selectedPerson?.parameters);

  const handleToggle = () => {
    setIsOpen((prev) => {
      const newIsOpen = !prev;

      if (newIsOpen) {
        onOpenDropdown();
      }
      return newIsOpen;
    });
  };

  const onPersonSelection = (
    person: PersonObject | undefined,
    query: string,
  ) => {
    onSelect(
      {
        id: 'person',
        type: SearchFilterType.Person,
        parameters: person,
      },
      query,
    );

    setIsOpen(false);
    anchorRef?.current?.focus();
  };

  const handleTriggerKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case KeyCodes.arrowUp:
      case KeyCodes.arrowDown:
        setIsOpen(true);
        return;
    }
  };

  return (
    <div>
      <Chip
        withDropdownIcon={true}
        onClick={handleToggle}
        isSelected={!!selectedPerson}
        selectedStyle="stroke"
        aria-pressed={selectedPerson ? 'true' : 'false'}
        onKeyDown={handleTriggerKeyDown}
        ref={anchorRef}
      >
        <Chip.IconAccessory>
          {selectedPerson ? (
            <Avatar
              size="small"
              src={selectedPerson?.parameters?.profilePhotoUrl}
              alt={selectedPersonInitials}
              hasNoOutline
              className={styles.avatar}
            >
              {selectedPersonInitials}
            </Avatar>
          ) : (
            <UIIcon src={PersonMultipleLine} />
          )}
        </Chip.IconAccessory>
        <Chip.Content>
          {selectedPerson
            ? selectedPersonTitle
            : i18n.t('people_search_chip_placeholder')}
        </Chip.Content>
      </Chip>
      <PeopleFilterTypeahead
        onPersonSelection={onPersonSelection}
        selectedPerson={selectedPerson?.parameters}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        anchorRef={anchorRef}
        currentAccount={currentAccount}
      />
    </div>
  );
};
