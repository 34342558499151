import i18n from '@mirage/translations';
import darwinHotKeys, {
  modifierKeys as darwinModifierKeys,
  mousetrapMap as darwinMousetrapMap,
} from './darwin';
import win32HotKeys, {
  modifierKeys as win32ModifierKeys,
  mousetrapMap as win32MousetrapMap,
} from './win32';

import type { GlobalShortcutActions } from '@mirage/settings/components/list-items/keyboard-shortcuts/hooks';

export enum HotkeysEditType {
  MAIN = 'main',
  BROWSER = 'browser',
}
export type HotkeysType = [GlobalShortcutActions, string, HotkeysEditType];

function getLabels() {
  const labels = {
    general: [
      ['appShortcut', i18n.t('app_shortcut'), HotkeysEditType.MAIN],
      ['settings', i18n.t('settings_settings_shortcut_label')],
      ['checkForUpdates', i18n.t('check_for_updates')],
      ['quit', i18n.t('quit_dash')],
    ],
  };

  return labels;
}

const getWin32KeyMaps = (isDesktop: boolean) => {
  return {
    ...win32HotKeys(isDesktop),
    labels: getLabels(),
  };
};

const getDarwinKeyMaps = (isDesktop: boolean) => {
  return {
    ...darwinHotKeys(isDesktop),
    labels: getLabels(),
  };
};

const commonMouseTrapMap = {
  ArrowLeft: 'left',
  ArrowRight: 'right',
  Enter: 'enter',
  Escape: 'escape',
  Tab: 'tab',
  Space: 'space',
};

const getWin32MouseTrapMap = () => {
  return {
    ...commonMouseTrapMap,
    ...win32MousetrapMap,
  };
};

const getDarwinMouseTrapMap = () => {
  return {
    ...commonMouseTrapMap,
    ...darwinMousetrapMap,
  };
};

// These map to React's key labels
export const modifierKeys = [
  'Meta', // Darwin Command, Win32 Windows
  'Alt', // Darwin Option, Win32 ALT
  'Control', // Darwin Control, Win32 CTRL
  'Shift',
];

// these keys don't need a modifier, but can take one
export const specialKeys = [
  'ArrowUp',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'Enter',
  'Escape',
  'Tab',
];

export const getOsModifierKeys = (platform: string) => {
  switch (platform) {
    case 'darwin':
      return darwinModifierKeys;
    case 'win32':
    default:
      return win32ModifierKeys;
  }
};

export const getMousetrapMap = (platform: string) => {
  switch (platform) {
    case 'darwin':
      return getWin32MouseTrapMap();
    case 'win32':
    default:
      return getDarwinMouseTrapMap();
  }
};

export const getKeyMaps = (platform: string, isDesktop: boolean) => {
  switch (platform) {
    case 'darwin':
      return getDarwinKeyMaps(isDesktop);
    case 'win32':
    default:
      return getWin32KeyMaps(isDesktop);
  }
};
