import { Typeahead } from '@dropbox/dig-components/typeahead';
import { UIIcon } from '@dropbox/dig-icons';
import React from 'react';
import { HighlightMatchedText } from './TypeaheadUtils';

// Init once inside the component. Can't init here as i18n is not ready yet.
const NO_CONTACTS_FOUND = '';
const NO_EVENTS_FOUND = '';

const isPlaceholderText = (text: string): boolean => {
  return text === NO_CONTACTS_FOUND || text === NO_EVENTS_FOUND;
};

export const TypeaheadRow: React.FC<{
  mentionText: string;
  rowText: string;
  iconSrc: React.ComponentType<React.SVGAttributes<SVGElement>>;
}> = ({ mentionText, rowText, iconSrc }) => {
  const needHighlight = !isPlaceholderText(rowText);

  return (
    <Typeahead.Row
      key={rowText}
      value={rowText}
      withTitle={
        needHighlight ? (
          <HighlightMatchedText mentionText={mentionText} rowText={rowText} />
        ) : (
          rowText
        )
      }
      withLeftAccessory={<UIIcon src={iconSrc} />}
    />
  );
};
