import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { openURL } from '@mirage/service-platform-actions';
import { getConnectorsUrl } from '@mirage/service-setting-actions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export const useConnectorsUI = () => {
  const [connectorsUrl, setConnectorsUrl] = useState('');
  const navigate = useNavigate();
  const isDesktop = EnvCtx.surface == 'desktop';

  useEffect(() => {
    getConnectorsUrl().then(setConnectorsUrl);
  }, []);

  const appSettingsPath = useMemo(() => {
    if (isDesktop) {
      return connectorsUrl;
    } else {
      return '/settings/apps';
    }
  }, [connectorsUrl]);

  const openConnectors = useCallback(() => {
    navigateOrOpenUrl(isDesktop, navigate, appSettingsPath);
  }, [navigate, appSettingsPath]);

  return {
    connectorsUrl,
    openConnectors,
    appSettingsPath,
  };
};

// Exported for testing
// Web: if connectors UI is embedded, we redirect to the new route
// Desktop: since connectors UI is not embedded in settings, open the browser
export const navigateOrOpenUrl = (
  isDesktop: boolean,
  navigate: NavigateFunction,
  appSettingsPath: string,
) => {
  if (!isDesktop) {
    navigate(appSettingsPath);
  } else {
    if (!appSettingsPath || appSettingsPath == '') {
      getConnectorsUrl().then(openURL);
    } else {
      openURL(appSettingsPath);
    }
  }
};
