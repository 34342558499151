import {
  HTMLProps,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  DragDropContext,
  DropResult,
  OnDragEndResponder,
  OnDragStartResponder,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { Draggable, DraggableGetStyle } from './Draggable';
import { Droppable } from './Droppable';
import { reorder } from './utils';

type HTMLDivProps = HTMLProps<HTMLDivElement>;

export type DragDropListItem = {
  id: string;
  key?: string;
  render: (isDragging: boolean) => ReactElement;
};

export type DragDropListOnDrop = (
  newItems: DragDropListItem[],
  fromIndex: number,
  toIndex: number,
) => void;

export type DragDropListProps = {
  // Unique id for this droppable list.
  droppableId: string;

  // List of items to reorder using drag-and-drop.
  items: DragDropListItem[];

  // Expect item contents to get updated, so always update them.
  alwaysUpdateItems?: boolean;

  // Off switch to render everything without enabling dragging.
  dragDisabled?: boolean;

  // Drag event listeners directly provided by react-beautiful-dnd.
  onDragStart?: OnDragStartResponder;
  onDragEnd?: OnDragEndResponder;

  // Send the newly-ordered list to the callback. This could save the caller
  // some work with the reordering logic. If you need the indexes only, then
  // just use `onDropWithIndexes` which is more lightweight.
  onDrop?: DragDropListOnDrop;

  // Extra properties for the <div> element wrapping the list container.
  listContainerProps?: HTMLDivProps;
  getListContainerProps?: (isDraggingOver: boolean) => HTMLDivProps | undefined;

  // Extra properties for the <div> element wrapping the list item.
  itemContainerProps?: HTMLDivProps;
  getItemContainerProps?: (isDragging: boolean) => HTMLDivProps | undefined;

  // Due to the way the react-beautiful-dnd api is implemented, we need to
  // update the style when dragging, so make it easy to override this.
  getItemContainerStyle?: DraggableGetStyle;
};

/**
 * Generic drag-and-drop list implementation using react-beautiful-dnd.
 * The list itself is wrapped inside a <div> element. Then each list item is
 * also wrapped inside its own <div> element. If you need to use a different
 * element to render the list, just put this element as a child inside that
 * element. You can style the container elements in any way you need.
 */
export const DragDropList: React.FC<DragDropListProps> = ({
  droppableId,
  dragDisabled,
  items,
  alwaysUpdateItems,
  onDragStart,
  onDragEnd,
  onDrop,
  listContainerProps,
  getListContainerProps,
  itemContainerProps,
  getItemContainerProps,
  getItemContainerStyle,
}) => {
  const localItems = useRef(items);

  const [, setForceReload] = useState(false);
  const reload = useCallback(() => setForceReload((v) => !v), []);

  const updateLocalItems = useCallback(
    (items: DragDropListItem[]) => {
      localItems.current = items;
      reload();
    },
    [reload],
  );

  useEffect(() => {
    if (alwaysUpdateItems) {
      updateLocalItems(items);
      return;
    }

    // Avoid component flickers if possible.
    const oldIds = JSON.stringify(localItems.current.map(({ id }) => id));
    const newIds = JSON.stringify(items.map(({ id }) => id));

    if (oldIds !== newIds) {
      updateLocalItems(items);
    }
  }, [alwaysUpdateItems, items, updateLocalItems]);

  // Intentionally not memoizing as we don't expect callers to memoize the
  // passed-in callbacks.
  function actualOnDragEnd(result: DropResult, provided: ResponderProvided) {
    onDragEnd?.(result, provided);

    // Dropped outside the list.
    if (!result.destination) {
      return;
    }

    const fromIndex = result.source.index;
    const toIndex = result.destination.index;

    if (fromIndex === toIndex) {
      return;
    }

    const newlyOrderedItems = reorder(items, fromIndex, toIndex);
    updateLocalItems(newlyOrderedItems);

    onDrop?.(newlyOrderedItems, fromIndex, toIndex);
  }

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={actualOnDragEnd}>
      <Droppable
        dropDisabled={dragDisabled}
        droppableId={droppableId}
        props={listContainerProps}
        getProps={getListContainerProps}
        renderChild={(droppableProps) => (
          <div {...droppableProps}>
            {localItems.current.map((item, index) => (
              <Draggable
                key={item.id}
                dragDisabled={dragDisabled}
                draggableId={item.id}
                index={index}
                renderChild={(props, isDragging) => (
                  <div {...props}>{item.render(isDragging)}</div>
                )}
                props={itemContainerProps}
                getProps={getItemContainerProps}
                getStyle={getItemContainerStyle}
              />
            ))}
          </div>
        )}
      />
    </DragDropContext>
  );
};
