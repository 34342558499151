// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgMayven({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.293 6c-1.304 0-3.218.718-4.07 2.367C12.48 6.877 11.123 6 9.287 6c-1.25 0-3.111.851-3.35 2.74V6.32H2v12.42h3.936v-6.49c0-2.075 1.09-2.606 2.154-2.606.984 0 1.942.691 1.942 2.606v6.49h3.936v-6.064c0-2.181.904-3.032 2.128-3.032 1.01 0 1.968.611 1.968 2.526v6.57H22v-6.676C22 8.26 20.404 6 17.293 6z"
        fill="#1139C8"
      />
    </svg>
  );
}

export default SvgMayven;
