import { namespace } from '@mirage/service-operational-metrics';
import { ONE_MINUTE_IN_MILLIS } from '@mirage/shared/util/constants';
import * as jobs from '@mirage/shared/util/jobs';

type MemoryInfo = {
  jsHeapSizeLimit: number;
  totalJSHeapSize: number;
  usedJSHeapSize: number;
};

const ONE_MB = 1_048_576;

const JOB_NAME = 'webapp/report-memory-usage';
const JOB_INTERVAL = 10 * ONE_MINUTE_IN_MILLIS;

const { stats: memoryStats } = namespace('memory');

async function reportMemoryUsage() {
  // performance.memory is a deprecated API, so the typing is not intact, but
  // it is the only possible way to get the memory usage info now.
  const memory = (performance as { memory?: MemoryInfo }).memory;

  if (!memory) return;

  // Use MB to make this number much more human-readable than bytes.
  memoryStats('used_mb', Math.floor(memory.usedJSHeapSize / ONE_MB));
}

export function startReportMemoryUsageJob() {
  jobs.register(
    JOB_NAME,
    JOB_INTERVAL,
    /* runOnStart= */ true,
    reportMemoryUsage,
  );
}

export function cancelReportMemoryUsageJob() {
  jobs.unregister(JOB_NAME);
}
