// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgBasecamp({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.073 17.944c-.487 0-.867-.38-.921-.868-.325-4.661-2.873-11.233-7.602-11.233-3.902 0-6.843 2.71-8.74 8.09-.163.487-.705.758-1.192.542-.488-.163-.76-.705-.542-1.139C4.244 7.144 7.78 4 12.55 4c6.247 0 9.12 7.927 9.445 12.968.054.488-.325.976-.867.976h-.055z"
        fill="#1D2D35"
      />
      <path
        d="M12.334 21.372a11.137 11.137 0 01-8.307-3.74c-.27-.217-.325-.596-.217-.922.597-1.572 2.168-5.217 4.174-5.27 1.03 0 1.789.812 2.385 1.463.162.216.433.487.65.65.488-.488 1.41-2.005 2.114-3.577.217-.488.759-.65 1.247-.434.487.217.65.759.433 1.247-2.168 4.715-3.306 4.715-3.74 4.715-.867 0-1.463-.65-2.06-1.3-.27-.272-.812-.868-1.03-.868-.487.054-1.517 1.735-2.221 3.524a9.253 9.253 0 006.734 2.71c2.168 0 6.03-.38 7.602-2.71-.434-4.662-2.981-10.963-7.602-10.963a.95.95 0 01-.921-.921c0-.488.433-.976.975-.976 6.247 0 9.12 7.927 9.445 12.968 0 .163-.054.38-.108.542-1.464 2.493-4.77 3.902-9.337 3.902-.054-.04-.162-.04-.216-.04z"
        fill="#1D2D35"
      />
    </svg>
  );
}

export default SvgBasecamp;
