import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Link } from '@mirage/link-list/types';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import { useEffect, useRef } from 'react';
import styles from './DragAndDrop.module.css';
import { useDraggablePreview } from './Preview';
import { useDnDContext } from './Provider';
import { DraggableData, typeKey } from './utils';

interface DraggableSuggestionProps {
  suggestion: Link;
  children?: React.ReactNode;
}
export const DraggableSuggestion: React.FC<DraggableSuggestionProps> = ({
  suggestion,
  children,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { isDisabled, setIsItemDragging } = useDnDContext();
  const { preview, handleGenerateDragPreview } =
    useDraggablePreview(suggestion);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    return draggable({
      element,
      canDrag: () => !isDisabled,
      onDragStart: () => setIsItemDragging(true),
      onDrop: () => setIsItemDragging(false),
      getInitialData: (): DraggableData => ({
        [typeKey]: true,
        id: nonNil(suggestion.itemIdHash, 'suggestion item ID hash').toString(),
        type: 'suggestion',
        payload: suggestion,
      }),
      onGenerateDragPreview: handleGenerateDragPreview,
    });
  }, [suggestion, isDisabled, handleGenerateDragPreview, setIsItemDragging]);

  if (isDisabled) {
    return <>{children}</>;
  }

  return (
    <div ref={ref} className={styles.draggableSuggestion}>
      {children}
      {preview}
    </div>
  );
};
