import { useEffect, useState } from 'react';
import { useMirageAnalyticsContext } from '../AnalyticsProvider';
import { PAP_Select_DashUxa } from '../events/types/select_dash_uxa';

const UXA_DATA_PREFIX = 'data-uxa-';
const UXA_DATA_NAME = 'data-uxa-log';

/**
 * This is implemented as a React hook so that we can tap into the mirage
 * analytics context.
 */
export function useInitPapUxa() {
  // Note: In localhost hot reloading, this event listener will be registered
  // one more time for each hot reload, so getting duplicate events is
  // expected. Make use of the fact that hot reloading maintains the react
  // states to work around this.
  const [registered, setRegistered] = useState(false);

  const { reportPapEvent } = useMirageAnalyticsContext();

  useEffect(() => {
    function onClick(e: MouseEvent) {
      let element = e.target;

      while (element instanceof Element) {
        const uxaElementId = element.getAttribute(UXA_DATA_NAME);
        if (!uxaElementId) {
          // Find the first UXA parent (if any).
          element = element.parentElement;
          continue;
        }

        const now = new Date();

        // Fields not defined in the PAP event itself.
        const extraProperties: Record<string, unknown> = {
          element_id: uxaElementId,
          readable_time: now.toISOString(),
        };

        // Read extra UXA properties.
        for (const propertyName of element.getAttributeNames()) {
          const value = element.getAttribute(propertyName);
          if (value === undefined || value === null) {
            continue;
          }

          const uxaPropertyName = convertDataNameToUxaName(propertyName);
          if (!uxaPropertyName) {
            continue;
          }

          extraProperties[uxaPropertyName] = value;
        }

        reportPapEvent(
          PAP_Select_DashUxa({
            startTimeMs: now.getTime(),
            ...extraProperties,
          }),
        );

        break;
      }
    }

    if (!registered) {
      setRegistered(true);
      document.addEventListener('click', onClick, true);
    }

    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [registered, reportPapEvent]);
}

// Convert a string like `data-uxa-entity-id` to `entity_id`.
function convertDataNameToUxaName(dataName: string): string | null {
  if (dataName === UXA_DATA_NAME) {
    return null;
  }

  if (!dataName.startsWith(UXA_DATA_PREFIX)) {
    return null;
  }

  dataName = dataName.slice(UXA_DATA_PREFIX.length);

  return dataName.replace(/-/g, '_');
}
