import { useCallback } from 'react';
import { InView } from 'react-intersection-observer';

type InfiniteScrollProps = React.PropsWithChildren<{
  onTrigger: () => void;
}>;

/**
 * Component to be placed at the bottom of a scrollable container to enable infinite scrolling.
 * When the user scrolls to the bottom of the container, the onTrigger function will be called.
 */
export const InfiniteScroll = ({ onTrigger }: InfiniteScrollProps) => {
  const onChange = useCallback(
    (inView: boolean) => {
      if (inView) {
        onTrigger();
      }
    },
    [onTrigger],
  );

  return (
    <InView
      as="div"
      threshold={0.5}
      onChange={onChange}
      style={{ minHeight: 1 }}
    ></InView>
  );
};
