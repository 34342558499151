import { Role } from '@mirage/service-dbx-api/service/grpc/context_engine_apiv2/doc_summarization';
import i18n from '@mirage/translations';
import { useMemo } from 'react';

import type { QnaAnswer } from '@mirage/service-dbx-api/service/grpc/context_engine_apiv2/types';

type QuestionsProps = {
  responses: QnaAnswer[];
};

export type CannedPrompt = {
  id: string;
  queryString: string;
  role: Role;
};

const cannedPrompts = [
  {
    id: '1',
    queryString: i18n.t('doc_summary_qna_what_are_the_key_takeaways'),
    role: Role.System,
  },
  {
    id: '2',
    queryString: i18n.t('doc_summary_qna_can_you_find_any_next_steps'),
    role: Role.System,
  },
] as CannedPrompt[];

export const useQuestions = ({ responses }: QuestionsProps) => {
  const suggestions = useMemo(() => {
    if (responses?.length > 0) {
      return cannedPrompts.filter(
        (p) =>
          !responses.some(
            (r) => r.queryString && r.queryString === p.queryString,
          ),
      );
    }
    return cannedPrompts;
  }, [responses]);

  return suggestions;
};
