import { UIIcon } from '@dropbox/dig-icons';
import { RocketLine } from '@dropbox/dig-icons/assets';
import { CSSProperties } from 'react';
import styles from './DesktopApplicationIcon.module.css';
import { StylableFavicon } from './StylableFavicon';

import type { SearchResult } from '../search/search-result';

type Props = {
  result: SearchResult;
  size?: number;
};

export default function DesktopApplicationIcon({ result, size = 36 }: Props) {
  return (
    <div
      className={styles.iconContainer}
      style={{ '--size': `${size}px` } as CSSProperties}
    >
      <StylableFavicon
        src={
          result.displayIconOverride?.icon?.['.tag'] === 'url'
            ? result.displayIconOverride?.icon?.url
            : ''
        }
        alt={result.connectorInfo.displayName}
        classNames={[styles.applicationFavicon]}
        fallback={<UIIcon src={RocketLine} />}
      />
    </div>
  );
}
