// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3026
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashGettingStartedChecklistItem } from '../enums/dash_getting_started_checklist_item';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'finish.dash_getting_started_checklist_item'
// Description: Event fired when a user finishes performing the task to mark a getting started checklist item complete
// Owner: otc
export type Finish_DashGettingStartedChecklistItem = PAPEventGeneric<
  'dash',
  'finish',
  'dash_getting_started_checklist_item',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Type of Getting Started Checklist that is being acted upon
    dashGettingStartedChecklistItem?: DashGettingStartedChecklistItem;
    // Feature Line for dash
    featureLine?: FeatureLine;
  }
>;

export function PAP_Finish_DashGettingStartedChecklistItem(
  properties?: Finish_DashGettingStartedChecklistItem['properties'],
): Finish_DashGettingStartedChecklistItem {
  return <Finish_DashGettingStartedChecklistItem>{
    class: 'dash',
    action: 'finish',
    object: 'dash_getting_started_checklist_item',
    properties,
  };
}
