// TODO (Matt): make sure this package makes sense and subdivide as necessary

import Error from 'es6-error';

export class ServiceNotFoundError extends Error {
  constructor(service: string) {
    super(`Unable to locate "${service}" service`);
  }
}

export class GraphStateError extends Error {}

export class DuplicateService extends Error {
  constructor(service: string) {
    super(`"${service}" has already been exposed in this context`);
  }
}

export class ServiceRemovalError extends Error {
  constructor(service: string) {
    super(`Attempting to remove unknown service "${service}"`);
  }
}

export class NoConnectionResponseError extends Error {
  constructor(service: string) {
    super(`Did not receive a connection response from "${service}"`);
  }
}
