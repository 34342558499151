import { Checkbox } from '@dropbox/dig-components/controls';
import { Text } from '@dropbox/dig-components/typography';
import styled from '@emotion/styled';
import * as React from 'react';
import BaseListItem from './BaseListItem';

export type CheckboxProps = Readonly<{
  /** left hand side icon */
  icon?: React.ReactNode;
  /** accepts and returns boolean or 0 and 1 */
  value?: boolean | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  /** default title */
  title: React.ReactNode;
  /** optional aria label */
  ariaLabel?: string;
  disabled?: boolean;
  tooltipTitle?: string;
}>;

export default function CheckboxListItem(props: CheckboxProps) {
  const {
    icon,
    onClick,
    value,
    title = '',
    ariaLabel,
    disabled,
    tooltipTitle,
  } = props;
  const checked = !!value;
  const onChange = React.useCallback(() => {
    if (typeof value === 'boolean') {
      onClick(!value);
    } else {
      onClick(value ? 0 : 1);
    }
  }, [value]);

  return (
    <BaseListItem
      icon={icon}
      onClick={onChange}
      title={<Text htmlFor={`checkbox-${title}`}>{title}</Text>}
      role="checkbox"
      ariaLabel={ariaLabel}
      ariaChecked={checked}
      disabled={disabled}
      tooltipTitle={tooltipTitle}
    >
      <CheckboxContainer>
        <Checkbox
          id={`checkbox-${title}`}
          checked={checked}
          readOnly={true}
          disabled={disabled}
        />
      </CheckboxContainer>
    </BaseListItem>
  );
}

const CheckboxContainer = styled.div`
  width: 24px;
  height: 24px;
  margin: 6px 12px 2px 4px;
  .dig-Checkbox-input {
    cursor: default;
  }
`;
