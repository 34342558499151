export const ANSWERS_SESSION_END_REASONS = {
  TIMEOUT: 'timeout',
  EXITED: 'exited',
  EXIT_PAGE: 'exit_page',
  LOST_FOCUS: 'lost_focus',
  CLEAR_CHAT: 'clear_chat',
} as const;

export type AnswersSessionEndReason =
  (typeof ANSWERS_SESSION_END_REASONS)[keyof typeof ANSWERS_SESSION_END_REASONS];

export const ANSWER_DWELL_END_REASONS = {
  TIMEOUT: 'timeout',
  EXITED: 'exited',
  NEW_QUESTION: 'new_question',
  EXIT_PAGE: 'exit_page',
  LOST_FOCUS: 'lost_focus',
  SCROLLED: 'scrolled',
} as const;
export type AnswerDwellEndReason =
  (typeof ANSWER_DWELL_END_REASONS)[keyof typeof ANSWER_DWELL_END_REASONS];

export const DASH_SESSION_END_REASONS = {
  TIMEOUT: 'timeout',
  EXIT_PAGE: 'exit_page',
} as const;
export type DashSessionEndReason =
  (typeof DASH_SESSION_END_REASONS)[keyof typeof DASH_SESSION_END_REASONS];

export const SEARCH_SESSION_END_REASONS = {
  TIMEOUT: 'timeout',
  PARENT_SESSION_ENDED: 'parent_session_ended',
  EXIT_PAGE: 'exit_page',
} as const;
export type SearchSessionEndReason =
  (typeof SEARCH_SESSION_END_REASONS)[keyof typeof SEARCH_SESSION_END_REASONS];

export const SEARCH_ATTEMPT_END_REASONS = {
  ESC_PRESSED: 'esc_pressed',
  CMD_L_PRESSED: 'cmd_l_pressed',
  QUERY_MANUALLY_CLEARED: 'query_manually_cleared',
  MISC: 'misc',
  PARENT_SESSION_ENDED: 'parent_session_ended',
  EXIT_SEARCH: 'exit_search',
  EXIT_PAGE: 'exit_page',
} as const;

export type SearchAttemptEndReason =
  (typeof SEARCH_ATTEMPT_END_REASONS)[keyof typeof SEARCH_ATTEMPT_END_REASONS];

export const DASH_APP_DWELL_END_REASONS = {
  LOST_FOCUS: 'lost_focus',
  EXIT_PAGE: 'exit_page',
} as const;
export type DashAppSessionEndReason =
  (typeof DASH_APP_DWELL_END_REASONS)[keyof typeof DASH_APP_DWELL_END_REASONS];
