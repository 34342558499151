import { useTheme } from '@dropbox/dig-foundations';
import i18n from '@mirage/translations';
import StacksEmptyState from './StacksEmptyState';

const BACKGROUND_IMAGE =
  'https://assets.dropbox.com/images/dashweb/emptystates/shared_stacks_empty_state.png';
const DARK_BACKGROUND_IMAGE =
  'https://assets.dropbox.com/images/dashweb/emptystates/shared_stacks_empty_state_dark.png';

export default function SharedStacksEmptyState({
  className,
}: {
  className?: string;
}) {
  const { mode } = useTheme();
  const isDarkMode = mode === 'dark';

  return (
    <StacksEmptyState
      className={className}
      title={i18n.t('shared_stacks_empty_state_title')}
      message={i18n.t('shared_stacks_empty_state_message')}
      imgSrc={isDarkMode ? DARK_BACKGROUND_IMAGE : BACKGROUND_IMAGE}
    />
  );
}
