import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { Service } from '@mirage/service-login-sync-v2/service';
import * as rx from 'rxjs';

import type { LoginSyncMessage } from './service/types';

const service = services.get<Service>(ServiceId.LOGIN_SYNC_V2);

export function sendLoginStateToService(message: LoginSyncMessage) {
  return rx.firstValueFrom(service.sendLoginStateToService(message));
}

export function sendLoginStateToSubscribers(
  message: LoginSyncMessage,
): Promise<LoginSyncMessage | undefined> {
  return rx.firstValueFrom(service.sendLoginStateToSubscribers(message));
}

export function listenForServiceLoginState(): rx.Observable<LoginSyncMessage> {
  return service.listenForServiceLoginState();
}
