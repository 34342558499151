// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgHubspot({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.28 15.177c.233.097.481.144.731.137l.002-.001c1.005-.04 1.802-.927 1.801-2.008 0-1.081-.797-1.968-1.803-2.006-.25-.007-.498.04-.73.137a1.858 1.858 0 00-.624.431 2.02 2.02 0 00-.418.658 2.14 2.14 0 000 1.562c.097.247.24.471.418.658.18.187.391.334.624.432z"
        fill="#FF7A59"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.667 2C2.747 2 2 2.746 2 3.667v16.666C2 21.253 2.746 22 3.667 22h16.666c.92 0 1.667-.746 1.667-1.667V3.667C22 2.747 21.254 2 20.333 2H3.667zM15.57 7.653v1.786c1.506.246 2.71 1.472 3.02 3.075.309 1.602-.343 3.243-1.633 4.111-1.291.87-2.949.783-4.156-.215l-1.35 1.452c.035.118.054.242.055.366 0 .703-.53 1.272-1.184 1.272-.654 0-1.184-.57-1.184-1.272 0-.703.53-1.273 1.183-1.273a1.1 1.1 0 01.341.06l1.366-1.468a4.15 4.15 0 01-.056-4.401l-4.49-3.754c-.215.135-.46.206-.71.208-.285 0-.563-.092-.8-.263a1.532 1.532 0 01-.53-.696 1.657 1.657 0 01-.082-.896c.057-.3.194-.576.396-.793.202-.216.46-.363.74-.423.28-.059.57-.028.833.09s.488.317.646.572c.158.255.242.555.242.862-.002.13-.02.26-.052.386l4.56 3.815a3.507 3.507 0 011.723-.815V7.653a1.313 1.313 0 01-.537-.505 1.45 1.45 0 01-.201-.735v-.041c0-.76.573-1.376 1.28-1.376h.038c.707 0 1.28.616 1.28 1.376v.04a1.45 1.45 0 01-.202.736c-.13.22-.316.394-.536.505z"
        fill="#FF7A59"
      />
    </svg>
  );
}

export default SvgHubspot;
