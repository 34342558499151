// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2832
import type { PAPEventGeneric } from '../base/event';
import type { FeatureLine } from '../enums/feature_line';

// Event_name: 'initiate.update_stack_emoji'
// Description: Fires when a user clicks on the stack icon to update the emoji
// Owner: dash-web
export type Initiate_UpdateStackEmoji = PAPEventGeneric<
  'stacks',
  'initiate',
  'update_stack_emoji',
  {
    // The stack id that represents the stack
    stackId?: string;
    // marks if the content is shared content
    isShared?: boolean;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // Feature Line for dash
    featureLine?: FeatureLine;
  }
>;

export function PAP_Initiate_UpdateStackEmoji(
  properties?: Initiate_UpdateStackEmoji['properties'],
): Initiate_UpdateStackEmoji {
  return <Initiate_UpdateStackEmoji>{
    class: 'stacks',
    action: 'initiate',
    object: 'update_stack_emoji',
    properties,
  };
}
