import * as mathCalculations from '@mirage/service-typeahead-search/service/sources/math-calculations';
import * as recentQueries from '@mirage/service-typeahead-search/service/sources/recent-queries';
import * as recommendations from '@mirage/service-typeahead-search/service/sources/recommendations';
import * as searchFilters from '@mirage/service-typeahead-search/service/sources/search-filters';
import * as stackItems from '@mirage/service-typeahead-search/service/sources/stack-items';
import * as stacks from '@mirage/service-typeahead-search/service/sources/stacks';
import * as suggestedQueries from '@mirage/service-typeahead-search/service/sources/suggested-queries';
import * as urlShortcuts from '@mirage/service-typeahead-search/service/sources/url-shortcuts';
import { SourceId } from '@mirage/service-typeahead-search/service/types';
import {
  extractTrailingSearchFilter,
  hasFilterBindingKeyword,
  SearchFilterType,
  withDelimiter,
} from '@mirage/shared/search/search-filters';

import type { typeahead } from '@mirage/service-typeahead-search/service/types';

export function relevant(
  query: string,
  sources: typeahead.Source[],
  config: typeahead.Config,
  cacheAnalysis: typeahead.CacheAnalysis,
) {
  return sources.filter((source) =>
    source.predicate(query, config, cacheAnalysis),
  );
}

// NOTE: order the sources by execution priority
export const TYPEAHEAD_SEARCH_SOURCES: typeahead.Source[] = [
  /**
   * Sources that affect "null state", i.e. empty query
   */

  {
    id: SourceId.RecentQueries,
    search: recentQueries.search,
    predicate: and(
      or(
        and(exactQueryLength(0), nullStateRecommendationsDisabled()),
        // Null state: If user has recent queries, show those
        and(
          exactQueryLength(0),
          nullStateRecommendationsEnabled(),
          hasRecentQueries(),
        ),
        and(minimumQueryLength(1)),
      ),
      isNotURLShortcut(),
      doesNotContainFilterBindingPrefix(),
    ),
  },
  {
    id: SourceId.Recommendations,
    search: recommendations.search,
    predicate: and(
      or(
        // Null state: If user doesn't have recent queries, show recommendations
        and(
          exactQueryLength(0),
          nullStateRecommendationsEnabled(),
          doesntHaveRecentQueries(),
        ),
        and(minimumQueryLength(1)),
      ),
      isNotURLShortcut(),
      doesNotContainFilterBindingPrefix(),
    ),
  },

  /**
   * Sources that don't affect "null state", i.e. empty query
   */

  {
    id: SourceId.SuggestedQueries,
    search: suggestedQueries.search,
    predicate: and(minimumQueryLength(1), isNotURLShortcut()),
  },
  {
    id: SourceId.URLShortcuts,
    search: urlShortcuts.search,
    // do not restrict shortcuts to only dropboxers as there are external shortcuts now!
    // the filtering of dropboxer-only shortcuts is done in the typeahead search function
    predicate: and(
      minimumQueryLength(1),
      isURLShortcut(),
      doesNotContainFilterBindingPrefix(),
    ),
  },
  {
    id: SourceId.Stacks,
    search: stacks.search,
    predicate: and(
      minimumQueryLength(1),
      isNotURLShortcut(),
      doesNotContainFilterBindingPrefix(),
    ),
  },
  {
    id: SourceId.StackItems,
    search: stackItems.search,
    predicate: and(
      minimumQueryLength(1),
      isNotURLShortcut(),
      doesNotContainFilterBindingPrefix(),
    ),
  },
  {
    id: SourceId.MathCalculations,
    search: mathCalculations.search,
    predicate: and(
      minimumQueryLength(1),
      isNotURLShortcut(),
      doesNotContainFilterBindingPrefix(),
    ),
  },
  {
    id: SourceId.SearchFilter,
    search: searchFilters.search,
    predicate: and(
      minimumQueryLength(1),
      isNotURLShortcut(),
      isTypeaheadFiltersEnabled(),
      containsActiveFilterBindingPrefix(),
      doesNotIntroduceMultiContentType(),
    ),
  },
];

/**
 * Predicate Combiners
 */

export function and(
  ...predicates: Array<typeahead.PredicateFunction>
): typeahead.PredicateFunction {
  return function predicate(...args) {
    return predicates.every((predicate) => predicate(...args));
  };
}

export function or(
  ...predicates: Array<typeahead.PredicateFunction>
): typeahead.PredicateFunction {
  return function predicate(...args) {
    return predicates.some((predicate) => predicate(...args));
  };
}

/**
 * Predicates
 */

export function minimumQueryLength(
  length: number,
): typeahead.PredicateFunction {
  return function predicate(query: string) {
    return query.length >= length;
  };
}

export function exactQueryLength(length: number): typeahead.PredicateFunction {
  return function predicate(query: string) {
    return query.length === length;
  };
}

export function isDropboxer() {
  return function predicate(_query: string, config: typeahead.Config) {
    return config.isDropboxer;
  };
}

function isURLShortcut() {
  return function predicate(query: string) {
    return query.startsWith('/');
  };
}

export function isNotURLShortcut() {
  return function predicate(query: string) {
    return !query.startsWith('/');
  };
}

function nullStateRecommendationsEnabled() {
  return function predicate(_query: string, config: typeahead.Config) {
    return config.isNullStateUsesRecommendations;
  };
}

function nullStateRecommendationsDisabled() {
  return function predicate(_query: string, config: typeahead.Config) {
    return !config.isNullStateUsesRecommendations;
  };
}

function isTypeaheadFiltersEnabled() {
  return function predicate(_query: string, config: typeahead.Config) {
    return config.isTypeaheadFiltersEnabled;
  };
}

export function containsActiveFilterBindingPrefix() {
  return function predicate(query: string) {
    return (
      hasFilterBindingKeyword(query) &&
      !!extractTrailingSearchFilter(query).filterType
    );
  };
}

export function doesNotContainFilterBindingPrefix() {
  return function predicate(query: string) {
    return !hasFilterBindingKeyword(query);
  };
}

export function hasRecentQueries() {
  return function predicate(
    _query: string,
    _config: typeahead.Config,
    cacheAnalysis: typeahead.CacheAnalysis,
  ) {
    return cacheAnalysis.hasRecentQueries;
  };
}

export function doesntHaveRecentQueries() {
  return function predicate(
    _query: string,
    _config: typeahead.Config,
    cacheAnalysis: typeahead.CacheAnalysis,
  ) {
    return !cacheAnalysis.hasRecentQueries;
  };
}

export function doesNotIntroduceMultiContentType() {
  return function predicate(query: string) {
    return !(
      query.split(' ').filter((part) => part.includes(withDelimiter('type')))
        .length > 1 &&
      extractTrailingSearchFilter(query).filterType ===
        SearchFilterType.ContentType
    );
  };
}
