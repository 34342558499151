import { useLayoutEffect, useRef, useState } from 'react';
import styles from './SmoothShow.module.css';

const INITIAL_VALUE = {};

export const SmoothShow: React.FC<{ redoOnChange?: unknown }> = ({
  redoOnChange,
  children,
}) => {
  const lastValue = useRef<unknown>(INITIAL_VALUE);
  const [redo, setRedo] = useState(false);

  useLayoutEffect(() => {
    if (lastValue.current !== redoOnChange) {
      lastValue.current = redoOnChange;
      setRedo(false);
    }
  }, [redoOnChange]);

  useLayoutEffect(() => {
    if (!redo) {
      setRedo(true);
    }
  }, [redo]);

  return redo ? <div className={styles.fadeIn}>{children}</div> : null;
};

// Just add this classname to any element to do fade in.
export const SmoothShowClassName = styles.fadeIn;
