import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { JobsToBeDoneSurvey } from "@mirage/growth/onboarding/flows/JobsToBeDoneSurvey";
import OneClickOnboarding from "@mirage/growth/onboarding/flows/OneClickOnboarding";
import { SingleStepOnboarding } from "@mirage/growth/onboarding/flows/SingleStepOnboarding";
import { TeamOnboarding } from "@mirage/growth/onboarding/flows/TeamOnboarding";
import { ONBOARDING_VERSION } from "@mirage/growth/onboarding/flows/constants";
import { RoutePath } from "@mirage/webapp/routeTypes";

const isSupportedOnboardingVersion = (
  version: string,
): version is ONBOARDING_VERSION => {
  return Object.values(ONBOARDING_VERSION).includes(
    version as ONBOARDING_VERSION,
  );
};

interface OnboardingProps {
  version: ONBOARDING_VERSION;
  // `params` from useRoute() hook. This is used by onboarding flow that has its own sub-routes setup
  routeParams?: Record<string, string | undefined>;
}

export const Onboarding = ({ version, routeParams }: OnboardingProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSupportedOnboardingVersion(version)) {
      // Redirect to home page if onboarding version is not supported
      // TODO: add logging/monitoring for this case
      navigate(RoutePath.ROOT);
    }
  }, [version, navigate]);

  // Iterate through the list of onboarding experiments and load appropriate version here
  switch (version) {
    case ONBOARDING_VERSION.SINGLE_STEP:
    case ONBOARDING_VERSION.SINGLE_STEP_MOBILE:
    case ONBOARDING_VERSION.SINGLE_STEP_B:
    case ONBOARDING_VERSION.SINGLE_STEP_C:
    case ONBOARDING_VERSION.ECOSYSTEM_CONNECTORS:
      return <SingleStepOnboarding version={version} />;
    case ONBOARDING_VERSION.ONE_CLICK:
      return <OneClickOnboarding />;
    case ONBOARDING_VERSION.SURVEY_JOBS_TO_BE_DONE:
      return <JobsToBeDoneSurvey routeParams={routeParams} />;
    case ONBOARDING_VERSION.TEAM:
      return <TeamOnboarding />;
    default:
      // useEffect should redirect this case to home page
      return <></>;
  }
};
