import { Button } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { FeatureBannerType } from '@mirage/analytics/events/enums/feature_banner_type';
import { PAP_Dismiss_DashFeatureBanner } from '@mirage/analytics/events/types/dismiss_dash_feature_banner';
import { PAP_Shown_DashFeatureBanner } from '@mirage/analytics/events/types/shown_dash_feature_banner';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';
import styles from './FeatureBanner.module.css';

interface Props {
  title: string;
  description: string;
  featureBannerType: FeatureBannerType;
  onDismiss: () => void;
  primaryAsset: React.ReactNode;
  mobileAsset?: React.ReactNode;
  actions?: React.ReactNode;
  className?: string;
}

export const FeatureBanner: React.FC<Props> = ({
  title,
  description,
  featureBannerType,
  primaryAsset,
  mobileAsset,
  onDismiss,
  actions,
  className,
}) => {
  const isMobile = useIsMobileSize();
  const asset = mobileAsset && isMobile ? mobileAsset : primaryAsset;
  const exposureLoggedRef = useRef(false);
  const { reportPapEvent } = useMirageAnalyticsContext();

  useEffect(() => {
    if (exposureLoggedRef.current) return;
    exposureLoggedRef.current = true;
    reportPapEvent(
      PAP_Shown_DashFeatureBanner({
        featureLine: 'onboarding',
        featureBannerType,
      }),
    );
  }, [featureBannerType, reportPapEvent]);

  const handleDismissed = useCallback(() => {
    reportPapEvent(
      PAP_Dismiss_DashFeatureBanner({
        featureLine: 'onboarding',
        featureBannerType,
      }),
    );
    onDismiss();
  }, [featureBannerType, onDismiss, reportPapEvent]);

  return (
    <Box className={classnames(styles.wrapper, className)}>
      <Box className={styles.primaryImage}>{asset}</Box>
      <Box className={styles.content} display="flex" flexDirection="column">
        <Title size="small" weightVariant="emphasized" className={styles.title}>
          {title}
        </Title>
        <Text size="medium" tagName="p" className={styles.description}>
          {description}
        </Text>
        <Box className={styles.actionsContainer}>
          {actions}
          <Button
            variant="borderless"
            className={styles.dismissButton}
            onClick={handleDismissed}
          >
            {i18n.t('dismiss')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
