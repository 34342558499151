import { addDisplayStat } from '.';

export function logPageLoadMilestone(name: string, performanceMs?: number) {
  const now = performanceMs || performance.now();

  addDisplayStat('page-load-milestone: ' + name, now);
}

const loggedMilestoneNames = new Set<string>();

/**
 * Log the given name once only even if called multiple times.
 *
 * Why aren't we using useEffect? --> useEffect logs a later time by a few ms
 * or more, so just use a global var here, which will log a much more accurate
 * number.
 */
export function logPageLoadMilestoneOnce(name: string) {
  if (!loggedMilestoneNames.has(name)) {
    loggedMilestoneNames.add(name);
    logPageLoadMilestone(name);
  }
}
