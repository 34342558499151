import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text, Title } from '@dropbox/dig-components/typography';
import { SpotIcon, UIIcon } from '@dropbox/dig-icons';
import { CopyLine, EditSpot } from '@dropbox/dig-icons/dist/mjs/assets';
import { copyToClipboard, openURL } from '@mirage/service-platform-actions';
import { getRecentQueries } from '@mirage/service-typeahead-search';
import { useEffect, useState } from 'react';
import styles from './AnnotationIntroModal.module.css';

const WORKSHOP_URL =
  'https://www.dropbox.com/scl/fi/2w2u1vuq9ggiiqqxrup5g/Search-Annotations-Workshop-Instructions-May-2024.paper?rlkey=slqlpcovg4lvbc4czt4vb9sal&dl=0';

const RECENT_QUERY_COPY_LIMIT = 20;

type AnnotationIntroModalProps = {
  open: boolean;
  onClose: () => void;
};

export const AnnotationIntroModal = ({
  open,
  onClose,
}: AnnotationIntroModalProps) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setCopied(false);
  }, [open]);

  const handleCopyQueries = async () => {
    const recentQueries = await getRecentQueries();
    const queriesToCopy = recentQueries
      .slice(0, RECENT_QUERY_COPY_LIMIT)
      .map((q) => q.query)
      .join('\n');

    await copyToClipboard(queriesToCopy);
    setCopied(true);
  };
  return (
    <Modal
      open={open}
      isCentered
      withCloseButton="close"
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
    >
      <Modal.Header className={styles.header}>
        <SpotIcon src={EditSpot} />
      </Modal.Header>
      <Modal.Body>
        <Title>Annotate search results</Title>
        <Text tagName="p">
          Thank you for taking the time to annotate your results. This will help
          improve the results you see in the future. Please ensure your
          annotations are high quality and are well representative of your
          expected behavior from search.
        </Text>
        <Button
          variant="transparent"
          href="#"
          onClick={() => openURL(WORKSHOP_URL)}
        >
          Learn more
        </Button>
      </Modal.Body>
      <Modal.Footer>
        {copied && (
          <Text size="small" style={{ color: 'green' }}>
            Copied to clipboard!
          </Text>
        )}
        <Button
          variant="outline"
          withIconStart={<UIIcon src={CopyLine} />}
          onClick={handleCopyQueries}
        >
          Copy previous queries
        </Button>
        <Button variant="primary" onClick={onClose}>
          Get started
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
