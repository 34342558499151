import { growth } from '@dropbox/api-v2-client';
import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';

import type { APIv2Callable } from '@mirage/service-dbx-api/service';
import type { ConsolaInstance } from 'consola';

export type OnboardingChecklistItem = {
  moduleId: string;
  milestoneKeys: string[];
  isComplete: boolean;
  completedAtMs?: number;
};

export type GrowthContinuousOnboardingType =
  | 'is_dash_checklist'
  | 'left_nav_checklist'
  | 'device_connectivity'
  | 'other';

export type Service = {
  getOnboardingChecklist: (
    surface: GrowthContinuousOnboardingType,
  ) => Promise<OnboardingChecklistItem[] | undefined>;
  markChecklistItemComplete: (milestoneIds: string[]) => Promise<boolean>;
};

interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

interface LoggingServiceContract {
  tagged: (tag: string) => ConsolaInstance;
}

export default function service(
  { callApiV2 }: DbxApiServiceContract,
  { tagged }: LoggingServiceContract,
) {
  const logger = tagged('service-onboarding-checklist');

  async function getOnboardingChecklist(
    surface: GrowthContinuousOnboardingType,
  ): Promise<OnboardingChecklistItem[] | undefined> {
    try {
      const continuousOnboarding = await callApiV2(
        'growthGetContinuousOnboarding',
        {
          surface: surface as unknown as growth.ContinuousOnboardingSurface,
          show_modules_completed_before_seen: true,
        },
      );
      const checklistItems: OnboardingChecklistItem[] = [];

      if (continuousOnboarding?.progression) {
        const { module_ids, module_map } = continuousOnboarding.progression;

        module_ids.forEach((moduleId) => {
          const module = module_map[moduleId];
          if (module) {
            const completedAtMs = module.completed_module_goals_time
              ? module.completed_module_goals_time * 1000
              : undefined;
            const checklistItem: OnboardingChecklistItem = {
              moduleId: module.module_id,
              milestoneKeys: module.milestone_keys,
              isComplete: module.completed_module_goals,
              completedAtMs,
            };
            checklistItems.push(checklistItem);
          }
        });
        return checklistItems;
      }
    } catch (error) {
      logger.error(
        `getOnboardingChecklist - Failed to fetch user onboarding progress`,
      );
    }
    return undefined;
  }

  async function markChecklistItemComplete(
    milestoneIds: string[],
  ): Promise<boolean> {
    try {
      await callApiV2('growthMarkOnboardingModulesAsCompleted', {
        modules: milestoneIds,
      });
      return true;
    } catch {
      logger.error(
        `markChecklistItemComplete - Failed to fetch update onboarding progress`,
      );
    }
    return false;
  }

  services.provide<Service>(
    ServiceId.ONBOARDING_CHECKLIST,
    {
      getOnboardingChecklist,
      markChecklistItemComplete,
    },
    [ServiceId.DBX_API],
  );
}
