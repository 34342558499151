import { Text } from '@dropbox/dig-components/typography';
import styled from '@emotion/styled';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';

type Props = {
  title?: React.ReactNode;
  action?: React.ReactNode;
  children: Array<React.ReactNode>;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

/**
 * TODO
 *  - Implement keyboard shortcuts for navigating lists
 *  - Group certain results into different groups
 */
export default function ResultList({
  title,
  action,
  children = [],
  onKeyDown,
}: Props) {
  const isMobileSize = useIsMobileSize();
  return (
    <Container onKeyDown={onKeyDown} isMobileSize={isMobileSize}>
      {children.length > 0 && (
        <div
          aria-live="polite"
          role="status"
          aria-label={i18n.t('aria_search_result_rendered')}
        />
      )}
      {(title || action) && (
        <ListHeader>
          {title && (
            <ListTitle color="faint" size="small" isBold={true}>
              {title}
            </ListTitle>
          )}
          {action && <ListAction>{action}</ListAction>}
        </ListHeader>
      )}
      <ResultsContainer>{children}</ResultsContainer>
    </Container>
  );
}

const Container = styled.div<{ isMobileSize: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;

  ${({ isMobileSize }) =>
    isMobileSize && `background-color: var(--dig-color__background__subtle)`}
`;

const ListHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 36px;
`;

const ListTitle = styled(Text)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const ListAction = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ResultsContainer = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
`;
