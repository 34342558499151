import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type { EnvironmentContext } from './service/types';
import type { Service } from '@mirage/service-environment-context/service';

const service = services.get<Service>(ServiceId.ENVIRONMENT_CONTEXT);

/** Don't call this function. To be used within this service only. */
export function internalGetAll(): Promise<EnvironmentContext> {
  return rx.firstValueFrom(service.internalGetAll());
}
