import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { addDisplayStat } from '@mirage/service-operational-metrics';
import { useEffect, useRef } from 'react';
import { getClientPath, logPerfMetric } from './vortexMetrics';

/**
 * Time To DOM Complete (TTDC)
 * Reports the TTDC only once on page load, but not on SPA navigates.
 */
export function useTtdcReporter(path: string) {
  const pathRef = useRef(path);

  useEffect(() => {
    function onTTDC() {
      const entries = performance.getEntriesByType('navigation');

      // There will only be at most one entry of type "navigation".
      entries.forEach((entry) => {
        const e = entry as PerformanceNavigationTiming;

        logPerfMetric({
          '.tag': 'generic_perf_histogram',
          metric_name: 'TTDC',
          surface: EnvCtx.surface,
          nav_type: e.type, // "reload" or "navigate"
          path: getClientPath(pathRef.current),
          value: e.domComplete,
        });

        addDisplayStat('TTDC', e.domComplete);
      });
    }

    // Ok to report now, so just report and return.
    if (document.readyState === 'complete') {
      onTTDC();
      return;
    }

    // Not DOM complete yet, so add a listener.
    window.addEventListener('load', onTTDC);

    return () => {
      window.removeEventListener('load', onTTDC);
    };
  }, []);
}
