import { useIsMobileSizeForSidebar } from './mobile';

/**
 * Note that using `'ontouchstart' in window` is risky because some users
 * might have both a touchscreen and a mouse, in which case having hover
 * UI is still ok.
 */
const isTouchScreenWithNoMouse = window.matchMedia('(pointer: coarse)').matches;

/** Check if we can use UI that appears on mouse hover or not. */
export function useIsHoverFriendly() {
  // Use the sidebar width only.
  const isMobileSize = useIsMobileSizeForSidebar();

  // If user has no mouse, then it is not hover friendly.
  if (isTouchScreenWithNoMouse) {
    return false;
  }

  // If screen is small, assume that it is not hover-friendly in case the
  // above check is unsupported by the browser.
  return !isMobileSize;
}
