import { UIIcon } from '@dropbox/dig-icons';
import { HideLine, ShowLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Select_StartPageAction } from '@mirage/analytics/events/types/select_start_page_action';
import { privacyModeEnabledAtom } from '@mirage/shared/atoms/privacyMode';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import i18n from '@mirage/translations';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import styles from './PrivacyToggle.module.css';

export const PrivacyToggle = () => {
  const [privacyModeEnabled, setPrivacyModeEnabled] = useAtom(
    privacyModeEnabledAtom,
  );
  const { reportPapEvent } = useMirageAnalyticsContext();

  const togglePrivacyMode = useCallback(() => {
    setPrivacyModeEnabled(!privacyModeEnabled);

    reportPapEvent(
      PAP_Select_StartPageAction({
        startPageAction: privacyModeEnabled
          ? 'disable_privacy_mode'
          : 'enable_privacy_mode',
        featureLine: 'privacy_mode',
        actionSurfaceComponent: 'privacy_mode',
      }),
    );
  }, [setPrivacyModeEnabled, privacyModeEnabled, reportPapEvent]);

  return (
    <div className={styles.privacyToggleWrapper}>
      <IconButtonWithTooltip
        variant="filled"
        shape="circular"
        size="large"
        onClick={togglePrivacyMode}
        tooltipProps={{
          title: privacyModeEnabled
            ? i18n.t('disable_privacy_mode')
            : i18n.t('enable_privacy_mode'),
          placement: 'left-start',
        }}
        className={styles.privacyToggle}
      >
        <UIIcon src={privacyModeEnabled ? ShowLine : HideLine} />
      </IconButtonWithTooltip>
    </div>
  );
};
