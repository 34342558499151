import { useEffect } from "react";

import { useIsDropboxer } from "@mirage/service-auth/useDropboxAccount";
import { EnvCtx } from "@mirage/service-environment-context/global-env-ctx";
import { useFeatureFlagValue } from "@mirage/service-experimentation/useFeatureFlagValue";
import { additionalDeveloperSettingsAtom } from "@mirage/settings/atoms/additionalDeveloperSettings";
import {
  isCanaryBuildChannel,
  isDevBuildChannel,
} from "@mirage/shared/util/tiny-utils";
import { useSetAtom } from "jotai";

import { EnvironmentSwitcher } from "../components/EnvironmentSwitcher";
import {
  getWebEnvCookie,
  setWebEnvCookie,
  checkAuthCookieAndRedirect,
  EnvCookieValue,
} from "../shared/webCookies";

export function useRedirectToStagingIfNeeded() {
  const isDropboxer = useIsDropboxer();
  const enableRedirectDropboxersStaging = useFeatureFlagValue(
    "dash_web_2024_05_09_redirect_dropboxers_staging",
  );
  const setAdditionalDeveloperSettings = useSetAtom(
    additionalDeveloperSettingsAtom,
  );

  useEffect(() => {
    setAdditionalDeveloperSettings({ headers: [EnvironmentSwitcher] });
  }, [setAdditionalDeveloperSettings]);

  useEffect(() => {
    if (
      !isDropboxer ||
      !enableRedirectDropboxersStaging ||
      enableRedirectDropboxersStaging === "OFF"
    ) {
      return;
    }

    const isDev = isDevBuildChannel(EnvCtx.buildChannel);
    const isCanary = isCanaryBuildChannel(EnvCtx.buildChannel);

    // Do nothing if it's dev or canary
    if (isDev || isCanary) {
      return;
    }

    // Till this point, the Dropboxer either has no cookies set or cookie matches to the right env
    const envCookie = getWebEnvCookie();
    if (!envCookie) {
      setWebEnvCookie(EnvCookieValue.STAGING);
      checkAuthCookieAndRedirect();
    }
  }, [isDropboxer, enableRedirectDropboxersStaging]);
}
