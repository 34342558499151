import useConnectors from '@mirage/service-connectors/useConnectors';
import { useTopContentByConnector } from '@mirage/service-recent-content/hooks/useRecentContent';
import {
  CustomizableModule,
  DefaultCustomizableModuleId,
} from '@mirage/service-settings/service/customize';
import { CustomizableModulesInternalSettings } from '@mirage/service-settings/useCustomizableModuleSettings';
import i18n from '@mirage/translations';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { dash_connectors } from '@dropbox/api-v2-client';

const DefaultCustomizableModules: CustomizableModule[] = [
  {
    id: DefaultCustomizableModuleId.RECENT_CONTENT,
    title: i18n.t('recent_content'),
    description: i18n.t('recent_content_customize_description'),
    icon: { type: 'svg', svgName: 'FileHistoryLine' },
    canHide: false,
  },
  {
    id: DefaultCustomizableModuleId.CALENDAR,
    title: i18n.t('calendar'),
    description: i18n.t('calendar_customize_description'),
    icon: { type: 'svg', svgName: 'CalendarLine' },
    canHide: true,
  },
  {
    id: DefaultCustomizableModuleId.NEW_STACK_SUGGESTIONS,
    title: i18n.t('new_stack_suggestions'),
    description: i18n.t('new_stack_suggestions_customize_description'),
    icon: { type: 'svg', svgName: 'CreateStackLine' },
    canHide: false,
  },
];

function dedupeCustomizableModules(modules: CustomizableModule[]) {
  const seen: { [id: string]: { newIndex: number } } = {};
  const newModules = [];

  for (let i = 0; i < modules.length; i++) {
    const module = modules[i];
    const existing = seen[module.id];

    // Add new module.
    if (!existing) {
      seen[module.id] = { newIndex: newModules.length };
      newModules.push(module);
      continue;
    }

    // Merge with existing module by assuming that the later module is always
    // more updated. Make sure all fields are filled in.
    // Important: maintain same sort order as input array.
    const newIndex = existing.newIndex;

    if (!isEqual(newModules[newIndex], module)) {
      newModules[newIndex] = { ...newModules[newIndex], ...module };
    }
  }

  return newModules;
}

function createModuleFromConnector(
  connector: dash_connectors.Connector,
): CustomizableModule {
  const iconUrl = connector.branding?.icon_src;

  return {
    id: connector.id_attrs?.type ?? '', // e.g. "gmail"
    title: i18n.t('updates_from_connector', {
      connector: connector?.branding?.display_name,
    }),
    description:
      connector.branding?.long_description ||
      connector.branding?.brief_description ||
      '',
    icon: iconUrl ? { type: 'url', url: iconUrl } : undefined,
    canHide: true,
  };
}

function useConnectorModules() {
  const { connectors, connectorsById, hasFinishedInitialFetch } =
    useConnectors();

  const { isLoading: isLoadingContent, topContentByConnector } =
    useTopContentByConnector(connectors);

  const modules = useMemo(() => {
    const modules: CustomizableModule[] = [];

    topContentByConnector.forEach((topContent) => {
      modules.push(createModuleFromConnector(connectorsById[topContent.key]));
    });

    return modules;
  }, [connectorsById, topContentByConnector]);

  return { isLoading: !hasFinishedInitialFetch || isLoadingContent, modules };
}

export function useCustomizableModules(
  internalSettings: CustomizableModulesInternalSettings,
) {
  // Make the UI more snappy by maintaining a local state here.
  const [localModules, setLocalModules] = useState<CustomizableModule[]>([]);

  const {
    customizableModulesInternalSettings,
    setCustomizableModulesInternalSettings,
  } = internalSettings;

  const customizableModules =
    customizableModulesInternalSettings?.customizableModules ??
    DefaultCustomizableModules;

  const isConnectorsCached =
    customizableModulesInternalSettings?.isCustomizableConnectorsCached;

  useEffect(() => {
    setLocalModules(customizableModules);
  }, [customizableModules]);

  const updateModulesWithDedupe = useCallback(
    (modules: CustomizableModule[]) => {
      if (isEqual(modules, customizableModules)) {
        return;
      }

      const deduped = dedupeCustomizableModules(modules);

      if (isEqual(deduped, customizableModules)) {
        return;
      }

      setLocalModules(deduped);
      setCustomizableModulesInternalSettings('customizableModules', deduped);
    },
    [customizableModules, setCustomizableModulesInternalSettings],
  );

  // Load connectors data.
  const { isLoading: isLoadingConnectors, modules: connectorModules } =
    useConnectorModules();

  useEffect(() => {
    // Cleanup old connectors.
    let newCustomizableModules = customizableModules;
    let newConnectorModules = connectorModules;

    if (!isLoadingConnectors && customizableModules?.length) {
      const oldModuleIds = customizableModules.map((m) => m.id);
      const newModuleIds = new Set(connectorModules.map((m) => m.id));
      DefaultCustomizableModules.map((m) => newModuleIds.add(m.id));

      const idsToRemove = new Set<string>(
        oldModuleIds.filter((id) => !newModuleIds.has(id)),
      );

      if (idsToRemove.size) {
        newCustomizableModules = customizableModules.filter(
          (module) => !idsToRemove.has(module.id),
        );
        newConnectorModules = connectorModules.filter(
          (module) => !idsToRemove.has(module.id),
        );
      }
    }

    if (isConnectorsCached || !isLoadingConnectors) {
      updateModulesWithDedupe([
        // Maintain the current sort order.
        ...newCustomizableModules,
        // Fill in for i18n strings, but don't modify sort order.
        ...DefaultCustomizableModules,
        // Append connector modules to the bottom.
        ...newConnectorModules,
      ]);
    }

    // We know connectors data is available only when the returned list of
    // connector modules is not empty. We set it to true once and it will
    // remain true for good.
    if (!isConnectorsCached && !isLoadingConnectors) {
      setCustomizableModulesInternalSettings(
        'isCustomizableConnectorsCached',
        true,
      );
    }
  }, [
    connectorModules,
    customizableModules,
    isConnectorsCached,
    isLoadingConnectors,
    setCustomizableModulesInternalSettings,
    updateModulesWithDedupe,
  ]);

  const localModulesById = useMemo(
    () => keyBy(localModules, (m) => m.id),
    [localModules],
  );

  return {
    settingsLoaded: customizableModulesInternalSettings !== null,
    isLoadingConnectors,
    isConnectorsCached,
    modules: localModules,
    setModules: updateModulesWithDedupe,
    modulesById: localModulesById,
  };
}
