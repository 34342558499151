// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgDropbox({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 2l-5.999 3.823 6 3.822L24 5.823l-6-3.822zM18 9.645l-5.999 3.822 6 3.822L24 13.467l-6-3.822zM6 2L.002 5.824l6 3.822 6-3.822-6-3.822z"
        fill="#0061FF"
      />
      <path
        d="M.001 13.467l6 3.822 6-3.822-6-3.822-6 3.822zM6 18.564l6.001 3.822 6-3.822-6-3.823-6 3.823z"
        fill="#0061FF"
      />
    </svg>
  );
}

export default SvgDropbox;
