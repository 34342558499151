import { useIsStartPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { usePublishHomepageSurveyEvent } from '@mirage/service-feedback/hooks/usePublishHomepageSurveyEvent';
import { logPageLoadMilestoneOnce } from '@mirage/service-operational-metrics/page-load';
import { useDashTitle } from '@mirage/shared/hooks/DashTitle';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { StartPage } from '../StartPage';
import { WorkingSet } from '../WorkingSet';
import styles from './HomePage.module.css';

export const HomePage: React.FC = () => {
  logPageLoadMilestoneOnce('HomePage start');

  useDashTitle(i18n.t('home'));
  usePublishHomepageSurveyEvent();
  const isMobileSize = useIsMobileSize();
  const isAugustStartPageEnabled = useIsStartPageAugustRevisionEnabled();

  return (
    <div
      className={classnames(styles.resultPageContainer, {
        [styles.isMobile]: isMobileSize,
      })}
    >
      {isAugustStartPageEnabled ? <StartPage /> : <WorkingSet />}
    </div>
  );
};
