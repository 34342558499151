// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgFront({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.727 5.328C4.727 3.49 6.275 2 8.185 2h11.088v4.736c0 .919-.774 1.664-1.73 1.664H13.04c-.918 0-1.662.716-1.662 1.6v10.336c0 .919-.774 1.664-1.73 1.664h-4.92V5.328z"
        fill="#001B38"
      />
      <path
        d="M8.592 10.04c0 2.386 2.009 4.32 4.488 4.32 2.479 0 4.488-1.934 4.488-4.32s-2.01-4.32-4.488-4.32c-2.479 0-4.488 1.934-4.488 4.32z"
        fill="url(#front_svg__paint0_linear)"
      />
      <path
        opacity={0.5}
        d="M8.592 10.04c0 2.386 2.009 4.32 4.488 4.32 2.479 0 4.488-1.934 4.488-4.32s-2.01-4.32-4.488-4.32c-2.479 0-4.488 1.934-4.488 4.32z"
        fill="url(#front_svg__paint1_linear)"
      />
      <defs>
        <linearGradient
          id="front_svg__paint0_linear"
          x1={9.919}
          y1={6.802}
          x2={16.409}
          y2={13.591}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0057" stopOpacity={0.16} />
          <stop offset={0.861} stopColor="#FF0057" />
        </linearGradient>
        <linearGradient
          id="front_svg__paint1_linear"
          x1={9.919}
          y1={6.802}
          x2={16.409}
          y2={13.591}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0057" stopOpacity={0.16} />
          <stop offset={0.861} stopColor="#FF0057" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgFront;
