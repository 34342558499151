// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3179
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { FeatureLine } from '../enums/feature_line';

// Event_name: 'select.dash_answers_source_labels'
// Description: selection of preferred sources for a dash answer. Used as part of the internal dash answers annotation tool
// Owner: otc-search-infra
export type Select_DashAnswersSourceLabels = PAPEventGeneric<
  'dash',
  'select',
  'dash_answers_source_labels',
  {
    // unique id for a /dash/search query. Used by the ML team to get feedback on search result ranking
    searchRequestId?: string;
    // Unique identifier (UUIDv5 ) of the answer
    answerId?: string;
    // Unique identifier (UUIDv5 ) of the conversation
    conversationId?: string;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // Unique identifier (UUIDv5 ) of the search result
    resultUuid?: string;
    // The zero-index returned position of search result in a list of search results.
    resultPosition?: number;
    // score in a feedback form
    feedbackScore?: number;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // The contents of a search query
    queryString?: string;
    // The LLM-generated answer string returned from dash answers (Q&A) service.
    // This is L0 data, and will be copied to restricted_otc hive table according to this TDD: https://www.dropbox.com/scl/fi/0r38gxrobgr6yvrft4yzu/OTCUS-Machine-Learning-Restricted-Logging-via-PAP-TDD.paper?dl=0&rlkey=7yvsba510fyeufi8cjea9juqk
    answerString?: string;
    // The result most desired by the user.
    goldenResult?: string;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
  }
>;

export function PAP_Select_DashAnswersSourceLabels(
  properties?: Select_DashAnswersSourceLabels['properties'],
): Select_DashAnswersSourceLabels {
  return <Select_DashAnswersSourceLabels>{
    class: 'dash',
    action: 'select',
    object: 'dash_answers_source_labels',
    properties,
  };
}
