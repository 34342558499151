import { WrapperTriggerPropsFn } from '@dropbox/dig-components/menu';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import classnames from 'classnames';
import { forwardRef, Ref } from 'react';
import styles from './WorkingSetCardWrapper.module.css';

type WorkingSetCardWrapperProps = {
  children: React.ReactNode;
  menuIsOpen?: boolean;
  withSolidBorder?: boolean;
  onClick?: () => void;
  getTriggerProps?: WrapperTriggerPropsFn;
  accessoryButton?: React.ReactNode;
  innerClassName?: string;
  ref?: React.Ref<HTMLDivElement>;
};

const WorkingSetCardWrapper = forwardRef(
  (
    {
      children,
      menuIsOpen,
      onClick,
      getTriggerProps,
      withSolidBorder = true,
      accessoryButton,
      innerClassName,
    }: WorkingSetCardWrapperProps,
    ref?: Ref<HTMLDivElement>,
  ) => {
    const triggerProps = getTriggerProps ? getTriggerProps() : {};

    return (
      <div className={styles.cardWrapper} ref={ref}>
        <div
          className={classnames(
            styles.cardContainer,
            withSolidBorder
              ? styles.cardContainerHardBorder
              : styles.cardContainerDashedBorder,
          )}
        >
          {/* Avoid warning: validateDOMNesting(...): <button> cannot appear as a descendant of <button>. */}
          <div
            role={onClick ? 'button' : undefined}
            tabIndex={0}
            onClick={onClick}
            onKeyDown={onKeyDownCommitFn(onClick)}
            className={classnames(styles.cardButton, innerClassName)}
            {...triggerProps}
          >
            {children}
          </div>
          {accessoryButton !== undefined && (
            <>
              <div
                className={classnames(
                  styles.accessoryButtonWrapper,
                  styles.accessoryButtonWrapperAutoSize,
                )}
              >
                <div
                  className={classnames(
                    styles.accessoryButton,
                    menuIsOpen && styles.accessoryButtonOpen,
                  )}
                >
                  {accessoryButton}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  },
);
WorkingSetCardWrapper.displayName = 'WorkingSetCardWrapper';
export default WorkingSetCardWrapper;
