// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgMixpanel({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.889C2 2.398 2.398 2 2.889 2H21.11c.491 0 .889.398.889.889V21.11c0 .491-.398.889-.889.889H2.89A.889.889 0 012 21.111V2.89zm5.864 15.616a1.42 1.42 0 100-2.838 1.42 1.42 0 000 2.838zm6.127-1.403a1.004 1.004 0 11-2.007 0 1.004 1.004 0 012.007 0zm3.22.455a.456.456 0 100-.911.456.456 0 000 .911zm-.263-4.335a.705.705 0 01-.718-.713V9.474c0-1.047-.505-1.85-1.636-1.85-1.132 0-1.82.864-1.82 1.866v3.02c0 .394-.275.712-.718.712a.696.696 0 01-.719-.713V9.474c0-1.047-.55-1.85-1.682-1.85-1.162 0-1.773.864-1.773 1.866v3.02c0 .394-.29.712-.719.712a.706.706 0 01-.719-.713V7.122c0-.424.276-.728.719-.728.49 0 .719.334.719.728v.486c.412-.804 1.253-1.275 2.155-1.275 1.101 0 1.927.547 2.34 1.35.596-.91 1.467-1.35 2.553-1.35 1.85 0 2.737 1.26 2.737 3.035v3.14c0 .396-.29.714-.719.714z"
        fill="#4F44E0"
      />
    </svg>
  );
}

export default SvgMixpanel;
