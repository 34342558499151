import type { Client, Scope } from '@sentry/types';

interface SurfaceSentry {
  getClient(): Client | undefined;
  // Add any non-Client type methods here that you want to proxy
  withScope(callback: (scope: Scope) => void): void;
  setTag(key: string, value: string): void;
  setContext(key: string, value: object): void;
}

/**
 * This is a list of methods that we want to proxy from the surface Sentry instance,
 * in addition to the standard Client methods.
 */
type AdditionalSentryMethods = Omit<SurfaceSentry, 'getClient'>;

let surfaceSentry: null | SurfaceSentry = null;
export function exposeSurfaceSentryInstance<T extends SurfaceSentry>(
  surface: T,
) {
  surfaceSentry = surface;
}

const additionalMethods: (keyof AdditionalSentryMethods)[] = [
  'withScope',
  'setTag',
  'setContext',
];

/**
 * Proxy that automatically routes Client methods to the surface Sentry instance,
 * without having to call `getClient()` first. Also exposes additional methods
 * that are not part of the Client interface but are useful for interacting with
 * Sentry.
 */
const clientProxy = new Proxy(
  {},
  {
    get(_, property, receiver) {
      if (!surfaceSentry) {
        throw new Error(
          `You must provide the surface's Sentry client before interacting with the wrapper.`,
        );
      }
      if (
        additionalMethods.includes(property as keyof AdditionalSentryMethods)
      ) {
        return surfaceSentry[property as keyof AdditionalSentryMethods].bind(
          surfaceSentry,
        );
      }
      const target = surfaceSentry.getClient();
      if (!target) {
        throw new Error('Surface did not return a valid Sentry client!');
      }
      return Reflect.get(target, property, receiver);
    },
  },
) as Readonly<Client> & AdditionalSentryMethods;

export default clientProxy;
