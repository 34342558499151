import * as React from "react";
import { ThemeProvider, Box } from "@dropbox/dig-foundations";
import { IconLayout, useIconSize } from "../icon-layout";
import type { IconLayoutProps } from "../icon-layout/IconLayout";
import classNames from "classnames";
import css from "./CalendarIcon.module.css";
export type DateFormatter = (date: Date) => {
  namedDay: string;
  numericDay: string;
};

export interface CalendarIconProps
  extends Pick<IconLayoutProps, "style" | "className">,
    React.AriaAttributes {
  size?: IconLayoutProps["size"];
  /**
   * The icon to display within the CalendarIcon Badge area.
   * Should be an SVG or an img element.
   *
   */
  badge: React.ReactNode;

  /**
   * The Date object this component should use to display the date.
   */
  date: Date;
  /**
   * A formatter function that takes a Date object and returns an object with the named day and numeric day.
   *
   * @example A basic implementation of a date formatter function:
   * ```ts
   * export const basicUsDateFormatter: DateFormatter = (date: Date) => {
   * // using `new Intl.DateTimeFormat` here because according to MDN https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
   * // Every time toLocaleString is called, it has to perform a search in a big database of localization strings, which is potentially inefficient.
   * // When the method is called many times with the same arguments, it is better to create a Intl.DateTimeFormat object
   * // and use its format() method, because a DateTimeFormat object remembers the arguments passed to it and may decide to cache a
   * // slice of the database, so future format calls can search for localization strings within a more constrained context.
   *
   * const [namedDay] = new Intl.DateTimeFormat("en-US", { weekday: "short" })
   *   .format(date)
   *   .split(",");
   *
   *  const numericDay = new Intl.DateTimeFormat("en-US", {
   *    day: "numeric",
   *  }).format(date);
   *
   *  return { namedDay, numericDay };
   * };
   * ```
   */
  dateFormatterFn: DateFormatter;
  children?: never;
}

/**
 * CalendarIcon is a component that displays a calendar icon with a badge for the type of calendar it is connected to.
 */
export const CalendarIcon: React.FC<CalendarIconProps> = ({
  size: _size,
  className,
  style,
  badge,
  date,
  dateFormatterFn,
  ...rest
}) => {
  const size = useIconSize({ size: _size });
  const cls = classNames(className, css["root"], {
    [css.rootSizeSmall]: size === "small",
    [css.rootSizeMedium]: size === "medium",
    [css.rootSizeLarge]: size === "large",
    [css.rootSizeXLarge]: size === "xlarge",
  });
  const { namedDay, numericDay } = dateFormatterFn(date);
  const _NamedDay = (
    <Box as="span" color="Text Base" className={css.namedDay}>
      {namedDay}
    </Box>
  );
  const _NumericDay = (
    <Box as="span" color="Text Base" className={css.numericDay}>
      {numericDay}
    </Box>
  );
  const _Date = (
    <Box
      as="span"
      fontWeight="Strong"
      fontFamily="Title"
      className={css["date"]}
    >
      {_NamedDay}
      {_NumericDay}
    </Box>
  );
  const _Badge =
    size === "small" ? (
      void 0
    ) : (
      <ThemeProvider>
        {({ getThemeProps }) => (
          <IconLayout.Badge position="bottom-right" {...getThemeProps()}>
            {badge}
          </IconLayout.Badge>
        )}
      </ThemeProvider>
    );

  /**
   * Get only the year/month/day part of the date string since the time part is not relevant for this component.
   */
  const a11yDate = date.toISOString().slice(0, 10);

  return (
    <ThemeProvider>
      {({ getInverseProps }) => (
        <Box as="span" display="block" width="fit-content">
          {/* The `time` element is not super well supported by screen readers. 
          The only way I've found for screen readers to consistently announce a date here
          is through nesting a visually hidden `time` element within the Calendar icon and hide the rest of
          the content from the SR */}
          <time className={css.visuallyHidden}>{a11yDate}</time>
          <IconLayout
            aria-hidden="true"
            hasBorder={false}
            hasBackground={true}
            size={size}
            content={_Date}
            backgroundColor="Background Base"
            textColor="Text Base"
            withBadge={_Badge}
            shouldConstrainContent={false}
            {...getInverseProps({ className: cls, style })}
            {...rest}
          />
        </Box>
      )}
    </ThemeProvider>
  );
};
