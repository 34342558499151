import { Button } from '@dropbox/dig-components/buttons';
import { LabelGroup } from '@dropbox/dig-components/combinations';
import { List } from '@dropbox/dig-components/list';
import { Text } from '@dropbox/dig-components/typography';
import { useTheme } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CheckmarkCircleFill,
  FailFill,
  SyncingLine,
} from '@dropbox/dig-icons/assets';
import { useConnectionRecentlyEstablished } from '@mirage/settings/atoms/recentConnections';
import {
  getConnectionMetadata,
  isBrowserExtension,
} from '@mirage/shared/connectors/util';
import {
  UIConnection,
  UIConnector,
  UIWebExtension,
} from '@mirage/shared/types';
import i18n from '@mirage/translations';
import { useState } from 'react';
import styles from './ConnectedConnector.module.css';
import { DisconnectModal } from './DisconnectModal';

interface Props {
  item: UIConnection | UIWebExtension;
  connector?: UIConnector | UIWebExtension;
  onDisconnect: (connection: UIConnection) => Promise<void>;
  onReauthenticate: (
    connection: UIConnection,
    connector: UIConnector | UIWebExtension,
  ) => Promise<void>;
  hideDisconnect?: boolean;
  onboarding?: string;
}

export const ConnectedConnector: React.FC<Props> = ({
  item,
  connector,
  onboarding = '',
  onDisconnect,
  onReauthenticate,
  hideDisconnect,
}) => {
  const {
    id,
    platform,
    type,
    label,
    authenticated,
    isReauth,
    isExpired,
    teamConnector,
    connectionOwner,
  } = getConnectionMetadata(item);
  const isWebExtension = isBrowserExtension(item);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const { mode } = useTheme();
  const isDarkMode = mode === 'dark';
  const isTeamConnector =
    !isWebExtension && item.metadata?.owner?.owner?.['.tag'] === 'team';
  const connectionJustEstablished = useConnectionRecentlyEstablished();
  const showSyncStatus = !isWebExtension && connectionJustEstablished(item);

  const connectedSubtext = (
    <div className={styles.connectedSubtitle}>
      <UIIcon
        size="small"
        src={CheckmarkCircleFill}
        className={styles.successColor}
      />
      <div className={styles.connectedText}>
        <Text size="small" color="faint">
          {i18n.t('connector_status_connected')}
        </Text>
      </div>
    </div>
  );

  // Team connectors should always be shown as connected within dash
  const subtext = isTeamConnector ? (
    connectedSubtext
  ) : item.loading ? (
    <div className={styles.connectedSubtitle}>
      <UIIcon size="small" src={SyncingLine} className={styles.successColor} />
      <div className={styles.connectedText}>
        {isReconnecting ? (
          <Text size="small" color="faint">
            {i18n.t('connector_status_reconnecting')}
          </Text>
        ) : (
          <Text size="small" color="faint">
            {i18n.t('connector_status_disconnecting')}
          </Text>
        )}
      </div>
    </div>
  ) : // do not show 'sync in progress' for team connectors
  showSyncStatus && !isTeamConnector ? (
    <div className={styles.connectedSubtitle}>
      <UIIcon size="small" src={SyncingLine} />
      <div className={styles.connectedText}>
        <Text size="small" color="faint">
          {i18n.t('connector_status_sync_in_progress')}
        </Text>
      </div>
    </div>
  ) : authenticated ? (
    connectedSubtext
  ) : isExpired || isReauth ? (
    <div className={styles.connectedSubtitle}>
      <UIIcon size="small" src={FailFill} className={styles.errorColor} />
      <div className={styles.connectedText}>
        <Text size="small" className={styles.errorColor}>
          {i18n.t('connector_status_expired')}
        </Text>
      </div>
    </div>
  ) : (
    <div className={styles.connectedSubtitle}>
      <UIIcon size="small" src={FailFill} className={styles.errorColor} />
      <div className={styles.connectedText}>
        <Text size="small" className={styles.errorColor}>
          {i18n.t('connector_status_sync_failed')}
        </Text>
      </div>
    </div>
  );

  return (
    <List.Item
      className={
        onboarding ? styles.onboardingVariantListItem : styles.listItem
      }
      padding="var(--spacing__unit--1_5)"
      verticalAlign="center"
    >
      <List.Content>
        <LabelGroup
          withLeftAccessory={
            isWebExtension ? (
              <span className={styles.iconExtraMargin}>{item.icon}</span>
            ) : (
              <img
                key={`${id}-icon`}
                src={
                  isDarkMode && item.branding?.icon_dark_src
                    ? item.branding.icon_dark_src
                    : item.branding?.icon_src
                }
                width="24px"
                height="24px"
                alt={label}
                className={styles.iconExtraMargin}
              />
            )
          }
          withText={
            <>
              <Text isBold>{label}</Text>
              {connectionOwner ? (
                <Text color="faint">
                  <span> • </span>
                  {connectionOwner}
                </Text>
              ) : null}
            </>
          }
          withSubtext={subtext}
        />
      </List.Content>
      <List.Accessory className={styles.flexCenter}>
        <>
          {teamConnector ||
          authenticated ||
          isReconnecting ||
          isDisconnecting ? null : (
            <Button
              variant="primary"
              onClick={() => {
                setIsReconnecting(true);
                if (!isWebExtension && connector) {
                  onReauthenticate(item, connector)
                    .then(() => setIsReconnecting(false))
                    .catch(() => setIsReconnecting(false));
                }
              }}
            >
              {i18n.t('reauthenticate')}
            </Button>
          )}
          {/* we don't want to show the disconnect modal during onboarding */}
          {!isReconnecting &&
            !isDisconnecting &&
            !hideDisconnect &&
            !teamConnector &&
            id &&
            (isWebExtension || (platform && type)) && (
              <DisconnectModal
                label={label ?? ''}
                item={item}
                onDisconnect={(item) => {
                  setIsDisconnecting(true);
                  onDisconnect(item)
                    .then(() => setIsReconnecting(false))
                    .catch(() => setIsReconnecting(false));
                }}
              />
            )}
        </>
      </List.Accessory>
    </List.Item>
  );
};
