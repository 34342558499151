import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { ArrowRightLine } from '@dropbox/dig-icons/assets';
import { openURL } from '@mirage/service-platform-actions';
import { DashThemeProvider } from '@mirage/service-settings/theming/DashThemeProvider';
import i18n from '@mirage/translations';
import { format, parse } from 'date-fns';
import { memo } from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './ChangelogModal.module.css';

interface ChangelogItem {
  date: string;
  title: string;
  body: string;
  link_url?: string;
  link?: string;
}

interface Changelog {
  version: string;
  changelog: ChangelogItem[];
}

interface ChangelogModalProps {
  onRequestClose: () => void;
  changelogJson: Changelog;
}
export const ChangelogModal = memo(
  ({ onRequestClose, changelogJson }: ChangelogModalProps) => {
    return (
      <Modal
        open
        width={692}
        isCentered
        withCloseButton="close"
        className={styles.ModalWindow}
        onRequestClose={onRequestClose}
      >
        <DashThemeProvider>
          <Modal.Body className={styles.ChangelogModalBody}>
            <div className={styles.ChangelogModalContent}>
              <div className={styles.ChangelogModalHeader}>
                <Title
                  size="medium"
                  className={styles.ChangelogModalTitle}
                  weightVariant="emphasized"
                >
                  {i18n.t('assistant_changelog')}
                </Title>
                <Text
                  size="medium"
                  tagName="div"
                  color="subtle"
                  className={styles.ChangelogModalSubtitle}
                >
                  {i18n.t('assistant_changelog_description')}
                </Text>
                <Button
                  variant="transparent"
                  size="small"
                  tone="neutral"
                  className={styles.ChangelogModalRequestAFeature}
                  onClick={() => {
                    openURL('https://forms.gle/8NrfeQ58mMAzYr916');
                  }}
                >
                  <Text size="small" isBold>
                    {i18n.t('assistant_request_a_feature')}
                  </Text>
                  <UIIcon size="small" src={ArrowRightLine} />
                </Button>
              </div>
              <div className={styles.ChangelogList}>
                {changelogJson.changelog.map(
                  (change: ChangelogItem, index: number) => (
                    <div key={index} className={styles.ChangelogItem}>
                      <Text
                        size="small"
                        tagName="div"
                        className={styles.ChangelogItemDate}
                      >
                        {format(
                          parse(change.date, 'yyyy-MM-dd', new Date()),
                          'MMMM dd, yyyy',
                        )}
                      </Text>
                      <Title
                        tagName="div"
                        size="small"
                        weightVariant="emphasized"
                        className={styles.ChangelogItemTitle}
                      >
                        {change.title}
                      </Title>
                      <Text
                        size="medium"
                        tagName="div"
                        className={styles.ChangelogItemBody}
                      >
                        <ReactMarkdown>{change.body}</ReactMarkdown>
                      </Text>
                      {change.link_url && (
                        <Button
                          variant="transparent"
                          size="small"
                          tone="neutral"
                          onClick={() => {
                            openURL(change.link_url);
                          }}
                        >
                          <Text size="small" isBold>
                            {i18n.t('assistant_changelog_default_link')}
                          </Text>
                          <UIIcon size="small" src={ArrowRightLine} />
                        </Button>
                      )}
                    </div>
                  ),
                )}
              </div>
            </div>
          </Modal.Body>
        </DashThemeProvider>
      </Modal>
    );
  },
);
ChangelogModal.displayName = 'ChangelogModal';
