import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { CreateStackLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Click_DashNewStack } from '@mirage/analytics/events/types/click_dash_new_stack';
import { useAllStacksAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { useNavigate } from 'react-router-dom';
import styles from './CreateStackButtonCard.module.css';

export default function CreateStackButtonCard({
  hasNoStacks,
  isMobileSize,
}: {
  hasNoStacks: boolean;
  isMobileSize: boolean;
}) {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const navigate = useNavigate();
  const augustRevision = useAllStacksAugustRevisionEnabled();
  const buttonWithoutIcon = isMobileSize ? (
    <UIIcon className={styles.stackIcon} src={CreateStackLine} />
  ) : (
    i18n.t('create_stack')
  );

  const content = (
    <Button
      variant="primary"
      onClick={() => {
        reportPapEvent(
          PAP_Click_DashNewStack({
            stacksEmptyState: hasNoStacks,
            entryPoint: 'stacks_page',
            featureLine: 'stacks',
          }),
        );

        navigate('/stacks/new');
      }}
      className={styles.iconTextContainer}
      data-testid="CreateStack"
      aria-label={i18n.t('create_stack')}
      withIconStart={!augustRevision && <UIIcon src={CreateStackLine} />}
    >
      {augustRevision ? buttonWithoutIcon : i18n.t('create_stack')}
    </Button>
  );

  if (isMobileSize) {
    return <DigTooltip title={i18n.t('create_stack')}>{content}</DigTooltip>;
  }
  return content;
}
