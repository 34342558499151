// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2563
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'initiate.dash_app_session'
// Description: Fires when the web app app is in the foreground (in focus).  This is the start of an app session based on new definition (notes)
// Owner: dash-web
export type Initiate_DashAppSession = PAPEventGeneric<
  'dash',
  'initiate',
  'dash_app_session',
  {
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // the start time of the event in ms
    startTimeMs?: number;
  }
>;

export function PAP_Initiate_DashAppSession(
  properties?: Initiate_DashAppSession['properties'],
): Initiate_DashAppSession {
  return <Initiate_DashAppSession>{
    class: 'dash',
    action: 'initiate',
    object: 'dash_app_session',
    properties,
  };
}
