import { stacks } from '@dropbox/api-v2-client';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { DashGettingStartedChecklistItem } from '@mirage/analytics/events/enums/dash_getting_started_checklist_item';
import { DashGettingStartedChecklistItemAction } from '@mirage/analytics/events/enums/dash_getting_started_checklist_item_action';
import { PAP_Dismiss_DashGettingStartedChecklist } from '@mirage/analytics/events/types/dismiss_dash_getting_started_checklist';
import { PAP_Finish_DashGettingStartedChecklistItem } from '@mirage/analytics/events/types/finish_dash_getting_started_checklist_item';
import { PAP_Open_DashGettingStartedChecklistItem } from '@mirage/analytics/events/types/open_dash_getting_started_checklist_item';
import { PAP_Select_DashGettingStartedChecklistItemInformation } from '@mirage/analytics/events/types/select_dash_getting_started_checklist_item_information';
import { PAP_Shown_DashGettingStartedChecklist } from '@mirage/analytics/events/types/shown_dash_getting_started_checklist';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { getFirstLoginTime } from '@mirage/service-onboarding';
import { useOnboardingValues } from '@mirage/service-onboarding/hooks';
import {
  DismissAfterAccountAge,
  useDismissableModule,
} from '@mirage/service-onboarding/useDismissableModule';
import {
  getOnboardingChecklist,
  markChecklistItemComplete,
} from '@mirage/service-onboarding-checklist';
import { OnboardingChecklistItem } from '@mirage/service-onboarding-checklist/service';
import { openURL } from '@mirage/service-platform-actions';
import { stackGetShareId } from '@mirage/service-stacks/service/utils';
import useAvailableExtensions from '@mirage/settings/utils/useAvailableExtensions';
import {
  openExtensionLinkForCurrentBrowser,
  useIsExtensionInstalled,
} from '@mirage/settings/utils/webExtensionsHelpers';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { DESKTOP_DOWNLOAD_URL } from '@mirage/shared/urls';
import { useSortedStacks } from '@mirage/stacks/hooks';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import {
  SVGProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import * as modules from './modules';

export type OnboardingChecklistAction = {
  key?: DashGettingStartedChecklistItemAction;
  label: string;
  action?: () => void;
  iconStart?: (props: SVGProps<SVGSVGElement>) => React.ReactElement;
  markComplete?: boolean;
};

export type OnboardingChecklistStep = OnboardingChecklistItem & {
  id: number;
  title: string;
  message: string;
  actions: OnboardingChecklistAction[];
  infoLink?: string;
};

type OnboardingChecklistStepPartial = {
  id: number;
  moduleId: string;
  title: string;
  message: string;
  actions: OnboardingChecklistAction[];
  infoLink?: string;
  milestoneKeys: string[];
};

const DISMISSED_GETTING_STARTED_CHECKLIST = 'dismissedGettingStartedChecklist';
const SEEN_GETTING_STARTED_CHECKLIST = 'seenGettingStartedChecklist';
const ONBOARDING_CHECKLIST_TYPE = 'is_dash_checklist';
const OPEN_DELAY_ON_LOAD = 1500; // 1.5 seconds
const MARK_SEEN_DELAY = 5000;

const getOnboardingChecklistSteps = (
  extensionAvailableForBrowser: boolean,
  hasGettingStartedStack: boolean,
): OnboardingChecklistStepPartial[] => {
  const checklistItems = [];

  checklistItems.push(
    modules.search,
    ...(hasGettingStartedStack ? [modules.getStartedStack] : []),
    modules.createStack,
    extensionAvailableForBrowser ? modules.installApps : modules.installDesktop,
  );

  return checklistItems.map((item, idx) => ({ ...item, id: idx }));
};

export const OpenTypeaheadAtom = atomWithStorage<boolean>(
  'openTypeaheadAtom',
  false,
);

export const OnboardingChecklistEnabledAtom = atomWithStorage<boolean>(
  'onboardingChecklistEnabledAtom',
  false,
);

const OnboardingChecklistDirtyAtom = atomWithStorage<boolean>(
  'onboardingChecklistDirtyAtom',
  false,
);

const OnboardingChecklistAtom = atomWithStorage<OnboardingChecklistStep[]>(
  'onboardingChecklistItems',
  [],
);

export default function useOnboardingChecklist() {
  const { checklistItems, fetchChecklistItems } = useOnboardingChecklistItems();
  const dismissBehavior = useMemo(() => new DismissAfterAccountAge('3d'), []);
  const { dismissed, setDismissed } = useDismissableModule(
    DISMISSED_GETTING_STARTED_CHECKLIST,
    dismissBehavior,
  );
  const { progress } = useChecklistProgress();
  const [openId, setOpenId] = useState('');
  const [open, setOpen] = useState(false);
  const enabled = useAtomValue(OnboardingChecklistEnabledAtom);
  const isMobile = useIsMobileSize();
  const previousIncompleteItem = useRef('');
  const { getOnboardingValue, setOnboardingValue } = useOnboardingValues();
  const { openLearnMore } = useChecklistActions(setOpen);
  const { reportPapEvent } = useMirageAnalyticsContext();

  const handleDismissed = useCallback(
    (log = true) => {
      setDismissed(true);

      if (log) {
        reportPapEvent(
          PAP_Dismiss_DashGettingStartedChecklist({
            actionSurfaceComponent: 'getting_started_checklist',
            featureLine: 'getting_started_checklist',
          }),
        );
      }
    },
    [reportPapEvent],
  );

  const displayed = !isMobile && !dismissed && enabled;

  const handleOpenId = useCallback(
    (moduleId: string) => {
      setOpenId(moduleId);

      if (!open) return;
      reportPapEvent(
        PAP_Open_DashGettingStartedChecklistItem({
          dashGettingStartedChecklistItem:
            moduleId as DashGettingStartedChecklistItem,
          actionSurfaceComponent: 'getting_started_checklist',
          featureLine: 'getting_started_checklist',
        }),
      );
    },
    [reportPapEvent, open],
  );

  useEffect(() => {
    if (!checklistItems?.length) {
      return;
    }

    const completeItemCount = checklistItems.filter((m) => m.isComplete).length;
    if (!completeItemCount) {
      return;
    }

    const firstIncompleteItem = checklistItems.find((m) => !m.isComplete);
    if (
      firstIncompleteItem &&
      firstIncompleteItem.moduleId !== previousIncompleteItem.current
    ) {
      handleOpenId(firstIncompleteItem.moduleId);
      previousIncompleteItem.current = firstIncompleteItem.moduleId;
      setOpen(true);
    }
  }, [checklistItems, handleOpenId]);

  // If displayed, fetch the latest getting started checklist progress and show if not seen
  useEffect(() => {
    const fetchShownOnLoad = async () => {
      if (displayed) {
        if (checklistItems?.length > 0) {
          const seen = await getOnboardingValue(SEEN_GETTING_STARTED_CHECKLIST);
          if (!seen) {
            setTimeout(() => {
              // Delay the initial open by 1.5 seconds
              setOpen(true);
            }, OPEN_DELAY_ON_LOAD);
          }
        }
        fetchChecklistItems();
      }
    };

    fetchShownOnLoad();
  }, [
    checklistItems?.length,
    displayed,
    fetchChecklistItems,
    getOnboardingValue,
  ]);

  const handleShown = () => {
    setTimeout(() => {
      // Delay marking as shown in order to ignore instances of checklist that are shown before onboarding redirect
      setOnboardingValue(SEEN_GETTING_STARTED_CHECKLIST, true);
    }, MARK_SEEN_DELAY);

    reportPapEvent(
      PAP_Shown_DashGettingStartedChecklist({
        actionSurfaceComponent: 'getting_started_checklist',
        featureLine: 'getting_started_checklist',
      }),
    );
  };

  return {
    checklistItems,
    fetchChecklistItems,
    displayed,
    progress,
    open,
    openId,
    setDismissed: handleDismissed,
    setOpenId: handleOpenId,
    openLearnMore,
    setOpen,
    handleShown,
  };
}

export function useOnboardingChecklistItems() {
  const setOpenTypeahead = useSetAtom(OpenTypeaheadAtom);
  const { extensionAvailableForBrowser } = useAvailableExtensions();
  const navigate = useNavigate();
  const extensionInstalled = useIsExtensionInstalled();
  const hasBrowserExtensionInstalled =
    extensionInstalled && extensionAvailableForBrowser;
  const [checklistItems, setChecklistItems] = useAtom(OnboardingChecklistAtom);
  const [isDirty, setIsDirty] = useAtom(OnboardingChecklistDirtyAtom);
  const [gettingStartedStack, setGettingStartedStack] =
    useState<stacks.Stack>();
  const [checklistSteps, setChecklistSteps] = useState<
    OnboardingChecklistStepPartial[]
  >([]);
  const stacks = useSortedStacks();

  const addBrowserExtension = () => {
    openExtensionLinkForCurrentBrowser();
  };

  const installDesktopApp = () => {
    openURL(DESKTOP_DOWNLOAD_URL);
  };

  useEffect(() => {
    const stack = stacks?.find(
      (s) => s.stack_data?.creation_type?.['.tag'] === 'welcome_stack',
    );
    setGettingStartedStack(stack);
  }, [stacks]);

  useEffect(() => {
    const steps = getOnboardingChecklistSteps(
      extensionAvailableForBrowser,
      !!gettingStartedStack,
    );
    setChecklistSteps(steps);
  }, [extensionAvailableForBrowser, gettingStartedStack, stacks]);

  const fetchChecklistItems = useCallback(async () => {
    const items = await getOnboardingChecklist(ONBOARDING_CHECKLIST_TYPE);
    const hasInstalledDesktop = !!(await getFirstLoginTime('desktop'));
    const uChecklistItems = checklistSteps
      .map((uiModule) => {
        const { moduleId } = uiModule;
        const module = items?.find((m) => m.moduleId === moduleId);
        if (!module) return undefined;

        const newChecklistItem = {
          ...module,
          ...uiModule,
        };
        switch (moduleId) {
          case 'perform_dash_search_module':
            newChecklistItem.actions[0].action = () => {
              // Open the typeahead
              setOpenTypeahead(true);
            };
            break;
          case 'create_stack_module':
            newChecklistItem.actions[0].action = () => {
              // Navigate to Create Stack page
              navigate('/stacks/new');
            };
            break;

          case 'explore_get_started_stack_module':
            {
              newChecklistItem.actions[0].action = () => {
                // Navigate to Welcome Stack page
                navigate(`/stacks/${stackGetShareId(gettingStartedStack)}`);
              };
            }
            break;
          case 'install_dash_desktop_and_extension_module': {
            const downloadExtensionAction = newChecklistItem.actions.find(
              (action) => action.key === 'download_extension',
            );
            if (downloadExtensionAction) {
              downloadExtensionAction.markComplete =
                hasBrowserExtensionInstalled;
              downloadExtensionAction.action = () => {
                // Open browser extension download link
                addBrowserExtension();
              };
            }
            const downloadDesktopAppAction = newChecklistItem.actions.find(
              (action) => action.key === 'download_desktop_app',
            );
            if (downloadDesktopAppAction) {
              downloadDesktopAppAction.markComplete = hasInstalledDesktop;
              downloadDesktopAppAction.action = () => {
                // Open the desktop app download link
                installDesktopApp();
              };
            }
            break;
          }
        }
        return newChecklistItem;
      })
      .filter(Boolean) as OnboardingChecklistStep[];

    setChecklistItems(uChecklistItems);
    setIsDirty(false);
    // If we add in the missing dependency, an infinite loop is created
  }, [
    checklistSteps,
    setChecklistItems,
    setIsDirty,
    setOpenTypeahead,
    navigate,
    gettingStartedStack,
    extensionAvailableForBrowser,
    hasBrowserExtensionInstalled,
  ]);

  // If the checklist is 'dirty' that means we've marked checklist items
  // as complete and we need to perform a refresh
  useEffect(() => {
    const doChecklistItemRefresh = async () => {
      if (isDirty) fetchChecklistItems();
    };
    doChecklistItemRefresh();
  }, [fetchChecklistItems, isDirty]);

  return {
    checklistItems,
    fetchChecklistItems,
  };
}

function useChecklistProgress() {
  const { checklistItems } = useOnboardingChecklistItems();
  const [progress, setProgress] = useState(0);
  const previousProgress = useRef(0);

  const updateProgress = (val: number) => {
    setProgress(val);
    previousProgress.current = val;
  };

  useEffect(() => {
    // Checklist items can be changed from separate instances of the hook
    // If we listen for changes we can update the progress appropriately
    if (!checklistItems) {
      updateProgress(0);
      return;
    }

    const completeItemCount = checklistItems.filter((m) => m.isComplete).length;
    if (!completeItemCount) {
      updateProgress(0);
      return;
    }
    const updatedProgress = (completeItemCount / checklistItems.length) * 100;
    if (updatedProgress === previousProgress.current) return;
    updateProgress(updatedProgress);
  }, [checklistItems]);

  return { progress };
}

export function useChecklistActions(setOpen: (open: boolean) => void) {
  const { checklistItems } = useOnboardingChecklistItems();
  const setIsDirty = useSetAtom(OnboardingChecklistDirtyAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const hasBrowserExtensionInstalled = useIsExtensionInstalled();
  const stacks = useSortedStacks();
  const hasMarkedStack = useRef(false);
  const hasMarkedAppsInstalled = useRef(false);
  const markChecklistItemComplete = useMarkChecklistItemComplete();
  const { extensionAvailableForBrowser } = useAvailableExtensions();
  const onboardingChecklistSteps = getOnboardingChecklistSteps(
    extensionAvailableForBrowser,
    true,
  );

  const markComplete = useCallback(
    (moduleId: string) => {
      const asyncMarkComplete = async () => {
        const success = await markChecklistItemComplete(moduleId);
        if (!success) return;
        setIsDirty(true);
        setOpen(true);
      };
      asyncMarkComplete();
    },
    [markChecklistItemComplete, setIsDirty, setOpen],
  );

  // If a stack is added, mark create_stack_module module as complete
  useEffect(() => {
    const addAppModule = checklistItems.find(
      (m) => m.moduleId === 'create_stack_module' && !m.isComplete,
    );
    if (addAppModule && stacks?.length && !hasMarkedStack.current) {
      const ownedStacks = stacks.filter(
        (s) =>
          s.permission?.['.tag'] === 'owner' &&
          s.stack_data?.creation_type?.['.tag'] !== 'welcome_stack',
      );
      if (ownedStacks.length) {
        hasMarkedStack.current = true;
        markComplete('create_stack_module');
      }
    }
  }, [stacks, checklistItems, markComplete]);

  // If the browser extension and desktop application have been installed,
  // mark install_dash_desktop_and_extension_module module as complete
  useEffect(() => {
    const asyncMarkComplete = async () => {
      const hasInstalledDesktop = !!(await getFirstLoginTime('desktop'));

      // If extension is available and installed or not available
      const browserExtensionInstalled =
        (hasBrowserExtensionInstalled && extensionAvailableForBrowser) ||
        !extensionAvailableForBrowser;

      if (
        !hasMarkedAppsInstalled.current &&
        hasInstalledDesktop &&
        browserExtensionInstalled
      ) {
        hasMarkedAppsInstalled.current = true;
        markComplete('install_dash_desktop_and_extension_module');
      }
    };
    asyncMarkComplete();
  }, [
    extensionAvailableForBrowser,
    hasBrowserExtensionInstalled,
    markComplete,
  ]);

  const openLearnMore = (moduleId: string) => {
    const module = onboardingChecklistSteps.find(
      (m) => m.moduleId === moduleId,
    );
    if (!module) return;
    const { infoLink } = module;
    reportPapEvent(
      PAP_Select_DashGettingStartedChecklistItemInformation({
        dashGettingStartedChecklistItem:
          moduleId as DashGettingStartedChecklistItem,
        actionSurfaceComponent: 'getting_started_checklist',
        featureLine: 'getting_started_checklist',
      }),
    );
    return openURL(infoLink);
  };

  return { markComplete, openLearnMore };
}

export function useMarkChecklistItemComplete() {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const setIsDirty = useSetAtom(OnboardingChecklistDirtyAtom);
  const { extensionAvailableForBrowser } = useAvailableExtensions();
  const onboardingChecklistSteps = getOnboardingChecklistSteps(
    extensionAvailableForBrowser,
    true,
  );

  const markComplete = useCallback(
    async (moduleId: string) => {
      // Desktop does not currently support the getting started checklist
      if (EnvCtx?.surface === 'desktop') return;

      const moduleIndex = onboardingChecklistSteps.findIndex(
        (m) => m.moduleId === moduleId,
      );
      const module = onboardingChecklistSteps[moduleIndex];
      if (!module) return false;
      const success = await markChecklistItemComplete(module.milestoneKeys);
      if (!success) return;

      setIsDirty(true);

      reportPapEvent(
        PAP_Finish_DashGettingStartedChecklistItem({
          dashGettingStartedChecklistItem:
            moduleId as DashGettingStartedChecklistItem,
          actionSurfaceComponent: 'getting_started_checklist',
          featureLine: 'getting_started_checklist',
        }),
      );
      return true;
    },
    [reportPapEvent, setIsDirty],
  );
  return markComplete;
}
