// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3066
import type { PAPEventGeneric } from '../base/event';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'open.customize_layout_modal'
// Description: Fires when a user clicks the option to open the customize layout modal
// Owner: dash-web
export type Open_CustomizeLayoutModal = PAPEventGeneric<
  'dash',
  'open',
  'customize_layout_modal',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
  }
>;

export function PAP_Open_CustomizeLayoutModal(
  properties?: Open_CustomizeLayoutModal['properties'],
): Open_CustomizeLayoutModal {
  return <Open_CustomizeLayoutModal>{
    class: 'dash',
    action: 'open',
    object: 'customize_layout_modal',
    properties,
  };
}
