import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { SearchPage } from '@mirage/mosaics/SearchPage';
import {
  generateSearchURL,
  getSearchFromURLParams,
  useQueryParams,
} from '@mirage/search/hooks/useQueryParams';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { useSearchContext } from '@mirage/service-search/hooks';
import { TypeaheadQueryAtom } from '@mirage/service-typeahead-search/hooks/useTypeaheadSearch';
import { useDashTitle } from '@mirage/shared/hooks/DashTitle';
import { useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { SearchFilter } from '@mirage/shared/search/search-filters';

interface SearchResultsPageProps {
  isDesktop?: boolean;
}

export const SearchResultsPage = ({ isDesktop }: SearchResultsPageProps) => {
  const { connectors } = useConnectors();
  const navigate = useNavigate();
  const { handleSearch: performSearch, ...rest } = useSearchContext();
  const { searchAttemptSessionManager } = useMirageAnalyticsContext();
  const queryParams = useQueryParams();
  const { query, filters } = useMemo(
    () => getSearchFromURLParams(queryParams),
    [queryParams],
  );
  const setTypeaheadQuery = useSetAtom(TypeaheadQueryAtom);

  useFeatureFlagValue('dash_2024_08_09_slotted_search_ranking');
  useDashTitle(query);

  useEffect(() => {
    if (!query) return;
    performSearch(query, filters);
  }, [query, filters]);

  useEffect(() => {
    return () => {
      rest.resetQuery();
    };
  }, [rest.resetQuery]);

  const handleSearch = async (
    query: string,
    filters?: SearchFilter[] | undefined,
  ) => {
    searchAttemptSessionManager.updateProperties({
      searchSurface: 'result_page',
    });
    setTypeaheadQuery(query);
    navigate(generateSearchURL(query, filters));
  };

  const handleResubmitSearch = () => {
    performSearch(rest.query, rest.filters);
  };

  return (
    <SearchPage
      searchContext={{
        handleSearch,
        ...rest,
      }}
      connectors={connectors}
      isDesktop={isDesktop}
      onResubmitSearch={handleResubmitSearch}
    />
  );
};
