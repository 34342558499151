import { useEffect } from 'react';

// Dash favicon urls.
// AEM (Adobe Experience Manager) link:
// https://author-dropbox-prod.adobemsbasic.com/assetdetails.html/content/dam/dropbox/images/
const LightSvg = 'https://assets.dropbox.com/images/dash-logo.svg';
const LightPng = 'https://assets.dropbox.com/images/dash-logo.png';
const DarkSvg = 'https://assets.dropbox.com/images/dash-logo-dark.svg';
const DarkPng = 'https://assets.dropbox.com/images/dash-logo-dark.png';

const removeAllFavicons = (): void => {
  document
    .querySelectorAll("link[rel~='icon'], link[rel~='mask-icon']")
    .forEach((link) => link.remove());
};

const htmlToElement = (html: string): HTMLElement => {
  const template = document.createElement('template');
  template.innerHTML = html;
  return template.content.firstChild as HTMLElement; // ignore null
};

const addLink = (html: string): void => {
  document.head.appendChild(htmlToElement(html));
};

/**
 * Update the Dash favicons on the webpage in light or dark mode.
 *
 * Notes:
 * 1.`rel="mask-icon"` is only used for Safari.
 * 2. `<link media="(prefers-color-scheme: dark)"` doesn't work for Firefox.
 * 3. `color` is not a valid attribute in HTMLLinkElement, so we create the
 *    links using HTML strings.
 * 4. SVGs and PNGs must be 32x32 or larger to look sharp.
 * 5. The `?v*` suffix is important to let Google Chrome render the favicon
 *    correctly (bad Chrome bug). Chrome favicon caches *cannot* be cleared
 *    - maybe unless you uninstall Chrome entirely. So whenever you upload
 *    new favicons, please be sure to bump the version number.
 */
const updateFavicons = (darkModeEnabled: boolean): void => {
  removeAllFavicons();

  const v = '?v8';

  const svg = (darkModeEnabled ? DarkSvg : LightSvg) + v;
  const png = (darkModeEnabled ? DarkPng : LightPng) + v;
  const bgColor = darkModeEnabled ? '#000' : '#fff';

  // Insert Dash favicon links.
  // Copy what github.com is doing.
  addLink(`<link rel="mask-icon" href="${svg}" color="${bgColor}">`);
  addLink(
    `<link rel="alternate icon" class="js-site-favicon" type="image/png" href="${png}">`,
  );
  addLink(
    `<link rel="icon" class="js-site-favicon" type="image/svg+xml" href="${svg}">`,
  );
  addLink(
    `<link key="apple-touch-icon" rel="apple-touch-icon" class="js-site-favicon" sizes="180x180" type="image/png" href="${png}">`,
  );
};

let initialized = false;

// Only need to call it once, but ok to call it more than once.
export const initDashFavicon = (): void => {
  if (initialized) {
    return;
  }

  const result = window.matchMedia('(prefers-color-scheme: dark)');

  // Set the current display mode.
  updateFavicons(result.matches);

  if (result.addEventListener) {
    result.addEventListener('change', (event) => updateFavicons(event.matches));
  } else if ('onchange' in result) {
    // value might be null, so need the `in` keyword.
    result.onchange = (event) => updateFavicons(event.matches);
  }

  initialized = true;
};

/** Small hook for convenience only. */
export function useDashFavicon() {
  useEffect(() => {
    initDashFavicon();
  }, []);
}
