import { callApiV2 } from '@mirage/service-dbx-api';
import { ONE_HOUR_IN_MILLIS } from '@mirage/shared/util/constants';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useEffect, useState } from 'react';

import type { dcs } from '@dropbox/api-v2-client';

interface SuggestionsCache {
  lastFetchMs: number;
  suggestions: dcs.Suggestions[];
}

// Create an atom with storage for caching the suggestions
const suggestionsCacheAtom = atomWithStorage<SuggestionsCache>(
  'questionSuggestionsCache',
  {
    lastFetchMs: 0,
    suggestions: [],
  },
);

export const useQuestionSuggestions = () => {
  const [cache, setCache] = useAtom(suggestionsCacheAtom);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Only fetch suggestions if we haven't fetched them in the last hour
    const shouldFetch =
      !cache.lastFetchMs || Date.now() - cache.lastFetchMs > ONE_HOUR_IN_MILLIS;

    const getSuggestions = async () => {
      setIsLoading(shouldFetch);
      if (shouldFetch) {
        try {
          const { suggestions } = await callApiV2(
            'dcsGetQuestionSuggestions',
            {},
          );
          if (suggestions) {
            // Only return the first three suggestions - in the case the API returns more in the future
            const threeSuggestions = suggestions.slice(0, 3);
            setCache({
              lastFetchMs: Date.now(),
              suggestions: threeSuggestions,
            });
          }
        } catch (err) {
          // Handle errors as needed
        } finally {
          setIsLoading(false);
        }
      }
    };

    getSuggestions();
  }, [cache, setCache]);

  return { suggestions: cache.suggestions, isLoading };
};
