// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3504
import type { PAPEventGeneric } from "../base/event";
import type { DashSurface } from "../enums/dash_surface";
import type { ActionSurfaceComponent } from "../enums/action_surface_component";
import type { DashActionSurface } from "../enums/dash_action_surface";
import type { FeatureLine } from "../enums/feature_line";
import type { Environment } from "../enums/environment";

// Event_name: 'hover.meeting_guests'
// Description: Log `hover.meeting_guests` when hovering over the Calendar meeting participants
// Owner: dash-web
export type Hover_MeetingGuests = PAPEventGeneric<
  'dash',
  'hover',
  'meeting_guests',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
  }
>;

export function PAP_Hover_MeetingGuests(properties?: Hover_MeetingGuests['properties']): Hover_MeetingGuests {
  return <Hover_MeetingGuests>{
    class: 'dash',
    action: 'hover',
    object: 'meeting_guests',
    properties,
  };
}
