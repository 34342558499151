// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgLever({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.81 20.948l-3.765-3.766a.636.636 0 00-.89 0l-3.766 3.766c-.404.404-.121 1.052.445 1.052h7.53c.567 0 .85-.688.445-1.052z"
        fill="#C3C6CC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.934 7.423L20.755 3.92c-.071-.179-.142-.322-.25-.429L2.143 21.857c.107.101.25.143.393.143h5.216c.286 0 .571-.107.786-.322L21.684 8.53c.286-.286.393-.714.25-1.107z"
        fill="#E1E3E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.077 3.242l-3.5-1.179a1.055 1.055 0 00-1.108.25L2.32 15.461c-.214.215-.321.5-.321.786v5.217c0 .143.042.286.143.393L20.506 3.492c-.107-.107-.25-.179-.429-.25z"
        fill="#C3C6CC"
      />
    </svg>
  );
}

export default SvgLever;
