import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { FavIcon } from '@mirage/link-list/Favicon/Favicon';
import { ListItemSize } from '@mirage/link-list/types';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import classnames from 'classnames';
import styles from './ContentTile.module.css';

export type ContentTileProps = {
  url: string;
  label: string;
  onClick: (e: React.SyntheticEvent) => void;
  size?: 'small' | 'medium';
  className?: string;
};

/**
 * Temporarily implementation of ContentTile while we wait for the
 * real implementation to merge into the Dash component library.
 * Feel free to expand the component as you see fit.
 *
 * Content Tile specs: https://stan-dbx-playground--65551c6bf7af0d3e3130abc7.chromatic.com/?path=/docs/components-contenttile-contenttile--documentation
 */
export const ContentTile = ({
  url,
  label,
  onClick,
  size = 'medium',
  className,
}: ContentTileProps) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        event.stopPropagation();
        break;
    }
  };

  return (
    <DigTooltip title={label} placement="top">
      <button
        className={classnames(styles.button, className)}
        onClick={onClick}
        onKeyDown={handleKeyDown}
      >
        <div className={styles.iconContainer}>
          <FavIcon src={faviconSrcForSrcUrl(url)} size={ListItemSize.Small} />
        </div>
        <div className={styles.textContainer}>
          <Text variant="label" color="standard" size="small">
            <Truncate lines={size === 'medium' ? 2 : 1}>{label}</Truncate>
          </Text>
        </div>
      </button>
    </DigTooltip>
  );
};
