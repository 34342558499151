// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgSalesloft({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 2.113c-3.19.159-6.192.563-9.052 1.762-1.235.505-2.356 1.184-3.347 2.108-1.02.954-1.293 2.123-.948 3.438.43 1.732 1.522 3.032 2.902 4.087 1.235.938 1.997 2.123 2.04 3.711.043 1.762-.69 3.148-2.17 4.058-1.508.939-3.089.953-4.626.101C3.64 19.602 1.972 16.872 2 13.204c.03-2.556 1.078-4.679 2.917-6.426 2.486-2.369 5.517-3.582 8.807-4.232 2.5-.49 5.015-.606 7.558-.52.086 0 .172.015.273.015.1 0 .215.029.445.072z"
        fill="#1C71B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.701 21.35c1.969-1.56 2.615-3.626 2.3-6.052-.116-.837-.547-1.545-1.05-2.195-.546-.707-1.164-1.357-1.767-2.022a5.81 5.81 0 01-.69-.88c-.56-.94-.517-1.82.144-2.672.517-.68 1.221-1.127 1.968-1.517.23-.115.36.015.518.13 1.968 1.473 3.19 3.408 3.563 5.85.589 3.74-1.178 7.379-4.425 9.213-.173.101-.33.231-.56.144z"
        fill="#1C71B1"
      />
    </svg>
  );
}

export default SvgSalesloft;
