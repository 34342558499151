// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1905
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';

// Event_name: 'update.stack_emoji'
// Description: Update the emoji on a stack
// Owner: otc-startpage
export type Update_StackEmoji = PAPEventGeneric<
  'stacks',
  'update',
  'stack_emoji',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
  }
>;

export function PAP_Update_StackEmoji(
  properties?: Update_StackEmoji['properties'],
): Update_StackEmoji {
  return <Update_StackEmoji>{
    class: 'stacks',
    action: 'update',
    object: 'stack_emoji',
    properties,
  };
}
