import cx from 'classnames';
import React from 'react';

type Props = {
  src?: string;
  alt: string;
  classNames: Parameters<typeof cx>;
  fallback: JSX.Element;
};

// generic styleable favicon component to let consumption context provide styles
export function StylableFavicon({ src, alt, classNames, fallback }: Props) {
  const [hasError, setHasError] = React.useState(false);

  return !src || hasError ? (
    fallback
  ) : (
    <img
      alt={alt}
      src={src}
      onError={() => {
        setHasError(true);
      }}
      className={cx(...classNames)}
    />
  );
}
