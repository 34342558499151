// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1996
import type { PAPEventGeneric } from '../base/event';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashAnnotationMode } from '../enums/dash_annotation_mode';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { EndReason } from '../enums/end_reason';
import type { FeatureLine } from '../enums/feature_line';
import type { SessionStartReason } from '../enums/session_start_reason';

// Event_name: 'finish.search_session'
// Description: A period of time where the user was using the search functionality. The period ends after 60 seconds of not using search functionality.
// Owner: otc
export type Finish_SearchSession = PAPEventGeneric<
  'dash',
  'finish',
  'search_session',
  {
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // the end time of the event in ms
    endTimeMs?: number;
    // The reason the session ended
    endReason?: EndReason;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // Indicating if the search result page is opened during a search session
    hasSerpOpened?: boolean;
    // Set to true when the search field has a value upon search
    hasQuery?: boolean;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The surface in which an action was taken on Dash.
    dashActionSurface?: DashActionSurface;
    // The reason why a session was started
    sessionStartReason?: SessionStartReason;
    // The annotation mode (if user is annotating).
    dashAnnotationMode?: DashAnnotationMode;
  }
>;

export function PAP_Finish_SearchSession(
  properties?: Finish_SearchSession['properties'],
): Finish_SearchSession {
  return <Finish_SearchSession>{
    class: 'dash',
    action: 'finish',
    object: 'search_session',
    properties,
  };
}
