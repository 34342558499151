/**
 * @GENERATED from Figma node 693:15953
 */

import * as React from "react";
export type IconProps = Omit<React.SVGProps<SVGSVGElement>, "color">;
const ShortcutAppBright = (props: IconProps, ref: React.Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    focusable={false}
    role="presentation"
    ref={ref}
    {...props}
  >
    <path
      fill="#58B1E4"
      d="M21 7.5A4.5 4.5 0 0 0 16.5 3h-9a4.49 4.49 0 0 0-3.182 1.318 4.5 4.5 0 0 0 0 6.364l1.603 1.603A4.502 4.502 0 0 0 7.5 21h9a4.49 4.49 0 0 0 3.182-1.318 4.5 4.5 0 0 0 0-6.364l-1.603-1.603A4.5 4.5 0 0 0 21 7.5M5.553 18.448a2.754 2.754 0 0 1 1.832-4.699l5.505 5.506H7.5a2.74 2.74 0 0 1-1.947-.807m12.894-3.895a2.754 2.754 0 0 1-3.894 3.895l-9-9a2.754 2.754 0 0 1 3.895-3.896zm0-5.106a2.73 2.73 0 0 1-1.831.804L11.11 4.746h5.39a2.754 2.754 0 0 1 1.947 4.701"
    />
  </svg>
);
const ForwardRef = React.forwardRef(ShortcutAppBright);
export { ForwardRef as ShortcutAppBright };
