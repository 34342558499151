import { getCurrentAccount, listenForAccount } from '@mirage/service-auth';
import {
  isDropboxerAccount,
  IsDropboxerMode,
  isTestAccount,
} from '@mirage/shared/account';
import { useConsolidatedSubscription } from '@mirage/shared/hooks/useConsolidatedSubscription';
import { atom, useAtom } from 'jotai';
import { useMemo } from 'react';

import type { users } from '@dropbox/api-v2-client';

const accountAtom = atom<users.FullAccount | undefined>(undefined);

function subscribeToAccount(
  updateState: (data: users.FullAccount | undefined) => void,
) {
  return listenForAccount().subscribe(updateState);
}

export default function useDropboxAccount() {
  const [account, setAccount] = useAtom(accountAtom);

  useConsolidatedSubscription({
    hookName: 'useDropboxAccount',
    setState: setAccount,
    getInitialData: getCurrentAccount,
    subscribe: subscribeToAccount,
  });

  return account;
}

/**
 * Return false if the user is not a Dropboxer.
 * Return true if the user is a Dropboxer.
 * Return undefined if the account is not yet loaded.
 */
export function useIsDropboxer(
  mode = IsDropboxerMode.WorkOrPersonalAccount,
  // for unit testing
  _useDropboxAccount = useDropboxAccount,
) {
  const account = _useDropboxAccount();
  return useMemo(() => isDropboxerAccount(account, mode), [account, mode]);
}

/** Combine two functions into one to minimize subscriptions. */
export function useAccountIsDropboxer(
  mode = IsDropboxerMode.WorkOrPersonalAccount,
  // for unit testing
  _useDropboxAccount = useDropboxAccount,
) {
  const account = _useDropboxAccount();
  const isDropboxer = useMemo(
    () => isDropboxerAccount(account, mode),
    [account, mode],
  );
  return { account, isDropboxer };
}

export function useIsTestAccount(_useDropboxAccount = useDropboxAccount) {
  const account = _useDropboxAccount();
  return isTestAccount(account);
}
