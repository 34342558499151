import { v5 as uuid } from 'uuid';

import type { stacks } from '@dropbox/api-v2-client';
import type {
  StackInfo,
  StackItem,
  StackItemDescription,
  StackItemUser,
} from '@mirage/shared/search/stack-item';

const STACK_ITEM_RESULT_NAMESPACE = 'b0ba8405-b01c-4c4c-b424-b2ad60c0008e';

export const buildStackItemUser = (
  entity?: stacks.StackItemUser,
): StackItemUser | null => {
  if (!entity) return null;
  return {
    displayName: entity.display_name,
    email: entity.email,
    profilePhotoUrl: entity.profile_photo_url,
  };
};

export const buildStackItemDescription = (
  entity?: stacks.StackItemDescription,
): StackItemDescription | null => {
  if (!entity) return null;
  return {
    content: entity.content,
    lastModifiedTime: entity.last_modified_time_utc_sec,
    creatorEmail: entity.creator_email,
  };
};

export const buildStackItem = (
  entity: stacks.StackItem,
  stackIds: StackInfo[] = [],
): StackItem | null => {
  // No stack items type other than shortcut.
  // TODO: Update build logic when additional stack item types are added
  if (entity['.tag'] === 'other') return null;

  const {
    name,
    url,
    last_modified_time_utc_sec,
    creator: stack_item_creator,
    parent_section_id,
    sort_key,
    description: stack_item_description,
    custom_name,
    metadata_title,
  } = entity;

  if (!name) return null;
  if (!url) return null;

  const creator = buildStackItemUser(stack_item_creator);
  const description = buildStackItemDescription(stack_item_description);

  // generating uuid based on url dedupes the same item in different stacks
  return {
    uuid: uuid(url, STACK_ITEM_RESULT_NAMESPACE),
    name: name.replace('.stackitem', ''),
    url,
    description,
    creator,
    parentSectionId: parent_section_id,
    sortKey: sort_key,
    lastModifiedTime: last_modified_time_utc_sec,
    stackIds,
    custom_name,
    metadata_title,
  };
};
