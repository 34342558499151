export enum Category {
  StrictlyNecessary = 'strictly necessary',
  Analytics = 'analytics',
  MarketingAdvertising = 'general marketing and advertising',
  PerformanceFunctionality = 'performance and functionality',
  SocialMediaAdvertising = 'social media advertising',
  AllCookies = 'all',
}

export type Categories = Record<Category, boolean>;

export interface EmbeddableConfig {
  // the "default" method (searches for <a> with href="manage-cookies")
  injectToFooter: boolean;
  // fallback for pages w/o a footer
  injectFloatingButton: boolean;
  // do not disable banner for logged-out pages!
  disableBanner: boolean;

  // Necessary config for the privacy consent modules to work
  thirdPartyConfig: {
    // can be scoped to a subdomain or more broad i.e. "dropbox.com"
    cookieDomain: string;
    redirectUrlForSandboxedPage?: string;
    emailProofToken?: string;
  };

  // user changed their preferences
  consentChangedCallback?: (categories: Partial<Categories>) => void;
  // consent has been detected from prior interactions
  priorConsentCallback?: (
    categories: Partial<Categories>,
    preferencesChanged: boolean,
    userInteracted: boolean,
  ) => void;
  // consent has been declined from prior interactions
  priorConsentDeclinedCallback?: (categories: Partial<Categories>) => void;

  // can be used to adjust spacing of UI elements when banner is present
  onBannerVisible?: (height: number) => void; // height - in "px" units
  onBannerNotVisible?: () => void;
  initCallback?: () => void;
}
