import type { IconProps } from './IconProps';

function SvgNinetyNinePlus({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="white" />
      <circle
        cx="12"
        cy="12"
        r="9.88125"
        stroke="#FF5043"
        strokeWidth="4.2375"
      />
      <circle cx="14.45" cy="14.45" r="4.25" fill="black" />
    </svg>
  );
}

export default SvgNinetyNinePlus;
