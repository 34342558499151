export const DBID_KEY = 'dbid';
export const UID_KEY = 'uid';

export function setURLParamInLocalStorage(paramName: string): string | null {
  if (location.search) {
    const urlParams = new URLSearchParams(location.search);
    const paramValue = urlParams.get(paramName);
    if (paramValue) {
      localStorage.setItem(paramName, paramValue);
    }
  }
  return localStorage.getItem(paramName);
}
