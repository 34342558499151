import { atom, useAtomValue, useSetAtom } from 'jotai';
import { WebExtensionProps } from '../types';
import { FetchedAtom } from './types';

type UIWebExtension = WebExtensionProps & {
  connected: boolean;
  loading: boolean;
};

const browserExtensionDataAtom = atom<FetchedAtom<UIWebExtension[]>>({
  data: [],
  loaded: false,
  loading: false,
});

export const useBrowserExtensionData = () =>
  useAtomValue(browserExtensionDataAtom);

export const useSetBrowserExtensionData = () =>
  useSetAtom(browserExtensionDataAtom);

export const connectedBrowserExtensionsAtom = atom((get) => {
  const { data: browserExtensions } = get(browserExtensionDataAtom);
  return browserExtensions.filter((b) => b.connected);
});
