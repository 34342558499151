import CachedStorage from '@mirage/storage/cached-storage';

import type { RawStorage } from '@mirage/storage';

class LocalStorageAdapter<Shape> implements RawStorage<Shape> {
  private namespace: string;
  constructor(namespace: string) {
    this.namespace = namespace;
  }

  async read(): Promise<Shape | undefined> {
    const value = window.localStorage.getItem(this.namespace);
    return value ? JSON.parse(value) : value;
  }

  async write(value: Shape): Promise<void> {
    window.localStorage.setItem(this.namespace, JSON.stringify(value));
  }

  async clear() {
    window.localStorage.removeItem(this.namespace);
  }
}

export default function LocalStorage<Shape>(namespace: string) {
  return new CachedStorage<Shape>(new LocalStorageAdapter<Shape>(namespace));
}
