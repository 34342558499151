function computeTrimSize(limit: number, trimSize: number): number {
  // Use default trim size if not given.
  if (!trimSize) {
    // Use 10% of limit by default.
    const tenPercent = Math.floor(limit / 10);

    // Use 10 as minimum trim size.
    trimSize = Math.max(tenPercent, 10);
  }

  // Make sure trimSize is <= limit.
  trimSize = Math.min(limit, trimSize);

  // Make sure trimSize is >= 1.
  trimSize = Math.max(trimSize, 1);

  return trimSize;
}

/**
 * Array with an auto size limit. Extends Array to make it serializable.
 */
export class SizeLimitedArray<T> extends Array<T> {
  public constructor(
    private readonly limit: number,
    private readonly trimSize = 0,
  ) {
    super();
    this.trimSize = computeTrimSize(limit, trimSize);
  }

  public push(...items: T[]): number {
    super.push(...items);

    if (this.length > this.limit) {
      const newLength = this.limit - this.trimSize;
      const deleteCount = this.length - newLength;

      // Always delete the oldest entries first.
      this.splice(0, deleteCount);
    }

    return this.length;
  }
}

/**
 * Map with an auto size limit. Not using Object because it does not maintain
 * the insertion order.
 */
export class SizeLimitedMap<K, V> extends Map<K, V> {
  public constructor(
    private readonly limit: number,
    private readonly trimSize = 0,
  ) {
    super();
    this.trimSize = computeTrimSize(limit, trimSize);
  }

  public set(key: K, value: V): this {
    super.set(key, value);

    const length = super.size;
    if (length > this.limit) {
      const newLength = this.limit - this.trimSize;
      const deleteCount = length - newLength;

      // Always delete the oldest entries first.
      let i = deleteCount;
      for (const key of super.keys()) {
        super.delete(key);
        if (--i <= 0) break;
      }
    }

    return this;
  }
}
