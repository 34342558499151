// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1466
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'open.dash_settings'
// Description: Fired when a user opens the "Settings" view in Dash Desktop (by pressing CMD+, or the gear icon). Not to be confused with https://pap.pp.dropbox.com/#/events/1363, which tracks viewing specific tabs.
// Owner: otc-growth
export type Open_DashSettings = PAPEventGeneric<
  'dash',
  'open',
  'dash_settings',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
  }
>;

export function PAP_Open_DashSettings(
  properties?: Open_DashSettings['properties'],
): Open_DashSettings {
  return <Open_DashSettings>{
    class: 'dash',
    action: 'open',
    object: 'dash_settings',
    properties,
  };
}
