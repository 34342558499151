import { typeahead } from '@mirage/service-typeahead-search/service/types';
import {
  ONE_DAY_IN_MILLIS,
  ONE_HOUR_IN_MILLIS,
} from '@mirage/shared/util/constants';
import { exponentialDecayScore } from './utils';

export function lastClickedScore(
  result: typeahead.TaggedResult,
  _config: typeahead.Config,
): number {
  const lastClickedMs = result.hits?.mostRecentMs;
  if (!lastClickedMs) return 0.0;

  const delta = Date.now() - lastClickedMs;
  if (delta < ONE_HOUR_IN_MILLIS) return 1;

  const score = exponentialDecayScore(delta / ONE_DAY_IN_MILLIS, {
    maxScore: 0.995,
    minScore: 0.0001,
    exp: -0.15, // a curve that lines up to about .38 at 1 week
  });
  return score;
}
