export const DASH_MARKETING_PAGE = 'https://dash.dropbox.com';
export const DASH_WEBAPP = 'https://www.dash.ai';
export const DASH_WEBAPP_STAGE = 'https://staging.dash.ai';
export const DASH_WEBAPP_HOSTNAME = 'www.dash.ai';
export const DASH_WEBAPP_AMPLIFY_HOSTNAME = 'd2unov8w0g44qf.amplifyapp.com';
export const DASH_WEBAPP_MASTER_HOSTNAME = 'd7kgc1ikry9m3.amplifyapp.com';
export const DASH_WEBAPP_DOMAIN = 'dash.ai';
export const DROPBOX_LOGOUT_URL = 'https://www.dropbox.com/logout';
export const DROPBOX_ONBOARDING_START_URL =
  'https://www.dropbox.com/dash/get-started';
export const DROPBOX_DELETE_ACCOUNT_INSTRUCTIONS =
  'https://help.dropbox.com/account-access/delete-account';

// You may want to use getConnectorsUrl() instead of CONNECTORS_URL.
// eslint-disable-next-line no-restricted-syntax
export const CONNECTORS_URL = 'https://www.dropbox.com/dash/connectors';
export const DASH_CONNECTORS_URL = `${DASH_WEBAPP}/settings/apps`;

export const DESKTOP_DOWNLOAD_URL = 'https://dropbox.com/dash/download';
export const HELP_CENTER =
  'https://dash.dropbox.com/landing?destination=help-center';

export const PRIVACY_AND_LEGAL =
  'https://dash.dropbox.com/landing?destination=privacy';
export const DASH_SECURITY = 'https://dash.dropbox.com/security';

export const AI_LEARN_MORE_URL = 'https://www.dropbox.com/ai-principles';
export const CHROME_EXTENSION_WEB_STORE =
  'https://chromewebstore.google.com/detail/dropbox-dash-beta/aclbkdnphcdelflmgjgmgbahlfngidma';
export const FIREFOX_EXTENSION_WEB_STORE =
  'https://client.dropbox.com/firefox/dash-scout/download/{c98b690b-0b48-4477-9630-081fd7de369d}';
export const EDGE_EXTENSION_WEB_STORE =
  'https://microsoftedge.microsoft.com/addons/detail/dropbox-dash-beta/kblhiphkmkbpcnokbgcnlgcnahnddedl';
export const SAFARI_EXTENSION_WEB_STORE =
  'https://apps.apple.com/us/app/dropbox-dash-for-business/id6446790295';

const DASH_EXTENSION_AUTH_URL =
  'https://www.dropbox.com/save_to_dropbox/web_auth_redirect';

export const isRedirectAllowedWithUrl = (urlOrPath: string): boolean => {
  if (
    urlOrPath.startsWith('/') ||
    urlOrPath.startsWith(DASH_EXTENSION_AUTH_URL)
  ) {
    return true;
  }
  try {
    // creates a new URL object with the current location as the base
    // if urlOrPath is a full URL, base will be ignored
    // otherwise, urlOrPath is used as a path relative to the current location
    const url = new URL(urlOrPath, location.origin);
    if (url.protocol !== 'https:' && url.protocol !== 'http:') {
      return false;
    }
    return url.host === location.host;
  } catch {
    return false;
  }
};
