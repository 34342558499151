// Copied from rSERVER:
// https://dropbox.sourcegraphcloud.com/github.com/dropbox-internal/server@98fb443c63ef573ea7fa4b2f3263b1f1e4beb968/-/blob/metaserver/static/js/catapult/catapult_redirect.tsx?L33-35

// Export for testing only.
export async function getRandomValues(buffer: Uint8Array): Promise<Uint8Array> {
  window.crypto.getRandomValues(buffer);

  return buffer;
}

// Export for testing only.
export function dec2hex(dec: number): string {
  return ('0' + dec.toString(16)).slice(-2);
}

// Export for testing only.
export function stringToBytes(data: string): Uint8Array {
  const result = new Uint8Array(data.length);
  for (let i = 0; i < data.length; i++) {
    result[i] = data.charCodeAt(i);
  }
  return result;
}

// Export for testing only.
export async function hashSHA256(data: ArrayBuffer): Promise<Uint8Array> {
  const encrypted = await window.crypto.subtle.digest('SHA-256', data);

  return new Uint8Array(encrypted);
}

// Export for testing only.
export function b64urlencodeString(data: string): string {
  return btoa(data).replace(/\//g, '_').replace(/\+/g, '-');
}

// PKCE RFC recommends between 43 characters and 128
export async function generatePKCE(
  length: number = 64,
): Promise<{ verifier: string; challenge: string }> {
  const buffer = new Uint8Array(length / 2);

  const randomValues = await getRandomValues(buffer);
  const verifier = Array.from(randomValues, dec2hex).join('');

  const hashedRandomValues = await hashSHA256(stringToBytes(verifier));
  const challenge = b64urlencodeString(
    String.fromCharCode(...new Uint8Array(hashedRandomValues)),
  );

  return { verifier, challenge: challenge.replace('=', '') };
}
