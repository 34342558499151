import { dash_async } from '@dropbox/api-v2-client';
import {
  useEligibilityCheck,
  useUpdateWithCheckResult,
} from '@mirage/eligibility/useEligibilityCheck';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { openURL } from '@mirage/service-platform-actions';
import { useCallback, useEffect, useState } from 'react';

// Conditional hook as component.
export const WebEligibilityCheck: React.FC<{
  getEligibilityFromStore: () => Promise<
    dash_async.ShouldRedirectIneligibleUserResponse | undefined
  >;
  logout: () => Promise<void>;
}> = ({ getEligibilityFromStore, logout }) => {
  const publicAccountId = useDropboxAccount()?.public_account_id;

  const onLogout = useCallback(
    async (redirectUrl: string) => {
      /*
      If a user is ineligible, we want to always:
      - Log them out
      - Open a new tab for the redirectUrl
      */
      openURL(redirectUrl);
      logout();
    },
    [logout],
  );

  const [needCheck, setNeedCheck] = useState<boolean>();
  const updateWithCheckResult = useUpdateWithCheckResult(
    onLogout,
    publicAccountId,
  );

  useEffect(() => {
    if (needCheck !== undefined) {
      return;
    }

    let canceled = false;

    getEligibilityFromStore().then((result) => {
      if (canceled) return;

      if (result) {
        setNeedCheck(false);
        updateWithCheckResult(result);
      } else {
        setNeedCheck(true);
      }
    });

    return () => {
      canceled = true;
    };
  }, [getEligibilityFromStore, needCheck, updateWithCheckResult]);

  return needCheck && publicAccountId ? (
    <PerformCheck onLogout={onLogout} publicAccountId={publicAccountId} />
  ) : null;
};

// Conditional hook as component.
const PerformCheck: React.FC<{
  onLogout: (str: string) => void;
  publicAccountId: string | undefined;
}> = ({ onLogout, publicAccountId }) => {
  useEligibilityCheck(onLogout, publicAccountId);

  return null;
};
