import { UIIcon } from '@dropbox/dig-icons';
import styles from './MetadataItem.module.css';

type MetadataItemProps = {
  title: React.ReactNode;
  iconSrc?: React.ComponentType<React.SVGAttributes<SVGElement>>;
};

export const MetadataItem = ({ title, iconSrc }: MetadataItemProps) => {
  return (
    <div>
      {iconSrc && <UIIcon className={styles.icon} src={iconSrc} size="small" />}
      {title}
    </div>
  );
};
