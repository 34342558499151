// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgMsOneDrive({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.383 18.75a5.417 5.417 0 01-4.177-1.947l11.198-4.699 8.708 4.903c-.767 1.001-1.926 1.743-3.237 1.737-1.977.006-9.582.006-12.492.006z"
        fill="url(#ms-one-drive_svg__paint0_linear)"
      />
      <path
        d="M18.585 10.029l-5.18 2.075 8.707 4.903A4.276 4.276 0 0023 14.396c0-2.394-1.972-4.336-4.412-4.354 0-.005-.002-.009-.003-.013z"
        fill="url(#ms-one-drive_svg__paint1_linear)"
      />
      <path
        d="M5.858 8.233l7.547 3.871 5.18-2.075C17.849 7.139 15.18 5 12.002 5a6.814 6.814 0 00-5.812 3.212 5.545 5.545 0 00-.331.021z"
        fill="url(#ms-one-drive_svg__paint2_linear)"
      />
      <path
        d="M2.206 16.804l11.198-4.7-7.547-3.87C3.13 8.491 1 10.741 1 13.478c0 1.26.452 2.418 1.206 3.325z"
        fill="url(#ms-one-drive_svg__paint3_linear)"
      />
      <defs>
        <linearGradient
          id="ms-one-drive_svg__paint0_linear"
          x1={4.043}
          y1={17.833}
          x2={19.721}
          y2={16.877}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2086B8" />
          <stop offset={1} stopColor="#46D3F6" />
        </linearGradient>
        <linearGradient
          id="ms-one-drive_svg__paint1_linear"
          x1={17.383}
          y1={14.396}
          x2={21.77}
          y2={11.33}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1694DB" />
          <stop offset={1} stopColor="#62C3FE" />
        </linearGradient>
        <linearGradient
          id="ms-one-drive_svg__paint2_linear"
          x1={6.852}
          y1={5.917}
          x2={17.043}
          y2={11.83}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D3D78" />
          <stop offset={1} stopColor="#063B83" />
        </linearGradient>
        <linearGradient
          id="ms-one-drive_svg__paint3_linear"
          x1={0.766}
          y1={14.625}
          x2={11.012}
          y2={10.82}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#16589B" />
          <stop offset={1} stopColor="#1464B7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgMsOneDrive;
