import classnames from 'classnames';
import styles from './ChecklistProgressBar.module.css';

type ChecklistProgressBarProps = {
  value: number;
  className?: string;
};

export const ChecklistProgressBar = ({
  value,
  className,
}: ChecklistProgressBarProps) => {
  return (
    <progress
      className={classnames(styles.progressBar, className)}
      value={value}
      max="100"
    ></progress>
  );
};
