// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1363
import { DashSettingsSection } from '../enums/dash_settings_section';

import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'view.dash_settings'
// Description: Fired whenever a user views one of the Settings tabs in the Dash Desktop application.
// Owner: otc-growth
export type View_DashSettings = PAPEventGeneric<
  'dash',
  'view',
  'dash_settings',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // A section in the Dash settings view.
    dashSettingsSection?: DashSettingsSection;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
  }
>;

export function PAP_View_DashSettings(
  properties?: View_DashSettings['properties'],
): View_DashSettings {
  return <View_DashSettings>{
    class: 'dash',
    action: 'view',
    object: 'dash_settings',
    properties,
  };
}
