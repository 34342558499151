import {
  TextInput,
  TextInputRefObject,
} from '@dropbox/dig-components/text_fields';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine, SearchLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { KeyCodes } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import styles from './FullWidthSearchBar.module.css';

export const FullWidthSearchBar: React.FC<{
  textInputRef: React.MutableRefObject<TextInputRefObject | null>;
  placeholder?: string;
  inputString: string;
  setInputString: (input: string) => void;
  handleMenuClose: () => void;
}> = ({
  textInputRef,
  placeholder,
  inputString,
  setInputString,
  handleMenuClose,
}) => {
  const isMobileSize = useIsMobileSize();

  // This is a bit counter-intuitive. Make the font bigger for
  // mobile size because in iOS Safari, the browser will auto-zoom
  // the entire page when the input font is too small.
  const inputSize = isMobileSize ? 'large' : 'medium';

  return (
    <Box paddingX="Micro Small" className={styles.fullWidthWrapper}>
      <div className={styles.box}>
        <div className={styles.searchInputContainer}>
          <TextInput
            wrapperProps={{ className: styles.searchTextInputWrapper }}
            isTransparent
            ref={textInputRef}
            size={inputSize}
            value={inputString}
            onChange={(e) => setInputString(e.target.value)}
            onKeyDown={(e) => {
              // if pressing esc, close the menu
              if (e.key === KeyCodes.escape) {
                handleMenuClose();
              }
            }}
            placeholder={placeholder}
            withLeftAccessory={
              <UIIcon src={SearchLine} className={styles.searchIcon} />
            }
            withRightAccessory={
              <IconButtonWithTooltip
                tooltipProps={{
                  title: i18n.t('close'),
                }}
                variant="transparent"
                onClick={handleMenuClose}
              >
                <UIIcon src={CloseLine} />
              </IconButtonWithTooltip>
            }
          />
        </div>
      </div>
    </Box>
  );
};
