import { IconButton } from '@dropbox/dig-components/buttons';
import {
  IconButtonGroup,
  LabelGroup,
} from '@dropbox/dig-components/combinations';
import { List } from '@dropbox/dig-components/list';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { CopyLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { copyToClipboard } from '@mirage/service-product-logging';
import { LocalPAPEvent } from '@mirage/service-product-logging/service';
import dompurify from 'dompurify';
import get from 'lodash/get';
import * as React from 'react';
import styles from './EventItem.module.css';

type Props = {
  event: LocalPAPEvent;
};

export default function EventItem({ event }: Props) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  let feature = '';
  if (event.action === 'exposure') {
    feature = get(event.properties, 'feature') || '';
  }
  const eventTitle = `${event.action}.${event.object}`;
  const eventDate = new Date(event.time);
  const onRowClick = () => {
    setIsExpanded(!isExpanded);
  };
  const onCopyClick = () => {
    copyToClipboard(event);
  };
  return (
    <div className={styles.listItem}>
      <div className={styles.listItemContent}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div className={styles.eventListContent} onClick={onRowClick}>
          <LabelGroup
            withText={
              <>
                {eventTitle}
                {feature && ' - '}
                {feature && <Text isBold>{feature}</Text>}
              </>
            }
            withSubtext={eventDate.toString()}
          />
        </div>
        <List.Accessory>
          <IconButtonGroup>
            {() => (
              <>
                <IconButton
                  aria-label="Copy"
                  variant="borderless"
                  onClick={onCopyClick}
                >
                  <UIIcon src={CopyLine} />
                </IconButton>
              </>
            )}
          </IconButtonGroup>
        </List.Accessory>
      </div>
      {isExpanded && (
        <Text
          className={styles.pre}
          tagName="pre"
          monospace
          size="small"
          // This is only okay to use because the contents are being sanitized by DOMPurify
          // before being rendered on the page.
          dangerouslySetInnerHTML={{
            __html: event.properties
              ? dompurify.sanitize(
                  syntaxHighlight(
                    JSON.stringify(event.properties || {}, null, 2),
                  ),
                )
              : '{}',
          }}
        />
      )}
    </div>
  );
}

function syntaxHighlight(json: string) {
  json = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
  return json.replace(
    new RegExp(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    ),
    function (match) {
      let cls = styles.number;
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = styles.key;
        } else if (/^"(\w+-){4}\w+"$/.test(match)) {
          cls = styles.uuid;
        } else if (/true|false/.test(match)) {
          cls = styles.boolean;
        } else {
          cls = styles.string;
        }
      } else if (/true|false/.test(match)) {
        cls = styles.boolean;
      } else if (/null/.test(match)) {
        cls = styles.null;
      }
      return '<span class="' + cls + '">' + match + '</span>';
    },
  );
}
