import { useConsolidatedSubscription } from '@mirage/shared/hooks/useConsolidatedSubscription';
import { getCombineAsyncRequestsFunc } from '@mirage/shared/util/combine-async-requests';
import { atom, useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import {
  getAllOnboardingValues,
  getLatestWelcomeModalComplete,
  isUsersDay0,
  markDashWelcomeModalCompleteIfNecessary,
  setOnboardingValue,
  setOnboardingValue as setOnboardingValueService,
  subscribeToOnboardingValues,
} from '.';
import { OnboardingStorage } from './service';

const onboardingValuesAtom = atom<OnboardingStorage | undefined>(undefined);

// We only need to fetch the initial values once.
const getAllOnboardingValuesCombined = getCombineAsyncRequestsFunc(
  getAllOnboardingValues,
);

export function useOnboardingValues(): {
  onboardingValues: OnboardingStorage | undefined;
  getOnboardingValue: <K extends keyof OnboardingStorage>(
    key: K,
  ) => Promise<OnboardingStorage[K]>;
  setOnboardingValue: <K extends keyof OnboardingStorage>(
    key: K,
    value: OnboardingStorage[K],
  ) => Promise<void>;
} {
  const [onboardingValues, setOnboardingValues] = useAtom(onboardingValuesAtom);

  useConsolidatedSubscription({
    hookName: 'useOnboardingValues',
    setState: setOnboardingValues,
    getInitialData: getAllOnboardingValuesCombined,
    subscribe: subscribeToOnboardingValues,
  });

  const getOnboardingValue = useCallback(
    async <K extends keyof OnboardingStorage>(
      key: K,
    ): Promise<OnboardingStorage[K]> => {
      const values =
        onboardingValues ?? (await getAllOnboardingValuesCombined());
      return values[key];
    },
    [onboardingValues],
  );

  const setOnboardingValue = useCallback(
    async <K extends keyof OnboardingStorage>(
      key: K,
      value: OnboardingStorage[K],
    ): Promise<void> => {
      setOnboardingValues({
        ...(onboardingValues ?? (await getAllOnboardingValuesCombined())),
        [key]: value,
      });
      setOnboardingValueService(key, value);
    },
    [onboardingValues, setOnboardingValues],
  );

  return { onboardingValues, getOnboardingValue, setOnboardingValue };
}

export function useIsUsersDay0() {
  const [isDay0, setIsUsersDay0] = useState(false);

  useEffect(() => {
    const doSetDay0 = async () => {
      const value = await isUsersDay0();
      setIsUsersDay0(value);
    };
    doSetDay0();
  }, []);
  return isDay0;
}

export function useWelcomeModalDismissed(modalVersion: number) {
  const [dismissed, setDismissed] = useState(true);

  useEffect(() => {
    const doSetDismissed = async () => {
      const value = await getLatestWelcomeModalComplete();
      setDismissed(!!value && value >= modalVersion);
    };
    doSetDismissed();
  }, [modalVersion]);

  const handleDismissed = async () => {
    setDismissed(true);
    await markDashWelcomeModalCompleteIfNecessary(modalVersion);
    setOnboardingValue('latestDismissedWelcomeModal', modalVersion);
  };

  return { dismissed, setDismissed: handleDismissed };
}
