/**
 * Manually dispatch the element_clicked event.
 * To be used in instances where you cannot add data-uxa-log direclty to the dom
 * Taken from rServer: https://sourcegraph.pp.dropbox.com/server/-/blob/metaserver/static/js/ux_analytics/utils.ts
 */
export const dispatchElementClicked = (
  elementId: string,
  entityId?: string,
  traceId?: string,
) => {
  const eventButton = document.createElement('button');
  eventButton.setAttribute('data-uxa-log', elementId);
  if (entityId) {
    eventButton.setAttribute('data-uxa-entity-id', entityId);
  }
  if (traceId) {
    eventButton.setAttribute('data-uxa-trace-id', traceId);
  }
  document.body.appendChild(eventButton);
  eventButton.style.visibility = 'hidden';
  eventButton.dispatchEvent(
    new MouseEvent('mousedown', {
      view: window,
      bubbles: true,
      cancelable: true,
    }),
  );
  document.body.removeChild(eventButton);
};
