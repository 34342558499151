// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2622
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'open.dash_suggested_link'
// Description: Fires when a user clicks a suggested link to open it
// Owner: dash-web
export type Open_DashSuggestedLink = PAPEventGeneric<
  'stacks',
  'open',
  'dash_suggested_link',
  {
    // Generated random request id for tracking suggestions in Dash
    dashRequestId?: string;
    // A hashed integer for unique predictions recommended in Dash
    predictionIdHash?: number;
    // A hashed integer for items/content recommended in Dash
    itemIdHash?: number;
    // unique session id for creating stacks on dash
    createStackSessionId?: string;
    // Hashed URLs accessed by Dash. These are pre-hashed.
    dashUrlHash?: string;
  }
>;

export function PAP_Open_DashSuggestedLink(
  properties?: Open_DashSuggestedLink['properties'],
): Open_DashSuggestedLink {
  return <Open_DashSuggestedLink>{
    class: 'stacks',
    action: 'open',
    object: 'dash_suggested_link',
    properties,
  };
}
