import { Text } from '@dropbox/dig-components/typography';
import { TitleToken } from '@mirage/shared/search/url-shortcut';
import classnames from 'classnames';
import styles from './ShortcutTitle.module.css';

import type { ReactNode } from 'react';

type ShortcutTitleProps = {
  titles: TitleToken[];
  selected?: boolean;
};

export const ShortcutTitle = ({
  titles,
  selected = false,
}: ShortcutTitleProps) => {
  return (
    <>
      {titles.map((component, i) => {
        if (component.type === 'string') {
          return <Text key={i}>{component.value}</Text>;
        }

        return (
          <div
            className={classnames(styles.paramContainer, {
              [styles.selectedContainer]: selected,
            })}
            key={i}
          >
            <Text isBold={true}>{component.value}</Text>
          </div>
        );
      })}
    </>
  );
};

export function ShortcutSubtitle({ subtitle }: { subtitle: ReactNode }) {
  return <Text color="subtle">{subtitle}</Text>;
}
