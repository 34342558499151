import { Avatar } from '@dropbox/dig-components/avatar';
import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { AppsLine } from '@dropbox/dig-icons/assets';
import styled from '@emotion/styled';
import React from 'react';

export default function HeaderActions() {
  return (
    <ActionsContainer>
      <IconButton isRounded variant="filled">
        <UIIcon src={AppsLine} />
      </IconButton>
      <Avatar isGuest />
    </ActionsContainer>
  );
}

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-left: 16px;
`;
