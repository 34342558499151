import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';
import { Service } from './service';
import { ActivityFeedFilters } from './types';

const service = services.get<Service>(ServiceId.FEED);

// gets the latest loaded activity cards
export const activityFeed = service.activityFeed;

// refreshes the user's feed
export const refreshActivityFeed = () => {
  return rx.firstValueFrom(service.refreshActivityFeed());
};

export const loadMoreActivities = () => {
  return rx.firstValueFrom(service.loadMoreActivities());
};

export const setActivityFeedFilters = (filters: ActivityFeedFilters) => {
  return rx.firstValueFrom(service.setActivityFeedFilters(filters));
};
