// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2522
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'open.stacks_page'
// Description: Fires when a user clicks the chat button to open the all stacks page
// Owner: dash-web
export type Open_StacksPage = PAPEventGeneric<
  'stacks',
  'open',
  'stacks_page',
  never
>;

export function PAP_Open_StacksPage(
  properties?: Open_StacksPage['properties'],
): Open_StacksPage {
  return <Open_StacksPage>{
    class: 'stacks',
    action: 'open',
    object: 'stacks_page',
    properties,
  };
}
