import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const previousPathAtom = atom<string>('');

export function usePreviousPath(withTracking = true) {
  const [previousPath, setPreviousPath] = useAtom(previousPathAtom);

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (withTracking) {
      setPreviousPath(pathname);
    }
  }, [pathname, setPreviousPath, withTracking]);

  return { previousPath };
}
