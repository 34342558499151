import { atom } from 'jotai';

const DefaultExtensionConnectedState = {
  connected: false,
  extensionId: '',
  browser: '',
  name: '',
};

export type ExtensionConnectedState = typeof DefaultExtensionConnectedState;

export const extensionConnectedStateAtom = atom(DefaultExtensionConnectedState);
