// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgSalesforce({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.436 17.683a4.002 4.002 0 005.438-2.684L19 15a5 5 0 10-2.675-9.225A3.996 3.996 0 0013 4a3.986 3.986 0 00-2.716 1.063A4.5 4.5 0 002.39 9.336a4 4 0 002.805 7.482 4.502 4.502 0 008.24.865z"
        fill="#00A1E0"
      />
    </svg>
  );
}

export default SvgSalesforce;
