import {
  HighFiveMini,
  LockMini,
  MailboxMini,
} from '@dropbox/dig-illustrations';
import {
  RedirectModal,
  RedirectModalProps,
} from '@mirage/growth/components/RedirectModal/RedirectModal';
import { tagged } from '@mirage/service-logging';
import { openURL } from '@mirage/service-platform-actions';
import { DROPBOX_ONBOARDING_START_URL } from '@mirage/shared/urls';
import i18n from '@mirage/translations';
import { useState } from 'react';
import { RedirectVariant } from './types';

const logger = tagged('FullPageRedirect');

interface Props {
  variant: RedirectVariant;
}

const isOnWebOnboarding = (pathname: string, protocol: string) =>
  // allow /authconfirm so users can create connections during onboarding
  pathname == '/authconfirm' ||
  // Always show for desktop
  (pathname.toLowerCase().includes('/setup') &&
    protocol.toLowerCase().startsWith('http'));

export const FullPageRedirect = ({ variant }: Props) => {
  const [dismissed, setDismissed] = useState(false);

  const modalConfig = {
    [RedirectVariant.CountryBlock]: {
      asset: <LockMini altText={i18n.t('lock')} />,
      description: i18n.t('blocked_country_description'),
      title: i18n.t('blocked_country_title'),
    },
    [RedirectVariant.GenericBlock]: {
      asset: <LockMini altText={i18n.t('lock')} />,
      description: i18n.t('blocked_generic_description'),
      title: i18n.t('blocked_generic_title'),
    },
    [RedirectVariant.PendingInviteBlock]: {
      asset: <MailboxMini altText={i18n.t('mailbox')} />,
      description: i18n.t('blocked_pending_invite_description'),
      title: i18n.t('blocked_pending_invite_title'),
    },
    [RedirectVariant.DfbOnboarding]: {
      asset: <HighFiveMini altText={i18n.t('high_five')} />,
      description: i18n.t('blocked_onboarding_description'),
      title: i18n.t('blocked_onboarding_title'),
      primaryCta: {
        label: i18n.t('blocked_onboarding_cta'),
        onClick: () => {
          setDismissed(true);
          openURL(DROPBOX_ONBOARDING_START_URL);
        },
      },
    },
  };

  const getConfigForVariant = (
    variant: RedirectVariant,
  ): (RedirectModalProps & { variant: RedirectVariant }) | undefined => {
    const config = modalConfig[variant];

    if (!config) {
      logger.error(
        `User navigated to invalid redirect page variant: ${variant}`,
      );
      return;
    }

    return { ...config, variant };
  };

  const props = getConfigForVariant(variant);
  const isWebOnboardingPage = isOnWebOnboarding(
    window.location.pathname,
    window.location.protocol,
  );

  // Don't show onboarding redirect modal if on onboarding already
  if (isWebOnboardingPage && variant === RedirectVariant.DfbOnboarding) {
    return null;
  }

  if (dismissed || !props) {
    return null;
  }

  return <RedirectModal {...props} />;
};
