import {
  authenticate,
  checkAndRefreshAccessToken,
  listen,
} from '@mirage/service-auth';
import { tagged } from '@mirage/service-logging';
import Sentry from '@mirage/shared/sentry';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
// TODO (Matt): cleanup, these should not be importing from service
import { AuthenticationStatus } from './service';

const logger = tagged('useDropboxAuthentication');

// Lightweight hook to check current login state.
const isLoggedInAtom = atom<boolean | undefined>(undefined);

/**
 * This hook will also init the access token, so should only be called once.
 * Please use useIsLoggedIn() if you only need the login status.
 */
export default function useDropboxAuthentication() {
  const [status, setStatus] = useState<AuthenticationStatus>(
    AuthenticationStatus.Initializing,
  );

  // Minimize re-renders when the value has not changed.
  const lastIsLoggedIn = useRef<boolean>();
  const setLoggedIn = useSetAtom(isLoggedInAtom);

  // listen for changes to our auth state
  useEffect(() => {
    const subscription = listen().subscribe(async (result) => {
      setStatus(result.status);

      const complete = result.status === AuthenticationStatus.Complete;
      logger.debug(
        `auth status change detected: status=${result.status}, complete=${complete}`,
      );

      if (lastIsLoggedIn.current !== complete) {
        logger.debug('detected status change, setting atom =', complete);

        lastIsLoggedIn.current = complete;
        setLoggedIn(complete);
      }
    });

    checkAndRefreshAccessToken()
      .catch((e) => {
        logger.error('check and refresh rejected', e);
        Sentry.withScope((scope) => {
          scope.setContext('checkAndRefreshAccessToken() error context', {
            originalException: e,
          });
          Sentry.captureException(e);
          Sentry.captureMessage(
            'checkAndRefreshAccessToken() - failed',
            'error',
            {
              originalException: e,
            },
            scope,
          );
        });
        return false;
      })
      .then(async (isLoggedIn) => {
        logger.debug('check and refresh completed, logged in =', isLoggedIn);

        if (lastIsLoggedIn.current !== isLoggedIn) {
          logger.debug('detected status change, setting atom =', isLoggedIn);

          lastIsLoggedIn.current = isLoggedIn;
          setLoggedIn(isLoggedIn);
        }

        if (isLoggedIn) {
          logger.debug('setting status to logged in');
          setStatus(AuthenticationStatus.Complete);
        } else {
          logger.debug('setting status to none');
          setStatus(AuthenticationStatus.None);
        }
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [setLoggedIn]);

  return {
    status,
    authenticate,
  };
}

/** Lightweight, auto-updating hook to listen for login status. */
export function useIsLoggedIn(): boolean | undefined {
  return useAtomValue(isLoggedInAtom);
}
