import { useState } from 'react';

export const enum PageStates {
  Init = 'init',
  Clean = 'clean',
  Dirty = 'dirty',
}

export const usePages = () => {
  const [pageState, setPageState] = useState<PageStates>(PageStates.Init);

  const setInit = () => setPageState(PageStates.Init);
  const setClean = () => setPageState(PageStates.Clean);
  const setDirty = () => setPageState(PageStates.Dirty);

  return { pageState, setInit, setDirty, setClean };
};
