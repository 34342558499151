import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import classnames from 'classnames';
import styles from './StacksEmptyState.module.css';

type Props = {
  className?: string;
  title: string;
  message: string;
  callToActionLabel?: string;
  callToAction?: () => void;
  imgSrc: string;
};

export default function StacksEmptyState({
  className,
  title,
  message,
  callToAction,
  callToActionLabel,
  imgSrc,
}: Props) {
  const handleCallToAction = () => {
    callToAction?.();
  };

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.textContentContainer}>
        <div>
          <Text className={styles.title} size="large" tagName="p" isBold>
            {title}
          </Text>
          <Text className={styles.message} size="medium" tagName="p">
            {message}
          </Text>
          {callToActionLabel && (
            <Button variant="opacity" onClick={handleCallToAction}>
              {callToActionLabel}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.assetContainer}>
        <img alt="" src={imgSrc} />
      </div>
    </div>
  );
}
