// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgNotion({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4.636C2 3.733 2.746 3 3.667 3h10C18.269 3 22 6.663 22 11.182v5.727C22 19.17 20.134 21 17.833 21H8.667C4.985 21 2 18.07 2 14.454V4.636z"
        fill="#fff"
      />
      <path
        d="M8.337 10.143v-.156c0-.394.318-.722.728-.749l2.984-.198 4.127 6.045V9.78l-1.062-.14v-.075c0-.399.325-.729.74-.75l2.715-.138v.389a.38.38 0 01-.323.37l-.654.11v8.593l-.82.28a1.596 1.596 0 01-1.84-.605l-4.006-6.014v5.74l1.233.235-.017.113a.77.77 0 01-.738.643l-3.067.136a.708.708 0 01.647-.768l.403-.042v-7.654l-1.05-.059z"
        fill="#0B0B0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.604 2.006L3.307 2.84C2.571 2.89 2 3.48 2 4.19v11.897c0 .667.23 1.315.656 1.842l2.651 3.286a2.146 2.146 0 001.801.78l13.648-.835c.7-.043 1.244-.6 1.244-1.274V6.359c0-.417-.212-.807-.567-1.046l-4.32-2.906a2.414 2.414 0 00-1.51-.401zM3.917 4.376c-.17-.127-.09-.39.125-.404l11.645-.831c.37-.027.74.075 1.04.286l2.335 1.647c.09.063.047.197-.062.203l-12.331.668a1.606 1.606 0 01-1.039-.308L3.917 4.375zm2.173 3.66c0-.399.324-.728.738-.75l13.037-.706c.404-.022.743.286.743.675v11.712a.765.765 0 01-.736.75l-12.954.733c-.45.026-.828-.317-.828-.75V8.036z"
        fill="#0B0B0B"
      />
    </svg>
  );
}

export default SvgNotion;
