import type { DashSurface } from '@mirage/analytics/events/enums/dash_surface';

// As per Dropbox's security policy, do NOT add emails as an attribute
export type SurveyCoreAttributes = {
  dashSurface?: DashSurface;
  isInternal?: boolean;
};

// Additional attributes that can be sent to Sprig
// Populated from getSprigAttributesList Apiv2 endpoint
export type SurveyAttributes = SurveyCoreAttributes & {
  [key: string]: string | number | boolean;
};

export const enum SurveyEvent {
  AppOpened = 'dash_open_app',
  SearchComplete = 'dash_search_complete',
  DebugTriggered = 'dash_debug_trigger',
  OpenStartPage = 'dash_open_start_page',
  OpenStackPage = 'dash_stacks_page_open',
}

export type SprigPublishEvent = {
  event: SurveyEvent;
  attributes: SurveyAttributes;
};

export type SprigPublishEventInternal = SprigPublishEvent & {
  userId?: string;
};
