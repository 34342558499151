import { ReportPapEventFn } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Finish_DashOnboarding } from '@mirage/analytics/events/types/finish_dash_onboarding';
import { tagged } from '@mirage/service-logging';
import {
  isUsersFirstLogin,
  markDashUserDfbOnboardingCompleteIfNecessary,
  markDashUserDfIOnboardingCompleteIfNecessary,
  markDashUserFirstUseTimestamp,
} from '@mirage/service-onboarding';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { useNavigate } from 'react-router-dom';

import type { OnboardingVariant } from '@mirage/analytics/events/enums/onboarding_variant';

const logger = tagged('onboarding');

export const markUserDoneOnboarding = async (
  reportPapEvent: ReportPapEventFn,
  variant: string,
  is_dfb_onboarding?: boolean,
) => {
  try {
    await reportPapEvent(
      PAP_Finish_DashOnboarding({
        onboardingVariant: variant.toLowerCase() as OnboardingVariant,
      }),
      true,
    );

    if (is_dfb_onboarding) {
      await markDashUserDfbOnboardingCompleteIfNecessary(variant);
    } else {
      await markDashUserDfIOnboardingCompleteIfNecessary(variant);
    }
  } catch (e) {
    logger.error('Failed to mark user done onboarding: ', e);
  }
};

export const useFinishOnboarding = (
  reportPapEvent: ReportPapEventFn,
  onboardingVariant: string,
  is_dfb_onboarding?: boolean,
) => {
  const navigate = useNavigate();

  return async () => {
    await markUserDoneOnboarding(
      reportPapEvent,
      onboardingVariant,
      is_dfb_onboarding,
    );
    const firstTimeUser = await isUsersFirstLogin();
    if (firstTimeUser) await markDashUserFirstUseTimestamp();
    navigate(RoutePath.ROOT);
  };
};
