// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2037
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashAnnotationMode } from '../enums/dash_annotation_mode';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { EndReason } from '../enums/end_reason';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'finish.dash_answers'
// Description: Dash Answers - tracks dwell time on an individual answer.
// Owner: dash-web
export type Finish_DashAnswers = PAPEventGeneric<
  'dash',
  'finish',
  'dash_answers',
  {
    // Time spent on a page
    dwellTime?: string;
    // The ID of the Dash answers session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashAnswersSessionId?: string;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // **DEPRECATED** - this should have been a string.
    // Dash Answers - this will be a randomly generated ID associated with each answer, used for logging purposes to track success/dwell time on answers page.
    dashAnswerId?: number;
    // The number of sources associated with a dash answer. This will be a count, likely 1-3 sources with any given answer.
    numAnswerSources?: number;
    // Dash Answers - number of questions asked in an answers session. This is a COUNT.
    numQuestionsAsked?: number;
    // The reason the session ended
    endReason?: EndReason;
    // the start time of the event in ms
    startTimeMs?: number;
    // the end time of the event in ms
    endTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // Dash Answers - this will be a randomly generated ID associated with each answer, used for logging purposes to track success/dwell time on answers page.
    answerId?: string;
    // unique id for a /dash/search query. Used by the ML team to get feedback on search result ranking
    searchRequestId?: string;
    // unique id for a /dash/conversation query. Used by the ML team to get feedback on answer quality
    dashAnswerRequestId?: string;
    entryPoint?: string;

    // Important note: The below properties are L0 properties that are manually added and MUST be re-added after running the PAP codegen
    // These properties will be properly handled with PAP-1249 (https://jira.dropboxer.net/browse/PAP-1249)
    queryString?: string;
    answerString?: string;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // The surface in which an action was taken on Dash.
    dashActionSurface?: DashActionSurface;
    // The annotation mode (if user is annotating).
    dashAnnotationMode?: DashAnnotationMode;
  }
>;

export function PAP_Finish_DashAnswers(
  properties?: Finish_DashAnswers['properties'],
): Finish_DashAnswers {
  return <Finish_DashAnswers>{
    class: 'dash',
    action: 'finish',
    object: 'dash_answers',
    properties,
  };
}
