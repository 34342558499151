import { tagged } from '@mirage/service-logging';
import { namespace } from '@mirage/service-operational-metrics';
import instrument from '@mirage/service-typeahead-search/service/utils/instrument';
import * as rx from 'rxjs';

import type { typeahead } from '@mirage/service-typeahead-search/service/types';

const metrics = namespace('typeahead-search');

export default function wrapper(
  name: string,
  wrapping: typeahead.TaggedSearch,
): typeahead.TaggedSearch {
  const logger = tagged(`typeahead-search/performance/${name}`);

  return (...args) => {
    return instrument(
      rx.defer(() => wrapping(...args)),
      (stats) => {
        const DEBUG = false;
        if (DEBUG) logger.debug('source completed:', name, stats);

        // log search success
        metrics.counter('search-source/status', 1, {
          name: name,
          status: stats.failed ? 'error' : 'success',
        });

        // log search duration
        metrics.stats('search-source/duration', stats.duration, {
          name: name,
        });

        // log # results
        metrics.stats('search-source/result_count', stats.emissions, {
          name: name,
        });
      },
    );
  };
}
