import { stacks } from '@dropbox/api-v2-client';
import { Button } from '@dropbox/dig-components/buttons';
import { Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ArrowDownLine,
  ArrowUpLine,
  CloseLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import {
  StackSortDirection,
  StackSortOption,
} from '@mirage/service-settings/service/types';
import { useStackByIdSortSettings } from '@mirage/service-settings/useStackByIdSortSettings';
import { stackItemGetName } from '@mirage/service-stacks/service/utils';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import {
  activeStackAtom,
  activeStackItemShortcutsAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import { useSortedStacks } from '@mirage/stacks/hooks';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import orderBy from 'lodash/orderBy';
import { StackSortOptionMetadata } from '../utils';
import { NoSectionsStackItems } from './NoSectionsStackItems';
import styles from './SortedStackItemsSection.module.css';

export const SortedStackItemsSection = ({
  onEditItem,
}: {
  onEditItem: (item: stacks.StackItemShortcut) => void;
}) => {
  const stack = useAtomValue(activeStackAtom);
  const allStacks = useSortedStacks();
  const { sortPreference, setStackByIdSortPreference } =
    useStackByIdSortSettings(stack?.namespace_id ?? '');
  const items = useAtomValue(activeStackItemShortcutsAtom);

  let sortedItems = items;
  if (sortPreference) {
    switch (sortPreference.option) {
      case StackSortOption.ALPHA:
        sortedItems = orderBy(
          items,
          [(item) => stackItemGetName(item, allStacks).toLowerCase()],
          [
            sortPreference.direction === undefined ||
            sortPreference.direction === StackSortDirection.ASC
              ? 'asc'
              : 'desc',
          ],
        );
        break;
      case StackSortOption.RECENT:
        sortedItems = orderBy(
          items,
          [(item) => item.last_modified_time_utc_sec],
          [
            sortPreference.direction === undefined ||
            sortPreference.direction === StackSortDirection.DESC
              ? 'desc'
              : 'asc',
          ],
        );
        break;
    }
  }

  if (!stack || items?.length === 0 || !sortedItems || !sortPreference) {
    return null;
  }

  return (
    <NoSectionsStackItems
      stack={stack}
      items={sortedItems}
      header={
        <>
          <div className={styles.titleContainer}>
            <Title
              className={classnames(styles.titleSizeXSmall)}
              weightVariant="emphasized"
              color="subtle"
            >
              {i18n.t('sort_option_sorting_by')}:
            </Title>
            <Title
              className={classnames(
                styles.sortTitleLabel,
                styles.titleSizeXSmall,
              )}
              weightVariant="emphasized"
            >
              {
                StackSortOptionMetadata[
                  sortPreference.option as keyof typeof StackSortOptionMetadata
                ].label
              }
              <IconButtonWithTooltip
                tooltipProps={{
                  title: i18n.t('sort_option_toggle_direction'),
                }}
                variant="borderless"
                size="small"
                onClick={() =>
                  setStackByIdSortPreference({
                    ...sortPreference,
                    direction:
                      sortPreference.direction === StackSortDirection.DESC
                        ? StackSortDirection.ASC
                        : StackSortDirection.DESC,
                  })
                }
              >
                <UIIcon
                  src={
                    sortPreference.direction === StackSortDirection.DESC
                      ? ArrowDownLine
                      : ArrowUpLine
                  }
                  size="small"
                  color="var(--dig-color__border__base)"
                />
              </IconButtonWithTooltip>
            </Title>
          </div>
          <Button
            variant="borderless"
            size="standard"
            onClick={() => setStackByIdSortPreference()}
            withIconStart={<UIIcon src={CloseLine} />}
          >
            {i18n.t('sort_option_clear')}
          </Button>
        </>
      }
      onEditItem={onEditItem}
    />
  );
};
