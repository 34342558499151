import { useEffect, useState } from 'react';
import { recentBrowserHistory, recentEntities } from '..';
import { RecentData } from '../service';
import { RecentBrowserContent, RecentConnectorContent } from '../types';

export const useRecentBrowserHistory = (): RecentData<RecentBrowserContent> => {
  const [_recentData, setRecentData] = useState<
    RecentData<RecentBrowserContent>
  >({
    content: [],
    isLoading: true,
  });

  useEffect(() => {
    const sub = recentBrowserHistory().subscribe(setRecentData);
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return _recentData;
};

export const useRecentEntities = (): RecentData<RecentConnectorContent> => {
  const [_recentData, setRecentData] = useState<
    RecentData<RecentConnectorContent>
  >({
    content: [],
    isLoading: true,
  });

  useEffect(() => {
    const sub = recentEntities().subscribe(setRecentData);

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return _recentData;
};
