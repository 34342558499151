import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const globalNavIsCollapsedAtom = atomWithStorage(
  'globalNavIsCollapsed',
  true,
  undefined,
  // Make sure the initial state is correct. Otherwise the globalnav will
  // flicker on load in the browser extension.
  { getOnInit: true },
);

export const globalNavIsMobileAtom = atom(false);
