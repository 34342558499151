import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import i18n from '@mirage/translations';

export const DeleteConfirmationModal = ({
  onSubmit,
  onCancel,
  isOpen,
}: {
  onSubmit: () => void;
  onCancel: () => void;
  isOpen: boolean;
}) => {
  return (
    <Modal open={isOpen} withCloseButton="Close" onRequestClose={onCancel}>
      <Modal.Header hasBottomSpacing="title-standard">
        <Modal.Title>{i18n.t('confirm_delete_stack_title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body hasVerticalSpacing={true}>
        {i18n.t('confirm_delete_stack_body')}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="opacity" onClick={onCancel}>
          {i18n.t('cancel')}
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          {i18n.t('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
