/** The minimum amount of data needed in a bolt data packet. */
export type BoltData = {
  boltToken: string;
  boltUniqueId: string;
  revision: number;
};

/**
 * A ChannelId identifies a particular channel, which comprises an app id and a unique id.
 * It is passed to the application code in some callbacks, but should not need to be generated.
 */
export type ChannelId = {
  app_id: string;
  unique_id: string;
};

/**
 * A SignedChannelState represents a particular revision on a particular channel. A channel
 * comprises an app id, and a unique id that represents a logical channel within the
 * application. The app id is used as a namespacing mechanism for Bolt. Examples of channels:
 *   - (app id: "file_activity", unique id: FileId of a file to watch updates for)
 *   - (app id: "sfj_ping", unique id: nsid to watch updates for)
 *   - (app id: "user_notification", unique id: user id to watch updates for)
 * The revision is a string encoding a monotonically increasing integer representing the
 * latest known state of the channel. When used to subscribe for notifications, acts as
 * a starting point for the channel so that only notifications with a revision greater than
 * the starting point are returned to the application.
 */
export type SignedChannelState = {
  channel_id: ChannelId;
  revision: string;
  token: string;
};

/**
 * Convert ChannelId into a string representation.
 * @param channelId
 * @returns
 */
export function getChannelIdString(channelId: ChannelId): string {
  return `${channelId.app_id}:${channelId.unique_id}`;
}

/**
 * Create a ChannelId from a string.
 * @param uniqueId
 * @returns
 */
function createChannelId(appId: string, uniqueId: string): ChannelId {
  return {
    app_id: appId,
    unique_id: uniqueId,
  };
}

// exported for test only
export function _boltDataToSignedChannelState<DataType extends BoltData>(
  appId: string,
  data: DataType,
): SignedChannelState {
  return {
    channel_id: createChannelId(appId, data.boltUniqueId),
    revision: String(data.revision),
    token: data.boltToken,
  };
}

/**
 * Compares two string-encoded revisions
 * Returns -1 if rev1 < rev2, 0 if rev1 = rev2, 1 if rev1 > rev2.
 */
export function compareRevisions(rev1: string, rev2: string): number {
  const paddedLength = Math.max(rev1.length, rev2.length);
  const paddedRev1 = Array(paddedLength - rev1.length + 1).join('0') + rev1;
  const paddedRev2 = Array(paddedLength - rev2.length + 1).join('0') + rev2;

  if (paddedRev1 < paddedRev2) {
    return -1;
  } else if (paddedRev1 > paddedRev2) {
    return 1;
  } else {
    return 0;
  }
}

export function createChannelStatesFromBoltData<DataType extends BoltData>(
  appId: string,
  boltData: DataType[],
) {
  const boltChannels: SignedChannelState[] = [];
  for (const data of boltData) {
    boltChannels.push(_boltDataToSignedChannelState(appId, data));
  }
  return boltChannels;
}
