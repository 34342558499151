// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgGoogleCalendar({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9492 10.2782L13.5232 11.0949L14.4062 10.4548V15.0905H15.3554V8.9978H14.5607L12.9492 10.2782Z"
        fill="#1E88E5"
      />
      <path
        d="M11.4261 11.8675C11.7793 11.5585 12 11.0949 12 10.6093C12 9.63796 11.1391 8.84326 10.1016 8.84326C9.21858 8.84326 8.44595 9.39514 8.24728 10.2119L9.17443 10.4547C9.26273 10.0795 9.66008 9.81456 10.1016 9.81456C10.6314 9.81456 11.0508 10.1678 11.0508 10.6093C11.0508 11.0508 10.6314 11.404 10.1016 11.404H9.5497V12.3753H10.1016C10.6976 12.3753 11.2053 12.7947 11.2053 13.2803C11.2053 13.7881 10.7197 14.1854 10.1237 14.1854C9.59386 14.1854 9.13028 13.8543 9.06405 13.3907L8.1369 13.5452C8.29143 14.4503 9.15235 15.1347 10.1457 15.1347C11.2716 15.1347 12.1766 14.2958 12.1766 13.2583C12.1546 12.7064 11.8676 12.1987 11.4261 11.8675Z"
        fill="#1E88E5"
      />
      <path d="M17.585 22.0001H6.45917V17.563H17.585V22.0001Z" fill="#FBC02D" />
      <path
        d="M22.0221 17.5628V6.43701H17.585V17.5628H22.0221Z"
        fill="#4CAF50"
      />
      <path
        d="M17.585 6.43709V2H3.6777C2.75055 2 2 2.75055 2 3.6777V17.585H6.43709V6.43709H17.585Z"
        fill="#1E88E5"
      />
      <path d="M17.585 17.563V22.0001L22.0221 17.563H17.585Z" fill="#E53935" />
      <path
        d="M20.3665 2H17.585V6.43709H22.0221V3.65563C22.0221 2.72848 21.2936 2 20.3665 2Z"
        fill="#1565C0"
      />
      <path
        d="M3.67772 22.0001H6.45918V17.563H2.02209V20.3444C2.02209 21.2716 2.75057 22.0001 3.67772 22.0001Z"
        fill="#1565C0"
      />
    </svg>
  );
}

export default SvgGoogleCalendar;
