import { lookup } from '@mirage/shared/errors/registry';
import { getIdOrName } from '@mirage/shared/errors/util';
import { deserializeError, serializeError } from 'serialize-error';

import type { ErrorObject } from 'serialize-error';

export function serialize(error: Error): ErrorObject {
  // for our custom error instances, extract the id property in the event that
  // the name is obfuscated due to compiler minification
  return {
    ...serializeError(error),
    name: getIdOrName(error.constructor),
  } as ErrorObject;
}

export function deserialize<T extends Error>(serialized: ErrorObject): T {
  const constructor = lookup(serialized.name);
  const deserialized = deserializeError(serialized);

  // no prototypical fuckery to be done if we match the default error object
  return constructor === Error
    ? deserialized
    : Object.setPrototypeOf(deserialized, constructor.prototype);
}

// re-export for convenience
export { default as instance } from '@mirage/shared/errors/instance';
