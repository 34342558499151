import * as React from "react";
import { Dividers, MetadataSizes } from "./types";
export type MetadataContextProps = {
  withDividers: Dividers;
  size: MetadataSizes;
};
const defaultMetadataContext: MetadataContextProps = {
  withDividers: "bullet",
  size: "small",
};
export const MetadataContext = React.createContext<MetadataContextProps>(
  defaultMetadataContext,
);
