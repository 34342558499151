import { tagged } from '@mirage/service-logging';
import { HitRecord } from '@mirage/shared/search/hit-record';

import type { MemoryCache } from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches';

const logger = tagged('HitTrackingCache');

const LIMIT_HITS_PER_UUID = 25;
const LIMIT_TOTAL_UUIDS = 200;

class HitTrackingCache implements MemoryCache<HitRecord> {
  private cache: HitRecord[] = [{}];
  private limitHitsPerUuid: number;
  private limitTotalUuids: number;

  constructor(
    limitHitsPerUuid: number = LIMIT_HITS_PER_UUID,
    limitTotalUuids: number = LIMIT_TOTAL_UUIDS,
  ) {
    this.limitHitsPerUuid = limitHitsPerUuid;
    this.limitTotalUuids = limitTotalUuids;
  }

  all() {
    return this.cache;
  }

  clear() {
    this.cache = [{}];
  }

  count() {
    return this.cache.length;
  }

  addItems(items: HitRecord[]): Promise<HitRecord[]> {
    if (items.length == 1) {
      this.clear();
      this.cache = items;
    }
    return Promise.resolve(this.cache);
  }

  removeItem(_item: HitRecord): Promise<HitRecord[]> {
    throw new Error('Method not implemented.');
  }

  async registerHit(uuid: string, epochDate: number) {
    logger.debug('Registering hit for uuid', uuid, 'at', epochDate);

    // Insert or append hit date by UUID
    if (!this.cache[0]) {
      this.cache[0] = {};
    }
    if (this.cache[0][uuid]) {
      if (this.cache[0][uuid].hits.length >= this.limitHitsPerUuid) {
        this.cache[0][uuid].hits.splice(0, 1);
      }
      this.cache[0][uuid].hits.push(epochDate);
    } else {
      this.cache[0][uuid] = { hits: [epochDate] };
    }

    if (Object.keys(this.cache[0]).length > this.limitTotalUuids) {
      let uuidToRemove = null;
      let minOldestHit = Infinity;

      for (const key in this.cache[0]) {
        // Remove key of `this.cache[0]` where the last hit timestamp is the oldest
        const hitCount = this.cache[0][key].hits.length;
        const oldestHit = this.cache[0][key].hits[hitCount - 1];
        if (oldestHit < minOldestHit) {
          minOldestHit = oldestHit;
          uuidToRemove = key;
        }
      }

      if (uuidToRemove !== null) {
        logger.debug('Removing uuid', uuidToRemove);
        delete this.cache[0][uuidToRemove];
      }
    }

    return Promise.resolve(this.cache);
  }
}

export default HitTrackingCache;
