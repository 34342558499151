import { Text } from '@dropbox/dig-components/typography';
import { useTheme } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { PAP_Shown_DashEmptySearchStateComponent } from '@mirage/analytics/events/types/shown_dash_empty_search_state_component';
import { PAP_Shown_DashEmptyStateComponent } from '@mirage/analytics/events/types/shown_dash_empty_state_component';
import { DEFAULT_SECTION } from '@mirage/service-stacks/service/utils';
import i18n from '@mirage/translations';
import { useEffect } from 'react';
import { AddItemButtonAugRev } from '../Sections/AddItemButton';
import { useContentSuggestions } from '../Sections/hooks';
import styles from './EmptyStateBox.module.css';
import stackDetailsEmptySearchResults from './stack_detail_empty_search_results.png';

const EMPTY_STATE_IMAGE_DARK =
  'https://assets.dropbox.com/images/dashweb/emptystates/stack_detail_empty_state_dark.png';

const EMPTY_STATE_IMAGE =
  'https://assets.dropbox.com/images/dashweb/emptystates/stack_detail_empty_state.png';

export const enum EmptyStateBoxType {
  EMPTY_STATE = 'empty_state',
  NO_SEARCH_RESULTS = 'no_search_results',
}

type EmptyStatePropertiesType = {
  [key in EmptyStateBoxType]: {
    img:
      | string
      | {
          light: string;
          dark: string;
        };
    alt: string;
    text: string;
    papEvent?: PAPEvent;
    cta?: JSX.Element;
  };
};

const EmptyStateAddItemButton = () => {
  const { contentSuggestions, isFetchingContentSuggestions } =
    useContentSuggestions();
  return (
    <AddItemButtonAugRev
      align="left"
      variant="opacity"
      section={DEFAULT_SECTION}
      emphasize={false}
      contentSuggestions={contentSuggestions}
      loadingSuggestions={isFetchingContentSuggestions}
    />
  );
};

const emptyStateProperties: EmptyStatePropertiesType = {
  [EmptyStateBoxType.EMPTY_STATE]: {
    img: {
      light: EMPTY_STATE_IMAGE,
      dark: EMPTY_STATE_IMAGE_DARK,
    },
    alt: i18n.t('stacks_connector_empty_alt'),
    text: i18n.t('stacks_connector_empty_cta'),
    papEvent: PAP_Shown_DashEmptyStateComponent({
      dashActionSurface: 'stack_details_page',
      actionSurfaceComponent: 'no_stack_items',
      featureLine: 'stacks',
    }),
    cta: <EmptyStateAddItemButton />,
  },
  [EmptyStateBoxType.NO_SEARCH_RESULTS]: {
    img: stackDetailsEmptySearchResults,
    alt: i18n.t('stacks_no_search_results_cta'),
    text: i18n.t('stacks_no_search_results_cta'),
    papEvent: PAP_Shown_DashEmptySearchStateComponent({
      dashActionSurface: 'stack_details_page',
      featureLine: 'stacks',
    }),
  },
};

export const EmptyStateBox = ({
  type = EmptyStateBoxType.EMPTY_STATE,
  hasWritePermissions,
}: {
  type?: EmptyStateBoxType;
  hasWritePermissions: boolean;
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { mode } = useTheme();
  const isDarkMode = mode === 'dark';
  const emptyState = emptyStateProperties[type];

  useEffect(() => {
    emptyState.papEvent && reportPapEvent(emptyState.papEvent);
  }, [emptyState.papEvent, reportPapEvent]);

  return (
    <div className={styles.emptyStateBox}>
      <div className={styles.emptyStateImg}>
        <img
          alt={emptyState.alt}
          src={
            typeof emptyState.img === 'string'
              ? emptyState.img
              : emptyState.img[isDarkMode ? 'dark' : 'light']
          }
          width="100%"
        />
      </div>
      <Text className={styles.emptyStateText} color="faint" size="large">
        {emptyState.text}
      </Text>
      <div>
        {hasWritePermissions &&
          'cta' in emptyStateProperties[type] &&
          emptyStateProperties[type].cta}
      </div>
    </div>
  );
};
