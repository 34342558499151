import { SetStateAction, useEffect } from 'react';
import { Subscription } from 'rxjs';

type SetAtom<Args extends unknown[], Result> = (...args: Args) => Result;

type SubscriptionState = {
  count: number;
  unsubscribe: () => void;
};

const subscriptionByHookName: { [hookName: string]: SubscriptionState } = {};

/**
 * Hook to simplify the use of subscription hooks by initializing its value
 * only once. In order for this to work, state and setState must refer to
 * something of global scope like jotai atoms or react context values.
 *
 * Why do we need this? This function simplifies consolidation of all data
 * subscribers into a single subscription. e.g. if we use a hook to return
 * the user account data, and there are 10 usages, we don't want to subscribe
 * to the exact same data 10 times in the same app. 😂
 *
 * Please make sure that all given arguments are stable (unchanging).
 * Otherwise we will end up doing wasted work or maybe get into an infinite
 * re-render loop which will degrade app performance.
 *
 * Note on getInitialData and subscribe:
 * There are basically 3 different patterns of using getInitialData together
 * with subscribe:
 *   1. subscribe never returns the initial data --> Recommended approach.
 *   2. subscribe always returns the initial data.
 *   3. subscribe may or may not return the initial data.
 *
 * We recommend pattern 1 above because the other patterns have downsides:
 *   Pattern 2:
 *     - We potentially create lot of wasted ipc traffic with
 *       fanning out data that all existing subscribers already have.
 *     - For subsequent subscribers, the data is already stored in some global
 *       state. So if we return that data, that entire data packet is redundant
 *       IPC traffic.
 *   Pattern 3
 *     - We don't know for sure if we will get the first callback or not at the
 *       time of subscription.
 *     - Returns initial value --> same as Pattern 2 (same downsides).
 */
export function useConsolidatedSubscription<T>({
  hookName,
  shouldSubscribe = true,
  setState,
  getInitialData,
  subscribe,
  onSubscribe,
  onUnsubscribe,
}: {
  // A unique name for this hook so that we easily group the data for the same
  // hook together into one subscription.
  hookName: string;
  // Inform the hook whether it should subscribe to the data or not.
  shouldSubscribe?: boolean;
  setState: ((data: T) => void) | SetAtom<[SetStateAction<T>], void>;
  getInitialData: () => Promise<T>;
  // `updateState` is called whenever the subscription gets notified with some
  // new data of type T.
  // Returns the unsubscribe callback.
  subscribe: (updateState: (data: T) => void) => (() => void) | Subscription;
  // onSubscribe and onUnsubscribe are optional callbacks that are called when
  // the first subscriber subscribes and the last subscriber unsubscribes
  // respectively.
  onSubscribe?: () => void | Promise<void>;
  onUnsubscribe?: () => void | Promise<void>;
}) {
  // Start subscription if needed.
  useEffect(() => {
    if (!shouldSubscribe) {
      return;
    }

    const subscription: SubscriptionState | undefined =
      subscriptionByHookName[hookName];

    if (subscription === undefined) {
      // Start a new subscription.
      const unsubscribe = subscribe(setState);

      subscriptionByHookName[hookName] = {
        count: 1,
        unsubscribe: () => {
          void onUnsubscribe?.();
          typeof unsubscribe === 'function'
            ? unsubscribe()
            : unsubscribe.unsubscribe(); // Subscription case
        },
      };

      // Always fetch initial data on first subscription.
      //
      // Why is this needed?
      // Sometimes the initial state is stored in a jotai atom with local
      // storage. This state is not undefined, but could be potentially very
      // stale data. Therefore we cannot safely assume that when the state is
      // defined, the data is usable.
      getInitialData().then((data) => {
        setState(data);
        void onSubscribe?.();
      });
    } else {
      subscription.count++;
    }

    // Cleanup subscription if needed.
    return () => {
      const subscription: SubscriptionState | undefined =
        subscriptionByHookName[hookName];
      if (!subscription) return;

      if (subscription.count === 1) {
        // This is the last subscriber, so clean up the subscription.
        delete subscriptionByHookName[hookName];
        subscription.unsubscribe();
      } else {
        subscription.count--;
      }
    };
  }, [
    hookName,
    shouldSubscribe,
    getInitialData,
    setState,
    subscribe,
    onSubscribe,
    onUnsubscribe,
  ]);
}
