type Mapper = (value: string) => string[];
export function jaccard(left: string, right: string, mapper: Mapper) {
  if (!left && !right) {
    return 0;
  }

  const set1 = new Set(mapper(left));
  const set2 = new Set(mapper(right));
  const intersection = new Set(
    [...set1].filter((segment) => set2.has(segment)),
  );
  const union = new Set([...set1, ...set2]);
  return intersection.size / union.size;
}
