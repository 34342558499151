import type { PreferenceOption } from '@mirage/settings/GeneralSettings';

export type CreateRadios = {
  options: PreferenceOption[];
  value: string | number;
  onClick: (val?: string | number) => Promise<void>;
  componentType: 'radio';
};

export default function createRadios({
  options,
  value,
  onClick,
  componentType,
}: CreateRadios) {
  return options.map((o) => {
    return {
      name: o.name,
      icon: o.icon,
      onClick: () => onClick(o.value),
      value: value === o.value ? 1 : 0,
      label: o.label,
      ariaLabel: o.ariaLabel,
      componentType,
    };
  });
}
