/* eslint @typescript-eslint/no-explicit-any: 0 */

// we need to be able to lookup a string constructor name to an instance
// object so we can fake a prototype when we rehydrate an error. this registry
// exists to map our error names to their respective constructors
// XXX: register supported errors here so they can be externally rehydrated
import * as discovery from '@mirage/shared/errors/classes/discovery';
import { getIdOrName } from '@mirage/shared/errors/util';

import type { ErrorClass } from './util';

const mapping: { [key: string]: ErrorClass<any> } = {};
export function register<T extends Error>(clazz: ErrorClass<T>): void {
  mapping[getIdOrName(clazz)] = clazz;
}
export function lookup<T extends Error>(
  name: string | undefined,
): ErrorClass<T> {
  return (name && mapping[name]) || Error;
}

// register new errors here for lookup
register(discovery.ServiceNotFoundError);
register(discovery.GraphStateError);
register(discovery.DuplicateService);
register(discovery.ServiceRemovalError);
