import * as React from "react";
import classNames from "classnames";
import { Box } from "@dropbox/dig-foundations";
import css from "./Item.module.css";
import { Dividers } from "./types";
import { MetadataContext } from "./context";

export interface ItemProps extends React.AriaAttributes {
  children: React.ReactNode;
  withDividers?: Dividers;
  className?: string;
}

/**
 * This component acts as a container for any content within the `Metadata` component.
 */
export const _Item: React.FC<ItemProps> = ({
  children,
  className,
  withDividers: _withDividers,
  ...props
}) => {
  const { withDividers: withDividersFromContext, size } =
    React.useContext(MetadataContext);
  const withDividers = _withDividers || withDividersFromContext;
  return (
    <Box
      as="span"
      role="listitem"
      display="flex"
      alignItems="center"
      className={classNames(css.itemRoot, className, {
        [css.hasBullet]: withDividers === "bullet",
        [css.sizeSmall]: size === "small",
        [css.sizeMedium]: size === "medium",
      })}
      {...props}
    >
      {children}
    </Box>
  );
};
