/**
 * Functions to enable and disable UXA devtools
 * The devtool highlights elements that have UXA logs and shows the element_id on hover
 */
const enableDevToolsEventName = 'enable_uxa_devtools';
const disableDevToolsEventName = 'disable_uxa_devtools';

function addUxaElHighlighting() {
  const isEnabled = isUxaDevtoolsEnabled();
  if (isEnabled) return;
  const styleTag = document.createElement('style');
  styleTag.id = 'uxa-devtool-styles';
  styleTag.innerHTML = `
    [data-uxa-log] {
      border: 1px solid red;
    }
  `;
  document.head.appendChild(styleTag);
}

export function removeUxaElHighlighting() {
  const styleTag = document.getElementById('uxa-devtool-styles');
  if (styleTag) {
    styleTag.remove();
  }
}

const styles = {
  fontFamily: 'Atlas Grotesk Web, sans-serif',
  position: 'fixed',
  bottom: '0',
  left: '0',
  padding: '10px',
  margin: '10px',
  minWidth: '300px',
  backgroundColor: '#004C99',
  border: '1px solid black',
  color: 'white',
  boxShadow: '0 0 10px 0 black',
};

function createDisplayContainer() {
  const container = document.createElement('div');
  container.id = 'uxa-devtool-container';

  const headerEl = document.createElement('p');
  headerEl.textContent = `UXA Attributes:`;
  const elIdParagraph = document.createElement('p');
  elIdParagraph.id = 'uxa-devtool-element-id';

  const enitityIdParagraph = document.createElement('p');
  enitityIdParagraph.id = 'uxa-devtool-entity-id';

  const traceIdParagraph = document.createElement('p');
  traceIdParagraph.id = 'uxa-devtool-trace-id';

  container.appendChild(headerEl);
  container.appendChild(elIdParagraph);
  container.appendChild(enitityIdParagraph);
  container.appendChild(traceIdParagraph);

  Object.assign(container.style, styles);
  document.body.appendChild(container);
}

function removeDisplayContainer() {
  const container = document.getElementById('uxa-devtool-container');
  if (container) {
    container.remove();
  }
}

function addHoverListener() {
  document.addEventListener('mouseover', displayUXAId);
}

function removeHoverListener() {
  document.removeEventListener('mouseover', displayUXAId);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function displayUXAId(event: any) {
  const isUXAElement = event.target?.hasAttribute('data-uxa-log');
  if (isUXAElement) {
    const elId = event.target.getAttribute('data-uxa-log');
    const entityId = event.target.getAttribute('data-uxa-entity-id');
    const traceId = event.target.getAttribute('data-uxa-trace-id');
    const container = document.getElementById('uxa-devtool-container');
    if (container) {
      const elIdParagraph = document.getElementById('uxa-devtool-element-id');
      if (elIdParagraph) {
        elIdParagraph.textContent = `element_id: ${elId}`;
      }

      const entityIdParagraph = document.getElementById(
        'uxa-devtool-entity-id',
      );
      if (entityIdParagraph) {
        entityIdParagraph.textContent = `entity_id: ${entityId}`;
      }

      const traceIdParagraph = document.getElementById('uxa-devtool-trace-id');
      if (traceIdParagraph) {
        traceIdParagraph.textContent = `trace_id: ${traceId}`;
      }
    }
  }
}

document.addEventListener(enableDevToolsEventName, () => {
  addUxaElHighlighting();
  createDisplayContainer();
  addHoverListener();
});

document.addEventListener(disableDevToolsEventName, () => {
  removeUxaElHighlighting();
  removeHoverListener();
  removeDisplayContainer();
});

export function enableUxaDevtools() {
  document.dispatchEvent(new Event(enableDevToolsEventName));
}

export function disableUxaDevtools() {
  document.dispatchEvent(new Event(disableDevToolsEventName));
}

export function isUxaDevtoolsEnabled() {
  return Boolean(document.getElementById('uxa-devtool-styles'));
}
