import { Menu } from '@dropbox/dig-components/menu';
import {
  Cluster,
  ThemeContainer,
  ThemeProvider,
} from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { CheckmarkLine } from '@dropbox/dig-icons/assets';
import { createUxaElementId } from '@mirage/analytics/uxa';
import {
  getTheme,
  StackTheme,
  stackThemeColorNames,
} from '@mirage/dash-component-library/themes/Stacks';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import styles from './ColorPicker.module.css';

interface ColorPickerProps {
  colorIndex: number;
  menuKey: string;
  stackNamespaceId?: string;
}

export const ColorPicker = ({
  colorIndex,
  menuKey,
  stackNamespaceId,
}: ColorPickerProps) => {
  const colorLength = stackThemeColorNames.length;

  return (
    <Cluster
      paddingLeft="16"
      paddingRight="16"
      className={styles.colorPicker}
      style={{
        // half of the color length times the width of the color circle container
        width: `calc(${Math.ceil(
          colorLength / 2,
        )} * var(--circle-container-size))`,
      }}
    >
      {stackThemeColorNames.map((color, index) => {
        const isSelected = index === colorIndex;
        // this will either be 'color' or 'unknnown'
        const colorLabelId = `stack_color_picker_${
          StackTheme[index] ? 'color' : 'unknown'
        }` as const;
        return (
          <ThemeProvider overrides={getTheme(index).primary} key={index}>
            <ThemeContainer>
              <Cluster.Item key={color}>
                <Menu.ActionItem
                  role="menuitemradio"
                  aria-label={i18n.t(colorLabelId, { color })}
                  className={classnames(styles.colorCircleContainerInput)}
                  aria-checked={isSelected}
                  id={color}
                  key={menuKey}
                  value={`${menuKey}|${index}`}
                  preferComposition
                  data-uxa-log={createUxaElementId(`color_picker_${color}`, {
                    actionSurfaceComponent: 'stacks',
                    featureLine: 'stacks',
                  })}
                  data-uxa-entity-id={stackNamespaceId}
                >
                  <span className={styles.colorCircle}>
                    <UIIcon
                      className={classnames(
                        styles.colorCircleCheck,
                        isSelected && styles.colorCircleCheckSelectedState,
                      )}
                      src={CheckmarkLine}
                    />{' '}
                  </span>
                </Menu.ActionItem>
              </Cluster.Item>
            </ThemeContainer>
          </ThemeProvider>
        );
      })}
    </Cluster>
  );
};
