import { Text } from '@dropbox/dig-components/typography';
import { Sources } from '@mirage/conversations/Sources';
import { ConversationMessage, Role } from '@mirage/conversations/types';
import { DashSpinner } from '@mirage/shared/dash-spinner';
import { FeedbackOptions } from '@mirage/shared/types';
import i18n from '@mirage/translations';
import { FC, useEffect, useRef } from 'react';
import Markdown from 'react-markdown';
import { MessageActions } from './MessageActions';
import styles from './SystemMessage.module.css';

import type { SearchResult } from '@mirage/service-dbx-api';

type SystemMessageProps = {
  message: ConversationMessage;
  onClickFeedback?: (feedback: FeedbackOptions) => void;
  onSourceClick?: (source: SearchResult) => void;
  onCopyMessage?: () => void;
  onShownEmptyState?: () => void;
};

export const SystemMessage: FC<SystemMessageProps> = ({
  message,
  // Show thumbs up/down buttons only if `onClickFeedback` is set.
  onClickFeedback,
  onCopyMessage,
  onSourceClick,
  onShownEmptyState,
}) => {
  const showSources = Boolean(message?.sources?.length) && onSourceClick;
  const hasShownEmpty = useRef(false);

  useEffect(() => {
    if (message.role === Role.Empty && !hasShownEmpty.current) {
      // Only call `onShownEmptyState` once
      hasShownEmpty.current = true;
      onShownEmptyState?.();
    }
  }, [message.role, onShownEmptyState]);

  return (
    <div className={styles.container}>
      <div className={styles.message}>
        {typeof message.content === 'string' ? (
          <Text
            className={styles.messageText}
            aria-live="polite"
            aria-label={i18n.t('aria_answers_system_answer')}
          >
            <Markdown>{message.content}</Markdown>
          </Text>
        ) : (
          <Text
            className={styles.styledText}
            aria-live="polite"
            aria-label={i18n.t('aria_answers_system_answer')}
          >
            {message.content}
          </Text>
        )}
        {showSources && (
          <Sources
            sources={message.sources}
            onSourceClick={onSourceClick}
            actionSurfaceComponent="ask_dialog"
          />
        )}
      </div>

      {!!onClickFeedback && (
        <div className={styles.feedbackContainer}>
          <MessageActions
            onCopyMessage={onCopyMessage}
            onClickFeedback={onClickFeedback}
            actionSurfaceComponent="ask_dialog"
          />
        </div>
      )}
    </div>
  );
};

// The spinner is contained inside the system message, so it is almost
// identical to a SystemMessage except for the content.
export const SpinnerSystemMessage: React.FC = () => {
  return (
    <div className={styles.spinnerContainer}>
      <div className={styles.spinnerMessage}>
        <DashSpinner size={32} />
      </div>
    </div>
  );
};
