// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgTodoist({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0h24c2.2 0 4 1.8 4 4v24c0 2.2-1.8 4-4 4H4c-2.2 0-4-1.8-4-4v-5.374l.057.033c1.365.795 4.592 2.674 5.382 3.12.478.27.936.264 1.397-.002.276-.16 3.302-1.9 6.36-3.66l.015-.01.06-.034 6.515-3.75c.277-.16.291-.65-.019-.828l-.218-.123-.001-.001c-.316-.18-.726-.413-.9-.516a1.02 1.02 0 00-.998.012 23821 23821 0 01-10.847 6.235 1.349 1.349 0 01-1.338 0L0 19.927v-2.693l.057.033c1.365.795 4.592 2.674 5.382 3.12.478.27.936.264 1.397-.002.276-.16 3.307-1.903 6.367-3.664l.024-.014.019-.011.01-.006 6.53-3.758c.277-.16.291-.651-.019-.828l-.218-.124c-.316-.18-.726-.414-.901-.517a1.02 1.02 0 00-.998.012A23821 23821 0 016.803 17.71a1.349 1.349 0 01-1.338 0L0 14.535v-2.693l.056.033c1.365.795 4.592 2.674 5.383 3.12.478.27.936.264 1.397-.002l6.374-3.668.008-.005c3.2-1.84 6.426-3.698 6.568-3.78.277-.16.291-.65-.019-.828l-.218-.124c-.317-.18-.726-.414-.901-.516a1.02 1.02 0 00-.998.012c-.154.089-10.5 6.038-10.847 6.235a1.349 1.349 0 01-1.338 0L0 9.143V4c0-2.2 1.8-4 4-4z"
        fill="#E44232"
      />
      <path
        d="M6.836 14.993c.276-.16 3.311-1.906 6.374-3.668l.008-.005c3.2-1.84 6.426-3.698 6.568-3.78.277-.16.291-.65-.02-.828l-.217-.124c-.316-.18-.727-.414-.901-.516a1.02 1.02 0 00-.998.012c-.154.089-10.501 6.038-10.847 6.235a1.349 1.349 0 01-1.339 0L0 9.143v2.698l.056.033c1.364.795 4.592 2.674 5.383 3.12.478.27.936.264 1.397-.002z"
        fill="#fff"
      />
      <path
        d="M6.836 20.385c.276-.16 3.306-1.903 6.367-3.664l.024-.014 6.559-3.775c.277-.16.291-.651-.02-.828l-.217-.124c-.316-.18-.727-.414-.901-.517a1.02 1.02 0 00-.998.012c-.154.09-10.501 6.039-10.847 6.235a1.349 1.349 0 01-1.339 0L0 14.535v2.699l.057.033c1.365.795 4.591 2.674 5.382 3.12.478.27.936.264 1.397-.002z"
        fill="#fff"
      />
      <path
        d="M13.21 22.108l-6.374 3.669c-.461.265-.919.272-1.397.002-.79-.446-4.018-2.325-5.382-3.12L0 22.626v-2.699l5.464 3.175c.413.239.925.235 1.339 0 .346-.196 10.693-6.146 10.847-6.235a1.02 1.02 0 01.998-.012c.174.103.585.336.901.517l.218.123c.31.178.296.669.019.829l-6.575 3.784z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgTodoist;
