import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Stack } from '@dropbox/dig-foundations';
import {
  CannedPrompt,
  useQuestions,
} from '@mirage/mosaics/SummaryQnaPanel/useQuestions';
import i18n from '@mirage/translations';
import styles from './QuestionSuggestionsV2.module.css';

import type { QnaAnswer } from '@mirage/service-dbx-api/service/grpc/context_engine_apiv2/types';

type SuggestionQuestionsProps = {
  onSelectSuggestion: (selected: CannedPrompt) => void;
  busy: boolean;
  responses?: QnaAnswer[];
};

export const QuestionSuggestions = ({
  onSelectSuggestion,
  busy,
  responses = [],
}: SuggestionQuestionsProps) => {
  const handleSelectSuggestion = (selected: CannedPrompt) => {
    onSelectSuggestion(selected);
  };
  const suggestions: CannedPrompt[] = useQuestions({ responses });

  if (suggestions.length === 0) {
    return null;
  }

  return (
    <Stack className={styles.suggestionContainer}>
      <div className={styles.suggestionTitle}>
        <Text color="subtle" variant="label" size="small" isBold>
          {i18n.t('doc_summary_qna_related_questions')}
        </Text>
      </div>
      <div className={styles.suggestions}>
        {suggestions.map((suggestion) => (
          <div key={suggestion.id}>
            <Button
              disabled={busy}
              variant="transparent"
              onClick={() => handleSelectSuggestion(suggestion)}
            >
              <Text size="medium">{suggestion.queryString}</Text>
            </Button>
          </div>
        ))}
      </div>
    </Stack>
  );
};
