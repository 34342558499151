// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2416
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'expand.dash_answers'
// Description: Expand the Dash inline answers in the search results
// Owner: dash-web
export type Expand_DashAnswers = PAPEventGeneric<
  'dash',
  'expand',
  'dash_answers',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // Dash Answers - this will be a randomly generated ID associated with each answer, used for logging purposes to track success/dwell time on answers page.
    answerId?: string;
    // An entry point string, used to describe how users are triggering the event as relevant (e.g. browse_file_row_context_menu)
    entryPoint?: string;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // The contents of a search query
    queryString?: string;
    // The LLM-generated answer string returned from dash answers (Q&A) service.
    // This is L0 data, and will be copied to restricted_otc hive table according to this TDD: https://www.dropbox.com/scl/fi/0r38gxrobgr6yvrft4yzu/OTCUS-Machine-Learning-Restricted-Logging-via-PAP-TDD.paper?dl=0&rlkey=7yvsba510fyeufi8cjea9juqk
    answerString?: string;
    // unique id for a /dash/search query. Used by the ML team to get feedback on search result ranking
    searchRequestId?: string;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // A zero-indexed position of an answer in the multi-answer card
    answerPositionIndex?: number;
  }
>;

export function PAP_Expand_DashAnswers(
  properties?: Expand_DashAnswers['properties'],
): Expand_DashAnswers {
  return <Expand_DashAnswers>{
    class: 'dash',
    action: 'expand',
    object: 'dash_answers',
    properties,
  };
}
