import { Modal, ModalWidth } from '@dropbox/dig-components/modal';
import { Skeleton } from '@dropbox/dig-components/skeleton';
import { Text } from '@dropbox/dig-components/typography';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Dismiss_DashCalendarModal } from '@mirage/analytics/events/types/dismiss_dash_calendar_modal';
import { PAP_Shown_DashCalendarModal } from '@mirage/analytics/events/types/shown_dash_calendar_modal';
import { CALENDAR_CONNECTORS } from '@mirage/shared/connectors/entityTypes';
import { UIConnector } from '@mirage/shared/types';
import { useModal } from '@mirage/shared/util/showModal';
import i18n from '@mirage/translations';
import { FC } from 'react';
import { AppChip } from '../../../working-set/AppChip/AppChip';
import styles from './AddCalendar.module.css';
import { useGetCalendarApps } from './useConnectedCalendars';

type CalendarModalProps = {
  open: boolean;
  width?: ModalWidth;
  onRequestClose: (connectorName?: UIConnector) => void;
};

const LOADING_COUNT = CALENDAR_CONNECTORS.length;

const LoadingApps = () => {
  return (
    <>
      {[...Array(LOADING_COUNT)].map((_, i) => (
        <Skeleton.Box
          key={i}
          shouldAnimate
          height={72}
          withBorderRadius="small"
          className={styles.calendarSkeletonChip}
        />
      ))}
    </>
  );
};

const CalendarApps = ({
  calendarApps,
  onAppConnected,
}: {
  calendarApps: UIConnector[];
  onAppConnected: (id: string) => void;
}) => {
  return (
    <>
      {calendarApps.map((connector) => (
        <AppChip
          className={styles.calendarChip}
          connector={connector}
          key={connector.id_attrs?.type}
          grey={true}
          actionSurface="start_page"
          actionSurfaceComponent="connector_calendar_module"
          onConnected={onAppConnected}
          showSpinner={true}
        />
      ))}
    </>
  );
};

export const AddCalendarModal: FC<CalendarModalProps> = ({
  open,
  width,
  onRequestClose,
}) => {
  const { calendarApps, isLoading } = useGetCalendarApps();

  // TODO: Log telemetry for add calendar modal shown
  // Task: OGROW-727

  const onAppConnected = (id: string) => {
    const connector = calendarApps.find((c) => c.id_attrs?.type === id);
    onRequestClose(connector);
  };

  return (
    <Modal
      open={open}
      onRequestClose={() => onRequestClose()}
      width={width}
      shouldCloseOnOverlayClick={true}
      withCloseButton="Close"
      isCentered={true}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('add_your_calendar')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text>
          {isLoading || calendarApps.length > 0
            ? i18n.t('select_calendar_you_use_most')
            : i18n.t('your_admin_has_not_enabled_calendars')}
        </Text>
        <div className={styles.calendarChipContainer}>
          {isLoading ? (
            <LoadingApps />
          ) : (
            <CalendarApps
              calendarApps={calendarApps}
              onAppConnected={onAppConnected}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export function useAddCalendarModal() {
  const { showModal, hideModal } = useModal();
  const { reportPapEvent } = useMirageAnalyticsContext();

  return ({ callback }: { callback: (connector?: UIConnector) => void }) => {
    const onCalendarSelect = (connector?: UIConnector) => {
      callback(connector);
      hideModal();

      reportPapEvent(
        PAP_Dismiss_DashCalendarModal({
          actionSurfaceComponent: 'connector_calendar_module',
          isSuccess: !!connector,
        }),
      );
    };

    reportPapEvent(
      PAP_Shown_DashCalendarModal({
        actionSurfaceComponent: 'connector_calendar_module',
      }),
    );

    showModal(
      <AddCalendarModal
        open={true}
        width={388}
        onRequestClose={onCalendarSelect}
      />,
    );
  };
}
