import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { componentConfigAtom } from './atoms';
import { defaultComponentConfig } from './defaultConfig';
import { ComponentConfig } from './types';

interface MirageComponentConfigProps {
  config: ComponentConfig;
}

export const MirageComponentConfig: React.FC<MirageComponentConfigProps> = ({
  config,
}) => {
  const setComponentConfig = useSetAtom(componentConfigAtom);

  useEffect(() => {
    const populatedConfig = {
      ...defaultComponentConfig,
      ...config,
    };

    setComponentConfig(populatedConfig);
  }, [config, setComponentConfig]);

  return null;
};
