import { sprig } from '@sprig-technologies/sprig-bundled';
import {
  SprigPublishEventInternal,
  SurveyAttributes,
  SurveyEvent,
} from '../types';

import type { Observable, Subscription } from 'rxjs';

// The Sprig SDK has made their WindowSprig type unassignable to SprigAPI

/**
 * Do not directly use this class, use service-feedback service instead.
 */
export class SprigClient {
  private _sprig: WindowSprig | null = null;
  private attributes: SurveyAttributes = {};

  private sprigPublishEventSub: Subscription | null = null;
  private sprigAttributesSub: Subscription | null = null;

  public constructor(
    private environmentId: string,
    // Observable that emits when there is a request for this sprig client to publish a survey event
    private sprigPublishEvent$?: Observable<SprigPublishEventInternal>,
    // Observable that emits when there is a request for this sprig client to update sprig attributes
    private sprigAttributes$?: Observable<SurveyAttributes>,
    // Callback that this sprig client calls when a survey is presented
    private surveyPresented?: () => void,
    // Callback that this sprig client calls when a survey is closed
    private surveyClosed?: () => void,
  ) {
    this.sprigPublishEventSub =
      this.sprigPublishEvent$?.subscribe((event) => {
        this.publishEvent(event.event, event.attributes, event.userId);
      }) ?? null;

    this.sprigAttributesSub =
      this.sprigAttributes$?.subscribe((attributes) => {
        this.updateAttributes(attributes);
      }) ?? null;
  }

  public dispose(): void {
    this.sprigPublishEventSub?.unsubscribe();
    this.sprigAttributesSub?.unsubscribe();
    this.sprigPublishEventSub = null;
    this.sprigAttributesSub = null;
    if (this._sprig) {
      this._sprig.removeAllListeners();
      this._sprig = null;
    }
  }

  private get sprigAPI(): WindowSprig {
    if (!this._sprig) {
      this._sprig = sprig.configure({
        environmentId: this.environmentId,
        maxHeight: '50%',
        dismissOnPageChange: true,
        customStyles: `
          .ul-app__container {
            overflow-y: scroll;
          }
          .ul-question {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #1E1919;
          }
          .ul-caption {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: rgba(82, 74, 62, 0.82);
          }
        `,
      });
      this.initEventListeners();
    }
    return this._sprig;
  }

  private initEventListeners(): void {
    if (this._sprig) {
      this._sprig.addListener(this._sprig.UPDATES.SURVEY_PRESENTED, () => {
        this.surveyPresented?.();
      });
      this._sprig.addListener(this._sprig.UPDATES.SURVEY_CLOSED, () => {
        this.surveyClosed?.();
      });
    }
  }

  /**
   * Set attributes for the Sprig survey. Calling this function will
   * immediately send the given attributes to Sprig.
   * @param newAttributes - The attributes to set.
   */
  private async updateAttributes(
    newAttributes: SurveyAttributes,
  ): Promise<void> {
    this.attributes = { ...this.attributes, ...newAttributes };
    this.sprigAPI.setAttributes(this.attributes);
  }

  private async publishEvent(
    event: SurveyEvent,
    attributes: SurveyAttributes,
    userId?: string,
  ): Promise<void> {
    // https://jira.dropboxer.net/browse/ASKSEC-11542
    // security approves to use public id for Sprig.
    // We send this userId in shown.dash_survey PAP events for correlation.
    if (userId) {
      this.sprigAPI.setUserId(userId);
    }
    await this.updateAttributes(attributes);
    this.sprigAPI.track(event);
  }
}
