// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3474
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { BrowserName } from '../enums/browser_name';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'edit.editor_text_area'
// Description: Key stroke for editing content in the editor (throttled—logs at most once per second). The is_first_action property is true if the user has not interacted with the chat panel yet — this is to determine whether users start the journey from the editor side
// Owner: dash-web
export type Edit_EditorTextArea = PAPEventGeneric<
  'dash_assist',
  'edit',
  'editor_text_area',
  {
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // This indicates the user's current browser
    browserName?: BrowserName;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Boolean event property that is set to true for Dash for Business (dfb) users.
    isDfbUser?: boolean;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // A list of actions users can do on the web
    actionType?: string;
    // Whether this is the first action the user performs
    isFirstAction?: boolean;
  }
>;

export function PAP_Edit_EditorTextArea(
  properties?: Edit_EditorTextArea['properties'],
): Edit_EditorTextArea {
  return <Edit_EditorTextArea>{
    class: 'dash_assist',
    action: 'edit',
    object: 'editor_text_area',
    properties,
  };
}
