import { KeyboardEvent } from 'react';

export function onKeyDownCommitFn(
  handler: ((e: KeyboardEvent) => void | Promise<void>) | undefined,
) {
  return (e: KeyboardEvent) => {
    if (e.key === ' ' || e.key === 'Enter') {
      handler?.(e);
    }
  };
}
