// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3456
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { BrowserName } from '../enums/browser_name';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'click.previous_session'
// Description: Selecting an old session
// Owner: dash-web
export type Click_PreviousSession = PAPEventGeneric<
  'dash_assist',
  'click',
  'previous_session',
  {
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // This indicates the user's current browser
    browserName?: BrowserName;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Boolean event property that is set to true for Dash for Business (dfb) users.
    isDfbUser?: boolean;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
  }
>;

export function PAP_Click_PreviousSession(
  properties?: Click_PreviousSession['properties'],
): Click_PreviousSession {
  return <Click_PreviousSession>{
    class: 'dash_assist',
    action: 'click',
    object: 'previous_session',
    properties,
  };
}
