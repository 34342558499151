import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { APIv2Callable } from '@mirage/service-dbx-api/service';
import { tagged } from '@mirage/service-logging';
import { getValueChangedFunc } from '@mirage/shared/util/value-changed';
import WithDefaults from '@mirage/storage/with-defaults';
import { EventEmitter } from 'eventemitter3';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import { getRecommendedStacks } from './api';

import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

const logger = tagged('service-recommended-stacks');

export type Service = {
  recommendedStacks(): Observable<string[]>;
};

export type StoredRecommendedStacks = {
  stackIds: string[];
};

type DbxApiServiceContract = {
  callApiV2: APIv2Callable;
};

export default function provideRecommendedStacksService(
  rawStorage: KVStorage<StoredRecommendedStacks>,
  { callApiV2 }: DbxApiServiceContract,
) {
  const adapter = new WithDefaults(rawStorage, {
    stackIds: [],
  });
  const emitter = new EventEmitter<{ update: void }>();
  const stackIdsChanged = getValueChangedFunc<string[]>();

  async function refreshRecommendedStacks() {
    const results = await getRecommendedStacks(callApiV2);
    if (results && stackIdsChanged(results)) {
      await adapter.set('stackIds', results);
      emitter.emit('update');
    }
  }

  async function latestRecommendedStacks(): Promise<string[]> {
    return await adapter.get('stackIds');
  }

  function recommendedStacks(): Observable<string[]> {
    logger.info('recommendedStacks() - APIv2 /stacks/get_ranked_stacks');
    // Get fresh events whenever this is called
    refreshRecommendedStacks();

    const changes$ = rx.fromEvent(emitter, 'update') as Observable<void>;

    return rx
      .from(latestRecommendedStacks())
      .pipe(op.mergeWith(changes$.pipe(op.concatMap(latestRecommendedStacks))));
  }

  services.provide<Service>(
    ServiceId.RECOMMENDED_STACKS,
    {
      recommendedStacks,
    },
    [ServiceId.DBX_API],
  );
}
