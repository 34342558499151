import { UIIcon } from '@dropbox/dig-icons';
import { LinkLine, StacksLine } from '@dropbox/dig-icons/dist/mjs/assets';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useState } from 'react';
import { ListItemSize } from '../types';
import styles from './Favicon.module.css';

// Gracefully handle missing and broken favicon links.
export const FavIcon: React.FC<{
  src?: string;
  size?: ListItemSize;
  pureImg?: boolean;
}> = ({ src, size = ListItemSize.Small, pureImg }) => {
  const [hasError, setHasError] = useState(false);
  return !src || hasError ? (
    <UIIcon src={LinkLine} className={styles.digIconFaviconStandin} />
  ) : (
    <img
      alt={i18n.t('icon_for_item')}
      src={src}
      onError={() => setHasError(true)}
      className={classNames(
        styles.favIcon,
        !pureImg && styles.favIconSpacing,
        size === ListItemSize.Large && styles.favIconLarge,
        size === ListItemSize.XLarge && styles.favIconXLarge,
      )}
    />
  );
};

export const StackIcon: React.FC = () => {
  return <UIIcon src={StacksLine} className={styles.digIconFaviconStandin} />;
};
