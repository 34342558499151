import * as React from "react";
import { ShortcutAppBright } from "./ShortcutAppBright";
import { ShortcutDark } from "./ShortcutDark";

export const ServiceNow = {
  dark: React.lazy(() =>
    import("./ServiceNowDark")
      .then((module) => ({ default: module.ServiceNowDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./ServiceNowBright")
      .then((module) => ({ default: module.ServiceNowBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const MsOutlook = {
  dark: React.lazy(() =>
    import("./MsOutlookDark")
      .then((module) => ({ default: module.MsOutlookDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./MsOutlookBright")
      .then((module) => ({ default: module.MsOutlookBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const ClickUp = {
  dark: React.lazy(() =>
    import("./ClickUpDark")
      .then((module) => ({ default: module.ClickUpDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./ClickUpBright")
      .then((module) => ({ default: module.ClickUpBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Greenhouse = {
  dark: React.lazy(() =>
    import("./GreenhouseDark")
      .then((module) => ({ default: module.GreenhouseDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GreenhouseBright")
      .then((module) => ({ default: module.GreenhouseBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const MsSuite = {
  dark: React.lazy(() =>
    import("./MsSuiteDark")
      .then((module) => ({ default: module.MsSuiteDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./MsSuiteBright")
      .then((module) => ({ default: module.MsSuiteBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Outreach = {
  dark: React.lazy(() =>
    import("./OutreachDark")
      .then((module) => ({ default: module.OutreachDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./OutreachBright")
      .then((module) => ({ default: module.OutreachBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const GoogleContacts = {
  dark: React.lazy(() =>
    import("./GoogleContactsDark")
      .then((module) => ({ default: module.GoogleContactsDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GoogleContactsBright")
      .then((module) => ({ default: module.GoogleContactsBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const GoogleFiles = {
  dark: React.lazy(() =>
    import("./GoogleFilesDark")
      .then((module) => ({ default: module.GoogleFilesDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GoogleFilesBright")
      .then((module) => ({ default: module.GoogleFilesBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Miro = {
  dark: React.lazy(() =>
    import("./MiroDark")
      .then((module) => ({ default: module.MiroDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./MiroBright")
      .then((module) => ({ default: module.MiroBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Monday = {
  dark: React.lazy(() =>
    import("./MondayDark")
      .then((module) => ({ default: module.MondayDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./MondayBright")
      .then((module) => ({ default: module.MondayBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Smartsheet = {
  dark: React.lazy(() =>
    import("./SmartsheetDark")
      .then((module) => ({ default: module.SmartsheetDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./SmartsheetBright")
      .then((module) => ({ default: module.SmartsheetBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Front = {
  dark: React.lazy(() =>
    import("./FrontDark")
      .then((module) => ({ default: module.FrontDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./FrontBright")
      .then((module) => ({ default: module.FrontBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Superhuman = {
  dark: React.lazy(() =>
    import("./SuperhumanDark")
      .then((module) => ({ default: module.SuperhumanDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./SuperhumanBright")
      .then((module) => ({ default: module.SuperhumanBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Mixpanel = {
  dark: React.lazy(() =>
    import("./MixpanelDark")
      .then((module) => ({ default: module.MixpanelDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./MixpanelBright")
      .then((module) => ({ default: module.MixpanelBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Google = {
  dark: React.lazy(() =>
    import("./GoogleDark")
      .then((module) => ({ default: module.GoogleDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GoogleBright")
      .then((module) => ({ default: module.GoogleBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Audio = {
  dark: React.lazy(() =>
    import("./AudioDark")
      .then((module) => ({ default: module.AudioDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./AudioBright")
      .then((module) => ({ default: module.AudioBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Firefox = {
  dark: React.lazy(() =>
    import("./FirefoxDark")
      .then((module) => ({ default: module.FirefoxDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./FirefoxBright")
      .then((module) => ({ default: module.FirefoxBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const GoogleCalendar = {
  dark: React.lazy(() =>
    import("./GoogleCalendarDark")
      .then((module) => ({ default: module.GoogleCalendarDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GoogleCalendarBright")
      .then((module) => ({ default: module.GoogleCalendarBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Twitter = {
  dark: React.lazy(() =>
    import("./TwitterDark")
      .then((module) => ({ default: module.TwitterDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./TwitterBright")
      .then((module) => ({ default: module.TwitterBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Flash = {
  dark: React.lazy(() =>
    import("./FlashDark")
      .then((module) => ({ default: module.FlashDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./FlashBright")
      .then((module) => ({ default: module.FlashBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const GoogleMail = {
  dark: React.lazy(() =>
    import("./GoogleMailDark")
      .then((module) => ({ default: module.GoogleMailDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GoogleMailBright")
      .then((module) => ({ default: module.GoogleMailBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Salesforce = {
  dark: React.lazy(() =>
    import("./SalesforceDark")
      .then((module) => ({ default: module.SalesforceDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./SalesforceBright")
      .then((module) => ({ default: module.SalesforceBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const MsSharepoint = {
  dark: React.lazy(() =>
    import("./MsSharepointDark")
      .then((module) => ({ default: module.MsSharepointDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./MsSharepointBright")
      .then((module) => ({ default: module.MsSharepointBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Evernote = {
  dark: React.lazy(() =>
    import("./EvernoteDark")
      .then((module) => ({ default: module.EvernoteDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./EvernoteBright")
      .then((module) => ({ default: module.EvernoteBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Symlink = {
  dark: React.lazy(() =>
    import("./SymlinkDark")
      .then((module) => ({ default: module.SymlinkDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./SymlinkBright")
      .then((module) => ({ default: module.SymlinkBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Pagerduty = {
  dark: React.lazy(() =>
    import("./PagerdutyDark")
      .then((module) => ({ default: module.PagerdutyDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./PagerdutyBright")
      .then((module) => ({ default: module.PagerdutyBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Github = {
  dark: React.lazy(() =>
    import("./GithubDark")
      .then((module) => ({ default: module.GithubDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GithubBright")
      .then((module) => ({ default: module.GithubBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const GoogleDrive = {
  dark: React.lazy(() =>
    import("./GoogleDriveDark")
      .then((module) => ({ default: module.GoogleDriveDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GoogleDriveBright")
      .then((module) => ({ default: module.GoogleDriveBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Lever = {
  dark: React.lazy(() =>
    import("./LeverDark")
      .then((module) => ({ default: module.LeverDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./LeverBright")
      .then((module) => ({ default: module.LeverBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Photoshop = {
  dark: React.lazy(() =>
    import("./PhotoshopDark")
      .then((module) => ({ default: module.PhotoshopDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./PhotoshopBright")
      .then((module) => ({ default: module.PhotoshopBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Audition = {
  dark: React.lazy(() =>
    import("./AuditionDark")
      .then((module) => ({ default: module.AuditionDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./AuditionBright")
      .then((module) => ({ default: module.AuditionBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Gong = {
  dark: React.lazy(() =>
    import("./GongDark")
      .then((module) => ({ default: module.GongDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GongBright")
      .then((module) => ({ default: module.GongBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Powerpoint = {
  dark: React.lazy(() =>
    import("./PowerpointDark")
      .then((module) => ({ default: module.PowerpointDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./PowerpointBright")
      .then((module) => ({ default: module.PowerpointBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Quip = {
  dark: React.lazy(() =>
    import("./QuipDark")
      .then((module) => ({ default: module.QuipDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./QuipBright")
      .then((module) => ({ default: module.QuipBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Calculator = {
  dark: React.lazy(() =>
    import("./CalculatorDark")
      .then((module) => ({ default: module.CalculatorDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./CalculatorBright")
      .then((module) => ({ default: module.CalculatorBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Segment = {
  dark: React.lazy(() =>
    import("./SegmentDark")
      .then((module) => ({ default: module.SegmentDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./SegmentBright")
      .then((module) => ({ default: module.SegmentBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const GoogleForms = {
  dark: React.lazy(() =>
    import("./GoogleFormsDark")
      .then((module) => ({ default: module.GoogleFormsDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GoogleFormsBright")
      .then((module) => ({ default: module.GoogleFormsBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Spotify = {
  dark: React.lazy(() =>
    import("./SpotifyDark")
      .then((module) => ({ default: module.SpotifyDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./SpotifyBright")
      .then((module) => ({ default: module.SpotifyBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Gdocs = {
  dark: React.lazy(() =>
    import("./GdocsDark")
      .then((module) => ({ default: module.GdocsDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GdocsBright")
      .then((module) => ({ default: module.GdocsBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Mayven = {
  dark: React.lazy(() =>
    import("./MayvenDark")
      .then((module) => ({ default: module.MayvenDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./MayvenBright")
      .then((module) => ({ default: module.MayvenBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Clubhouse = {
  dark: React.lazy(() =>
    import("./ClubhouseDark")
      .then((module) => ({ default: module.ClubhouseDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./ClubhouseBright")
      .then((module) => ({ default: module.ClubhouseBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const GoogleDocs = {
  dark: React.lazy(() =>
    import("./GoogleDocsDark")
      .then((module) => ({ default: module.GoogleDocsDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GoogleDocsBright")
      .then((module) => ({ default: module.GoogleDocsBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Brave = {
  dark: React.lazy(() =>
    import("./BraveDark")
      .then((module) => ({ default: module.BraveDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./BraveBright")
      .then((module) => ({ default: module.BraveBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Zendesk = {
  dark: React.lazy(() =>
    import("./ZendeskDark")
      .then((module) => ({ default: module.ZendeskDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./ZendeskBright")
      .then((module) => ({ default: module.ZendeskBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const GoogleMeet = {
  dark: React.lazy(() =>
    import("./GoogleMeetDark")
      .then((module) => ({ default: module.GoogleMeetDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GoogleMeetBright")
      .then((module) => ({ default: module.GoogleMeetBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Sentry = {
  dark: React.lazy(() =>
    import("./SentryDark")
      .then((module) => ({ default: module.SentryDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./SentryBright")
      .then((module) => ({ default: module.SentryBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Angellist = {
  dark: React.lazy(() =>
    import("./AngellistDark")
      .then((module) => ({ default: module.AngellistDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./AngellistBright")
      .then((module) => ({ default: module.AngellistBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Figma = {
  dark: React.lazy(() =>
    import("./FigmaDark")
      .then((module) => ({ default: module.FigmaDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./FigmaBright")
      .then((module) => ({ default: module.FigmaBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const MsTeams = {
  dark: React.lazy(() =>
    import("./MsTeamsDark")
      .then((module) => ({ default: module.MsTeamsDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./MsTeamsBright")
      .then((module) => ({ default: module.MsTeamsBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Slack = {
  dark: React.lazy(() =>
    import("./SlackDark")
      .then((module) => ({ default: module.SlackDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./SlackBright")
      .then((module) => ({ default: module.SlackBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Sketch = {
  dark: React.lazy(() =>
    import("./SketchDark")
      .then((module) => ({ default: module.SketchDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./SketchBright")
      .then((module) => ({ default: module.SketchBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Hubspot = {
  dark: React.lazy(() =>
    import("./HubspotDark")
      .then((module) => ({ default: module.HubspotDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./HubspotBright")
      .then((module) => ({ default: module.HubspotBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Workday = {
  dark: React.lazy(() =>
    import("./WorkdayDark")
      .then((module) => ({ default: module.WorkdayDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./WorkdayBright")
      .then((module) => ({ default: module.WorkdayBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Confluence = {
  dark: React.lazy(() =>
    import("./ConfluenceDark")
      .then((module) => ({ default: module.ConfluenceDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./ConfluenceBright")
      .then((module) => ({ default: module.ConfluenceBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Framer = {
  dark: React.lazy(() =>
    import("./FramerDark")
      .then((module) => ({ default: module.FramerDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./FramerBright")
      .then((module) => ({ default: module.FramerBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Keynote = {
  dark: React.lazy(() =>
    import("./KeynoteDark")
      .then((module) => ({ default: module.KeynoteDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./KeynoteBright")
      .then((module) => ({ default: module.KeynoteBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Premiere = {
  dark: React.lazy(() =>
    import("./PremiereDark")
      .then((module) => ({ default: module.PremiereDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./PremiereBright")
      .then((module) => ({ default: module.PremiereBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Crunchbase = {
  dark: React.lazy(() =>
    import("./CrunchbaseDark")
      .then((module) => ({ default: module.CrunchbaseDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./CrunchbaseBright")
      .then((module) => ({ default: module.CrunchbaseBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Salesloft = {
  dark: React.lazy(() =>
    import("./SalesloftDark")
      .then((module) => ({ default: module.SalesloftDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./SalesloftBright")
      .then((module) => ({ default: module.SalesloftBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const GoogleSheets = {
  dark: React.lazy(() =>
    import("./GoogleSheetsDark")
      .then((module) => ({ default: module.GoogleSheetsDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GoogleSheetsBright")
      .then((module) => ({ default: module.GoogleSheetsBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Jira = {
  dark: React.lazy(() =>
    import("./JiraDark")
      .then((module) => ({ default: module.JiraDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./JiraBright")
      .then((module) => ({ default: module.JiraBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Word = {
  dark: React.lazy(() =>
    import("./WordDark")
      .then((module) => ({ default: module.WordDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./WordBright")
      .then((module) => ({ default: module.WordBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Lucid = {
  dark: React.lazy(() =>
    import("./LucidDark")
      .then((module) => ({ default: module.LucidDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./LucidBright")
      .then((module) => ({ default: module.LucidBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Autodesk = {
  dark: React.lazy(() =>
    import("./AutodeskDark")
      .then((module) => ({ default: module.AutodeskDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./AutodeskBright")
      .then((module) => ({ default: module.AutodeskBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const GetGuru = {
  dark: React.lazy(() =>
    import("./GetGuruDark")
      .then((module) => ({ default: module.GetGuruDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GetGuruBright")
      .then((module) => ({ default: module.GetGuruBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Illustrator = {
  dark: React.lazy(() =>
    import("./IllustratorDark")
      .then((module) => ({ default: module.IllustratorDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./IllustratorBright")
      .then((module) => ({ default: module.IllustratorBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Pages = {
  dark: React.lazy(() =>
    import("./PagesDark")
      .then((module) => ({ default: module.PagesDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./PagesBright")
      .then((module) => ({ default: module.PagesBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Trello = {
  dark: React.lazy(() =>
    import("./TrelloDark")
      .then((module) => ({ default: module.TrelloDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./TrelloBright")
      .then((module) => ({ default: module.TrelloBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Zip = {
  dark: React.lazy(() =>
    import("./ZipDark")
      .then((module) => ({ default: module.ZipDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./ZipBright")
      .then((module) => ({ default: module.ZipBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Notion = {
  dark: React.lazy(() =>
    import("./NotionDark")
      .then((module) => ({ default: module.NotionDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./NotionBright")
      .then((module) => ({ default: module.NotionBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Dropbox = {
  dark: React.lazy(() =>
    import("./DropboxDark")
      .then((module) => ({ default: module.DropboxDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./DropboxBright")
      .then((module) => ({ default: module.DropboxBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Marketo = {
  dark: React.lazy(() =>
    import("./MarketoDark")
      .then((module) => ({ default: module.MarketoDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./MarketoBright")
      .then((module) => ({ default: module.MarketoBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Benchmark = {
  dark: React.lazy(() =>
    import("./BenchmarkDark")
      .then((module) => ({ default: module.BenchmarkDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./BenchmarkBright")
      .then((module) => ({ default: module.BenchmarkBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Linkedin = {
  dark: React.lazy(() =>
    import("./LinkedinDark")
      .then((module) => ({ default: module.LinkedinDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./LinkedinBright")
      .then((module) => ({ default: module.LinkedinBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Edge = {
  dark: React.lazy(() =>
    import("./EdgeDark")
      .then((module) => ({ default: module.EdgeDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./EdgeBright")
      .then((module) => ({ default: module.EdgeBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Exe = {
  dark: React.lazy(() =>
    import("./ExeDark")
      .then((module) => ({ default: module.ExeDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./ExeBright")
      .then((module) => ({ default: module.ExeBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const GoogleSite = {
  dark: React.lazy(() =>
    import("./GoogleSiteDark")
      .then((module) => ({ default: module.GoogleSiteDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GoogleSiteBright")
      .then((module) => ({ default: module.GoogleSiteBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const GoogleAnalytics = {
  dark: React.lazy(() =>
    import("./GoogleAnalyticsDark")
      .then((module) => ({ default: module.GoogleAnalyticsDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GoogleAnalyticsBright")
      .then((module) => ({ default: module.GoogleAnalyticsBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Gaming = {
  dark: React.lazy(() =>
    import("./GamingDark")
      .then((module) => ({ default: module.GamingDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GamingBright")
      .then((module) => ({ default: module.GamingBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Intercom = {
  dark: React.lazy(() =>
    import("./IntercomDark")
      .then((module) => ({ default: module.IntercomDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./IntercomBright")
      .then((module) => ({ default: module.IntercomBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Excel = {
  dark: React.lazy(() =>
    import("./ExcelDark")
      .then((module) => ({ default: module.ExcelDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./ExcelBright")
      .then((module) => ({ default: module.ExcelBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const MsOneNote = {
  dark: React.lazy(() =>
    import("./MsOneNoteDark")
      .then((module) => ({ default: module.MsOneNoteDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./MsOneNoteBright")
      .then((module) => ({ default: module.MsOneNoteBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const GoogleWorkspace = {
  dark: React.lazy(() =>
    import("./GoogleWorkspaceDark")
      .then((module) => ({ default: module.GoogleWorkspaceDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GoogleWorkspaceBright")
      .then((module) => ({ default: module.GoogleWorkspaceBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Pdf = {
  dark: React.lazy(() =>
    import("./PdfDark")
      .then((module) => ({ default: module.PdfDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./PdfBright")
      .then((module) => ({ default: module.PdfBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Box = {
  dark: React.lazy(() =>
    import("./BoxDark")
      .then((module) => ({ default: module.BoxDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./BoxBright")
      .then((module) => ({ default: module.BoxBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const GoogleDrawings = {
  dark: React.lazy(() =>
    import("./GoogleDrawingsDark")
      .then((module) => ({ default: module.GoogleDrawingsDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GoogleDrawingsBright")
      .then((module) => ({ default: module.GoogleDrawingsBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Safari = {
  dark: React.lazy(() =>
    import("./SafariDark")
      .then((module) => ({ default: module.SafariDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./SafariBright")
      .then((module) => ({ default: module.SafariBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const AtriumHq = {
  dark: React.lazy(() =>
    import("./AtriumHqDark")
      .then((module) => ({ default: module.AtriumHqDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./AtriumHqBright")
      .then((module) => ({ default: module.AtriumHqBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const NotSigned = {
  dark: React.lazy(() =>
    import("./NotSignedDark")
      .then((module) => ({ default: module.NotSignedDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./NotSignedBright")
      .then((module) => ({ default: module.NotSignedBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Font = {
  dark: React.lazy(() =>
    import("./FontDark")
      .then((module) => ({ default: module.FontDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./FontBright")
      .then((module) => ({ default: module.FontBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Image = {
  dark: React.lazy(() =>
    import("./ImageDark")
      .then((module) => ({ default: module.ImageDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./ImageBright")
      .then((module) => ({ default: module.ImageBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Text = {
  dark: React.lazy(() =>
    import("./TextDark")
      .then((module) => ({ default: module.TextDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./TextBright")
      .then((module) => ({ default: module.TextBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Gslides = {
  dark: React.lazy(() =>
    import("./GslidesDark")
      .then((module) => ({ default: module.GslidesDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GslidesBright")
      .then((module) => ({ default: module.GslidesBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Unknown = {
  dark: React.lazy(() =>
    import("./UnknownDark")
      .then((module) => ({ default: module.UnknownDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./UnknownBright")
      .then((module) => ({ default: module.UnknownBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Warning = {
  dark: React.lazy(() =>
    import("./WarningDark")
      .then((module) => ({ default: module.WarningDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./WarningBright")
      .then((module) => ({ default: module.WarningBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Asana = {
  dark: React.lazy(() =>
    import("./AsanaDark")
      .then((module) => ({ default: module.AsanaDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./AsanaBright")
      .then((module) => ({ default: module.AsanaBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Link = {
  dark: React.lazy(() =>
    import("./LinkDark")
      .then((module) => ({ default: module.LinkDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./LinkBright")
      .then((module) => ({ default: module.LinkBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Paper = {
  dark: React.lazy(() =>
    import("./PaperDark")
      .then((module) => ({ default: module.PaperDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./PaperBright")
      .then((module) => ({ default: module.PaperBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Chrome = {
  dark: React.lazy(() =>
    import("./ChromeDark")
      .then((module) => ({ default: module.ChromeDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./ChromeBright")
      .then((module) => ({ default: module.ChromeBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Numbers = {
  dark: React.lazy(() =>
    import("./NumbersDark")
      .then((module) => ({ default: module.NumbersDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./NumbersBright")
      .then((module) => ({ default: module.NumbersBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Gsheets = {
  dark: React.lazy(() =>
    import("./GsheetsDark")
      .then((module) => ({ default: module.GsheetsDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./GsheetsBright")
      .then((module) => ({ default: module.GsheetsBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Slideshow = {
  dark: React.lazy(() =>
    import("./SlideshowDark")
      .then((module) => ({ default: module.SlideshowDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./SlideshowBright")
      .then((module) => ({ default: module.SlideshowBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Video = {
  dark: React.lazy(() =>
    import("./VideoDark")
      .then((module) => ({ default: module.VideoDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./VideoBright")
      .then((module) => ({ default: module.VideoBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Airtable = {
  dark: React.lazy(() =>
    import("./AirtableDark")
      .then((module) => ({ default: module.AirtableDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./AirtableBright")
      .then((module) => ({ default: module.AirtableBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Code = {
  dark: React.lazy(() =>
    import("./CodeDark")
      .then((module) => ({ default: module.CodeDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./CodeBright")
      .then((module) => ({ default: module.CodeBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const MsOneDrive = {
  dark: React.lazy(() =>
    import("./MsOneDriveDark")
      .then((module) => ({ default: module.MsOneDriveDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./MsOneDriveBright")
      .then((module) => ({ default: module.MsOneDriveBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Okta = {
  dark: React.lazy(() =>
    import("./OktaDark")
      .then((module) => ({ default: module.OktaDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./OktaBright")
      .then((module) => ({ default: module.OktaBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Shortcut = {
  dark: React.lazy(() =>
    import("./ShortcutDark")
      .then((module) => ({ default: module.ShortcutDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./ShortcutBright")
      .then((module) => ({ default: module.ShortcutBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Package = {
  dark: React.lazy(() =>
    import("./PackageDark")
      .then((module) => ({ default: module.PackageDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./PackageBright")
      .then((module) => ({ default: module.PackageBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const ShortcutApp = {
  dark: React.lazy(() =>
    import("./ShortcutAppDark")
      .then((module) => ({ default: module.ShortcutAppDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./ShortcutAppBright")
      .then((module) => ({ default: module.ShortcutAppBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Cad = {
  dark: React.lazy(() =>
    import("./CadDark")
      .then((module) => ({ default: module.CadDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./CadBright")
      .then((module) => ({ default: module.CadBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Coda = {
  dark: React.lazy(() =>
    import("./CodaDark")
      .then((module) => ({ default: module.CodaDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./CodaBright")
      .then((module) => ({ default: module.CodaBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Basecamp = {
  dark: React.lazy(() =>
    import("./BasecampDark")
      .then((module) => ({ default: module.BasecampDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./BasecampBright")
      .then((module) => ({ default: module.BasecampBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
export const Disc = {
  dark: React.lazy(() =>
    import("./DiscDark")
      .then((module) => ({ default: module.DiscDark }))
      .catch(() => ({ default: ShortcutDark })),
  ),
  bright: React.lazy(() =>
    import("./DiscBright")
      .then((module) => ({ default: module.DiscBright }))
      .catch(() => ({ default: ShortcutAppBright })),
  ),
};
