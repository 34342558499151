// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3071
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'create.stacks_dnd_section'
// Description: Create a section using drag and drop
// Owner: content-ingest
export type Create_StacksDndSection = PAPEventGeneric<
  'stacks',
  'create',
  'stacks_dnd_section',
  {
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
  }
>;

export function PAP_Create_StacksDndSection(
  properties?: Create_StacksDndSection['properties'],
): Create_StacksDndSection {
  return <Create_StacksDndSection>{
    class: 'stacks',
    action: 'create',
    object: 'stacks_dnd_section',
    properties,
  };
}
