import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { dropTargetForExternal } from '@atlaskit/pragmatic-drag-and-drop/external/adapter';
import { containsURLs } from '@atlaskit/pragmatic-drag-and-drop/external/url';
import {
  attachClosestEdge,
  Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { Link } from '@mirage/link-list/types';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './DragAndDrop.module.css';
import { useDraggablePreview } from './Preview';
import { useDnDContext } from './Provider';
import {
  DraggableData,
  isDraggableData,
  tryExtractLinkFromHTMLDragElement,
  typeKey,
} from './utils';

export const DroppableItemizedSection = ({
  sectionId,
  link,
  index,
  children,
}: {
  sectionId: string;
  link: Link;
  index: number;
  children?: React.ReactNode;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const element = ref?.current;
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const { moveSuggestionIntoSection, moveStackItemIntoSection, isDisabled } =
    useDnDContext();
  const { preview, handleGenerateDragPreview } = useDraggablePreview({
    url: nonNil(link.url, 'item URL'),
    title: link.title,
  });

  // handles whether a suggestion or stack item is dropped into the section
  const handleElementDrop = useCallback(
    ({ source, self: dest }) => {
      setClosestEdge(null);
      if (
        source.element === element ||
        !isDraggableData(source.data) ||
        !isDraggableData(dest.data) ||
        dest.data.type !== 'stackitem'
      ) {
        return;
      }
      const dstIndex = dest.data.index + (closestEdge === 'bottom' ? 1 : 0);
      if (source.data.type === 'suggestion') {
        moveSuggestionIntoSection(sectionId, source.data.payload, dstIndex);
      } else if (source.data.type === 'stackitem') {
        moveStackItemIntoSection(sectionId, source.data.id, dstIndex);
      }
    },
    [
      element,
      moveSuggestionIntoSection,
      sectionId,
      closestEdge,
      moveStackItemIntoSection,
    ],
  );

  // this drag handler is used to show the drop indicator when dragging an item over another item
  // only show the drop indicator when the dragged item is a stack item and the destination is also a stack item
  const handleElementDrag = useCallback(
    ({ self, source }) => {
      if (!isDraggableData(source.data) || !isDraggableData(self.data)) {
        return;
      }
      const closestEdge = extractClosestEdge(self.data);
      if (source.data.type !== 'stackitem') {
        setClosestEdge(closestEdge);
        return;
      }
      // hide the drop indicator if the item is being dragged to the top or bottom of the source item
      const isItemBeforeSource = index === source.data.index - 1;
      const isItemAfterSource = index === source.data.index + 1;
      const sameSection = sectionId === source.data.sectionId;
      const isDropIndicatorHidden =
        sameSection &&
        (source.data.index === index ||
          (isItemBeforeSource && closestEdge === 'bottom') ||
          (isItemAfterSource && closestEdge === 'top'));
      setClosestEdge(isDropIndicatorHidden ? null : closestEdge);
    },
    [index, sectionId],
  );

  useEffect(() => {
    if (!element) return;

    const data: DraggableData = {
      [typeKey]: true,
      index,
      sectionId,
      id: String(nonNil(link.id, 'item ID')),
      type: 'stackitem',
    };

    return combine(
      // handles external URLs
      dropTargetForExternal({
        element,
        canDrop: containsURLs,
        onDragLeave: () => setClosestEdge(null),
        onDrag: ({ self }) => setClosestEdge(extractClosestEdge(self.data)),
        onDrop: ({ source }) => {
          const newLink = tryExtractLinkFromHTMLDragElement(source);
          if (newLink) {
            moveSuggestionIntoSection(
              sectionId,
              newLink,
              index + (closestEdge === 'bottom' ? 1 : 0),
            );
          }
          setClosestEdge(null);
        },
        getData: ({ input }) =>
          attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ['top', 'bottom'],
          }),
      }),
      dropTargetForElements({
        element,
        onDragLeave: () => setClosestEdge(null),
        onDrop: handleElementDrop,
        onDrag: handleElementDrag,
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ['top', 'bottom'],
          });
        },
      }),
    );
  }, [
    closestEdge,
    index,
    moveStackItemIntoSection,
    moveSuggestionIntoSection,
    isDisabled,
    handleGenerateDragPreview,
    sectionId,
    element,
    handleElementDrop,
    handleElementDrag,
    link.id,
  ]);

  return (
    <div ref={ref} className={styles.dragDropSectionItem}>
      {children}
      {closestEdge && <DropIndicator edge={closestEdge} />}
      {preview}
    </div>
  );
};
