// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2030
import type { PAPEventGeneric } from '../base/event';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSettingName } from '../enums/dash_setting_name';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'update.dash_settings'
// Description: Event triggered when a user setting is updated in dash
// Owner: otc
export type Update_DashSettings = PAPEventGeneric<
  'dash',
  'update',
  'dash_settings',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // Name of the Dash setting being acted upon
    dashSettingName?: DashSettingName;
    // True or false value for a user setting
    dashSettingValue?: boolean;
    // value for a user setting
    dashSettingValueNum?: number;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
  }
>;

export function PAP_Update_DashSettings(
  properties?: Update_DashSettings['properties'],
): Update_DashSettings {
  return <Update_DashSettings>{
    class: 'dash',
    action: 'update',
    object: 'dash_settings',
    properties,
  };
}
