// @generated by bzl gen
// @generated by protoc-gen-connect-web v0.3.1 with parameter "target=ts"
// @generated from file dropbox/proto/context_engine/doc_summarization.proto (package context_engine, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {
  DocQnaRequest,
  DocQnaResponse,
  DocSummarizationRequest,
  DocSummarizationResponse,
} from './doc_summarization_pb';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service context_engine.DocSummarizationApiV2
 */
export const DocSummarizationApiV2 = {
  typeName: 'context_engine.DocSummarizationApiV2',
  methods: {
    /**
     * @generated from rpc context_engine.DocSummarizationApiV2.GetDocSummary
     */
    getDocSummary: {
      name: 'GetDocSummary',
      I: DocSummarizationRequest,
      O: DocSummarizationResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc context_engine.DocSummarizationApiV2.GetDocAnswer
     */
    getDocAnswer: {
      name: 'GetDocAnswer',
      I: DocQnaRequest,
      O: DocQnaResponse,
      kind: MethodKind.ServerStreaming,
    },
  },
} as const;
