import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { batchedIntervalFunc } from '@mirage/shared/util/batching';
import * as rx from 'rxjs';

import type {
  LocalPAPEvent,
  Service,
} from '@mirage/service-product-logging/service';

const service = services.get<Service>(ServiceId.PRODUCT_LOGGING);

const autoBatchReportPapEvent = batchedIntervalFunc(
  (events: PAPEvent[], flush?: boolean) => {
    batchReportPapEvent(events, flush);
  },
  1000,
);

/**
 * Warning: Don't call this directly. Please use
 * `const { reportPapEvent } = useMirageAnalyticsContext();` instead.
 */
export function reportPapEvent(event: PAPEvent, flush?: boolean): void {
  const newEvent: PAPEvent = event.properties?.startTimeMs
    ? event
    : {
        ...event,
        properties: {
          // Preserve actual event trigger time if not already given.
          startTimeMs: Date.now(),
          ...event.properties,
        },
      };

  autoBatchReportPapEvent(newEvent, flush);
}

/** Calling reportPapEvent above will auto-batch the PAP events. */
export function batchReportPapEvent(
  events: PAPEvent[],
  flush?: boolean,
): Promise<void> {
  return rx.firstValueFrom(service.batchReportPapEvents(events, flush));
}

/** Send to PAP UI but not to server. */
export function batchLogLocalEvents(events: PAPEvent[]): Promise<void> {
  return rx.firstValueFrom(service.batchLogLocalEvents(events));
}

export function getLocalEvents() {
  return rx.firstValueFrom(service.getLocalEvents());
}

export function subscribeToLocalEvents(
  callback: (events: LocalPAPEvent[]) => void,
) {
  return service.localEventsObservable().subscribe(callback);
}

export function clearLocalEventLog() {
  return rx.firstValueFrom(service.clearLocalEventLog());
}

export function copyToClipboard(event: PAPEvent) {
  return rx.firstValueFrom(service.copyToClipboard(event));
}
