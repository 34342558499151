import { getAll, listen, set } from '@mirage/service-settings';
import { useConsolidatedSubscription } from '@mirage/shared/hooks/useConsolidatedSubscription';
import { useStableObject } from '@mirage/shared/util/hooks';
import { atom, useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { Settings } from './service/types';

import type { SettingsPartial } from '@mirage/service-settings';

export const DefaultKeys: (keyof Settings)[] = [
  'annotationMode',
  'appDebug',
  'appShortcut',
  'appToNearestWindow',
  'collapsedCards',
  'customizableModules',
  'disableAutoUpdate',
  'enableDarkMode',
  'enableTips',
  'hideAppOnStartup',
  'isDevTools',
  'localFiles',
  'showDashInDock',
  'openFilesInDesktopApps',
  'stackByIdSortPreference',
  'stackItemByIdCollapseDescription',
  'stackItemByIdCollapseSummary',
  'stackPageFilterPreference',
  'stackPageSortPreference',
  'disableGlobalShortcut',
  'startPageSelectedTab',
];

const settingsAtom = atom<Settings | undefined>(undefined);

export function useAllSettings() {
  const [settings, setSettings] = useAtom(settingsAtom);

  const subscribe = useCallback(
    (update) => listen(DefaultKeys).subscribe(update),
    [],
  );

  useConsolidatedSubscription({
    hookName: 'useAllSettings',
    setState: setSettings,
    getInitialData: getAll,
    subscribe,
  });

  return settings;
}

export default function useSettings<K extends keyof Settings>(
  keys: K[] | Readonly<K[]>,
) {
  const allSettings = useAllSettings();
  const stableKeys = useStableObject(keys);

  const settings = useMemo(() => {
    if (!allSettings) return null;

    const newSettings = {} as Settings;
    for (const key of stableKeys) {
      newSettings[key] = allSettings[key];
    }
    return newSettings as SettingsPartial<K>;
  }, [allSettings, stableKeys]);

  return { settings, set };
}
