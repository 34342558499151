import { Text } from '@dropbox/dig-components/typography';
import { SearchResultIcon } from '@mirage/shared/icons';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import styles from './Source.module.css';
import { AnswersActionComponent } from './types';

import type { SearchResult } from '@mirage/service-dbx-api';
import type { QuestionAndAnswerSource } from '@mirage/shared/answers/multi-answer';

export const Source = ({
  source,
  onClick,
  sourceMaxWidth = '100%',
  sourceWidth = 'auto',
  onQASourceClick,
}: {
  source: SearchResult | QuestionAndAnswerSource;
  onClick?: (source: SearchResult) => void;
  sourceMaxWidth?: string;
  sourceWidth?: string;
  actionSurfaceComponent?: AnswersActionComponent;
  onQASourceClick?: (source: QuestionAndAnswerSource) => void;
}) => {
  const isQASource = isQuestionAndAnswerSource(source);
  const handleClick = () => {
    if (!isQASource && onClick) {
      onClick(source);
    }
    if (isQASource && onQASourceClick) {
      onQASourceClick(source);
    }
  };

  return (
    <DigTooltip
      key={isQASource ? source.id3p : source.uuid}
      title={source.title}
      isPortaled
      openDelay={400}
    >
      <button
        className={styles.sourceButton}
        onClick={handleClick}
        style={{ maxWidth: sourceMaxWidth, width: sourceWidth }}
        aria-label={source.title}
        type="button"
      >
        <div className={styles.sourceInner}>
          {isQASource ? (
            <img
              className={styles.sourceIcon}
              alt={i18n.t('answers_source_logo_alt', {
                connectorName: source.connectorName,
              })}
              src={source.iconUrl}
            />
          ) : (
            <div className={styles.iconContainer}>
              <SearchResultIcon size={20} result={source} />
            </div>
          )}
          <Text className={styles.sourceTitle} size="small">
            {source.title}
          </Text>
        </div>
      </button>
    </DigTooltip>
  );
};

// type guard
export function isQuestionAndAnswerSource(
  source: SearchResult | QuestionAndAnswerSource,
): source is QuestionAndAnswerSource {
  return 'brandedType' in source;
}
