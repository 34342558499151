// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2567
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'cancel.add_stack_section'
// Description: Fires when a user cancels adding a section to a stack
// Owner: dash-web
export type Cancel_AddStackSection = PAPEventGeneric<
  'stacks',
  'cancel',
  'add_stack_section',
  {
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
  }
>;

export function PAP_Cancel_AddStackSection(
  properties?: Cancel_AddStackSection['properties'],
): Cancel_AddStackSection {
  return <Cancel_AddStackSection>{
    class: 'stacks',
    action: 'cancel',
    object: 'add_stack_section',
    properties,
  };
}
