import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { useConnections } from '@mirage/shared/atoms/connections';
import {
  connectorsAtom,
  connectorsSortedByDisplayNameAtom,
  useConnectors,
} from '@mirage/shared/atoms/connectors';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import { atom, useAtomValue } from 'jotai';
import { OnboardingConnectors } from './OnboardingConnectors';

export const PopularConnectorsList = () => {
  const { loaded: connectionsLoaded } = useConnections();
  const { loaded: connectorsLoaded } = useConnectors();
  const connectorsSortedByDisplayName = useAtomValue(
    connectorsSortedByDisplayNameAtom,
  );
  const orderedPopularOnboardingConnectors = useAtomValue(
    orderedPopularOnboardingConnectorsAtom,
  );

  return (
    <Box
      style={{
        width: '100%',
        gap: '16px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box>
        <OnboardingConnectors
          title={i18n.t('onboarding_connectors_section_popular')}
          connectors={orderedPopularOnboardingConnectors}
          browserExtensionData={[]}
          showSnackbar={showSnackbar}
        />
      </Box>
      <Box>
        <OnboardingConnectors
          title={i18n.t('onboarding_connectors_section_all')}
          connectors={connectorsSortedByDisplayName}
          browserExtensionData={[]}
          showSnackbar={showSnackbar}
        />
      </Box>
      {connectorsLoaded && connectionsLoaded && (
        <Text size="large" color="faint" style={{ textAlign: 'center' }}>
          {i18n.t('more_apps_coming_soon')}
        </Text>
      )}
    </Box>
  );
};

const orderedPopularOnboardingConnectorTypes = [
  'dropbox',
  'gmail',
  'googleCalendar',
  'googledrive',
  'outlook',
  'onedrive',
];

const orderedPopularOnboardingConnectorsAtom = atom((get) => {
  const { data: connectors } = get(connectorsAtom);

  return connectors.filter((c) => {
    return (
      c.id_attrs?.type &&
      orderedPopularOnboardingConnectorTypes.includes(c.id_attrs.type)
    );
  });
});
