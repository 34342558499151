// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgShortcut({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 7.5A4.5 4.5 0 0016.5 3h-9a4.486 4.486 0 00-3.182 1.318 4.5 4.5 0 000 6.364l1.603 1.603A4.502 4.502 0 007.5 21h9a4.485 4.485 0 003.182-1.318 4.5 4.5 0 000-6.364l-1.603-1.603A4.502 4.502 0 0021 7.5zM5.553 18.448a2.754 2.754 0 011.832-4.699l5.505 5.506H7.5a2.735 2.735 0 01-1.947-.807zm12.894-3.895a2.754 2.754 0 01-3.894 3.895l-9-9a2.754 2.754 0 013.895-3.896l9 9zm0-5.106a2.734 2.734 0 01-1.831.804L11.11 4.746h5.39a2.754 2.754 0 011.947 4.701z"
        fill="#58B1E4"
      />
    </svg>
  );
}

export default SvgShortcut;
