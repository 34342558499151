import { SUB_ICON_SIZE } from '@mirage/dash-component-library/components/Icon';
import { SearchResultIcon } from '@mirage/shared/icons';
import EventIcon from '@mirage/shared/icons/EventIcon';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { utcToZonedTime } from 'date-fns-tz';

import type { ContentIconProps } from '@dropbox/dash-component-library';
import type { SearchResult } from '@mirage/service-dbx-api';

type Props = {
  result: SearchResult;
  size?: ContentIconProps['size'];
};

export default function ResultIcon({ result, size = 'medium' }: Props) {
  const isEvent = result?.recordType?.['.tag'] === 'event' && result?.startTime;

  if (isEvent) {
    let eventDate = new Date(result.startTime as number); // Cannot be null if isEvent is true
    if (result?.isAllDay) {
      // All-day events are represented as UTC, so we need to convert to local time
      // TODO: Temporary fix until https://jira.dropboxer.net/browse/OTCIP-46 is
      // resolved and the API provides just the date for these all-day events.
      eventDate = utcToZonedTime(eventDate, 'UTC');
    }
    // checking if the date is valid here prevents EventIcon from throwing on invalid dates
    if (eventDate instanceof Date && !isNaN(eventDate.getTime())) {
      return (
        <EventIcon
          size={size}
          eventDate={eventDate}
          sourceIcon={<SearchResultIcon result={result} size={SUB_ICON_SIZE} />}
        />
      );
    }
  }

  return <FileContentIcon content={result} size={size} />;
}
