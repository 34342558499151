import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Select_StartPageAction } from '@mirage/analytics/events/types/select_start_page_action';
import { RecentsTabPanel } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/RecentsTabPanel';
import { StacksTabPanel } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/StacksTabPanel';
import { useStacksTabFilterPreference } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/useStacksTabFilterPreference';
import { useSelectedTab } from '@mirage/mosaics/StartPage/TabbedModule/useSelectedTab';
import { TabIds } from '@mirage/service-settings/service/types';
import { StackFilterOptionToPapName } from '@mirage/service-settings/service/types';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import * as React from 'react';
import { forwardRef, Ref, useCallback } from 'react';
import styles from './TabbedModule.module.css';

export const TabbedModule = ({
  maxCardsPerRow,
}: {
  maxCardsPerRow: number;
}) => {
  const {
    startPageSelectedTab: selectedTab,
    setStartPageSelectedTab: _setSelectedTab,
  } = useSelectedTab();

  // We use a forward ref on the TabHeader so we can save the div that we use to render accessory buttons.
  // We pass that down into each TabPanel which will use react's portal to render any buttons associated with
  //    that panel/module. This keeps the logic cleaner & more contained
  const [headerButtonSlot, setHeaderButtonSlot] = React.useState(null);
  const accessoryButtonRef = React.useRef(null);
  React.useEffect(() => {
    setHeaderButtonSlot(accessoryButtonRef.current);
  }, []);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { startPageStackFilterPreference } = useStacksTabFilterPreference();

  const onTabSelection = useCallback(
    (tabId: TabIds) => {
      if (tabId === 'recents') {
        reportPapEvent(
          PAP_Select_StartPageAction({
            startPageAction: 'click_recents_tab',
            featureLine: 'recents',
            actionSurfaceComponent: 'recents',
          }),
        );
      }
      if (tabId === 'stacks') {
        reportPapEvent(
          PAP_Select_StartPageAction({
            startPageAction: 'click_stacks_tab',
            featureLine: 'stacks',
            actionSurfaceComponent: 'stacks',
            dashStackFilterName:
              StackFilterOptionToPapName[startPageStackFilterPreference],
          }),
        );
      }

      _setSelectedTab(tabId);
    },
    [startPageStackFilterPreference, reportPapEvent],
  );

  return (
    <Box>
      {/*  TODO create a context or jotai for storing tabs & unread state */}
      <TabHeader
        ref={accessoryButtonRef}
        selectedTab={selectedTab}
        onTabSelection={onTabSelection}
      />

      <RecentsTabPanel
        isActive={selectedTab === 'recents'}
        headerButtonSlot={selectedTab === 'recents' ? headerButtonSlot : null}
      />

      <StacksTabPanel
        isActive={selectedTab === 'stacks'}
        maxCardsPerRow={maxCardsPerRow}
        headerButtonSlot={selectedTab === 'stacks' ? headerButtonSlot : null}
      />
    </Box>
  );
};

const TabHeader = forwardRef(
  (
    {
      selectedTab,
      onTabSelection,
    }: {
      selectedTab: TabIds;
      onTabSelection: (tabId: TabIds) => void;
    },
    ref: Ref<HTMLDivElement>,
  ) => {
    const tabLabels: Array<{ label: string; tabId: TabIds }> = [
      {
        label: i18n.t('recents'),
        tabId: 'recents',
      },
      {
        label: i18n.t('stacks'),
        tabId: 'stacks',
      },
    ];

    return (
      <div className={styles.tabHeaderContainer}>
        {tabLabels.map(({ label, tabId }) => (
          <div
            role="tablist"
            aria-labelledby={label}
            tabIndex={0}
            className={styles.tabLabelWrapper}
            onClick={() => onTabSelection(tabId)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onTabSelection(tabId);
              }
            }}
            key={tabId}
          >
            <div
              className={classNames(styles.tabLabelUnderline, {
                [styles.isSelected]: tabId === selectedTab,
              })}
            >
              <Text isBold color={selectedTab === tabId ? 'standard' : 'faint'}>
                {label}
              </Text>
            </div>
          </div>
        ))}

        {/* Target div used for portal-ing accessory buttons*/}
        <Box marginLeft="auto" display="flex" alignItems="center" ref={ref} />
      </div>
    );
  },
);
TabHeader.displayName = 'TabHeader';
