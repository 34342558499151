import { atom } from 'jotai';
import isEqual from 'lodash/isEqual';

/**
 * Creates a new atom that dedupes the memory usage.
 *
 * Currently, we don't store large objects in local storage using
 * `atomWithStorage` because those are sourced from indexed db. But if we need
 * a deduped version of `atomWithStorage`, we can add that later.
 */
export function dedupedAtom<T>(initialValue: T) {
  const a = atom<T>(initialValue);
  let uniqueValue = initialValue;

  return atom(
    (get) => {
      // Need to trigger a UI re-render, but ignore the returned clone.
      get(a);

      // Just return the value we have.
      return uniqueValue;
    },
    (_get, set, newValue: T) => {
      // Update value only if changed to avoid redundant react renders.
      // Doing so will also preserve the old value of `uniqueValue`.
      if (uniqueValue !== newValue && !isEqual(uniqueValue, newValue)) {
        uniqueValue = newValue;
        set(a, newValue);
      }
    },
  );
}
