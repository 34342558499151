// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgGoogleSlides({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 7v13.712c0 .711-.565 1.288-1.26 1.288H5.76c-.696 0-1.26-.577-1.26-1.288V3.288C4.5 2.577 5.064 2 5.76 2h8.74l5 5z"
        fill="#FFBA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 10.333h10v5.834H7v-5.834zm.833.834h8.334v4.166H7.833v-4.166z"
        fill="#F1F1F1"
      />
      <path d="M19.5 7h-5V2l5 5z" fill="#F59307" />
    </svg>
  );
}

export default SvgGoogleSlides;
