import { SegmentedControl } from '@dropbox/dig-components/controls';
import * as React from 'react';
import BaseListItem from './BaseListItem';
import styles from './SegmentButtonsListItem.module.css';

type OptionType = {
  label: string;
  optionValue: string | number;
};

export type RadioButtonProps = Readonly<{
  /** left hand side icon */
  icon?: React.ReactNode;
  /** can accept and returns strings or numbers */
  value?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  /** default title */
  title: React.ReactNode;
  /** select options labels are displayed, values are stored */
  options?: Array<OptionType>;
  /** optional aria label */
  ariaLabel?: string;
}>;

export default function RadioListItem(props: RadioButtonProps) {
  const { icon, onClick, value, title = '', options, ariaLabel } = props;
  // TODO : remove these value type conversions (see larger note below)
  const currentValue = value?.toString();
  const selectionClick = (selected?: string | number) => {
    // TODO : remove these value type conversions (left on 12-28-2023)
    // SegmentedControl doesn't recognize the number 0 as a value to option match
    // I started a thread here about it https://dropbox.slack.com/archives/C105BU9HS/p1703811769625239
    // the PR to fix it is already merged https://github.com/dropbox-internal/design-systems/pull/1226
    // we can remove this excess value conversions once that makes it into a release (current is 19.7.0)
    if (onClick && typeof value === 'number') {
      onClick(Number(selected));
    } else if (onClick) {
      onClick(selected);
    }
  };

  return (
    <BaseListItem
      icon={icon}
      title={title}
      role="radio"
      ariaLabel={ariaLabel}
      swapLayout
    >
      <SegmentedControl
        className={styles.styledSegmentedControl}
        selectedValue={currentValue}
        onSelection={selectionClick}
      >
        {options?.map((option) => (
          <SegmentedControl.Option
            key={option.label}
            variant="text"
            // TODO : remove these value type conversions (see larger note above)
            value={option.optionValue.toString()}
          >
            {option.label}
          </SegmentedControl.Option>
        ))}
      </SegmentedControl>
    </BaseListItem>
  );
}
