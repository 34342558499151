// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3176
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';

// Event_name: 'cancel.dash_more_actions'
// Description: When a user clicks away from the more actions menu and closes it
// Owner: content-ingest
export type Cancel_DashMoreActions = PAPEventGeneric<
  'stacks',
  'cancel',
  'dash_more_actions',
  {
    // The stack id that represents the stack
    stackId?: string;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // marks if the content is shared content
    isShared?: boolean;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // stormcrow feature name
    feature?: string;
  }
>;

export function PAP_Cancel_DashMoreActions(
  properties?: Cancel_DashMoreActions['properties'],
): Cancel_DashMoreActions {
  return <Cancel_DashMoreActions>{
    class: 'stacks',
    action: 'cancel',
    object: 'dash_more_actions',
    properties,
  };
}
