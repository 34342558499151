// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3247
import type { PAPEventGeneric } from '../base/event';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'select.dash_uxa'
// Description: Generic PAP event used to log all UX analytics events.
// Owner: dash-web
export type Select_DashUxa = PAPEventGeneric<
  'dash',
  'select',
  'dash_uxa',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // the start time of the event in ms
    startTimeMs?: number;
  }
>;

export function PAP_Select_DashUxa(
  properties?: Select_DashUxa['properties'],
): Select_DashUxa {
  return <Select_DashUxa>{
    class: 'dash',
    action: 'select',
    object: 'dash_uxa',
    properties,
  };
}
