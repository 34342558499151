import { dash_async, dash_settings } from '@dropbox/api-v2-client';
import { tagged } from '@mirage/service-logging';
import { getCombineAsyncRequestsFunc } from '@mirage/shared/util/combine-async-requests';
import { FeatureRing, FeatureRingSettings } from '../types';

import type { APIv2Callable } from '@mirage/service-dbx-api/service';

const logger = tagged('FeatureRingApi');

interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

function findFeatureRingById(
  id: string,
  overrideableFeatureRings: dash_async.OverridableFeatureRing[],
): FeatureRing {
  const featureRing = overrideableFeatureRings?.find(
    (value) => value.feature_ring?.['.tag'] === id,
  );
  return {
    id: featureRing?.growthbook_value ?? id,
    rank: featureRing?.rank ?? 0,
    displayName: featureRing?.display_name ?? id,
  };
}

function fromApiModel(
  featureRing: dash_async.OverridableFeatureRing | undefined,
): FeatureRing {
  const id = featureRing?.growthbook_value ?? '';
  return {
    id,
    rank: featureRing?.rank ?? 0,
    displayName: featureRing?.display_name ?? id,
  };
}

const getFeatureRingSettingsCombined = getCombineAsyncRequestsFunc(
  (dbxApiService: DbxApiServiceContract) =>
    dbxApiService.callApiV2('dashAsyncGetFeatureRingSettings', undefined),
);

class FeatureRingApi {
  constructor(private readonly dbxApiService: DbxApiServiceContract) {}

  public async getFeatureRingSettings(): Promise<FeatureRingSettings> {
    const response = await getFeatureRingSettingsCombined(this.dbxApiService);

    // Process default feature ring
    const defaultFeatureRing = fromApiModel(response.default_feature_ring_v2);

    // Process override (if one is set)
    let overrideFeatureRing: FeatureRing | undefined = undefined;
    if (response.override_feature_ring?.optional_ring?.['.tag'] == 'ring') {
      const featureRingOverrideId =
        response.override_feature_ring?.optional_ring?.ring['.tag'];
      overrideFeatureRing = findFeatureRingById(
        featureRingOverrideId,
        response.overridable_feature_rings ?? [],
      );
    }

    // Process all rings
    const rings = response.overridable_feature_rings?.map(fromApiModel) || [];

    const lastFetchedMs = Date.now();

    const result = {
      default: defaultFeatureRing,
      override: overrideFeatureRing,
      rings,
      lastFetchedMs,
    };

    logger.debug('Raw feature ring response:', response);
    logger.debug('Computed feature ring settings:', result);
    return result;
  }

  public async setFeatureRingOverride(
    ring: dash_async.Ring | null,
  ): Promise<void> {
    const args: dash_settings.UpdateUserSettingsArgs = {
      settings: {
        settings: {
          '.tag': 'global_settings',
          feature_ring_override: {
            enabled: ring !== null,
            value: ring ?? undefined,
          },
        },
      },
      update_mask: { paths: ['global_settings.feature_ring_override'] },
      segment: {
        '.tag': 'global_settings',
      },
    };
    await this.dbxApiService.callApiV2('dashSettingsUpdateUserSettings', args);
  }
}

export function getFeatureRingAPI(dbxAPiService: DbxApiServiceContract) {
  return new FeatureRingApi(dbxAPiService);
}
