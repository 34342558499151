import { dash_connectors } from '@dropbox/api-v2-client';
import { fetchConnectorsWithVisibilityFilters } from '@mirage/settings/utils/connectorMetadataService';
import { UIConnector } from '@mirage/shared/types';
import { useEffect, useState } from 'react';

export const useUIConnectors = (connectorTypes: string[]) => {
  const [connectors, setConnectors] = useState<UIConnector[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    fetchConnectorsWithVisibilityFilters()
      .then((data) => {
        const uiConnectors: UIConnector[] = [];
        const connectorMap: Record<string, dash_connectors.Connector> = {};
        data.forEach((connector) => {
          if (connector.id_attrs?.type) {
            connectorMap[connector.id_attrs.type] = connector;
          }
        });

        connectorTypes.forEach((connectorType) => {
          const connector = connectorMap[connectorType];
          if (connector) {
            uiConnectors.push({
              ...connector,
              loading: false,
            });
          }
        });

        setConnectors(uiConnectors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [connectorTypes]);

  return { connectors, isLoading };
};
