import { jaccard } from '@mirage/shared/search/scoring/jaccard';
import { PersonObject } from '@mirage/shared/search/search-filters';

const WEIGHTS = {
  email: 0.3,
  name: 0.7,
  fullWord: 0.9,
  character: 0.1,
};

export const scorePerson = (person: PersonObject, _query: string): number => {
  const query = _query.toLowerCase();
  const email = person.email.toLowerCase();
  const displayName = person.displayName?.toLowerCase();

  // Exact matches
  if (query === email || query === displayName) {
    return 2; // Boost for exact matches
  }

  // Boost for starts with match
  const startsWithBoost =
    displayName?.startsWith(query) || email.startsWith(query) ? 0.5 : 0;

  const displayNameScore = titleMatch(query, displayName || '');
  const emailScore = emailMatch(query, email);

  // If displayName is missing, rely solely on email score
  if (!displayName) {
    return emailScore * WEIGHTS.email + startsWithBoost;
  }

  // Combine scores: giving more weight to displayName if it exists
  return (
    WEIGHTS.email * emailScore +
    WEIGHTS.name * displayNameScore +
    startsWithBoost
  );
};

function titleMatch(query: string, title: string): number {
  const fullWordMatches = jaccard(query, title, (value) => value.split(/\s+/));
  const charMatches = jaccard(query, title, (value) =>
    value.replace(/\s+/g, '').split(''),
  );
  // Heavily index on full word and partially on character matches
  return fullWordMatches * WEIGHTS.fullWord + charMatches * WEIGHTS.character;
}

function emailMatch(query: string, email: string): number {
  // Character matches
  return jaccard(query, email, (value) => value.replace(/\s+/g, '').split(''));
}
