import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine, DeleteLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useState } from 'react';
import styles from './AnnotationURLModal.module.css';

export function useAddResultURLs() {
  const [pendingURLs, setPendingURLs] = useState<string[]>(['']);

  const handleAddURL = () => {
    setPendingURLs((prev) => [...prev, '']);
  };

  const handleUpdateURL = (index: number, newURL: string) => {
    setPendingURLs((prev) => {
      const updatedURLs = [...prev];
      updatedURLs[index] = newURL;
      return updatedURLs;
    });
  };

  const handleDeleteURL = (index: number) => {
    setPendingURLs((prev) => prev.filter((_, i) => i !== index));
  };

  return {
    pendingURLs,
    setPendingURLs,
    handleAddURL,
    handleUpdateURL,
    handleDeleteURL,
  };
}

type AddURLFormProps = {
  pendingURLs: string[];
  handleAddURL: () => void;
  handleUpdateURL: (index: number, newURL: string) => void;
  handleDeleteURL: (index: number) => void;
};

export const AddURLForm = (props: AddURLFormProps) => {
  const { pendingURLs, handleAddURL, handleUpdateURL, handleDeleteURL } = props;
  return (
    <>
      {pendingURLs.map((url, index) => (
        <URLInput
          url={url}
          key={index}
          onUpdateURL={(e) => handleUpdateURL(index, e.target.value)}
          onDeleteURL={() => handleDeleteURL(index)}
        />
      ))}
      <Button
        variant="borderless"
        className={styles.addRow}
        onClick={handleAddURL}
        type="button"
        withIconStart={<UIIcon src={AddLine} />}
      >
        <Text style={{ paddingLeft: '8px' }}>Add URL</Text>
      </Button>
    </>
  );
};

type URLInputProps = {
  url: string;
  onUpdateURL: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDeleteURL: () => void;
};

const URLInput = ({ url, onUpdateURL, onDeleteURL }: URLInputProps) => {
  return (
    <div className={styles.URLInputRow}>
      <TextInput
        size="medium"
        placeholder="Enter a URL"
        value={url}
        onChange={onUpdateURL}
      />
      <IconButton variant="transparent" onClick={onDeleteURL} type="button">
        <UIIcon src={DeleteLine} />
      </IconButton>
    </div>
  );
};
