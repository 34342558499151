import * as React from "react";
import { IconLayout } from "../icon-layout";
import { type IconLayoutProps } from "../icon-layout/IconLayout";
import { type BadgeProps } from "../icon-layout/Badge";
export interface ContentIconProps
  extends Pick<
      IconLayoutProps,
      | "style"
      | "className"
      | "hasBackground"
      | "shouldConstrainContent"
      | "shapeVariant"
    >,
    React.AriaAttributes {
  /**
   * The size of the `ContentIcon`.
   * @default xlarge
   */
  size?: IconLayoutProps["size"];
  /**
   * The icon to display in the larger area of the `ContentIcon`.
   */
  icon: React.ReactNode;
  /**
   * The icon to display in the offset Badge of the `ContentIcon`.
   */
  badgeIcon?: React.ReactNode;

  /**
   * Props to pass to the Badge component.
   */
  badgeProps?: Omit<BadgeProps, "children">;
  children?: never;
}

/**
 * Content icons represent traditional files, connected apps, or web content.
 */
export const _ContentIcon: React.FC<ContentIconProps> = ({
  icon = "Shortcut",
  badgeIcon,
  hasBackground,
  badgeProps,
  ...rest
}) => {
  const shouldShowBadge = badgeIcon && hasBackground;
  return (
    <IconLayout
      hasBackground={hasBackground}
      hasBorder={hasBackground ? true : false}
      content={icon}
      withBadge={
        shouldShowBadge && (
          <IconLayout.Badge position="bottom-right" {...badgeProps}>
            {badgeIcon}
          </IconLayout.Badge>
        )
      }
      {...rest}
    />
  );
};
