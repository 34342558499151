import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { ConsolaInstance } from 'consola';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';

const TIMEOUT_MS = 5000;

type Deferred<T> = {
  promise: Promise<T>;
  resolve: (val: T) => void;
  reject: (e: unknown) => void;
};
function deferred<T>(): Deferred<T> {
  const deferred: Deferred<T> = {} as Deferred<T>;

  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  return deferred;
}

export interface Service {
  logout(): Promise<void>;
  registerService(service: ServiceId): Promise<void>;
  completeService(service: ServiceId): void;
}

export type ServiceAdapter = {
  logout(): Promise<void>;
};

interface LoggingServiceContract {
  tagged: (tag: string) => ConsolaInstance;
}

export default function logoutService(
  { tagged }: LoggingServiceContract,
  adapter: ServiceAdapter,
) {
  const logger = tagged('service-logout');

  const logoutDeferred = deferred<void>();
  const registered: Map<ServiceId, Deferred<void>> = new Map();

  async function logout() {
    logger.debug(
      'Logout called, waiting for services to complete logout activity...',
    );
    logoutDeferred.resolve();

    const execution$ = rx.from(registered.entries()).pipe(
      op.mergeMap(([id, deferred]) => {
        // wait for the registered handler to send back that they're complete
        return (
          rx
            .from(deferred.promise)
            // but also time this out so we do not perpetually block logout
            .pipe(op.timeout(TIMEOUT_MS))
            // if we do capture an error on timeout, we'll log indicating such
            // but not blow up the entire processing pipeline like we would if
            // we used the promises directly and raced a rejection
            .pipe(
              op.catchError((e) => {
                logger.error('%s service did not clean up in time', id, e);
                return rx.of(undefined);
              }),
            )
            .pipe(
              op.tap(() => {
                // courtesy clean up even though we're going to kill the app
                registered.delete(id);
              }),
            )
        );
      }),
    );

    // if we hit logout with nothing registered this can throw, so capture that
    // here. nothing within the pipeline itself should be surfaced but more so
    // hitting boundaries of the lib
    //
    // realistically we could early-exit at the top if we have no handlers set
    // up and directly execute the logout :shrug:
    await rx.lastValueFrom(execution$).catch((e) => {
      logger.debug('execution$ rejected', e);
    });

    logger.debug('logout handlers complete, proceeding with adapter logout');
    return adapter.logout();
  }

  // WARN: If you `registerService` you must reliably `completeService` or else
  // you'll block logout!
  function registerService(serviceId: ServiceId): Promise<void> {
    logger.debug('registering %s for logout activity', serviceId);

    // setting up a promise we can fire ourselves is more communicative than
    // attempting to use an inline bus that we'd push onto and wait for (imo)
    const resolvers = deferred<void>();
    registered.set(serviceId, resolvers);

    // this will let the consumer file listen for our trigger for log out
    return logoutDeferred.promise;
  }

  function completeService(serviceId: ServiceId) {
    const resolver = registered.get(serviceId);

    if (!resolver) {
      logger.warn('unregistered service completing logout! (%s)', serviceId);
      return;
    }

    resolver.resolve();
  }

  return services.provide<Service>(
    ServiceId.LOGOUT,
    {
      logout,
      registerService,
      completeService,
    },
    [],
  );
}
