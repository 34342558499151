// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3487
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { FeatureLine } from '../enums/feature_line';
import type { StackAccessLevel } from '../enums/stack_access_level';
import type { StacksAction } from '../enums/stacks_action';

// Event_name: 'select.stacks_action'
// Description: Fires when stack action is performed
// Owner: dash-web
export type Select_StacksAction = PAPEventGeneric<
  'stacks',
  'select',
  'stacks_action',
  {
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // Whether the user is interacting with their welcome stack
    isWelcomeStack?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // Bool that indicates if the item acted upon is pinned
    isPinned?: boolean;
    // Used to identify if a stack is a published stack
    isPublished?: boolean;
    // The access level settings for a stack
    stackAccessLevel?: StackAccessLevel;
    // Type of action performed on stack
    stacksAction?: StacksAction;
    // Boolean event property that is set to true for Dash for Business (dfb) users.
    isDfbUserV2?: boolean;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
  }
>;

export function PAP_Select_StacksAction(
  properties?: Select_StacksAction['properties'],
): Select_StacksAction {
  return <Select_StacksAction>{
    class: 'stacks',
    action: 'select',
    object: 'stacks_action',
    properties,
  };
}
