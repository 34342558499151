import { dash_async, DropboxResponseError } from '@dropbox/api-v2-client';
import { callApiV2 } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import Sentry from '@mirage/shared/sentry';
import { Backoff } from '@mirage/shared/util/backoff';
import { getCombineAsyncRequestsFunc } from '@mirage/shared/util/combine-async-requests';
import { sleepMs } from '@mirage/shared/util/tiny-utils';

const logger = tagged('get-eligibility');

type ShouldRedirectIneligibleUserV2Response =
  dash_async.ShouldRedirectIneligibleUserResponse & {
    dash_user_attributes?: {
      is_dfb_user: boolean;
    };
  };

const fetchUserEligibility = getCombineAsyncRequestsFunc(() => {
  return callApiV2('dashAsyncShouldRedirectIneligibleUser', {
    pathname: 'dash.ai',
  }) as Promise<ShouldRedirectIneligibleUserV2Response>;
});

let isDfbUser: boolean | undefined;

export const getIsDfbUser = async () => {
  if (isDfbUser !== undefined) {
    return isDfbUser;
  }

  try {
    const response = await fetchUserEligibility();
    const isDfbUserRaw = response?.dash_user_attributes?.is_dfb_user;

    if (typeof isDfbUserRaw === 'undefined') {
      Sentry.captureMessage('is_dfb_user was not successfully fetched');
    }

    isDfbUser = isDfbUserRaw || false;

    return isDfbUser;
  } catch (e) {
    logger.error('failed to get isDfbUser', e);
    return false;
  }
};

const MAX_TRIES = 4;

export async function getUserEligibilityWithRetries(
  canceled: [boolean],
): Promise<
  | dash_async.ShouldRedirectIneligibleUserResponse
  | 'access_token_invalid'
  | 'network_error'
  | 'canceled'
> {
  // Played around in the UI and we need to provide fast user feedback here.
  const backoff = new Backoff(300, 1200);

  for (let loopCount = 1; loopCount <= MAX_TRIES && !canceled[0]; loopCount++) {
    try {
      logger.debug(`Checking eligibility: loopCount=${loopCount}`);
      return await fetchUserEligibility();
    } catch (err) {
      if (canceled[0]) return 'canceled';

      // Unsafe cast, but below code has safe checks.
      const e =
        err as DropboxResponseError<dash_async.ShouldRedirectIneligibleUserError>;

      if (
        'status' in e &&
        e.status === 400 &&
        'error' in e &&
        e.error &&
        // e.error could be an error message string.
        typeof e.error === 'object' &&
        'error' in e.error &&
        e.error.error == 'invalid_grant'
      ) {
        // Auto-logout on invalid token error.
        logger.warn('Access token invalid, logging out now');
        return 'access_token_invalid';
      }

      logger.warn('Error fetching eligibility:', e);
    }

    if (canceled[0]) return 'canceled';

    backoff.stepUp();
    logger.debug(
      `Checking eligibility: loopCount=${loopCount}, backoff for ${backoff.millis}ms`,
    );
    await sleepMs(backoff.millis);
  }

  logger.error(
    `Eligibility check failed after ${MAX_TRIES} tries, returning network_error`,
  );
  return 'network_error';
}

export const resetIsDfbUserInTests = () => {
  isDfbUser = undefined;
};
