import { Text } from '@dropbox/dig-components//typography';
import { Button } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { Menu } from '@dropbox/dig-components/menu';
import { Modal } from '@dropbox/dig-components/modal';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { TextArea, TextInput } from '@dropbox/dig-components/text_fields';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AttachLine,
  BugLine,
  CloseLine,
  CommentLine,
  HelpLine,
} from '@dropbox/dig-icons/assets';
import { Link } from '@mirage/link/Link';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import {
  FeedbackType,
  useSubmitFeedback,
} from '@mirage/service-feedback/hooks/useSubmitFeedback';
import { isDropboxerEmail } from '@mirage/shared/account';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n, { Trans } from '@mirage/translations';
import { useRef, useState } from 'react';
import styles from './FeedbackModal.module.css';
import { getSupportUrl } from './support-url';

export function FeedbackModal({ onClose }: { onClose: () => void }) {
  const account = useDropboxAccount();
  const submitFeedback = useSubmitFeedback();
  const [submitting, setSubmitting] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [selectingType, setSelectingType] = useState(false);
  const [feedbackType, setFeedbackType] = useState<FeedbackType>(
    FeedbackType.BugReport,
  );
  const [imageUrl, setImageUrl] = useState<null | string>(null);
  const [imageFilename, setImageFilename] = useState<string | null>(null);
  const fileUploadRef = useRef<HTMLInputElement>(null);

  function onImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const inputEl = event.target;
    if (inputEl && inputEl?.files && inputEl.files.length > 0) {
      try {
        const file = inputEl.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (event: ProgressEvent<FileReader>) => {
            const result = event.target?.result;
            if (typeof result === 'string') {
              setImageUrl(result);
            }
          };
          setImageFilename(file.name);
          reader.readAsDataURL(file);
          return;
        }
      } catch (e) {
        showSnackbar({ title: i18n.t('feedback_screenshot_failed') });
      }
    }
    showSnackbar({ title: i18n.t('feedback_screenshot_failed') });
  }

  function onUploadClick() {
    if (fileUploadRef?.current) {
      fileUploadRef.current.click();
    }
  }

  function getFeedbackTypeIcon(feedback: FeedbackType) {
    switch (feedback) {
      case FeedbackType.BugReport:
        return <UIIcon src={BugLine} />;
      case FeedbackType.Suggestion:
        return <UIIcon src={CommentLine} />;
      case FeedbackType.Other:
        return <UIIcon src={HelpLine} />;
      default:
        feedback satisfies never;
    }
  }

  async function submit() {
    setSubmitting(true);
    const { success } = await submitFeedback({
      title,
      body,
      feedbackType: feedbackType ?? FeedbackType.Other,
      screenCapture: imageUrl
        ? imageUrl.replace(/^data:image\/png;base64,/, '')
        : undefined,
    });
    setSubmitting(false);
    if (success) {
      showSnackbar({ title: i18n.t('feedback_received') });
    } else {
      showSnackbar({ title: i18n.t('feedback_failed') });
    }
    onClose();
  }

  return (
    <Modal
      open={true}
      withCloseButton="close"
      onRequestClose={onClose}
      isCentered
    >
      <Modal.Header hasBottomSpacing="title-small">
        <Modal.Title>{i18n.t('feedback_modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.feedbackFormBody}>
        <Text>
          {account?.email && isDropboxerEmail(account?.email) ? (
            <>{i18n.t('feedback_modal_blurb')}</>
          ) : (
            <>
              {i18n.t('feedback_modal_blurb')}&nbsp;
              <Trans
                i18nKey="feedback_modal_contact_blurb"
                components={[<Link key={0} href={getSupportUrl()} />]}
              />
            </>
          )}
        </Text>
        <Menu.Wrapper
          onToggle={({ isOpen }) => setSelectingType(isOpen)}
          onSelection={setFeedbackType}
          isPortaled={false}
        >
          {({ getTriggerProps, getContentProps }) => (
            <>
              <Chip
                {...getTriggerProps()}
                withDropdownIcon={true}
                selectedStyle="stroke"
                isSelected={selectingType}
              >
                {feedbackType && (
                  <Chip.IconAccessory>
                    {getFeedbackTypeIcon(feedbackType)}
                  </Chip.IconAccessory>
                )}
                <Chip.Content>
                  {feedbackType ??
                    i18n.t('feedback_modal_type_dropdown_default')}
                </Chip.Content>
              </Chip>
              <Menu.Content
                {...getContentProps()}
                className={styles.feedbackTypeDropdown}
              >
                <Menu.Segment>
                  <Menu.ActionItem
                    key={`feedback-type-${FeedbackType.BugReport}`}
                    value={FeedbackType.BugReport}
                    withTitle={FeedbackType.BugReport}
                    withLeftAccessory={getFeedbackTypeIcon(
                      FeedbackType.BugReport,
                    )}
                  />
                  <Menu.ActionItem
                    key={`feedback-type-${FeedbackType.Suggestion}`}
                    value={FeedbackType.Suggestion}
                    withTitle={FeedbackType.Suggestion}
                    withLeftAccessory={getFeedbackTypeIcon(
                      FeedbackType.Suggestion,
                    )}
                  />
                </Menu.Segment>
              </Menu.Content>
            </>
          )}
        </Menu.Wrapper>
        <TextInput
          placeholder={i18n.t('feedback_modal_title_placeholder')}
          onChange={(e) => setTitle(e.currentTarget.value)}
          maxLength={100}
        />
        <TextArea
          placeholder={i18n.t('feedback_modal_body_placeholder')}
          rows={10}
          onChange={(e) => setBody(e.currentTarget.value)}
        />
      </Modal.Body>
      <Modal.Footer
        className={styles.feedbackModalFooter}
        hasTopBorder={true}
        preferComposition={true}
      >
        {imageUrl ? (
          <div className={styles.screenshotAttachment}>
            <IconButtonWithTooltip
              tooltipProps={{
                title: i18n.t('feedback_modal_remove_image'),
                isPortaled: false,
              }}
              size="small"
              variant="borderless"
              onClick={() => setImageUrl(null)}
            >
              <UIIcon
                src={CloseLine}
                size="small"
                aria-label={i18n.t('feedback_modal_remove_image')}
              />
            </IconButtonWithTooltip>
            <img
              src={imageUrl}
              className={styles.screenshotMini}
              alt={'mini uploaded screenshot'}
            />
            {imageFilename && <Text>{imageFilename}</Text>}
          </div>
        ) : (
          <div>
            <input
              type="file"
              accept="image/png"
              onChange={onImageUpload}
              ref={fileUploadRef}
              className={styles.fileInput}
            />
            <Button
              variant="transparent"
              withIconStart={<UIIcon src={AttachLine} />}
              onClick={onUploadClick}
            >
              {i18n.t('feedback_modal_attach_screentshot_cta')}
            </Button>
          </div>
        )}
        <div className={styles.feedbackFormCTA}>
          {submitting && <Spinner monochromatic size="small" />}
          <Button
            variant="primary"
            onClick={submit}
            disabled={submitting || body.length === 0 || title.length === 0}
          >
            {i18n.t('feedback_modal_cta_text')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
