import { USER_AGENT_INFO } from '@mirage/shared/util/tiny-utils';

export function getWebPlatformForEnvCtx() {
  const osName = USER_AGENT_INFO.os.name;

  if (osName?.includes('Mac')) {
    return 'darwin';
  }

  if (osName?.includes('Windows')) {
    return 'win32';
  }

  return 'unknown';
}
