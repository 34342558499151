import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Shown_DashNoResults } from '@mirage/analytics/events/types/shown_dash_no_results';
import i18n from '@mirage/translations';
import { useEffect } from 'react';
import { Error } from './Error';
import styles from './NoResults.module.css';

import type { SearchFilter } from '@mirage/shared/search/search-filters';

type Props = {
  activeFilters: SearchFilter[];
  resetAllFilters: () => void;
};

export function NoResults({ activeFilters, resetAllFilters }: Props) {
  const noActiveFilters = activeFilters.length === 0;
  const { reportPapEvent, searchAttemptSessionManager, searchSessionManager } =
    useMirageAnalyticsContext();

  useEffect(() => {
    reportPapEvent(
      PAP_Shown_DashNoResults({
        searchAttemptId: searchAttemptSessionManager.getSessionIdOrUndefined(),
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        dashActionSurface: 'search_result_page',
        featureLine: 'search',
      }),
    );
  }, []);

  return (
    <div className={styles.container}>
      <Error
        title={i18n.t('no_search_results_title')}
        subtitle={
          noActiveFilters
            ? i18n.t('no_search_results_subtitle')
            : i18n.t('no_search_results_with_filters_subtitle')
        }
        hideAction={noActiveFilters}
        actionText={i18n.t('clear_all_filters')}
        onActionClick={resetAllFilters}
      />
    </div>
  );
}
