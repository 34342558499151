// This file implements the replacement for this restricted import.
// eslint-disable-next-line no-restricted-imports
import { Link as DigLink } from '@dropbox/dig-components/typography';
import { openURL } from '@mirage/service-platform-actions';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import { forwardRef } from 'react';

export type LinkProps = Omit<Parameters<typeof DigLink>[0], 'href'> & {
  // Unsupport the overloaded MouseEvent type from `href` so that we can open
  // links properly using the keyboard as well.
  href: string;

  // Custom additional action to run when user opens the URL.
  onOpen?: () => void;
};

/**
 * Drop-in replacement for a DIG Link that uses `openURL()` by default.
 *
 * @param href Required, and must be a string. Using MouseEvent makes it hard
 *   to support opening the link properly using the keyboard.
 * @param onOpen Additional custom action to run when opening the link.
 * @param onClick Use a custom onClick handler, need to call openURL if needed.
 *   Use onOpen for additional actions.
 * @param onKeyDown Opens the link when Enter or Space is pressed. When
 *   specified, the Enter & Space keys will not be supported, and caller has
 *   to specify their own implementation completely.
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ href, onOpen, onClick, onKeyDown, ...others }, ref) => {
    return (
      <DigLink
        ref={ref}
        // Specifying href is needed to display it on mouse hover.
        href={href}
        onClick={
          onClick ??
          ((e) => {
            e.preventDefault();

            onOpen?.();
            openURL(href);
          })
        }
        onKeyDown={
          onKeyDown ??
          onKeyDownCommitFn((e) => {
            onOpen?.();
            openURL(href);
            e.preventDefault();
            e.stopPropagation();
          })
        }
        {...others}
      />
    );
  },
);

Link.displayName = 'Link';
