// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgFigma({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 22c1.932 0 3.5-1.494 3.5-3.334v-3.333H8.5c-1.932 0-3.5 1.493-3.5 3.333S6.568 22 8.5 22z"
        fill="#0ACF83"
      />
      <path
        d="M19 12.167c0 1.931-1.568 3.5-3.5 3.5a3.501 3.501 0 01-3.5-3.5c0-1.932 1.568-3.5 3.5-3.5s3.5 1.568 3.5 3.5z"
        fill="#1ABCFE"
      />
      <path
        d="M5 12c0-1.84 1.568-3.333 3.5-3.333H12v6.666H8.5C6.568 15.333 5 13.84 5 12z"
        fill="#A259FF"
      />
      <path
        d="M12 2h3.5C17.432 2 19 3.493 19 5.333s-1.568 3.334-3.5 3.334H12V2z"
        fill="#FF7262"
      />
      <path
        d="M5 5.333C5 3.493 6.568 2 8.5 2H12v6.667H8.5C6.568 8.667 5 7.173 5 5.333z"
        fill="#F24E1E"
      />
    </svg>
  );
}

export default SvgFigma;
