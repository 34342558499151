export function isStopWord(word: string) {
  return STOP_WORDS.has(word);
}

// stop word list ported from Tesla
export const STOP_WORDS = new Set<string>([
  'the',
  'a',
  'of',
  'and',
  'to',
  'in',
  'for',
  'that',
  'on',
  'is',
  'with',
  'at',
  'by',
  'it',
  'as',
  'but',
  'from',
  'be',
  'an',
  'was',
  'not',
  'this',
  'are',
  'has',
  'who',
  'he',
  'one',
  'or',
  'all',
  'if',
  'i',
  'no',
  'so',
  'we',
  'its',
  'do',
  'go',
  'our',
  'too',
  'my',
  'she',
  'her',
  'me',
]);
