// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1845
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'leave.dash_stack'
// Description: Leave a shared stack
// Owner: dash-web
export type Leave_DashStack = PAPEventGeneric<
  'stacks',
  'leave',
  'dash_stack',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // The stack id that represents the stack
    stackId?: string;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
  }
>;

export function PAP_Leave_DashStack(
  properties?: Leave_DashStack['properties'],
): Leave_DashStack {
  return <Leave_DashStack>{
    class: 'stacks',
    action: 'leave',
    object: 'dash_stack',
    properties,
  };
}
