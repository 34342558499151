// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2962
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'refresh.dash_suggested_link'
// Description: Fires when a user clicks the refresh link on the content suggestions
// Owner: dash-web
export type Refresh_DashSuggestedLink = PAPEventGeneric<
  'stacks',
  'refresh',
  'dash_suggested_link',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // Generated random request id for tracking suggestions in Dash
    dashRequestId?: string;
    // A hashed integer for unique predictions recommended in Dash
    predictionIdHash?: number;
    // A hashed integer for items/content recommended in Dash
    itemIdHash?: number;
    // unique session id for creating stacks on dash
    createStackSessionId?: string;
  }
>;

export function PAP_Refresh_DashSuggestedLink(
  properties?: Refresh_DashSuggestedLink['properties'],
): Refresh_DashSuggestedLink {
  return <Refresh_DashSuggestedLink>{
    class: 'stacks',
    action: 'refresh',
    object: 'dash_suggested_link',
    properties,
  };
}
