import { Button } from '@dropbox/dig-components/buttons';
import { DatePickerRange } from '@dropbox/dig-components/date_picker';
import { FormLabel, FormRow } from '@dropbox/dig-components/form_row';
import { Modal } from '@dropbox/dig-components/modal';
import i18n from '@mirage/translations';
import React from 'react';

import type { DatePickerRangeSelection } from '@dropbox/dig-components/date_picker';
// Type only import will not affect tree-shaking.
// eslint-disable-next-line no-restricted-imports
import type {
  DatePickerInputOnChangeFn,
  DatePickerOnSelectionFn,
} from '@dropbox/dig-components/dist/date_picker/typings';

export const LastUpdatedFilterModal = ({
  selectedRange,
  handleSelection,
  handleChangeStart,
  handleChangeEnd,
  onSubmit,
  onCancel,
  isOpen,
}: {
  selectedRange: DatePickerRangeSelection;
  handleSelection: DatePickerOnSelectionFn;
  handleChangeStart: DatePickerInputOnChangeFn;
  handleChangeEnd: DatePickerInputOnChangeFn;
  onSubmit: () => void;
  onCancel: () => void;
  isOpen: boolean;
}) => {
  return (
    <Modal
      open={isOpen}
      withCloseButton="Close"
      onRequestClose={onCancel}
      width={'small'}
    >
      <Modal.Header hasBottomSpacing="title-standard">
        <Modal.Title>{i18n.t('last_updated_filter_modal_title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <DatePickerRange
          selectedRange={selectedRange}
          onSelection={handleSelection}
          isPortaled={false}
          placement={'bottom-end'}
        >
          <FormRow split>
            <FormRow>
              <FormLabel htmlFor="last-updated-start-date">
                {i18n.t('last_updated_filter_modal_from')}
              </FormLabel>
              <DatePickerRange.StartInput
                id="last-updated-start-date"
                value={selectedRange[0]}
                onChange={handleChangeStart}
                autocomplete="off"
              />
            </FormRow>
            <FormRow>
              <FormLabel htmlFor="last-updated-end-date">
                {i18n.t('last_updated_filter_modal_to')}
              </FormLabel>
              <DatePickerRange.EndInput
                id="last-updated-end_date"
                value={selectedRange[1]}
                onChange={handleChangeEnd}
                autocomplete="off"
              />
            </FormRow>
          </FormRow>
        </DatePickerRange>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="opacity" onClick={onCancel}>
          {i18n.t('last_updated_filter_modal_cancel')}
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          {i18n.t('last_updated_filter_modal_apply')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
