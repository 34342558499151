import { tagged } from '@mirage/service-logging';
import * as primitives from '@mirage/service-typeahead-search/service/primitives';
import * as scoring from '@mirage/service-typeahead-search/service/scoring';
import { twoStageSearch } from '@mirage/service-typeahead-search/service/search/two-stage-search';
import { CacheKey } from '@mirage/service-typeahead-search/service/typeahead-cache';
import { SourceId } from '@mirage/service-typeahead-search/service/types';
import * as wrappers from '@mirage/service-typeahead-search/service/utils/wrappers';
import * as rx from 'rxjs';

import type { TypeaheadCache } from '@mirage/service-typeahead-search/service/typeahead-cache';
import type { typeahead } from '@mirage/service-typeahead-search/service/types';
import type { Recommendation } from '@mirage/shared/search/recommendation';
import type { Observable } from 'rxjs';

const logger = tagged('typeahead/recommendations');

const SOURCE_RESULT_LIMIT = 200;

/**
 * API
 */

export const search = wrappers.wrapped(SourceId.Recommendations, raw);

export function raw(
  query: string,
  config: typeahead.Config,
  cache: TypeaheadCache,
): Observable<typeahead.TaggedResult> {
  return rx.defer(() => {
    let results = cache.all(CacheKey.Recommendations) as Recommendation[];
    results = removeContacts(results);

    if (query === '') {
      logger.debug(`Getting matches with empty query`);
      results = matchesWithEmptyQuery(results);
    } else {
      results = matchesWithQuery(query, results);
    }

    return rx.from(tagAndInjectHits(results, config, cache));
  });
}

/**
 * Match with query
 */

function matchesWithQuery(
  query: string,
  recommendations: Recommendation[],
): Recommendation[] {
  // box recommendations into a SearchableResults array
  const searchableResultCandidates = recommendations.map((recommendation) => ({
    searchableStrings: [recommendation.title],
    originalResult: recommendation,
  }));

  // and let twoStageSearch handle the actual searching!
  const matchingSearchableResults = twoStageSearch(
    query,
    searchableResultCandidates,
    SOURCE_RESULT_LIMIT,
  );

  // and then we just need to extract the original results from the SearchableResults
  return matchingSearchableResults.map(
    (searchableResult) => searchableResult.originalResult,
  );
}

/**
 * Matches with empty query
 */

function matchesWithEmptyQuery(
  recommendations: Recommendation[],
): Recommendation[] {
  return sortLastClickedMsDesc(recommendations);
}

function sortLastClickedMsDesc(
  recommendations: Recommendation[],
): Recommendation[] {
  const sorted = recommendations.sort(
    (a: Recommendation, b: Recommendation) =>
      (b.lastClickedMs || 0) - (a.lastClickedMs || 0),
  );
  const limited = sorted.slice(0, SOURCE_RESULT_LIMIT);
  return limited;
}

/**
 * Helpers
 */

function removeContacts(candidates: Recommendation[]): Recommendation[] {
  return candidates.filter(
    (candidate) => candidate.recordType?.['.tag'] !== 'contact',
  );
}

function tagAndInjectHits(
  results: Recommendation[],
  config: typeahead.Config,
  cache: TypeaheadCache,
) {
  const scoreWithoutQuery = scoring.score('', config);
  return results.map((result) => {
    // we can have click tracking from the server through recommendations,
    // and we can have click tracking locally. The server can be delayed,
    // so in order to provide the best user experience, we use whichever
    // source has the highest non-title-match score for the recommendation
    // that way when the server catches up, it'll just start transparently
    // using the server-side score over the client-side score

    const resultWithLocalHits = primitives.recommendation(
      result.uuid,
      result,
      cache.getHits(result.uuid),
    );
    const resultWithRemoteHits = primitives.recommendation(
      result.uuid,
      result,
      {
        history: result.clicks,
        mostRecentMs: result.lastClickedMs,
      },
    );

    const localScore = scoreWithoutQuery(resultWithLocalHits).score;
    const remoteScore = scoreWithoutQuery(resultWithRemoteHits).score;

    return localScore > remoteScore
      ? resultWithLocalHits
      : resultWithRemoteHits;
  });
}
