import { tagged } from '@mirage/service-logging';
import {
  autoStackSuggestions,
  refreshStackSuggestions,
} from '@mirage/service-stack-suggestions';
import { useEffect, useRef, useState } from 'react';
import { AutoStackSuggestionData } from './service';

const logger = tagged('useStackSuggestions');

export const useStackSuggestions = () => {
  // Using a ref here instead of a state value to avoid a re-triggering
  // of subscribe hook due to a bug in the service layer that resets the
  // autoStack data on new subscriptions.
  const firstRefreshComplete = useRef(false);
  const [dataReady, setDataReady] = useState(false);
  const [suggestions, setSuggestions] = useState<AutoStackSuggestionData>({
    isLoading: false,
    autoStacks: [],
  });
  const delayedCall = useRef<NodeJS.Timeout | undefined>(undefined);
  const pendingRefresh = useRef(false);

  const callApi = async () => {
    logger.debug('calling refresh API');
    await refreshStackSuggestions();
    firstRefreshComplete.current = true;
    pendingRefresh.current = false;
  };

  const cleanup = () => {
    clearTimeout(delayedCall.current);
    pendingRefresh.current = false;
  };

  useEffect(() => {
    const { autoStacks } = suggestions;

    // Delay this immediate refresh to avoid blocking main paint
    // executing only after we've loaded the initial data set from cache
    if (dataReady && !firstRefreshComplete.current && !pendingRefresh.current) {
      pendingRefresh.current = true;
      clearTimeout(delayedCall.current);
      if (autoStacks.length === 0) {
        // No cached data, let's call the API sooner
        void callApi();
      } else {
        // We're just refreshing the cache here, so can afford to wait
        // a bit longer
        delayedCall.current = setTimeout(callApi, 5000);
      }
    }
  }, [dataReady, suggestions]);

  useEffect(() => {
    logger.debug('Subscribing to autoStackSuggestions');
    const sub = autoStackSuggestions().subscribe(
      ({ autoStacks, isLoading }) => {
        logger.debug('Received autoStacks', autoStacks.length);
        setSuggestions({
          autoStacks,
          isLoading:
            // show expected loading state if:
            // - first refresh is complete OR
            // - we have no previously cached autoStack data
            firstRefreshComplete.current || autoStacks.length === 0
              ? isLoading
              : false,
        });
        setDataReady(true);
      },
    );
    return () => sub.unsubscribe();
  }, []);

  return {
    ...suggestions,
    cleanupPendingRefresh: cleanup,
  };
};
