// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event Property Definition: https://pap.pp.dropbox.com/#/event_property/2248

import type { FeatureRolloutRing } from './feature_rollout_ring';

const FeatureRolloutRingValues: FeatureRolloutRing[] = [
  'internal_team',
  'internal_dash',
  'internal_dbx',
  'external_beta',
  'long_term_stability',
];

export function getFeatureRolloutRingFromString(
  s: string,
): FeatureRolloutRing | undefined {
  return FeatureRolloutRingValues.find((e) => e === s?.toLowerCase());
}
