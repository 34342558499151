// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgCoda({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.717 7.385c1.13 0 2.18.423 2.96 1.115a.782.782 0 001.284-.615V3.577c0-.846-.7-1.577-1.596-1.577H3.596C2.701 2 2 2.692 2 3.577V20.46C2 21.309 2.7 22 3.596 22h15.808C20.26 22 21 21.308 21 20.423v-4.308c0-.653-.779-1.038-1.285-.615-.817.692-1.83 1.115-2.959 1.115A4.624 4.624 0 0112.123 12c0-2.538 2.064-4.615 4.594-4.615z"
        fill="#EE5A29"
      />
    </svg>
  );
}

export default SvgCoda;
