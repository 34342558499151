// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3189
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';

// Event_name: 'archive.dash_stacks_archive'
// Description: Event triggered when archiving a stack
// Owner: content-ingest
export type Archive_DashStacksArchive = PAPEventGeneric<
  'stacks',
  'archive',
  'dash_stacks_archive',
  {
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
  }
>;

export function PAP_Archive_DashStacksArchive(
  properties?: Archive_DashStacksArchive['properties'],
): Archive_DashStacksArchive {
  return <Archive_DashStacksArchive>{
    class: 'stacks',
    action: 'archive',
    object: 'dash_stacks_archive',
    properties,
  };
}
