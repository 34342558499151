import { asterixOut, transformJsonResponse } from "@alwaysmeticulous/redaction";
import { RecorderMiddleware } from "@alwaysmeticulous/sdk-bundles-api";
import { dash_feed } from "@dropbox/api-v2-client";
import { StoredActivityFeed } from "@mirage/service-feed/service";

import { DEFAULT_REDACTED_URL } from "./utils";

const redactActivity = (
  activity: dash_feed.ActivityItem,
): dash_feed.ActivityItem => {
  return {
    ...activity,
    actor: {
      ...activity.actor,
      name: activity.actor?.name ? asterixOut(activity.actor.name) : undefined,
      email: activity.actor?.email
        ? asterixOut(activity.actor.email)
        : undefined,
      avatar_img: activity.actor?.avatar_img ? DEFAULT_REDACTED_URL : undefined,
    },
    object: {
      ...activity.object,
      name: activity.object?.name
        ? asterixOut(activity.object.name)
        : undefined,
      url: activity.object?.url ? DEFAULT_REDACTED_URL : undefined,
      preview_img: activity.object?.preview_img
        ? DEFAULT_REDACTED_URL
        : undefined,
    },
  };
};

export const redactActivityFeed = (
  activityFeed: StoredActivityFeed,
): StoredActivityFeed => {
  return {
    ...activityFeed,
    activities: activityFeed.activities?.map((activity) =>
      redactActivity(activity),
    ),
  };
};

export const redactListActivityFeedResponse: RecorderMiddleware =
  transformJsonResponse<dash_feed.ListActivityFeedResponse>({
    urlRegExp: /dash_feed\/list_activity_feed/,
    transform: (data) => ({
      ...data,
      activities: data?.items?.map((activity) => redactActivity(activity)),
    }),
  });
