import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';

/**
 * Tracking sheet for tk names:
 * https://docs.google.com/spreadsheets/d/1Er-9CIdsCRB1Rptr94TuTCt7d-9PYMU0RhdifiQjlYs/edit?gid=0#gid=0
 */
function getSupportUrlTkName() {
  switch (EnvCtx.surface) {
    case 'desktop':
      switch (EnvCtx.platform) {
        case 'darwin':
          return 'mac-desktop-support';
        case 'win32':
          return 'win-desktop-support';
      }
      break;

    case 'web':
      switch (EnvCtx.platform) {
        case 'darwin':
          return 'mac-webapp-support';
        case 'win32':
          return 'win-webapp-support';
      }
      break;

    case 'extension':
      switch (EnvCtx.platform) {
        case 'chrome':
          return 'extension-chrome-support';
        case 'edge':
          return 'extension-edge-support';
        case 'safari':
          return 'extension-safari-support';
        case 'firefox':
          return 'extension-firefox-support';
      }
      break;

    default:
      EnvCtx.surface satisfies never;
  }

  return '';
}

export function getSupportUrl() {
  return `https://dash.dropbox.com/help-center?_tk=${getSupportUrlTkName()}`;
}
