/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
// XXX: for some reason, regardless of the contents of "lib" in tsconfig, when
// hornet imports this from mirage it fails to make tsc happy. this is the
// easiest solution in the interim
/// <reference lib="es2021.weakref" />
import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { batchedIntervalFunc } from '@mirage/shared/util/batching';
// import redact from '@mirage/service-logging/redact';
import { createConsola, LogLevels } from 'consola';
import { BrowserReporter } from './browser-reporter';
import { normalize, serializable } from './helpers';

import type { LogMessage, Service } from '@mirage/service-logging/service';
import type { LogLevel, LogObject } from 'consola';

export type { Service };

// each context will have its own instance of consola's logger and allow for
// sub-context creation via tagging. these outputs will be fed into a custom
// reporter which sends information to the service to aggregate and transport
// log messages to the appropriate sinks
let resolveInitDone: (() => void) | undefined;

export const ExposeForTesting = {
  service: services.getp<Service>(ServiceId.LOGGING),
  instance: createConsola(),
  loggers: new Map<string, WeakRef<ReturnType<typeof createConsola>>>(),
  initDonePromise: new Promise<void>((resolve) => (resolveInitDone = resolve)),
};

const batchedSink = batchedIntervalFunc(
  (batch: LogMessage[]) => ExposeForTesting.service.transport(batch),
  // Batch to logging per second. This shouldn't be set too high as it might
  // cause us to lose some logs, but shouldn't be set too low as most of the
  // logs we have are not needed urgently.
  1000,
);

// Create a transport specific to our service-based cross-proc ingestion
// Expose for testing only.
export function log(log: LogObject) {
  if (!serializable(log)) {
    console.warn('attempting to log non-transferrable data, dropping!');
    return;
  }

  batchedSink(normalize(log));
}

export function initForLogLevel(consoleLogLevel: LogLevel) {
  // We have to manage setting our reporters and child reporters when the log
  // level changes, track local state here as well as our service ingest reporter
  const serviceReporter = { log };

  // This ensures that the console mirrors our log level without being
  // impacted by the consola logger level setting (i.e. filtering out
  // logs that need to go to other sinks)
  const browserReporter = new BrowserReporter(consoleLogLevel);

  const reporters = [serviceReporter, browserReporter];

  for (const [tag, ref] of ExposeForTesting.loggers.entries()) {
    // this child may have went out of scope and got gc'd, do cleanup here
    const child = ref.deref();
    if (!child) {
      ExposeForTesting.loggers.delete(tag);
      continue;
    }

    // Allow all logs to go from consola to the underlying reporters. The
    // reporters will then use their own log level to determine if a log should
    // be output or not.
    child.level = LogLevels.verbose;

    child.setReporters(reporters);
  }
}

// we have to manage setting our reporters and child reporters when the log
// level changes, track local state here as well as our service ingest reporter
ExposeForTesting.service
  .getConsoleLogLevel()
  .then(initForLogLevel)
  .catch((e) =>
    console.warn('failed to get current log level, using default ("debug")', e),
  )
  .finally(() => {
    resolveInitDone?.();
  });

// of course, nothing is as easy as it seems when it comes to software. we want
// to be able to programatically set the levels of _all_ loggers simultaneously,
// which, due to how consola creates tagged instances, we need to track them
// all locally when they are created as they are not referencing their parent
const METHOD_EXCEPTIONS: (string | symbol)[] = ['setReporters'];

export function tagged(tag: string) {
  const child = ExposeForTesting.instance.withTag(tag);

  const wrapped = new Proxy(child, {
    get(target, method, receiver) {
      const targeted = Reflect.get(target, method, receiver);
      if (!(targeted instanceof Function)) return targeted;

      if (METHOD_EXCEPTIONS.includes(method)) return targeted;

      // in the case this is a function call, wait for our level setting to have
      // completed before issuing the call
      return async (...args: Parameters<typeof targeted>) => {
        await ExposeForTesting.initDonePromise;
        return Reflect.apply(targeted, target, args);
      };
    },
  });

  ExposeForTesting.loggers.set(tag, new WeakRef(wrapped));
  return wrapped;
}

export function exportLogs() {
  ExposeForTesting.service.exportLogs();
}
