import { Button } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { Modal } from '@dropbox/dig-components/modal';
import { TextArea } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { DashAnswersFeedbackTag } from '@mirage/analytics/events/enums/dash_answers_feedback_tag';
import { createUxaElementId } from '@mirage/analytics/uxa';
import i18n from '@mirage/translations';
import { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from 'react';
import { SecondaryFeedbackOption } from './FeedbackUtils/FeedbackUtils';
import styles from './MessageSecondaryFeedback.module.css';

import type { AnswersActionComponent } from './types';

type MessageFeedbackProps = {
  isOpen: boolean;
  onClose: () => void;
  onClickFeedback: (
    feedback: DashAnswersFeedbackTag,
    otherFeedbackText?: string,
  ) => void;
  feedbackOptions: SecondaryFeedbackOption[];
  actionSurfaceComponent?: AnswersActionComponent;
};

const FORM_MIN_LENGTH = 8;
const FORM_MAX_LENGTH = 164;

export const MessageSecondaryFeedback: FC<MessageFeedbackProps> = ({
  isOpen,
  onClose,
  feedbackOptions,
  onClickFeedback,
  actionSurfaceComponent = 'inline_answer',
}) => {
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherFeedback, setOtherFeedback] = useState('');
  const [feedbackError, setFeedbackError] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleOnClick = (feedback: DashAnswersFeedbackTag) => {
    if (feedback === 'other') {
      setShowOtherInput(true);
    } else {
      onClickFeedback(feedback);
    }
  };

  const handleOtherFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (otherFeedback.length && !feedbackError) {
      onClickFeedback('other', otherFeedback);
    }
  };

  const handleFeedbackText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    // truncate feedback to max length in case they paste a long string
    setOtherFeedback(e.currentTarget?.value.slice(0, FORM_MAX_LENGTH));
    if (e.currentTarget?.value.length >= FORM_MAX_LENGTH + 1) {
      setFeedbackError(true);
    } else {
      setFeedbackError(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setOtherFeedback('');
      setShowOtherInput(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef?.current.focus();
    }
    if (!showOtherInput) {
      // reset form state
      setOtherFeedback('');
      setFeedbackError(false);
    }
  }, [showOtherInput]);

  return (
    <Modal
      open={isOpen}
      isCentered
      width="small"
      withCloseButton="Close"
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('feedback_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showOtherInput ? (
          <>
            <div className={styles.feedbackFormContainer}>
              <form onSubmit={handleOtherFormSubmit}>
                <TextArea
                  ref={inputRef}
                  placeholder={i18n.t('feedback_other_placeholder')}
                  value={otherFeedback}
                  onChange={handleFeedbackText}
                  isInvalid={feedbackError}
                />
                <div className={styles.feedbackFormButtonContainer}>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={
                      otherFeedback?.length <= FORM_MIN_LENGTH || feedbackError
                    }
                    data-uxa-log={createUxaElementId(`feedback_other_submit`, {
                      actionSurfaceComponent,
                      featureLine: 'answers',
                    })}
                  >
                    {i18n.t('send')}
                  </Button>
                  {feedbackError && (
                    <Text color="error">
                      {i18n.t('feedback_other_error_max_length_exceeded', {
                        maxLength: FORM_MAX_LENGTH + 1,
                      })}
                    </Text>
                  )}
                </div>
              </form>
            </div>
          </>
        ) : (
          <>
            <Text className={styles.styledText}>
              {i18n.t('feedback_subtitle')}
            </Text>
            <div className={styles.feedbackTags}>
              {feedbackOptions.map((option) => (
                <Chip
                  className={styles.styledChip}
                  onClick={() => handleOnClick(option.key)}
                  key={option.key}
                  data-uxa-log={createUxaElementId(`feedback_${option.key}`, {
                    actionSurfaceComponent,
                    featureLine: 'answers',
                  })}
                >
                  <Chip.Content>{option.value}</Chip.Content>
                </Chip>
              ))}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
