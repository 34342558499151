// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3514
import type { PAPEventGeneric } from "../base/event";
import type { ActionSurfaceComponent } from "../enums/action_surface_component";
import type { BrowserName } from "../enums/browser_name";
import type { DashActionSurface } from "../enums/dash_action_surface";
import type { Environment } from "../enums/environment";
import type { FeatureLine } from "../enums/feature_line";
import type { OperatingSystem } from "../enums/operating_system";

// Event_name: 'interact.search_call'
// Description: Request to dash made after a user queries the chat in Dash Assistant
// Owner: dash-web
export type Interact_SearchCall = PAPEventGeneric<
  'dash_assist',
  'interact',
  'search_call',
  {
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // This indicates the user's current browser
    browserName?: BrowserName;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Boolean event property that is set to true for Dash for Business (dfb) users.
    isDfbUser?: boolean;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // The contents of a search query
    queryString?: string;
    // The length of a query
    queryLength?: number;
    // Number of search results returned
    searchResultCount?: number;
    // Comma separated list of unique identifiers (UUIDv5) of the search results
    resultUuidList?: string;
  }
>;

export function PAP_Interact_SearchCall(properties?: Interact_SearchCall['properties']): Interact_SearchCall {
  return <Interact_SearchCall>{
    class: 'dash_assist',
    action: 'interact',
    object: 'search_call',
    properties,
  };
}
