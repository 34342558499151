import { areStacksReady } from '@mirage/service-stacks';
import { DashSpinnerAutofitContainer } from '@mirage/shared/dash-spinner/DashSpinnerAutofitContainer';
import { sleepMs } from '@mirage/shared/util/tiny-utils';
import { useEffect, useState } from 'react';

const enum State {
  INITIAL,
  LOADING,
  READY,
}

/**
 * Show a dash spinner while the page is loading.
 * @param loading Allow parent container to add additional loading state checks
 * @returns
 */
export const StacksLoadingContainer = ({
  loading,
  children,
}: {
  loading?: boolean;
  children: JSX.Element;
}): JSX.Element | null => {
  const [state, setState] = useState(State.INITIAL);
  const [recheck, setRecheck] = useState(false);

  useEffect(() => {
    if (state === State.READY) return;

    let canceled = false;

    function updateState(newState: State) {
      if (!canceled) {
        setState(newState);
      }
    }

    async function check() {
      const ready = await areStacksReady();

      if (ready && !loading) {
        updateState(State.READY);
        return;
      }

      updateState(State.LOADING);

      // Do a short polling loop to check for stack readiness.
      // `areStacksReady()` will return false only after login, but always
      // true after that, so that this code is usually not reachable.
      await sleepMs(20);

      setRecheck(!recheck);
    }

    void check();

    return () => {
      canceled = true;
    };
  }, [loading, recheck, state]);

  switch (state) {
    case State.INITIAL:
      // Don't let the UI flicker when loading.
      return null;

    case State.LOADING:
      return <DashSpinnerAutofitContainer />;

    case State.READY:
      return children;
  }
};
