import { useEffect } from "react";

import { useMirageAnalyticsContext } from "@mirage/analytics/AnalyticsProvider";
import { PAP_Start_DashLogin } from "@mirage/analytics/events/types/start_dash_login";
import { FullPageSpinner } from "@mirage/mosaics/FullPageSpinner";
import { authenticate } from "@mirage/service-auth";
import { RedirectState } from "@mirage/service-auth/types";
import { tagged } from "@mirage/service-logging";
import { logout } from "@mirage/service-logout";
import { useDashTitle } from "@mirage/shared/hooks/DashTitle";
import Sentry from "@mirage/shared/sentry";
import { sleepMs } from "@mirage/shared/util/tiny-utils";
import i18n from "@mirage/translations";
import { startLoginMetricTiming } from "@mirage/webapp/performance/LoginMetrics";

import { removeWebAuthCookie } from "../shared/webCookies";
import { sharedWorkerDatabase } from "../shared-worker/sharedWorkerStorage";

export const REDIRECT_TO_PATH_URL_PARAM = "redirectToPathEncoded";
const MAX_AUTHENTICATE_ATTEMPTS = 3;

const logger = tagged("Login");

export const Login = () => {
  useDashTitle(i18n.t("sign_in"));
  const { reportPapEvent } = useMirageAnalyticsContext();

  useEffect(() => {
    async function init() {
      // Start timing for regular login flow.
      startLoginMetricTiming("normal");
      reportPapEvent(PAP_Start_DashLogin(), true);

      // Sometimes the user has old leftover data in storage. This code will
      // get hit only when the user is currently logged out.
      try {
        await cleanUpOnSignIn();
      } catch (e) {
        logger.error("Error cleaning up on sign in. Ignoring error.", e);
        Sentry.captureMessage("Error cleaning up on sign in", "error", {
          originalException: e,
        });
      }

      const query = new URLSearchParams(location.search);
      const redirectToPathEncoded = query.get(REDIRECT_TO_PATH_URL_PARAM);
      const redirectState: RedirectState | null = redirectToPathEncoded
        ? { redirectToPathEncoded }
        : null;

      let attempt = 0;
      while (attempt < MAX_AUTHENTICATE_ATTEMPTS) {
        try {
          await authenticate(redirectState);
          break;
        } catch (e) {
          attempt++;
          if (attempt === MAX_AUTHENTICATE_ATTEMPTS) {
            const errorMessage = `Login: Error calling service-auth authenticate. Max attempts reached. Forcing user logout.`;
            logger.error(errorMessage, e);
            Sentry.captureMessage(errorMessage, "error", {
              originalException: e,
            });
            // Force logout
            await logout();
            return;
          }

          const warningMessage = `Login: Error calling service-auth authenticate. Retrying...`;
          logger.warn(warningMessage, e);
          Sentry.captureMessage(warningMessage, "warning", {
            originalException: e,
          });
          await sleepMs(1000);
        }
      }
    }

    init();
  }, [reportPapEvent]);

  return <FullPageSpinner spinnerId="Login" />;
};

async function cleanUpOnSignIn() {
  await sharedWorkerDatabase.clear();

  removeWebAuthCookie();
}
