import { asterixOut, transformJsonResponse } from "@alwaysmeticulous/redaction";
import { RecorderMiddleware } from "@alwaysmeticulous/sdk-bundles-api";
import { stacks } from "@dropbox/api-v2-client";
import { StoredRecentContent } from "@mirage/service-recent-content/service";
import { RecentContent } from "@mirage/service-recent-content/types";

import { DEFAULT_REDACTED_URL } from "./utils";

const redactUrlFromRecentContent = <T extends RecentContent>(
  recentContent: T,
): T => {
  return {
    ...recentContent,
    url: recentContent.url ? DEFAULT_REDACTED_URL : undefined,
    title: recentContent.title ? asterixOut(recentContent.title) : undefined,
  };
};

export const redactRecentContent = (
  recentContent: StoredRecentContent,
): StoredRecentContent => {
  return {
    ...recentContent,
    browserHistoryV3: recentContent.browserHistoryV3.map((entry) =>
      redactUrlFromRecentContent(entry),
    ),
    recentContentV3: recentContent.recentContentV3.map((entry) =>
      redactUrlFromRecentContent(entry),
    ),
    recentContentV4: recentContent.recentContentV4.map((entry) =>
      redactUrlFromRecentContent(entry),
    ),
  };
};

const redactRecentActivity = (
  recentActivity: stacks.RecentActivity,
): stacks.RecentActivity => {
  if (recentActivity[".tag"] === "webpage_visit") {
    return {
      ...recentActivity,
      page_title: asterixOut(recentActivity.page_title ?? ""),
      url: DEFAULT_REDACTED_URL,
    };
  }
  return recentActivity;
};

export const redactStacksGetRecentActivityResponse: RecorderMiddleware =
  transformJsonResponse<stacks.GetRecentActivityResponse>({
    urlRegExp: /stacks\/get_recent_activity/,
    transform: (data) => ({
      ...data,
      recent_activities: data?.recent_activities?.map((activity) =>
        redactRecentActivity(activity),
      ),
    }),
  });
