// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3295
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';

// Event_name: 'click.open_calendar_item'
// Description: Sent when the user clicks an event’s “Open on calendar” button
// Owner: dash-web
export type Click_OpenCalendarItem = PAPEventGeneric<
  'meetings',
  'click',
  'open_calendar_item',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // Meeting IDs that are uuidv4s as generated by neo4j. Used to tie together Dash meetings and related content.
    meetingId?: string;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
  }
>;

export function PAP_Click_OpenCalendarItem(
  properties?: Click_OpenCalendarItem['properties'],
): Click_OpenCalendarItem {
  return <Click_OpenCalendarItem>{
    class: 'meetings',
    action: 'click',
    object: 'open_calendar_item',
    properties,
  };
}
