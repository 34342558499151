import { useEffect } from "react";

import { FullPageSpinner } from "@mirage/mosaics/FullPageSpinner";
import * as logoutService from "@mirage/service-logout";
import { useDashTitle } from "@mirage/shared/hooks/DashTitle";
import i18n from "@mirage/translations";

export const Logout = () => {
  useDashTitle(i18n.t("sign_out"));

  useEffect(() => {
    logoutService.logout();
  }, []);

  return <FullPageSpinner spinnerId="Logout" />;
};
