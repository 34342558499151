// @generated by bzl gen
// @generated by protoc-gen-connect-web v0.3.1 with parameter "target=ts"
// @generated from file dropbox/proto/ml_capabilities/api_v2_service_streaming.proto (package ml_capabilities, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {
  CreateChatCompletionRequest,
  CreateChatCompletionResponse,
} from './api_v2_pb';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * The ML Capabilities endpoints are a catalog of generative & machine learning endpoints. They
 * provide an interface to various generative AI models & embedding models hosted by Dropbox and
 * select third-parties providers, such as OpenAI.
 *
 * These endpoints are organized into distinct namespaces corresponding to the specific type of
 * resource they are designed to operate upon, such as text or image. For example we have
 * `ml_capabilities/text/summary` to create a summary of a block of text &
 * `ml_capabilities/chat/completion` to generate a completion given a conversation.
 *
 * See the [ML Capabilities Tutorials](https://dropbox.atlassian.net/wiki/spaces/mlplatform/pages/1217954382/ML+Capabilities+Tutorials) for examples of how to use these endpoints.
 *
 * @generated from service ml_capabilities.MLCapabilitiesStreamingApiV2
 */
export const MLCapabilitiesStreamingApiV2 = {
  typeName: 'ml_capabilities.MLCapabilitiesStreamingApiV2',
  methods: {
    /**
     * Given a list of messages comprising a conversation, the model will return a stream response.
     * This is a gRPC endpoint which only supports gRPC clients, see [docs for details](https://dropbox.atlassian.net/wiki/spaces/CLABS/pages/1195314954/Create+an+APIv2+gRPC+gRPC-web+Only+Route)
     *
     *
     * **Example:**
     * ```json
     * {
     *     "model_info": { "model_name": "mixtral-8x7b-instruct-openai", "model_source": "kserve" },
     *     "dialogs": [
     *         {
     *             "messages": [
     *                 {
     *                     ".tag": "user_message",
     *                     "content": { ".tag": "text", "text": "what is the meaning of life" }
     *                 }
     *             ]
     *         }
     *     ]
     * }
     * ```
     *
     * @generated from rpc ml_capabilities.MLCapabilitiesStreamingApiV2.CreateChatCompletionStreaming
     */
    createChatCompletionStreaming: {
      name: 'CreateChatCompletionStreaming',
      I: CreateChatCompletionRequest,
      O: CreateChatCompletionResponse,
      kind: MethodKind.ServerStreaming,
    },
  },
} as const;
