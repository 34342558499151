import { APP_NAME } from '@mirage/shared/util/build-vars';
import { useEffect } from 'react';

export function useDashTitle(title?: string) {
  useEffect(() => {
    const oldTitle = document.title;

    // No need i18n for product name.
    document.title = title ? `${title} - ${APP_NAME}` : APP_NAME;

    return () => {
      document.title = oldTitle;
    };
  }, [title]);
}
