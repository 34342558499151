import { users } from '@dropbox/api-v2-client';
import { useInitDbxUxa } from './useInitDbxUxa';
import { useInitPapUxa } from './useInitPapUxa';

export function useInitUxa({
  enable,
  account,
}: {
  enable: boolean;
  account: users.FullAccount | undefined;
}) {
  // Send PAP events for UXA-marked elements.
  useInitPapUxa();

  // Use the Dropbox-provided UXA script.
  useInitDbxUxa({ enable, account });
}
