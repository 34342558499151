import { getLoginPathWithReturnRedirectURLParam } from '@mirage/service-auth';
import { useIsLoggedIn } from '@mirage/service-auth/useDropboxAuthentication';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { useUpdateExperimentationAttributes } from '@mirage/service-experimentation/useInitFeatureFlags';
import { usePreviewPublicStack, useStackByShareId } from '@mirage/stacks/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Retrieve the stack data based on the share id, the stack's public status
 * and the user's login status (and the feature flag).
 *
 * If the user is not logged in and the stack is not public, they will be
 * redirected to the login page. After login, they will be routed back to
 * this page.
 */
export function useStackOrRedirectToLogin(shareId: string) {
  const navigate = useNavigate();
  const [featureFlagsReady, setFeatureFlagsReady] = useState(false);
  const updateExperimentationAttributes = useUpdateExperimentationAttributes();
  const previewPublicStacks =
    useFeatureFlagValue('dash_2024_05_10_preview_public_stacks', false) ===
      'ON' && featureFlagsReady;
  const loggedIn = useIsLoggedIn();
  const stackData = useStackByShareId(loggedIn ? shareId : undefined);
  const publicStackData = usePreviewPublicStack(loggedIn ? undefined : shareId);

  useEffect(() => {
    if (loggedIn) {
      return;
    }

    async function setStackShareIdExperimentationAttribute() {
      // We need to know when the attributes have been applied to the feature
      // flag selections. This level of synchronization is necessary because
      // if we get a false negative, we will erroneously and immediately
      // redirect the user to the login page.
      await updateExperimentationAttributes({ custom_string: shareId });
      setFeatureFlagsReady(true);
    }
    void setStackShareIdExperimentationAttribute();
  }, [
    setFeatureFlagsReady,
    loggedIn,
    shareId,
    updateExperimentationAttributes,
  ]);

  useEffect(() => {
    // Once the required values have stabilized, we can determine if the user
    // can access the stack or if they need to be redirected to the login page.
    if (loggedIn) {
      // Only logged-out users need to be redirected
      return;
    }

    if (!featureFlagsReady) {
      // Don't attempt to redirect until we know the final state of the feature flags
      return;
    }

    if (previewPublicStacks) {
      // Public stacks are enabled by the feature flag so we may avoid
      // a redirect if certain conditions are met.

      if (!publicStackData) {
        // Wa're still wating for the API call to complete
        return;
      }

      if (publicStackData.stack) {
        // We successfully fetched the public stack data, no need to redirect
        return;
      }

      if (!publicStackData.error) {
        // We have no error, so we still don't know that the user cannot
        // access the stack.
        return;
      }

      // Replacing this for now until we understand why the type info is lost
      // and why the error.name field is being stomped on.
      //    https://jira.dropboxer.net/browse/DASHWEB-4859
      //
      // if (!(publicStackData?.error instanceof DropboxResponseError)) {
      //   // We have no error or an error that is not a DropboxResponseError.
      //   // We don't have definitive proof that the user must log in.
      //   return;
      // }

      if (!('status' in publicStackData.error)) {
        // The error has no status, so it's likely not an API error.
        return;
      }

      if (Math.floor((publicStackData.error.status as number) / 100) !== 4) {
        // The error is not a 4XX error, so we still don't know that the user
        // cannot access the stack.
        return;
      }

      // If we're here, the API call has failed with a 4XX error. We will
      // interpret this as the logged-out user not having access to the
      // stack and logging in could resolve that.
    } else {
      // Public stacks are not enabled by the feature flag. Go ahead and
      // redirect the user to the login page.
    }

    // Emulate the behavior of useAuthCheck.onLoggedOut() to redirect to login page
    async function redirectToLoginPage() {
      const loginPath = await getLoginPathWithReturnRedirectURLParam();
      navigate(loginPath);
    }
    void redirectToLoginPage();
  }, [
    navigate,
    shareId,
    loggedIn,
    publicStackData?.stack,
    featureFlagsReady,
    previewPublicStacks,
    publicStackData,
  ]);

  if (loggedIn === false && previewPublicStacks) {
    return { ...publicStackData, featureFlagsReady, publicPreview: true };
  }
  return { ...stackData, featureFlagsReady, publicPreview: false };
}
