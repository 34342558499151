// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgTrello({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.047 2H3.953A1.953 1.953 0 002 3.953v16.094C2 21.126 2.874 22 3.953 22h16.094A1.953 1.953 0 0022 20.047V3.953A1.953 1.953 0 0020.047 2z"
        fill="url(#trello_svg__paint0_linear)"
      />
      <path
        d="M18.462 4.6h-4.225a.937.937 0 00-.937.937v6.875c0 .518.42.938.937.938h4.225c.518 0 .938-.42.938-.938V5.537a.938.938 0 00-.938-.937zM9.763 4.6H5.538a.937.937 0 00-.938.937v11.876c0 .517.42.937.938.937h4.225c.517 0 .937-.42.937-.938V5.538a.937.937 0 00-.937-.937z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="trello_svg__paint0_linear"
          x1={12}
          y1={2}
          x2={12}
          y2={22}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0091E6" />
          <stop offset={1} stopColor="#0079BF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgTrello;
