import { useCallback, useEffect } from 'react';

type PasteAnywhereProps = {
  onPaste: (e: ClipboardEvent) => void;
};

/**
 * Hook that allows pasting anywhere in the document.
 * Will not trigger on paste events in text inputs or textareas.
 *
 * @param onPaste The function to call when a paste event occurs.
 */
export const usePasteAnywhere = ({ onPaste }: PasteAnywhereProps) => {
  const onPasteHandler = useCallback(
    (e: ClipboardEvent) => {
      // Check if the paste event is occurring in a text input or textarea
      const target = e.target as HTMLElement;
      if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
        return;
      }

      onPaste(e);
    },
    [onPaste],
  );

  useEffect(() => {
    document.addEventListener('paste', onPasteHandler);

    return () => document.removeEventListener('paste', onPasteHandler);
  }, [onPasteHandler]);
};
