import { stacks } from '@dropbox/api-v2-client';
import { IconButton } from '@dropbox/dig-components/buttons';
import { Menu, WrapperContentPropsFn } from '@dropbox/dig-components/menu';
import {
  TextInput,
  TextInputRefObject,
} from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CloseLine,
  OpenLine,
  SearchLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useAllStacksAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { DashStackIcon } from '@mirage/dash-component-library/components/DashStackIcon';
import { getDefaultColorIndex } from '@mirage/dash-component-library/themes/Stacks';
import { Link } from '@mirage/link/Link';
import { stackGetShareId } from '@mirage/service-stacks/service/utils';
import { useIsHoverFriendly } from '@mirage/shared/responsive/hover';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import {
  EMOJI_CLEARED_SENTINEL_VALUE,
  KeyCodes,
} from '@mirage/shared/util/constants';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { KeyboardEvent, useCallback, useEffect, useRef } from 'react';
import styles from './SearchMenuContent.module.css';

interface SearchResultItemProps {
  className: string | undefined;
  setRef: (ref: HTMLElement | null) => void;
  stack: stacks.Stack;
  onSelect: (stack: stacks.Stack) => void;
  onKeyDown: (e: KeyboardEvent<HTMLElement>) => void;
}

const SearchResultItem: React.FC<SearchResultItemProps> = ({
  className,
  setRef,
  stack,
  onSelect,
  onKeyDown,
}) => {
  const { name, emoji, color_index } = stack.stack_data || {};
  const url = `/stacks/${stackGetShareId(stack)}`;

  return (
    <Link
      ref={setRef}
      href={url}
      role="menuitem"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        onSelect(stack);
      }}
      onKeyDown={(e) => {
        onKeyDownCommitFn(() => {
          // Prevent double creation of link.
          e.preventDefault();

          onSelect(stack);
        })(e);

        onKeyDown?.(e);
      }}
      className={classNames(styles.suggestionsContainer, className)}
    >
      <div className={styles.suggestionsLeftContent}>
        <DashStackIcon
          emoji={emoji || EMOJI_CLEARED_SENTINEL_VALUE}
          colorIndex={color_index || getDefaultColorIndex()}
          size="small"
        />
        <DigTooltip title={`${name} · Stack`}>
          <Text className={styles.suggestionText}>
            {name}
            <Text color="faint"> · Stack</Text>
          </Text>
        </DigTooltip>
      </div>
      <div className={styles.suggestionsAddButton}>
        <IconButton variant="transparent" style={{ pointerEvents: 'none' }}>
          <UIIcon
            src={OpenLine}
            className={styles.openSuggestionIcon}
            size="large"
          />
        </IconButton>
      </div>
    </Link>
  );
};

interface SearchMenuContentProps {
  getContentProps: WrapperContentPropsFn;
  textInputRef: React.MutableRefObject<TextInputRefObject | null>;
  inputString: string;
  setInputString: React.Dispatch<React.SetStateAction<string>>;
  searchResults: stacks.Stack[];
  suggestionsClassName?: string;
  onSelectResult: (stack: stacks.Stack) => void;
  onShowResults?: (stack: stacks.Stack[]) => void;
  isLoading: boolean;
  closeMenu: () => void;
}

export const SearchMenuContent: React.FC<SearchMenuContentProps> = ({
  getContentProps,
  textInputRef,
  inputString,
  setInputString,
  searchResults,
  suggestionsClassName,
  onSelectResult,
  onShowResults,
  isLoading,
  closeMenu,
}) => {
  const linkRefs = useRef<(HTMLElement | null)[]>([]);

  // Convenience function to get the linkRef at index.
  const linkRefAt = useCallback((index: number) => {
    const a = linkRefs.current;
    if (index < 0) index = a.length + index;
    return index >= 0 ? a[index] : undefined;
  }, []);

  const isMobileSize = useIsMobileSize();
  const isHoverFriendly = useIsHoverFriendly();
  const augustRevision = useAllStacksAugustRevisionEnabled();

  useEffect(() => {
    onShowResults?.(searchResults);
  }, [searchResults, onShowResults]);

  useEffect(() => {
    linkRefs.current.length = searchResults.length;
  }, [searchResults]);

  // This is a bit counter-intuitive. Make the font bigger for
  // mobile size because in iOS Safari, the browser will auto-zoom
  // the entire page when the input font is too small.
  const inputSize = isMobileSize ? 'large' : 'medium';

  return (
    <Menu.Content
      {...getContentProps()}
      placement="right-start"
      triggerOffset={{
        mainAxis: -44,
        crossAxis: -11,
      }}
      className={classNames(styles.wrapper, isHoverFriendly || styles.noHover)}
      onKeyDown={(e) => {
        // Stop tab keys from closing the popup.
        if (e.key === KeyCodes.tab) {
          e.stopPropagation();
        }
      }}
    >
      <div className={styles.box}>
        <TextInput
          wrapperProps={{ className: styles.searchTextInputWrapper }}
          withLeftAccessory={
            <UIIcon src={SearchLine} className={styles.searchIcon} />
          }
          withRightAccessory={
            <DigTooltip title={i18n.t('close')}>
              <IconButton variant="borderless" onClick={() => closeMenu()}>
                <UIIcon src={CloseLine} />
              </IconButton>
            </DigTooltip>
          }
          isTransparent
          size={inputSize}
          value={inputString}
          placeholder={i18n.t('search_stacks')}
          ref={textInputRef}
          onChange={(e) => setInputString(e.target.value)}
          onKeyDown={(e) => {
            if (
              e.key === KeyCodes.arrowUp ||
              (e.key === KeyCodes.tab && e.shiftKey)
            ) {
              // Jump to the last link if it exists
              linkRefAt(-1)?.focus();
            } else if (e.key === KeyCodes.arrowDown) {
              // Jump to the first link if exists.
              linkRefAt(0)?.focus();
            }
          }}
        />
        {!augustRevision &&
          searchResults.map((stack, index) => (
            <SearchResultItem
              key={stack.namespace_id}
              className={suggestionsClassName}
              setRef={(ref) => {
                linkRefs.current[index] = ref;
              }}
              stack={stack}
              onSelect={onSelectResult}
              onKeyDown={(e) => {
                if (e.key === KeyCodes.arrowUp) {
                  if (index === 0) {
                    textInputRef.current?.focus();
                  } else {
                    linkRefAt(index - 1)?.focus();
                  }
                } else if (e.key === KeyCodes.arrowDown) {
                  if (index >= searchResults.length - 1) {
                    textInputRef.current?.focus();
                  } else {
                    linkRefAt(index + 1)?.focus();
                  }
                }
              }}
            />
          ))}
        {!augustRevision &&
          !isLoading &&
          inputString.length > 0 &&
          searchResults.length === 0 && (
            <Text color="faint" className={styles.noSearchResults}>
              {i18n.t('no_search_results')}
            </Text>
          )}
      </div>
    </Menu.Content>
  );
};
