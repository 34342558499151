// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1697
import type { PAPEventGeneric } from '../base/event';
import type { DashSuggestedLinkType } from '../enums/dash_suggested_link_type';
import type { DashSuggestionSource } from '../enums/dash_suggestion_source';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';
import type { TabGroupType } from '../enums/tab_group_type';

// Event_name: 'shown.dash_suggested_link'
// Description: An event fired when a suggested link is displayed to the user. Areas include the recommendation cards, content suggestions on a stack, and the start page calendar
// Owner: otc-startpage
export type Shown_DashSuggestedLink = PAPEventGeneric<
  'dash',
  'shown',
  'dash_suggested_link',
  {
    // stormcrow feature name
    feature?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The zero-index returned position of search result in a list of search results.
    resultPosition?: number;
    // Identifier for item being suggested by Dash ML
    itemId?: string;
    // Identifier for Dash predictions
    predictionId?: string;
    // Rank of the individual suggestion within the group
    predictionPosition?: number;
    // Generated random request id for tracking suggestions in Dash
    dashRequestId?: string;
    // A hashed integer for unique predictions recommended in Dash
    predictionIdHash?: number;
    // A hashed integer for items/content recommended in Dash
    itemIdHash?: number;
    // Meeting IDs that are uuidv4s as generated by neo4j. Used to tie together Dash meetings and related content.
    meetingId?: string;
    // zero-indexed position of a calendar item
    itemPosition?: number;
    // 0 indexed position of the calendar event/item
    calendarItemPosition?: number;
    // Type of Tab groups provided by dash
    tabGroupType?: TabGroupType;
    // fresh id that gets generated for every new session or instance of the chat window
    chatSessionId?: string;
    // An entry point string, used to describe how users are triggering the event as relevant (e.g. browse_file_row_context_menu)
    entryPoint?: string;
    // unique session id for creating stacks on dash
    createStackSessionId?: string;
    // Which ML endpoint a Dash suggested link was sourced from
    dashSuggestionSource?: DashSuggestionSource;
    // type of dash suggested_link
    dashSuggestedLinkType?: DashSuggestedLinkType;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
  }
>;

export function PAP_Shown_DashSuggestedLink(
  properties?: Shown_DashSuggestedLink['properties'],
): Shown_DashSuggestedLink {
  return <Shown_DashSuggestedLink>{
    class: 'dash',
    action: 'shown',
    object: 'dash_suggested_link',
    properties,
  };
}
