export const constructAbsoluteURL = (url: string) => {
  //TODO (gschaeffer): Find a way to infer the correct top-level domain such as .com when it is not included
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  } else {
    return `https://${url}`;
  }
};

export const absoluteOrURLFailover = (
  url: string,
  fallbackURLForLocalFiles: string,
) =>
  constructAbsoluteURL(
    url.startsWith('file://') && fallbackURLForLocalFiles.length > 0
      ? fallbackURLForLocalFiles
      : url,
  );

// Safe way to return the host (hostname+port) of the url.
export const getHost = (url: string, removeWww = true): string => {
  try {
    // This might throw an error, so need to handle properly.
    let host = new URL(url).host;
    if (removeWww && host && host.startsWith('www.')) {
      host = host.slice(4);
    }
    if (host) {
      return host;
    }
  } catch {
    // ignore error
  }

  url = url.toLowerCase();

  // Remove http prefix.
  if (url.startsWith('https://')) {
    url = url.slice('https://'.length);
  } else if (url.startsWith('http://')) {
    url = url.slice('http://'.length);
  }

  // Remove url path.
  if (url.includes('/')) {
    url = url.slice(0, url.indexOf('/'));
  }

  // Remove url search.
  if (url.includes('?')) {
    url = url.slice(0, url.indexOf('?'));
  }

  // Remove url hash.
  if (url.includes('#')) {
    url = url.slice(0, url.indexOf('#'));
  }

  // Remove WWW
  if (removeWww && url.startsWith('www.')) {
    url = url.slice(4);
  }

  return url;
};
