import { IconLayout } from '@dropbox/dash-component-library';
import { Avatar, AvatarWithGuestProps } from '@dropbox/dig-components/avatar';
import { Skeleton } from '@dropbox/dig-components/skeleton';
import classnames from 'classnames';
import styles from './StartAccessory.module.css';

export const ContentRowAvatar = ({
  className,
  ...props
}: AvatarWithGuestProps) => (
  <Avatar
    hasNoOutline={true}
    {...props}
    className={classnames(styles['dig-Avatar--auto'], className)}
  />
);

export const ContentRowIcon = ({
  icon,
  badge,
}: {
  icon: React.ReactNode;
  badge?: React.ReactNode;
}) => (
  <IconLayout
    content={icon}
    size="large"
    shouldConstrainContent={false}
    withBadge={
      badge && (
        <IconLayout.Badge isStyled={false} position="bottom-right">
          {badge}
        </IconLayout.Badge>
      )
    }
  />
);

export const SkeletonStartAccessory = ({
  shape,
}: {
  shape: 'square' | 'circle';
}) => {
  const content =
    shape === 'circle' ? (
      <Skeleton.Avatar className={styles['dig-Avatar--auto']} />
    ) : (
      <Skeleton.Box
        className={styles['dig-Avatar--auto']}
        width={40}
        height={40}
      />
    );
  return (
    <IconLayout
      content={content}
      shouldConstrainContent={false}
      size="large"
      shapeVariant={shape}
    />
  );
};
