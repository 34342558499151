import { useResizeObserver } from '@dropbox/dig-components/hooks';
import { FileIcon } from '@dropbox/dig-content-icons';
import { DashGlyph, GlyphLogo } from '@dropbox/dig-logos';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import styles from './FloatingAppTilesPage.module.css';

const FILE_TYPES: string[] = [
  'paper',
  'jpg',
  'pdf',
  'key',
  'gdoc',
  'odt',
  'doc',
  'ppt',
  'mp3',
  'xls',
  'csv',
  'gsheet',
  'url',
  'psd',
  'html',
  'gslides',
  'exe',
  'dwg',
  'framerx',
  'sketch',
  '3dm',
  'pkg',
  'zip',
  'ttf',
  'swf',
  'ai',
];
// Changes to TILE_SIZE should be changed in the css file
const TILE_SIZE = 120;
const SECTION_USAGE_RATIO = 0.4;

type TileStyle = {
  transition: string;
  left: string;
  top: string;
  rotate: number;
};

type FloatingAppTilesPageProps = {
  dbxCenterTile?: boolean;
  numberOfTiles?: number;
  randomize?: boolean;
};

export default function FloatingAppTilesPage({
  dbxCenterTile = false,
  numberOfTiles = FILE_TYPES.length,
  randomize = false,
}: FloatingAppTilesPageProps) {
  const [tileTypes, setTileTypes] = useState<string[]>([]);
  const [tileStyles, setTileStyles] = useState<TileStyle[]>([]);
  const { nodeRef: pageRef, observerEntry } = useResizeObserver();

  useEffect(() => {
    const tilePool = randomize
      ? FILE_TYPES.slice().sort(() => Math.random() - 0.5)
      : FILE_TYPES;
    setTileTypes(tilePool.slice(0, numberOfTiles));
  }, [randomize, numberOfTiles]);

  useEffect(() => {
    if (observerEntry) {
      const pageHeight = observerEntry.contentRect.height;
      const pageWidth = observerEntry.contentRect.width;
      const rowColCount = Math.floor(Math.sqrt(tileTypes.length));
      const sectionHeight = pageHeight / rowColCount;
      const sectionWidth = pageWidth / rowColCount;

      const styles = [];
      const sectionsX = Math.ceil(pageWidth / sectionWidth);
      const sectionsY = Math.ceil(pageHeight / sectionHeight);
      for (let x = 0; x < sectionsX; x++) {
        for (let y = 0; y < sectionsY; y++) {
          const left =
            x * sectionWidth + // Get section starting point
            sectionWidth / 2 - // Center item in section
            TILE_SIZE / 2 + // Offset by tile size
            (Math.random() * sectionWidth * 2 - sectionWidth) *
              SECTION_USAGE_RATIO; // Randomly place tile within section's borders
          const top =
            y * sectionHeight +
            sectionHeight / 2 -
            TILE_SIZE / 2 +
            (Math.random() * sectionHeight * 2 - sectionHeight) *
              SECTION_USAGE_RATIO;
          const rotation = Math.ceil(Math.random() * 26) - 10; // Between -12.5 and 12.5 degrees
          styles.push({
            transition: 'all 1s',
            left: `${left}px`,
            top: `${top}px`,
            rotate: rotation,
          });
        }
      }
      setTileStyles(styles);
    }
  }, [tileTypes, observerEntry]);

  return (
    <div className={styles.pageContainer} ref={pageRef}>
      {dbxCenterTile && (
        <div
          className={classnames(styles.tileContainer, styles.dbxTile)}
          style={{
            left: (observerEntry?.contentRect?.width || 0) / 2 - TILE_SIZE / 2,
            top: (observerEntry?.contentRect?.height || 0) / 2 - TILE_SIZE / 2,
          }}
        >
          <GlyphLogo src={DashGlyph} size={128} />
        </div>
      )}
      {tileStyles.map((style, index) => (
        <motion.div
          className={styles.tileContainer}
          key={tileTypes[index]}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: Math.random() * 0.5 }}
          style={style}
        >
          <FileIcon
            extension={tileTypes[index]}
            size="large"
            hasWhitespace={false}
            hasBackground={false}
            className={styles.fileIcon}
          />
        </motion.div>
      ))}
    </div>
  );
}
