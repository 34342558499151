import { useConsolidatedSubscription } from '@mirage/shared/hooks/useConsolidatedSubscription';
import { atom, useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { isPublicSharingAllowed, refreshPublicSharingStatus } from '..';

const publicSharingAllowedNoRefreshAtom = atom<boolean | undefined>(undefined);

export function useIsPublicSharingAllowed(withRefresh = false) {
  const [publicSharingAllowed, setPublicSharingAllowed] = useAtom(
    publicSharingAllowedNoRefreshAtom,
  );

  const getInitialData = useCallback(async () => true, []);

  const subscribe = useCallback(
    (update) => isPublicSharingAllowed(false).subscribe(update),
    [],
  );

  useConsolidatedSubscription({
    hookName: 'useIsPublicSharingAllowed',
    setState: setPublicSharingAllowed,
    getInitialData,
    subscribe,
  });

  useEffect(() => {
    if (withRefresh) {
      refreshPublicSharingStatus();
    }
  }, [withRefresh]);

  return publicSharingAllowed;
}
