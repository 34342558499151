// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3077
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'move.stacks_dnd_stack_suggestion'
// Description: Moving a suggested link using drag and drop
// Owner: content-ingest
export type Move_StacksDndStackSuggestion = PAPEventGeneric<
  'stacks',
  'move',
  'stacks_dnd_stack_suggestion',
  {
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
  }
>;

export function PAP_Move_StacksDndStackSuggestion(
  properties?: Move_StacksDndStackSuggestion['properties'],
): Move_StacksDndStackSuggestion {
  return <Move_StacksDndStackSuggestion>{
    class: 'stacks',
    action: 'move',
    object: 'stacks_dnd_stack_suggestion',
    properties,
  };
}
