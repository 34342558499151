import { stacks } from '@dropbox/api-v2-client';
import { Menu } from '@dropbox/dig-components/menu';
import { UIIcon } from '@dropbox/dig-icons';
import {
  DeleteLine,
  EditLine,
  LinkLine,
  MoreHorizontalLine,
  MoveLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Delete_DashLink } from '@mirage/analytics/events/types/delete_dash_link';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { deleteStackItem } from '@mirage/service-stacks';
import {
  DEFAULT_SECTION_ID,
  stackDerivePAPProps,
} from '@mirage/service-stacks/service/utils';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { showSnackbar } from '@mirage/shared/snackbar';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import { useCallback } from 'react';
import styles from './StackItemActionMenu.module.css';

type StackItemActionMenuProps = {
  stack: stacks.Stack | null;
  item: stacks.StackItemShortcut;
  setActionMenuOpen: (x: boolean) => void;
  onCopyLink: (x: string) => void;
  onEditItem: (x: stacks.StackItemShortcut) => void;
  currentSection?: stacks.Section;
  sections?: stacks.Section[];
  onMoveToSection?: (sectionId: string, item: stacks.StackItemShortcut) => void;
  hasWritePermissions: boolean;
};

export function StackItemActionMenu({
  stack,
  item,
  setActionMenuOpen,
  onCopyLink,
  onEditItem,
  onMoveToSection,
  currentSection,
  sections,
  hasWritePermissions,
}: StackItemActionMenuProps) {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isNewStack = !stack?.namespace_id;
  const removeStackItem = useCallback(
    async (fileId: string) => {
      if (isNewStack) {
        return;
      }

      reportPapEvent(
        PAP_Delete_DashLink({
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
        }),
      );
      await deleteStackItem(stack.namespace_id ?? '', fileId);
    },
    [isNewStack, stack, reportPapEvent],
  );

  const handleToggle = ({ isOpen }: { isOpen: boolean }) => {
    setActionMenuOpen(isOpen);
  };

  const onCopyStackItemLink = () => {
    onCopyLink(item.url ?? '');
    showSnackbar({ title: i18n.t('link_copied2') });
  };

  const onDeleteStackItem = async () => {
    if (stack) {
      await removeStackItem(item.api_file_id ?? '');
      showSnackbar({ title: i18n.t('item_deleted') });
    }
  };

  return (
    <Menu.Wrapper onToggle={handleToggle}>
      {({ getContentProps, getTriggerProps }) => (
        <>
          <IconButtonWithTooltip
            tooltipProps={{
              title: i18n.t('more'),
            }}
            variant="transparent"
            size="standard"
            className={styles.pointerEventReset}
            {...getTriggerProps()}
            data-uxa-log={createUxaElementId('stack_item_more_actions_button', {
              featureLine: 'stacks',
            })}
            data-testid="StacksItem-moreActionsButton"
          >
            <UIIcon
              src={MoreHorizontalLine}
              style={{ color: 'var(--dig-color__text__subtle)' }}
            />
          </IconButtonWithTooltip>
          <Menu.Content
            {...getContentProps()}
            className={styles.pointerEventReset}
            placement="bottom-end"
          >
            <Menu.Segment>
              <Menu.ActionItem
                onClick={onCopyStackItemLink}
                withLeftAccessory={<UIIcon src={LinkLine} />}
                data-uxa-log={createUxaElementId(
                  'stack_item_copy_link_button',
                  {
                    featureLine: 'stacks',
                  },
                )}
              >
                {i18n.t('copy_link')}
              </Menu.ActionItem>
              {hasWritePermissions && (
                <Menu.ActionItem
                  onClick={() => onEditItem(item)}
                  withLeftAccessory={<UIIcon src={EditLine} />}
                  data-uxa-log={createUxaElementId('stack_item_edit_button', {
                    featureLine: 'stacks',
                  })}
                >
                  {i18n.t('rename_stack_item_title')}
                </Menu.ActionItem>
              )}
              {hasWritePermissions &&
                currentSection &&
                sections &&
                onMoveToSection && (
                  <MenuActionItemMoveToSection
                    currentSection={currentSection}
                    sections={sections}
                    item={item}
                    moveToSection={onMoveToSection}
                  />
                )}
            </Menu.Segment>
            {hasWritePermissions && (
              <Menu.Segment>
                <Menu.ActionItem
                  onClick={onDeleteStackItem}
                  withLeftAccessory={<UIIcon src={DeleteLine} />}
                  data-uxa-log={createUxaElementId('stack_item_delete_button', {
                    featureLine: 'stacks',
                  })}
                  data-testid="StacksItem-deleteButton"
                >
                  {i18n.t('remove_from_stack')}
                </Menu.ActionItem>
              </Menu.Segment>
            )}
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
}

const MenuActionItemMoveToSection = ({
  currentSection,
  sections,
  item,
  moveToSection,
}: {
  currentSection: stacks.Section;
  sections: stacks.Section[];
  item: stacks.StackItemShortcut;
  moveToSection: (sectionId: string, item: stacks.StackItemShortcut) => void;
}) => {
  const isMobileSize = useIsMobileSize();

  const handleMoveToSection = (sectionId: string) => {
    moveToSection(sectionId, item);
  };

  if (sections.length <= 1) {
    return null;
  }

  const filteredSections = sections.filter(
    (section) => section.id !== currentSection.id,
  );

  return (
    <Menu.Submenu
      withTriggerContent={i18n.t('move_to_section')}
      withLeftAccessory={<UIIcon src={MoveLine} />}
      {...(isMobileSize ? { placement: 'bottom' } : undefined)}
    >
      <Menu.Segment>
        {filteredSections.map((section) => (
          <Menu.ActionItem
            key={section.id}
            onClick={() => handleMoveToSection(nonNil(section.id, 'sectionId'))}
            data-uxa-log={createUxaElementId('stack_item_move_section', {
              featureLine: 'stacks',
            })}
          >
            {section.id == DEFAULT_SECTION_ID
              ? i18n.t('default_section')
              : section.title}
          </Menu.ActionItem>
        ))}
      </Menu.Segment>
    </Menu.Submenu>
  );
};
