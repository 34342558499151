import { ServiceAdapter } from '@mirage/service-setting-actions/service';

export class WebSettingActionsAdapter implements ServiceAdapter {
  manualCheckForUpdates() {
    if (window) window.location.reload();
    return Promise.resolve();
  }

  async exit() {
    return Promise.reject('Web setting-actions adapter: Exit not implemented');
  }
}
