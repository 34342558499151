import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { directInitEnvCtx } from '../global-env-ctx';

import type { EnvironmentContext } from './types';
import type { KVStorage } from '@mirage/storage';

export type Service = Awaited<ReturnType<typeof setup>>;

export default async function setup(
  adapter: KVStorage<EnvironmentContext>,
  input: EnvironmentContext,
) {
  // Init the global `EnvCtx` for use everywhere in the main process.
  directInitEnvCtx(input);

  for (const [k, v] of Object.entries(input)) {
    await adapter.set(k as keyof EnvironmentContext, v);
  }

  async function internalGetAll(): Promise<EnvironmentContext> {
    return input;
  }

  return services.provide(
    ServiceId.ENVIRONMENT_CONTEXT,
    { internalGetAll },
    [],
  );
}
