import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AiLine,
  CalendarLine,
  EmojiFrownLine,
  SearchLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { DashGlyph, GlyphLogo } from '@dropbox/dig-logos';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashConnectAppPageConfirmSkipAddingAppsModal } from '@mirage/analytics/events/types/open_dash_connect_app_page_confirm_skip_adding_apps_modal';
import { PAP_Select_DashConnectAppPageConfirmSkipAddingAppsModalAddApps } from '@mirage/analytics/events/types/select_dash_connect_app_page_confirm_skip_adding_apps_modal_add_apps';
import { PAP_Select_DashConnectAppPageConfirmSkipAddingAppsModalSkipForNow } from '@mirage/analytics/events/types/select_dash_connect_app_page_confirm_skip_adding_apps_modal_skip_for_now';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import { ComponentType, SVGAttributes, useEffect } from 'react';
import styles from './ConfirmSkipAddingAppsModal.module.css';

const FEATURES: [ComponentType<SVGAttributes<SVGElement>>, string, string][] = [
  [SearchLine, i18n.t('search'), i18n.t('search_feature_description')],
  [AiLine, i18n.t('answers'), i18n.t('answers_feature_description')],
  [CalendarLine, i18n.t('calendar'), i18n.t('calendar_feature_description')],
];

export const ConfirmSkipAddingAppsModal = ({
  finishOnboarding,
  onRequestClose,
  open,
}: {
  finishOnboarding: () => void;
  onRequestClose: () => void;
  open: boolean;
}) => {
  const isMobile = useIsMobileSize();
  const { reportPapEvent } = useMirageAnalyticsContext();

  useEffect(() => {
    if (open) {
      reportPapEvent(PAP_Open_DashConnectAppPageConfirmSkipAddingAppsModal());
    }
  }, [open, reportPapEvent]);

  const handleSkipForNowClick = () => {
    reportPapEvent(
      PAP_Select_DashConnectAppPageConfirmSkipAddingAppsModalSkipForNow(),
    );
    finishOnboarding();
  };

  const handleAddAppsClick = () => {
    reportPapEvent(
      PAP_Select_DashConnectAppPageConfirmSkipAddingAppsModalAddApps(),
    );
    onRequestClose();
  };

  return (
    <Modal
      open={open}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick
      isCentered
      shouldFocusAfterRender
      width={600}
    >
      <Modal.Header className={styles.modalSpotlight}>
        <div className={styles.spotlightContainer}>
          <div className={styles.spotlightContent}>
            <Box display="flex" alignItems="center">
              <GlyphLogo size={56} src={DashGlyph} />
              <div className={styles.grayBar} />
            </Box>
            <Box
              color="Disabled Surface"
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <UIIcon className={styles.frownIcon} src={EmojiFrownLine} />
              <Title
                className={styles.noResults}
                color="disabled"
                size={isMobile ? 'medium' : 'large'}
                weightVariant="emphasized"
              >
                {i18n.t('no_results')}
              </Title>
            </Box>
          </div>
        </div>
      </Modal.Header>
      <Modal.Header className={styles.modalTitle}>
        <Modal.Title size={isMobile ? 'small' : 'medium'}>
          {i18n.t('confirm_skip_adding_apps_modal_title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box marginBottom={isMobile ? '16' : '24'}>
          <Text
            size={isMobile ? 'medium' : 'large'}
            className={styles.modalText}
          >
            {isMobile ? (
              i18n.t('confirm_skip_adding_apps_modal_text_mobile')
            ) : (
              <>
                {i18n.t('confirm_skip_adding_apps_modal_text_line_1')}
                <br />
                {i18n.t('confirm_skip_adding_apps_modal_text_line_2')}
              </>
            )}
          </Text>
        </Box>
        {FEATURES.map(([Icon, name, description]) => (
          <DashFeature
            Icon={Icon}
            description={description}
            key={name}
            name={name}
            isMobile={isMobile}
          />
        ))}
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button
          className={styles.skipButton}
          onClick={handleSkipForNowClick}
          variant={isMobile ? 'borderless' : 'transparent'}
          fullWidth={isMobile}
          size={isMobile ? 'large' : 'medium'}
        >
          {i18n.t('skip_for_now')}
        </Button>
        <Button
          className={styles.addAppsButton}
          fullWidth={isMobile}
          onClick={handleAddAppsClick}
          size="xlarge"
          variant="primary"
        >
          {i18n.t('add_apps')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const DashFeature = ({
  Icon,
  name,
  description,
  isMobile,
}: {
  Icon: ComponentType<SVGAttributes<SVGElement>>;
  name: string;
  description: string;
  isMobile: boolean;
}) => (
  <Box
    alignItems={isMobile ? 'flex-start' : 'center'}
    color="Text Subtle"
    display="flex"
    key={name}
    paddingX={isMobile ? '0' : '24'}
    paddingY="12"
  >
    <UIIcon src={Icon} className={styles.featureIcon} />
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
      <Text
        size={isMobile ? 'medium' : 'large'}
        isBold
        className={styles.featureTitle}
      >
        {name}
      </Text>
      <Text size={isMobile ? 'medium' : 'large'}>{description}</Text>
    </Box>
  </Box>
);
