import { useEffect } from 'react';

import type { users } from '@dropbox/api-v2-client';

export function useInitDbxUxa({
  enable,
  account,
}: {
  enable: boolean;
  account: users.FullAccount | undefined;
}) {
  useEffect(() => {
    if (!enable) return;

    loadUxaScript();

    if (!account) return;

    updateUxaAttributes(account);
  }, [enable, account]);
}

function addUXAMetaTag(attribute: string, content?: string) {
  if (!content) return;
  let metaEl = document.querySelector(`meta[${attribute}]`);
  if (metaEl) {
    metaEl.setAttribute(attribute, content);
  } else {
    metaEl = document.createElement('meta');
    metaEl.setAttribute(attribute, content);
    document.head.appendChild(metaEl);
  }
}

function updateUxaAttributes(account: users.FullAccount | undefined) {
  addUXAMetaTag(
    'data-uxa-external-user-id',
    account?.uid ? account.uid.toString() : undefined,
  );
  addUXAMetaTag('data-uxa-external-team-id', account?.team?.id);
  window?.dispatchEvent(
    new CustomEvent('delayed_page_view_with_late_meta_tag'),
  );
}

function loadUxaScript() {
  const scriptTag = document.querySelector(`script[src*="ux_analytics"]`);
  if (scriptTag) return;

  const script = document.createElement('script');
  script.src = 'https://www.dropbox.com/pithos/ux_analytics';
  script.async = true;
  document.head.appendChild(script);
  addUXAMetaTag('data-uxa-app-name', 'dash');
}
