import { EndReason } from '@mirage/analytics/events/enums/end_reason';
import { PAP_Finish_DashAnswers } from '@mirage/analytics/events/types/finish_dash_answers';
import { PAP_Finish_DashAnswersSession } from '@mirage/analytics/events/types/finish_dash_answers_session';
import { PAP_Finish_DashAppSession } from '@mirage/analytics/events/types/finish_dash_app_session';
import { PAP_Finish_DashSession } from '@mirage/analytics/events/types/finish_dash_session';
import { PAP_Finish_SearchAttempt } from '@mirage/analytics/events/types/finish_search_attempt';
import { PAP_Finish_SearchSession } from '@mirage/analytics/events/types/finish_search_session';
import { PAP_Initiate_DashAppSession } from '@mirage/analytics/events/types/initiate_dash_app_session';
import { PAP_Leave_DashStartPage } from '@mirage/analytics/events/types/leave_dash_start_page';
import {
  ANSWER_DWELL_END_REASONS,
  ANSWERS_SESSION_END_REASONS,
  DASH_APP_DWELL_END_REASONS,
  DASH_SESSION_END_REASONS,
  SEARCH_ATTEMPT_END_REASONS,
  SEARCH_SESSION_END_REASONS,
} from '@mirage/analytics/session/session-utils';
import { ChatEntryPoint } from '@mirage/conversations/types';
import { tagged } from '@mirage/service-logging';
import { get as getSettingValue } from '@mirage/service-settings';
import { reportEvent } from '../logging';
import {
  dashAppDwellManager,
  dashSessionManager,
  explicitAnswerDwellManager,
  explicitAnswersSessionManager,
  implicitAnswerDwellManager,
  searchAttemptManager,
  searchSessionManager,
} from './sessionManagers';

const logger = tagged('session-managers-callbacks');

const getDashAnnotationMode = async () => {
  const isEnabled = await getSettingValue('annotationMode');
  return isEnabled ? 'query_manual_labeling_v1' : undefined;
};

/**
 * Create Session Callbacks
 */

dashAppDwellManager.setCreateSessionCallback((session) => {
  logger.info('Reporting PAP_Initiate_DashAppSession');
  reportEvent(
    PAP_Initiate_DashAppSession({
      dashSessionId: session.id,
      startTimeMs: session.startTime,
      featureLine: 'session_tracking',
    }),
    true,
  );
});

explicitAnswersSessionManager.setCreateSessionCallback((session) => {
  dashSessionManager.extendOrCreateSession(undefined, session.startTime);
  session.properties.dashSessionId =
    dashSessionManager.getSessionIdOrUndefined();
});

// When Search Session is extended, extend Dash Session
searchSessionManager.setExtendOrCreateSessionCallback((session) => {
  dashSessionManager.extendOrCreateSession(undefined, session.startTime);
});

// When Search Attempt is extended, extend Search Session
searchAttemptManager.setExtendOrCreateSessionCallback((session) => {
  searchSessionManager.extendOrCreateSession(undefined, session.startTime);
});

explicitAnswersSessionManager.setExtendOrCreateSessionCallback((session) => {
  const entryPoint = session.properties.entryPoint;
  // We want search result summarization actions to count towards search session
  if (
    entryPoint === ChatEntryPoint.search_result_summary ||
    entryPoint === ChatEntryPoint.search_result_doc_question
  ) {
    // Extend dash session when anwers session is created
    dashSessionManager.extendOrCreateSession(undefined, session.startTime);
    session.properties.dashSessionId =
      dashSessionManager.getSessionIdOrUndefined();
    searchSessionManager.extendOrCreateSession();
  }
});

searchAttemptManager.setCreateSessionCallback((session) => {
  searchSessionManager.extendOrCreateSession(undefined, session.startTime);

  session.properties.dashSessionId =
    dashSessionManager.getSessionIdOrUndefined();
  session.properties.searchSessionId =
    searchSessionManager.getSessionIdOrUndefined();
});

/**
 * End Session Callbacks
 */

dashAppDwellManager.setEndSessionCallback((session) => {
  logger.info(
    'Reporting PAP_Finish_DashAppSession and PAP_Leave_DashStartPage',
  );
  reportEvent(
    PAP_Finish_DashAppSession({
      startTimeMs: session.startTime,
      endTimeMs: session.endTime ?? undefined,
      endReason: session.endReason as EndReason,
      dashSessionId: session.id,
      featureLine: 'session_tracking',
    }),
    true,
  );
  reportEvent(
    PAP_Leave_DashStartPage({
      startTimeMs: session.startTime,
      dashSessionId: session.id,
      startpageSessionId: session.id,
      dwellTimeMs: (session.endTime || Date.now()) - session.startTime,
      featureLine: 'session_tracking',
    }),
  );
});

dashSessionManager.setEndSessionCallback((session) => {
  logger.info(`Reporting PAP_Finish_DashSession ${session?.id}`);

  reportEvent(
    PAP_Finish_DashSession({
      endReason: session.endReason as EndReason,
      dashSessionId: session?.id,
      featureLine: 'session_tracking',
    }),
    true,
  );
});

explicitAnswersSessionManager.setEndSessionCallback((session) => {
  reportEvent(
    PAP_Finish_DashAnswersSession({
      dashAnswersSessionId: session.id,
      numAnswersProvided: session.properties.numAnswersProvided || 0,
      numQuestionsAsked: session.properties.numQuestionsAsked || 0,
      startTimeMs: session.startTime,
      endTimeMs: session.endTime || 0,
      endReason: session.endReason as EndReason,
      sessionStartReason: session.startReason,
      entryPoint: session.properties.entryPoint,
      searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      dashSessionId: dashSessionManager.getSessionIdOrUndefined(),
      dashAnswerRequestId: session.properties.dashAnswerRequestId,
      actionSurfaceComponent: session.properties.actionSurfaceComponent,
      featureLine: 'session_tracking',
    }),
  );
});

explicitAnswerDwellManager.setEndSessionCallback((session) => {
  reportEvent(
    PAP_Finish_DashAnswers({
      answerId: session.properties.answerId,
      answerString: session.properties.answerString,
      queryString: session.properties.queryString,
      numQuestionsAsked: session.properties.numQuestionsAsked || 0,
      numAnswerSources: session.properties.numAnswerSources || 0,
      endTimeMs: session.endTime || 0,
      startTimeMs: session.startTime,
      endReason: session.endReason as EndReason,
      entryPoint: session.properties.entryPoint,
      dashAnswersSessionId: session.properties.dashAnswersSessionId,
      searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      dashAnswerRequestId: session.properties.dashAnswerRequestId,
      actionSurfaceComponent: session.properties.actionSurfaceComponent,
      featureLine: 'session_tracking',
    }),
  );
});

implicitAnswerDwellManager.setEndSessionCallback(async (session) => {
  const dashAnnotationMode = await getDashAnnotationMode();
  reportEvent(
    PAP_Finish_DashAnswers({
      answerId: session.properties.answerId,
      answerString: session.properties.answerString,
      queryString: session.properties.queryString,
      numAnswerSources: session.properties.numAnswerSources || 0,
      endTimeMs: session.endTime || 0,
      startTimeMs: session.startTime,
      endReason: session.endReason as EndReason,
      entryPoint: session.properties.entryPoint,
      dashAnswersSessionId: session.properties.dashAnswersSessionId,
      searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      searchRequestId: session.properties.searchRequestId,
      actionSurfaceComponent: session.properties.actionSurfaceComponent,
      featureLine: session.properties.featureLine,
      dashAnnotationMode,
    }),
  );
});

searchSessionManager.setEndSessionCallback(async (session) => {
  const dashSession = dashSessionManager.getSession();

  logger.info(
    `Reporting PAP_Finish_SearchSession. ${session.type}: ${session?.id}, ${dashSession?.type} = ${dashSession?.id}`,
  );

  if (searchAttemptManager.getSession()) {
    searchAttemptManager.endSession(
      SEARCH_ATTEMPT_END_REASONS.PARENT_SESSION_ENDED,
    );
  }

  const dashAnnotationMode = await getDashAnnotationMode();

  reportEvent(
    PAP_Finish_SearchSession({
      searchSessionId: session.id,
      dashSessionId: dashSession?.id,
      // TODO: startSurface: StartSurface.START_PAGE,
      // TODO: endSurface: EndSurface.SEARCH,
      startTimeMs: session.startTime,
      endTimeMs: session.endTime || 0,
      endReason: session.endReason as EndReason,
      hasSerpOpened: !!session.properties.hasSerpOpened,
      hasQuery: !!session.properties.hasQuery,
      featureLine: 'search',
      sessionStartReason: session.startReason,
      dashAnnotationMode,
    }),
  );
});

searchAttemptManager.setEndSessionCallback(async (session) => {
  const dashAnnotationMode = await getDashAnnotationMode();

  logger.info('Reporting PAP_Finish_SearchAttempt');

  // When Search Attempt ends, there are no children session to end
  reportEvent(
    PAP_Finish_SearchAttempt({
      searchAttemptId: session.id,
      searchSessionId: session.properties.searchSessionId,
      startTimeMs: session.startTime,
      endTimeMs: session.endTime || 0,
      endReason: session.endReason as EndReason,
      featureLine: 'search',
      dashAnnotationMode,
    }),
  );
});

/**
 * Func to end all sessions (if they exist). This can be used when user exits the page
 */
export const endAllSessions = () => {
  logger.info('Ending all sessions');
  dashAppDwellManager.endSession(DASH_APP_DWELL_END_REASONS.EXIT_PAGE);
  dashSessionManager.endSession(DASH_SESSION_END_REASONS.EXIT_PAGE);
  searchSessionManager.endSession(SEARCH_SESSION_END_REASONS.EXIT_PAGE);
  searchAttemptManager.endSession(SEARCH_ATTEMPT_END_REASONS.EXIT_PAGE);
  explicitAnswersSessionManager.endSession(
    ANSWERS_SESSION_END_REASONS.EXIT_PAGE,
  );
  explicitAnswerDwellManager.endSession(ANSWER_DWELL_END_REASONS.EXIT_PAGE);
  implicitAnswerDwellManager.endSession(ANSWER_DWELL_END_REASONS.EXIT_PAGE);
};
