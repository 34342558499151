import { callApiV2 } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import {
  getContentAnswerRequest,
  getContentSummaryRequest,
} from '@mirage/shared/stack-item-summary/llmConfigs';
import {
  LinkContent,
  LinkSummarySuccess,
  Question,
  QuestionAnswer,
} from '@mirage/shared/stack-item-summary/types';
import * as rx from 'rxjs';
import { createChatCompletionObservable } from '../ml_capabilities_apiv2/chat_completions';
import { getLinkContent } from './fetch_metadata';

const logger = tagged('fetch_summary_qna');

async function getPersistedLinkSummary(
  url: string,
): Promise<LinkSummarySuccess | undefined> {
  try {
    const persistedSummary = await callApiV2(
      'stacksGetPersistedStackLinkSummary',
      {
        url: url,
      },
    );

    if (persistedSummary && persistedSummary.summary) {
      logger.debug('Found persisted summary');
      return {
        type: 'success',
        response: {
          short_summary: persistedSummary.summary || '',
          key_points: [],
        },
        content: { url: url, title: '', text: '' },
        created: persistedSummary.last_updated_time_utc_ms || Date.now(),
      };
    }
  } catch (error) {
    logger.error('Cannot fetch persisted summary');
  }

  return undefined;
}

export function getLinkSummary(
  url: string,
  regenerate: boolean,
): rx.Observable<LinkSummarySuccess> {
  return rx.from(getPersistedLinkSummary(url)).pipe(
    rx.switchMap((persistedSummary) => {
      if (persistedSummary && !regenerate) {
        return rx.of(persistedSummary);
      }
      return rx.from(getLinkContent(url)).pipe(
        rx.switchMap((content) => {
          if (!content) {
            throw new Error('Failed to fetch content');
          }

          const request = getContentSummaryRequest(content.title, content.text);
          const summary$ = createChatCompletionObservable(request);

          let finalSummary: LinkSummarySuccess;

          return summary$.pipe(
            rx.scan((acc, chunk) => {
              const newSummary = (acc.response?.short_summary || '') + chunk;
              finalSummary = {
                ...acc,
                response: { short_summary: newSummary, key_points: [] },
                content: content as LinkContent,
                created: Date.now(),
              } as LinkSummarySuccess;
              return finalSummary;
            }, {} as LinkSummarySuccess),
            rx.finalize(() => {
              // Persist the final summary after streaming is done
              if (finalSummary) {
                callApiV2('stacksSetPersistedStackLinkSummary', {
                  url: content.url,
                  summary: finalSummary.response.short_summary,
                  update_time_utc_ms: Date.now(),
                });
              }
            }),
          );
        }),
      );
    }),
  );
}

async function fetchContentIfNeeded(
  summary: LinkSummarySuccess,
): Promise<string> {
  let contentText = summary.content.text;
  if (!contentText) {
    const fetchedContent = await getLinkContent(summary.content.url);
    if (fetchedContent && fetchedContent.text) {
      contentText = fetchedContent.text;
      summary.content = fetchedContent; // Update summary with new content
      logger.debug('Updated summary with new content');
    }
  }
  return contentText;
}

export function getAnswerForQuestion(
  summary: LinkSummarySuccess,
  question: Question,
  id: string,
): rx.Observable<QuestionAnswer> {
  return rx.from(fetchContentIfNeeded(summary)).pipe(
    rx.switchMap((contentText) => {
      if (contentText) {
        const request = getContentAnswerRequest(summary, question.text);
        return createChatCompletionObservable(request).pipe(
          rx.scan((fullAnswer, chunk) => fullAnswer + chunk, ''),
          rx.map((fullAnswer) => ({
            question,
            answer: { text: fullAnswer, timestamp: Date.now() },
            id: id,
          })),
        );
      } else {
        throw new Error('Content text is empty');
      }
    }),
  );
}
