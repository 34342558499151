// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgFirefox({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.279 8.947c-.435-1.049-1.317-2.182-2.009-2.54.564 1.107.89 2.218 1.014 3.046l.002.017c-1.132-2.829-3.052-3.97-4.62-6.453-.08-.125-.159-.25-.236-.384a2.877 2.877 0 01-.11-.21 1.827 1.827 0 01-.156-.413.027.027 0 00-.016-.009.036.036 0 00-.019 0 .02.02 0 00-.004.003c-.003 0-.005.003-.008.004l.004-.006c-2.515 1.477-3.368 4.208-3.447 5.575a5.005 5.005 0 00-2.758 1.064 2.962 2.962 0 00-.259-.196 4.661 4.661 0 01-.028-2.453c-1.028.47-1.828 1.211-2.41 1.867h-.005c-.397-.504-.369-2.166-.346-2.513-.005-.022-.296.152-.334.178-.35.25-.677.532-.978.84a8.772 8.772 0 00-.935 1.124 8.48 8.48 0 00-1.344 3.04l-.013.067c-.019.088-.087.53-.099.626v.022c-.089.457-.144.92-.165 1.385v.051c0 5.542 4.483 10.035 10.013 10.035 4.953 0 9.065-3.603 9.87-8.337.017-.128.03-.258.046-.387.199-1.721-.023-3.53-.65-5.043zM9.738 16.803c.046.022.09.046.138.068l.007.004a7.028 7.028 0 01-.145-.072zm10.549-7.329v-.01.012-.002z"
        fill="url(#firefox_svg__paint0_linear)"
      />
      <path
        d="M21.279 8.948c-.435-1.05-1.317-2.183-2.009-2.54.564 1.106.89 2.217 1.014 3.045v.02c.945 2.568.43 5.178-.312 6.774-1.147 2.467-3.925 4.997-8.274 4.874-4.694-.134-8.833-3.63-9.606-8.204-.14-.722 0-1.088.071-1.674-.086.452-.119.582-.162 1.385v.051c0 5.542 4.483 10.035 10.013 10.035 4.953 0 9.065-3.603 9.87-8.337.017-.128.03-.258.046-.387.198-1.721-.024-3.53-.651-5.042z"
        fill="url(#firefox_svg__paint1_radial)"
      />
      <path
        d="M21.279 8.948c-.435-1.05-1.317-2.183-2.009-2.54.564 1.106.89 2.217 1.014 3.045v.02c.945 2.568.43 5.178-.312 6.774-1.147 2.467-3.925 4.997-8.274 4.874-4.694-.134-8.833-3.63-9.606-8.204-.14-.722 0-1.088.071-1.674-.086.452-.119.582-.162 1.385v.051c0 5.542 4.483 10.035 10.013 10.035 4.953 0 9.065-3.603 9.87-8.337.017-.128.03-.258.046-.387.198-1.721-.024-3.53-.651-5.042z"
        fill="url(#firefox_svg__paint2_radial)"
      />
      <path
        d="M16.413 10.127c.022.015.042.03.062.046a5.447 5.447 0 00-.93-1.216c-3.112-3.12-.815-6.764-.428-6.949l.004-.005c-2.516 1.476-3.369 4.207-3.447 5.574.117-.008.233-.018.351-.018 1.878 0 3.512 1.035 4.388 2.568z"
        fill="url(#firefox_svg__paint3_radial)"
      />
      <path
        d="M12.032 10.75c-.017.25-.896 1.11-1.204 1.11-2.848 0-3.31 1.727-3.31 1.727.125 1.454 1.136 2.65 2.359 3.284.056.03.112.055.166.08.098.043.197.084.295.122.42.148.858.232 1.303.25 4.99.235 5.958-5.98 2.356-7.785.922-.16 1.88.211 2.414.588-.875-1.533-2.51-2.568-4.388-2.568-.118 0-.234.01-.351.018A5.005 5.005 0 008.916 8.64c.153.13.326.303.689.661.68.674 2.423 1.366 2.427 1.448z"
        fill="url(#firefox_svg__paint4_radial)"
      />
      <path
        d="M12.032 10.75c-.017.25-.896 1.11-1.204 1.11-2.848 0-3.31 1.727-3.31 1.727.125 1.454 1.136 2.65 2.359 3.284.056.03.112.055.166.08.098.043.197.084.295.122.42.148.858.232 1.303.25 4.99.235 5.958-5.98 2.356-7.785.922-.16 1.88.211 2.414.588-.875-1.533-2.51-2.568-4.388-2.568-.118 0-.234.01-.351.018A5.005 5.005 0 008.916 8.64c.153.13.326.303.689.661.68.674 2.423 1.366 2.427 1.448z"
        fill="url(#firefox_svg__paint5_radial)"
      />
      <path
        d="M8.451 8.308c.081.052.148.097.208.137a4.661 4.661 0 01-.028-2.453c-1.028.47-1.828 1.211-2.41 1.867.047 0 1.5-.027 2.23.449z"
        fill="url(#firefox_svg__paint6_radial)"
      />
      <path
        d="M2.093 12.917c.773 4.575 4.911 8.07 9.609 8.204 4.349.123 7.125-2.407 8.274-4.874.742-1.596 1.257-4.206.312-6.773v-.02l.002.017c.355 2.325-.825 4.577-2.669 6.1a.353.353 0 00-.005.012c-3.594 2.933-7.033 1.77-7.729 1.295a5.794 5.794 0 01-.145-.073c-2.096-1.002-2.961-2.916-2.776-4.557-1.768 0-2.372-1.496-2.372-1.496s1.589-1.135 3.682-.148c1.939.915 3.76.149 3.76.148-.004-.081-1.747-.776-2.426-1.447-.363-.358-.536-.531-.689-.661a2.964 2.964 0 00-.259-.196c-.06-.042-.125-.084-.208-.138-.73-.476-2.183-.45-2.231-.448h-.005c-.397-.504-.369-2.167-.346-2.514-.005-.021-.296.152-.334.178-.35.25-.677.532-.978.84a8.772 8.772 0 00-.935 1.124 8.48 8.48 0 00-1.344 3.04c-.008.02-.364 1.579-.188 2.387z"
        fill="url(#firefox_svg__paint7_radial)"
      />
      <path
        d="M15.545 8.957c.366.36.68.769.93 1.216.052.04.102.081.15.125 2.27 2.095 1.083 5.06.992 5.273 1.845-1.523 3.024-3.775 2.67-6.1-1.133-2.83-3.053-3.97-4.62-6.454-.08-.125-.16-.25-.237-.384a2.877 2.877 0 01-.11-.21 1.827 1.827 0 01-.156-.413.027.027 0 00-.016-.009.036.036 0 00-.019 0 .02.02 0 00-.004.003c-.003 0-.005.003-.008.004-.387.185-2.684 3.83.428 6.95z"
        fill="url(#firefox_svg__paint8_radial)"
      />
      <path
        d="M16.625 10.296a1.946 1.946 0 00-.15-.125l-.063-.046c-.534-.376-1.492-.749-2.414-.588 3.601 1.805 2.635 8.02-2.356 7.785a4.445 4.445 0 01-1.303-.25 5.518 5.518 0 01-.294-.12 2.938 2.938 0 01-.167-.082l.007.005c.696.476 4.135 1.64 7.728-1.295l.006-.013c.089-.209 1.276-3.174-.994-5.27z"
        fill="url(#firefox_svg__paint9_radial)"
      />
      <path
        d="M7.518 13.587s.462-1.726 3.31-1.726c.308 0 1.188-.862 1.204-1.111.016-.25-1.821.766-3.76-.148-2.093-.987-3.681.148-3.681.148s.603 1.495 2.372 1.495c-.186 1.641.68 3.554 2.775 4.558.047.022.09.047.139.068-1.223-.632-2.233-1.83-2.359-3.284z"
        fill="url(#firefox_svg__paint10_radial)"
      />
      <path
        d="M21.279 8.947c-.435-1.049-1.317-2.182-2.009-2.54.564 1.107.89 2.218 1.014 3.046l.002.017c-1.132-2.829-3.052-3.97-4.62-6.453-.08-.125-.159-.25-.236-.384a2.877 2.877 0 01-.11-.21 1.827 1.827 0 01-.156-.413.027.027 0 00-.016-.009.036.036 0 00-.019 0 .02.02 0 00-.004.003c-.003 0-.005.003-.008.004l.004-.006c-2.515 1.477-3.368 4.208-3.447 5.575.117-.008.233-.018.352-.018 1.877 0 3.512 1.035 4.387 2.568-.534-.376-1.492-.749-2.414-.588 3.602 1.805 2.635 8.02-2.356 7.785a4.445 4.445 0 01-1.303-.25 5.518 5.518 0 01-.294-.12 2.944 2.944 0 01-.167-.082l.007.005a5.778 5.778 0 01-.145-.073c.046.022.09.047.138.069-1.225-.634-2.235-1.832-2.36-3.286 0 0 .461-1.727 3.309-1.727.308 0 1.188-.86 1.204-1.11-.004-.082-1.746-.777-2.426-1.448-.363-.358-.535-.53-.688-.66a2.962 2.962 0 00-.26-.197 4.661 4.661 0 01-.027-2.453C7.6 6.462 6.8 7.203 6.22 7.86h-.005c-.397-.504-.37-2.166-.347-2.513-.005-.022-.296.152-.333.178-.35.25-.678.532-.979.84a8.772 8.772 0 00-.935 1.124 8.48 8.48 0 00-1.344 3.04l-.013.067c-.019.088-.104.536-.115.632 0 .008 0-.007 0 0-.078.464-.128.931-.15 1.4v.052c0 5.542 4.483 10.035 10.013 10.035 4.953 0 9.065-3.603 9.87-8.337.017-.128.03-.258.046-.387.199-1.721-.023-3.53-.65-5.043zm-.993.516v.01-.01z"
        fill="url(#firefox_svg__paint11_linear)"
      />
      <defs>
        <radialGradient
          id="firefox_svg__paint1_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(20.8747 0 0 20.9205 19.185 4.3)"
        >
          <stop offset={0.13} stopColor="#FFBD4F" />
          <stop offset={0.19} stopColor="#FFAC31" />
          <stop offset={0.25} stopColor="#FF9D17" />
          <stop offset={0.28} stopColor="#FF980E" />
          <stop offset={0.4} stopColor="#FF563B" />
          <stop offset={0.47} stopColor="#FF3750" />
          <stop offset={0.71} stopColor="#F5156C" />
          <stop offset={0.78} stopColor="#EB0878" />
          <stop offset={0.86} stopColor="#E50080" />
        </radialGradient>
        <radialGradient
          id="firefox_svg__paint2_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(20.8747 0 0 20.9205 11.558 12.852)"
        >
          <stop offset={0.3} stopColor="#960E18" />
          <stop offset={0.35} stopColor="#B11927" stopOpacity={0.74} />
          <stop offset={0.43} stopColor="#DB293D" stopOpacity={0.34} />
          <stop offset={0.5} stopColor="#F5334B" stopOpacity={0.09} />
          <stop offset={0.53} stopColor="#FF3750" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="firefox_svg__paint3_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(15.1228 0 0 15.156 14.075 -.449)"
        >
          <stop offset={0.13} stopColor="#FFF44F" />
          <stop offset={0.25} stopColor="#FFDC3E" />
          <stop offset={0.51} stopColor="#FF9D12" />
          <stop offset={0.53} stopColor="#FF980E" />
        </radialGradient>
        <radialGradient
          id="firefox_svg__paint4_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(9.93926 0 0 9.96107 9.249 18.317)"
        >
          <stop offset={0.35} stopColor="#3A8EE6" />
          <stop offset={0.47} stopColor="#5C79F0" />
          <stop offset={0.67} stopColor="#9059FF" />
          <stop offset={1} stopColor="#C139E6" />
        </radialGradient>
        <radialGradient
          id="firefox_svg__paint5_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(-13.926 48.363 -43.36) scale(5.26654 6.19894)"
        >
          <stop offset={0.21} stopColor="#9059FF" stopOpacity={0} />
          <stop offset={0.28} stopColor="#8C4FF3" stopOpacity={0.06} />
          <stop offset={0.75} stopColor="#7716A8" stopOpacity={0.45} />
          <stop offset={0.97} stopColor="#6E008B" stopOpacity={0.6} />
        </radialGradient>
        <radialGradient
          id="firefox_svg__paint6_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(7.1506 0 0 7.1663 11.294 3.443)"
        >
          <stop stopColor="#FFE226" />
          <stop offset={0.12} stopColor="#FFDB27" />
          <stop offset={0.3} stopColor="#FFC82A" />
          <stop offset={0.5} stopColor="#FFA930" />
          <stop offset={0.73} stopColor="#FF7E37" />
          <stop offset={0.79} stopColor="#FF7139" />
        </radialGradient>
        <radialGradient
          id="firefox_svg__paint7_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(30.5078 0 0 30.5747 16.915 -1.102)"
        >
          <stop offset={0.11} stopColor="#FFF44F" />
          <stop offset={0.46} stopColor="#FF980E" />
          <stop offset={0.62} stopColor="#FF5634" />
          <stop offset={0.72} stopColor="#FF3647" />
          <stop offset={0.9} stopColor="#E31587" />
        </radialGradient>
        <radialGradient
          id="firefox_svg__paint8_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(84.245 5.745 8.841) scale(22.2854 14.6102)"
        >
          <stop stopColor="#FFF44F" />
          <stop offset={0.06} stopColor="#FFE847" />
          <stop offset={0.17} stopColor="#FFC830" />
          <stop offset={0.3} stopColor="#FF980E" />
          <stop offset={0.36} stopColor="#FF8B16" />
          <stop offset={0.45} stopColor="#FF672A" />
          <stop offset={0.57} stopColor="#FF3647" />
          <stop offset={0.74} stopColor="#E31587" />
        </radialGradient>
        <radialGradient
          id="firefox_svg__paint9_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(19.046 0 0 19.0878 11.126 6.097)"
        >
          <stop offset={0.14} stopColor="#FFF44F" />
          <stop offset={0.48} stopColor="#FF980E" />
          <stop offset={0.59} stopColor="#FF5634" />
          <stop offset={0.66} stopColor="#FF3647" />
          <stop offset={0.9} stopColor="#E31587" />
        </radialGradient>
        <radialGradient
          id="firefox_svg__paint10_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(20.846 0 0 20.8917 16.153 7.215)"
        >
          <stop offset={0.09} stopColor="#FFF44F" />
          <stop offset={0.23} stopColor="#FFE141" />
          <stop offset={0.51} stopColor="#FFAF1E" />
          <stop offset={0.63} stopColor="#FF980E" />
        </radialGradient>
        <linearGradient
          id="firefox_svg__paint0_linear"
          x1={19.954}
          y1={5.209}
          x2={3.296}
          y2={21.245}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.05} stopColor="#FFF44F" />
          <stop offset={0.11} stopColor="#FFE847" />
          <stop offset={0.22} stopColor="#FFC830" />
          <stop offset={0.37} stopColor="#FF980E" />
          <stop offset={0.4} stopColor="#FF8B16" />
          <stop offset={0.46} stopColor="#FF672A" />
          <stop offset={0.53} stopColor="#FF3647" />
          <stop offset={0.7} stopColor="#E31587" />
        </linearGradient>
        <linearGradient
          id="firefox_svg__paint11_linear"
          x1={19.754}
          y1={5.123}
          x2={5.579}
          y2={19.267}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.17} stopColor="#FFF44F" stopOpacity={0.8} />
          <stop offset={0.27} stopColor="#FFF44F" stopOpacity={0.63} />
          <stop offset={0.49} stopColor="#FFF44F" stopOpacity={0.22} />
          <stop offset={0.6} stopColor="#FFF44F" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgFirefox;
