import { Text } from '@dropbox/dig-components/typography';
import { AvatarSettings } from '@mirage/service-settings/service/types';
import { useCallback } from 'react';
import ListContainer from './components/list-items/ListContainer';
import ListItem from './components/list-items/ListItem';
import styles from './GeneralSettings.module.css';
import { SettingsModal } from './types/modal';

type Props = Readonly<{
  preferenceSections: Array<PreferenceSection>;
  onModal?: (modal: SettingsModal) => void;
}>;

export type PreferenceSection = {
  name: string;
  key: string;
  preferences: Array<PreferenceOption>;
};

export type OnClickTypes = string | number;
export type OnClick = (option?: OnClickTypes) => void;

export type GateOptions =
  | 'extensionInstalled'
  | 'enableAppToNearestOption'
  | 'enableAppTypeOption'
  | 'enableOpenFilesInDesktopApps'
  | 'isDropboxer'
  | 'internalDesktop';

export type GeneralSections =
  | 'theme'
  | 'preferences'
  | 'webPreferences'
  | 'more'
  | 'debug'
  | 'security';

export type PreferenceOption = {
  name: string;
  label: string | JSX.Element;
  /**
   * key used for the setting, e.g. appShortcut
   */
  settingKey?: keyof AvatarSettings;
  componentType?: 'checkbox' | 'button' | 'space' | 'radio' | 'segment-buttons';
  value?: number | string;
  onClick?: OnClick;
  icon?: React.ReactNode;
  modalData?: SettingsModal;
  /**
   * used for selects and radios
   */
  options?: Array<{
    label: string;
    optionValue: number;
  }>;
  ariaLabel?: string;
  role?: string;
  /**
   * when a UI meaning is the opposite of stored setting
   * e.g. UI: "Show Dash on startup"  - stored key: hideAppOnStartup
   */
  invert?: boolean;
  /**
   * used to show/hide settings with feature flags or other booleans
   */
  gateSetting?: GateOptions;
  /**
   * which section the setting will appear,
   */
  section?: GeneralSections;
  /**
   * Pass through for disabled state
   */
  disabled?: boolean;
  /**
   * Right hand side actions
   */
  displayActions?: React.ReactNode | null;
  /**
   * Only display actions on hover
   */
  displayActionsOnHover?: boolean;
  /**
   * Label subtext
   */
  subtitle?: string;
  /**
   * optional tooltip title
   */
  tooltipTitle?: string;
};

export default function GeneralSettings({
  preferenceSections,
  onModal,
}: Props) {
  const onPreferenceClick = useCallback(
    (option: PreferenceOption, value?: OnClickTypes) => {
      if (option.modalData && onModal) {
        onModal(option.modalData);
      } else if (option.onClick) {
        option.onClick(value);
      }
    },
    [],
  );

  return (
    <div className={styles.container}>
      {preferenceSections.map((section) => {
        return (
          <div key={section.key}>
            <div className={styles.listHeader}>
              <Text
                className={styles.listHeaderTitle}
                size="medium"
                tagName="h2"
                isBold
              >
                {section.name}
              </Text>
              <div className={styles.lineSeparator} />
            </div>
            <ListContainer
              items={section.preferences}
              height={`${section.preferences.length * 56}px`}
            >
              {(item: PreferenceOption) => (
                <ListItem
                  key={item.name}
                  title={item.label}
                  componentType={item.componentType}
                  textColor={item.name === 'quit' ? 'error' : 'standard'}
                  onClick={(value?: OnClickTypes) =>
                    onPreferenceClick(item, value)
                  }
                  value={item.value}
                  options={item.options}
                  ariaLabel={item.ariaLabel}
                  role={item.role}
                  icon={item.icon}
                  disabled={item.disabled}
                  displayActions={item.displayActions}
                  subtitle={item.subtitle}
                  displayActionsOnHover={item.displayActionsOnHover}
                  tooltipTitle={item.tooltipTitle}
                />
              )}
            </ListContainer>
          </div>
        );
      })}
    </div>
  );
}
