import { ProgressBar } from '@dropbox/dig-components/progress_indicators';
import { Title } from '@dropbox/dig-components/typography';
import { LookMagnifyingGlassSpot } from '@dropbox/dig-illustrations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Select_DashUxa } from '@mirage/analytics/events/types/select_dash_uxa';
import { useMarkChecklistItemComplete } from '@mirage/growth/onboarding/getting-started-checklist/useOnboardingChecklist';
import { StacksLoadingContainer } from '@mirage/mosaics/StacksLoading/StacksLoadingContainer';
import { auditLogUserOpenedStack } from '@mirage/service-audit-logging';
import { getLoginPathWithReturnRedirectURLParam } from '@mirage/service-auth';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { useIsLoggedIn } from '@mirage/service-auth/useDropboxAuthentication';
import { callApiV2 } from '@mirage/service-dbx-api';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { tagged } from '@mirage/service-logging';
import {
  MetricPageName,
  StackDetailsModule,
} from '@mirage/service-operational-metrics/module/constants';
import {
  useAutoRecordAggregatedPageLatency,
  useRecordModuleLatency,
} from '@mirage/service-operational-metrics/module/module';
import {
  getStacksDataCachedTags,
  useStacksDataCachedTags,
} from '@mirage/service-operational-metrics/module/tags';
import { logPageLoadMilestoneOnce } from '@mirage/service-operational-metrics/page-load';
import { previewStack } from '@mirage/service-stacks';
import { useDashTitle } from '@mirage/shared/hooks/DashTitle';
import { Backoff } from '@mirage/shared/util/backoff';
import { FullScreenPublicStackV2, FullScreenStackV2 } from '@mirage/stacks';
import { useSetActiveStack } from '@mirage/stacks/ActiveStack/atomHooks';
import { useEnrichedStackItems } from '@mirage/stacks/hooks';
import i18n from '@mirage/translations';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStackOrRedirectToLogin } from './hooks';
import styles from './StackPage.module.css';

const logger = tagged('StackPage');

interface StackPageProps {
  shareId: string;
}

// A simple wrapper to be used by hornet
export const StackPageWithoutShareId = () => {
  const { shareId } = useParams();
  return <StackPage shareId={shareId ?? ''} />;
};

const enum ViewMode {
  Loading,
  Loaded,
  NotFound,
}

export const StackPage = ({ shareId }: StackPageProps) => {
  const [viewMode, setViewMode] = useState(ViewMode.Loading);
  const { stack, items, publicPreview, featureFlagsReady } =
    useStackOrRedirectToLogin(shareId);
  const currentAccount = useDropboxAccount();
  const [showSharingModule, setShowSharingModule] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const markGettingStartedTaskComplete = useMarkChecklistItemComplete();
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();
  const enrichedStackItems = useEnrichedStackItems(stack?.namespace_id, items);

  // Initialize the active stack atoms
  useSetActiveStack(stack ?? null, enrichedStackItems ?? null);

  // Detect the condition where the stack was rendered, then disappears.
  const lastRenderedShareId = useRef('');

  const tryRedirectToLoginPage = useCallback(
    async function () {
      if (isLoggedIn || !featureFlagsReady || publicPreview) {
        return false;
      }
      const loginPath = await getLoginPathWithReturnRedirectURLParam();
      navigate(loginPath);
      return true;
    },
    [navigate, isLoggedIn, publicPreview, featureFlagsReady],
  );

  const hasSentCanonicalRequest: MutableRefObject<string | null> = useRef(null);
  const tryRedirectToCanonicalShareUrl = useCallback(
    async function () {
      if (hasSentCanonicalRequest.current === shareId) {
        return false;
      }
      hasSentCanonicalRequest.current = shareId;
      const { canonical_sharing_id: canonicalShareId } = await callApiV2(
        'stacksGetCanonicalSharingId',
        {
          sharing_id: shareId,
        },
      );
      if (canonicalShareId && canonicalShareId !== shareId) {
        navigate(`/stacks/${canonicalShareId}`, { replace: true });
        return true;
      }
      return false;
    },
    [navigate, shareId],
  );

  useDashTitle(stack?.stack_data?.name);

  useEffect(() => {
    // Switched to a different stack, so reset the last share id.
    if (
      lastRenderedShareId.current &&
      shareId !== lastRenderedShareId.current
    ) {
      lastRenderedShareId.current = '';
    }

    // If stack was rendered, but is now gone, then display not found.
    if (lastRenderedShareId.current && !stack) {
      setViewMode(ViewMode.NotFound);
      return;
    }

    setViewMode(ViewMode.Loading);
  }, [shareId, stack]);

  useEffect(() => {
    let canceled = false;

    async function previewLoop(): Promise<void> {
      logPageLoadMilestoneOnce(`previewLoop start`);

      // Use local cache only for the first call.
      let refresh = false;

      // Need to keep refreshing because the suggested stack items are added
      // one-by-one (non-batched). Cypress does not support batched adds (yet).
      const backoff = new Backoff(5000, 30_000);

      async function onError() {
        if (await tryRedirectToCanonicalShareUrl()) {
          return;
        }
        if (await tryRedirectToLoginPage()) {
          return;
        }
        setViewMode(ViewMode.NotFound);
        await backoff.sleep();
      }

      // Try for a while, but don't spin forever.
      for (
        let attemptsLeft = 20;
        attemptsLeft > 0 && !canceled;
        attemptsLeft--
      ) {
        try {
          const response = await previewStack(shareId, {
            refresh,
            fetchInBackground: true,
          });

          // Handling undefined in case the error is not thrown. Seen this in
          // Chrome but not sure about exact repro steps.
          if (!response) {
            await onError();
            continue;
          }

          refresh = true;
          // Mark not loading as soon as we get a non-error response.
          // Otherwise we won't know when we have finished loading.
          setViewMode(ViewMode.Loaded);
          lastRenderedShareId.current = shareId;
          return;
        } catch {
          await onError();
        }
      }
    }

    if (publicPreview) {
      if (stack?.namespace_id) {
        setViewMode(ViewMode.Loaded);
      }
    } else {
      previewLoop();
    }

    return () => {
      canceled = true;
    };
  }, [
    shareId,
    publicPreview,
    stack?.namespace_id,
    tryRedirectToLoginPage,
    tryRedirectToCanonicalShareUrl,
  ]);

  const stackNameRef = useRef(stack?.stack_data?.name);
  stackNameRef.current = stack?.stack_data?.name;

  const markCompleteCallback = useCallback(
    (moduleId) => markGettingStartedTaskComplete(moduleId),
    [],
  );

  useEffect(() => {
    if (publicPreview) {
      return;
    }

    stack?.namespace_id; // added for dependency
    const stackName = stackNameRef.current;
    if (stackName) {
      auditLogUserOpenedStack(stackName, Date.now());
      if (stack?.stack_data?.creation_type?.['.tag'] === 'welcome_stack') {
        markCompleteCallback('explore_get_started_stack_module');
      }
    }
  }, [
    markCompleteCallback,
    publicPreview,
    stack?.namespace_id,
    stack?.stack_data?.creation_type,
  ]);

  const markStacksDataLoadComplete = useStackDetailsPagePerformanceTracking({
    showV2: true,
    showSharingModule,
    showSuggestions,
  });

  if (publicPreview) {
    if (stack && items) {
      return <FullScreenPublicStackV2 />;
    }
    return <ProgressBar in isIndeterminate />;
  }

  if (viewMode === ViewMode.NotFound) {
    return <StackNotFound shareId={shareId} />;
  }

  if (!stack || !items) {
    return <ProgressBar in isIndeterminate />;
  }

  if (!currentAccount) {
    return <ProgressBar in isIndeterminate />;
  }

  markStacksDataLoadComplete();

  return (
    <StacksLoadingContainer>
      <FullScreenStackV2
        currentAccount={currentAccount}
        setShowSharingModule={setShowSharingModule}
        setShowSuggestions={setShowSuggestions}
      />
    </StacksLoadingContainer>
  );
};

function useStackDetailsPagePerformanceTracking({
  showV2,
  showSuggestions,
  showSharingModule,
}: {
  showV2: boolean;
  showSuggestions: boolean;
  showSharingModule: boolean;
}) {
  const tags = useStacksDataCachedTags();

  const dependentModuleNames = useMemo(() => {
    return showV2
      ? [
          StackDetailsModule.STACKS_DATA,
          ...(showSharingModule ? [StackDetailsModule.SHARING] : []),
          ...(showSuggestions ? [StackDetailsModule.SUGGESTED_ITEMS] : []),
        ]
      : [
          StackDetailsModule.STACKS_DATA,
          ...(showSuggestions ? [StackDetailsModule.SUGGESTED_ITEMS] : []),
        ];
  }, [showSharingModule, showSuggestions, showV2]);

  // This aggregated metric is for all the modules combined.
  useAutoRecordAggregatedPageLatency(
    MetricPageName.STACK_DETAILS,
    dependentModuleNames,
    tags || getStacksDataCachedTags,
  );

  // This is for the stacks data module only. In the UI, the stacks data is
  // displayed in a few different cards, but since the data is the same,
  // we consider all of them to be one single module.
  const { markModuleLoadComplete } = useRecordModuleLatency(
    MetricPageName.STACK_DETAILS,
    StackDetailsModule.STACKS_DATA,
    tags || getStacksDataCachedTags,
  );

  return markModuleLoadComplete;
}

const StackNotFound: React.FC<{ shareId: string }> = ({ shareId }) => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  useEffect(() => {
    const now = new Date();

    // Fields not defined in the PAP event itself.
    const extraProperties: Record<string, unknown> = {
      stackShareId: shareId,
      element_id: EnvCtx.surface + '.stack_page.stacks.shown_not_found',
      readable_time: now.toISOString(),
      startTimeMs: now.getTime(),
    };

    reportPapEvent(
      PAP_Select_DashUxa({ featureLine: 'stacks', ...extraProperties }),
    );

    // This does not indicate an error, but sometimes it might be.
    logger.warn(`Stack not found: shareId=${shareId}`);
  }, [reportPapEvent, shareId]);

  return (
    <div className={styles.fullPage}>
      <div className={styles.iconContainer}>
        <LookMagnifyingGlassSpot />
      </div>
      <Title>{i18n.t('not_found')}</Title>
    </div>
  );
};
