/**
 * Mostly lifted from https://github.com/typescript-cheatsheets/react/issues/167#issuecomment-751347673
 * I've yet to find a better way to do this that will still allow all the correct HTMLAttributes to be passed through.
 */
import * as React from "react";
import classNames from "classnames";
import styles from "./button-anchor.module.css";

type ButtonProps = JSX.IntrinsicElements["button"] & {
  href?: never;
  resetStyles?: boolean;
};

type AnchorProps = JSX.IntrinsicElements["a"] & {
  /**
   * If this should change the route, pass this component an `href` which will render this as a `a` element.
   */
  href: string;
  resetStyles?: boolean;
};

type PolymorphicProps = ButtonProps | AnchorProps;
type PolymorphicButton = {
  (props: AnchorProps): JSX.Element;
  (props: ButtonProps): JSX.Element;
};

const isAnchor = (props: PolymorphicProps): props is AnchorProps => {
  return props.href != undefined;
};

/**
 * This is silly difficult to do in Typescript correctly so this is most likely not done in the **most correct** way.
 * This component will simply render either a `button` or `a` element based on the usage of a `href` prop.
 * It will correctly set its ref to the correct type of element based on the props passed.
 */
export const ButtonAnchor = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  PolymorphicProps
>((props, ref) => {
  if (isAnchor(props)) {
    const { children, resetStyles = true, className, ...rest } = props;
    return (
      <a
        {...rest}
        className={classNames(className, {
          [styles.reset]: resetStyles,
        })}
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
      >
        {children}
      </a>
    );
  } else {
    const { children, resetStyles = true, className, ...rest } = props;
    return (
      <button
        {...rest}
        className={classNames(className, {
          [styles.reset]: resetStyles,
        })}
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
      >
        {children}
      </button>
    );
  }
}) as PolymorphicButton;
