import { tagged } from '@mirage/service-logging';
import { ONE_MINUTE_IN_MILLIS } from '@mirage/shared/util/constants';
import { register, unregister } from '@mirage/shared/util/jobs';
import { getCachedOrFetchFeatureValue } from '..';
import { FeatureName, FeatureValue } from '../features';

const logger = tagged('service-experimentation/memCache');

/**
 * In-memory cache for feature flags. Only should be used when its
 * impractical to fetch a feature flag value via `getCachedOrFetchFeatureValue`
 * or `useFeatureFlagValue`. This will take some time to update due to the
 * periodic job, and consumers will not automatically get the latest value.
 */
const featureMemCache: Partial<Record<FeatureName, FeatureValue>> = {};

/**
 * Returns the value of the feature flag from the mem cache.
 * Might be undefined if the flag is not in the cache
 */
export function getMemCacheFeatureValue(
  featureName: FeatureName,
): FeatureValue | undefined {
  return featureMemCache[featureName];
}

/**
 * Periodic job
 *
 * NOTE: Growthbook caches feature flags for 15m so in practice score will
 * update every ~20 minutes. Also changing your flag manually will still need
 * to wait for the next sync.
 */
const SYNC_FEATURE_MEM_CACHE_JOB_NAME =
  'service-experimentation/sync-feature-mem-cache';
const SYNC_FEATURE_MEM_CACHE_INTERVAL = 5 * ONE_MINUTE_IN_MILLIS; // 5 minutes

/**
 * Start the job to sync all provided feature names to the mem cache.
 * This must be called in all apps that use any mem cache value!
 */
export function startSyncFeatureMemCache(featureNames: FeatureName[]) {
  register(
    SYNC_FEATURE_MEM_CACHE_JOB_NAME,
    SYNC_FEATURE_MEM_CACHE_INTERVAL,
    true,
    async () => {
      for (const featureName of featureNames) {
        const flagValue = await getCachedOrFetchFeatureValue(featureName);

        // 0 is a valid value, null check this correctly!
        if (flagValue == null) {
          logger.warn(
            `[getMemCacheFeatureValue] Couldn't get feature flag value`,
            featureName,
          );
          return;
        }

        featureMemCache[featureName] = flagValue;
      }
    },
  );
}

export function cancelSyncFeatureMemCache() {
  unregister(SYNC_FEATURE_MEM_CACHE_JOB_NAME);
}
