// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2911
import type { PAPEventGeneric } from '../base/event';
import type { FeatureLine } from '../enums/feature_line';

// Event_name: 'cancel.delete_dash_stack'
// Description: When a user cancels the delete stack flow
// Owner: content-ingest
export type Cancel_DeleteDashStack = PAPEventGeneric<
  'stacks',
  'cancel',
  'delete_dash_stack',
  {
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // Bool that indicates if the item acted upon is pinned
    isPinned?: boolean;
    // Feature Line for dash
    featureLine?: FeatureLine;
  }
>;

export function PAP_Cancel_DeleteDashStack(
  properties?: Cancel_DeleteDashStack['properties'],
): Cancel_DeleteDashStack {
  return <Cancel_DeleteDashStack>{
    class: 'stacks',
    action: 'cancel',
    object: 'delete_dash_stack',
    properties,
  };
}
