// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgAirtable({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.826 3.23L2.627 6.548a.533.533 0 00.006.996l8.235 3.194c.723.283 1.53.283 2.253 0l8.234-3.194a.533.533 0 00.006-.996L13.163 3.23a3.086 3.086 0 00-2.337 0z"
        fill="#FFBF00"
      />
      <path
        d="M10.578 12.895L7.856 14.18l-.277.13-5.747 2.693C1.47 17.174 1 16.915 1 16.52V9.03c0-.142.073-.266.175-.36a.55.55 0 01.139-.1.59.59 0 01.5-.036l8.716 3.377c.446.171.482.778.048.984z"
        fill="#ED3049"
      />
      <path
        d="M1.616 8.493c-.109 0-.211.03-.29.076-.048.03-.096.06-.138.1l6.668 5.511 2.723-1.285a.528.528 0 00.307-.477.542.542 0 00-.349-.507L1.82 8.534a.509.509 0 00-.204-.041z"
        fill="#B22437"
      />
      <path
        d="M12.723 12.483v7.98c0 .377.392.636.753.5l9.174-3.483a.537.537 0 00.35-.5V9c0-.378-.392-.637-.753-.501l-9.175 3.483a.537.537 0 00-.349.5z"
        fill="#26B5F8"
      />
    </svg>
  );
}

export default SvgAirtable;
