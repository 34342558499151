import { PAP_Click_AddSourcesButton } from '@mirage/analytics/events/types/click_add_sources_button';
import { PAP_Click_AppType } from '@mirage/analytics/events/types/click_app_type';
import { PAP_Click_SaveSources } from '@mirage/analytics/events/types/click_save_sources';
import { ContextInputMessages } from '@mirage/mosaics/ComposeAssistant/components/chat/ContextInputMessages';
import { ConversationFollowUpSuggestions } from '@mirage/mosaics/ComposeAssistant/components/chat/ConversationFollowUpSuggestions';
import { ConversationInput } from '@mirage/mosaics/ComposeAssistant/components/chat/ConversationInput';
import { ConversationMessages } from '@mirage/mosaics/ComposeAssistant/components/chat/ConversationMessages';
import {
  ClickableEmptyChatInstructionsOption,
  EmptyChatInstructions,
} from '@mirage/mosaics/ComposeAssistant/components/chat/EmptyChatInstructions';
import { AddSourcesModal } from '@mirage/mosaics/ComposeAssistant/components/compose-sources/AddSourcesModal';
import { useComposeAnalyticsContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeAnalyticsContext';
import { useComposeCurrentSessionContext } from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionContext';
import { getSelectionPromptString } from '@mirage/mosaics/ComposeAssistant/data/llm/llm-prompts';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { openURL } from '@mirage/service-platform-actions';
import {
  ComposeAssistantConversationMessage,
  ComposeAssistantConversationMessageActionContext,
  ComposeAssistantConversationMessageMessage,
  getFirstMarkdownArtifact,
} from '@mirage/shared/compose/compose-session';
import i18n from '@mirage/translations';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

interface ComposeConversationProps {
  rightPaneExpanded: boolean;
  setDidJustSendFirstWriteCommand: (
    didJustSendFirstWriteCommand: boolean,
  ) => void;
}
export const ComposeConversation = memo(
  ({
    rightPaneExpanded,
    setDidJustSendFirstWriteCommand,
  }: ComposeConversationProps) => {
    const chatWithDashEnabled =
      useFeatureFlagValue('dash_assist_2024_10_12_chat_v1') === 'ON';
    const {
      messagesHistory,
      sources,
      addSource,
      removeSource,
      artifacts,
      setDraftConfig,
      sourcesContentCache,
      addRawMessage,
      postUserMessage,
      inputContext,
      setInputContext,
      currentSessionID,
      isWaitingForResponse,
      progressString,
      newSession,
    } = useComposeCurrentSessionContext();
    const { logComposeEvent } = useComposeAnalyticsContext('compose_chat_pane');
    const markdownArtifact = useMemo(
      () => getFirstMarkdownArtifact(artifacts),
      [artifacts],
    );
    const handleCompleteContextInputs = useCallback(() => {
      if (messagesHistory.length > 0) {
        // no-op if already started talking
        return;
      }
      const text = markdownArtifact?.draftConfig.contentType
        ? i18n.t('compose_assistant_message_with_content_type', {
            contentType: markdownArtifact.draftConfig.contentType,
          })
        : i18n.t('compose_assistant_message_generic');
      addRawMessage({
        type: 'message',
        role: 'assistant',
        text,
        ts: Date.now(),
      });
    }, [
      addRawMessage,
      markdownArtifact?.draftConfig.contentType,
      messagesHistory.length,
    ]);
    const [isAddSourceModalOpen, setIsAddSourceModalOpen] = useState(false);
    const handleClickAdd = useCallback(() => {
      setIsAddSourceModalOpen(true);
      logComposeEvent(
        PAP_Click_AddSourcesButton({
          actionType: 'chat_pane',
        }),
      );
    }, [logComposeEvent]);
    const handleCloseAddSourcesModal = useCallback(() => {
      if (isAddSourceModalOpen) {
        logComposeEvent(
          PAP_Click_SaveSources({
            numberOfSelectedItems: sources.length,
          }),
          { actionSurfaceComponent: 'compose_source_modal' },
        );
      }
      setIsAddSourceModalOpen(false);
      if (messagesHistory.length === 0) handleCompleteContextInputs();
    }, [
      handleCompleteContextInputs,
      messagesHistory.length,
      logComposeEvent,
      isAddSourceModalOpen,
      sources.length,
    ]);
    const handleSubmitInput = useCallback(
      (text: string) => {
        const rawPromptText = inputContext
          ? getSelectionPromptString(text, inputContext.selectedText)
          : undefined;
        const actionContext:
          | ComposeAssistantConversationMessageActionContext
          | undefined = inputContext
          ? {
              type: 'compose_selection_edit',
              selectedText: inputContext.selectedText,
            }
          : undefined;
        const hasNonEmptyMarkdownContent = Boolean(
          markdownArtifact && markdownArtifact.markdownContent.length > 0,
        );
        if (
          getUserMessages(messagesHistory).length === 0 &&
          !hasNonEmptyMarkdownContent
        ) {
          // first user message with empty draft must trigger new draft generation
          postUserMessage({
            text,
            rawPromptText,
            mustGenerateDraft: true,
            mustIncludeSourceContents: true,
            actionContext,
          });
          setDidJustSendFirstWriteCommand(true);
        } else {
          postUserMessage({
            text,
            rawPromptText,
            actionContext,
          });
        }
      },
      [
        inputContext,
        markdownArtifact,
        messagesHistory,
        postUserMessage,
        setDidJustSendFirstWriteCommand,
      ],
    );

    const showEmptyChatInstructions =
      messagesHistory.length === 0 && markdownArtifact === undefined;
    const hasCustomInstruction = messagesHistory.some(
      (message) => message.type === 'instruction',
    );
    // Update the URL based on the current state: for empty sessions clear search params,
    // if user clicked on the sidebar 'Assistant' button then go back to new session page,
    // and for non-empty sessions set the 'app' param to 'chat' or 'write'
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
      if (location.state?.reset) {
        newSession([]);
        navigate(location.pathname, { replace: true }); // go to new session page and remove the state.reset property
        return;
      }
      if (showEmptyChatInstructions) {
        if ([...searchParams].length > 0) {
          setSearchParams({});
        }
      } else {
        const appName = markdownArtifact ? 'write' : 'chat';
        if (searchParams.get('app') !== appName) {
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set('app', appName);
          setSearchParams(newSearchParams);
        }
      }
    }, [
      showEmptyChatInstructions,
      markdownArtifact,
      setSearchParams,
      searchParams,
      newSession,
      location.state,
      navigate,
      location.pathname,
    ]);
    const startingMessageNode = showEmptyChatInstructions ? (
      <ComposeEmptyChatInstructions chatWithDashEnabled={chatWithDashEnabled} />
    ) : (
      !hasCustomInstruction &&
      markdownArtifact && (
        <ContextInputMessages
          key={currentSessionID} // reset inputs when session changes
          handleClickAdd={handleClickAdd}
          sources={sources}
          sourcesContentCache={sourcesContentCache}
          removeSource={removeSource}
          draftConfig={markdownArtifact?.draftConfig || {}}
          setDraftConfig={setDraftConfig}
          onCompleteInputs={() => {
            if (messagesHistory.length === 0) {
              handleCompleteContextInputs();
            }
          }}
          logComposeEvent={logComposeEvent}
        />
      )
    );
    const lastMessage =
      messagesHistory.length > 0 && messagesHistory[messagesHistory.length - 1];
    const isLastMessageFollowUpSuggestions = !!(
      lastMessage as ComposeAssistantConversationMessageMessage
    ).followUpSuggestions?.length;
    return (
      <>
        <ConversationMessages
          isLastMessageFollowUpSuggestions={isLastMessageFollowUpSuggestions}
          startingMessageNode={startingMessageNode}
          messages={messagesHistory}
          isWaitingForResponse={isWaitingForResponse}
          progressString={progressString}
          onRemoveSource={removeSource}
          artifacts={artifacts}
          logComposeEvent={logComposeEvent}
        />
        <div style={{ height: 8, width: '100%' }} />
        {isLastMessageFollowUpSuggestions && (
          <ConversationFollowUpSuggestions
            rightPaneExpanded={rightPaneExpanded}
            message={messagesHistory[messagesHistory.length - 1]}
            onClickFollowUpSuggestion={(suggestion: string) => {
              postUserMessage({
                text: suggestion,
                isFollowUpSuggestion: true,
              });
            }}
          />
        )}
        <ConversationInput
          onSubmit={handleSubmitInput}
          inputContext={inputContext}
          setInputContext={setInputContext}
          logComposeEvent={logComposeEvent}
          handleClickAdd={handleClickAdd}
          sources={sources}
          messages={messagesHistory}
        />
        {isAddSourceModalOpen && (
          <AddSourcesModal
            sources={sources}
            sourcesContentCache={sourcesContentCache}
            addSource={addSource}
            removeSource={removeSource}
            onRequestClose={handleCloseAddSourcesModal}
            logComposeEvent={logComposeEvent}
          />
        )}
      </>
    );
  },
);
ComposeConversation.displayName = 'ComposeConversation';

interface ComposeEmptyChatInstructionsProps {
  chatWithDashEnabled: boolean;
}
export const ComposeEmptyChatInstructions = memo(
  ({ chatWithDashEnabled }: ComposeEmptyChatInstructionsProps) => {
    const { addArtifact, addRawMessage } = useComposeCurrentSessionContext();
    const { logComposeEvent } = useComposeAnalyticsContext('compose_chat_pane');
    const handleClickStartingOption = useCallback(
      (option: ClickableEmptyChatInstructionsOption) => {
        logComposeEvent(PAP_Click_AppType({ actionType: option }));
        switch (option) {
          case 'write':
            addArtifact({
              type: 'markdown_draft',
              id: uuidv4(),
              markdownContent: '',
              draftConfig: {},
            });
            return;
          case 'chat_with_dash':
            addRawMessage({
              type: 'instruction',
              role: 'assistant',
              title: i18n.t('assistant_initial_generic_prompt_message'),
            });
            return;
          case 'request_capabilities':
            openURL('https://forms.gle/8NrfeQ58mMAzYr916');
            return;
          default:
            option satisfies never;
            throw new Error(`Unknown option: ${option}`);
        }
      },
      [addArtifact, addRawMessage, logComposeEvent],
    );
    return (
      <EmptyChatInstructions
        chatWithDashEnabled={chatWithDashEnabled}
        onClickOption={handleClickStartingOption}
      />
    );
  },
);
ComposeEmptyChatInstructions.displayName = 'ComposeEmptyChatInstructions';

function getUserMessages(
  messages: ComposeAssistantConversationMessage[],
): ComposeAssistantConversationMessage[] {
  return messages.filter((message) => message.role === 'user');
}
