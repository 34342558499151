import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { EmptyBoxMini } from '@dropbox/dig-illustrations';
import i18n from '@mirage/translations';
import styles from './NoMoreActivityItems.module.css';

export const NoMoreActivityItems = () => {
  return (
    <Box
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      display="flex"
      style={{ height: '290px' }}
    >
      {/* See what needs to be done */}
      <Box paddingBottom="Macro Small">
        <div className={styles.iconContainer}>
          <EmptyBoxMini altText={i18n.t('activity_feed_end_of_feed_title')} />
        </div>
      </Box>
      <Box as={Text} variant="label" size="large" paddingBottom="8">
        {i18n.t('activity_feed_end_of_feed_title')}
      </Box>
      <Text size="medium" color="subtle">
        {i18n.t('activity_feed_end_of_feed_subtitle')}
      </Text>
    </Box>
  );
};
