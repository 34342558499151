import { atom } from 'jotai';
import { FC } from 'react';

type AdditionalDeveloperSettingsState = {
  headers: FC[];
};

const DefaultAdditionalDeveloperSettingsState: AdditionalDeveloperSettingsState =
  {
    headers: [],
  };

export const additionalDeveloperSettingsAtom = atom(
  DefaultAdditionalDeveloperSettingsState,
);
