// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2187
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'leave.dash_start_page'
// Description: Fires when a user loses focus on start page by performing an action that navigates away from the start page
// This event will record user’s dwell time
// This event will create a new session_id whenever a user returns to the page
// This event will have a separate session_id based on the tab that it is opened on, to allow DS to track cumulative time with the same start page open
// This event will be unbounded by any timeout, and will generally record all exits except if the computer crashes or other e
// Owner: dash-web
export type Leave_DashStartPage = PAPEventGeneric<
  'dash',
  'leave',
  'dash_start_page',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // The tab or session associated with the Start Page instance
    startpageTabId?: string;
    // Session_id associated with focusing on start page
    startpageSessionId?: string;
    // Dwell time associated with looking at start page
    dwellTimeMs?: number;
  }
>;

export function PAP_Leave_DashStartPage(
  properties?: Leave_DashStartPage['properties'],
): Leave_DashStartPage {
  return <Leave_DashStartPage>{
    class: 'dash',
    action: 'leave',
    object: 'dash_start_page',
    properties,
  };
}
