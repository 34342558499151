import React from 'react';

export function usePageVisibility() {
  const [isPageVisible, setPageVisible] = React.useState(!document.hidden);
  React.useEffect(() => {
    const handleVisibilityChange = () => {
      setPageVisible(!document.hidden);
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  return isPageVisible;
}
