import { Button } from '@dropbox/dig-components/buttons';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Snackbar } from '@dropbox/dig-components/snackbar';
import { Text, Title } from '@dropbox/dig-components/typography';
import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { WordmarkLogo } from '@dropbox/dig-logos';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import i18n from '@mirage/translations';
import { useEffect, useState } from 'react';
import { DashWordmarkText } from '../GlobalNav/DashWordmarkText';
import heroImage from './assets/logged_out_icons.png';
import styles from './SplashPage.module.css';

export type SplashStage = 'login' | 'logging_in' | 'error';

type SplashPageProps = {
  stage: SplashStage;
  errorText?: string;
  snackbarText?: string;
  onLogin: () => void;
};

type SplashPageCallToActionProps = {
  stage: SplashStage;
  errorText?: string;
  onLogin: () => void;
};

function SplashPageCallToAction({
  stage,
  errorText,
  onLogin,
}: SplashPageCallToActionProps) {
  // Prevent duplicate clicks from user.
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!disabled) return;

    let canceled = false;

    setTimeout(() => {
      if (!canceled) {
        setDisabled(false);
      }
    }, 1000);

    return () => {
      canceled = true;
    };
  }, [disabled]);

  const onClickLogin = () => {
    setDisabled(true);
    onLogin();
  };

  switch (stage) {
    case 'login':
      return (
        <Button
          variant="primary"
          size="xlarge"
          onClick={onClickLogin}
          disabled={disabled}
          className={styles.loginButton}
          data-testid="signin_button"
          data-uxa-log={createUxaElementId('login_button', {})}
          data-uxa-interactions="shown click"
        >
          {i18n.t('continue_to_log_in')}
        </Button>
      );
    case 'logging_in':
      return (
        <div>
          <div className={styles.loggingInProgress}>
            <Spinner withTrack={true} />
            <Text color="faint">{i18n.t('logging_in_loading_message')}</Text>
          </div>
        </div>
      );
    case 'error':
      return (
        <>
          <Text color="faint" className={styles.loggingInError}>
            {errorText}
          </Text>
          <Button
            variant="primary"
            size="xlarge"
            onClick={onClickLogin}
            disabled={disabled}
            className={styles.loginButton}
            data-testid="signin_button"
            data-uxa-log={createUxaElementId('login_button', {})}
            data-uxa-interactions="shown click"
          >
            {i18n.t('logging_in_try_again')}
          </Button>
        </>
      );
    default:
      return <></>;
  }
}

function SplashPageFooter() {
  return (
    <div className={styles.footer}>
      <Text size="medium" className={styles.copyright}>
        {i18n.t('logout_screen_copyright', {
          copyrightYear: new Date().getFullYear(),
        })}
      </Text>
      <Text size="medium" className={styles.version}>
        {i18n.t('settings_dash_version_copy_text', {
          appVersion: EnvCtx.version,
        })}
      </Text>
    </div>
  );
}

function SplashPageSnackbar({ snackbarText }: { snackbarText?: string }) {
  if (!snackbarText) return <></>;

  return (
    <Snackbar open={true} preferComposition className={styles.snackbar}>
      <Snackbar.Content>
        <Snackbar.Message>
          <Text size="medium">{snackbarText}</Text>
        </Snackbar.Message>
      </Snackbar.Content>
    </Snackbar>
  );
}

export default function SplashPage({
  stage,
  errorText,
  snackbarText,
  onLogin,
}: SplashPageProps) {
  return (
    // TODO: dark mode design not available yet, but should follow
    // system settings instead of always showing bright here.
    <ThemeProvider mode="bright">
      <ThemeContainer>
        <div className={styles.background}>
          <div className={styles.splashContainer}>
            <div className={styles.heroContainer}>
              <img src={heroImage} alt="" className={styles.heroImage} />
            </div>
            <div className={styles.content}>
              <div className={styles.workmarkContainer}>
                <WordmarkLogo
                  src={DashWordmarkText}
                  size={32}
                  color="var(--dig-color__text__base)"
                />
              </div>
              <Title
                weightVariant="emphasized"
                size="medium"
                className={styles.loginTitle}
              >
                {i18n.t('logout_screen_title')}
              </Title>
              <div className={styles.callToAction}>
                <SplashPageCallToAction
                  stage={stage}
                  errorText={errorText}
                  onLogin={onLogin}
                />
              </div>
            </div>
          </div>
          <SplashPageFooter />
          <SplashPageSnackbar snackbarText={snackbarText} />
        </div>
      </ThemeContainer>
    </ThemeProvider>
  );
}
