// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1856
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { SidebarDensityState } from '../enums/sidebar_density_state';

// Event_name: 'view.dash_stack_details'
// Description: Passive view action that loads for stack details. Stacks can be accessed via links, so this event is used for the total count of stack views.
// Event fires on the stacks sidebar, when viewing a stack.
// Event also fires when viewing a stack, when the extension is not installed.
// Owner: otc
export type View_DashStackDetails = PAPEventGeneric<
  'stacks',
  'view',
  'dash_stack_details',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The stack id that represents the stack
    stackId?: string;
    // User_id associated with the creator of the content being acted upon
    creatorId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // Number of items in a Dash stack
    stackSize?: number;
    // The time in ms to load, cancel, or fail to load the content.
    loadTimeMs?: number;
    // The number of links in the stack
    numberOfLinks?: number;
    // Density state of the stacks sidebar stacks listings
    sidebarDensityState?: SidebarDensityState;
    // Hashed URLs accessed by Dash. These are pre-hashed.
    dashUrlHash?: string;
    // Property that indicates if user clicked to access one of their stacks
    fromUserStackOpen?: boolean;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
  }
>;

export function PAP_View_DashStackDetails(
  properties?: View_DashStackDetails['properties'],
): View_DashStackDetails {
  return <View_DashStackDetails>{
    class: 'stacks',
    action: 'view',
    object: 'dash_stack_details',
    properties,
  };
}
