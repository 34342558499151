import { Text } from '@dropbox/dig-components/typography';
import { findHighlightableChunksInQuery } from '@mirage/search/SearchResults/util/resultUtil';
import ReactHighlightWords from 'react-highlight-words';
import styles from './TitleHighlighter.module.css';

type DIGTextSize = 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall';

type TitleHighlighterProps = Readonly<{
  title: string;
  query?: string;
  textSize?: DIGTextSize;
}>;

const HighlightTag = (textSize: DIGTextSize) => {
  const Inner: React.FC = ({ children }) => (
    <Text size={textSize} tagName="span" isBold={true}>
      {children}
    </Text>
  );
  return Inner;
};

export function TitleHighlighter({
  title = '',
  query = '',
  textSize = 'medium',
}: TitleHighlighterProps) {
  const [findChunks, searchWords] = findHighlightableChunksInQuery(query);

  return (
    <div className={styles.container}>
      <ReactHighlightWords
        className={styles.titleHighlighter}
        searchWords={searchWords}
        autoEscape={true}
        textToHighlight={title}
        caseSensitive={false}
        highlightTag={HighlightTag(textSize)}
        findChunks={findChunks}
      />
    </div>
  );
}
