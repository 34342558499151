import { HomePage } from "@mirage/mosaics/HomePage";
import { useIsLoggedIn } from "@mirage/service-auth/useDropboxAuthentication";

import { Welcome } from "./Welcome";

/**
 * Logged out -> show splash page
 * Logged in -> show start page
 */
export const Home: React.FC = () => {
  const loggedIn = useIsLoggedIn();

  switch (loggedIn) {
    case undefined:
      return null;
    case true:
      return <HomePage />;
    case false:
      return <Welcome />;
    default:
      loggedIn satisfies never;
      return null;
  }
};
