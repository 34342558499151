import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import i18n from '@mirage/translations';
import styles from './NoAnswer.module.css';

export const NoAnswer: React.FC = () => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        lineHeight="Text Medium"
      >
        <Text className={styles.noAnswerTitle}>
          {i18n.t('answers_no_answer_title')}
        </Text>
        <Text color="subtle" className={styles.noAnswerByline}>
          {i18n.t('answers_no_answer_byline')}
        </Text>
      </Box>
    </>
  );
};
