import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { APIv2Callable } from '@mirage/service-dbx-api/service';
import { tagged } from '@mirage/service-logging';
import { getValueChangedFunc } from '@mirage/shared/util/value-changed';
import WithDefaults from '@mirage/storage/with-defaults';
import { EventEmitter } from 'eventemitter3';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import {
  getContentSuggestions as getContentSuggestionsApi,
  getSuggestedStacksForHistory,
} from './api';

import type { ContentSuggestionItem } from './api';
import type { stacks } from '@dropbox/api-v2-client';
import type { APIResponse } from '@mirage/service-dbx-api/service/types';
import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

export type AutoStackSuggestionData = {
  autoStacks: stacks.AutoStackSuggestion[];
  isLoading: boolean;
};

export type Service = {
  autoStackSuggestions(): Observable<AutoStackSuggestionData>;
  refreshStackSuggestions(): Promise<void>;
  dismissStackSuggestion(stack: stacks.AutoStackSuggestion): Promise<void>;
  getContentSuggestions(
    stack: stacks.Stack | string,
    items: ContentSuggestionItem[],
    scoreThreshold: number,
    maxItems: number,
  ): Promise<APIResponse<'stacksGetContentSuggestions'>>;
};

export type StoredStackSuggestions = {
  suggestions: stacks.AutoStackSuggestion[];
  // Used in the past; don't re-use this key.
  // dismissedPredictionIds: string[];

  dismissedSuggestions: stacks.AutoStackSuggestion[];
};

const logger = tagged('stack-suggestions');

interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

export default function provideStackSuggestionsService(
  rawStorage: KVStorage<StoredStackSuggestions>,
  { callApiV2 }: DbxApiServiceContract,
) {
  const adapter = new WithDefaults(rawStorage, {
    suggestions: [],
    dismissedSuggestions: [],
  });
  const emitter = new EventEmitter<{ update: void }>();
  const suggestionsChanged =
    getValueChangedFunc<stacks.AutoStackSuggestion[]>();
  const dismissedSuggestionsChanged =
    getValueChangedFunc<stacks.AutoStackSuggestion[]>();

  const changes$ = rx.fromEvent(emitter, 'update');
  const loading$ = new rx.ReplaySubject<boolean>(1);
  const autoStacks$ = rx
    .from(latestStackSuggestions())
    .pipe(op.mergeWith(changes$.pipe(op.concatMap(latestStackSuggestions))));
  const autoStacksData$ = rx
    .combineLatest([loading$, autoStacks$])
    .pipe(op.map(([isLoading, autoStacks]) => ({ isLoading, autoStacks })));

  async function refreshStackSuggestions() {
    const dismissedSuggestions = await adapter.get('dismissedSuggestions');
    loading$.next(true);
    try {
      const results = await getSuggestedStacksForHistory(
        dismissedSuggestions,
        callApiV2,
      );
      if (results && results.length > 0 && suggestionsChanged(results)) {
        await adapter.set('suggestions', results);
        emitter.emit('update');
      }
    } catch (e) {
      logger.error('Failed to refresh stack suggestions', e);
    }
  }

  async function dismissStackSuggestion(stack: stacks.AutoStackSuggestion) {
    if (stack.prediction_id) {
      const dismissed = await adapter.get('dismissedSuggestions');
      const newDismissed = [...dismissed, stack];

      if (dismissedSuggestionsChanged(newDismissed)) {
        await adapter.set('dismissedSuggestions', newDismissed);
        emitter.emit('update');
      }
    }
  }

  async function latestStackSuggestions(): Promise<
    stacks.AutoStackSuggestion[]
  > {
    const [suggestions, dismissedSuggestions] = await Promise.all([
      adapter.get('suggestions'),
      adapter.get('dismissedSuggestions'),
    ]);

    const activeSuggestions = suggestions.filter(
      (stack) =>
        !dismissedSuggestions.some(
          (dismissed) =>
            dismissed.prediction_id === stack.prediction_id ||
            dismissed.stack_data?.name === stack.stack_data?.name,
        ),
    );

    // Don't consider the "loading" state of an API call to be complete
    // until we've updated the autoStackData$ observable.
    loading$.next(false);

    return activeSuggestions;
  }

  function autoStackSuggestions(): Observable<AutoStackSuggestionData> {
    return autoStacksData$;
  }

  function getContentSuggestions(
    stack: stacks.Stack | string,
    items: ContentSuggestionItem[],
    scoreThreshold: number = 0.01,
    maxItems: number = 20,
  ) {
    return getContentSuggestionsApi(
      stack,
      items,
      scoreThreshold,
      maxItems,
      callApiV2,
    );
  }

  services.provide<Service>(
    ServiceId.STACK_SUGGESTIONS,
    {
      autoStackSuggestions,
      refreshStackSuggestions,
      dismissStackSuggestion,
      getContentSuggestions,
    },
    [ServiceId.DBX_API],
  );
}
