// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2205
import type { PAPEventGeneric } from '../base/event';
import type { CalendarItemState } from '../enums/calendar_item_state';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'shown.calendar_item'
// Description: Fires when a meeting is displayed on Start Page
// Owner: dash-web
export type Shown_CalendarItem = PAPEventGeneric<
  'dash',
  'shown',
  'calendar_item',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // Meeting IDs that are uuidv4s as generated by neo4j. Used to tie together Dash meetings and related content.
    meetingId?: string;
    // zero-indexed position of a calendar item
    itemPosition?: number;
    // Expanded or collapsed state for calendar item
    calendarItemState?: CalendarItemState;
    // number of attachments on a calendar event
    numAttachments?: number;
    // number of suggestions for a calendar event
    numSuggestions?: number;
    // 0 indexed position of the calendar event/item
    calendarItemPosition?: number;
  }
>;

export function PAP_Shown_CalendarItem(
  properties?: Shown_CalendarItem['properties'],
): Shown_CalendarItem {
  return <Shown_CalendarItem>{
    class: 'dash',
    action: 'shown',
    object: 'calendar_item',
    properties,
  };
}
