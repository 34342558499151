import useConnectors from '@mirage/service-connectors/useConnectors';
import { useUIConnectors } from '@mirage/settings/hooks/connectors/useUIConnectors';
import { CALENDAR_CONNECTORS } from '@mirage/shared/connectors/entityTypes';

export const useConnectedCalendars = () => {
  const { connectors, connections, refreshConnectionsList } = useConnectors();

  const calendars = connectors.filter((connector) =>
    CALENDAR_CONNECTORS.includes(connector?.id_attrs?.type ?? ''),
  );

  const calendarConnections = connections.filter((connection) =>
    CALENDAR_CONNECTORS.includes(connection?.connector?.id_attrs?.type ?? ''),
  );

  return {
    calendars,
    connections: calendarConnections,
    refreshConnectionsList,
  };
};

export const useGetCalendarApps = () => {
  const { connectors, isLoading } = useUIConnectors(CALENDAR_CONNECTORS);
  return { calendarApps: connectors, isLoading };
};
