import {
  SimpleTruncate,
  SimpleTruncateProps,
} from '@dropbox/dash-component-library';
import { useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

export const DashTruncateWithTooltip = ({
  lines = 1,
  children,
  onBeforeTruncate,
  tooltipProps = {
    title: '',
  },
}: {
  lines?: number;
  children?: string;
  onBeforeTruncate?: (truncated: boolean) => void;
  tooltipProps?: SimpleTruncateProps['tooltipControlProps'];
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const truncateId = useRef(uuid());
  const disabled = !tooltipProps.title || !children;

  return (
    <SimpleTruncate
      data-id={truncateId.current}
      // Hover and focus to trigger the tooltip
      // Blur, click and keydown to hide the tooltip
      onPointerEnter={() => {
        setIsHovered(true);
      }}
      onPointerLeave={() => {
        setIsHovered(false);
      }}
      onFocus={() => {
        setIsHovered(true);
      }}
      onBlur={() => {
        setIsHovered(false);
      }}
      // These replicate `shouldCloseOnClick` behavior
      onClick={() => {
        setIsHovered(false);
      }}
      onKeyDown={() => {
        setIsHovered(false);
      }}
      // SimpleTruncate props
      tooltipControlProps={{
        openDelay: 1000,
        open: !disabled && isHovered && isTruncated,
        ...tooltipProps,
      }}
      onOverflowChange={({ isOverflowing }) => {
        onBeforeTruncate?.(isOverflowing);
        setIsTruncated(isOverflowing);
      }}
      lines={lines}
    >
      {children}
    </SimpleTruncate>
  );
};
