// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2561
import type { PAPEventGeneric } from '../base/event';
import type { SidebarCollapsedState } from '../enums/sidebar_collapsed_state';

// Event_name: 'shown.dash_navigation_sidebar'
// Description: Impression event for when the left sidebar is loaded on page load.
// Owner: dash-web
export type Shown_DashNavigationSidebar = PAPEventGeneric<
  'dash',
  'shown',
  'dash_navigation_sidebar',
  {
    // expanded/collapsed state of the dash stacks sidebar
    sidebarCollapsedState?: SidebarCollapsedState;
    // number of pinned stacks
    numPinnedStacks?: number;
  }
>;

export function PAP_Shown_DashNavigationSidebar(
  properties?: Shown_DashNavigationSidebar['properties'],
): Shown_DashNavigationSidebar {
  return <Shown_DashNavigationSidebar>{
    class: 'dash',
    action: 'shown',
    object: 'dash_navigation_sidebar',
    properties,
  };
}
