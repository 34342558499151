import { isDefined } from '@mirage/shared/util/tiny-utils';

import type { APIv2Callable } from '@mirage/service-dbx-api/service';

export const getRecommendedStacks = async (
  callApiV2: APIv2Callable,
): Promise<string[]> => {
  return callApiV2('stacksGetRankedStacks', {})
    .then((r) => {
      return (
        r.ranked_stacks?.map((stack) => stack.stack_id).filter(isDefined) ?? []
      );
    })
    .catch(() => []);
};
