// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgCrunchbase({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.001 2H4A2.008 2.008 0 002 3.999V20A2.008 2.008 0 003.999 22H20A2.008 2.008 0 0022 20.001V4A2.008 2.008 0 0020.001 2zM7.872 14.054a1.757 1.757 0 002.328-.87h1.384c-.863 3.532-6.061 2.907-6.061-.729 0-3.64 5.198-4.264 6.06-.73H10.2a1.756 1.756 0 00-2.842-.514 1.756 1.756 0 00.514 2.842zm9.454.703c-.264.228-.56.404-.886.527a3.067 3.067 0 01-2.823-.318v.318h-1.26V6.476h1.25V9.9a3.11 3.11 0 011.501-.524h.224c2.845-.007 4.154 3.535 1.993 5.38zm-.186-2.304a1.753 1.753 0 11-3.506 0c.05-2.286 3.454-2.286 3.506 0z"
        fill="#0287D1"
      />
    </svg>
  );
}

export default SvgCrunchbase;
