import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { DeleteLine, MoreHorizontalLine } from '@dropbox/dig-icons/assets';
import { openURL } from '@mirage/service-platform-actions';
import { getConnectorNameForAttr } from '@mirage/shared/connectors';
import { isBrowserExtension } from '@mirage/shared/connectors/util';
import { UIConnection, UIWebExtension } from '@mirage/shared/types';
import { isEmail } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import { useMemo, useState } from 'react';

interface Props {
  label: string;
  item: UIConnection | UIWebExtension;
  onDisconnect: (item: UIConnection) => void;
}

export const DisconnectModal = ({ label, item, onDisconnect }: Props) => {
  const isWebExtension = isBrowserExtension(item);
  const [openModal, setOpenModal] = useState(false);
  const attrFriendlyName = useMemo(
    () => getConnectorNameForAttr(label || ''),
    [label],
  );

  const close = () => {
    setOpenModal(false);
  };

  const confirm = () => {
    if (isWebExtension) {
      openURL(item.installUrl);
    } else {
      onDisconnect(item);
    }

    setOpenModal(false);
  };

  const handleSelection = (value: string) => {
    if (value === 'disconnect') {
      setOpenModal(true);
    }
  };

  const connectedEmail =
    !isWebExtension && isEmail(item.metadata?.display_identifier)
      ? item.metadata?.display_identifier
      : null;

  return (
    <>
      <Menu.Wrapper closeOnSelection={false} onSelection={handleSelection}>
        {({ getContentProps, getTriggerProps }) => (
          <>
            <IconButton
              data-testid="trigger-menu"
              {...getTriggerProps()}
              variant="borderless"
            >
              <Text color="faint">
                <UIIcon src={MoreHorizontalLine} />
              </Text>
            </IconButton>

            <Menu.Content {...getContentProps()}>
              <Menu.Segment>
                <Menu.ActionItem
                  key="disconnect"
                  value="disconnect"
                  withLeftAccessory={<UIIcon src={DeleteLine} />}
                >
                  {i18n.t('disconnect')}
                </Menu.ActionItem>
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>

      <Modal
        open={openModal}
        isCentered
        onRequestClose={close}
        aria-labelledby="disconnect-modal-open-button"
      >
        <Modal.Header hasBottomSpacing="title-standard">
          <Modal.Title id="disconnect-modal-title">
            {i18n.t('disconnect_title', { serviceName: label })}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isWebExtension ? (
            <Text>
              {i18n.t('disconnect_modal_text_for_web_extension', {
                webExtensionName: label,
              })}
            </Text>
          ) : connectedEmail ? (
            <Text>
              {i18n.t('disconnect_modal_text', {
                serviceName: label,
                userEmail: connectedEmail,
              })}
            </Text>
          ) : (
            <Text>
              {i18n.t('disconnect_modal_text_without_email', {
                serviceName: label,
              })}
            </Text>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="opacity" onClick={close}>
            {i18n.t('disconnect_cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={confirm}
            data-testid={`DisconnectModal-confirm-${attrFriendlyName}`}
          >
            {i18n.t('disconnect')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
