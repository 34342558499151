import { ONE_DAY_IN_SECONDS } from '@mirage/shared/util/constants';
import CachedStorage from '../cached-storage';

import type { LargeKvStore } from './interface';
import type { RawStorage } from '@mirage/storage';

const TTL_SECONDS = ONE_DAY_IN_SECONDS * 30;

export function largeToRawStorage<Shape>(
  namespace: string,
  storage: LargeKvStore,
  ttlSeconds = TTL_SECONDS,
): RawStorage<Shape> {
  return {
    async read(): Promise<Shape | undefined> {
      const value = await storage.get(namespace);
      return value ? JSON.parse(value) : undefined;
    },

    async write(value: Shape): Promise<void> {
      await storage.set(namespace, JSON.stringify(value), ttlSeconds);
    },

    async clear() {
      await storage.delete(namespace);
    },
  };
}

export function largeToCachedStorage<Shape>(
  namespace: string,
  storage: LargeKvStore,
  ttlSeconds = TTL_SECONDS,
) {
  return new CachedStorage<Shape>(
    largeToRawStorage<Shape>(namespace, storage, ttlSeconds),
  );
}
