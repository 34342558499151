/* eslint @typescript-eslint/no-explicit-any: 0 */
/* eslint @typescript-eslint/ban-ts-comment: 0 */

// service registration and access across discovery protocol layer
import * as discovery from '@mirage/discovery';
import * as observable from '@mirage/discovery/services/observable';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import type { ServiceId } from '@mirage/discovery/id';
import type { Observable, ObservableInput, Subject } from 'rxjs';

type Callable = (...args: any[]) => any;
type ObservableFn<Fn extends Callable> = (
  ...args: Parameters<Fn>
) => ReturnType<Fn> extends Observable<any>
  ? ReturnType<Fn>
  : Observable<Awaited<ReturnType<Fn>>>;
type PromiseFn<Fn extends Callable> = (
  ...args: Parameters<Fn>
) => Promise<Awaited<ReturnType<Fn>>>;

// support exposing functions, raw values, and callables
type ExternalService<T> = T extends Callable
  ? ObservableFn<T>
  : {
      [P in keyof T]: T[P] extends Callable ? ObservableFn<T[P]> : T[P];
    };

type ExternalServiceAsPromise<T> = T extends Callable
  ? PromiseFn<T>
  : {
      [P in keyof T]: T[P] extends Callable ? PromiseFn<T[P]> : T[P];
    };

export type TimingLogger = (
  service: ServiceId,
  method: string[],
  args: unknown[],
  requestId: string,
  // True if this timing is coming from services.provide().
  isProvider: boolean,
  durationMs: number,
) => void;

let _timingLogger: TimingLogger | undefined;

//
// external api
//------------------------------------------------------------------------------
export function provide<S>(
  id: ServiceId,
  handler: S,
  dependencies: ServiceId[],
): S {
  waitForDependencies(dependencies)
    .then(() => {
      discovery.expose(id).subscribe(([tx$, rx$]) => {
        provider(id, tx$, rx$, handler);
      });
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error('Failed to register service:', id);
      // eslint-disable-next-line no-console
      console.error('  ->', e.message);
      return Promise.reject(e);
    });

  return handler;
}

export function get<S>(id: ServiceId): ExternalService<S> {
  const txrx$ = rx
    .defer(() => rx.from(discovery.get(id)))
    .pipe(op.shareReplay(1));
  return consumer<ExternalService<S>>(id, txrx$);
}

export function getp<S>(id: ServiceId): ExternalServiceAsPromise<S> {
  const txrx$ = rx
    .defer(() => rx.from(discovery.get(id)))
    .pipe(op.shareReplay(1));
  return consumer<ExternalServiceAsPromise<S>>(id, txrx$, (res$) =>
    rx.firstValueFrom(res$),
  );
}

export function attachTimingLogger(logger: TimingLogger): void {
  _timingLogger = logger;
}

export function isTimingLoggerAttached(): boolean {
  return !!_timingLogger;
}

export function removeTimingLogger(): void {
  _timingLogger = undefined;
}

//
// raw helpers
//------------------------------------------------------------------------------
function waitForDependencies(dependencies: ServiceId[]): Promise<void> {
  // no need to interact with anything if we have no deps
  if (!dependencies.length) return Promise.resolve();

  const ready$ = rx.from(dependencies).pipe(
    op.mergeMap((dependency: ServiceId) => {
      // XXX: wrapping in a defer since this is a sync op and retry needs a new
      // value on every iteration
      return (
        rx
          .defer(() => rx.of(discovery.has(dependency)))
          .pipe(
            op.mergeMap((exists) =>
              exists
                ? rx.of(exists)
                : rx.throwError(
                    () => new Error(`Failed to find dependency: ${dependency}`),
                  ),
            ),
          )
          // the `has` operation is a map lookup, we can retry quickly
          .pipe(op.retry({ delay: 10 }))
          .pipe(
            op.timeout({
              first: 10_000,
              with: () =>
                rx.throwError(
                  () => new Error(`Failed to find dependency: ${dependency}`),
                ),
            }),
          )
      );
    }),
  );

  // using `reduce` as a control operation here to wait for all sub-observables
  // to complete without caring about their output since errors will bubble and
  // reject the promise returned from `firstValueFrom`
  return rx.lastValueFrom(ready$).then(() => undefined);
}

function attachAndLogTimings<T>(
  service: ServiceId,
  method: string[],
  args: unknown[],
  requestId: string,
  isProvider: boolean,
  call$: Observable<T>,
): Observable<T> {
  // this can still technically change while in flight, but early return here
  if (!_timingLogger) return call$;

  return rx.defer(() => {
    const t0 = performance.now();
    return call$.pipe(
      op.finalize(() => {
        if (!_timingLogger) return;
        const durationMs = performance.now() - t0;
        _timingLogger(service, method, args, requestId, isProvider, durationMs);
      }),
    );
  });
}

export function provider<T>(
  serviceId: ServiceId,
  tx$: rx.Subject<any>,
  rx$: rx.Observable<any>,
  handlers: T,
) {
  rx$
    .pipe(op.filter(({ type }) => type === 'request'))
    .subscribe(({ id, method, args }) => {
      // need to traverse our local handler definition to see if we have a handler
      // for the given method path
      const handler = lookup(handlers, method);

      // TODO: error the other side instead of letting it hang
      if (!handler) return;

      // XXX: wrap in a defer to wait to execute the method until after the
      // caller subscribes to the observable
      const res$ = rx.defer(() => {
        return toObservable(
          handler instanceof Function ? handler(...args) : handler,
        ).pipe(
          op.concatMap((value: unknown) => {
            if (!isSerializable(value)) {
              return rx.throwError(() => {
                new Error('Attempting to send non-serializable data over IPC');
              });
            }
            return rx.of(value);
          }),
        );
      });
      const timed$ = attachAndLogTimings(
        serviceId,
        method,
        args,
        id,
        true,
        res$,
      );
      observable.send(...substream<any, any>(id, tx$, rx$), timed$);
    });
}

function toObservable<
  T extends string | number | boolean | ObservableInput<any> | null | void,
>(value: T): Observable<T> {
  // special case null, undefined
  if (!value) return rx.of(value);

  // pretty much anything other than a promise or a observable ought to be done
  // as a rx.of() so the first value going over ipc is correct for all promise
  // -based service wrappers
  if (value instanceof rx.Observable) return rx.from(value);
  if (value instanceof Promise) return rx.from(value);

  return rx.of(value);
}

function lookup(target: any, path: Array<string>) {
  let _target = target;
  for (let i = 0; i < path.length; ++i) {
    if (!_target[path[i]]) return undefined;
    _target = _target[path[i]];
  }
  return _target;
}

type MapperFunction = (outgoing: Observable<any>) => any;
const DISALLOWED_PROPERTIES = [
  'then',
  'catch',
  'finally',
  'prototype',
  'isReactComponent',
];

export function consumer<T>(
  serviceId: ServiceId,
  channel$: rx.Observable<[rx.Subject<any>, rx.Observable<any>]>,
  mapper?: MapperFunction,
): T {
  function request(method: Array<string>, args: Array<any>) {
    if (!isSerializable(args)) {
      throw new Error(
        'Attempting to call across IPC with non-serializable data!',
      );
    }
    const id = uuid();
    const res$ = rx.defer(() => {
      return channel$.pipe(
        op.mergeMap(([tx$, rx$]) => {
          tx$.next({ type: 'request', method, args, id });
          const [otx$, orx$] = substream<any, any>(id, tx$, rx$);
          return attachAndLogTimings(
            serviceId,
            method,
            args,
            id,
            false,
            observable.receive(otx$, orx$),
          );
        }),
      );
    });
    return mapper ? mapper(res$) : res$;
  }

  // this lets us create dynamically nested and callable functions to allow for
  // "namespacing" exposed apis
  function factory<C>(context: Array<string> = []): C {
    function callable(...args: Array<any>) {
      return request(context, args);
    }

    const proxied = new Proxy(callable, {
      get<K extends Extract<keyof C, string>>(_: any, property: K): C[K] {
        // special case: 'apply'
        // because we are proxying requests using a proxy object which tracks
        // sub-property calls, we need to handle fn.apply() calls separately so
        // a funtion call with passed parameters are treated correctly
        if (property === 'apply') {
          // @ts-ignore -- hack to make fn.apply(...) work
          return function applied(
            _this: any,
            args: any[],
          ): ReturnType<typeof callable> {
            return callable(...args);
          };
        }

        // XXX: you must filter our these properties or the execution context
        // will attempt to use them to continue promise chains and this will
        // never resolve!
        if (DISALLOWED_PROPERTIES.includes(property)) return undefined as C[K];
        return factory<C[K]>([...context, property]);
      },
    });

    return proxied as any as C;
  }

  return factory<T>(); // hope ya typed T properly!
}

function substream<T, R>(
  id: string,
  tx$: Subject<any>,
  rx$: Observable<any>,
): [Subject<T>, Observable<R>] {
  const otx$: Subject<T> = new rx.Subject();
  otx$
    .pipe(op.map((payload) => ({ type: 'stream', id, payload })))
    .subscribe((message) => tx$.next(message));

  const orx$: Observable<R> = rx$
    .pipe(op.filter(({ type, id: _id }) => type === 'stream' && _id === id))
    .pipe(op.pluck('payload'));

  return [otx$, orx$];
}

// not everything can be sent over ipc, attempt a structured clone like the ipc
// channel will do behind the scenes and see if it fails!
function isSerializable(parameters: unknown) {
  try {
    structuredClone(parameters);
  } catch (e) {
    return false;
  }
  return true;
}
