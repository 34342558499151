import { nonEmpty } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';

export enum TypeOfWork {
  COMMUNICATIONS = 'communications',
  CONSULTANT = 'consultant',
  CREATIVE = 'creative',
  EDUCATOR = 'educator',
  FINANCE = 'finance',
  HUMAN_RESOURCES = 'human_resources',
  LEGAL = 'legal',
  MARKETING = 'marketing',
  OPERATIONS = 'operations',
  SALES = 'sales',
  SOFTWARE = 'software',
  STUDENT = 'student',
  PRODUCT = 'product',
  DESIGN = 'design',
  ENGINEERING = 'engineering',
  OTHER = 'other',
}

export enum WorkWith {
  TEAMMATES = 'teammates',
  CLIENTS = 'clients',
  SELF = 'me',
}

export const enum JtbdType {
  SEARCH = 1,
  ORGANIZE,
  MEETINGS,
  NOT_SURE,
}

const typeOfWorkToLabelTranslations = {
  [TypeOfWork.COMMUNICATIONS]: i18n.t('jtbd_type_of_work_communications'),
  [TypeOfWork.CONSULTANT]: i18n.t('jtbd_type_of_work_consultant'),
  [TypeOfWork.CREATIVE]: i18n.t('jtbd_type_of_work_creative'),
  [TypeOfWork.EDUCATOR]: i18n.t('jtbd_type_of_work_educator'),
  [TypeOfWork.FINANCE]: i18n.t('jtbd_type_of_work_finance'),
  [TypeOfWork.HUMAN_RESOURCES]: i18n.t('jtbd_type_of_work_human_resources'),
  [TypeOfWork.LEGAL]: i18n.t('jtbd_type_of_work_legal'),
  [TypeOfWork.MARKETING]: i18n.t('jtbd_type_of_work_marketing'),
  [TypeOfWork.OPERATIONS]: i18n.t('jtbd_type_of_work_operations'),
  [TypeOfWork.SALES]: i18n.t('jtbd_type_of_work_sales'),
  [TypeOfWork.SOFTWARE]: i18n.t('jtbd_type_of_work_software'),
  [TypeOfWork.STUDENT]: i18n.t('jtbd_type_of_work_student'),
  [TypeOfWork.PRODUCT]: i18n.t('jtbd_type_of_work_product'),
  [TypeOfWork.DESIGN]: i18n.t('jtbd_type_of_work_design'),
  [TypeOfWork.ENGINEERING]: i18n.t('jtbd_type_of_work_engineering'),
  [TypeOfWork.OTHER]: i18n.t('jtbd_type_of_work_other_option'),
};

export const typeOfWorkToLabel = (typeOfWork: TypeOfWork): string => {
  return nonEmpty(
    typeOfWorkToLabelTranslations[typeOfWork],
    `Invalid type of work: ${typeOfWork}`,
  );
};

const workWithLabelTranslations = {
  [WorkWith.TEAMMATES]: i18n.t('jtbd_work_with_teammates'),
  [WorkWith.CLIENTS]: i18n.t('jtbd_work_with_clients'),
  [WorkWith.SELF]: i18n.t('jtbd_work_with_self'),
};

export const workWithLabel = (workWith: WorkWith): string => {
  return nonEmpty(
    workWithLabelTranslations[workWith],
    `Invalid work with: ${workWith}`,
  );
};

const jtbdTypeToLabelTranslations = {
  [JtbdType.SEARCH]: i18n.t('jtbd_type_search'),
  [JtbdType.ORGANIZE]: i18n.t('jtbd_type_organize'),
  [JtbdType.MEETINGS]: i18n.t('jtbd_type_meetings'),
  [JtbdType.NOT_SURE]: i18n.t('jtbd_type_not_sure'),
};

export const jtbdTypeToLabel = (jtbdType: JtbdType): string => {
  return nonEmpty(
    jtbdTypeToLabelTranslations[jtbdType],
    `Invalid JTBD type: ${jtbdType}`,
  );
};
