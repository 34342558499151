import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { isDevBuildChannel } from '@mirage/shared/util/tiny-utils';
import { useMemo } from 'react';
import { useIsDropboxer, useIsTestAccount } from './useDropboxAccount';

export const useDevFeatures = () => {
  const isDropboxer = useIsDropboxer();
  const isTestAccount = useIsTestAccount();
  return useMemo(
    () =>
      isDropboxer || isTestAccount || isDevBuildChannel(EnvCtx.buildChannel),
    [isDropboxer, isTestAccount],
  );
};
