import type { Recommendation } from '@mirage/shared/search/recommendation';
import type { PersonObject } from '@mirage/shared/search/search-filters';

export const extractValidPersonFromRecommendation = (
  rec: Recommendation,
): PersonObject | null => {
  const person = [rec.sender, rec.creator, rec.lastModifier].find(
    (p) => p && p.email,
  ); // Ensure the person object exists and has an email

  if (!person) return null;

  return {
    email: person.email || '',
    displayName: person.displayName,
  };
};
