import { Title } from '@dropbox/dig-components/typography';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashConnectAppPage } from '@mirage/analytics/events/types/open_dash_connect_app_page';
import { PAP_Open_DashEmptyStateComponent } from '@mirage/analytics/events/types/open_dash_empty_state_component';
import { PAP_Shown_DashEmptyStateComponent } from '@mirage/analytics/events/types/shown_dash_empty_state_component';
import { useConnectorsUI } from '@mirage/mosaics/SettingsPage/useConnectorsUI';
import { ActivationTypes } from '@mirage/service-connectors/useActivationForConnectors';
import { DashButton } from '@mirage/shared/buttons/DashButton';
import i18n from '@mirage/translations';
import { useEffect } from 'react';
import { AddConnectorsSvg } from './icons/AddConnectorSvg';
import styles from './TypeaheadConnectorActivation.module.css';

export const TypeaheadNoConnectors = ({
  onAddFirstAppClick,
}: {
  onAddFirstAppClick?: (open: boolean) => void;
}) => {
  const { openConnectors } = useConnectorsUI();
  const { reportPapEvent } = useMirageAnalyticsContext();

  const handleClick = () => {
    openConnectors();
    if (onAddFirstAppClick) {
      onAddFirstAppClick(false);
    }
    reportPapEvent(
      PAP_Open_DashEmptyStateComponent({
        dashActionSurface: 'search_typeahead',
        actionSurfaceComponent: 'no_connectors_typeahead_result',
      }),
    );
    reportPapEvent(
      PAP_Open_DashConnectAppPage({
        dashActionSurface: 'search_typeahead',
        actionSurfaceComponent: 'no_connectors_typeahead_result',
        connectAppType: 'all_apps',
      }),
    );
  };

  useEffect(() => {
    reportPapEvent(
      PAP_Shown_DashEmptyStateComponent({
        dashActionSurface: 'search_typeahead',
        actionSurfaceComponent: 'no_connectors_typeahead_result',
        featureLine: 'search',
      }),
    );
  }, [reportPapEvent]);

  return (
    <li>
      <div className={styles.container}>
        <div className={styles.noConnectorsContent}>
          <AddConnectorsSvg
            className={styles.icons}
            height="80px"
            width="272px"
          />
          <Title size="small">{i18n.t('add_connectors')}</Title>
          <DashButton onClick={handleClick}>{i18n.t('add_apps')}</DashButton>
        </div>
      </div>
    </li>
  );
};

interface Props {
  activationType?: string;
  setTypeaheadOpen?: (open: boolean) => void;
}

export function TypeaheadConnectorActivation({
  activationType,
  setTypeaheadOpen,
}: Props) {
  if (activationType === ActivationTypes.NoConnectors) {
    return <TypeaheadNoConnectors onAddFirstAppClick={setTypeaheadOpen} />;
  }

  return null;
}
