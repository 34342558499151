// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgGreenhouse({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13.457c1.445 0 2.51 1.114 2.51 2.493A2.473 2.473 0 0112 18.443a2.472 2.472 0 01-2.51-2.493c0-1.379 1.065-2.493 2.51-2.493zM12 7.327c.905 0 1.649.725 1.649 1.602 0 .877-.744 1.602-1.649 1.602s-1.649-.725-1.649-1.602c0-.877.744-1.602 1.649-1.602z"
        fill="#23A47F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.667 2C2.747 2 2 2.746 2 3.667v16.666C2 21.253 2.746 22 3.667 22h16.666c.92 0 1.667-.746 1.667-1.667V3.667C22 2.747 21.254 2 20.333 2H3.667zm10.288 8.823c.525-.501.847-1.142.847-1.866 0-.828-.355-1.318-.62-1.685-.158-.219-.285-.394-.285-.571 0-.251.204-.363.467-.446.32-.112.744-.348.744-.864a.907.907 0 00-.92-.891c-.51 0-.933.39-.933.864 0 .112.021.22.04.32.017.086.033.166.033.237 0 .264-.204.431-.598.431-.102 0-.213-.009-.332-.018a5.38 5.38 0 00-.442-.023c-1.517 0-2.758 1.17-2.758 2.618 0 .752.321 1.393.847 1.894.24.23.525.388.777.528.36.2.653.364.653.642 0 .327-.282.437-.696.597-.396.153-.913.354-1.42.838-.686.654-1.109 1.518-1.109 2.522 0 1.976 1.663 3.55 3.75 3.55 2.087 0 3.75-1.574 3.75-3.552 0-1.002-.423-1.866-1.109-2.52-.507-.484-1.024-.685-1.42-.838-.414-.16-.696-.27-.696-.597 0-.278.293-.441.653-.642.252-.14.537-.298.777-.528z"
        fill="#23A47F"
      />
    </svg>
  );
}

export default SvgGreenhouse;
