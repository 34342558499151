// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3147
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';

// Event_name: 'shown.dash_survey'
// Description: Triggered when a survey is shown
// Owner: otc
export type Shown_DashSurvey = PAPEventGeneric<
  'dash',
  'shown',
  'dash_survey',
  {
    // user_id from non-dropbox domain, such as hellosign, docsend etc.
    // They are strings reported to UXA via meta tag.
    externalUserId?: string;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
  }
>;

export function PAP_Shown_DashSurvey(
  properties?: Shown_DashSurvey['properties'],
): Shown_DashSurvey {
  return <Shown_DashSurvey>{
    class: 'dash',
    action: 'shown',
    object: 'dash_survey',
    properties,
  };
}
