import { _IconLayout } from "./IconLayout";
import { _Badge } from "./Badge";

export type { IconLayoutProps, IconSizes } from "./IconLayout";

export type { BadgeProps } from "./Badge";

export { useIconSize, IconSizeProvider } from "./IconLayout";

export const IconLayout = Object.assign(_IconLayout, {
  Badge: _Badge,
});
