import {
  clearCachedFeatureValues,
  ExperimentationAttributes,
  getCachedFlags,
  listenForFeatures,
  refreshAllFeatureValues,
  setExperimentationAttributes,
  startSyncFeatureFlags,
} from '@mirage/service-experimentation';
import { startSyncFeatureRingSettings } from '@mirage/service-feature-ring-settings';
import { useFeatureRingSettings } from '@mirage/service-feature-ring-settings/hooks/useFeatureRingSettings';
import { tagged } from '@mirage/service-logging';
import {
  logPageLoadMilestone,
  logPageLoadMilestoneOnce,
} from '@mirage/service-operational-metrics/page-load';
import { sleepMs } from '@mirage/shared/util/tiny-utils';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FeatureFlag, FeatureFlags, features } from './features';

const logger = tagged('useInitFeatureFlags');

export const enum FeatureFlagsStatus {
  NOT_READY,
  LOGGED_OUT_READY,
  LOGGED_IN_READY,
}

const enum ProcessingStatus {
  FREE,
  PROCESSING_LOGGED_OUT,
  PROCESSING_LOGGED_IN,
}

const featureFlagsAtom = atom<FeatureFlags>(features);

// Avoid redundant updates across multiple hooks of `useUpdateFeatureFlags`.
let currentFlags = features;

function useUpdateFeatureFlags() {
  const setFeatureFlags = useSetAtom(featureFlagsAtom);

  const updateFeatureFlags = useCallback(
    (flags: FeatureFlag[]) => {
      const newFlags = { ...currentFlags };
      flags.forEach((flag) => (newFlags[flag.featureName] = flag));

      if (!isEqual(currentFlags, newFlags)) {
        currentFlags = newFlags;
        setFeatureFlags(newFlags);
        logPageLoadMilestoneOnce('useUpdateFeatureFlags updateFeatureFlags');
        logger.debug(`updateFeatureFlags`);
      }
    },
    [setFeatureFlags],
  );

  return updateFeatureFlags;
}

export function useUpdateExperimentationAttributes() {
  const updateFeatureFlags = useUpdateFeatureFlags();

  return useCallback(
    async (attributes: ExperimentationAttributes) => {
      setExperimentationAttributes(attributes);
      const flags = await refreshAllFeatureValues();
      updateFeatureFlags(flags);
    },
    [updateFeatureFlags],
  );
}

export type InitFeatureFlagsOptions = {
  onLoggedInAndSuccess?: () => void;
  onLoggedInAndError?: (error: unknown) => void;
};

/**
 * This only needs to be called once, at the top-level app (before login).
 * To get the feature flag values, just use `useFeatureFlags()`.
 */
export function useInitFeatureFlags(
  loggedIn: boolean | undefined,
  options?: InitFeatureFlagsOptions,
) {
  logPageLoadMilestoneOnce('useInitFeatureFlags start');

  const updateFeatureFlags = useUpdateFeatureFlags();
  const { getCurrentFeatureRing } = useFeatureRingSettings();

  const [featureFlagsStatus, setFeatureFlagsStatus] = useState(
    FeatureFlagsStatus.NOT_READY,
  );

  const previousLoggedIn = useRef<boolean>();
  const processingStatus = useRef(ProcessingStatus.FREE);

  const { onLoggedInAndSuccess, onLoggedInAndError } = options ?? {};

  useEffect(() => {
    async function setLoggedOutFeatureFlags() {
      logPageLoadMilestone('setLoggedOutFeatureFlags start');
      logger.debug('setLoggedOutFeatureFlags start');

      processingStatus.current = ProcessingStatus.PROCESSING_LOGGED_OUT;

      try {
        const flags = await refreshAllFeatureValues();
        logger.debug('setLoggedOutFeatureFlags after refreshAllFeatureValues');
        updateFeatureFlags(flags);
      } finally {
        // Don't let an error block the app from loading.
        setFeatureFlagsStatus(FeatureFlagsStatus.LOGGED_OUT_READY);
        logPageLoadMilestone('setLoggedOutFeatureFlags LOGGED_OUT_READY');
        logger.debug('setLoggedOutFeatureFlags LOGGED_OUT_READY');

        processingStatus.current = ProcessingStatus.FREE;
      }
    }

    async function setLoggedInFeatureFlags() {
      logPageLoadMilestone('setLoggedInFeatureFlags start');
      logger.debug('setLoggedInFeatureFlags start');

      processingStatus.current = ProcessingStatus.PROCESSING_LOGGED_IN;

      let cached: FeatureFlag[] | undefined;
      try {
        cached = await getCachedFlags();
        if (cached.length) {
          updateFeatureFlags(cached);
          setFeatureFlagsStatus(FeatureFlagsStatus.LOGGED_IN_READY);
          logPageLoadMilestone(
            'setLoggedInFeatureFlags LOGGED_IN_READY cached',
          );
          logger.debug('setLoggedInFeatureFlags LOGGED_IN_READY cached');
        }

        if (!cached.length) {
          logPageLoadMilestone(
            `setLoggedInFeatureFlags before getAllCachedOrFetchFeatureValues`,
          );
          logger.debug('setLoggedInFeatureFlags refreshAllFeatureValues');
          const flags = await refreshAllFeatureValues();
          updateFeatureFlags(flags);
        }

        onLoggedInAndSuccess?.();
      } catch (e) {
        onLoggedInAndError?.(e);
      } finally {
        // Don't let an error block the app from loading.
        if (!cached?.length) {
          setFeatureFlagsStatus(FeatureFlagsStatus.LOGGED_IN_READY);
          logPageLoadMilestone('setLoggedInFeatureFlags LOGGED_IN_READY');
          logger.debug('setLoggedInFeatureFlags LOGGED_IN_READY');
        }

        processingStatus.current = ProcessingStatus.FREE;

        logPageLoadMilestone('setLoggedInFeatureFlags end');
      }
    }

    // Clear cached flags when login status changes.
    if (loggedIn !== undefined) {
      if (previousLoggedIn.current === undefined) {
        previousLoggedIn.current = loggedIn;
      } else if (previousLoggedIn.current !== loggedIn) {
        previousLoggedIn.current = loggedIn;

        logPageLoadMilestone('useInitFeatureFlags clearCachedFeatureValues');
        logger.debug('clearCachedFeatureValues');
        clearCachedFeatureValues();
      }
    }

    async function startSyncJobs() {
      // Wait for page load to complete before starting.
      await sleepMs(2000);

      startSyncFeatureRingSettings();
      startSyncFeatureFlags();
    }

    async function init() {
      switch (loggedIn) {
        case false:
          if (
            featureFlagsStatus !== FeatureFlagsStatus.LOGGED_OUT_READY &&
            processingStatus.current !== ProcessingStatus.PROCESSING_LOGGED_OUT
          ) {
            await setLoggedOutFeatureFlags();
            await startSyncJobs();
          }
          break;
        case true:
          if (
            featureFlagsStatus !== FeatureFlagsStatus.LOGGED_IN_READY &&
            processingStatus.current !== ProcessingStatus.PROCESSING_LOGGED_IN
          ) {
            await setLoggedInFeatureFlags();
            await startSyncJobs();
          }
          break;
        case undefined:
          // Login state unknown, so do nothing.
          break;
        default:
          loggedIn satisfies never;
      }
    }

    init();
  }, [
    featureFlagsStatus,
    getCurrentFeatureRing,
    loggedIn,
    onLoggedInAndError,
    onLoggedInAndSuccess,
    setFeatureFlagsStatus,
    updateFeatureFlags,
  ]);

  useEffect(() => {
    // As we get new feature values from various adapters (stormcrow,
    // growthbook) we should update our store. This ensures we're not waiting
    // on the slowest adapter to return before updating the UI.
    const subscription = listenForFeatures().subscribe((flags) => {
      updateFeatureFlags(flags);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [updateFeatureFlags]);

  return featureFlagsStatus;
}

export function useFeatureFlags() {
  return useAtomValue(featureFlagsAtom);
}
