import { FC, useCallback, useEffect, useState } from "react";

import { Button } from "@dropbox/dig-components/buttons";
import { TextInput } from "@dropbox/dig-components/text_fields";
import { Text } from "@dropbox/dig-components/typography";
import { ThemeContainer, ThemeProvider } from "@dropbox/dig-foundations";
import { useIsDropboxer } from "@mirage/service-auth/useDropboxAccount";
import { showSnackbar } from "@mirage/shared/snackbar";
import {
  isUserEligibleByCache,
  resetEligibilityCache,
  tempSkipEligibilityCheck,
} from "@mirage/webapp/gating/Eligibility";
import { RoutePath } from "@mirage/webapp/routeTypes";

import { sharedWorkerDatabase } from "../shared-worker/sharedWorkerStorage";

import styles from "./UserResearch.module.css";

function hash(s: string) {
  const n = s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

  const code = String(Math.abs(n));

  // Grab 5 digits only. Use slice on string to keep leading zeros.
  // Start from second digit to allow a leading zero (0-9 instead of 1-9).
  return code.slice(1, 6);
}

/** This code updates itself daily. */
function generateSecretCode(): string {
  const dateString = new Date().toISOString().substring(0, 10);
  return hash(dateString) + "";
}

/**
 * Admin tool webpage to enable UX researchers to whitelist any user for a
 * short time. No need i18n for anything here.
 */
export const UserResearch: FC = () => {
  const isDropboxer = useIsDropboxer();

  const [code, setCode] = useState("");
  const [value, setValue] = useState("");
  const [isEligible, setIsEligible] = useState(false);

  const submit = useCallback(() => {
    if (value === code) {
      tempSkipEligibilityCheck(sharedWorkerDatabase);
      setIsEligible(true);
      setValue("");
    } else if (value) {
      showSnackbar({ title: "Incorrect code entered" });
    }
  }, [code, value]);

  useEffect(() => {
    const code = generateSecretCode();
    setCode(code);

    const urlParams = new URLSearchParams(location.search);
    const urlCode = urlParams.get("code");

    if (urlCode === code) {
      tempSkipEligibilityCheck(sharedWorkerDatabase).then(() => {
        // Jump straight to login page.
        location.href = RoutePath.LOGIN;
      });
    }
  }, []);

  useEffect(() => {
    isUserEligibleByCache(sharedWorkerDatabase).then(setIsEligible);
  }, []);

  return (
    <ThemeProvider mode="dark">
      <ThemeContainer>
        <div className={styles.pageContainer}>
          <div className={styles.mainGrid}>
            {isEligible ? (
              <div>
                <Text>🎉 You are eligible to use Dash 🎉</Text>
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="opacity"
                  onClick={() => {
                    resetEligibilityCache(sharedWorkerDatabase);
                    setIsEligible(false);
                  }}
                >
                  Reset
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="primary"
                  onClick={() => {
                    location.href = RoutePath.LOGIN;
                  }}
                >
                  Login
                </Button>
              </div>
            ) : (
              <div>
                <Text>Please enter the secret code to use Dash.</Text>
              </div>
            )}

            {/* In a grid layout, we need to specify each element separately. */}
            {isDropboxer && <Text>Secret code:</Text>}

            {isDropboxer && (
              <TextInput
                wrapperProps={{ className: styles.textInput }}
                value={code}
              />
            )}

            {isDropboxer && <Text>URL with code:</Text>}

            {isDropboxer && (
              <TextInput
                wrapperProps={{ className: styles.urlInput }}
                value={location.origin + location.pathname + "?code=" + code}
              />
            )}

            {isEligible || <Text>Enter code:</Text>}

            {isEligible || (
              <TextInput
                wrapperProps={{ className: styles.textInput }}
                maxLength={30}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    submit();
                  }
                }}
              />
            )}
          </div>
        </div>
      </ThemeContainer>
    </ThemeProvider>
  );
};
