import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { batchedIntervalFunc } from '@mirage/shared/util/batching';
import * as rx from 'rxjs';

import type {
  DisplayStatData,
  DisplayStatsByName,
  MetricData,
  Service,
  Tags,
} from '@mirage/service-operational-metrics/service';

const FLUSH_INTERVAL_MS = 1000;

// Note: using services.getp() causes the service to return the subscription
// data object instead of an Observable (contrary to type-checking), so we need
// to use service.get() here.
const service = services.get<Service>(ServiceId.OPERATIONAL_METRICS);

export interface Namespace {
  counter(name: string, value: number, tags?: Tags): void;
  stats(name: string, value: number, tags?: Tags): void;
}

const addCounterToBatch = batchedIntervalFunc((batch: MetricData[]) => {
  return rx.firstValueFrom(service.countersBatch(batch));
}, FLUSH_INTERVAL_MS);

const addStatsToBatch = batchedIntervalFunc((batch: MetricData[]) => {
  return rx.firstValueFrom(service.statsBatch(batch));
}, FLUSH_INTERVAL_MS);

// TODO (Matt): should probably capture failures here
export function namespace(ns: string): Namespace {
  const counter = (name: string, value: number, tags?: Tags) => {
    addCounterToBatch({ metric: { ns, name }, value, tags });
  };

  const stats = (name: string, value: number, tags?: Tags) => {
    addStatsToBatch({ metric: { ns, name }, value, tags });
  };

  return { counter, stats };
}

export function getDisplayStats() {
  return rx.firstValueFrom(service.getDisplayStats());
}

export function batchAddDisplayStat(batch: DisplayStatData[]) {
  return rx.firstValueFrom(service.batchAddDisplayStat(batch));
}

const addDisplayStatToBatch = batchedIntervalFunc(
  (batch: DisplayStatData[]) => {
    return batchAddDisplayStat(batch);
  },
  FLUSH_INTERVAL_MS,
);

export function addDisplayStat(metricName: string, valueMs: number) {
  addDisplayStatToBatch({ metricName, valueMs });
}

export function clearDisplayStats() {
  return rx.firstValueFrom(service.clearDisplayStats());
}

export async function subscribeToDisplayStatsUpdates(
  callback: (event: DisplayStatsByName) => void | Promise<void>,
) {
  const observable = service.displayStatsByNameObservable();
  return observable.subscribe(callback);
}
