/* eslint @typescript-eslint/no-explicit-any: 0 */
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';

import type { Observable } from 'rxjs';

type UnionToIntersection<U> = (U extends any ? (x: U) => void : never) extends (
  x: infer I,
) => void
  ? I
  : never;

// cleanly merges together intersections to a single object
type Id<T> = T extends infer U ? { [K in keyof U]: U[K] } : never;

// voodoo magic, typescript can't ascertain by non-tuple types each index type,
// so we get a type union of possibilties and use the first index
type Values<T extends Observable<any>[]> = {
  [K in keyof T]: T[K] extends Observable<infer V> ? Partial<V> : never;
}[0];

// typing to merge together observable emission values into an aggregate type
type Merge<T extends Observable<any>[]> = Id<UnionToIntersection<Values<T>>>;

type WithDestroy<T> = T & {
  destroy(): void;
};

export default async function mkcache<T extends Observable<any>>(
  input: T[],
): Promise<WithDestroy<Merge<typeof input>>> {
  let cache: Merge<typeof input> = {} as unknown as Merge<typeof input>;
  const subscription = rx
    .combineLatest(input)
    .pipe(
      op.scan(
        (_, values) =>
          values.reduce((obj, partial) => ({ ...obj, ...partial })),
        {} as Merge<typeof input>,
      ),
    )
    .subscribe((value) => (cache = value));

  const destroy = () => subscription.unsubscribe();

  const handler = {
    get(_: any, key: string) {
      if (key === 'destroy') return destroy;
      return (cache as any)[key];
    },
    ownKeys() {
      return Reflect.ownKeys(cache);
    },
    getOwnPropertyDescriptor(_: any, key: string) {
      return {
        value: this.get(undefined, key),
        enumerable: true,
        configurable: true,
      };
    },
  };

  // wait for all of the observables to emit a single value before returning
  // XXX (Matt): questionable implementation?
  return rx
    .firstValueFrom(rx.forkJoin(input.map((obs$) => obs$.pipe(op.first()))))
    .then(() => {
      return new Proxy({}, handler) as unknown as WithDestroy<
        Merge<typeof input>
      >;
    });
}
