import { stacks } from '@dropbox/api-v2-client';
import { IconButton } from '@dropbox/dig-components/buttons';
import { Skeleton } from '@dropbox/dig-components/skeleton';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine, RotateRightLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashSuggestedLink } from '@mirage/analytics/events/types/open_dash_suggested_link';
import { PAP_Refresh_DashSuggestedLink } from '@mirage/analytics/events/types/refresh_dash_suggested_link';
import { PAP_Shown_DashCard } from '@mirage/analytics/events/types/shown_dash_card';
import { PAP_Shown_DashSuggestedLink } from '@mirage/analytics/events/types/shown_dash_suggested_link';
import { PAP_Shown_SuggestedAutoStack } from '@mirage/analytics/events/types/shown_suggested_auto_stack';
import {
  StackTheme,
  stackThemeColorNames,
} from '@mirage/dash-component-library/themes/Stacks';
import { LinkComponent, LinkList } from '@mirage/link-list';
import { StackIcon } from '@mirage/link-list/Favicon/Favicon';
import { ListItemSize } from '@mirage/link-list/types';
import { ContentTile } from '@mirage/mosaics/ContentTile/ContentTile';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import {
  HomePageModule,
  MetricPageName,
} from '@mirage/service-operational-metrics/module/constants';
import { useRecordModuleLatency } from '@mirage/service-operational-metrics/module/module';
import {
  getFreshLoginTags,
  useFreshLoginTags,
} from '@mirage/service-operational-metrics/module/tags';
import { openURL } from '@mirage/service-platform-actions';
import { DefaultCustomizableModuleId } from '@mirage/service-settings/service/customize';
import { useCustomizableModuleIsHidden } from '@mirage/service-settings/useCustomizableModuleSettings';
import { refreshStackSuggestions } from '@mirage/service-stack-suggestions';
import { useStackSuggestions } from '@mirage/service-stack-suggestions/useStackSuggestions';
import { stackItemComputeNameFromFields } from '@mirage/service-stacks/service/utils';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import {
  CardHeaderType,
  TwoColumnGridCard,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import { indexFromSeedString } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { AsyncLinkListCard, LinkWithRenderData } from './AsyncCard';
import styles from './SuggestedStackCard.module.css';
import { useImpressionLogging } from './useImpressionLogging';

import type { FullScreenStackV2IncomingState } from '@mirage/stacks/FullScreenStack/types';

const actionSurfaceComponent = 'suggested_auto_stacks';
const featureLine = 'suggested_auto_stacks';
const SUGGESTED_STACKS_VISIBLE = 2;
const MAX_ITEMS_TO_PREVIEW = 3;

const createSuggestedStackLink = (
  autostack: stacks.AutoStackSuggestion,
): LinkWithRenderData => {
  const { stack_data, items, prediction_id_hash } = autostack;
  const colorIndex: StackTheme = indexFromSeedString(
    stack_data?.name ?? '',
    stackThemeColorNames.length,
  );

  const state: FullScreenStackV2IncomingState = {
    name: stack_data?.name ?? '',
    colorIndex,
    items: items ?? [],
    predictionIdHash: prediction_id_hash,
    autostackSuggestion: autostack,
  };
  return {
    title: `\u201c${stack_data?.name ?? ''}\u201d`,
    subtitle: i18n.t('num_items', { count: items?.length ?? 0 }),
    url: `/stacks/new`,
    isInternalLink: { state },
    accessoryComponent: (
      <IconButton variant="transparent">
        <UIIcon src={AddLine} className={styles.rightIcon} />
      </IconButton>
    ),
    icon: <StackIcon />,
    iconTheme: colorIndex,
    predictionIdHash: autostack.prediction_id_hash,
    exposurePapEvents: [
      PAP_Shown_SuggestedAutoStack({
        actionSurfaceComponent,
        featureLine,
      }),
    ],
  };
};

export const SuggestedStacksCard: React.FC = () => {
  const isHidden = useCustomizableModuleIsHidden(
    DefaultCustomizableModuleId.NEW_STACK_SUGGESTIONS,
  );

  return isHidden ? null : <SuggestedStacksCardActual />;
};

const LoadingSkeletons = Array.from(
  { length: SUGGESTED_STACKS_VISIBLE },
  (_, i) => (
    <LinkComponent
      key={i}
      link={{
        url: '',
        title: '',
      }}
      isLoading
      listItemSize={ListItemSize.Large}
    >
      <div
        className={classnames(
          styles.suggestionPreviewContainer,
          styles.suggestionPreviewContainerLoading,
        )}
      >
        {Array.from({ length: MAX_ITEMS_TO_PREVIEW }, (_, i) => (
          <Skeleton.Text
            key={i}
            shouldAnimate
            size="large"
            // This 232 width is the max width on the ContentTile component
            width={232}
            paddingY="12"
          />
        ))}
      </div>
    </LinkComponent>
  ),
);

const SuggestedStacksCardActual = () => {
  const showStackPreviews = useFeatureFlagValue(
    'dash_preview_suggested_stacks',
  );
  const { reportPapEvent } = useMirageAnalyticsContext();
  const [visibleStackIndex, setVisibleStackIndex] = useState(0);
  const [syntheticLoading, setSynthenticLoading] = useState(false);
  const { autoStacks, isLoading, cleanupPendingRefresh } =
    useStackSuggestions();
  const [autoStacksForDisplay, setAutoStacksForDisplay] = useState<
    LinkWithRenderData[]
  >([]);

  useEffect(() => {
    return cleanupPendingRefresh;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Used to track if we have sent the exposure event for a link, by item_id_hash
  const linkExposureEvents = useRef<{
    [key: string]: boolean;
  }>({});

  const freshLoginTags = useFreshLoginTags();

  const { markModuleLoadComplete } = useRecordModuleLatency(
    MetricPageName.HOME_V2,
    HomePageModule.SUGGESTED_STACKS_V2,
    freshLoginTags || getFreshLoginTags,
  );

  useEffect(() => {
    if (!isLoading) {
      markModuleLoadComplete();
    }
  }, [isLoading, markModuleLoadComplete]);

  const handleRefreshSuggestions = async () => {
    if (!showStackPreviews) return;
    reportPapEvent(
      PAP_Refresh_DashSuggestedLink({
        actionSurfaceComponent,
        featureLine,
      }),
    );

    // As long as we still have more stacks to show, we'll show the next set
    if (autoStacks.length - visibleStackIndex > SUGGESTED_STACKS_VISIBLE) {
      setSynthenticLoading(true);
      setTimeout(() => {
        setVisibleStackIndex(visibleStackIndex + SUGGESTED_STACKS_VISIBLE);
        setSynthenticLoading(false);
      }, 500);
    }
    // Otherwise, we'll refresh the suggestions
    else {
      // Set synthentic loading here to avoid flicker when new data is returned
      setSynthenticLoading(true);
      await refreshStackSuggestions();
      // Clear out exposure tracking when we refresh
      linkExposureEvents.current = {};
      setVisibleStackIndex(0);
      setSynthenticLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      if (autoStacksForDisplay.length > 0) {
        setAutoStacksForDisplay([]);
      }
    } else {
      const newStacksToShow = autoStacks.slice(
        visibleStackIndex,
        showStackPreviews
          ? visibleStackIndex + SUGGESTED_STACKS_VISIBLE
          : visibleStackIndex + 4,
      );
      if (
        !newStacksToShow.every(
          (stack, i) =>
            stack.prediction_id_hash ===
            autoStacksForDisplay[i]?.predictionIdHash,
        )
      ) {
        setAutoStacksForDisplay(newStacksToShow.map(createSuggestedStackLink));
      }
    }
  }, [
    isLoading,
    visibleStackIndex,
    autoStacks,
    showStackPreviews,
    autoStacksForDisplay,
  ]);

  useImpressionLogging(
    !isLoading && autoStacksForDisplay.length > 0,
    'suggested_stacks',
    PAP_Shown_DashCard({
      actionSurfaceComponent,
      featureLine,
      dashCardType: 'suggested_stacks',
    }),
  );

  // This will only show the loading state on the card if we previously
  // had suggestions. This should be a good indication of if we will
  // be able to successfully fetch suggestions on this attempt.
  if (autoStacks.length === 0) {
    return null;
  }

  if (showStackPreviews) {
    return (
      <TwoColumnGridCard
        settingId="suggested_stacks"
        showSubtitleWhenExpanded
        isAlwaysCollapsed={false}
        showsChildrenWhileCollapsed
        cardTypeProps={{
          actionSurfaceComponent,
          featureLine,
          title: i18n.t('suggested_stacks'),
          cardType: CardHeaderType.INLINE_SUBTITLE,
        }}
        headerActions={
          <IconButtonWithTooltip
            tooltipProps={{
              title: i18n.t('refresh'),
            }}
            variant="borderless"
            onClick={handleRefreshSuggestions}
          >
            <UIIcon src={RotateRightLine} />
          </IconButtonWithTooltip>
        }
      >
        <LinkList listItemSize={ListItemSize.Large}>
          {isLoading || syntheticLoading
            ? LoadingSkeletons
            : autoStacksForDisplay.map((link, i) => {
                const autostack = autoStacks[visibleStackIndex + i];
                const { items, content_items, prediction_id_hash } = autostack;

                return (
                  <LinkComponent
                    key={link.title}
                    link={link}
                    listItemSize={ListItemSize.Large}
                    icon={link.icon}
                    iconTheme={link.iconTheme}
                    isInternalLink={link.isInternalLink}
                    accessoryComponent={link.accessoryComponent}
                    exposurePapEvents={link.exposurePapEvents}
                    isLoading={isLoading}
                  >
                    <div className={styles.suggestionPreviewContainer}>
                      {items
                        ?.slice(0, MAX_ITEMS_TO_PREVIEW)
                        .map((item, itemIdx) => {
                          if (item['.tag'] === 'shortcut' && item.url) {
                            const url = item.url;
                            const itemIdHash =
                              content_items?.[itemIdx].item_id_hash;
                            if (
                              itemIdHash &&
                              !linkExposureEvents.current[itemIdHash]
                            ) {
                              linkExposureEvents.current = {
                                ...linkExposureEvents.current,
                                [itemIdHash]: true,
                              };
                              reportPapEvent(
                                PAP_Shown_DashSuggestedLink({
                                  actionSurfaceComponent,
                                  featureLine,
                                  predictionIdHash: prediction_id_hash,
                                  itemIdHash,
                                }),
                              );
                            }
                            return (
                              <ContentTile
                                key={url}
                                url={url}
                                label={
                                  stackItemComputeNameFromFields(item) || url
                                }
                                size="small"
                                onClick={() => {
                                  openURL(url);
                                  reportPapEvent(
                                    PAP_Open_DashSuggestedLink({
                                      actionSurfaceComponent,
                                      featureLine,
                                      predictionIdHash: prediction_id_hash,
                                      itemIdHash,
                                    }),
                                  );
                                }}
                              />
                            );
                          }
                        })}
                    </div>
                  </LinkComponent>
                );
              })}
        </LinkList>
      </TwoColumnGridCard>
    );
  }

  return (
    <AsyncLinkListCard
      settingId="suggested_stacks"
      title={i18n.t('suggested_stacks')}
      links={autoStacksForDisplay}
      loadingRowCount={4}
      showSubtitleWhenExpanded
      isCollapsedInitially
      actionSurfaceComponent={actionSurfaceComponent}
      featureLine={featureLine}
      openPapEvents={[]}
    />
  );
};
