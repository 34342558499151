// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgTwitter({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.905 21c-2.541 0-4.91-.695-6.905-1.895 1.693.103 4.681-.144 6.54-1.812-2.796-.12-4.057-2.138-4.221-3 .237.086 1.37.19 2.01-.052-3.217-.758-3.71-3.413-3.6-4.224.603.397 1.626.535 2.028.5C.76 8.5 1.838 5.466 2.368 4.81 4.52 7.613 7.742 9.188 11.73 9.275a4.09 4.09 0 01-.115-.965c0-2.38 2.046-4.31 4.569-4.31 1.318 0 2.506.527 3.34 1.37.88-.195 2.206-.65 2.855-1.042-.327 1.103-1.344 2.024-1.96 2.365.006.011-.004-.012 0 0 .541-.077 2.003-.342 2.581-.71-.286.62-1.364 1.65-2.249 2.227C20.916 15.041 15.361 21 7.905 21z"
        fill="#47ACDF"
      />
    </svg>
  );
}

export default SvgTwitter;
