// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgZendesk({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.786 4v11.429L23 4H12.786zM6.107 9.09c2.794 0 5.107-2.606 5.107-5.09H1c0 2.484 2.313 5.09 5.107 5.09zM12.786 20c0-2.484 2.313-5.09 5.107-5.09S23 17.515 23 20H12.786zM11.214 20V8.571L1 20h10.214z"
        fill="#07363D"
      />
    </svg>
  );
}

export default SvgZendesk;
