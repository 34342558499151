import type { TypeaheadResult } from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { Namespace } from '@mirage/service-operational-metrics';
import type { typeahead } from '@mirage/service-typeahead-search/service/types';

type SearchResultSource = 'local_cache' | 'upstream' | 'server';

type SearchResultMetricsTags = {
  surface: 'serp' | 'typeahead';
  typeaheadResultType: typeahead.ResultType | 'n/a';
  brandedType: string;
  position?: number;
  entityType?: string;
  source?: SearchResultSource;
};

export function captureTypeaheadClickMetrics(
  metrics: Namespace,
  result: TypeaheadResult,
  position: number,
) {
  const tags: SearchResultMetricsTags = {
    surface: 'typeahead',
    typeaheadResultType: result.type,
    brandedType: 'n/a',
    position, // track in both count and stats for historgram/grouping
  };

  metrics.counter('click', 1, tags);
  metrics.stats('click/position', position, tags);
}

export function captureTypeaheadImpressionMetrics(
  metrics: Namespace,
  result: TypeaheadResult,
  position: number,
) {
  const tags: SearchResultMetricsTags = {
    surface: 'typeahead',
    typeaheadResultType: result.type,
    brandedType: 'n/a',
    position, // track in both count and stats for historgram/grouping
  };

  metrics.counter('impression', 1, tags);
  metrics.stats('impression/position', position, tags);
}

export function captureSerpClicMetrics(
  metrics: Namespace,
  result: SearchResult,
  position: number,
) {
  const tags: SearchResultMetricsTags = {
    surface: 'serp',
    brandedType: result.connectorInfo.connectorName,
    typeaheadResultType: 'n/a', // can't send undefined values to apex
    position,
    entityType: result.recordType['.tag'] || 'unknown',
    source: result.searchResultSource,
  };

  metrics.counter('click', 1, tags);
  metrics.stats('click/position', position, tags);
}

export function captureSerpImpressionMetrics(
  metrics: Namespace,
  result: SearchResult,
  position: number,
) {
  const tags: SearchResultMetricsTags = {
    surface: 'serp',
    brandedType: result.connectorInfo.connectorName,
    typeaheadResultType: 'n/a', // can't send undefined values to apex
    position,
    entityType: result.recordType['.tag'] || 'unknown',
    source: result.searchResultSource,
  };
  metrics.counter('impression', 1, tags);
  metrics.stats('impression/position', position, tags);
}

export function getSearchResultPosition(
  results: SearchResult[],
  result: SearchResult,
): number {
  return results.findIndex((r) => r === result) + 1;
}
