import {
  ExperimentSource,
  FeatureFlag,
  FeatureValue,
} from '@mirage/service-experimentation/features';
import { ExperimentationServiceAdapter } from '@mirage/service-experimentation/service';
import { tagged } from '@mirage/service-logging';

import type { APIv2Callable } from '@mirage/service-dbx-api/service';

const logger = tagged('stormcrow');

interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

class StormcrowImpl implements ExperimentationServiceAdapter {
  public source: ExperimentSource = 'stormcrow';
  private fetchPromise: Promise<FeatureFlag[]> | null = null;

  constructor(private readonly dbxApiService: DbxApiServiceContract) {}

  private async fetchValuesFromSC(
    features: FeatureFlag[],
  ): Promise<FeatureFlag[]> {
    if (!this.fetchPromise) {
      this.fetchPromise = (async () => {
        // Construct feature names in the format stormcrow API expects
        const featureNames = features.map(({ featureName }) => ({
          feature: featureName,
        }));

        try {
          const { assignments } = await this.dbxApiService.callApiV2(
            'stormcrowServicerGetAssignments',
            {
              features: featureNames,
            },
          );

          // creating a map of feature name:variant for easy lookup
          const featureVariantMap = new Map(
            assignments?.map((item) => [item.feature, item.variant]),
          );

          return features.map((feature) => ({
            ...feature,
            // Note: The cast to FeatureValue is unsafe and not guaranteed to
            // be correct because the server can specify any string value.
            // If you need to use non-standard string values, then we might
            // want to think of a way to better represent the type enums for
            // each flag.
            value: (featureVariantMap.get(feature.featureName) ||
              feature.value) as FeatureValue,
          }));
        } catch (e) {
          logger.warn(`Fetch stormcrow failed, defaulting to all OFF`, e);

          // This is necessary for the code to work in devbox.
          return featureNames.map(({ feature }) => ({
            featureName: feature,
            source: this.source,
            value: 'OFF',
          }));
        }
      })();
    }

    try {
      return await this.fetchPromise;
    } finally {
      this.fetchPromise = null;
    }
  }

  public async fetchFeatureValues(
    features: FeatureFlag[],
  ): Promise<FeatureFlag[]> {
    return await this.fetchValuesFromSC(features);
  }

  public setAttributes() {
    // No attributes on stormcrow. Noop.
  }
}

export const StormcrowAdapter = (
  dbxApiService: DbxApiServiceContract,
): StormcrowImpl => new StormcrowImpl(dbxApiService);

export default StormcrowAdapter;
