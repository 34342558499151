// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgGoogleMail({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.238 19.238h3.334c.789 0 1.428-.626 1.428-1.4V7.81l-4.762 3.499v7.93z"
        fill="#34A853"
      />
      <path
        d="M3.429 19.238h3.333v-7.93L2 7.809v10.03c0 .773.64 1.399 1.429 1.399z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.761 11.619v-6.19L12 9.238l5.239-3.81v6.19l-5.239 3.81-5.238-3.81z"
        fill="#EA4335"
      />
      <path
        d="M17.238 5.432v6.187L22 8.049V6.146c0-1.765-2.015-2.773-3.428-1.714l-1.334 1z"
        fill="#FBBC04"
      />
      <path
        d="M2 6.146v1.903l4.762 3.57V5.432l-1.333-1C4.015 3.373 2 4.381 2 6.146z"
        fill="#C5221F"
      />
    </svg>
  );
}

export default SvgGoogleMail;
