import { dedupePreviousQueries } from '@mirage/service-typeahead-search/service/typeahead-cache/utils';

import type { MemoryCache } from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches';
import type { PreviousQuery } from '@mirage/shared/search/cache-result';

export const RECENT_QUERIES_CACHE_LIMIT = 100;

class RecentQueriesCache implements MemoryCache<PreviousQuery> {
  private cache: PreviousQuery[] = [];

  all() {
    return this.cache;
  }

  addItems(items: PreviousQuery[] = []): Promise<PreviousQuery[]> {
    this.cache = dedupePreviousQueries([...items, ...this.cache]).slice(
      0,
      RECENT_QUERIES_CACHE_LIMIT,
    );
    return Promise.resolve(this.cache);
  }

  removeItem(item: PreviousQuery): Promise<PreviousQuery[]> {
    this.cache = this.cache.filter((cacheItem) => cacheItem.uuid != item.uuid);
    return Promise.resolve(this.cache);
  }

  clear() {
    this.cache = [];
  }

  count() {
    return this.cache.length;
  }
}

export default RecentQueriesCache;
