import * as primitives from '@mirage/service-typeahead-search/service/primitives';
import { TypeaheadCache } from '@mirage/service-typeahead-search/service/typeahead-cache';
import { SourceId } from '@mirage/service-typeahead-search/service/types';
import * as wrappers from '@mirage/service-typeahead-search/service/utils/wrappers';
import { SEARCH_DASH_UUID } from '@mirage/shared/search/cache-result';
import * as rx from 'rxjs';

import type {
  SuggestedQuery,
  typeahead,
} from '@mirage/service-typeahead-search/service/types';
import type { Observable } from 'rxjs';

export const search = wrappers.wrapped(SourceId.SuggestedQueries, raw);

export function raw(
  query: string,
  _config: typeahead.Config,
  _cache: TypeaheadCache,
): Observable<typeahead.TaggedResult> {
  return rx.defer(() => {
    if (query.length > 0) {
      return rx.of(
        primitives.suggestedQuery(SEARCH_DASH_UUID, {
          uuid: SEARCH_DASH_UUID,
          query,
        } satisfies SuggestedQuery),
      );
    } else {
      return rx.of();
    }
  });
}
