import classNames from 'classnames';
import { memo } from 'react';
import styles from './SideBarFooter.module.css';

interface SideBarFooterProps {
  showBorder?: boolean;
  children: React.ReactNode;
}
export const SideBarFooter = memo(
  ({ children, showBorder = false }: SideBarFooterProps) => {
    const containerStyles = showBorder
      ? [styles.container, styles.withBorder]
      : [styles.container];

    return <div className={classNames(containerStyles)}>{children}</div>;
  },
);
SideBarFooter.displayName = 'SideBarFooter';
