import { ServiceId } from '@mirage/discovery/id';
import * as service from '@mirage/discovery/services';
import { PersonObject } from '@mirage/shared/search/search-filters';
import { Service } from './service';

const peopleService = service.getp<Service>(ServiceId.PEOPLE);

export default peopleService;

export function getPeopleSuggestions(): Promise<PersonObject[]> {
  return peopleService.getPeopleSuggestions();
}

export function performPeopleSearch(query: string): Promise<PersonObject[]> {
  return peopleService.performPeopleSearch(query);
}

export function tearDown(): Promise<void> {
  return peopleService.tearDown();
}
