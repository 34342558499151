import { Skeleton } from '@dropbox/dig-components/skeleton';
import styles from './LoadingSuggestions.module.css';

export const LoadingSuggestions: React.FC = () => {
  return (
    <div className={styles.box}>
      <LoadingSuggestionRow opacity={1} />
      <LoadingSuggestionRow opacity={1} />
      <LoadingSuggestionRow opacity={0.66} />
      <LoadingSuggestionRow opacity={0.5} />
      <LoadingSuggestionRow opacity={0.33} />
      <LoadingSuggestionRow opacity={0.2} />
    </div>
  );
};

const LoadingSuggestionRow: React.FC<{ opacity: number }> = ({ opacity }) => {
  return (
    <div className={styles.loadingSuggestionRow} style={{ opacity }}>
      <Skeleton.Avatar />
      <Skeleton.Text width={253} />
    </div>
  );
};
