import {
  mergePartialFilterText,
  type SearchFilter,
  SearchFilterBinding,
  SearchFilterKeyword,
} from '@mirage/shared/search/search-filters';
import i18n from '@mirage/translations';
import React, { useMemo } from 'react';
import styles from './SearchFilterRowSubTitle.module.css';

export function SearchFilterResultSubTitle({
  query,
  nextFilter,
}: {
  nextFilter: SearchFilter;
  query: string;
}) {
  const [restOfQuery, prefix, beforeMatch, matchText, afterMatch] =
    useMemo(() => {
      const [prefix, typedFilterText = ''] =
        extractTrailingPartialFilterBinding(query);

      return [
        extractAllButTrailingFilterBinding(query, nextFilter),
        prefix,
        ...parseFilterTextByMatchedTyping(
          nextFilter,
          typedFilterText.trim().toLowerCase(),
        ),
      ];
    }, [query, nextFilter]);

  return (
    <span className={styles.baseText}>
      <span>
        {i18n.t('search_filter_result_byline')} - {restOfQuery}{' '}
      </span>
      <span className={styles.prefix}>{prefix}:</span>
      <span className={styles.autocompleteText}>{beforeMatch}</span>
      <span className={styles.matchedText}>{matchText}</span>
      <span className={styles.autocompleteText}>{afterMatch}</span>
    </span>
  );
}

function extractTrailingPartialFilterBinding(
  query: string,
): SearchFilterBinding {
  const rawQuery = query.split(' ');
  const rawLastWord = rawQuery[rawQuery.length - 1] || '';
  const [prefix, typedFilterText = ''] = rawLastWord.split(':');
  return [prefix as SearchFilterKeyword, typedFilterText];
}

function extractAllButTrailingFilterBinding(
  query: string,
  nextFilter: SearchFilter,
): string {
  const newQuery = mergePartialFilterText(query, nextFilter.id);
  const words = newQuery.split(' ');
  return words.slice(0, words.length - 1).join(' ');
}

function parseFilterTextByMatchedTyping(
  nextFilter: SearchFilter,
  formattedTypedText: string,
): [string, string, string] {
  const matchIndex = nextFilter.id.toLowerCase().indexOf(formattedTypedText);

  if (matchIndex !== -1 && formattedTypedText.length > 0) {
    return [
      nextFilter.id.substring(0, matchIndex),
      nextFilter.id.substring(
        matchIndex,
        matchIndex + formattedTypedText.length,
      ),
      nextFilter.id.substring(matchIndex + formattedTypedText.length),
    ];
  } else {
    return [nextFilter.id, '', ''];
  }
}
