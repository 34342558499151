// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgSafari({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11z"
        fill="url(#safari_svg__paint0_linear)"
      />
      <path
        d="M12 22.175c5.62 0 10.175-4.556 10.175-10.175 0-5.62-4.556-10.175-10.175-10.175C6.38 1.825 1.825 6.38 1.825 12c0 5.62 4.556 10.175 10.175 10.175z"
        fill="url(#safari_svg__paint1_radial)"
      />
      <path
        d="M11.123 20.663c.07.008.12.06.12.128l-.06.8a.125.125 0 01-.137.111c-.069-.008-.12-.06-.12-.129l.06-.799a.125.125 0 01.137-.111zM12.868 20.663a.132.132 0 01.146.103l.103.79a.125.125 0 01-.112.138.132.132 0 01-.146-.103l-.103-.791a.125.125 0 01.112-.137z"
        fill="#F3F3F3"
      />
      <path
        d="M12.868 20.654c.077-.009.146.043.155.112l.103.79a.134.134 0 01-.12.146c-.078.009-.147-.043-.155-.111l-.103-.791a.134.134 0 01.12-.146zm.129 1.031c.06-.008.112-.069.103-.129l-.103-.79a.115.115 0 00-.13-.095c-.06.009-.11.069-.102.129l.103.79c.008.06.069.104.129.095zM11.123 20.654a.14.14 0 01.13.137l-.06.8c-.01.068-.07.129-.147.12a.14.14 0 01-.129-.137l.06-.8c.009-.077.07-.129.146-.12zm-.077 1.04c.06 0 .12-.043.12-.103l.06-.8c0-.06-.042-.111-.111-.12-.06 0-.12.043-.12.103l-.06.8c0 .06.051.111.111.12z"
        fill="#fff"
      />
      <path
        d="M18.72 17.534c.043-.051.12-.068.172-.025l.628.49c.051.042.06.12.017.171-.043.052-.12.07-.172.026l-.627-.49a.123.123 0 01-.018-.172zM19.666 16.125c.034-.06.103-.086.163-.06l.713.36c.06.027.086.104.052.173-.035.06-.104.086-.164.06l-.713-.361c-.06-.034-.086-.112-.051-.172z"
        fill="#F3F3F3"
      />
      <path
        d="M19.657 16.116a.131.131 0 01.18-.06l.714.361a.131.131 0 01.06.18.131.131 0 01-.18.06l-.714-.36c-.069-.026-.094-.112-.06-.18zm.928.473c.026-.06.009-.129-.052-.155l-.713-.36c-.051-.026-.12 0-.154.05-.026.061-.01.13.051.156l.713.36c.06.026.13.01.155-.051z"
        fill="#fff"
      />
      <path
        d="M18.402 16.288c.043-.06.112-.077.172-.034l1.495.997c.06.034.07.111.035.172-.043.06-.112.077-.172.034l-1.495-.997c-.06-.043-.078-.12-.035-.172z"
        fill="#F3F3F3"
      />
      <path
        d="M18.394 16.28a.149.149 0 01.189-.043l1.495.997c.06.042.078.12.035.189a.148.148 0 01-.19.043l-1.495-.997c-.069-.043-.077-.13-.034-.19zm1.693 1.134c.034-.051.026-.129-.026-.163l-1.495-.997a.112.112 0 00-.155.034c-.034.052-.026.13.026.163l1.495.997c.052.035.12.018.155-.034zM18.712 17.526c.043-.06.129-.069.189-.026l.627.49c.06.043.069.129.026.189-.043.06-.129.069-.19.026l-.627-.49c-.06-.043-.068-.13-.025-.19zm.825.644c.043-.051.034-.12-.017-.163l-.628-.49a.122.122 0 00-.163.026c-.043.052-.034.12.017.163l.627.49c.052.034.12.026.164-.026z"
        fill="#fff"
      />
      <path
        d="M17.44 17.448c.051-.051.129-.051.172 0l1.272 1.264a.122.122 0 010 .172c-.052.051-.13.051-.172 0L17.44 17.62a.131.131 0 010-.172z"
        fill="#F3F3F3"
      />
      <path
        d="M17.44 17.44a.135.135 0 01.189 0l1.272 1.263a.135.135 0 010 .19.135.135 0 01-.19 0l-1.271-1.264a.122.122 0 010-.19zm1.444 1.435a.12.12 0 000-.163l-1.272-1.264a.12.12 0 00-.163 0 .12.12 0 000 .164l1.271 1.263c.043.052.112.052.164 0z"
        fill="#fff"
      />
      <path
        d="M17.517 18.729c.052-.043.13-.043.172.009l.524.601a.123.123 0 01-.017.172c-.051.043-.129.043-.172-.009l-.524-.601c-.043-.043-.034-.12.017-.172z"
        fill="#F3F3F3"
      />
      <path
        d="M17.509 18.729a.135.135 0 01.189.009l.524.601c.051.052.043.138-.017.19a.135.135 0 01-.19-.01l-.524-.601c-.043-.06-.043-.146.018-.19zm.687.782c.052-.043.052-.112.017-.163l-.524-.602a.12.12 0 00-.163-.008c-.052.043-.052.111-.017.163l.524.601c.043.052.112.052.163.009z"
        fill="#fff"
      />
      <path
        d="M16.28 18.402a.116.116 0 01.172.035l.996 1.486c.035.06.018.138-.034.172a.116.116 0 01-.172-.034l-.997-1.487c-.034-.06-.017-.137.035-.172z"
        fill="#F3F3F3"
      />
      <path
        d="M16.28 18.394a.137.137 0 01.189.034l.996 1.487a.132.132 0 01-.042.189.137.137 0 01-.19-.034l-.996-1.487a.13.13 0 01.043-.19zm1.134 1.693a.112.112 0 00.034-.155l-.996-1.487c-.035-.051-.104-.06-.164-.025a.112.112 0 00-.034.154l.997 1.487c.034.043.111.06.163.026z"
        fill="#fff"
      />
      <path
        d="M16.1 19.674a.125.125 0 01.171.043l.395.696c.035.06.009.138-.051.172a.125.125 0 01-.172-.043l-.395-.696c-.026-.06-.009-.137.051-.172z"
        fill="#F3F3F3"
      />
      <path
        d="M16.1 19.666c.068-.035.145-.018.18.043l.395.696c.034.06.017.146-.051.18-.07.035-.147.017-.181-.043l-.395-.696c-.035-.06-.017-.137.051-.18zm.515.91c.052-.034.077-.102.043-.154l-.395-.696a.123.123 0 00-.155-.043c-.052.034-.078.103-.043.155l.395.696c.026.051.103.077.155.043z"
        fill="#fff"
      />
      <path
        d="M14.956 19.107c.07-.026.138 0 .164.06l.696 1.659c.025.06-.009.137-.069.163-.06.026-.137 0-.163-.06l-.696-1.659c-.026-.06.008-.137.068-.163z"
        fill="#F3F3F3"
      />
      <path
        d="M14.956 19.098a.143.143 0 01.18.07l.697 1.658a.134.134 0 01-.078.172.142.142 0 01-.18-.07l-.696-1.658c-.026-.06.008-.137.077-.172zm.782 1.883c.06-.026.086-.095.06-.147l-.696-1.658a.116.116 0 00-.154-.06c-.06.025-.086.094-.06.146l.696 1.658c.034.06.094.086.154.06z"
        fill="#fff"
      />
      <path
        d="M14.535 20.327c.069-.026.138.009.163.078l.25.756c.025.06-.018.137-.078.155-.068.025-.137-.009-.163-.078l-.25-.756c-.025-.06.01-.129.078-.155zM9.448 20.319a.128.128 0 01.094.155l-.215.773c-.017.069-.085.103-.154.086a.128.128 0 01-.095-.155l.215-.773c.017-.069.086-.103.155-.086z"
        fill="#F3F3F3"
      />
      <path
        d="M14.527 20.319a.133.133 0 01.172.077l.249.756c.026.07-.017.146-.086.172a.134.134 0 01-.172-.077l-.25-.756a.146.146 0 01.087-.172zm.335.988c.06-.017.094-.086.077-.146l-.249-.756c-.017-.06-.086-.086-.146-.069-.06.017-.095.086-.077.146l.249.756a.109.109 0 00.146.07zM9.448 20.31c.069.017.12.095.094.164l-.215.773a.137.137 0 01-.163.094c-.069-.017-.12-.094-.094-.163l.215-.773c.017-.069.085-.112.163-.095zm-.284 1.006c.06.017.13-.017.146-.078l.215-.773c.017-.06-.017-.12-.086-.138-.06-.017-.129.018-.146.078l-.215.773c-.008.06.026.12.086.138z"
        fill="#fff"
      />
      <path
        d="M11.983 19.7c.069 0 .129.052.129.12v1.796c0 .069-.06.12-.13.12-.068 0-.128-.051-.128-.12V19.82c.009-.069.06-.12.129-.12z"
        fill="#F3F3F3"
      />
      <path
        d="M11.983 19.691c.077 0 .137.06.137.13v1.795c0 .069-.06.13-.137.13-.078 0-.138-.061-.138-.13V19.82c0-.069.07-.129.138-.129zm0 2.037c.06 0 .12-.052.12-.112V19.82c0-.06-.051-.111-.12-.111-.06 0-.12.051-.12.111v1.796c.008.06.06.112.12.112z"
        fill="#fff"
      />
      <path
        d="M7.866 19.657c.06.035.086.103.06.163l-.36.713a.126.126 0 01-.172.052c-.06-.034-.086-.103-.06-.163l.36-.713a.126.126 0 01.172-.052z"
        fill="#F3F3F3"
      />
      <path
        d="M7.867 19.649a.131.131 0 01.06.18l-.361.713a.131.131 0 01-.18.06.131.131 0 01-.06-.18l.36-.713a.131.131 0 01.18-.06zm-.473.936c.06.026.129.009.155-.051l.36-.713c.026-.052 0-.12-.051-.155-.06-.026-.129-.009-.155.051l-.36.713c-.026.052 0 .12.051.155z"
        fill="#fff"
      />
      <path
        d="M10.479 19.545a.132.132 0 01.103.146l-.352 1.762c-.018.069-.078.112-.146.095a.132.132 0 01-.104-.146l.353-1.762c.008-.06.077-.103.146-.095zM13.521 19.545c.069-.017.138.026.146.095l.361 1.762c.017.068-.034.128-.103.146-.069.017-.138-.026-.146-.095l-.361-1.762a.138.138 0 01.103-.146z"
        fill="#F3F3F3"
      />
      <path
        d="M10.48 19.537a.14.14 0 01.11.154l-.352 1.762a.138.138 0 01-.163.103.14.14 0 01-.112-.154l.353-1.762c.017-.069.086-.112.163-.103zm-.405 2.002c.06.009.12-.026.138-.086l.352-1.762a.122.122 0 00-.095-.137.123.123 0 00-.137.086l-.352 1.762c-.009.068.034.12.094.137zM13.521 19.537a.138.138 0 01.163.103l.361 1.762c.018.068-.034.146-.103.154a.138.138 0 01-.163-.103l-.361-1.762c-.017-.069.026-.137.103-.154zm.404 2.002c.06-.009.103-.069.095-.138l-.361-1.761c-.009-.06-.078-.103-.138-.086-.06.008-.103.069-.094.137l.36 1.762c.018.06.078.095.138.086z"
        fill="#fff"
      />
      <path
        d="M9.027 19.107a.123.123 0 01.068.163l-.687 1.659c-.026.06-.103.095-.163.06-.06-.034-.095-.103-.07-.163l.688-1.659c.026-.06.103-.094.163-.06z"
        fill="#F3F3F3"
      />
      <path
        d="M9.027 19.098a.134.134 0 01.077.172l-.688 1.659c-.025.069-.103.094-.18.069a.133.133 0 01-.078-.172l.688-1.659c.034-.069.112-.103.18-.069zm-.783 1.883c.06.025.13 0 .155-.06l.688-1.66c.025-.06-.009-.12-.07-.145a.117.117 0 00-.154.06l-.687 1.658a.122.122 0 00.068.146z"
        fill="#fff"
      />
      <path
        d="M6.44 18.703c.051.043.069.12.026.172l-.499.627a.122.122 0 01-.172.017c-.051-.042-.068-.12-.025-.171l.498-.628a.122.122 0 01.172-.017zM7.694 18.385c.06.043.078.12.035.172l-.997 1.487c-.034.06-.112.069-.172.034-.06-.043-.077-.12-.034-.172l.997-1.486a.116.116 0 01.171-.035z"
        fill="#F3F3F3"
      />
      <path
        d="M7.695 18.377c.06.043.077.129.042.189l-.996 1.486a.136.136 0 01-.19.035.149.149 0 01-.042-.19l.996-1.486a.137.137 0 01.19-.035zM6.56 20.07c.052.034.129.025.163-.026l.997-1.487a.112.112 0 00-.034-.155c-.052-.034-.129-.025-.163.026l-.997 1.478a.122.122 0 00.034.164zM6.448 18.695c.06.042.07.128.026.189l-.498.627c-.043.06-.13.069-.19.017-.06-.043-.068-.129-.025-.189l.498-.627c.043-.06.13-.069.19-.017zm-.644.816c.051.043.12.034.163-.017l.499-.628a.122.122 0 00-.026-.163c-.052-.043-.12-.034-.163.017l-.499.628c-.034.051-.026.129.026.163z"
        fill="#fff"
      />
      <path
        d="M6.526 17.414c.051.051.051.129 0 .172l-1.272 1.263a.122.122 0 01-.172 0c-.043-.043-.051-.129 0-.172l1.263-1.263a.125.125 0 01.18 0z"
        fill="#F3F3F3"
      />
      <path
        d="M6.534 17.406a.135.135 0 010 .189l-1.272 1.263a.135.135 0 01-.188 0 .135.135 0 010-.19l1.271-1.262a.135.135 0 01.19 0zm-1.452 1.443a.12.12 0 00.163 0l1.272-1.263a.12.12 0 000-.163.12.12 0 00-.163 0l-1.272 1.263c-.043.043-.043.112 0 .163z"
        fill="#fff"
      />
      <path
        d="M5.237 17.483c.043.051.043.129-.009.172l-.61.515a.123.123 0 01-.172-.017c-.043-.051-.043-.129.009-.172l.61-.515a.122.122 0 01.172.017z"
        fill="#F3F3F3"
      />
      <path
        d="M5.245 17.474c.052.06.043.146-.008.19l-.61.515c-.052.052-.138.043-.19-.017-.051-.06-.043-.146.009-.19l.61-.515c.052-.052.138-.043.19.017zm-.79.68c.043.05.111.05.163.016l.61-.515a.12.12 0 00.009-.164c-.043-.051-.112-.051-.164-.017l-.61.516c-.051.043-.051.112-.008.163z"
        fill="#fff"
      />
      <path
        d="M5.58 16.245a.116.116 0 01-.034.172l-1.495.997c-.06.034-.138.017-.172-.034a.116.116 0 01.034-.172l1.496-.997c.051-.034.129-.017.172.034z"
        fill="#F3F3F3"
      />
      <path
        d="M5.59 16.245a.137.137 0 01-.035.19l-1.496.996a.132.132 0 01-.189-.043.137.137 0 01.035-.189l1.495-.997a.132.132 0 01.189.043zm-1.702 1.126a.112.112 0 00.154.035l1.495-.997c.052-.035.07-.104.026-.164a.112.112 0 00-.154-.034l-1.496.997a.122.122 0 00-.025.163z"
        fill="#fff"
      />
      <path
        d="M4.3 16.065a.125.125 0 01-.043.172l-.696.395c-.06.034-.138.009-.172-.052a.125.125 0 01.043-.171l.696-.396c.06-.034.138-.008.172.052z"
        fill="#F3F3F3"
      />
      <path
        d="M4.309 16.056c.034.07.017.146-.043.18l-.696.396c-.06.034-.147.009-.18-.051-.035-.07-.018-.146.042-.18l.696-.396c.06-.035.146-.009.18.051zm-.911.516c.034.06.103.077.154.043l.696-.395a.124.124 0 00.043-.155c-.034-.06-.103-.077-.154-.043l-.696.395c-.052.026-.069.095-.043.155z"
        fill="#fff"
      />
      <path
        d="M20.319 14.552a.126.126 0 01.058-.079.13.13 0 01.096-.015l.774.215c.069.017.103.086.086.154a.128.128 0 01-.155.095l-.773-.215c-.07-.017-.103-.086-.086-.155z"
        fill="#F3F3F3"
      />
      <path
        d="M20.31 14.552c.018-.069.095-.12.164-.094l.773.215a.137.137 0 01.095.163c-.018.069-.095.12-.164.094l-.773-.214c-.069-.018-.112-.086-.095-.164zm1.006.284c.017-.06-.017-.129-.078-.146l-.773-.215c-.06-.017-.12.017-.137.086-.018.06.017.129.077.146l.773.215c.06.008.12-.026.138-.086z"
        fill="#fff"
      />
      <path
        d="M19.107 14.965a.123.123 0 01.163-.069l1.659.688c.06.025.094.103.069.163-.026.069-.104.094-.164.069l-1.658-.688a.123.123 0 01-.069-.163z"
        fill="#F3F3F3"
      />
      <path
        d="M19.098 14.956a.134.134 0 01.172-.077l1.659.688c.069.025.094.103.069.18a.133.133 0 01-.172.077l-1.659-.687a.143.143 0 01-.069-.18zm1.882.782a.117.117 0 00-.06-.154l-1.658-.688c-.06-.026-.12.009-.146.069-.026.06 0 .129.06.155l1.658.687c.06.017.13-.008.146-.069z"
        fill="#fff"
      />
      <path
        d="M4.876 14.922c.025.069 0 .137-.07.163l-1.658.688c-.06.025-.137-.009-.163-.07-.026-.068 0-.137.069-.162l1.658-.688c.06-.026.138 0 .164.069z"
        fill="#F3F3F3"
      />
      <path
        d="M4.884 14.913a.143.143 0 01-.068.18l-1.66.688a.134.134 0 01-.17-.077.142.142 0 01.068-.18l1.658-.688a.133.133 0 01.172.077zm-1.882.782c.026.06.095.086.146.069l1.659-.687c.06-.026.086-.095.06-.155a.122.122 0 00-.146-.069l-1.659.688c-.06.025-.085.094-.06.154z"
        fill="#fff"
      />
      <path
        d="M3.664 14.492c.026.069-.009.138-.077.163l-.757.25c-.06.025-.137-.018-.154-.078-.026-.069.008-.137.077-.163l.756-.25c.06-.025.13.01.155.078z"
        fill="#F3F3F3"
      />
      <path
        d="M3.672 14.484a.131.131 0 01-.085.172l-.757.249c-.068.026-.146-.017-.172-.086a.131.131 0 01.086-.172l.757-.25a.146.146 0 01.172.087zm-.996.326c.017.06.086.095.146.078l.756-.25c.06-.017.086-.085.069-.146-.017-.06-.086-.094-.146-.077l-.757.25a.118.118 0 00-.068.145z"
        fill="#fff"
      />
      <path
        d="M19.545 13.521a.132.132 0 01.146-.103l1.762.352c.069.018.112.078.095.146a.132.132 0 01-.146.104l-1.762-.353c-.06-.008-.103-.077-.095-.146z"
        fill="#F3F3F3"
      />
      <path
        d="M19.537 13.521a.14.14 0 01.154-.112l1.762.353c.069.017.12.086.103.163a.14.14 0 01-.154.112l-1.762-.353c-.07-.017-.112-.085-.103-.163zm2.002.404a.123.123 0 00-.086-.138l-1.762-.352a.122.122 0 00-.137.095c-.009.06.026.12.086.137l1.762.353c.068.008.12-.035.137-.095z"
        fill="#fff"
      />
      <path
        d="M4.438 13.47c.017.068-.026.137-.095.146l-1.762.343c-.068.018-.129-.034-.146-.103-.017-.069.026-.137.095-.146l1.762-.344c.068-.008.137.035.146.104z"
        fill="#F3F3F3"
      />
      <path
        d="M4.455 13.47a.138.138 0 01-.103.163l-1.762.344c-.069.017-.138-.035-.155-.112a.138.138 0 01.103-.163l1.762-.344c.069-.017.138.034.155.112zm-2.003.386c.009.06.07.104.13.095l1.761-.344c.06-.008.103-.077.086-.137a.115.115 0 00-.129-.095l-1.762.344c-.06.017-.103.077-.086.137z"
        fill="#fff"
      />
      <path
        d="M20.654 12.894c.008-.069.06-.12.129-.12l.799.06c.069.008.12.068.112.137-.009.069-.06.12-.13.12l-.798-.06c-.06-.008-.112-.069-.112-.137z"
        fill="#F3F3F3"
      />
      <path
        d="M20.645 12.894a.14.14 0 01.138-.13l.8.061c.068.009.128.069.12.146a.14.14 0 01-.138.129l-.8-.06c-.068-.009-.12-.077-.12-.146zm1.049.077a.109.109 0 00-.103-.12l-.8-.06c-.06 0-.111.043-.12.111-.008.06.043.12.103.12l.8.06c.06 0 .111-.05.12-.11z"
        fill="#fff"
      />
      <path
        d="M3.338 12.808c.008.069-.035.129-.104.137l-.79.095a.125.125 0 01-.138-.112c-.008-.069.035-.129.103-.137l.791-.095c.06-.008.129.043.138.112z"
        fill="#F3F3F3"
      />
      <path
        d="M3.346 12.808c.009.077-.043.146-.112.154l-.79.095a.134.134 0 01-.146-.12c-.009-.078.043-.146.111-.155l.791-.095a.134.134 0 01.146.12zm-1.04.12c.009.06.07.112.13.103l.79-.094a.115.115 0 00.094-.13c-.008-.06-.068-.11-.128-.102l-.791.094a.115.115 0 00-.095.13z"
        fill="#fff"
      />
      <path
        d="M19.7 12.026c0-.069.052-.13.12-.13h1.797c.068 0 .12.061.12.13s-.052.129-.12.129H19.82c-.068 0-.12-.06-.12-.13z"
        fill="#F3F3F3"
      />
      <path
        d="M19.692 12.026c0-.078.06-.138.128-.138h1.796c.07 0 .13.06.13.138 0 .077-.06.137-.13.137H19.82c-.068 0-.128-.06-.128-.137zm2.036.008c0-.06-.051-.12-.112-.12H19.82c-.06 0-.111.052-.111.12 0 .06.051.12.111.12h1.797c.06-.008.111-.06.111-.12z"
        fill="#fff"
      />
      <path
        d="M4.3 11.974c0 .07-.052.13-.12.13H2.384c-.07 0-.12-.06-.12-.13 0-.068.05-.129.12-.129H4.18c.068 0 .12.06.12.13z"
        fill="#F3F3F3"
      />
      <path
        d="M4.309 11.974c0 .078-.06.138-.13.138H2.385c-.07 0-.13-.06-.13-.138 0-.077.06-.137.13-.137H4.18c.069 0 .129.06.129.137zm-2.037-.008c0 .06.051.12.112.12H4.18c.06 0 .111-.052.111-.12 0-.06-.051-.12-.111-.12H2.384c-.06.008-.112.06-.112.12z"
        fill="#fff"
      />
      <path
        d="M20.663 11.192c-.009-.069.034-.129.103-.137l.79-.095a.125.125 0 01.138.112c.008.069-.035.129-.103.138l-.791.094c-.06.009-.129-.043-.137-.112z"
        fill="#F3F3F3"
      />
      <path
        d="M20.654 11.192c-.009-.077.043-.146.112-.155l.79-.094a.134.134 0 01.146.12c.009.078-.043.146-.111.155l-.791.094a.134.134 0 01-.146-.12zm1.04-.12c-.009-.06-.069-.112-.13-.103l-.79.094a.115.115 0 00-.094.13c.008.06.069.11.129.102l.79-.094a.115.115 0 00.095-.13z"
        fill="#fff"
      />
      <path
        d="M3.346 11.106c-.008.069-.06.12-.129.12l-.799-.06a.125.125 0 01-.112-.137c.009-.069.06-.12.13-.12l.799.06c.06.008.111.069.111.137z"
        fill="#E2E2E2"
      />
      <path
        d="M3.355 11.106a.14.14 0 01-.138.13l-.8-.061c-.068-.009-.128-.069-.12-.146a.14.14 0 01.138-.129l.8.06c.068.009.12.077.12.146zm-1.049-.077c-.008.06.043.12.103.12l.8.06c.06 0 .111-.043.12-.111a.109.109 0 00-.103-.12l-.8-.06c-.06 0-.111.05-.12.111z"
        fill="#fff"
      />
      <path
        d="M19.563 10.53c-.017-.068.025-.137.094-.146l1.762-.343c.069-.018.129.034.146.103.017.069-.026.137-.095.146l-1.761.344c-.069.008-.138-.035-.146-.104z"
        fill="#F3F3F3"
      />
      <path
        d="M19.545 10.53a.138.138 0 01.103-.163l1.762-.344c.069-.017.138.035.155.112a.138.138 0 01-.103.163l-1.762.344c-.069.017-.138-.034-.155-.112zm2.003-.386a.115.115 0 00-.13-.095l-1.761.344c-.06.008-.103.077-.086.137.009.06.069.104.129.095l1.762-.344c.06-.017.103-.077.086-.137z"
        fill="#fff"
      />
      <path
        d="M4.455 10.479a.132.132 0 01-.146.103l-1.762-.36c-.069-.018-.112-.078-.095-.147a.132.132 0 01.146-.103l1.762.352c.06.017.103.086.095.155z"
        fill="#F3F3F3"
      />
      <path
        d="M4.463 10.479a.14.14 0 01-.154.112l-1.762-.353a.138.138 0 01-.103-.163.14.14 0 01.154-.112l1.762.353c.069.017.112.086.103.163zm-2.002-.404c-.009.06.026.12.086.137l1.762.353c.06.008.12-.026.137-.095a.123.123 0 00-.086-.137L2.6 9.98c-.07-.008-.12.035-.138.095z"
        fill="#fff"
      />
      <path
        d="M19.124 9.078c-.025-.069 0-.137.069-.163l1.659-.688c.06-.025.137.009.163.07.026.068 0 .137-.069.162l-1.658.688a.123.123 0 01-.164-.069z"
        fill="#F3F3F3"
      />
      <path
        d="M19.116 9.087a.143.143 0 01.068-.18l1.659-.688a.133.133 0 01.172.077.142.142 0 01-.069.18l-1.659.688a.133.133 0 01-.171-.077zm1.882-.782a.122.122 0 00-.146-.069l-1.659.687a.117.117 0 00-.06.155c.026.06.094.086.146.069l1.658-.688c.06-.025.086-.094.06-.154z"
        fill="#fff"
      />
      <path
        d="M20.336 9.508c-.026-.069.009-.138.077-.163l.757-.25c.06-.025.137.017.154.078.026.068-.008.137-.077.163l-.756.25c-.06.025-.13-.01-.155-.078z"
        fill="#F3F3F3"
      />
      <path
        d="M20.327 9.516a.131.131 0 01.086-.171l.756-.25c.07-.025.147.018.172.086a.131.131 0 01-.086.172l-.756.25a.146.146 0 01-.172-.087zm.997-.326c-.017-.06-.086-.095-.146-.077l-.756.249c-.06.017-.086.086-.069.146.017.06.086.094.146.077l.756-.249a.119.119 0 00.07-.146z"
        fill="#fff"
      />
      <path
        d="M3.681 9.448a.128.128 0 01-.154.094l-.774-.215c-.069-.017-.103-.086-.086-.154a.128.128 0 01.155-.095l.773.215c.07.017.104.086.086.155z"
        fill="#F3F3F3"
      />
      <path
        d="M3.69 9.448c-.017.068-.095.12-.163.094l-.774-.215a.137.137 0 01-.094-.163c.017-.069.094-.12.163-.094l.773.214c.07.018.112.086.095.164zm-1.006-.284c-.017.06.018.129.078.146l.773.215c.06.017.12-.017.138-.086.017-.06-.018-.129-.078-.146l-.773-.215a.123.123 0 00-.138.086z"
        fill="#fff"
      />
      <path
        d="M4.893 9.035a.123.123 0 01-.163.069L3.07 8.416c-.06-.025-.094-.103-.069-.163a.123.123 0 01.164-.069l1.658.688a.123.123 0 01.069.163z"
        fill="#F3F3F3"
      />
      <path
        d="M4.902 9.044a.134.134 0 01-.172.077L3.07 8.434c-.069-.026-.094-.103-.069-.18a.133.133 0 01.172-.078l1.659.687c.069.035.094.112.069.18zm-1.883-.782c-.025.06 0 .129.06.154l1.66.688c.06.026.12-.009.145-.069a.117.117 0 00-.06-.154l-1.658-.688c-.06-.017-.13.009-.147.069z"
        fill="#fff"
      />
      <path
        d="M17.56 5.297c-.051-.043-.069-.12-.026-.172l.499-.627a.123.123 0 01.172-.018c.051.043.068.12.025.172l-.498.628a.123.123 0 01-.172.017zM16.134 4.343c-.06-.034-.086-.103-.06-.163l.36-.713a.126.126 0 01.172-.052c.06.034.086.103.06.163l-.36.713a.126.126 0 01-.172.052z"
        fill="#F3F3F3"
      />
      <path
        d="M16.134 4.352a.131.131 0 01-.06-.181l.36-.713a.131.131 0 01.18-.06.131.131 0 01.061.18l-.36.713a.131.131 0 01-.181.06zm.472-.937c-.06-.026-.129-.009-.154.051l-.361.714c-.026.051 0 .12.051.154.06.026.13.009.155-.051l.36-.714c.027-.051 0-.12-.05-.154z"
        fill="#fff"
      />
      <path
        d="M16.305 5.615c-.06-.043-.077-.12-.034-.172l.997-1.487c.034-.06.112-.069.172-.034.06.043.077.12.034.172l-.997 1.486a.116.116 0 01-.172.035z"
        fill="#F3F3F3"
      />
      <path
        d="M16.305 5.624a.148.148 0 01-.043-.19l.997-1.486a.136.136 0 01.19-.035c.06.043.077.13.042.19l-.997 1.486a.137.137 0 01-.189.035zM17.44 3.93c-.052-.035-.13-.026-.163.025l-.997 1.487a.112.112 0 00.034.155c.052.034.129.026.163-.026l.997-1.478a.122.122 0 00-.034-.163zM17.552 5.306c-.06-.044-.07-.13-.026-.19l.498-.627c.043-.06.13-.069.19-.017.06.043.068.129.025.189l-.498.627c-.043.06-.13.07-.19.018zm.644-.817c-.051-.043-.12-.034-.163.017l-.499.628a.122.122 0 00.026.163c.052.043.12.034.163-.017l.499-.628c.034-.051.026-.129-.026-.163z"
        fill="#fff"
      />
      <path
        d="M17.474 6.586c-.051-.052-.051-.129 0-.172l1.272-1.263a.122.122 0 01.172 0c.043.043.052.129 0 .172l-1.263 1.263a.125.125 0 01-.18 0z"
        fill="#F3F3F3"
      />
      <path
        d="M17.466 6.595a.135.135 0 010-.19l1.271-1.263a.135.135 0 01.19 0 .135.135 0 010 .19l-1.272 1.262a.135.135 0 01-.19 0zm1.452-1.444a.12.12 0 00-.163 0l-1.272 1.263a.12.12 0 000 .163.12.12 0 00.163 0l1.272-1.263c.043-.043.043-.112 0-.163z"
        fill="#fff"
      />
      <path
        d="M18.763 6.517c-.043-.051-.043-.129.009-.172l.61-.515a.123.123 0 01.172.017c.043.051.043.129-.009.172l-.61.515a.123.123 0 01-.172-.017z"
        fill="#F3F3F3"
      />
      <path
        d="M18.755 6.526c-.052-.06-.043-.146.008-.19l.61-.515c.052-.051.138-.043.19.017.051.06.043.146-.01.19l-.61.515c-.05.051-.137.043-.188-.017zm.79-.68c-.043-.05-.111-.05-.163-.016l-.61.515a.12.12 0 00-.009.164c.043.051.112.051.163.017l.61-.516c.052-.043.052-.112.01-.163z"
        fill="#fff"
      />
      <path
        d="M18.42 7.755a.116.116 0 01.034-.172l1.495-.997c.06-.034.138-.017.172.034a.116.116 0 01-.034.172l-1.496.997c-.051.035-.129.017-.172-.034z"
        fill="#F3F3F3"
      />
      <path
        d="M18.411 7.755a.137.137 0 01.034-.19l1.496-.996a.132.132 0 01.189.043.137.137 0 01-.035.189l-1.495.997a.132.132 0 01-.189-.043zm1.701-1.126a.112.112 0 00-.154-.034l-1.495.996c-.052.035-.07.104-.026.164a.112.112 0 00.154.034l1.496-.997a.122.122 0 00.026-.163z"
        fill="#fff"
      />
      <path
        d="M19.7 7.935a.125.125 0 01.043-.172l.696-.395c.06-.034.138-.009.172.052a.125.125 0 01-.043.171l-.696.396c-.06.034-.137.008-.172-.052z"
        fill="#F3F3F3"
      />
      <path
        d="M19.692 7.944c-.035-.069-.018-.146.043-.18l.696-.396c.06-.034.146-.009.18.052.034.068.017.146-.043.18l-.696.395c-.06.035-.146.009-.18-.051zm.91-.516c-.034-.06-.103-.077-.154-.043l-.696.396a.123.123 0 00-.043.154c.034.06.103.078.154.043l.697-.395c.051-.026.068-.095.042-.155z"
        fill="#fff"
      />
      <path
        d="M4.334 7.875c-.034.06-.103.086-.163.06l-.713-.36c-.06-.026-.086-.104-.052-.173.035-.06.103-.086.164-.06l.713.361c.06.035.086.112.051.172z"
        fill="#F3F3F3"
      />
      <path
        d="M4.343 7.884a.131.131 0 01-.18.06l-.714-.361a.131.131 0 01-.06-.18.131.131 0 01.18-.06l.714.36c.069.026.094.112.06.18zm-.928-.473c-.026.06-.009.129.052.155l.713.36c.051.026.12 0 .154-.051.026-.06.01-.129-.051-.155l-.722-.36a.106.106 0 00-.146.051z"
        fill="#fff"
      />
      <path
        d="M5.598 7.712c-.043.06-.112.077-.172.034l-1.487-.988c-.06-.035-.069-.112-.034-.172a.116.116 0 01.172-.034l1.495.996c.051.035.069.112.026.164z"
        fill="#F3F3F3"
      />
      <path
        d="M5.606 7.72a.148.148 0 01-.189.043L3.93 6.766a.136.136 0 01-.034-.188.148.148 0 01.19-.043l1.486.996c.069.043.077.13.034.19zM3.913 6.586c-.034.052-.025.129.026.163l1.495.997c.052.035.12.017.155-.034.034-.052.026-.129-.026-.163l-1.495-.997a.112.112 0 00-.155.034z"
        fill="#fff"
      />
      <path
        d="M5.28 6.466c-.043.051-.12.068-.172.025l-.627-.49a.123.123 0 01-.018-.171c.043-.052.12-.07.172-.026l.628.49c.051.043.06.12.017.172z"
        fill="#F3F3F3"
      />
      <path
        d="M5.288 6.474c-.043.06-.129.069-.189.026l-.627-.49c-.06-.043-.069-.129-.026-.189.043-.06.13-.069.19-.026l.626.49c.06.043.07.13.026.19zm-.825-.644c-.043.051-.034.12.018.163l.627.49c.051.034.12.026.163-.026.043-.052.034-.12-.017-.163l-.627-.49a.122.122 0 00-.164.026z"
        fill="#fff"
      />
      <path
        d="M6.56 6.552c-.051.051-.129.051-.172 0l-1.28-1.264a.122.122 0 010-.172c.051-.051.129-.051.172 0L6.552 6.38a.122.122 0 01.008.172z"
        fill="#F3F3F3"
      />
      <path
        d="M6.56 6.56a.135.135 0 01-.189 0L5.1 5.297a.135.135 0 010-.19.135.135 0 01.19 0L6.56 6.372c.06.051.06.137 0 .189zM5.117 5.125a.12.12 0 000 .163l1.271 1.263a.12.12 0 00.164 0 .12.12 0 000-.163L5.28 5.116c-.043-.043-.112-.043-.163.009z"
        fill="#fff"
      />
      <path
        d="M6.483 5.271c-.052.043-.13.043-.172-.008l-.524-.602a.123.123 0 01.017-.172c.051-.043.129-.043.172.009l.524.601c.043.043.034.12-.017.172z"
        fill="#F3F3F3"
      />
      <path
        d="M6.491 5.271a.135.135 0 01-.189-.008l-.524-.602c-.051-.052-.043-.138.017-.19a.135.135 0 01.19.01l.524.601c.043.06.043.146-.018.19zm-.687-.782c-.052.043-.052.112-.017.163l.524.602a.12.12 0 00.163.009c.052-.043.052-.112.017-.164l-.524-.601c-.043-.052-.112-.052-.163-.009z"
        fill="#fff"
      />
      <path
        d="M7.72 5.598a.116.116 0 01-.172-.035L6.543 4.077c-.034-.06-.017-.138.034-.172a.116.116 0 01.172.034l.997 1.487a.121.121 0 01-.026.172z"
        fill="#F3F3F3"
      />
      <path
        d="M7.72 5.606a.137.137 0 01-.189-.034l-.997-1.487a.132.132 0 01.043-.189.137.137 0 01.19.035l.996 1.486a.13.13 0 01-.043.19zM6.586 3.913a.112.112 0 00-.034.155l.996 1.487c.035.051.104.06.164.025a.112.112 0 00.034-.154l-.997-1.478a.122.122 0 00-.163-.035z"
        fill="#fff"
      />
      <path
        d="M7.9 4.326a.125.125 0 01-.171-.043l-.395-.696c-.035-.06-.009-.138.051-.172a.125.125 0 01.172.043l.395.696c.026.06.009.137-.051.172z"
        fill="#F3F3F3"
      />
      <path
        d="M7.9 4.334c-.068.035-.145.018-.18-.043l-.395-.696c-.034-.06-.017-.146.052-.18.068-.034.146-.017.18.043l.395.696c.035.06.017.137-.051.18zm-.515-.91c-.051.034-.077.103-.043.154l.396.696a.123.123 0 00.154.043c.052-.034.077-.103.043-.155l-.395-.696c-.026-.051-.103-.077-.155-.043z"
        fill="#fff"
      />
      <path
        d="M9.044 4.893c-.07.026-.138 0-.164-.06l-.696-1.659c-.025-.06.009-.137.069-.163.069-.026.138 0 .163.06l.696 1.659c.026.06-.008.137-.068.163zM14.552 3.681a.129.129 0 01-.094-.154l.215-.774c.017-.069.086-.103.154-.086a.128.128 0 01.095.155l-.215.773c-.017.07-.086.103-.155.086z"
        fill="#F3F3F3"
      />
      <path
        d="M14.552 3.69c-.068-.017-.12-.095-.094-.163l.215-.774c.017-.069.094-.112.163-.094.069.017.12.094.095.163l-.215.773c-.018.069-.086.112-.163.095zm.284-1.006c-.06-.017-.129.018-.146.078l-.215.773c-.017.06.017.12.086.138.06.017.129-.018.146-.078l.215-.773a.123.123 0 00-.086-.138z"
        fill="#fff"
      />
      <path
        d="M14.973 4.893a.123.123 0 01-.068-.163l.687-1.659c.026-.06.103-.095.163-.06a.123.123 0 01.07.163l-.688 1.659c-.026.06-.103.094-.163.06z"
        fill="#F3F3F3"
      />
      <path
        d="M9.044 4.902a.142.142 0 01-.18-.07l-.697-1.658a.134.134 0 01.078-.172.143.143 0 01.18.07l.696 1.658c.026.06-.008.137-.077.172zM8.262 3.02c-.06.025-.086.094-.06.146l.696 1.658c.025.06.094.086.154.06.06-.025.086-.094.06-.146L8.417 3.08c-.034-.06-.094-.086-.154-.06zM14.973 4.902a.134.134 0 01-.077-.172l.688-1.659c.025-.069.103-.094.18-.069a.134.134 0 01.078.172l-.688 1.659c-.034.069-.112.103-.18.069zm.782-1.882a.117.117 0 00-.154.06l-.688 1.658c-.026.06.009.12.069.146.06.026.129 0 .155-.06l.687-1.658a.122.122 0 00-.069-.146z"
        fill="#fff"
      />
      <path
        d="M9.465 3.673c-.07.026-.138-.009-.164-.078l-.249-.756c-.025-.06.017-.137.078-.155.06-.017.137.01.163.078l.249.756c.026.06-.009.129-.077.155z"
        fill="#F3F3F3"
      />
      <path
        d="M9.473 3.681a.133.133 0 01-.171-.077l-.25-.756c-.025-.069.018-.146.086-.172a.134.134 0 01.172.077l.25.757a.146.146 0 01-.087.171zm-.335-.988c-.06.017-.094.086-.077.146l.25.756c.016.06.085.086.145.07.06-.018.095-.087.078-.147l-.25-.756a.109.109 0 00-.146-.069z"
        fill="#fff"
      />
      <path
        d="M13.521 4.455a.132.132 0 01-.103-.146l.352-1.762c.018-.069.078-.112.146-.095a.132.132 0 01.103.147l-.352 1.761c-.008.06-.077.103-.146.095zM10.479 4.455c-.069.017-.138-.026-.146-.095L9.972 2.6c-.017-.07.034-.13.103-.147a.116.116 0 01.146.095l.361 1.762a.138.138 0 01-.103.146z"
        fill="#F3F3F3"
      />
      <path
        d="M13.521 4.463a.14.14 0 01-.112-.154l.353-1.762a.138.138 0 01.163-.103.14.14 0 01.112.154l-.353 1.762c-.017.069-.086.112-.163.103zm.404-2.002a.123.123 0 00-.138.086l-.352 1.762c-.008.06.026.12.095.137.06.009.12-.026.137-.086l.353-1.762c.008-.068-.035-.12-.095-.137zM10.479 4.463a.138.138 0 01-.163-.103l-.361-1.762c-.018-.068.034-.146.103-.154a.138.138 0 01.163.103l.361 1.762c.017.068-.026.137-.103.154zm-.404-2.002c-.06.009-.103.069-.095.137l.361 1.762c.009.06.078.103.138.086.06-.008.103-.069.094-.137l-.36-1.762a.123.123 0 00-.138-.086z"
        fill="#fff"
      />
      <path
        d="M11.132 3.338a.132.132 0 01-.146-.104l-.103-.79a.125.125 0 01.112-.138.132.132 0 01.146.103l.103.791a.125.125 0 01-.112.138zM12.877 3.338c-.07-.009-.12-.06-.12-.13l.06-.799a.125.125 0 01.137-.111c.069.008.12.06.12.129l-.06.799a.125.125 0 01-.137.112z"
        fill="#F3F3F3"
      />
      <path
        d="M11.132 3.346c-.077.009-.146-.043-.155-.112l-.103-.79a.134.134 0 01.12-.146c.078-.009.147.043.155.111l.103.791a.134.134 0 01-.12.146zm-.129-1.031c-.06.008-.112.069-.103.129l.103.79c.009.06.069.104.13.095.06-.009.11-.069.102-.129l-.103-.79a.115.115 0 00-.129-.095zM12.877 3.346a.14.14 0 01-.13-.137l.06-.8c.01-.068.07-.128.147-.12a.14.14 0 01.129.138l-.06.799c-.009.077-.07.129-.146.12zm.077-1.04c-.06 0-.12.043-.12.103l-.06.8c0 .06.042.111.111.12.06 0 .12-.043.12-.103l.06-.8c0-.06-.051-.111-.111-.12z"
        fill="#fff"
      />
      <path
        d="M12.017 4.3c-.069 0-.129-.052-.129-.12V2.384c0-.07.06-.12.13-.12.068 0 .128.05.128.12V4.18c-.009.068-.06.12-.129.12z"
        fill="#F3F3F3"
      />
      <path
        d="M12.017 4.309c-.077 0-.137-.06-.137-.13V2.385c0-.07.06-.13.137-.13.078 0 .138.06.138.13V4.18c0 .068-.069.129-.138.129zm0-2.037c-.06 0-.12.051-.12.112V4.18c0 .06.051.111.12.111.06 0 .12-.051.12-.111V2.384c-.008-.06-.06-.112-.12-.112z"
        fill="#fff"
      />
      <path
        d="M12.06 22.166c5.62 0 10.175-4.555 10.175-10.175 0-5.62-4.555-10.175-10.175-10.175-5.62 0-10.175 4.556-10.175 10.175 0 5.62 4.556 10.175 10.175 10.175z"
        fill="url(#safari_svg__paint2_linear)"
        fillOpacity={0.2}
      />
      <path
        d="M19.107 5.537l-8.207 5.26h-.009v.008l-.008.009-5.122 8.448 7.528-6.059.009-.008v-.009l5.81-7.648z"
        fill="#000"
        fillOpacity={0.05}
      />
      <path
        d="M18.952 5.048l-8.112 5.766 2.406 2.389 5.706-8.155"
        fill="#CD151E"
      />
      <path
        d="M10.848 10.797l1.212 1.194 6.892-6.943-8.104 5.749z"
        fill="#FA5153"
      />
      <path
        d="M10.848 10.797l2.407 2.389-8.113 5.766 5.706-8.155z"
        fill="#ACACAC"
      />
      <path d="M5.142 18.952l6.918-6.96-1.212-1.195-5.706 8.155z" fill="#EEE" />
      <defs>
        <linearGradient
          id="safari_svg__paint0_linear"
          x1={12}
          y1={23}
          x2={12}
          y2={1}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.25} stopColor="#DBDBDA" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="safari_svg__paint2_linear"
          x1={11.867}
          y1={9.082}
          x2={7.118}
          y2={17.602}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0} />
          <stop offset={1} />
        </linearGradient>
        <radialGradient
          id="safari_svg__paint1_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.43 9.765) scale(12.42)"
        >
          <stop stopColor="#2ABCE1" />
          <stop offset={0.114} stopColor="#2ABBE1" />
          <stop offset={1} stopColor="#3375F8" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default SvgSafari;
