import { namespace } from '.';
import { Tags } from './service';

export async function measureLatency<T>(
  metricNamespace: string,
  metricName: string,
  func: () => Promise<T>,
  tags?: Tags,
): Promise<T> {
  const startMs = performance.now();

  try {
    return await func();
  } finally {
    const durationMs = performance.now() - startMs;
    const { stats } = namespace(metricNamespace);
    stats(metricName, durationMs, tags);
  }
}

export function measureLatencySync<T>(
  metricNamespace: string,
  metricName: string,
  func: () => T,
  tags?: Tags,
): T {
  const startMs = performance.now();

  try {
    return func();
  } finally {
    const durationMs = performance.now() - startMs;
    const { stats } = namespace(metricNamespace);
    stats(metricName, durationMs, tags);
  }
}
