import { IconButton } from '@dropbox/dig-components/buttons';
import { Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { ExpandLeftLine, RotateLeftLine } from '@dropbox/dig-icons/assets';
import { CloseDashChatType } from '@mirage/analytics/events/enums/close_dash_chat_type';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { BetaBadge } from '@mirage/shared/badges/BetaBadge';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import styles from './RailHeader.module.css';

export type RailHeaderProps = {
  onClose: (closeChatType: CloseDashChatType) => void;
  onReset?: () => void;
};

export const RailHeader = ({ onClose, onReset }: RailHeaderProps) => {
  return (
    <div className={styles.container}>
      {onReset && (
        <DigTooltip title={i18n.t('clear_chat')} placement="bottom-end">
          <IconButton
            size="medium"
            variant="transparent"
            shape="circular"
            aria-label={i18n.t('clear_chat')}
            onClick={onReset}
            data-uxa-log={createUxaElementId('clear_chat_button', {
              actionSurfaceComponent: 'ask_dialog',
              featureLine: 'answers',
            })}
          >
            <UIIcon src={RotateLeftLine} />
          </IconButton>
        </DigTooltip>
      )}
      <div className={styles.titleContainer}>
        <Title className={styles.title} size="small">
          {i18n.t('ask_dash')}
        </Title>
        <BetaBadge />
      </div>
      <div className={styles.closeContainer}>
        <IconButton
          size="medium"
          variant="transparent"
          shape="circular"
          aria-label={i18n.t('close')}
          onClick={() => onClose('close_chat')}
          data-uxa-log={createUxaElementId('close_chat_button', {
            actionSurfaceComponent: 'ask_dialog',
            featureLine: 'answers',
          })}
        >
          <UIIcon src={ExpandLeftLine} />
        </IconButton>
      </div>
    </div>
  );
};
