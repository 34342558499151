// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1263
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';
import type { TabGroupType } from '../enums/tab_group_type';

// Event_name: 'create.dash_new_stack'
// Description: Fires when a dash stack is created
// Owner: content-ingest
export type Create_DashNewStack = PAPEventGeneric<
  'dash',
  'create',
  'dash_new_stack',
  {
    // The stack id that represents the stack
    stackId?: string;
    // The length of the stack name
    stackNameLen?: number;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // User_id associated with the creator of the content being acted upon
    creatorId?: string;
    // True if the user has no stacks, false if the user has stacks
    stacksEmptyState?: boolean;
    // Random string that ties together sessions of the stack generator
    stackGeneratorSessionId?: string;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // stormcrow feature name
    feature?: string;
    // Generated random request id for tracking suggestions in Dash
    dashRequestId?: string;
    // A hashed integer for unique predictions recommended in Dash
    predictionIdHash?: number;
    // Type of Tab groups provided by dash
    tabGroupType?: TabGroupType;
    // An entry point string, used to describe how users are triggering the event as relevant (e.g. browse_file_row_context_menu)
    entryPoint?: string;
    // unique session id for creating stacks on dash
    createStackSessionId?: string;
    // number of suggestions for a calendar event
    numSuggestions?: number;
    // An array of hashed prediction ids. Derived from a set of prediction_id_hash.
    predictionIdHashes?: string;
    // an array of item_id_hashes
    itemIdHashes?: string;
    // The number of links in the stack
    numberOfLinks?: number;
  }
>;

export function PAP_Create_DashNewStack(
  properties?: Create_DashNewStack['properties'],
): Create_DashNewStack {
  return <Create_DashNewStack>{
    class: 'dash',
    action: 'create',
    object: 'dash_new_stack',
    properties,
  };
}
