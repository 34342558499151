// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1489
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { StackAccessLevel } from '../enums/stack_access_level';
import type { StackPermissions } from '../enums/stack_permissions';
import type { StackSendShareType } from '../enums/stack_send_share_type';

// Event_name: 'send.dash_share_modal'
// Description: Fired when the user shares a stack with one or more members via IB (email)
// Owner: stacks
export type Send_DashShareModal = PAPEventGeneric<
  'stacks',
  'send',
  'dash_share_modal',
  {
    // The permissions of a user on a stack
    stackPermissions?: StackPermissions;
    // A property that indicates whether or not email notifications are on at the time of a stack share
    stackEmailNotificationsOn?: boolean;
    // The access level settings for a stack
    stackAccessLevel?: StackAccessLevel;
    // The stack id that represents the stack
    stackId?: string;
    // User_id associated with the creator of the content being acted upon
    creatorId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // True if public sharing is allowed, false otherwise
    isPublicSharingAllowed?: boolean;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the user is interacting with their welcome stack
    isWelcomeStack?: boolean;
    // Property to indicate whether it's from suggested collaborator or entered by user for sharing contact
    stackSendShareType?: StackSendShareType;
    // Whether an object is being shared with a group or not
    sharedWithGroup?: boolean;
    // whether an object is shared with a non-team member
    sharedWithExternal?: boolean;
  }
>;

export function PAP_Send_DashShareModal(
  properties?: Send_DashShareModal['properties'],
): Send_DashShareModal {
  return <Send_DashShareModal>{
    class: 'stacks',
    action: 'send',
    object: 'dash_share_modal',
    properties,
  };
}
