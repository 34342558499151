import { context_engine } from '@dropbox/api-v2-client/types/dropbox_types';
import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { callApiV2 } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { ComposeSession } from '@mirage/shared/compose/compose-session';
import { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import { KVStorage } from '@mirage/storage';

import type { LogoutServiceConsumerContract } from '@mirage/service-logout';

export type Service = ReturnType<typeof composeService>;

export interface ComposeSessionsStorage {
  sessions: {
    rows: ComposeSession[];
    version: 1;
  };
}

export interface ComposeVoicesStorage {
  voices: ComposeVoice[];
}

export enum StorageKey {
  Voices = 'voices',
  Sessions = 'sessions',
}

export default function composeService(
  sessionsStore: KVStorage<ComposeSessionsStorage>,
  voicesStore: KVStorage<ComposeVoicesStorage>,
  logoutService: LogoutServiceConsumerContract,
) {
  const logger = tagged('compose-service');

  async function loadComposeSessions(): Promise<ComposeSession[]> {
    const sessions = await sessionsStore.get(StorageKey.Sessions);
    if (sessions?.version === 1) {
      logger.log('loaded ComposeSessions', sessions.rows.length);
      return sessions.rows;
    } else {
      // Ignore old data if the version is not >=1
      return [];
    }
  }

  async function loadComposeApiSessions(): Promise<ComposeSession[]> {
    const args: context_engine.AssistListUserDataArg = {
      user_data_type: StorageKey.Sessions,
    };
    const response = await callApiV2('contextEngineAssistListUserData', args);
    const loadedSessions: ComposeSession[] = [];
    if (!response.items) {
      return loadedSessions;
    }
    Object.entries(response.items).forEach(([key, item]) => {
      if (!item.user_data) {
        return;
      }
      const sessionData = JSON.parse(item.user_data);
      const session: ComposeSession = {
        ...sessionData,
        dataId: key,
      };
      if (verifySessionData(session)) {
        loadedSessions.push(session);
      } else {
        logger.error('Invalid session data:', sessionData);
      }
    });
    logger.log('Loaded remote assistant sessions', loadedSessions.length);
    return loadedSessions;
  }

  function verifySessionData(sessionData: ComposeSession): boolean {
    const isValid =
      typeof sessionData.id === 'string' &&
      typeof sessionData.dataId === 'string' &&
      Array.isArray(sessionData.messagesHistory) &&
      Array.isArray(sessionData.sources) &&
      Array.isArray(sessionData.artifacts) &&
      (sessionData.lastUpdated === undefined ||
        typeof sessionData.lastUpdated === 'number');
    if (!isValid) {
      logger.error('Invalid session data:', sessionData);
    }
    return isValid;
  }

  async function saveComposeSessions(sessions: ComposeSession[]) {
    await sessionsStore.set(StorageKey.Sessions, {
      rows: sessions,
      version: 1,
    });
    logger.log('saved ComposeSessions', sessions.length);
  }

  async function saveComposeApiSession(session: ComposeSession) {
    const args: context_engine.AssistSaveUserDataArg = {
      data_item: {
        user_data_type: StorageKey.Sessions,
        user_data_key: session.id,
        user_data: JSON.stringify(session),
      },
      data_id: session.dataId,
    };
    const response = await callApiV2('contextEngineAssistSaveUserData', args);
    if (response.data_id) {
      session.dataId = response.data_id;
    }
    logger.log('saved ComposeSession', session.id);
    return session;
  }

  async function syncComposeSessions(
    localSessions: ComposeSession[],
    apiSessions: ComposeSession[],
  ): Promise<ComposeSession[]> {
    const localSessionIds = new Set(localSessions.map((s) => s.id));
    const sessionsToAdd = apiSessions.filter(
      (apiSession) => !localSessionIds.has(apiSession.id),
    );
    if (sessionsToAdd.length > 0) {
      const updatedSessions = [...localSessions, ...sessionsToAdd];
      await saveComposeSessions(updatedSessions);
      logger.log('Added new sessions from API:', sessionsToAdd);
      return updatedSessions;
    } else {
      return localSessions;
    }
  }

  async function deleteComposeApiSession(session: ComposeSession) {
    if (!session.dataId) {
      return;
    }
    const args: context_engine.AssistDeleteUserDataArg = {
      data_id: session.dataId,
    };
    await callApiV2('contextEngineAssistDeleteUserData', args);
    logger.log('deleted ComposeSession', session.id);
    return;
  }

  async function loadComposeVoices(): Promise<ComposeVoice[]> {
    const voices = (await voicesStore.get(StorageKey.Voices)) || [];
    logger.log('loaded ComposeVoices', voices?.length);
    return voices || [];
  }

  async function saveComposeVoices(voices: ComposeVoice[]) {
    await voicesStore.set(StorageKey.Voices, voices);
    logger.log('saved ComposeVoices', voices.length);
  }

  async function tearDown() {
    await sessionsStore.clear();
    await voicesStore.clear();
  }

  logoutService.registerLogoutCallback(ServiceId.COMPOSE, async () => {
    logger.debug('Handling logout in compose service');
    await tearDown();
    logger.debug('Done handling logout in compose service');
  });

  return services.provide(
    ServiceId.COMPOSE,
    {
      loadComposeSessions,
      loadComposeApiSessions,
      saveComposeSessions,
      saveComposeApiSession,
      deleteComposeApiSession,
      loadComposeVoices,
      saveComposeVoices,
      syncComposeSessions,
      tearDown,
    },
    [ServiceId.DBX_API],
  );
}
