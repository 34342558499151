// XXX: in order for us to ensure service ids are consistent and access
// identifiers across contexts for dependency itemization, all service ids
// should be defined here

export enum ServiceId {
  CALENDAR_EVENTS = 'calendar-events',
  CLOUD_DOCS = 'cloud-docs',
  RECENT_CONTENT = 'recent-content',
  STACK_ADMIN_SETTINGS = 'stack-admin-settings',
  RECOMMENDED_STACKS = 'recommended-stacks',
  DASH_AUTH = 'dash-auth',
  DASH_CONNECTORS = 'dash-connectors',
  DBX_API = 'dbx-api',
  ENVIRONMENT_CONTEXT = 'environment-context',
  LOCAL_FILE_SETTINGS = 'local-file-settings',
  LOGGING = 'logging',
  MEETINGS = 'meetings',
  OPEN = 'open',
  PLATFORM_ACTIONS = 'platform-actions',
  RESULT_ACTIONS = 'result-actions',
  SEARCH = 'search',
  TYPEAHEAD_SEARCH = 'typeahead-search',
  SETTING_ACTIONS = 'setting-actions',
  KEYMAP_ACTIONS = 'keymap-actions',
  SETTINGS = 'settings',
  STACKS = 'stacks',
  STACK_SUGGESTIONS = 'stack-suggestions',
  ONBOARDING = 'onboarding',
  ONBOARDING_CHECKLIST = 'onboarding-checklist',
  BROWSER_HISTORY_V1 = 'browser-history-v1',
  BROWSER_HISTORY_V2 = 'browser-history-v2',
  PRODUCT_LOGGING = 'product-logging',
  EXPERIMENTATION = 'experimentation',
  OPERATIONAL_METRICS = 'operational-metrics',
  ROUTE_NAVIGATION = 'route-navigation',
  URL_METADATA = 'url-metadata',
  FEATURE_RING_SETTINGS = 'feature-ring-settings',
  LOGOUT = 'logout',
  AUDIT_LOGGING = 'audit-logging',
  IPC_PROFILING = 'ipc-profiling',
  PEOPLE = 'people',
  FEED = 'feed',
  FEEDBACK = 'feedback',
  COMPOSE = 'compose',
  LOGIN_SYNC_V1 = 'login-sync-v1',
  LOGIN_SYNC_V2 = 'login-sync-v2',

  SCOUT_BROWSER_ACTIONS = 'scout-browser-actions',

  HORNET_WINDOW = 'hornet-window',
  HORNET_EXIT = 'hornet-exit',
  HORNET_AUTO_UPDATER = 'hornet-auto-updater',
  HORNET_DEVTOOLS = 'hornet-devtools',
  HORNET_BACKGROUND_LOGGING = 'hornet-background-logging',
  HORNET_TESLA_MIGRATION = 'hornet-tesla-migration',
  HORNET_NATIVE = 'hornet-native',
  HORNET_UTILS = 'hornet-utils',
  HORNET_TIMING_BUS = 'hornet-timing-bus',
  HORNET_FOLDER_PICKER = 'hornet-folder-picker',

  LEGACY_TESLA_AUTH = 'legacy-tesla-auth',
}
