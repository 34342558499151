import { dash, dash_feed } from '@dropbox/api-v2-client';
import {
  buildConnectorInfo,
  buildFileTypeInfo,
} from '@mirage/service-dbx-api/service/utils';
import {
  BrandedSiteInfo,
  ConnectorInfo,
  FileTypeInfo,
} from '../search/search-result';

// a type that is compatible with helper utilities used for Icons/Names in Search Result
export type ActivityResult = {
  url: string;
  connectorInfo?: ConnectorInfo | null;
  fileTypeInfo?: FileTypeInfo | null;
  brandedSiteInfo?: BrandedSiteInfo | null;
  recordType?: dash.RecordType | dash_feed.object_details_union;
};

export function toActivityResult(
  activityItem: dash_feed.ActivityItem,
): ActivityResult | undefined {
  const object = activityItem.object;
  if (!object) {
    return;
  }

  return {
    url: object.url || '',
    fileTypeInfo: buildFileTypeInfo(object.file_type_info),
    connectorInfo: buildConnectorInfo(object.connector_info),
    recordType: object.details?.object_details,
  };
}
