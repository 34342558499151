import Error from 'es6-error';

/** used in service-search, thrown when a search times out without returning anything */
export class SearchTimeoutError extends Error {
  static errorName = 'SearchTimeoutError';

  constructor(message?: string) {
    super(message);
    this.name = SearchTimeoutError.errorName;
  }
}
