// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2135
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { MoveDashLinkType } from '../enums/move_dash_link_type';
import type { MoveDashObjectMethod } from '../enums/move_dash_object_method';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'move.dash_link'
// Description: Fires when a user moves a link from one section to another
// Owner: dash-web
export type Move_DashLink = PAPEventGeneric<
  'stacks',
  'move',
  'dash_link',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The stack id that represents the stack
    stackId?: string;
    // marks if the content is shared content
    isShared?: boolean;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // Whether the user is interacting with their welcome stack
    isWelcomeStack?: boolean;
    // How the link was moved during Drag and drop
    moveDashLinkType?: MoveDashLinkType;
    // How was it moved
    moveDashObjectMethod?: MoveDashObjectMethod;
  }
>;

export function PAP_Move_DashLink(
  properties?: Move_DashLink['properties'],
): Move_DashLink {
  return <Move_DashLink>{
    class: 'stacks',
    action: 'move',
    object: 'dash_link',
    properties,
  };
}
