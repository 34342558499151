import { useEffect, useState } from "react";

import * as authService from "@mirage/service-auth";
import { tagged } from "@mirage/service-logging";
import {
  listenForServiceLoginState as listenForServiceLoginStateV1,
  sendLoginStateToService as sendLoginStateToServiceV1,
} from "@mirage/service-login-sync-v1";
import { handleLoginSyncMessage as handleLoginSyncMessageV1 } from "@mirage/service-login-sync-v1/handleLoginSyncMessage";
import { LoginData } from "@mirage/service-login-sync-v1/service/types";
import {
  listenForServiceLoginState as listenForServiceLoginStateV2,
  sendLoginStateToService as sendLoginStateToServiceV2,
} from "@mirage/service-login-sync-v2";
import { handleLoginSyncMessage as handleLoginSyncMessageV2 } from "@mirage/service-login-sync-v2/handleLoginSyncMessage";
import { logout } from "@mirage/service-logout";
import { extensionConnectedStateAtom } from "@mirage/shared/atoms/extensionConnection";
import {
  expectLoginSyncV1,
  expectLoginSyncV2,
} from "@mirage/webapp/helpers/sentry";
import { useAtomValue } from "jotai";

import { directLoginUsingAuthData } from "../helpers/directLogin";
import { useConnectToExtension } from "../helpers/extensionConnector";

const logger = tagged("ExtensionConnector");

export const ExtensionConnector: React.FC = () => {
  const connectToExtension = useConnectToExtension();
  const extensionConnection = useAtomValue(extensionConnectedStateAtom);
  const [subscribed, setSubscribed] = useState(false);

  useEffect(() => {
    const connectIfDisconnected = () => {
      if (!extensionConnection.connected) {
        connectToExtension();
      }
    };

    connectIfDisconnected();
    // Try to connect to the extension every minute.
    const intervalId = setInterval(connectIfDisconnected, 60_000);

    return () => {
      clearInterval(intervalId);
    };
  }, [connectToExtension, extensionConnection.connected]);

  // Sync the login sent from the extension to the webapp.
  useEffect(() => {
    if (!extensionConnection.connected) return;

    if (expectLoginSyncV1()) {
      const subscriptionV1 = listenForServiceLoginStateV1().subscribe(
        (message) => {
          handleLoginSyncMessageV1(
            message,
            authService,
            async (loginData: LoginData) => {
              return directLoginUsingAuthData(loginData, authService);
            },
            // The service is hosted by the extension.
            sendLoginStateToServiceV1,
            logout,
          );
        },
      );

      setSubscribed(true);

      return () => {
        subscriptionV1.unsubscribe();
      };
    }

    if (expectLoginSyncV2()) {
      const subscriptionV2 = listenForServiceLoginStateV2().subscribe(
        (message) => {
          handleLoginSyncMessageV2(
            message,
            authService,
            /* canGenerateAuthCode= */ true,
            async () => {
              throw new Error(`loginUsingAuthCode not supported for webapp`);
            },
            // The service is hosted by the extension.
            sendLoginStateToServiceV2,
            logout,
          );
        },
      );

      setSubscribed(true);

      return () => {
        subscriptionV2.unsubscribe();
      };
    }
  }, [extensionConnection.connected]);

  // Sync the login from the webapp to the extension.
  useEffect(() => {
    // Make sure we are already subscribed above before init.
    if (!subscribed) return;

    async function init() {
      const [account, authData] = await Promise.all([
        authService.getCurrentAccount(),
        authService.getAuthenticationData(),
      ]);

      if (account && authData) {
        logger.info("Sending login data to extension on page load");

        // Auto-login the extension if needed.
        if (expectLoginSyncV1()) {
          sendLoginStateToServiceV1({
            type: "loggedIn",
            email: account.email,
            ...authData,
          });
        } else if (expectLoginSyncV2()) {
          sendLoginStateToServiceV2({
            type: "loggedIn",
            email: account.email,
          });
        }
      } else {
        // Don't auto-logout the extension. This is ok because:
        //
        // Case 1: Extension is logged out
        //         --> All good, no need to do anything
        // Case 2: Extension is logged in with same account as future webapp login
        //         --> All good, no need to do anything
        // Case 3: Extension is logged in with different account as future webapp login
        //         --> Webapp will sync the new login to the extension
        //
        logger.info(
          `Webapp is logged out now, don't send any message to extension:` +
            ` has account=${!!account}, has authData=${!!authData}`,
        );
      }

      // If the extension is logged in, then it will auto-login the webapp.
      // If the extension is logged out, then we don't need to do anything
      // since the code above will auto-login the extension from the webapp.
    }

    init();
  }, [subscribed]);

  return null;
};
