import * as React from "react";
import classNames from "classnames";
import { Box } from "@dropbox/dig-foundations";
import css from "./Metadata.module.css";
import { Dividers, MetadataSizes } from "./types";
import { MetadataContext } from "./context";

export interface MetadataProps extends React.AriaAttributes {
  children: React.ReactNode;
  /**
   * Metadata items may have a leading divider that separates each item from the proceeding item.
   * You may choose between a bullet or blank space depending on the use case
   *
   * @default bullet
   */
  withDividers?: Dividers;
  className?: string;
  /**
   * The size of the metadata items
   * @default small
   */
  size?: MetadataSizes;
}

/**
 *
 * Metadata component provides a pre-composed layout for one or more pieces of metadata.
 * Each metadata item is represented by a string with optional leading accessories.
 *
 */
export const _Metadata: React.FC<MetadataProps> = ({
  children,
  className,
  size: _size,
  withDividers: _withDividers,
  ...props
}) => {
  const ctxProps = React.useContext(MetadataContext);
  // prefer local props over context props
  const size = _size ?? ctxProps.size;
  const withDividers = _withDividers ?? ctxProps.withDividers;
  return (
    <MetadataContext.Provider
      value={{
        withDividers,
        size,
      }}
    >
      <Box
        as="span"
        role="list"
        color="Text Subtle"
        fontWeight="Base"
        fontFamily="Text"
        fontSize={size === "small" ? "Text Small" : "Text Medium"}
        lineHeight={size === "small" ? "Text Small" : "Text Medium"}
        className={classNames(css.root, className, {
          [css.hasBullet]: withDividers === "bullet",
          [css.sizeSmall]: size === "small",
          [css.sizeMedium]: size === "medium",
        })}
        {...props}
      >
        {children}
      </Box>
    </MetadataContext.Provider>
  );
};
