import * as rx from 'rxjs';
import * as op from 'rxjs/operators';

import type { typeahead } from '@mirage/service-typeahead-search/service/types';

export default function delayed(
  wrapping: typeahead.TaggedSearch,
  delay: number,
): typeahead.TaggedSearch {
  return (...args) => {
    return rx
      .of(undefined)
      .pipe(op.delay(delay))
      .pipe(op.mergeMap(() => wrapping(...args)));
  };
}
