// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2721
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'select.dash_connect_app_page_connector'
// Description: User clicked to connect to an app from onboarding flow
// Owner: otc
export type Select_DashConnectAppPageConnector = PAPEventGeneric<
  'dash',
  'select',
  'dash_connect_app_page_connector',
  {
    // connector app's display name that comes from our connector backend API
    appName?: string;
  }
>;

export function PAP_Select_DashConnectAppPageConnector(
  properties?: Select_DashConnectAppPageConnector['properties'],
): Select_DashConnectAppPageConnector {
  return <Select_DashConnectAppPageConnector>{
    class: 'dash',
    action: 'select',
    object: 'dash_connect_app_page_connector',
    properties,
  };
}
