// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3263
import type { PAPEventGeneric } from '../base/event';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { FeatureLine } from '../enums/feature_line';

// Event_name: 'shown.dash_empty_search_state_component'
// Description: Shown event for Dash empty state when searching within a stack
// Owner: dash-web
export type Shown_DashEmptySearchStateComponent = PAPEventGeneric<
  'dash',
  'shown',
  'dash_empty_search_state_component',
  {
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Feature Line for dash
    featureLine?: FeatureLine;
  }
>;

export function PAP_Shown_DashEmptySearchStateComponent(
  properties?: Shown_DashEmptySearchStateComponent['properties'],
): Shown_DashEmptySearchStateComponent {
  return <Shown_DashEmptySearchStateComponent>{
    class: 'dash',
    action: 'shown',
    object: 'dash_empty_search_state_component',
    properties,
  };
}
