import { sendLoginStateToService as sendLoginStateToServiceV1 } from "@mirage/service-login-sync-v1";
import { sendLoginStateToService as sendLoginStateToServiceV2 } from "@mirage/service-login-sync-v2";
import { ServiceAdapter } from "@mirage/service-logout/service";
import { runWithTimeLimit } from "@mirage/shared/util/tiny-utils";
import { DBID_KEY, UID_KEY } from "@mirage/webapp/helpers/readUrlParam";
import {
  expectLoginSyncV1,
  expectLoginSyncV2,
} from "@mirage/webapp/helpers/sentry";
import { RoutePath } from "@mirage/webapp/routeTypes";

import { AUTH_STORAGE } from "../AuthStorage";
import { removeWebAuthCookie, removeWebEnvCookie } from "../shared/webCookies";
import { sharedWorkerDatabase } from "../shared-worker/sharedWorkerStorage";

export class LogoutAdapter implements ServiceAdapter {
  async logout() {
    return logout();
  }
}

/**
 * Service specific teardowns are done in their respective services.
 * In case the services are not initialized we clear the AUTH_STORAGE directly.
 */
export async function logout() {
  // Preserve installId upon logout.

  const installId = await AUTH_STORAGE.get("installId");
  const email = (await AUTH_STORAGE.get("currentAccount"))?.email;
  const dbid = window.localStorage.getItem(DBID_KEY);
  const uid = window.localStorage.getItem(UID_KEY);

  // Local storage can only be cleared after logout as the
  // logout interferes with the local storage.
  await sharedWorkerDatabase.clear();

  window.localStorage.clear();

  // Clear Cookies
  removeWebAuthCookie();
  removeWebEnvCookie();

  await AUTH_STORAGE.clear();

  // Preserve installId upon logout.
  if (installId) {
    await AUTH_STORAGE.set("installId", installId);
  }

  if (dbid) {
    window.localStorage.setItem(DBID_KEY, dbid);
  }

  if (uid) {
    window.localStorage.setItem(UID_KEY, uid);
  }

  // Cause the extension to logout as well.
  try {
    // Warning: This could run for a while if we don't set a time limit here.
    if (expectLoginSyncV1()) {
      await runWithTimeLimit(
        sendLoginStateToServiceV1({
          type: "logout",
          email: email ?? "",
        }),
        3000,
      );
    } else if (expectLoginSyncV2()) {
      await runWithTimeLimit(
        sendLoginStateToServiceV2({
          type: "logout",
          email: email ?? "",
        }),
        3000,
      );
    }
  } catch {
    // ignore
  }

  // Cannot use navigate because we are switching from a logged in state to a
  // logged out state, so do full reload to refresh the Single-Page Application.
  // Not easy to do a full instant cleanup of all background services otherwise.
  window.location.href = RoutePath.ROOT + window.location.search;
}
