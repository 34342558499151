import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Shown_DashSurvey } from '@mirage/analytics/events/types/shown_dash_survey';
import {
  _listenForSprigAttributes,
  _listenForSprigPublishEvent,
  _publishSurveyClosed,
  _publishSurveyPresented,
  listenForSurveyPresented,
} from '@mirage/service-feedback';
import { useEffect } from 'react';
import { SprigClient } from '../client/sprig';

import type { DashSurface } from '@mirage/analytics/events/enums/dash_surface';

type SprigSurveyProps = {
  surface: DashSurface;
  environmentId: string;
  disabled?: boolean;
  publicAccountId?: string;
};

/**
 * Connects service-feedback to the sprig client.
 * Must call this hook somewhere in the React tree to enable service-feedback functionality.
 */
export default function useSprigSurvey({
  surface,
  environmentId,
  disabled,
  publicAccountId,
}: SprigSurveyProps): void {
  const { reportPapEvent } = useMirageAnalyticsContext();

  // Connect the sprig client to service-feedback
  useEffect(() => {
    if (disabled) {
      return;
    }

    const client = new SprigClient(
      environmentId,
      _listenForSprigPublishEvent(),
      _listenForSprigAttributes(),
      _publishSurveyPresented,
      _publishSurveyClosed,
    );

    return () => client.dispose();
  }, [disabled, environmentId]);

  useEffect(() => {
    if (disabled) {
      return;
    }

    // Listen for survey presented events on the service-feedback instead
    // of the client. This is so other callers can just listen directly on the service
    // and not have to deal with the client.
    const surveyPresentedSub = listenForSurveyPresented().subscribe(() => {
      reportPapEvent(
        PAP_Shown_DashSurvey({
          dashSurface: surface,
          externalUserId: publicAccountId,
        }),
      );
    });

    return () => surveyPresentedSub.unsubscribe();
  }, [disabled, publicAccountId, surface, reportPapEvent]);
}
