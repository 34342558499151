import { stacks } from '@dropbox/api-v2-client';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_View_DashStack } from '@mirage/analytics/events/types/view_dash_stack';
import { callApiV2 } from '@mirage/service-dbx-api';
import { stackDerivePAPProps } from '@mirage/service-stacks/service/utils';
import { useDashActionSurface } from '@mirage/shared/hooks/useDashActionSurface';
import { useStackByNsId } from '@mirage/stacks/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function useSelectedStackShareId(): string | undefined {
  const [selectedStackShareId, setSelectedStackShareId] = useState<
    string | undefined
  >(undefined);
  const { pathname } = useLocation();
  useEffect(() => {
    const prefix = '/stacks/';
    if (pathname.startsWith(prefix)) {
      setSelectedStackShareId(pathname.slice(prefix.length));
    } else {
      setSelectedStackShareId(undefined);
    }
  }, [pathname]);

  return selectedStackShareId;
}

export function useViewDashStackEvent(stack: stacks.Stack) {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const selectedShareId = useSelectedStackShareId();
  const { items: selectedStackItems } = useStackByNsId(stack.namespace_id);
  const { dashActionSurface } = useDashActionSurface();

  return useCallback(
    (actionSurfaceComponent?: ActionSurfaceComponent) => {
      reportPapEvent(
        PAP_View_DashStack({
          actionSurfaceComponent: actionSurfaceComponent ?? 'sidebar',
          numberOfLinks: selectedStackItems?.length || 0,
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
          actionSurface: dashActionSurface,
          dashActionSurface,
          // Had to overwrite the actionSurface with the value when stack is selected.
        }),
      );
    },
    [stack, selectedStackItems, dashActionSurface, selectedShareId],
  );
}

export function useDashTeamLogoUrl() {
  const [dashTeamLogoUrl, setDashTeamLogoUrl] = useState<string>();
  useEffect(() => {
    const fetch = async () => {
      const response = await callApiV2('dashAdminGetDashTeamLogoUrl', {});
      setDashTeamLogoUrl(response?.url ?? undefined);
    };

    fetch();
  }, [setDashTeamLogoUrl]);

  return dashTeamLogoUrl;
}
