// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgBox({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.614 13.136c0 .985.795 1.743 1.78 1.743.947 0 1.742-.758 1.78-1.743 0-.947-.795-1.742-1.78-1.742s-1.78.795-1.78 1.742zM5.386 13.136c0 .985.796 1.743 1.78 1.743.986 0 1.781-.758 1.781-1.743 0-.947-.795-1.742-1.78-1.742s-1.78.795-1.78 1.742z"
        fill="#0071F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 2h15A2.5 2.5 0 0122 4.5v15a2.5 2.5 0 01-2.5 2.5h-15A2.5 2.5 0 012 19.5v-15A2.5 2.5 0 014.5 2zm14.712 13.902c.227-.19.265-.53.076-.796l-1.477-1.97 1.477-1.931c.19-.266.19-.644-.076-.834-.303-.19-.644-.151-.833.114l-1.326 1.629-1.288-1.705c-.19-.227-.568-.303-.833-.114-.228.19-.303.569-.114.834l1.515 1.97-1.515 1.97c-.19.264-.151.605.114.795.265.189.644.189.833-.076l1.288-1.667 1.326 1.705c.19.227.568.265.833.075zm-9.394-4.13a2.933 2.933 0 012.576-1.514c1.629 0 2.954 1.288 2.917 2.84 0 1.63-1.326 2.917-2.955 2.917-1.098 0-2.083-.644-2.576-1.553a2.982 2.982 0 01-2.613 1.553 2.902 2.902 0 01-2.917-2.84V8.401c0-.304.265-.569.606-.569.303 0 .568.265.568.569v2.424a3.113 3.113 0 011.78-.568c1.137 0 2.122.606 2.614 1.515z"
        fill="#0071F7"
      />
    </svg>
  );
}

export default SvgBox;
