import { DASH_WEBAPP } from '@mirage/shared/urls';
import { isIframe } from '@mirage/shared/util/tiny-utils';

/*
Privacy module hack

The Dropbox privacy module provides two ways of invoking the cookie preferences:
  1. adding a #manage-cookies link, or
  2. using the floating button

The issue with just adding the #manage-cookies in the menu is that the event
handlers don't get added if the DOM element is hidden. Even if it does, the
menu destroys the DOM element after it's hidden.

The floating button obstructs our future designs, hence this hack: create an
invisible link that adds the event listeners and propagate the click action
from Manage cookies to this link
*/
const MANAGE_COOKIES_HASH = '#manage-cookies';

const InvisibleManageCookiesLink = document.createElement('a');
InvisibleManageCookiesLink.style.display = 'none';
InvisibleManageCookiesLink.href = MANAGE_COOKIES_HASH;
document.body.append(InvisibleManageCookiesLink);

export function openManageCookiesPanel() {
  // Break out of the iframe if needed.
  if (isIframe() && window.top) {
    window.top.location.href =
      DASH_WEBAPP + location.pathname + MANAGE_COOKIES_HASH;
  } else {
    InvisibleManageCookiesLink.click();
  }
}
