// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgGoogleDocs({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 7v13.712c0 .711-.565 1.288-1.26 1.288H5.76c-.696 0-1.26-.577-1.26-1.288V3.288C4.5 2.577 5.064 2 5.76 2h8.74l5 5z"
        fill="#2684FC"
      />
      <path
        d="M13.667 15.333H7v1.25h6.667v-1.25zM17 12.833H7v1.25h10v-1.25zM17 10.333H7v1.25h10v-1.25z"
        fill="#fff"
      />
      <path d="M19.5 7h-5V2l5 5z" fill="#0066DA" />
    </svg>
  );
}

export default SvgGoogleDocs;
