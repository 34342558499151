// @generated by bzl gen
// Simple type that can be exported to metaserver/js without pulling in dependencies.
// keep in sync ml_platform/capabilities/capability_check.proto

// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/ml_capabilities_types/capability_check.proto (package ml_capabilities_types, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';

/**
 * @generated from enum ml_capabilities_types.Product
 */
export enum Product {
  /**
   * Default value so we don't accidentally specify a real product
   *
   * @generated from enum value: UNKNOWN_PRODUCT = 0;
   */
  UNKNOWN_PRODUCT = 0,

  /**
   * @generated from enum value: EXPERIMENTAL = 1;
   */
  EXPERIMENTAL = 1,

  /**
   * @generated from enum value: CAPTURE = 2;
   */
  CAPTURE = 2,

  /**
   * Deprecated
   *
   * @generated from enum value: PHOTOS = 3;
   */
  PHOTOS = 3,

  /**
   * @generated from enum value: REPLAY = 4;
   */
  REPLAY = 4,

  /**
   * @generated from enum value: DROPBOX_BASE = 5;
   */
  DROPBOX_BASE = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(Product)
proto3.util.setEnumType(Product, 'ml_capabilities_types.Product', [
  { no: 0, name: 'UNKNOWN_PRODUCT' },
  { no: 1, name: 'EXPERIMENTAL' },
  { no: 2, name: 'CAPTURE' },
  { no: 3, name: 'PHOTOS' },
  { no: 4, name: 'REPLAY' },
  { no: 5, name: 'DROPBOX_BASE' },
]);

/**
 * @generated from enum ml_capabilities_types.Capability
 */
export enum Capability {
  /**
   * Default value so we don't accidentally specify a real capability
   *
   * @generated from enum value: UNKNOWN_CAPABILITY = 0;
   */
  UNKNOWN_CAPABILITY = 0,

  /**
   * @generated from enum value: TRANSCRIPTION = 1;
   */
  TRANSCRIPTION = 1,

  /**
   * Deprecated
   *
   * @generated from enum value: FACE_RECOGNITION = 2;
   */
  FACE_RECOGNITION = 2,

  /**
   * @generated from enum value: OPEN_AI_SHARE_ALL_FILES = 3;
   */
  OPEN_AI_SHARE_ALL_FILES = 3,

  /**
   * @generated from enum value: AI_THIRD_PARTY_SHARING = 4;
   */
  AI_THIRD_PARTY_SHARING = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(Capability)
proto3.util.setEnumType(Capability, 'ml_capabilities_types.Capability', [
  { no: 0, name: 'UNKNOWN_CAPABILITY' },
  { no: 1, name: 'TRANSCRIPTION' },
  { no: 2, name: 'FACE_RECOGNITION' },
  { no: 3, name: 'OPEN_AI_SHARE_ALL_FILES' },
  { no: 4, name: 'AI_THIRD_PARTY_SHARING' },
]);

/**
 * @generated from message ml_capabilities_types.StatusCheck
 */
export class StatusCheck extends Message<StatusCheck> {
  /**
   * Whether this check allows (true) or blocks (false) the capability
   *
   * @generated from field: bool is_allowed = 1;
   */
  isAllowed = false;

  /**
   * Whether the user can change `is_allowed` by their own action
   *
   * @generated from field: bool can_modify = 2;
   */
  canModify = false;

  /**
   * Whether this check determines whether the user can access the capability
   *
   * @generated from field: bool is_enforced = 3;
   */
  isEnforced = false;

  constructor(data?: PartialMessage<StatusCheck>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities_types.StatusCheck';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'is_allowed', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: 'can_modify', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: 'is_enforced', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): StatusCheck {
    return new StatusCheck().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): StatusCheck {
    return new StatusCheck().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): StatusCheck {
    return new StatusCheck().fromJsonString(jsonString, options);
  }

  static equals(
    a: StatusCheck | PlainMessage<StatusCheck> | undefined,
    b: StatusCheck | PlainMessage<StatusCheck> | undefined,
  ): boolean {
    return proto3.util.equals(StatusCheck, a, b);
  }
}

/**
 * @generated from message ml_capabilities_types.ProductCapabilityStatus
 */
export class ProductCapabilityStatus extends Message<ProductCapabilityStatus> {
  /**
   * @generated from field: ml_capabilities_types.Product product = 1;
   */
  product = Product.UNKNOWN_PRODUCT;

  /**
   * @generated from field: ml_capabilities_types.Capability capability = 2;
   */
  capability = Capability.UNKNOWN_CAPABILITY;

  /**
   * Whether the capability can be used with current opt-in and gating.
   *
   * @generated from field: bool passes_all_checks = 3;
   */
  passesAllChecks = false;

  /**
   * Whether the capability is allowed per killswitches, feature gates, etc.
   *
   * @generated from field: ml_capabilities_types.StatusCheck gating_check = 4;
   */
  gatingCheck?: StatusCheck;

  /**
   * User-level opt in
   *
   * @generated from field: ml_capabilities_types.StatusCheck user_check = 5;
   */
  userCheck?: StatusCheck;

  /**
   * Team-level opt in
   *
   * @generated from field: ml_capabilities_types.StatusCheck team_check = 6;
   */
  teamCheck?: StatusCheck;

  /**
   * File-level opt in
   *
   * @generated from field: ml_capabilities_types.StatusCheck file_check = 7;
   */
  fileCheck?: StatusCheck;

  constructor(data?: PartialMessage<ProductCapabilityStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities_types.ProductCapabilityStatus';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product', kind: 'enum', T: proto3.getEnumType(Product) },
    {
      no: 2,
      name: 'capability',
      kind: 'enum',
      T: proto3.getEnumType(Capability),
    },
    {
      no: 3,
      name: 'passes_all_checks',
      kind: 'scalar',
      T: 8 /* ScalarType.BOOL */,
    },
    { no: 4, name: 'gating_check', kind: 'message', T: StatusCheck },
    { no: 5, name: 'user_check', kind: 'message', T: StatusCheck },
    { no: 6, name: 'team_check', kind: 'message', T: StatusCheck },
    { no: 7, name: 'file_check', kind: 'message', T: StatusCheck },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ProductCapabilityStatus {
    return new ProductCapabilityStatus().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ProductCapabilityStatus {
    return new ProductCapabilityStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ProductCapabilityStatus {
    return new ProductCapabilityStatus().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ProductCapabilityStatus
      | PlainMessage<ProductCapabilityStatus>
      | undefined,
    b:
      | ProductCapabilityStatus
      | PlainMessage<ProductCapabilityStatus>
      | undefined,
  ): boolean {
    return proto3.util.equals(ProductCapabilityStatus, a, b);
  }
}

/**
 * @generated from message ml_capabilities_types.ProductCapabilityBatchStatus
 */
export class ProductCapabilityBatchStatus extends Message<ProductCapabilityBatchStatus> {
  /**
   * @generated from field: repeated ml_capabilities_types.ProductCapabilityStatus product_capability_statuses = 1;
   */
  productCapabilityStatuses: ProductCapabilityStatus[] = [];

  constructor(data?: PartialMessage<ProductCapabilityBatchStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName =
    'ml_capabilities_types.ProductCapabilityBatchStatus';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'product_capability_statuses',
      kind: 'message',
      T: ProductCapabilityStatus,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ProductCapabilityBatchStatus {
    return new ProductCapabilityBatchStatus().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ProductCapabilityBatchStatus {
    return new ProductCapabilityBatchStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ProductCapabilityBatchStatus {
    return new ProductCapabilityBatchStatus().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | ProductCapabilityBatchStatus
      | PlainMessage<ProductCapabilityBatchStatus>
      | undefined,
    b:
      | ProductCapabilityBatchStatus
      | PlainMessage<ProductCapabilityBatchStatus>
      | undefined,
  ): boolean {
    return proto3.util.equals(ProductCapabilityBatchStatus, a, b);
  }
}

/**
 * @generated from message ml_capabilities_types.CapabilityAuth
 */
export class CapabilityAuth extends Message<CapabilityAuth> {
  /**
   * List of per-product-capability auth states. This is a list because proto3 does not allow
   * maps with enum keys, and using a list is more strongly typed.
   *
   * @generated from field: repeated ml_capabilities_types.ProductCapabilityAuth batch_auths = 1;
   */
  batchAuths: ProductCapabilityAuth[] = [];

  constructor(data?: PartialMessage<CapabilityAuth>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities_types.CapabilityAuth';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'batch_auths',
      kind: 'message',
      T: ProductCapabilityAuth,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CapabilityAuth {
    return new CapabilityAuth().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CapabilityAuth {
    return new CapabilityAuth().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CapabilityAuth {
    return new CapabilityAuth().fromJsonString(jsonString, options);
  }

  static equals(
    a: CapabilityAuth | PlainMessage<CapabilityAuth> | undefined,
    b: CapabilityAuth | PlainMessage<CapabilityAuth> | undefined,
  ): boolean {
    return proto3.util.equals(CapabilityAuth, a, b);
  }
}

/**
 * @generated from message ml_capabilities_types.ProductCapabilityAuth
 */
export class ProductCapabilityAuth extends Message<ProductCapabilityAuth> {
  /**
   * @generated from field: ml_capabilities_types.Product product = 1;
   */
  product = Product.UNKNOWN_PRODUCT;

  /**
   * @generated from field: ml_capabilities_types.Capability capability = 2;
   */
  capability = Capability.UNKNOWN_CAPABILITY;

  /**
   * @generated from field: bool is_allowed = 3;
   */
  isAllowed = false;

  constructor(data?: PartialMessage<ProductCapabilityAuth>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities_types.ProductCapabilityAuth';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product', kind: 'enum', T: proto3.getEnumType(Product) },
    {
      no: 2,
      name: 'capability',
      kind: 'enum',
      T: proto3.getEnumType(Capability),
    },
    { no: 3, name: 'is_allowed', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ProductCapabilityAuth {
    return new ProductCapabilityAuth().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ProductCapabilityAuth {
    return new ProductCapabilityAuth().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ProductCapabilityAuth {
    return new ProductCapabilityAuth().fromJsonString(jsonString, options);
  }

  static equals(
    a: ProductCapabilityAuth | PlainMessage<ProductCapabilityAuth> | undefined,
    b: ProductCapabilityAuth | PlainMessage<ProductCapabilityAuth> | undefined,
  ): boolean {
    return proto3.util.equals(ProductCapabilityAuth, a, b);
  }
}
