// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3452
import type { PAPEventGeneric } from "../base/event";
import type { Environment } from "../enums/environment";
import type { BrowserName } from "../enums/browser_name";
import type { FeatureLine } from "../enums/feature_line";
import type { ActionSurfaceComponent } from "../enums/action_surface_component";
import type { DashActivityAction } from "../enums/dash_activity_action";
import type { DashObjectType } from "../enums/dash_object_type";

// Event_name: 'shown.activity_feed_item'
// Description: Triggered when a user views an activity feed item in dash. scroll_depth indicates what position in the feed the item is.
// Owner: otc-growth
export type Shown_ActivityFeedItem = PAPEventGeneric<
  'dash',
  'shown',
  'activity_feed_item',
  {
    // The depth of the scroll
    scrollDepth?: number;
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // This indicates the user's current browser
    browserName?: BrowserName;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Boolean event property that is set to true for Dash for Business (dfb) users.
    isDfbUser?: boolean;
    // The action type of a dash activity feed item.
    dashActivityAction?: DashActivityAction;
    // The type of an object in dash (ex: stack, document). Used to differentiate different object ids.
    dashObjectType?: DashObjectType;
    // The id of a generic object in dash -- could be a document uuid, a stack id, etc... Differentiated with dash_object_type
    dashObjectId?: string;
    // The dropbox user id of the actor who performed some action (typically not the current user).
    actorUserId?: number;
    // The zero-index returned position of search result in a list of search results.
    resultPosition?: number;
    // Which Dash connector an event is associated with, e.g. Trello.
    dashConnectorId?: string;
    // zero-indexed position of a calendar item
    itemPosition?: number;
  }
>;

export function PAP_Shown_ActivityFeedItem(properties?: Shown_ActivityFeedItem['properties']): Shown_ActivityFeedItem {
  return <Shown_ActivityFeedItem>{
    class: 'dash',
    action: 'shown',
    object: 'activity_feed_item',
    properties,
  };
}
