import { UIIcon } from '@dropbox/dig-icons';
import { FileLine, FilterLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import {
  type SearchFilter,
  SearchFilterType,
} from '@mirage/shared/search/search-filters';
import React, { useMemo } from 'react';
import { BaseIcon, getIconFromIconResource } from '.';

type Props = {
  filter: SearchFilter;
  size?: number;
};

export function SearchFilterIcon({ filter, size = 24 }: Props) {
  const isDarkMode = useTheme();

  return useMemo(() => {
    const DefaultIcon = <UIIcon src={FilterLine} />;

    switch (filter.type) {
      case SearchFilterType.Connector: {
        const { displayName, iconLightSrc, iconDarkSrc } = filter.parameters;
        const Icon = getIconFromIconResource(
          {
            lightUrl: iconLightSrc,
            darkUrl: iconDarkSrc ? iconDarkSrc : iconLightSrc,
          },
          displayName,
          isDarkMode,
        );
        return Icon ? <BaseIcon size={size} Icon={Icon} /> : DefaultIcon;
      }
      case SearchFilterType.ContentType:
        return <UIIcon src={FileLine} />;
      case SearchFilterType.Person:
      case SearchFilterType.LastUpdated:
      default:
        return DefaultIcon;
    }
  }, [filter, isDarkMode]);
}
