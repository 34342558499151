// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2587
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'open.dash_empty_state_component'
// Description: Open event for Dash empty state component
// Owner: otc-growth
export type Open_DashEmptyStateComponent = PAPEventGeneric<
  'dash',
  'open',
  'dash_empty_state_component',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
  }
>;

export function PAP_Open_DashEmptyStateComponent(
  properties?: Open_DashEmptyStateComponent['properties'],
): Open_DashEmptyStateComponent {
  return <Open_DashEmptyStateComponent>{
    class: 'dash',
    action: 'open',
    object: 'dash_empty_state_component',
    properties,
  };
}
