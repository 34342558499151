import { EnvironmentContext } from "@mirage/service-environment-context/service/types";
import { getWebPlatformForEnvCtx } from "@mirage/service-environment-context/web-platform";

import { Config } from "./config";

export const WebEnvCtx: EnvironmentContext = {
  version: Config.BUILD_VERSION,
  platform: getWebPlatformForEnvCtx(),
  environment: process.env.NODE_ENV,
  buildChannel: Config.BUILD_CHANNEL,
  defaultBrowser: "unused",
  surface: "web",
};
