// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgClickUp({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 17.366l3.075-2.357c1.634 2.133 3.37 3.116 5.303 3.116 1.922 0 3.609-.971 5.169-3.087l3.12 2.3c-2.252 3.05-5.05 4.662-8.29 4.662C9.15 22 6.325 20.398 4 17.366z"
        fill="url(#click-up_svg__paint0_linear)"
      />
      <path
        d="M12.368 7.125l-5.474 4.717-2.53-2.934L12.378 2l7.953 6.913-2.542 2.924-5.422-4.712z"
        fill="url(#click-up_svg__paint1_linear)"
      />
      <defs>
        <linearGradient
          id="click-up_svg__paint0_linear"
          x1={4}
          y1={19.764}
          x2={20.666}
          y2={19.764}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8930FD" />
          <stop offset={1} stopColor="#49CCF9" />
        </linearGradient>
        <linearGradient
          id="click-up_svg__paint1_linear"
          x1={4.363}
          y1={8.694}
          x2={20.332}
          y2={8.694}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF02F0" />
          <stop offset={1} stopColor="#FFC800" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgClickUp;
