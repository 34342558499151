import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useMirageAnalyticsContext } from "@mirage/analytics/AnalyticsProvider";
import { PAP_Finish_DashLogin } from "@mirage/analytics/events/types/finish_dash_login";
import { FullPageSpinner } from "@mirage/mosaics/FullPageSpinner";
import { exchangeCodeForToken, getCurrentAccount } from "@mirage/service-auth";
import { RedirectState } from "@mirage/service-auth/types";
import { clearCachedFeatureValues } from "@mirage/service-experimentation";
import { tagged } from "@mirage/service-logging";
import { isUsersFirstLogin } from "@mirage/service-onboarding";
import { hasUserCompletedOnboarding } from "@mirage/service-onboarding/hasUserCompletedOnboarding";
import { getIsDfbUser } from "@mirage/shared/eligibility";
import { isRedirectAllowedWithUrl } from "@mirage/shared/urls";
import { sleepMs } from "@mirage/shared/util/tiny-utils";
import { dashAppDwellManager } from "@mirage/webapp/analytics/sessionManagers/sessionManagers";
import {
  endLoginMetricTiming,
  startLoginMetricTiming,
} from "@mirage/webapp/performance/LoginMetrics";
import { RoutePath } from "@mirage/webapp/routeTypes";
import * as Sentry from "@sentry/react";

import { syncSignInToExtension } from "../helpers/extensionSignIn";

let authState: "not-started" | "in-progress" | "completed" = "not-started";

const logger = tagged("OAuth");

class ExchangeTimeout extends Error {}

export const OAuth = () => {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  const { reportPapEvent } = useMirageAnalyticsContext();
  dashAppDwellManager.extendOrCreateSession();

  if (navigateRef.current !== navigate) {
    const s = "navigate changed identity";
    Sentry.captureMessage(s);
    logger.debug(s);
    navigateRef.current = navigate;
  }

  useEffect(() => {
    logger.debug("OAuth start");

    function redirectAfterLogin(redirectToPath: string | null) {
      if (redirectToPath === null || redirectToPath.startsWith("/")) {
        // replace: true prevents the back button from navigating back to
        // /oauth?code=<code> (such a navigation produces errors or an infinite
        // spinner as the code is probably already used).
        navigateRef.current(redirectToPath || RoutePath.ROOT, {
          replace: true,
        });
      } else {
        window.location.href = redirectToPath;
      }
    }

    async function completeOauthLogin() {
      const query = new URLSearchParams(location.search);
      const code = query.get("code");
      const codeVerifier = query.get("code_verifier");
      const encodedState = query.get("state");

      // If the codeVerifier were given, that means we are using Catapult login.
      if (codeVerifier) {
        startLoginMetricTiming("catapult");
      }

      let redirectToPath: string | null = null;
      if (encodedState) {
        // Note: In our dev api app, we begin getting a `state` url param
        // containing a uuid, so `atob(encodedState)` below will always fail.
        // Not sure why this changed, but we should add a try-catch anyways.
        try {
          const decodedState = atob(encodedState);
          const redirectState: RedirectState = JSON.parse(decodedState);

          if (redirectState.redirectToPathEncoded) {
            redirectToPath = decodeURIComponent(
              redirectState.redirectToPathEncoded,
            );
            if (!isRedirectAllowedWithUrl(redirectToPath)) {
              redirectToPath = null;
            }
          }
        } catch (e) {
          logger.warn(`Error with encodedState`, e);
        }
      }

      logger.debug("completeOauthLogin() - query params", {
        code,
        redirectToPath,
      });

      let authSucceeded = false;
      try {
        // If there is no code, send to home page.
        if (!code) {
          logger.debug(
            "completeOauthLogin() - no oauth code found, redirecting to home",
          );
          return;
        }

        const success = await Promise.race([
          exchangeCodeForToken(code, codeVerifier),
          sleepMs(10_000).then(() => {
            throw new ExchangeTimeout("Timeout during exchangeCodeForToken");
          }),
        ]).catch(async (e) => {
          if (e instanceof ExchangeTimeout) {
            logger.error("exchangeCodeForToken failed (timeout reached)", e);
          } else {
            logger.error("exchangeCodeForToken failed (not due to timeout)", e);
          }
          Sentry.captureException(e);
          // Try to let the exception be reported before retrying
          // Also allows datadog logs to be flushed.
          await sleepMs(3000);
          return false;
        });

        if (!success) {
          logger.error("Error validating token");
          return;
        }

        clearCachedFeatureValues();

        // Record the total time taken for the login.
        const accountPromise = getCurrentAccount().then((account) => {
          endLoginMetricTiming(account?.is_paired ?? false);
          return account;
        });

        logger.debug(
          "completeOauthLogin() - successfully exchanged code for token",
        );

        const [firstTimeUser, userCompletedOnboarding, isDfbUser] =
          await Promise.all([
            isUsersFirstLogin(),
            hasUserCompletedOnboarding(),
            getIsDfbUser(),
          ]);

        logger.debug("completeOauthLogin() - onboarding state", {
          firstTimeUser,
          userCompletedOnboarding,
          isDfbUser,
        });

        reportPapEvent(
          PAP_Finish_DashLogin({
            returningUser: !firstTimeUser,
          }),
        );
        authSucceeded = true;

        // If the code throws an error, it shouldn't cause the webapp login to fail.
        try {
          const account = await accountPromise;
          await syncSignInToExtension(account);
        } catch (e) {
          logger.warn(`Failed to send auth data to the browser extension:`, e);
        }
      } catch (e) {
        logger.error("Oauth login error: ", e);
      } finally {
        // Intentionally drop redirectToPath if auth failed:
        // The redirectToPath probably requires auth, which means it will
        // re-trigger the login flow. However, that flow just failed, meaning
        // there is a chance we are in a loop of some kind.
        logger.info("completeOauthLogin() - redirecting after login");
        redirectAfterLogin(authSucceeded ? redirectToPath : null);
      }
    }

    switch (authState) {
      case "not-started":
        {
          authState = "in-progress";
          logger.debug("auth not in progress, attempting to complete oauth");
          completeOauthLogin().finally(() => {
            logger.info("oauth completed");
            authState = "completed";
          });
        }
        break;
      case "in-progress":
        {
          logger.debug("duplicate auth attempt detected in parallel");
          Sentry.captureMessage("Mitigated duplicate auth attempt in parallel");
        }
        break;
      case "completed":
        {
          logger.debug("auth attempt detected after completion");
          Sentry.captureMessage("Mitigated auth attempt after completion");
        }
        break;
    }
  }, [reportPapEvent]);

  return <FullPageSpinner spinnerId="OAuth" />;
};
