import { useMemo } from 'react';
import useSettings from './useSettings';

const SettingKeys = ['customizableModules'] as const;

export function useCustomizableModuleIsHidden(moduleId: string) {
  const { settings } = useSettings(SettingKeys);
  const customizableModules = settings?.customizableModules;

  return useMemo(() => {
    if (!customizableModules?.length) return;

    for (const module of customizableModules) {
      if (module.id === moduleId) {
        return Boolean(module.isHidden);
      }
    }

    return false; // default is false to show this module
  }, [customizableModules, moduleId]);
}

export function useCustomizableModulesSortOrder(): { [id: string]: number } {
  const { settings } = useSettings(SettingKeys);
  const customizableModules = settings?.customizableModules;

  return useMemo(() => {
    const idToIndex: { [id: string]: number } = {};

    if (customizableModules?.length) {
      let index = 0;
      for (const module of customizableModules) {
        idToIndex[module.id] = index;
        index += 1;
      }
    }

    return idToIndex;
  }, [customizableModules]);
}

const InternalSettingsKeys = [
  'customizableModules',
  'isCustomizableConnectorsCached',
] as const;

/**
 * The settings is a bit slow to initial load, so allow preload from the
 * parent component instead.
 */
export function useCustomizableModulesInternalSettings() {
  const { settings, set } = useSettings(InternalSettingsKeys);

  // Intentionally return a new data type to make sure users are loading the
  // correct settings.
  return {
    customizableModulesInternalSettings: settings,
    setCustomizableModulesInternalSettings: set,
  };
}

export type CustomizableModulesInternalSettings = ReturnType<
  typeof useCustomizableModulesInternalSettings
>;
