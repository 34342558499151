// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgGoogleDrive({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.535 21.773c.34-.207.63-.491.832-.853l.403-.711 1.928-3.426A2.39 2.39 0 0023 15.62h-6.93l1.474 2.973 1.99 3.18z"
        fill="#EA4335"
      />
      <path
        d="M2.663 19.2l.97 1.72c.202.362.492.646.832.853L7.93 15.62H1c0 .4.1.801.302 1.163l1.361 2.418z"
        fill="#0066DA"
      />
      <path
        d="M12 8.38L8.535 2.227c-.34.207-.63.491-.832.853l-6.4 11.376A2.39 2.39 0 001 15.62h6.93L12 8.38z"
        fill="#00AC47"
      />
      <path
        d="M16.07 15.62H7.93l-3.465 6.153c.34.207.73.31 1.134.31h12.802a2.3 2.3 0 001.134-.31L16.07 15.62z"
        fill="#2684FC"
      />
      <path
        d="M12 8.38l3.465-6.153c-.34-.207-.73-.31-1.134-.31H9.669a2.3 2.3 0 00-1.134.31L12 8.38z"
        fill="#00832D"
      />
      <path
        d="M19.497 8.768l-3.2-5.688a2.319 2.319 0 00-.832-.853L12 8.38l4.07 7.24h6.918c0-.401-.101-.802-.303-1.164l-3.188-5.688z"
        fill="#FFBA00"
      />
    </svg>
  );
}

export default SvgGoogleDrive;
