// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgEvernote({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.642 6.476c.02.266-.062.53-.23.744-.228.2-.581.219-.788.219H5.386c-.431-.012-.863 0-1.293.033a1.909 1.909 0 00-.402.116c-.027.01-.035 0-.016-.016l5.107-4.927c.017-.017.028-.01.017.017-.056.122-.097.25-.122.381-.036.408-.047.818-.035 1.227v2.206zm4.747 14.897a2.569 2.569 0 01-1.03-1.144 2.382 2.382 0 01-.171-1.201c.047-.408.2-.798.444-1.137.245-.338.573-.615.956-.804.384-.19.81-.288 1.243-.284a.818.818 0 01.557.217c.073.07.132.152.17.243a.71.71 0 01-.051.664.777.777 0 01-.297.274.972.972 0 01-.297.096 1.34 1.34 0 00-.575.179.748.748 0 00-.227.257.706.706 0 00-.084.325c0 .242.1.475.28.647.155.148.34.265.545.345.204.08.423.12.643.119.283 0 .564-.051.825-.153.262-.102.5-.253.7-.442.2-.19.36-.415.467-.663.109-.248.164-.513.164-.782 0-1.011-.717-1.907-1.66-2.305a3.403 3.403 0 00-.578-.165 7.563 7.563 0 00-.524-.083c-.735-.083-2.57-.63-2.693-2.173 0 0-.542 2.322-1.626 2.952-.128.06-.263.104-.402.133a2.46 2.46 0 01-.384.05c-1.766.099-3.637-.432-4.93-1.692 0 0-.875-.68-1.33-2.587-.104-.464-.314-1.293-.437-2.072-.052-.282-.07-.498-.087-.697 0-.804.525-1.36 1.18-1.438H7.738c.612 0 .962-.149 1.189-.348.297-.265.367-.646.367-1.094V3.217v-.096C9.376 2.499 9.962 2 10.81 2h.42a4.387 4.387 0 011.048.133c1.067.252 1.294 1.276 1.294 1.276l3.025.498c.961.166 3.34.315 3.794 2.587 1.066 5.405.42 10.646.367 10.646-.752 5.107-5.228 4.858-5.228 4.858a4.035 4.035 0 01-2.141-.625zm4.03-10.871c-.58-.058-1.07.165-1.25.582a.416.416 0 00-.05.232.143.143 0 00.09.083c.211.096.564.144 1.07.202.505.058.86.09 1.089.043a.143.143 0 00.107-.06.395.395 0 00.01-.237c-.07-.45-.484-.772-1.066-.845z"
        fill="#00A82D"
      />
    </svg>
  );
}

export default SvgEvernote;
