import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgLucid({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 417 417"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.6181 104.89V311.529L209.14 415.081V363.537L76.7485 285.99V130.894L32.6181 104.89Z"
        fill="#F96B13"
      />
      <path
        d="M340.891 130.911V337.693L385.021 311.671V104.89L340.891 130.911Z"
        fill="#CC4E00"
      />
      <path
        d="M165.009 27.4785L340.926 131.112L385.021 105.088L209.104 1.9187L165.009 27.4785Z"
        fill="#FFCA73"
      />
      <path
        d="M32.6181 105.209L76.7485 131.112L120.879 105.209L76.7485 79.3067L32.6181 105.209Z"
        fill="#FFCA73"
      />
      <path
        d="M76.7485 130.879V285.888L120.879 259.898V104.89L76.7485 130.879Z"
        fill="#CC4E00"
      />
      <path
        d="M209.14 363.644V415.081L253.27 389.131V337.693L209.14 363.644Z"
        fill="#CC4E00"
      />
      <path
        d="M76.7485 286.324L209.14 363.916L253.27 337.897L120.879 260.305L76.7485 286.324Z"
        fill="#FFCA73"
      />
      <path
        d="M165.009 27.5015V79.0454L297.4 156.593V311.689L341.531 337.693V131.054L165.009 27.5015Z"
        fill="#F96B13"
      />
    </svg>
  );
}

export default SvgLucid;
