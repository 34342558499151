import { LaunchMethod } from '@mirage/analytics/events/enums/launch_method';
import { AnalyticsHtmlAttributes } from '@mirage/analytics/uxa';

import type { ReactNode } from 'react';

export const enum DisplayActionTypes {
  IconButton,
  Normal,
}

export type DisplayAction = {
  key: string;
  icon?: ReactNode;
  title: string;
  onClick?: (launchMethod: LaunchMethod) => void;
  analyticsAttr?: AnalyticsHtmlAttributes;
  hidden?: boolean;
  persistent?: boolean;
  disabled?: boolean;
  type?: DisplayActionTypes;
  tooltip?:
    | (() => Promise<DisplayActionTooltip | undefined>)
    | (() => DisplayActionTooltip | undefined);
};

export type DisplayActionTooltip = {
  key: string;
  title: string;
  message: string;
};

export type ResultActionConfig = {
  displayActions?: Array<DisplayAction>;
  launchAction?: DisplayAction;
};
