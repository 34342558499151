import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgWorkday({ size = 24 }: IconProps) {
  return (
    <svg
      version="1.2"
      baseProfile="tiny-ps"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1464 1655"
      width={size}
      height={size}
    >
      <g id="#f69f1aff">
        <path
          id="Layer"
          fill="#f69f1a"
          d="M686.3 1.98C855.4 -8.39 1027.2 38.12 1168.14 132.11C1295.93 216.5 1398.58 338.7 1458.12 479.88C1467.41 505.18 1464.18 535.04 1448.31 557.03C1431.06 582.15 1399.21 595.65 1369.15 591.41C1341.05 587.85 1315.55 568.71 1304.53 542.61C1276.84 477.39 1237.85 417.01 1189.88 364.9C1105.21 272.62 992.12 206.49 869.73 179.5C775.32 159.88 676.69 160.56 582.66 181.98C467.51 209.11 360.85 270.93 280.14 357.46C236.43 404.67 197.95 457.29 170.89 515.87C162.76 531.96 158.17 550.2 145.79 563.79C122.89 590.85 81.81 599.76 49.65 584.98C19.08 571.89 -1.42 538.92 0.49 505.68C0.77 485.41 11.36 467.58 19.44 449.62C84.25 313.33 189.32 196.65 318.1 117.97C428.79 49.79 556.48 9.49 686.3 1.98Z"
        />
      </g>
      <g id="#0067abff">
        <path
          id="Layer"
          fill="#0067ab"
          d="M23.6 724.65C32.02 712.92 45.56 704.57 60.29 705.03C101.58 705.05 142.89 704.27 184.15 705.48C206.02 705.68 224.98 724.1 227.2 745.63C268.01 913.93 309.02 1082.18 350.08 1250.45C362.81 1305.53 378.48 1360.04 387.35 1415.95C396.16 1465.71 397.85 1516.38 405.89 1566.26C413.26 1511.26 422.89 1456.55 435.1 1402.41C445.08 1357.16 458.17 1312.7 469.9 1267.88C516.81 1092.95 563.8 918.02 610.63 743.04C615.75 721.93 635.88 705.63 657.67 705.26C703.19 704.52 748.77 704.93 794.29 705.03C803.47 705.08 812.78 704.17 821.83 706.14C838.58 710.17 853.31 722.08 860.37 737.84C863.6 745.18 865.26 753.07 867.43 760.77C899.01 875.75 930.26 990.83 961.6 1105.86C971.97 1143.11 981.43 1180.64 992.73 1217.61C1016.31 1297.61 1036.21 1378.7 1052.25 1460.54C1059.66 1495.8 1062.71 1531.79 1070.26 1567.02C1085.84 1450.48 1106.4 1334.61 1131.92 1219.83C1143.22 1167.45 1157.22 1115.7 1168.49 1063.31C1192.42 956.3 1215.91 849.16 1240.19 742.23C1243.8 726.49 1255.83 713.2 1270.96 707.73C1279.74 704.22 1289.37 704.98 1298.63 705C1334.85 705.1 1371.09 704.3 1407.28 705.43C1422.84 705.41 1437.39 717.66 1439.71 733.07C1441.61 744.07 1436.96 754.49 1434.29 764.9C1357.19 1049.52 1279.99 1334.11 1203.19 1618.8C1197.29 1638.45 1177.95 1652.92 1157.47 1653.33C1094.47 1655.67 1031.42 1653.38 968.41 1654.39C950.23 1653.7 933.71 1639.96 929.27 1622.38C882.21 1449.59 835.96 1276.58 789.42 1103.67C778.75 1063.34 766.88 1023.29 758.55 982.41C747.35 929.09 739.01 875.12 734.67 820.79C727.23 884.3 716.99 947.53 702.59 1009.82C649.27 1209.29 595.6 1408.66 541.88 1608.01C539.21 1618.47 537.09 1629.9 528.99 1637.74C515.37 1651.33 495.02 1654.76 476.54 1654.74C417.62 1652.82 358.63 1656.03 299.74 1653.33C286.98 1652.92 274.64 1646.04 267.66 1635.37C262.41 1627.65 261.28 1618.19 258.73 1609.47C182.21 1330.8 105.92 1052.07 29.55 773.38C24.66 757.61 24 741.02 23.6 724.65Z"
        />
      </g>
    </svg>
  );
}

export default SvgWorkday;
