/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { getReadableTime } from '@mirage/shared/util/tiny-utils';

import type { LogLevel, LogObject } from 'consola';

// consola does not expose these externally, so we are kinda cucked trying to do
// any sort of level-setting without a direct import
export class BrowserReporter {
  level: LogLevel;
  defaultColor: string;
  levelColorMap: Record<number, string>;
  typeColorMap: Record<string, string>;

  constructor(level: LogLevel) {
    this.level = level;

    this.defaultColor = '#7f8c8d'; // Gray
    this.levelColorMap = {
      0: '#c0392b', // Red
      1: '#f39c12', // Yellow
      3: '#00BCD4', // Cyan
    };
    this.typeColorMap = {
      success: '#2ecc71', // Green
    };
  }

  _getLogFn(level: number) {
    if (level < 1) return (console as any).__error || console.error;
    if (level === 1) return (console as any).__warn || console.warn;
    return (console as any).__log || console.log;
  }

  log(logObj: LogObject) {
    // ignore if verbosity is greater than our targeted level
    if (logObj.level > this.level) return;

    const consoleLogFn = this._getLogFn(logObj.level);
    const type = logObj.type === 'log' ? '' : logObj.type;
    const tag = logObj.tag || '';
    const color =
      this.typeColorMap[logObj.type] ||
      this.levelColorMap[logObj.level] ||
      this.defaultColor;

    const tagStyle = `
      background: ${color};
      border-radius: 0.5em;
      color: white;
      font-weight: bold;
      padding: 2px 0.5em;
    `;

    const timeStyle = `
      font-weight: bold;
      padding: 2px 0.5em;
      margin-right: 0.25em;
      border-radius: 0.5em;
      border: 1px solid ${color}
    `;

    const badge = `%c${[tag, type].filter(Boolean).join(':')}`;
    const timestamp = `%c${getReadableTime()}`;

    // Log to the console
    if (typeof logObj.args[0] === 'string') {
      consoleLogFn(
        `${timestamp}${badge}%c ${logObj.args[0]}`,
        timeStyle,
        tagStyle,
        // Empty string as style resets to default console style
        '',
        ...logObj.args.slice(1),
      );
    } else {
      consoleLogFn(timestamp, badge, timeStyle, tagStyle, ...logObj.args);
    }
  }
}
