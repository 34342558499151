import styled from '@emotion/styled';
import * as React from 'react';

type ListProps = {
  height?: string | number;
  flex?: string;
  fade?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Item = any;

type PropsType<Item> = {
  children: (item: Item, idx: number) => React.ReactElement | null | undefined;
  height?: number | string;
  items: Array<Item>;
  flex?: string;
};

function renderListItem(
  item: Item,
  mapItem: Item,
  idx: number,
  references: Array<React.Ref<HTMLDivElement>>,
) {
  if (!mapItem) return null;
  const mappedItem = mapItem(item, idx);
  if (!mappedItem) return null;

  const key = Object.hasOwnProperty.call(mappedItem, 'key')
    ? mappedItem.key
    : undefined;

  if (!references[idx]) {
    return null;
  }

  return (
    <div ref={references[idx]} key={key}>
      {mappedItem}
    </div>
  );
}

function itemMap(
  children: Item,
  items: Array<Item>,
  references: Array<React.Ref<HTMLDivElement>>,
): JSX.Element[] | null {
  // use the provided item mapping function to generate renderable rows for
  // each of the provided items, allow nullable returns and filtering them
  // to remove results
  if (items.length === 0) return null;

  const elements: JSX.Element[] = [];

  for (const [index, item] of items.entries()) {
    const listItem = renderListItem(item, children, index, references);
    if (listItem) {
      elements.push(listItem);
    }
  }

  return elements;
}

export default function ListContainer({
  children,
  items,
  height,
  flex,
}: PropsType<Item>) {
  // create refs only once
  const references = items.map((_item) => React.createRef<HTMLDivElement>());
  const self = React.useRef(null);

  return (
    <List ref={self} height={height} flex={flex}>
      {itemMap(children, items, references)}
    </List>
  );
}

const List = styled.ul<ListProps>`
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  min-height: ${(props: { height?: string | number }) =>
    props.height || '400px'};

  ${(props: ListProps) => props.flex && `flex: ${props.flex};`}

  ${(props: ListProps) =>
    props.fade &&
    `&:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 36px;
      pointer-events: none;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--whiteV2) 68.75%);
    }`}
`;
