import { dash, dcs } from '@dropbox/api-v2-client/types/dropbox_types';
import { callApiV2, fetchAnswersForQuery } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';

const logger = tagged('ComposeAssistant/search-tools-apis');

export async function runMultiSearch(queries: string[]) {
  const searchResponses = await Promise.all(
    queries.map((query) => runSearch(query)),
  );
  // merge results, remove duplicates based on uuid
  const mergedResults: dash.query_result_unionSearchResult[] = [];
  const uuids = new Set<string>();
  for (const searchResponse of searchResponses) {
    for (const result of searchResponse) {
      if (!result.uuid) {
        continue;
      }
      if (!uuids.has(result.uuid)) {
        mergedResults.push(result);
        uuids.add(result.uuid);
      }
    }
  }
  return mergedResults;
}

async function runSearch(query: string) {
  const response = await callApiV2('dcsSearch', {
    query_text: query,
    type: { '.tag': 'do_not_include_answer' },
  });
  logger.log('dash_search called', query, response);
  const results: dash.query_result_unionSearchResult[] = [];
  for (const result of response.results || []) {
    if (
      result.query_result &&
      result.query_result['.tag'] === 'search_result' &&
      result.query_result.summarizable?.['.tag'] === 'yes_summarizable' &&
      result.query_result.uuid
    ) {
      results.push(result.query_result);
    }
  }
  return results.slice(0, 4);
}

type GetContentResult = GetContentResultSuccess | GetContentResultError;
interface GetContentResultSuccess {
  type: 'success';
  content: string;
}
interface GetContentResultError {
  type: 'error';
  error:
    | 'request_failed'
    | 'cannot_find_content'
    | 'no_body_content'
    | 'malformed_source';
}

export async function getContent(uuid: string): Promise<GetContentResult> {
  let response: dcs.GetLinkMetadataResponse | undefined = undefined;
  try {
    response = await callApiV2('dcsGetLinkMetadata', {
      url_or_uuids: [
        {
          link_type: {
            '.tag': 'uuid',
            uuid: uuid,
          },
        },
      ],
      include_body: true,
    });
  } catch (e) {
    logger.error('failed to call dcsGetLinkMetadata', e);
    return {
      type: 'error',
      error: 'request_failed',
    };
  }
  if (!response.results || response.results.length === 0) {
    logger.error('no results in dcsGetLinkMetadata response');
    return {
      type: 'error',
      error: 'cannot_find_content',
    };
  }
  if (response.results[0].body?.blob_content?.['.tag'] === 'raw_content') {
    const rawContent = response.results[0].body.blob_content.raw;
    if (rawContent) {
      logger.log(`fetched content for ${uuid}: ${rawContent.length} chars`);
      return {
        type: 'success',
        content: rawContent,
      };
    } else {
      logger.error('no body content in dcsGetLinkMetadata response');
      return {
        type: 'error',
        error: 'no_body_content',
      };
    }
  }
  logger.error('no body.blob_content in dcsGetLinkMetadata result');
  return {
    type: 'error',
    error: 'no_body_content',
  };
}

export async function callAnswersAPI(question: string) {
  const response = await fetchAnswersForQuery(question);
  return response.answers;
}
