import { internalGetAll } from '.';

import type { EnvironmentContext } from './service/types';

/**
 * Global access to the full environment context which is usable from both
 * the main process and renderer processes. Make it easier for UI code to use
 * this value by making it non-async. No need to add value assertions here
 * because if not initialized, EnvCtx will be undefined, and will throw an
 * error during runtime, which should be caught during development.
 */
// eslint-disable-next-line import/no-mutable-exports
export let EnvCtx: Readonly<EnvironmentContext>;

/** Use this to init the env ctx inside the same process. */
export function directInitEnvCtx(ctx: EnvironmentContext) {
  EnvCtx = ctx;
}

/** Apps need to init this once in the renderer process. */
export async function initUiEnvCtx() {
  // Fetch the actual values from the main process.
  directInitEnvCtx(await internalGetAll());
}
