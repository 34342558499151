import { tagged } from '@mirage/service-logging';
import { Backoff } from '@mirage/shared/util/backoff';
import { getReadableTime, sleepMs } from '@mirage/shared/util/tiny-utils';
import { ChannelId, compareRevisions, SignedChannelState } from './utils';

const logger = tagged('packages/bolt/client');

// Adapted from:
// https://sourcegraph.pp.dropbox.com/server@925592b0e3b7ee12c8abc70b3ff1ca30aa770e46/-/blob/metaserver/static/js/modules/clean/bolt_nodeps.ts
// and
// https://sourcegraph.pp.dropbox.com/github.com/dropbox-internal/passwords-extensions/-/blob/src/background/notifications/bolt-client.ts

// When the user is signed out, prevent the bolt polls from going into a fast loop.
const MIN_POLL_INTERVAL_MS = 5000;

// const BOLT_SESSION_HEADER = "X-Bolt-Session";
const NOTIFICATION_SUBSCRIBE_ENDPOINT = '/2/notify/subscribe';
// const PAYLOAD_SUBSCRIBE_ENDPOINT = "/2/payloads/subscribe";

/**
 * A Payload represents specific payload received from application server.
 * `revision` is a string encoding a monotonically increasing integer representing the
 * revision of this payload.
 * `payload` is payload object, which is likely a JSON object.
 */
export type Payload = {
  revision: string;
  payload: unknown;
};

/**
 * ChannelPayloads represents single update response from BoltPayloadClient.
 * `channel_state` is SignedChannelState, its revision is the same as revision of the latest
 * returned payload.
 * `payloads` is a list of Payload objects needed to update client's state to latest known
 * revision.
 */
export type ChannelPayloads = {
  channel_state: SignedChannelState;
  payloads: Payload[];
};

type LongPollInvalidChannels = { invalid_channels: ChannelId[] };
type LongPollChannelStates<DataType> = { channel_states: DataType[] };

type LongPollResponseBody<DataType> =
  | LongPollInvalidChannels
  | LongPollChannelStates<DataType>;

/**
 * Base implementation for bolt notifications of 2 possible variants:
 * (1) with payloads (BoltPayloadClient)
 * (2) no payloads (not needed in this code base).
 */
abstract class BaseClient<DataType> {
  protected signedChannelStates: { [combinedId: string]: SignedChannelState } =
    {};
  protected signedChannelStatesKeys: string[] = [];
  protected _started = false;
  protected backoff = new Backoff();
  protected additionalHeaders: { [header: string]: string } = {};
  protected successLastTimestamp = 0;
  protected successMinimumDelayMs: number = MIN_POLL_INTERVAL_MS;
  protected isTest: boolean;

  protected timeoutId: NodeJS.Timeout | null = null;
  protected longPollAbortController: AbortController | null = null;
  protected longPollAbortTimerId: NodeJS.Timeout | null = null;
  protected ignoreExtraChannels: boolean = false;

  protected updateCallback: (states: DataType[]) => Promise<void>;
  protected refreshCallback: (invalidChannels: ChannelId[]) => Promise<void>;
  protected hostname: string;
  protected log: (s: string) => void;

  public get started(): boolean {
    return this._started;
  }

  /**
   * Returns the ChannelState with the given channel id in the current subscription.
   */
  protected findState(channelId: ChannelId): SignedChannelState {
    return this.signedChannelStates[JSON.stringify(channelId)];
  }

  public constructor({
    log,
    signedChannelStates,
    updateCallback,
    refreshCallback,
    hostname,
    isTest,
  }: {
    log: (s: string) => void;
    signedChannelStates: SignedChannelState[];
    updateCallback: (states: DataType[]) => Promise<void>;
    refreshCallback: (invalidChannels: ChannelId[]) => Promise<void>;
    hostname: string;
    isTest: boolean;
  }) {
    this.updateCallback = updateCallback;
    this.refreshCallback = refreshCallback;
    this.hostname = hostname;
    this.log = log;
    this.isTest = isTest;

    this.updateStates(signedChannelStates, false);
  }

  // Resets the clients states before applying a new batch. Required for SFJ subscriptions, as a user may lose access
  // to a channel (via unsharing / unmounting), after which further updates would be irrelevant
  clearAndUpdateStates(signedChannelStates: SignedChannelState[]): void {
    this.ignoreExtraChannels = true;
    this.signedChannelStates = {};
    this.signedChannelStatesKeys = [];
    this.updateStates(signedChannelStates);
  }

  /**
   * Update client's revision by information from another sources, i.e. from AppServer.
   */
  public updateStates(
    signedChannelStates: SignedChannelState[],
    pollOnNewChannels = true,
  ): void {
    // Update the subscription state by information from other sources.
    let addedNewChannel = false;
    for (const newState of signedChannelStates) {
      // Look for the channel id in the current subscription.
      const curState = this.findState(newState.channel_id);

      // Sanity checks.
      if (curState == null) {
        // Add new state.
        const key = JSON.stringify(newState.channel_id);
        this.signedChannelStates[key] = newState;
        this.signedChannelStatesKeys.push(key);
        addedNewChannel = true;
      } else if (
        // We use >= 0 because we want to replace the token even if there isn't a new revision.
        // Otherwise it's impossible to refresh the token if the channel hasn't changed.
        compareRevisions(newState.revision, curState.revision) >= 0
      ) {
        // Bump the revision.
        curState.revision = newState.revision;
        curState.token = newState.token;
      }
    }
    // If we added a new channel, reset our long poll to make sure we're listening to it immediately
    if (pollOnNewChannels && addedNewChannel) {
      this.cancelLongPoll();
      this.handlePollRetrigger();
    }
  }

  /**
   * Start listening for updates.
   */
  public start(): void {
    if (this._started) return;
    this._started = true;

    this.log?.(`Start listening to Bolt notifications.`);

    this.longPoll();
  }

  /**
   * Stop listening for updates.
   */
  public stop(): void {
    if (!this._started) return;
    this._started = false;

    this.log?.(`Stop listening to Bolt notifications.`);

    this.cancelLongPoll();
  }

  protected cancelLongPoll(): void {
    if (this.longPollAbortController) {
      this.longPollAbortController.abort();
      this.longPollAbortController = null;
    }

    if (this.longPollAbortTimerId) {
      clearTimeout(this.longPollAbortTimerId);
      this.longPollAbortTimerId = null;
    }

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  protected async longPoll(): Promise<void> {
    // If we already have a poll queued, don't start another one in the meantime
    if (this.longPollAbortController) {
      return;
    }

    if (
      !this.isTest &&
      typeof window !== 'undefined' &&
      window.document.location.hostname === 'localhost'
    ) {
      logger.debug(
        `${getReadableTime()} [Bolt] Skipping long poll in localhost - it won't ever succeed due to CORS checks`,
      );
      logger.debug(
        `${getReadableTime()} [Bolt] Attempting to long poll with the following channels:`,
        this.signedChannelStates,
      );

      // simulate a 30 second delay
      await sleepMs(30000);
      // simulate a successful poll and retrigger
      this.updateCallback([]);
      this.handlePollRetrigger();
      return;
    }

    const startMillis = Date.now();
    try {
      this.log?.('Calling bolt long poll to ' + this.getSubscribeUrl());
      this.longPollAbortController = new AbortController();
      // As part of the migration to Manifest v3, execution of
      // setInterval/setTimeout is *not guaranteed* in service worker
      // Consider replacing with Alarms API; See src/background/alarm_manager
      /* eslint-disable-next-line no-restricted-syntax */
      this.longPollAbortTimerId = setTimeout(
        () => this.longPollAbortController?.abort(),
        120000,
      );

      const response = await fetch(this.getSubscribeUrl(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          ...this.additionalHeaders,
        },
        body: JSON.stringify({
          channel_states: this.signedChannelStatesKeys.map(
            (k) => this.signedChannelStates[k],
          ),
        }),
        credentials: 'include',
        signal: this.longPollAbortController?.signal,
      });

      this.longPollAbortController = null;

      if (this.longPollAbortTimerId) {
        clearTimeout(this.longPollAbortTimerId);
        this.longPollAbortTimerId = null;
      }

      // The body json can only be read once.
      const body = (await this.parseBodyAsJson(
        response,
      )) as LongPollResponseBody<DataType>;

      if (response.ok) {
        this.handlePollSuccess(startMillis, response, body);
      } else {
        this.handlePollError(
          startMillis,
          `${response.statusText} (${response.status})`,
        );
      }
    } catch (e) {
      this.longPollAbortController = null;
      // Most likely be a network connectivity error (computer sleeping etc.).
      this.handlePollError(startMillis, `[thrown] [${e}]`);
    }
  }

  protected mustFindState(channelId: ChannelId): SignedChannelState {
    return this.findState(channelId);
  }

  protected abstract handlePollData(
    response: Response,
    body: unknown,
  ): Promise<DataType[]>;

  protected getSubscribeUrl(): string {
    return `https://${this.hostname}${this.getSubscribeEndpoint()}`;
  }

  protected abstract getSubscribeEndpoint(): string;

  protected async parseBodyAsJson(response: Response): Promise<unknown> {
    try {
      return await response.json();
    } catch (e) {
      this.log?.(`Warning: failed to parse response body json:` + e);
      this.log?.('Response:' + JSON.stringify(response));
      return null;
    }
  }

  private async refreshChannels(invalidChannels: ChannelId[]): Promise<void> {
    try {
      this.log?.(
        'Refreshing bolt channels: invalid channels ' +
          invalidChannels.map((c) => c.unique_id).join(', '),
      );
      await this.refreshCallback(invalidChannels);
    } catch (e) {
      this.log?.(`Warning: Failed to refresh bolt data:` + e);
    }
  }

  protected async handlePollSuccess(
    startMillis: number,
    response: Response,
    body: LongPollResponseBody<DataType>,
  ): Promise<void> {
    const timeTaken = Date.now() - startMillis;
    this.log?.(`Bolt long poll succeeded (${timeTaken.toLocaleString()}ms)`);

    if (!this._started) {
      return;
    }

    try {
      const updates = await this.handlePollData(response, body);
      if (updates.length > 0) {
        // As part of the migration to Manifest v3, execution of setInterval/setTimeout is *not guaranteed* in service worker
        // Consider replacing with Alarms API; See src/background/alarm_manager
        /* eslint-disable-next-line no-restricted-syntax */
        setTimeout(
          () =>
            this.updateCallback(updates).catch((e) => {
              this.log?.('Error from bolt updateCallback' + e);
            }),
          1,
        );
      }

      if ('invalid_channels' in body) {
        if (body.invalid_channels.length > 0) {
          this.refreshChannels(body.invalid_channels);
          return;
        }
      }
    } catch (e) {
      this.log?.(`Failed to handle poll data:` + e);
    }

    this.handlePollRetrigger();
  }

  /**
   * Handle triggering of a new poll with retry backoff throttling.
   */
  protected handlePollRetrigger(): void {
    // If enabled, prevent re-subscribing more frequently than requested
    let successDelayMs = 0;
    const now = Date.now();
    const msSinceLastSuccess = now - this.successLastTimestamp;
    if (msSinceLastSuccess < this.successMinimumDelayMs) {
      successDelayMs = this.successMinimumDelayMs - msSinceLastSuccess;
    }
    this.successLastTimestamp = now;

    this.backoff.reset();
    // As part of the migration to Manifest v3, execution of setInterval/setTimeout is *not guaranteed* in service worker
    // Consider replacing with Alarms API; See src/background/alarm_manager
    /* eslint-disable-next-line no-restricted-syntax */
    this.timeoutId = setTimeout(() => this.longPoll(), successDelayMs); // re-poll
  }

  protected handlePollError(startMillis: number, error: string): void {
    const timeTaken = Date.now() - startMillis;
    this.backoff.stepUp();

    this.log?.(
      `Bolt long poll failed (${timeTaken.toLocaleString()}ms): error=${error}; retrying after ${
        this.backoff
      }.`,
    );

    if (!this._started) {
      return;
    }

    // Retry after backoff.
    // As part of the migration to Manifest v3, execution of setInterval/setTimeout is *not guaranteed* in service worker
    // Consider replacing with Alarms API; See src/background/alarm_manager
    /* eslint-disable-next-line no-restricted-syntax */
    this.timeoutId = setTimeout(() => this.longPoll(), this.backoff.millis);
  }

  // If greater than 0, prevents the long-poll from re-subscribing more frequently than once every X seconds
  public setSuccessMinimumDelay(
    minimumDelaySeconds: number = MIN_POLL_INTERVAL_MS / 1000,
  ): void {
    this.successMinimumDelayMs = minimumDelaySeconds * 1000;
    this.successLastTimestamp = Date.now();
  }
}

// An instance of BoltClient can be used to subscribe for notifications to a set of channels.
export class BoltSignalClient extends BaseClient<SignedChannelState> {
  public constructor({
    log,
    boltOrigin,
    signedChannelStates,
    updateCallback,
    refreshCallback,
    isTest,
  }: {
    log: (s: string) => void;
    boltOrigin: string;
    signedChannelStates: SignedChannelState[];
    updateCallback: (states: SignedChannelState[]) => Promise<void>;
    refreshCallback: (invalidChannels: ChannelId[]) => Promise<void>;
    isTest: boolean;
  }) {
    super({
      log,
      signedChannelStates,
      updateCallback,
      refreshCallback,
      hostname: new URL(boltOrigin).hostname,
      isTest,
    });
  }

  protected getSubscribeEndpoint() {
    return NOTIFICATION_SUBSCRIBE_ENDPOINT;
  }

  protected async handlePollData(
    response: Response,
    body: LongPollChannelStates<SignedChannelState>,
  ): Promise<SignedChannelState[]> {
    const updates: SignedChannelState[] = [];

    // Update the subscription state for the next poll.
    if (body.channel_states != null) {
      for (const newstate of body.channel_states) {
        if (this.ignoreExtraChannels && !this.findState(newstate.channel_id)) {
          continue;
        }

        const matched_state = this.mustFindState(newstate.channel_id);
        if (compareRevisions(newstate.revision, matched_state.revision) > 0) {
          // Bump the revision.
          matched_state.revision = newstate.revision;
          matched_state.token = newstate.token;
          updates.push(newstate);
        }
      }
    }

    return updates;
  }
}

// /**
//  * An instance of BoltPayloadClient (aka "ThunderClient") can be used to subscribe to
//  * receive payloads from a set of channels.
//  *
//  * This isn't needed at this time, but may be useful in the future.
//  * Keeping it commented out to avoid needing to incorporate tests for it.
//  */
// export class BoltPayloadClient extends BaseClient<ChannelPayloads> {
//   public stop(): void {
//     delete this.additionalHeaders[BOLT_SESSION_HEADER];
//     return super.stop();
//   }

//   protected getSubscribeEndpoint(): string {
//     return PAYLOAD_SUBSCRIBE_ENDPOINT;
//   }

//   protected async handlePollData(
//     response: Response,
//     body: LongPollChannelStates<ChannelPayloads>,
//   ): Promise<ChannelPayloads[]> {
//     const updates: ChannelPayloads[] = [];
//     // Always respond using the same bolt session header that was received.
//     this.additionalHeaders = {};

//     const sessionHeader = response.headers.get(BOLT_SESSION_HEADER);
//     if (sessionHeader) {
//       this.additionalHeaders[BOLT_SESSION_HEADER] = sessionHeader;
//     }

//     // Update the subscription state for the next poll and collect payloads.
//     if ("channel_payloads" in body) {
//       const channelPayloadsArray = body.channel_payloads as ChannelPayloads[];
//       if (channelPayloadsArray) {
//         for (const channelPayloads of channelPayloadsArray) {
//           const newState = channelPayloads.channel_state;
//           const curState = this.mustFindState(newState.channel_id);

//           // Skip payloads client is already aware of.
//           const payloads = channelPayloads.payloads.filter(
//             (p) => compareRevisions(p.revision, curState.revision) > 0,
//           );

//           if (payloads.length > 0) {
//             updates.push({
//               channel_state: channelPayloads.channel_state,
//               payloads,
//             });
//           }

//           if (compareRevisions(newState.revision, curState.revision) > 0) {
//             // Bump the revision.
//             curState.revision = newState.revision;
//             curState.token = newState.token;
//           }
//         }
//       }
//     }

//     return updates;
//   }
// }
