import { Avatar } from '@dropbox/dig-components/avatar';
import { Split } from '@dropbox/dig-foundations';
import { getInitialsFromPerson } from '@mirage/shared/account';
import { AuthorInfo } from '@mirage/shared/search/search-result';
import i18n, { I18nKey } from '@mirage/translations';

export const PersonMetadata = ({
  i18nKey,
  person,
}: {
  i18nKey: I18nKey;
  person: AuthorInfo | undefined | null;
}) => {
  if (!person) {
    return null;
  }

  const displayName = person?.displayName || person?.email;
  const initials = getInitialsFromPerson({
    displayName: person?.displayName,
    email: person?.email || '',
  });

  return (
    <Split gap="Micro XSmall" direction="horizontal" alignY="center">
      <Split.Item>
        <Avatar
          size="xsmall"
          hasNoOutline
          src={person?.profilePhotoUrl}
          alt={initials}
        >
          {initials}
        </Avatar>
      </Split.Item>
      <Split.Item>
        <span>
          {i18n.t(i18nKey)}
          &nbsp;
          {displayName}
        </span>
      </Split.Item>
    </Split>
  );
};
