// ReactDOM.render is deprecated in react@18.0.0, but we are using react@17.0.2
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom';
import { SnackbarView } from './SnackbarView';
import { SnackbarProps } from './types';

function getOrCreateRoot() {
  const rootId = 'snackbarRoot';
  const root = document.getElementById(rootId);
  if (root) return root;

  const div = document.createElement('div');
  div.id = rootId;

  document.body.appendChild(div);
  return div;
}

type ShowSnackBarArgs = SnackbarProps & {
  hideCloseButton?: boolean;
};
export function showSnackbar({
  title,
  accessory,
  timeoutMs,
  parentElement,
  buttons,
  showSpinner,
  showProgressBar,
  onDismiss,
  marginBottom = 0,
  hideCloseButton,
}: ShowSnackBarArgs) {
  const parent = parentElement ?? getOrCreateRoot();
  const onClose = hideCloseButton ? undefined : () => hideSnackbar(parent);

  render(
    <SnackbarView
      title={title}
      accessory={accessory}
      onClose={onClose}
      timeoutMs={timeoutMs}
      buttons={buttons}
      showSpinner={showSpinner}
      showProgressBar={showProgressBar}
      onDismiss={onDismiss}
      marginBottom={marginBottom}
    />,
    parent,
  );
}

export function hideSnackbar(parentElement?: HTMLElement) {
  const parent = parentElement ?? getOrCreateRoot();
  render(<></>, parent);
}
