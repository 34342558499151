export const AddConnectorsSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 272 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_2807_21568)">
      <rect x="16" y="16" width="48" height="48" rx="8" fill="white" />
      <path
        d="M49.374 47.9597L44.0287 42.6143C44.934 41.25 45.3902 39.6367 45.3333 38.0003C45.3333 33.4083 42.592 30.667 38 30.667C33.408 30.667 30.6667 33.4083 30.6667 38.0003C30.6667 42.5923 33.408 45.3337 38 45.3337C39.6366 45.3906 41.2501 44.9345 42.6147 44.029L47.96 49.3743L49.374 47.9597ZM32.6667 38.0003C32.6667 34.5117 34.5113 32.667 38 32.667C41.4887 32.667 43.3333 34.5117 43.3333 38.0003C43.3333 41.489 41.4887 43.3337 38 43.3337C34.5113 43.3337 32.6667 41.489 32.6667 38.0003Z"
        fill="#1A1918"
      />
    </g>
    <g filter="url(#filter1_d_2807_21568)">
      <rect x="72" y="20" width="40" height="40" rx="8" fill="white" />
      <path
        d="M86.5902 46.7502C84.9045 46.7502 83.3998 45.9916 82.4128 44.8039L93.6114 40.1045L102.319 45.0071C101.552 46.0085 100.393 46.7502 99.0821 46.7443C97.1055 46.7502 89.5002 46.7502 86.5902 46.7502Z"
        fill="url(#paint0_linear_2807_21568)"
      />
      <path
        d="M98.792 38.0293L93.6114 40.1045L102.319 45.0072C102.877 44.2797 103.207 43.3757 103.207 42.3962C103.207 40.003 101.235 38.0606 98.7953 38.0422C98.7942 38.0379 98.7931 38.0336 98.792 38.0293Z"
        fill="url(#paint1_linear_2807_21568)"
      />
      <path
        d="M86.0647 36.2332L93.6119 40.1041L98.7925 38.0289C98.055 35.1399 95.3866 33 92.2076 33C89.7426 33 87.5845 34.2868 86.3957 36.2117C86.2844 36.2156 86.1741 36.2228 86.0647 36.2332Z"
        fill="url(#paint2_linear_2807_21568)"
      />
      <path
        d="M82.4125 44.8038L93.6111 40.1043L86.064 36.2334C83.3379 36.4923 81.207 38.7421 81.207 41.4793C81.207 42.7397 81.6588 43.8968 82.4125 44.8038Z"
        fill="url(#paint3_linear_2807_21568)"
      />
    </g>
    <g filter="url(#filter2_d_2807_21568)">
      <rect x="120" y="20" width="40" height="40" rx="8" fill="white" />
      <g clipPath="url(#clip0_2807_21568)">
        <path
          d="M147.841 30H132.577C131.705 30 130.998 30.7066 130.998 31.5789L131.029 41.5789H149.419V31.5789C149.42 30.7066 148.713 30 147.841 30Z"
          fill="#C2C2C2"
        />
        <rect
          x="128.432"
          y="32.1055"
          width="23.5531"
          height="11.5891"
          fill="url(#pattern0_2807_21568)"
        />
        <path
          d="M148.893 33.1582H131.525C130.652 33.1582 129.946 33.8648 129.946 34.7372C129.946 34.8108 129.951 34.8832 129.96 34.9529L130.998 41.5793H149.419L150.458 34.9529C150.467 34.8819 150.472 34.8095 150.472 34.7372C150.472 33.8648 149.766 33.1582 148.893 33.1582Z"
          fill="#3367D6"
        />
        <path
          opacity="0.5"
          d="M148.893 33.1582H131.525C130.652 33.1582 129.946 33.8648 129.946 34.7372C129.946 34.8108 129.951 34.8832 129.96 34.9529L130.998 41.5793H149.419L150.458 34.9529C150.467 34.8819 150.472 34.8095 150.472 34.7372C150.472 33.8648 149.766 33.1582 148.893 33.1582Z"
          fill="#2A56C6"
        />
        <path
          d="M149.942 48.312L149.419 41.5791H130.998L130.476 48.312C130.473 48.3488 130.472 48.3844 130.472 48.4212C130.472 49.2936 131.179 50.0002 132.051 50.0002H148.367C149.239 50.0002 149.946 49.2936 149.946 48.4212C149.946 48.3844 149.945 48.3488 149.942 48.312Z"
          fill="#4285F4"
        />
        <path
          d="M130.998 41.5791L139.419 50.0002H149.946V41.5791H130.998Z"
          fill="url(#paint4_linear_2807_21568)"
        />
        <path
          d="M131.525 33.2898H148.893C149.747 33.2898 150.442 33.9687 150.47 34.8161C150.471 34.7898 150.472 34.7635 150.472 34.7371C150.472 33.8648 149.766 33.1582 148.893 33.1582H131.525C130.652 33.1582 129.946 33.8648 129.946 34.7372C129.946 34.7635 129.947 34.7898 129.948 34.8161C129.976 33.9687 130.671 33.2898 131.525 33.2898Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M148.367 49.8681H132.051C131.197 49.8681 130.502 49.1892 130.475 48.3418C130.473 48.3681 130.472 48.3944 130.472 48.4207C130.472 49.2931 131.179 49.9997 132.051 49.9997H148.367C149.239 49.9997 149.946 49.2931 149.946 48.4207C149.946 48.3944 149.944 48.3681 149.943 48.3418C149.916 49.1892 149.221 49.8681 148.367 49.8681Z"
          fill="#1A237E"
          fillOpacity="0.2"
        />
        <path
          d="M147.841 30H132.577C131.705 30 130.998 30.7066 130.998 31.5789V31.704C131.002 30.8355 131.708 30.1316 132.577 30.1316H147.841C148.713 30.1316 149.42 30.8382 149.42 31.7105V31.5789C149.42 30.7066 148.713 30 147.841 30Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M138.664 41.466C139.263 41.2186 140.034 40.5607 140.034 39.5699C140.034 38.0436 139.054 37.1055 137.402 37.1055C135.931 37.1055 134.746 38.0686 134.725 39.4739H135.793C135.809 38.6528 136.364 37.9779 137.401 37.9779C138.4 37.9779 138.963 38.4976 138.963 39.5463C138.963 40.595 138.16 41.0792 137.181 41.0792C136.797 41.0792 136.435 41.0792 136.435 41.0792V41.916C136.435 41.916 136.848 41.916 137.181 41.916C138.621 41.916 139.094 42.6634 139.094 43.5305C139.094 44.6647 138.405 45.1884 137.406 45.1884C136.537 45.1884 135.745 44.7239 135.721 43.6858H134.645C134.691 45.3581 136.098 46.0542 137.406 46.0542C138.917 46.0542 140.163 45.1739 140.163 43.5068C140.166 42.162 139.208 41.6252 138.664 41.466ZM144.772 37.1055L141.656 38.2239V39.2292L143.893 38.4818V46.0528H144.946V37.1055H144.772Z"
          fill="#E1E1E1"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M138.664 41.466C139.263 41.2186 140.034 40.5607 140.034 39.5699C140.034 38.0436 139.054 37.1055 137.402 37.1055C135.931 37.1055 134.746 38.0686 134.725 39.4739H135.793C135.809 38.6528 136.364 37.9779 137.401 37.9779C138.4 37.9779 138.963 38.4976 138.963 39.5463C138.963 40.595 138.16 41.0792 137.181 41.0792C136.797 41.0792 136.435 41.0792 136.435 41.0792V41.916C136.435 41.916 136.848 41.916 137.181 41.916C138.621 41.916 139.094 42.6634 139.094 43.5305C139.094 44.6647 138.405 45.1884 137.406 45.1884C136.537 45.1884 135.745 44.7239 135.721 43.6858H134.645C134.691 45.3581 136.098 46.0542 137.406 46.0542C138.917 46.0542 140.163 45.1739 140.163 43.5068C140.166 42.162 139.208 41.6252 138.664 41.466ZM144.772 37.1055L141.656 38.2239V39.2292L143.893 38.4818V46.0528H144.946V37.1055H144.772Z"
            fill="#C2C2C2"
          />
        </g>
        <path
          d="M138.664 41.466C139.263 41.2186 140.034 40.5607 140.034 39.5699C140.034 38.0436 139.054 37.1055 137.402 37.1055C135.931 37.1055 134.746 38.0686 134.725 39.4739H135.793C135.809 38.6528 136.364 37.9779 137.401 37.9779C138.4 37.9779 138.963 38.4976 138.963 39.5463C138.963 40.595 138.16 41.0792 137.181 41.0792C136.797 41.0792 136.435 41.0792 136.435 41.0792V41.916C136.435 41.916 136.848 41.916 137.181 41.916C138.621 41.916 139.094 42.6634 139.094 43.5305C139.094 44.6647 138.405 45.1884 137.406 45.1884C136.537 45.1884 135.745 44.7239 135.721 43.6858H134.645C134.691 45.3581 136.098 46.0542 137.406 46.0542C138.917 46.0542 140.163 45.1739 140.163 43.5068C140.166 42.162 139.208 41.6252 138.664 41.466ZM144.772 37.1055L141.656 38.2239V39.2292L143.893 38.4818V46.0528H144.946V37.1055H144.772Z"
          fill="#EEEEEE"
        />
        <path
          d="M135.671 33.1573L134.651 32.1363L135.767 31.0205L137.905 33.1573H135.671Z"
          fill="url(#paint5_linear_2807_21568)"
        />
        <path
          d="M135.209 32.368C135.645 32.368 135.998 32.0146 135.998 31.5785C135.998 31.1425 135.645 30.7891 135.209 30.7891C134.773 30.7891 134.419 31.1425 134.419 31.5785C134.419 32.0146 134.773 32.368 135.209 32.368Z"
          fill="#EEEEEE"
        />
        <path
          d="M135.209 30.9216C135.623 30.9216 135.962 31.24 135.994 31.6453C135.997 31.6229 135.998 31.6019 135.998 31.5795C135.998 31.144 135.644 30.79 135.209 30.79C134.773 30.79 134.419 31.144 134.419 31.5795C134.419 31.6019 134.421 31.6229 134.423 31.6453C134.456 31.24 134.794 30.9216 135.209 30.9216Z"
          fill="white"
          fillOpacity="0.4"
        />
        <path
          d="M135.994 31.6445C135.962 32.0498 135.622 32.3682 135.209 32.3682C134.796 32.3682 134.456 32.0498 134.423 31.6445C134.421 31.6669 134.419 31.6879 134.419 31.7103C134.419 32.1458 134.773 32.4998 135.209 32.4998C135.644 32.4998 135.998 32.1458 135.998 31.7103C135.998 31.6879 135.997 31.6669 135.994 31.6445Z"
          fill="#212121"
          fillOpacity="0.1"
        />
        <path
          d="M149.551 41.5791H130.867V41.7107H149.551V41.5791Z"
          fill="white"
          fillOpacity="0.05"
        />
        <path
          d="M149.551 41.4473H130.867V41.5788H149.551V41.4473Z"
          fill="#1A237E"
          fillOpacity="0.05"
        />
        <path
          d="M145.671 33.1573L144.651 32.1363L145.767 31.0205L147.379 33.1573H145.671Z"
          fill="url(#paint6_linear_2807_21568)"
        />
        <path
          d="M145.21 32.368C145.646 32.368 145.999 32.0146 145.999 31.5785C145.999 31.1425 145.646 30.7891 145.21 30.7891C144.774 30.7891 144.42 31.1425 144.42 31.5785C144.42 32.0146 144.774 32.368 145.21 32.368Z"
          fill="#EEEEEE"
        />
        <path
          d="M145.21 30.9216C145.624 30.9216 145.963 31.24 145.995 31.6453C145.997 31.6229 145.999 31.6019 145.999 31.5795C145.999 31.144 145.645 30.79 145.21 30.79C144.774 30.79 144.42 31.144 144.42 31.5795C144.42 31.6019 144.422 31.6229 144.424 31.6453C144.457 31.24 144.795 30.9216 145.21 30.9216Z"
          fill="white"
          fillOpacity="0.4"
        />
        <path
          d="M145.994 31.6445C145.962 32.0498 145.622 32.3682 145.209 32.3682C144.796 32.3682 144.456 32.0498 144.423 31.6445C144.422 31.6669 144.419 31.6879 144.419 31.7103C144.419 32.1458 144.773 32.4998 145.209 32.4998C145.644 32.4998 145.998 32.1458 145.998 31.7103C145.998 31.6879 145.997 31.6669 145.994 31.6445Z"
          fill="#212121"
          fillOpacity="0.1"
        />
        <path
          d="M150.472 34.7369C150.472 34.05 150.033 33.4671 149.42 33.25V31.5789C149.42 30.7066 148.713 30 147.841 30H132.577C131.705 30 130.998 30.7066 130.998 31.5789L131.002 33.2474C130.388 33.4632 129.946 34.0474 129.946 34.7369C129.946 34.8105 129.951 34.8829 129.96 34.9526L130.998 41.5789L130.476 48.3118C130.473 48.3487 130.472 48.3842 130.472 48.4211C130.472 49.2934 131.179 50 132.051 50H148.367C149.239 50 149.946 49.2934 149.946 48.4211C149.946 48.3842 149.944 48.3487 149.942 48.3118L149.419 41.5789L150.458 34.9526C150.467 34.8829 150.472 34.8105 150.472 34.7369Z"
          fill="url(#paint7_radial_2807_21568)"
          fillOpacity="0.05"
        />
      </g>
    </g>
    <g filter="url(#filter3_d_2807_21568)">
      <rect x="168" y="20" width="40" height="40" rx="8" fill="white" />
      <path
        d="M193.445 47.2379H196.778C197.568 47.2379 198.207 46.6117 198.207 45.8385V35.8096L193.445 39.3081V47.2379Z"
        fill="#34A853"
      />
      <path
        d="M179.636 47.2379H182.969V39.3081L178.207 35.8096V45.8385C178.207 46.6117 178.846 47.2379 179.636 47.2379Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.969 39.6191V33.4287L188.207 37.2382L193.445 33.4287V39.6191L188.207 43.4285L182.969 39.6191Z"
        fill="#EA4335"
      />
      <path
        d="M193.445 33.4317V39.6189L198.207 36.0494V34.1456C198.207 32.381 196.192 31.3732 194.779 32.4322L193.445 33.4317Z"
        fill="#FBBC04"
      />
      <path
        d="M178.207 34.1456V36.0494L182.969 39.6189V33.4317L181.636 32.4322C180.222 31.3732 178.207 32.381 178.207 34.1456Z"
        fill="#C5221F"
      />
    </g>
    <g filter="url(#filter4_d_2807_21568)">
      <rect x="216" y="20" width="40" height="40" rx="8" fill="white" />
      <path
        d="M232.25 32.6667C232.25 31.7462 232.996 31 233.917 31H243.083C244.004 31 244.75 31.7462 244.75 32.6667V46.8333C244.75 47.7538 244.004 48.5 243.083 48.5H233.917C232.996 48.5 232.25 47.7538 232.25 46.8333V32.6667Z"
        fill="#1066B5"
      />
      <path
        d="M232.25 32.6667C232.25 31.7462 232.996 31 233.917 31H243.083C244.004 31 244.75 31.7462 244.75 32.6667V46.8333C244.75 47.7538 244.004 48.5 243.083 48.5H233.917C232.996 48.5 232.25 47.7538 232.25 46.8333V32.6667Z"
        fill="url(#paint8_linear_2807_21568)"
      />
      <rect x="238.5" y="39.333" width="6.25" height="6.66667" fill="#32A9E7" />
      <rect
        x="232.25"
        y="39.333"
        width="6.25"
        height="6.66667"
        fill="#167EB4"
      />
      <path
        d="M245.442 38.9163C245.802 39.1979 245.95 39.783 245.95 39.783L244.75 40.4497V38.5518C244.841 38.5518 245.081 38.6348 245.442 38.9163Z"
        fill="#135298"
      />
      <path
        d="M245.583 47.9332C246 47.3665 246 46.8332 246 46.8332V40.1665C246 40.1665 246 39.8332 245.95 39.7832C245.906 39.8123 245.908 39.8011 245.908 39.8011L231.417 47.9165C231.417 47.9165 231.417 47.9998 231.833 48.2832C232.267 48.4998 232.667 48.4998 232.667 48.4998H244.333C244.333 48.4998 245.117 48.5082 245.583 47.9332Z"
        fill="url(#paint9_linear_2807_21568)"
      />
      <path
        d="M231.392 47.9167C230.999 47.3673 231 46.8333 231 46.8333V39.75L245.6 47.9167C245.6 47.9167 245.441 48.1271 245.167 48.275C244.75 48.5 244.333 48.5 244.333 48.5H232.667C232.667 48.5 231.775 48.4417 231.392 47.9167Z"
        fill="url(#paint10_linear_2807_21568)"
      />
      <rect x="238.5" y="32.667" width="6.25" height="6.66667" fill="#58D9FD" />
      <rect
        x="232.25"
        y="32.667"
        width="6.25"
        height="6.66667"
        fill="#32A9E7"
      />
      <path
        d="M231 37.875C231 36.4943 232.119 35.375 233.5 35.375H236C237.381 35.375 238.5 36.4943 238.5 37.875V44.125C238.5 45.5057 237.381 46.625 236 46.625H231V37.875Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M226 35.9997C226 35.0792 226.746 34.333 227.667 34.333H236C236.92 34.333 237.667 35.0792 237.667 35.9997V44.333C237.667 45.2535 236.92 45.9997 236 45.9997H227.667C226.746 45.9997 226 45.2535 226 44.333V35.9997Z"
        fill="url(#paint11_linear_2807_21568)"
      />
      <path
        d="M234.75 39.7933V39.6894C234.75 37.8889 233.455 36.625 231.635 36.625C229.805 36.625 228.5 37.8975 228.5 39.7067V39.8106C228.5 41.6111 229.795 42.875 231.625 42.875C233.445 42.875 234.75 41.6025 234.75 39.7933ZM233.277 39.8106C233.277 41.0052 232.604 41.7237 231.635 41.7237C230.666 41.7237 229.983 40.9879 229.983 39.7933V39.6894C229.983 38.4948 230.656 37.7763 231.625 37.7763C232.584 37.7763 233.277 38.5121 233.277 39.7067V39.8106Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2807_21568"
        x="4"
        y="8"
        width="72"
        height="72"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.102 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2807_21568"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2807_21568"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_2807_21568"
        x="60"
        y="12"
        width="64"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.102 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2807_21568"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2807_21568"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_2807_21568"
        x="108"
        y="12"
        width="64"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.102 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2807_21568"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2807_21568"
          result="shape"
        />
      </filter>
      <pattern
        id="pattern0_2807_21568"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use />
      </pattern>
      <filter
        id="filter3_d_2807_21568"
        x="156"
        y="12"
        width="64"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.102 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2807_21568"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2807_21568"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d_2807_21568"
        x="204"
        y="12"
        width="64"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.102 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2807_21568"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2807_21568"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2807_21568"
        x1="84.2498"
        y1="45.8336"
        x2="99.9281"
        y2="44.8777"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2086B8" />
        <stop offset="1" stopColor="#46D3F6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2807_21568"
        x1="97.5901"
        y1="42.3961"
        x2="101.977"
        y2="39.3309"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1694DB" />
        <stop offset="1" stopColor="#62C3FE" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2807_21568"
        x1="87.0588"
        y1="33.9167"
        x2="97.2496"
        y2="39.8301"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0D3D78" />
        <stop offset="1" stopColor="#063B83" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2807_21568"
        x1="80.973"
        y1="42.6252"
        x2="91.2193"
        y2="38.82"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#16589B" />
        <stop offset="1" stopColor="#1464B7" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2807_21568"
        x1="135.777"
        y1="36.8858"
        x2="144.404"
        y2="45.5129"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A237E" stopOpacity="0.2" />
        <stop offset="1" stopColor="#1A237E" stopOpacity="0.02" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2807_21568"
        x1="135.196"
        y1="31.5678"
        x2="136.219"
        y2="32.5914"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#212121" stopOpacity="0.2" />
        <stop offset="1" stopColor="#212121" stopOpacity="0.02" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2807_21568"
        x1="145.196"
        y1="31.5693"
        x2="146.093"
        y2="32.4669"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#212121" stopOpacity="0.2" />
        <stop offset="1" stopColor="#212121" stopOpacity="0.02" />
      </linearGradient>
      <radialGradient
        id="paint7_radial_2807_21568"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(131.355 30.4419) scale(26.3479 26.3479)"
      >
        <stop offset="0.33" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint8_linear_2807_21568"
        x1="232.25"
        y1="39.75"
        x2="244.75"
        y2="39.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#064484" />
        <stop offset="1" stopColor="#0F65B5" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_2807_21568"
        x1="246"
        y1="44.1248"
        x2="231"
        y2="44.1248"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#44DCFD" />
        <stop offset="0.453125" stopColor="#259ED0" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_2807_21568"
        x1="231"
        y1="44.125"
        x2="246"
        y2="44.125"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#259ED0" />
        <stop offset="1" stopColor="#44DCFD" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_2807_21568"
        x1="226"
        y1="40.1663"
        x2="237.667"
        y2="40.1663"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#064484" />
        <stop offset="1" stopColor="#0F65B5" />
      </linearGradient>
      <clipPath id="clip0_2807_21568">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(128.207 28)"
        />
      </clipPath>
      <image id="image0_2807_21568" />
    </defs>
  </svg>
);
