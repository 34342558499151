import { GrowthBook } from '@growthbook/growthbook';
import {
  ExperimentSource,
  FeatureFlag,
} from '@mirage/service-experimentation/features';
import { tagged } from '@mirage/service-logging';

import type {
  ExperimentationAttributes,
  ExperimentationServiceAdapter,
} from '@mirage/service-experimentation/service';

const logger = tagged('service-experimentation/growthbook');

type GrowthbookConfig = {
  clientKey: string;
  attributes: ExperimentationAttributes;
  enableDevMode: boolean;
};

class GrowthBookImpl implements ExperimentationServiceAdapter {
  private growthBook: GrowthBook;
  public source: ExperimentSource = 'growthbook';
  private refreshPromise: Promise<void> | null = null;

  constructor({ clientKey, attributes, enableDevMode }: GrowthbookConfig) {
    const gb = new GrowthBook({
      apiHost: 'https://cdn.dropboxexperiment.com',
      clientKey,

      // Enable growthbook devtools extension in dev mode
      enableDevMode,
    });
    logger.debug('set initial growthbook attributes', attributes);
    gb.setAttributes(attributes); // Set initial attributes
    gb.loadFeatures();
    this.growthBook = gb;
  }

  // Refreshes the feature flags from the GrowthBook service.
  public async refreshFeatures() {
    if (!this.refreshPromise) {
      this.refreshPromise = this.growthBook.refreshFeatures({
        skipCache: true,
      });
    }

    try {
      return await this.refreshPromise;
    } finally {
      this.refreshPromise = null;
    }
  }

  public async fetchFeatureValues(
    features: FeatureFlag[],
  ): Promise<FeatureFlag[]> {
    await this.refreshFeatures();

    return features.map((feature) => ({
      ...feature,
      value: this.growthBook.getFeatureValue(feature.featureName, undefined),
    }));
  }

  public setAttributes(attributes: ExperimentationAttributes) {
    this.growthBook.setAttributes({
      ...this.growthBook.getAttributes(),
      ...attributes,
    });
    logger.debug(
      'update growthbook attributes: plus',
      attributes,
      ', all:',
      this.growthBook.getAttributes(),
    );
  }
}

export const GrowthBookAdapter = (config: GrowthbookConfig): GrowthBookImpl =>
  new GrowthBookImpl(config);

export default GrowthBookAdapter;
