import { DropboxResponseError, files } from '@dropbox/api-v2-client';
import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { getCurrentAccount } from '@mirage/service-auth';
import { extractPaperFilenameFromPath, fullPaperPath } from '../utils';

import type { APIv2Callable } from '@mirage/service-dbx-api/service';

export type Service = ReturnType<typeof provideCloudDocsService>;

export type CloudDocTypeArgs = 'paper';

export type CloudDocContentsArgs = {
  import_format: files.ImportFormat;
  contents: string;
};

const DEFAULT_PAPER_PATH = '/Paper Docs';

interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

export default function provideCloudDocsService({
  callApiV2,
}: DbxApiServiceContract) {
  async function createDoc(
    docType: CloudDocTypeArgs,
    filename: string,
    contents?: CloudDocContentsArgs,
  ): Promise<{ url: string; filename: string }> {
    switch (docType) {
      case 'paper':
        return createPaperDoc(filename, contents);
      default:
        // Check that we have covered all values, but don't throw error.
        return docType satisfies never;
    }
  }

  async function createPaperDoc(
    filename: string,
    contents?: CloudDocContentsArgs,
  ): Promise<{ url: string; filename: string }> {
    await ensurePaperDocsFolderExists();
    const path = fullPaperPath(DEFAULT_PAPER_PATH, filename);
    const args: files.PaperCreateArg = contents
      ? {
          import_format: contents.import_format,
          path,
          contents: contents?.contents,
        }
      : {
          import_format: { '.tag': 'html' },
          path,
        };
    const createResponse = await callApiV2('filesPaperCreate', args);
    return {
      url: createResponse.url,
      filename: extractPaperFilenameFromPath(createResponse.result_path),
    };
  }

  async function ensurePaperDocsFolderExists(): Promise<void> {
    const account = await getCurrentAccount();
    try {
      await callApiV2('filesGetFileInfo', {
        file: {
          '.tag': 'fq_path',
          fq_path: account?.home_path + DEFAULT_PAPER_PATH,
        },
      });
    } catch (err) {
      // unsafe cast, but code below has safe checks
      const e = err as DropboxResponseError<files.GetFileInfoError>;
      if (e?.status == 409) {
        // expected that Paper Docs folder doesn't exist
        await callApiV2('filesCreateFolderV2', {
          autorename: false,
          path: DEFAULT_PAPER_PATH,
        });
      } else {
        throw err;
      }
    }
  }

  return services.provide(
    ServiceId.CLOUD_DOCS,
    {
      createDoc,
    },
    [ServiceId.DBX_API],
  );
}
