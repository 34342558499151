import { Card as DigCard } from "@dropbox/dig-components/card";

import { Card as Container } from "./Container";
import { Contents } from "./Contents";
import { Header } from "./Header";

export const Card = Object.assign(Container, {
  Contents,
  Header,
  Link: DigCard.Link,
});

export type { ContentsProps as CardContentsProps } from "./Contents";
