import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';
import { Settings } from './service/types';

import type {
  Service,
  SettingsPartial,
} from '@mirage/service-settings/service';

export type { SettingsPartial } from '@mirage/service-settings/service';
export type { Service };

const service = services.get<Service>(ServiceId.SETTINGS);

export function get<K extends keyof Settings>(key: K): Promise<Settings[K]> {
  return rx.firstValueFrom(service.get(key)) as Promise<Settings[K]>;
}

export function getAll(): Promise<Settings> {
  return rx.firstValueFrom(service.getAll()) as Promise<Settings>;
}

export function set<K extends keyof Settings>(
  key: K,
  value: Settings[K],
): Promise<void> {
  return rx.firstValueFrom(service.set(key, value));
}

export function listen<K extends keyof Settings>(
  keys: K[] | Readonly<K[]>,
): rx.Observable<SettingsPartial<K>> {
  return service.listen(keys);
}

export function clear(): Promise<void> {
  return rx.firstValueFrom(service.clear());
}
