// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2835
import type { PAPEventGeneric } from '../base/event';
import type { DashAvatarOptionType } from '../enums/dash_avatar_option_type';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'click.dash_avatar_option'
// Description: Fires when a user clicks an option from the avatar dropdown menu
// Owner: dash-web
export type Click_DashAvatarOption = PAPEventGeneric<
  'dash',
  'click',
  'dash_avatar_option',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // dash_avatar_option_type
    dashAvatarOptionType?: DashAvatarOptionType;
  }
>;

export function PAP_Click_DashAvatarOption(
  properties?: Click_DashAvatarOption['properties'],
): Click_DashAvatarOption {
  return <Click_DashAvatarOption>{
    class: 'dash',
    action: 'click',
    object: 'dash_avatar_option',
    properties,
  };
}
