import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { tagged } from '@mirage/service-logging';
import Sentry from '@mirage/shared/sentry';
import { useEffect, useRef } from 'react';

const logger = tagged('useReportingIfVisibleForTooLong');

// Report abnormal rendering every 15s the spinner is visible.
const ABNORMAL_RENDERING_INTERVAL_MS = 15_000;

// No need to report after 30s (it will likely spin forever).
const MAX_REPORTING_LATENCY_MS = 30_000;

let spinnerEscapeFunction: (() => void) | undefined;

/**
 * Allow the app to escape a stuck spinner. Otherwise an invalidated refresh
 * token will cause the app to get stuck forever!
 */
export function registerSpinnerEscapeFunction(func: () => void) {
  spinnerEscapeFunction = func;
}

export function useReportingIfVisibleForTooLong(
  spinnerId: string,
  intervalMs = ABNORMAL_RENDERING_INTERVAL_MS,
  escapeMs = MAX_REPORTING_LATENCY_MS,
) {
  const account = useDropboxAccount();
  const startMs = useRef(Date.now());
  const nextThreshold = useRef(intervalMs);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | undefined;

    intervalId = setInterval(() => {
      const elapsedMs = Date.now() - startMs.current;
      if (elapsedMs < nextThreshold.current) {
        return;
      }

      const msg = `Spinner ${spinnerId} visible for over threshold ${nextThreshold.current}ms for user ${account?.uid}`;

      // Log as warning because these are not really actionable.
      Sentry.captureMessage(msg, 'warning');

      // Sentry doesn't log anything to console, so do it manually.
      logger.warn(msg);

      // No need to report anymore, so stop interval.
      if (elapsedMs >= escapeMs) {
        clearInterval(intervalId);
        intervalId = undefined;
        Sentry.captureMessage(
          `Spinner ${spinnerId} caused logout for ${elapsedMs}ms for user`,
          'warning',
        );
        spinnerEscapeFunction?.();
        return;
      }

      nextThreshold.current += intervalMs;
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [account?.uid, escapeMs, intervalMs, spinnerId]);
}
