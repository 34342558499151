// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgSegment({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 3.667C2 2.747 2.746 2 3.667 2h16.666C21.253 2 22 2.746 22 3.667v16.666c0 .92-.746 1.667-1.667 1.667H3.667C2.747 22 2 21.254 2 20.333V3.667z"
        fill="#52BD94"
      />
      <path
        d="M18.642 9.517h-7.997a.27.27 0 00-.276.264v.892a.27.27 0 00.276.263h7.997a.27.27 0 00.276-.263V9.78a.27.27 0 00-.276-.264zM13.352 13.064H5.355a.27.27 0 00-.276.264v.89a.27.27 0 00.276.265h7.997a.27.27 0 00.276-.264v-.891a.27.27 0 00-.276-.264z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.106 10.489a.29.29 0 00.206-.026.268.268 0 00.13-.155 5.638 5.638 0 012.647-3.144 6.063 6.063 0 014.17-.614c.069.014.14.002.2-.033a.263.263 0 00.12-.157l.242-.862a.254.254 0 00-.032-.206.27.27 0 00-.178-.12 7.604 7.604 0 00-5.273.768c-1.594.882-2.782 2.307-3.327 3.99a.252.252 0 00.024.203.267.267 0 00.17.125l.9.23zM17.894 13.511a.29.29 0 00-.206.026.269.269 0 00-.13.155 5.638 5.638 0 01-2.647 3.144 6.062 6.062 0 01-4.17.614.284.284 0 00-.2.034.262.262 0 00-.12.156l-.242.862a.254.254 0 00.032.206.27.27 0 00.178.12 7.604 7.604 0 005.273-.768c1.594-.882 2.782-2.306 3.327-3.99a.251.251 0 00-.024-.203.268.268 0 00-.17-.125l-.9-.23z"
        fill="#fff"
      />
      <path
        d="M16.226 7.96c.449 0 .813-.349.813-.779 0-.43-.364-.778-.814-.778-.449 0-.813.349-.813.778 0 .43.364.778.813.778zM7.774 17.597c.45 0 .813-.348.813-.778 0-.43-.364-.778-.813-.778-.449 0-.813.348-.813.778 0 .43.364.778.813.778z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgSegment;
