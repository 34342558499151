import { getCachedOrFetchFeatureValue } from '@mirage/service-experimentation';
import { tagged } from '@mirage/service-logging';
import { register, unregister } from '@mirage/shared/util/jobs';

const logger = tagged('typeahead/scoring/suggested-query-score');

/**
 * if something happens and we can't load the gb score, we should default to
 * ranking the suggested-query at the top
 */
const DEFAULT_SCORE = Number.POSITIVE_INFINITY;

let score = DEFAULT_SCORE;
/**
 * Returns the score we should give to suggested-query (aka "Search all results")
 * loaded from Growthbook
 */
export function getScore() {
  // add an early return here if you want to try many different values for the
  // score and ignore growthbook
  return score;
}

/**
 * Periodic job
 *
 * NOTE: Growthbook caches feature flags for 15m so in practice score will
 * update every ~20 minutes.
 */
const SYNC_QUERY_SCORE_JOB_NAME =
  'service-typeahead-search/sync-suggested-query-score';
const SYNC_QUERY_SCORE_INTERVAL = 5 * 60 * 1000; // 5 minutes

const SYNC_QUERY_SCORE_FEATURE_FLAG =
  'dash_typeahead_2024_06_05_suggested_query_score';

/**
 * Start the job to sync the suggested-query score
 * This must be called in all apps that use the typeahead!
 */
export function startSyncSuggestedQueryScore() {
  register(
    SYNC_QUERY_SCORE_JOB_NAME,
    SYNC_QUERY_SCORE_INTERVAL,
    true,
    async () => {
      const flagValue = await getCachedOrFetchFeatureValue(
        SYNC_QUERY_SCORE_FEATURE_FLAG,
      );

      // 0 is a valid value, null check this correctly!
      if (flagValue == null) {
        logger.warn(
          `[getSuggestedQueryScoreFromGrowthbook] Couldn't get feature flag value`,
          SYNC_QUERY_SCORE_FEATURE_FLAG,
        );
        return;
      }

      if (typeof flagValue !== 'number') {
        logger.warn(
          `[getSuggestedQueryScoreFromGrowthbook] Feature flag value is not a numeric score`,
          flagValue,
        );
        return;
      }

      if (flagValue < 0 || flagValue > 1) {
        logger.warn(
          `[getSuggestedQueryScoreFromGrowthbook] Feature flag value is not between 0 and 1`,
          flagValue,
        );
        return;
      }

      score = flagValue;
    },
  );
}

export function cancelSyncSuggestedQueryScore() {
  unregister(SYNC_QUERY_SCORE_JOB_NAME);
}
