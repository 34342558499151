import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { FormLabel } from '@dropbox/dig-components/form_row';
import { Select } from '@dropbox/dig-components/text_fields';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box, Split, Stack } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ArrowLeftLine,
  CalendarLine,
  EmojiSunglassesLine,
  EmojiUneasyLine,
  SearchLine,
} from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashJtbdOnboarding } from '@mirage/analytics/events/types/open_dash_jtbd_onboarding';
import { PAP_Select_DashJtbdOnboarding } from '@mirage/analytics/events/types/select_dash_jtbd_onboarding';
import { PAP_Shown_DashJtbdOnboarding } from '@mirage/analytics/events/types/shown_dash_jtbd_onboarding';
import { MeetingsValueSvg } from '@mirage/growth/components/assets/MeetingsValueSvg';
import { NotSureValueSvg } from '@mirage/growth/components/assets/NotSureValueSvg';
import { OrganizeValueSvg } from '@mirage/growth/components/assets/OrganizeValueSvg';
import { SearchValueSvg } from '@mirage/growth/components/assets/SearchValueSvg';
import { OnboardingHeaderWithStepper } from '@mirage/growth/components/OnboardingHeader';
import { UserNameCardChip } from '@mirage/growth/components/UserNameCardChip';
import { getCurrentAccount } from '@mirage/service-auth';
import i18n from '@mirage/translations';
import { RoutePath } from '@mirage/webapp/routeTypes';
import classnames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ONBOARDING_VERSION } from './constants';
import {
  JtbdType,
  jtbdTypeToLabel,
  TypeOfWork,
  typeOfWorkToLabel,
  WorkWith,
  workWithLabel,
} from './JobsToBeDoneConstants';
import JTBDStyles from './JobsToBeDoneSurvey.module.css';
import styles from './Onboarding.module.css';
import { PageContainer, PrivacyFooter } from './OnboardingComponents';
import { SingleStepOnboarding } from './SingleStepOnboarding';

import type { JtbdAnswer } from '@mirage/analytics/events/enums/jtbd_answer';
import type { PageName } from '@mirage/analytics/events/enums/page_name';

enum JTBD_STEP {
  JTBD_STEP_1 = '1-about-yourself',
  JTBD_STEP_2 = '2-challenges',
  JTBD_STEP_3A_SEARCH = '3a-search',
  JTBD_STEP_3B_ORGANIZE = '3b-organization',
  JTBD_STEP_3C_MEETINGS = '3c-meetings',
  JTBD_STEP_3D_NOT_SURE = '3d-not-sure',
  JTBD_STEP_3E_SKIPPED = '3e-skipped',
  JSBD_STEP_4 = '4-add-connector',
}

const TotalSteps = 4; // including add connector step that is not part of this file

const isSupportedStep = (step: string): step is JTBD_STEP => {
  return Object.values(JTBD_STEP).includes(step as JTBD_STEP);
};

export const JobsToBeDoneSurvey = ({
  routeParams: { step } = {},
}: {
  routeParams?: Record<string, string | undefined>;
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (step === undefined || !isSupportedStep(step)) {
      // Redirect to home page if we have unknown step
      navigate(RoutePath.ROOT);
    }
    // TODO: log page view
  }, [step, navigate]);

  if (step === JTBD_STEP.JSBD_STEP_4) {
    // `OnboardingHeaderWithStepper` is rendered inside `SingleStepOnboarding`, but we don't get nice animation
    // between step 3 & 4 since Stepper is rendered from different react tree.
    return (
      <SingleStepOnboarding
        version={ONBOARDING_VERSION.SURVEY_JOBS_TO_BE_DONE}
      />
    );
  }

  return (
    <div>
      <PageContainer>
        <div className={styles.leftPanel}>
          <OnboardingHeaderWithStepper
            totalSteps={TotalSteps}
            currentStep={getStepNumber(step as JTBD_STEP)}
          />
          {step && getLeftPanel(step)}

          <Box flexGrow={1}></Box>
          <PrivacyFooter />
        </div>
        <div className={classnames([styles.rightPanel, JTBDStyles.rightPanel])}>
          {step && getRightPanel(step)}
        </div>
      </PageContainer>
    </div>
  );
};

const getStepNumber = (step: JTBD_STEP): number => {
  switch (step) {
    case JTBD_STEP.JTBD_STEP_1:
      return 1;
    case JTBD_STEP.JTBD_STEP_2:
      return 2;
    case JTBD_STEP.JTBD_STEP_3A_SEARCH:
      return 3;
    case JTBD_STEP.JTBD_STEP_3B_ORGANIZE:
      return 3;
    case JTBD_STEP.JTBD_STEP_3C_MEETINGS:
      return 3;
    case JTBD_STEP.JTBD_STEP_3D_NOT_SURE:
      return 3;
    case JTBD_STEP.JTBD_STEP_3E_SKIPPED:
      return 3;
    case JTBD_STEP.JSBD_STEP_4:
      return 4;
    default:
      throw new Error(`Invalid JTDB step: ${step}`);
  }
};

const getNavigatePathForStep = (step: JTBD_STEP): string => {
  return RoutePath.SETUP_SURVEY.replace(':step', step);
};

const getLeftPanel = (step: string): React.ReactElement => {
  switch (step) {
    case JTBD_STEP.JTBD_STEP_1:
      return <StepOneLeftPanel />;
    case JTBD_STEP.JTBD_STEP_2:
      return <StepTwoLeftPanel />;
    case JTBD_STEP.JTBD_STEP_3A_SEARCH:
      return <StepThreeLeftPanel.Search />;
    case JTBD_STEP.JTBD_STEP_3B_ORGANIZE:
      return <StepThreeLeftPanel.Organize />;
    case JTBD_STEP.JTBD_STEP_3C_MEETINGS:
      return <StepThreeLeftPanel.Meetings />;
    case JTBD_STEP.JTBD_STEP_3D_NOT_SURE:
      return <StepThreeLeftPanel.NotSure />;
    case JTBD_STEP.JTBD_STEP_3E_SKIPPED:
      return <StepThreeLeftPanel.Skipped />;
    default:
      throw new Error(`Invalid JTDB step: ${step}`);
  }
};

const getRightPanel = (step: string): React.ReactElement => {
  switch (step) {
    case JTBD_STEP.JTBD_STEP_1:
      return <NameCardRightPanel />;
    case JTBD_STEP.JTBD_STEP_2:
      return <NameCardRightPanel />;
    case JTBD_STEP.JTBD_STEP_3A_SEARCH:
      return <SearchValueSvg />;
    case JTBD_STEP.JTBD_STEP_3B_ORGANIZE:
      return <OrganizeValueSvg />;
    case JTBD_STEP.JTBD_STEP_3C_MEETINGS:
      return <MeetingsValueSvg />;
    case JTBD_STEP.JTBD_STEP_3D_NOT_SURE:
      return <NotSureValueSvg />;
    case JTBD_STEP.JTBD_STEP_3E_SKIPPED:
      return <NotSureValueSvg />;
    default:
      throw new Error(`Invalid JTDB step: ${step}`);
  }
};

interface TitlesProps {
  title1?: string;
  title2?: string;
}
const Titles: React.FC<TitlesProps> = ({ title1, title2 }: TitlesProps) => {
  return (
    <Stack>
      {title1 && (
        <Title
          weightVariant="emphasized"
          // styles.title for general Onboarding styles, JTBDStyles.title for overriding margins
          className={classnames([styles.title, JTBDStyles.title])}
        >
          {title1}
        </Title>
      )}
      {title2 && (
        <Title
          weightVariant="emphasized"
          className={classnames([styles.title, JTBDStyles.title])}
          color="subtle"
        >
          {title2}
        </Title>
      )}
    </Stack>
  );
};

const StepOneLeftPanel: React.FC = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState<string>('');
  const [selectedTypeOfWork, setSelectedTypeOfWork] = useState<string>('');
  const [selectedWorkWith, setSelectedWorkWith] = useState<string>('');
  const hasAllAnswers = selectedTypeOfWork && selectedWorkWith;

  const { reportPapEvent } = useMirageAnalyticsContext();

  useEffect(() => {
    reportPapEvent(
      PAP_Shown_DashJtbdOnboarding({ pageName: 'tell_us_about_yourself' }),
    );

    getCurrentAccount().then((account) => {
      setFirstName(account?.name?.given_name || '');
    });
  }, [reportPapEvent]);

  const submitAnswerAndContinue = () => {
    reportPapEvent(
      PAP_Select_DashJtbdOnboarding({
        pageName: 'tell_us_about_yourself',
        questionType: 'work_type',
        jtbdAnswer: selectedTypeOfWork as JtbdAnswer,
      }),
    );

    reportPapEvent(
      PAP_Select_DashJtbdOnboarding({
        pageName: 'tell_us_about_yourself',
        questionType: 'collaborate_with',
        jtbdAnswer: selectedWorkWith as JtbdAnswer,
      }),
    );

    reportPapEvent(
      PAP_Open_DashJtbdOnboarding({
        pageName: 'tell_us_about_yourself',
        ctaName: 'continue',
      }),
    );

    navigate(getNavigatePathForStep(JTBD_STEP.JTBD_STEP_2));
  };

  const handleSkip = () => {
    reportPapEvent(
      PAP_Open_DashJtbdOnboarding({
        pageName: 'tell_us_about_yourself',
        ctaName: 'skip',
      }),
    );

    navigate(getNavigatePathForStep(JTBD_STEP.JTBD_STEP_2));
  };

  return (
    <>
      <Titles
        title1={firstName && i18n.t('jtbd_welcome_title', { firstName })}
        title2={i18n.t('jtdb_tell_us_about_yourself_title')}
      />
      <Stack gap="Macro XLarge">
        <Stack gap="16">
          <Text isBold>{i18n.t('jtbd_type_of_work_question')}</Text>
          <Select
            id="select_type_of_work"
            placeholder={i18n.t('jtbd_type_of_work_placeholder')}
            className={JTBDStyles.dropdown}
            onChange={(e) => setSelectedTypeOfWork(e)}
          >
            {Object.values(TypeOfWork).map((typeOfWork) => {
              return (
                <Select.Option
                  key={typeOfWork}
                  value={typeOfWork.toString()}
                  className={JTBDStyles.dropdownItem}
                >
                  {typeOfWorkToLabel(typeOfWork as TypeOfWork)}
                </Select.Option>
              );
            })}
          </Select>
        </Stack>

        <Stack gap="16">
          <Text isBold>{i18n.t('jtbd_work_with_question')}</Text>
          <Split
            className={JTBDStyles.answerButtonHorizontalContainer}
            gap="16"
          >
            {Object.values(WorkWith).map((workWith) => {
              const id = `workWith-${workWith}`;
              const isSelected = selectedWorkWith === workWith.toString();
              return (
                <Split.Item className={JTBDStyles.answerButton} key={id}>
                  <input
                    type="radio"
                    id={id}
                    value={workWith}
                    name="workWith"
                    onChange={() => setSelectedWorkWith(workWith.toString())}
                  />
                  <FormLabel
                    htmlFor={id}
                    className={JTBDStyles.answerButtonLabel}
                  >
                    <Text
                      className={classnames(
                        isSelected && JTBDStyles.selectedAnswerColor,
                      )}
                    >
                      {workWithLabel(workWith as WorkWith)}
                    </Text>
                  </FormLabel>
                </Split.Item>
              );
            })}
          </Split>
        </Stack>
      </Stack>

      <div className={styles.buttonsContainer}>
        <Box display="flex" flexGrow={1}>
          <Button
            variant="primary"
            size="xlarge"
            fullWidth
            className={classnames(['roundedCorners', 'continueCta'])}
            disabled={!hasAllAnswers}
            onClick={submitAnswerAndContinue}
          >
            {i18n.t('continue_button')}
          </Button>
        </Box>
        <Box display="flex" justifyContent="center" marginTop="8">
          <Button
            variant="transparent"
            size="medium"
            className={classnames(['roundedCorners', 'skipCta'])}
            onClick={handleSkip}
          >
            {i18n.t('skip_button')}
          </Button>
        </Box>
      </div>
    </>
  );
};

const jtbdQuestions = [
  JtbdType.MEETINGS,
  JtbdType.ORGANIZE,
  JtbdType.SEARCH,
  JtbdType.NOT_SURE,
];

interface IconForJtbdTypeProps {
  jtbdType: JtbdType;
  isSelected: boolean;
}
const JtbdIcon: React.FC<IconForJtbdTypeProps> = ({
  jtbdType,
  isSelected,
}: IconForJtbdTypeProps) => {
  let iconSrc: React.ComponentType<React.SVGAttributes<SVGElement>>;
  switch (jtbdType) {
    case JtbdType.MEETINGS:
      iconSrc = CalendarLine;
      break;
    case JtbdType.ORGANIZE:
      iconSrc = EmojiUneasyLine;
      break;
    case JtbdType.SEARCH:
      iconSrc = SearchLine;
      break;
    case JtbdType.NOT_SURE:
      iconSrc = EmojiSunglassesLine;
      break;
    default:
      throw new Error(`Invalid JTBD type: ${jtbdType}`);
  }
  return (
    <UIIcon
      src={iconSrc}
      className={classnames(isSelected && JTBDStyles.selectedAnswerColor)}
    />
  );
};

const StepTwoLeftPanel: React.FC = () => {
  const navigate = useNavigate();
  const [selectedJtbdType, setSelectedJtbdType] = useState<
    JtbdType | undefined
  >(undefined);
  const { reportPapEvent } = useMirageAnalyticsContext();

  const hasAllAnswers = selectedJtbdType !== undefined;
  // We need to have random order of questions to avoid bias
  const randomizedJtbdQuestions = useMemo(
    () => jtbdQuestions.sort(() => Math.random() - 0.5),
    [],
  );

  const submitAnswerAndContinue = (jtbd: JtbdType) => {
    reportPapEvent(
      PAP_Open_DashJtbdOnboarding({
        pageName: 'jtbd',
        ctaName: 'continue',
      }),
    );

    switch (jtbd) {
      case JtbdType.MEETINGS:
        navigate(getNavigatePathForStep(JTBD_STEP.JTBD_STEP_3C_MEETINGS));
        break;
      case JtbdType.ORGANIZE:
        navigate(getNavigatePathForStep(JTBD_STEP.JTBD_STEP_3B_ORGANIZE));
        break;
      case JtbdType.SEARCH:
        navigate(getNavigatePathForStep(JTBD_STEP.JTBD_STEP_3A_SEARCH));
        break;
      case JtbdType.NOT_SURE:
        navigate(getNavigatePathForStep(JTBD_STEP.JTBD_STEP_3D_NOT_SURE));
        break;
      default:
        throw new Error(`Invalid JTBD type: ${jtbd}`);
    }
  };

  const handleSkip = () => {
    reportPapEvent(
      PAP_Open_DashJtbdOnboarding({
        pageName: 'jtbd',
        ctaName: 'skip',
      }),
    );

    navigate(getNavigatePathForStep(JTBD_STEP.JTBD_STEP_3E_SKIPPED));
  };

  useEffect(() => {
    reportPapEvent(PAP_Shown_DashJtbdOnboarding({ pageName: 'jtbd' }));
  }, [reportPapEvent]);

  return (
    <>
      <Titles
        title1={i18n.t('jtbd_relevant_question_title')}
        title2={i18n.t('jtdb_select_one_title')}
      />
      <Stack gap="Macro XLarge">
        <Stack gap="16">
          {randomizedJtbdQuestions.map((jtbdType) => {
            const id = `jtbdType-${jtbdType.toString()}`;
            const isSelected = selectedJtbdType === jtbdType;
            return (
              <Box className={JTBDStyles.answerButton} key={id}>
                <input
                  type="radio"
                  id={id}
                  value={jtbdType}
                  name="jtbdType"
                  onChange={() => setSelectedJtbdType(jtbdType)}
                />
                <FormLabel
                  htmlFor={id}
                  className={JTBDStyles.answerButtonLabel}
                >
                  <JtbdIcon jtbdType={jtbdType} isSelected={isSelected} />
                  <Text
                    className={classnames(
                      isSelected && JTBDStyles.selectedAnswerColor,
                    )}
                  >
                    {jtbdTypeToLabel(jtbdType)}
                  </Text>
                </FormLabel>
              </Box>
            );
          })}
        </Stack>
      </Stack>

      <div className={styles.buttonsContainer}>
        <Box display="flex" flexGrow={1}>
          <Button
            variant="primary"
            size="xlarge"
            fullWidth
            className={classnames(['roundedCorners', 'continueCta'])}
            disabled={!hasAllAnswers}
            onClick={() =>
              selectedJtbdType && submitAnswerAndContinue(selectedJtbdType)
            }
          >
            {i18n.t('continue_button')}
          </Button>
        </Box>
        <Box display="flex" justifyContent="center" marginTop="8">
          <Button
            variant="transparent"
            size="medium"
            className={classnames(['roundedCorners', 'skipCta'])}
            onClick={handleSkip}
          >
            {i18n.t('skip_button')}
          </Button>
        </Box>
      </div>
    </>
  );
};

type StepThreeLeftPanelContentProps = {
  features: {
    key: string;
    title: React.ReactNode;
    description?: React.ReactNode;
  }[];
  title1: string;
  title2?: string;
  pageName: PageName;
};

const StepThreeLeftPanel = {
  Search: () =>
    StepThreeLeftPanel.Content({
      features: [
        {
          key: 'search',
          title: <Text isBold>Search</Text>,
          description: <Text>Instantly find content across your apps.</Text>,
        },
        {
          key: 'answers',
          title: <Text isBold>Answers</Text>,
          description: <Text>Get AI answers about your work.</Text>,
        },
        {
          key: 'stacks',
          title: <Text isBold>Stacks</Text>,
          description: (
            <Text>Collect links and documents across various apps.</Text>
          ),
        },
      ],
      title1: 'How Dash can help',
      title2: 'Based on your answers',
      pageName: 'time_searching',
    }),
  Organize: () =>
    StepThreeLeftPanel.Content({
      features: [
        {
          key: 'search',
          title: <Text isBold>Search</Text>,
          description: <Text>Instantly find content across your apps.</Text>,
        },
        {
          key: 'stacks',
          title: <Text isBold>Stacks</Text>,
          description: (
            <Text>Collect links and documents across various apps.</Text>
          ),
        },
        {
          key: 'sharing',
          title: <Text isBold>Sharing</Text>,
          description: (
            <Text>Send links, answers, and Stacks easily to your team.</Text>
          ),
        },
      ],
      title1: 'How Dash can help',
      title2: 'Based on your answers',
      pageName: 'feeling_disorganized',
    }),
  Meetings: () =>
    StepThreeLeftPanel.Content({
      features: [
        {
          key: 'schedule',
          title: (
            <Text>
              <Text isBold>See your schedule</Text> at a glance on the home
              page.
            </Text>
          ),
        },
        {
          key: 'shortcuts',
          title: (
            <Text>
              <Text isBold>Use keyboard shortcuts</Text> to join meetings
              instantly.
            </Text>
          ),
        },
        {
          key: 'calendar',
          title: (
            <Text>
              <Text isBold>Search your calendar</Text> to find past and future
              events.
            </Text>
          ),
        },
      ],
      title1: 'How Dash can help',
      title2: 'Based on your answers',
      pageName: 'missing_video_meetings',
    }),
  NotSure: () =>
    StepThreeLeftPanel.Content({
      features: [
        {
          key: 'search-and-answers',
          title: <Text isBold>Search and Answers</Text>,
          description: <Text>Instantly find content across your apps.</Text>,
        },
        {
          key: 'stacks',
          title: <Text isBold>Stacks</Text>,
          description: (
            <Text>Collect links and documents across various apps.</Text>
          ),
        },
        {
          key: 'calendar',
          title: <Text isBold>Calendar</Text>,
          description: (
            <Text>Search your calendar to find past and future events.</Text>
          ),
        },
      ],
      title1: 'How Dash can help',
      title2: 'Based on your answers',
      pageName: 'not_sure',
    }),
  Skipped: () =>
    StepThreeLeftPanel.Content({
      features: [
        {
          key: 'search-and-answers',
          title: <Text isBold>Search and Answers</Text>,
          description: <Text>Instantly find content across your apps.</Text>,
        },
        {
          key: 'stacks',
          title: <Text isBold>Stacks</Text>,
          description: (
            <Text>Collect links and documents across various apps.</Text>
          ),
        },
        {
          key: 'calendar',
          title: <Text isBold>Calendar</Text>,
          description: (
            <Text>Search your calendar to find past and future events.</Text>
          ),
        },
      ],
      title1: 'How Dash can help',
      pageName: 'skipped',
    }),
  Content: ({
    features,
    title1,
    title2,
    pageName,
  }: StepThreeLeftPanelContentProps) => {
    const navigate = useNavigate();
    const { reportPapEvent } = useMirageAnalyticsContext();

    const onContinueClick = () => {
      reportPapEvent(
        PAP_Open_DashJtbdOnboarding({
          pageName,
          ctaName: 'continue',
        }),
      );

      navigate(getNavigatePathForStep(JTBD_STEP.JSBD_STEP_4));
    };

    useEffect(() => {
      reportPapEvent(PAP_Shown_DashJtbdOnboarding({ pageName }));
    }, [reportPapEvent, pageName]);

    return (
      <>
        <Titles title1={title1} title2={title2} />
        <Stack gap="Macro XLarge">
          <Stack gap="16">
            {features.map((item, index) => (
              <Box display="flex" key={item.key} paddingY="16">
                <Box
                  className={JTBDStyles.featureNumberBox}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  backgroundColor="Background Subtle"
                  borderRadius="Medium"
                  color="Primary Base"
                >
                  <Text isBold color="inherit">
                    {index + 1}
                  </Text>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  style={{ gap: '4px', marginLeft: '24px' }}
                >
                  {item.title}
                  {item.description}
                </Box>
              </Box>
            ))}
          </Stack>
        </Stack>

        <div className={styles.buttonsContainer}>
          <Box display="flex" flexGrow={1} style={{ gap: 16 }}>
            <IconButton
              onClick={() => navigate(-1)}
              variant="outline"
              style={{ borderRadius: 16, padding: '0 8px' }}
              size="xlarge"
            >
              <UIIcon src={ArrowLeftLine} aria-label="Back button" />
            </IconButton>
            <Button
              onClick={onContinueClick}
              variant="primary"
              size="xlarge"
              fullWidth
              className={classnames(['roundedCorners', 'continueCta'])}
            >
              {i18n.t('continue_button')}
            </Button>
          </Box>
        </div>
      </>
    );
  },
};

const NameCardRightPanel: React.FC = () => {
  const [firstName, setFirstName] = useState<string>('');

  useEffect(() => {
    getCurrentAccount().then((account) => {
      setFirstName(account?.name?.given_name || '');
    });
  }, []);

  return <UserNameCardChip name={firstName} />;
};
