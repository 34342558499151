import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { useVariableLineHeight } from '@mirage/shared/hooks/useVariableLineHeight';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './Markdown.module.css';

interface MarkdownProps {
  body: string;
  truncateLine?: number;
  onToggleExpand?: () => void;
}

// don't allow images in markdown.
const disallowedElements = ['img'];

export const Markdown: React.FC<MarkdownProps> = ({
  body,
  truncateLine = 0,
  onToggleExpand,
}) => {
  const [isExpanded, setIsExpanded] = useState(!truncateLine);
  const { ref, height, overflowing } = useVariableLineHeight(
    truncateLine,
    body,
  );

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
    onToggleExpand?.();
  };

  const maxHeight =
    truncateLine && overflowing && !isExpanded ? height : 'fit-content';

  return (
    <div className={styles.markdownContainer}>
      <Text
        size="medium"
        style={{ maxHeight }}
        className={`${truncateLine && !isExpanded ? styles.truncate : ''}`}
      >
        <div ref={ref}>
          <ReactMarkdown disallowedElements={disallowedElements}>
            {body}
          </ReactMarkdown>
        </div>
      </Text>
      {!!truncateLine && overflowing && (
        <div
          className={classnames(styles.truncateButtonContainer, {
            [styles.truncateButtonDiv]: !isExpanded,
          })}
        >
          <Button
            variant="transparent"
            size="medium"
            onClick={toggleExpand}
            className={styles.truncateButton}
          >
            {!isExpanded ? i18n.t('see_more') : ''}
          </Button>
        </div>
      )}
    </div>
  );
};
