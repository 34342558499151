// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2048
import type { PAPEventGeneric } from '../base/event';
import type { ConnectorPage } from '../enums/connector_page';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'open.dash_connectors'
// Description: Event triggered when a user performs an action on the dash client connectors page
// Owner: otc
export type Open_DashConnectors = PAPEventGeneric<
  'dash',
  'open',
  'dash_connectors',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // An entry point string, used to describe how users are triggering the event as relevant (e.g. browse_file_row_context_menu)
    entryPoint?: string;
    // Enum that dictates what connector page was opened.
    connectorPage?: ConnectorPage;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The surface in which an action was taken on Dash.
    dashActionSurface?: DashActionSurface;
  }
>;

export function PAP_Open_DashConnectors(
  properties?: Open_DashConnectors['properties'],
): Open_DashConnectors {
  return <Open_DashConnectors>{
    class: 'dash',
    action: 'open',
    object: 'dash_connectors',
    properties,
  };
}
