// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3070
import type { PAPEventGeneric } from '../base/event';
import type { CustomizeLayoutModalStatus } from '../enums/customize_layout_modal_status';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'move.customize_layout_modal'
// Description: Fires when a user clicks moves the modules in the Dash customize layout modal
// Owner: dash-web
export type Move_CustomizeLayoutModal = PAPEventGeneric<
  'dash',
  'move',
  'customize_layout_modal',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // Type of the Dash startpage module being customized. Values are:
    // * related_content
    // * calendar
    // * new_stack_suggestions
    // * connector_name (i.e. notion, dropbox, gmail)
    customizeLayoutModalType?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Whether the Dash startpage module was turned on or off by the user
    customizeLayoutModalStatus?: CustomizeLayoutModalStatus;
  }
>;

export function PAP_Move_CustomizeLayoutModal(
  properties?: Move_CustomizeLayoutModal['properties'],
): Move_CustomizeLayoutModal {
  return <Move_CustomizeLayoutModal>{
    class: 'dash',
    action: 'move',
    object: 'customize_layout_modal',
    properties,
  };
}
