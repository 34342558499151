// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2517
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'collapse.dash_left_sidebar'
// Description: Fires when a user clicks the collapse icon when the left sidebar is expanded to collapse it.
// Owner: dash-web
export type Collapse_DashLeftSidebar = PAPEventGeneric<
  'dash',
  'collapse',
  'dash_left_sidebar',
  never
>;

export function PAP_Collapse_DashLeftSidebar(
  properties?: Collapse_DashLeftSidebar['properties'],
): Collapse_DashLeftSidebar {
  return <Collapse_DashLeftSidebar>{
    class: 'dash',
    action: 'collapse',
    object: 'dash_left_sidebar',
    properties,
  };
}
