import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import { AddURLForm, useAddResultURLs } from './AddResultURLs';

type AnnotationURLModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmitURL: (url: string) => void;
};

export const AnnotationURLModal = ({
  isOpen,
  onClose,
  onSubmitURL,
}: AnnotationURLModalProps) => {
  const { pendingURLs, setPendingURLs, ...rest } = useAddResultURLs();

  const handleSubmit = () => {
    pendingURLs.forEach((url) => {
      if (url.trim() !== '') {
        onSubmitURL(url);
      }
    });
    setPendingURLs(['']);
    handleClose();
  };

  const handleClose = () => {
    setPendingURLs(['']);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      isCentered
      onRequestClose={handleClose}
      withCloseButton="Close"
      shouldCloseOnOverlayClick
    >
      <Modal.Header>
        <Text size="xlarge">Add missing URLs</Text>
      </Modal.Header>
      <Modal.Body>
        <AddURLForm pendingURLs={pendingURLs} {...rest} />
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button variant="opacity" onClick={handleClose}>
            Cancel
          </Button>
          {pendingURLs.length > 0 && (
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};
