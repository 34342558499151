import { getStacks, listAllStackItemsFromCache } from '@mirage/service-stacks';
import * as primitives from '@mirage/service-typeahead-search/service/primitives';
import { twoStageSearch } from '@mirage/service-typeahead-search/service/search/two-stage-search';
import { SourceId } from '@mirage/service-typeahead-search/service/types';
import * as wrappers from '@mirage/service-typeahead-search/service/utils/wrappers';
import Sentry from '@mirage/shared/sentry';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import { TypeaheadCache } from '../typeahead-cache';
import { buildStackItem } from '../utils/stack-items';

import type { stacks } from '@dropbox/api-v2-client';
import type { typeahead } from '@mirage/service-typeahead-search/service/types';
import type { Observable } from 'rxjs';

const SOURCE_RESULT_LIMIT = 50;

export const search = wrappers.wrapped(SourceId.StackItems, raw);

export function raw(
  query: string,
  _config: typeahead.Config,
  cache: TypeaheadCache,
): Observable<typeahead.TaggedResult> {
  return rx
    .from(_internalSearch(query))
    .pipe(op.mergeMap((result) => result))
    .pipe(
      op.map((result) =>
        primitives.stackItem(result.uuid, result, cache.getHits(result.uuid)),
      ),
    );
}

// exported for testing only
export async function _internalSearch(query: string) {
  try {
    // url -> nsid | We are indexing on url to check if items are unique
    const urlsToNsid: { [key: string]: Set<string> } = {};

    const [stackMap, stacks] = await Promise.all([
      listAllStackItemsFromCache(),
      getStacks(),
    ]);

    if (!stackMap) return [];

    const nsidToStack: Record<string, stacks.Stack> = {};
    stacks?.forEach((stack) => {
      // Remove archived or invalid stacks
      if (
        stack.namespace_id &&
        !stack.stack_data?.archive_data?.is_archived &&
        !!stack.stack_data?.name
      ) {
        nsidToStack[stack.namespace_id] = stack;
      }
    });

    const stackItems = Object.entries(stackMap)
      .flatMap(([stackId, stackItems]) => {
        if (!Object.prototype.hasOwnProperty.call(nsidToStack, stackId)) {
          return [];
        }

        return stackItems.map((stackItem) => {
          // store stack item attribution
          if (stackItem['.tag'] === 'shortcut' && stackItem.url) {
            if (
              !Object.prototype.hasOwnProperty.call(urlsToNsid, stackItem.url)
            ) {
              urlsToNsid[stackItem.url] = new Set<string>();
            }
            urlsToNsid[stackItem.url].add(stackId);
          }

          return buildStackItem(stackItem);
        });
      })
      .map((stackItem) => {
        if (stackItem) {
          stackItem.stackIds = [...urlsToNsid[stackItem.url]].map((nsid) => ({
            name: nsidToStack[nsid].stack_data?.name,
            namespaceId: nsid,
          }));
        }
        return stackItem;
      })
      .filter((maybeStackItem) => maybeStackItem != null);

    // box the stack items into a SearchableResults array
    const searchableResultCandidates = stackItems.map((stackItem) => ({
      searchableStrings: [
        stackItem.name,
        stackItem.custom_name,
        stackItem.description?.content,
      ].filter((s) => s != null),
      originalResult: stackItem,
    }));

    // and let twoStageSearch handle the actual searching!
    const matchingSearchableResults = twoStageSearch(
      query,
      searchableResultCandidates,
      SOURCE_RESULT_LIMIT,
    );

    // then unbox back to stack items
    return matchingSearchableResults.map(
      (searchableResult) => searchableResult.originalResult,
    );
  } catch (e) {
    Sentry.captureException(e);
    return [];
  }
}
