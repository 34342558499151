// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1289
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';
import type { TabGroupType } from '../enums/tab_group_type';

// Event_name: 'copy.dash_link'
// Description: Fires when user clicks the Copy link button on a link saved within a stack
// Owner: content-ingest
export type Copy_DashLink = PAPEventGeneric<
  'stacks',
  'copy',
  'dash_link',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The stack id that represents the stack
    stackId?: string;
    // User_id associated with the creator of the content being acted upon
    creatorId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // Meeting IDs that are uuidv4s as generated by neo4j. Used to tie together Dash meetings and related content.
    meetingId?: string;
    // zero-indexed position of a calendar item
    itemPosition?: number;
    // stormcrow feature name
    feature?: string;
    // Generated random request id for tracking suggestions in Dash
    dashRequestId?: string;
    // A hashed integer for unique predictions recommended in Dash
    predictionIdHash?: number;
    // 0 indexed position of the calendar event/item
    calendarItemPosition?: number;
    // A hashed integer for items/content recommended in Dash
    itemIdHash?: number;
    // Type of Tab groups provided by dash
    tabGroupType?: TabGroupType;
    // An entry point string, used to describe how users are triggering the event as relevant (e.g. browse_file_row_context_menu)
    entryPoint?: string;
  }
>;

export function PAP_Copy_DashLink(
  properties?: Copy_DashLink['properties'],
): Copy_DashLink {
  return <Copy_DashLink>{
    class: 'stacks',
    action: 'copy',
    object: 'dash_link',
    properties,
  };
}
