// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2966
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'select.dash_connect_app_page_add_later'
// Description: Fires when the user clicks 'Add later' to skip adding apps during single-step onboarding
// Owner: otc-growth
export type Select_DashConnectAppPageAddLater = PAPEventGeneric<
  'dash',
  'select',
  'dash_connect_app_page_add_later',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
  }
>;

export function PAP_Select_DashConnectAppPageAddLater(
  properties?: Select_DashConnectAppPageAddLater['properties'],
): Select_DashConnectAppPageAddLater {
  return <Select_DashConnectAppPageAddLater>{
    class: 'dash',
    action: 'select',
    object: 'dash_connect_app_page_add_later',
    properties,
  };
}
