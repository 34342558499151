import title from '@mirage/shared/search/scoring/jaccard-title-match';

import type { SearchResult } from '@mirage/shared/search/search-result';

// TODO: Can we move these to a server config?
const TITLE_SCORE_WEIGHT = 0.65;
const RELEVANCE_SCORE_WEIGHT = 0.35;

// XXX: don't want to get too close to our current implementation as we don't
// have specific requirements for scoring. but in the interim this is stubbed
// out to reach into the title match scoring that I quickly wrote

export function scoreResult(query: string, result: SearchResult) {
  const titleScore = title(query, result.title);

  // If a relevanceScore is provided, use a weighted average.
  if (result.relevanceScore) {
    const weightedScore =
      titleScore * TITLE_SCORE_WEIGHT +
      result.relevanceScore * RELEVANCE_SCORE_WEIGHT;

    return weightedScore;
  }

  // If no relevanceScore is available, rely solely on the title match.
  return titleScore;
}
