import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Shown_DashEligibilityModal } from '@mirage/analytics/events/types/shown_dash_eligibility_modal';
import { Link } from '@mirage/link/Link';
import i18n, { Trans } from '@mirage/translations';
import { useEffect, useRef } from 'react';

export const HELP_CENTER_ELIGIBILITY_ARTICLE =
  'https://dash.dropbox.com/landing?destination=help-center';

export const IneligibleModal = ({
  message,
  onClick,
}: {
  message: string;
  onClick: () => void;
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const exposureLoggedRef = useRef(false);

  useEffect(() => {
    if (exposureLoggedRef.current) return;
    exposureLoggedRef.current = true;
    reportPapEvent(PAP_Shown_DashEligibilityModal());
  }, [reportPapEvent]);

  return (
    <Modal open={true}>
      <Modal.Header hasBottomSpacing="title-small">
        <Modal.Title>{i18n.t('user_ineligible_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{i18n.t('user_ineligible_message')}</p>
        {Boolean(message) && (
          <ul>
            <li>{message}</li>
          </ul>
        )}
        <br />
        <Text>
          <Trans
            i18nKey="link_dash_eligibility_article"
            components={[
              <Link
                key={0}
                variant="monochromatic"
                href={HELP_CENTER_ELIGIBILITY_ARTICLE}
              />,
            ]}
          />
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClick}>
          {i18n.t('user_ineligible_button')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

IneligibleModal.displayName = 'IneligibleModal';
