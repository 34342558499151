import { Banner } from '@dropbox/dig-components/banner';
import { listConnections } from '@mirage/service-connectors';
import i18n from '@mirage/translations';
import { useEffect, useState } from 'react';
import styles from './InitialSyncBanner.module.css';

export const InitialSyncBanner: React.FC = () => {
  const [connectionNames, setConnectionNames] = useState<string[]>([]);

  useEffect(() => {
    const doSetConnections = async () => {
      const connections = await listConnections();
      const connectionNames = connections
        .filter((item) => {
          const isReauth = item.connection_status?.['.tag'] === 'reauth';
          const isExpired = item.connection_status?.['.tag'] === 'expired';
          const syncProgress = item.sync_status?.sync_progress;
          return isExpired || isReauth
            ? false
            : syncProgress?.start_time &&
                !syncProgress.end_time &&
                !syncProgress.last_successful_sync_time;
        })
        .map((c) => c.branding?.display_name)
        .filter(Boolean) as string[];

      setConnectionNames(connectionNames);
    };

    doSetConnections();
  }, []);

  return <SyncBanner connectorNames={connectionNames} />;
};

type SyncBannerProps = {
  connectorNames: Array<string>;
};

export const SyncBanner: React.FC<SyncBannerProps> = ({ connectorNames }) => {
  const connectorCount = connectorNames.length;

  if (!connectorCount) return null;

  let message = '';
  if (connectorCount > 3) {
    message = i18n.t('initial_sync_message_with_count', {
      connectorCount,
    });
  } else if (connectorCount === 1) {
    message = i18n.t('initial_sync_message_single', {
      connector: connectorNames[0],
    });
  } else if (connectorCount === 2) {
    const connectorList = connectorNames.join(` ${i18n.t('conjunction_and')} `);
    message = i18n.t('initial_sync_message_list', {
      connectorList,
    });
  } else {
    const connectorList = [
      connectorNames.slice(0, connectorCount - 1).join(', '),
      connectorNames[connectorCount - 1],
    ].join(`, ${i18n.t('conjunction_and')} `);

    message = i18n.t('initial_sync_message_list', {
      connectorList,
    });
  }

  return (
    <Banner type="attention" className={styles.banner}>
      <Banner.Message>{message}</Banner.Message>
    </Banner>
  );
};
