// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2564
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { EndReason } from '../enums/end_reason';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'finish.dash_app_session'
// Description: "This is the end of an overall dash session for different surfaces (e.g. desktop app, web app) based on our new definition (notes).
// Fires when the desktop app or web app loses focus (window blurs). "
// Owner: dash-web
export type Finish_DashAppSession = PAPEventGeneric<
  'dash',
  'finish',
  'dash_app_session',
  {
    // the end time of the event in ms
    endTimeMs?: number;
    // the start time of the event in ms
    startTimeMs?: number;
    // The reason the session ended
    endReason?: EndReason;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
  }
>;

export function PAP_Finish_DashAppSession(
  properties?: Finish_DashAppSession['properties'],
): Finish_DashAppSession {
  return <Finish_DashAppSession>{
    class: 'dash',
    action: 'finish',
    object: 'dash_app_session',
    properties,
  };
}
