// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgMsOneNote({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.455 15.227H22v3.637c0 1.004-.724 1.818-1.616 1.818H9.07c-.893 0-1.616-.814-1.616-1.818v-3.637z"
        fill="#5D29A1"
      />
      <path fill="#7F32CC" d="M7 10h15v5.455H7z" />
      <path fill="#9A40D6" d="M7 6.667h15v4.167H7z" />
      <path
        d="M8.25 2.5C7.56 2.5 7 3.081 7 3.799v15.584c0 .717.56 1.299 1.25 1.299H17V7.045h5V3.8c0-.718-.56-1.299-1.25-1.299H8.25z"
        fill="url(#ms-one-note_svg__paint0_linear)"
      />
      <path
        d="M7 9.375a2.5 2.5 0 012.5-2.5H12a2.5 2.5 0 012.5 2.5v6.25a2.5 2.5 0 01-2.5 2.5H7v-8.75z"
        fill="#000"
        fillOpacity={0.3}
      />
      <path
        d="M2 7.5c0-.92.746-1.667 1.667-1.667H12c.92 0 1.667.747 1.667 1.667v8.333c0 .92-.746 1.667-1.667 1.667H3.667C2.747 17.5 2 16.754 2 15.833V7.5z"
        fill="url(#ms-one-note_svg__paint1_linear)"
      />
      <path
        d="M10.125 14.375v-6.25h-1.32v3.643L6.353 8.125H5.125v6.25h1.3v-3.777l2.547 3.777h1.153z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="ms-one-note_svg__paint0_linear"
          x1={7}
          y1={11.591}
          x2={17.313}
          y2={11.591}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8C42AC" />
          <stop offset={1} stopColor="#C36DEF" />
        </linearGradient>
        <linearGradient
          id="ms-one-note_svg__paint1_linear"
          x1={2}
          y1={11.667}
          x2={14.315}
          y2={11.667}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#44176C" />
          <stop offset={1} stopColor="#621F9C" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgMsOneNote;
