import * as dbxApiService from '@mirage/service-dbx-api';
import { MultiAnswerResponse } from '@mirage/shared/answers/multi-answer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { from, of, Subject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

const useMultiAnswers = () => {
  const [answers, setAnswers] = useState<MultiAnswerResponse | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(false);

  const querySubject = useRef(new Subject<string>()).current;

  useEffect(() => {
    const subscription = querySubject
      .pipe(
        switchMap((query) => {
          setLoading(true);
          return from(dbxApiService.fetchAnswersForQuery(query)).pipe(
            catchError(() => of(undefined)),
          );
        }),
      )
      .subscribe((response) => {
        setAnswers(response);
        setLoading(false);
      });

    return () => subscription.unsubscribe();
  }, [querySubject]);

  const fetch = useCallback(
    (query: string) => {
      setAnswers(undefined);
      querySubject.next(query);
    },
    [querySubject],
  );

  return { answers, loading, fetch };
};

export default useMultiAnswers;
