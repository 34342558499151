import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { createUxaElementId } from '@mirage/analytics/uxa';
import i18n from '@mirage/translations';
import styles from './LoginCTA.module.css';

export const LoginCTA: React.FC<{
  onClickLogin: () => void;
  onClickLearnMore: () => void;
}> = ({ onClickLogin, onClickLearnMore }) => {
  return (
    <div className={styles.container}>
      <Text isBold>{i18n.t('public_stack_sign_in_cta_title')}</Text>
      <Text color="subtle">{i18n.t('public_stack_sign_in_cta')}</Text>
      <div className={styles.buttonsContainer}>
        <Button
          variant="primary"
          size="medium"
          onClick={onClickLogin}
          data-uxa-log={createUxaElementId('sign_in_button', {
            actionSurfaceComponent: 'public_stack_preview_cta',
            featureLine: 'stacks',
          })}
        >
          {i18n.t('sign_in')}
        </Button>
        <Button
          variant="opacity"
          size="medium"
          onClick={onClickLearnMore}
          data-uxa-log={createUxaElementId('learn_more_button', {
            actionSurfaceComponent: 'public_stack_preview_cta',
            featureLine: 'stacks',
          })}
        >
          {i18n.t('learn_more')}
        </Button>
      </div>
    </div>
  );
};
