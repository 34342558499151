import { IconButton } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { useTheme } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { ChevronRightLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { ComposeAssistantConversationMessage } from '@mirage/shared/compose/compose-session';
import classnames from 'classnames';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import styles from './ConversationFollowUpSuggestions.module.css';

interface ConversationFollowUpSuggestionsProps {
  message: ComposeAssistantConversationMessage;
  onClickFollowUpSuggestion: (suggestion: string) => void;
  rightPaneExpanded: boolean;
}

export const ConversationFollowUpSuggestions = memo(
  ({
    onClickFollowUpSuggestion,
    message,
    rightPaneExpanded,
  }: ConversationFollowUpSuggestionsProps) => {
    const theme = useTheme();
    const containerRef = useRef<HTMLDivElement>(null);
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
    const [isScrollable, setIsScrollable] = useState(false);
    // Check whether to hide the scroll right button when the content is scrolled to the end
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const isNearEnd =
          container.scrollWidth -
            container.scrollLeft -
            container.clientWidth <=
          30;
        setIsScrolledToEnd(isNearEnd);
      }
    };
    // Check if container is scrollable when resizing the chat pane
    const checkIfScrollable = useCallback(() => {
      const container = containerRef.current;
      if (container) {
        const newScrollable = container.scrollWidth > container.clientWidth;
        if (newScrollable !== isScrollable) {
          setIsScrollable(newScrollable);
        }
        handleScroll();
      }
    }, [isScrollable]);
    // Clicking on the button scrolls the container to the right
    const scrollRight = () => {
      const container = containerRef.current;
      if (container) {
        container.scrollBy({ left: 200, behavior: 'smooth' });
      }
    };
    useEffect(() => {
      const container = containerRef.current;
      if (container) {
        handleScroll(); // Check if the content is scrolled to the end on load
        checkIfScrollable(); // Check if the content is scrollable on load
        container.addEventListener('scroll', handleScroll);
        const resizeObserver = new ResizeObserver(() => {
          checkIfScrollable();
        });
        resizeObserver.observe(container);
        return () => {
          container.removeEventListener('scroll', handleScroll);
          resizeObserver.disconnect();
        };
      }
    }, [message, checkIfScrollable]); // Re-check scroll state when message changes
    return (
      <>
        <div
          className={classnames(styles.ConversationMessageFollowUpSuggestions, {
            [styles.ConversationMessageScrollEnd]: isScrolledToEnd,
          })}
          ref={containerRef}
        >
          {message.type === 'message' &&
            message?.followUpSuggestions?.map((suggestion, i) => (
              <Chip
                key={`follow-up-${i}`}
                className={styles.ConversationMessageFollowUpSuggestionButton}
                variant="transparent"
                onClick={() => onClickFollowUpSuggestion(suggestion)}
              >
                <Chip.Content>{suggestion}</Chip.Content>
              </Chip>
            ))}
          {isScrollable && !isScrolledToEnd ? (
            <div
              className={classnames(styles.ConversationMessageFollowUpArrow, {
                [styles.ConversationMessageFollowUpArrowPaneExpanded]:
                  rightPaneExpanded,
                [styles.ConversationMessageFollowUpArrowDark]:
                  theme.mode === 'dark',
                [styles.ConversationMessageFollowUpArrowPaneExpandedDark]:
                  theme.mode === 'dark' && rightPaneExpanded,
              })}
            >
              <IconButton
                variant="borderless"
                shape="standard"
                size="small"
                onClick={scrollRight}
              >
                <UIIcon src={ChevronRightLine} />
              </IconButton>
            </div>
          ) : null}
          {/* The arrow takes up room so need a bit of extra padding to avoid a jolt when scrolling to the end */}
          {isScrolledToEnd && (
            <div className={styles.ConversationMessageSpacer} />
          )}
        </div>
      </>
    );
  },
);
ConversationFollowUpSuggestions.displayName = 'ConversationFollowUpSuggestions';
