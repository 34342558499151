// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgJira({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.326 11.337L12.978.994 11.983 0l-7.76 7.757-3.55 3.547c-.364.364-.364.961 0 1.359l7.098 7.094L11.983 24l7.761-7.757.133-.133 3.45-3.447a.94.94 0 000-1.326zm-11.343 4.21L8.435 12l3.548-3.547L15.533 12l-3.55 3.547z"
        fill="#2684FF"
      />
      <path
        d="M11.983 8.453c-2.321-2.32-2.321-6.1-.033-8.42L4.19 7.79 8.4 12l3.582-3.547z"
        fill="url(#jira_svg__paint0_linear)"
      />
      <path
        d="M15.532 12l-3.549 3.547c2.322 2.32 2.322 6.1 0 8.453l7.794-7.79L15.532 12z"
        fill="url(#jira_svg__paint1_linear)"
      />
      <defs>
        <linearGradient
          id="jira_svg__paint0_linear"
          x1={11.35}
          y1={4.86}
          x2={6.397}
          y2={9.816}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.176} stopColor="#0052CC" />
          <stop offset={1} stopColor="#2684FF" />
        </linearGradient>
        <linearGradient
          id="jira_svg__paint1_linear"
          x1={12.662}
          y1={19.089}
          x2={17.606}
          y2={14.143}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.176} stopColor="#0052CC" />
          <stop offset={1} stopColor="#2684FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgJira;
