import { SurveyEvent } from '@mirage/service-feedback/types';
import { useEffect } from 'react';
import { publishEvent } from '..';

export const usePublishHomepageSurveyEvent = () => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      publishEvent(SurveyEvent.OpenStartPage);
    }, 1500);

    // Clear the timeout if the user navigates away from the page
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
};
