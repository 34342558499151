// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgIntercom({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 2h-15C3.12 2 2 3.12 2 4.5v15C2 20.88 3.12 22 4.5 22h15c1.38 0 2.5-1.12 2.5-2.5v-15C22 3.12 20.88 2 19.5 2zm-4.835 3.665c0-.884 1.335-.884 1.335 0v8.906c0 .884-1.335.884-1.335 0V5.665zm-3.33-.335c0-.884 1.335-.884 1.335 0v9.666c0 .883-1.335.883-1.335 0V5.33zM8 5.665c0-.884 1.335-.884 1.335 0v8.906c0 .884-1.335.884-1.335 0V5.665zM4.665 7C4.665 6.116 6 6.116 6 7v5.995c0 .885-1.335.885-1.335 0V7zm14.438 10.17c-3.25 2.812-10.79 2.92-14.201 0-.67-.572.196-1.585.866-1.014 2.942 2.469 9.647 2.407 12.469 0 .665-.576 1.53.438.866 1.014zm.232-4.175c0 .885-1.335.885-1.335 0V7c0-.884 1.335-.884 1.335 0v5.995z"
        fill="#0B0B0B"
      />
    </svg>
  );
}

export default SvgIntercom;
