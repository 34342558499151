// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2724
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'open.dash_connect_app_page_continue'
// Description: User clicked the “continue” button from onboarding page
// Owner: otc
export type Open_DashConnectAppPageContinue = PAPEventGeneric<
  'dash',
  'open',
  'dash_connect_app_page_continue',
  never
>;

export function PAP_Open_DashConnectAppPageContinue(
  properties?: Open_DashConnectAppPageContinue['properties'],
): Open_DashConnectAppPageContinue {
  return <Open_DashConnectAppPageContinue>{
    class: 'dash',
    action: 'open',
    object: 'dash_connect_app_page_continue',
    properties,
  };
}
