// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2556
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'click.dash_rename_link'
// Description: Fires when a user clicks the Rename Link button to open the stacks link name modal
// Owner: dash-web
export type Click_DashRenameLink = PAPEventGeneric<
  'stacks',
  'click',
  'dash_rename_link',
  {
    // The stack id that represents the stack
    stackId?: string;
    // marks if the content is shared content
    isShared?: boolean;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
  }
>;

export function PAP_Click_DashRenameLink(
  properties?: Click_DashRenameLink['properties'],
): Click_DashRenameLink {
  return <Click_DashRenameLink>{
    class: 'stacks',
    action: 'click',
    object: 'dash_rename_link',
    properties,
  };
}
