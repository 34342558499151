// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1858
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';

// Event_name: 'update.stacks_description'
// Description: Stacks description is updated/confirmed
// Owner: otc
export type Update_StacksDescription = PAPEventGeneric<
  'stacks',
  'update',
  'stacks_description',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The stack id that represents the stack
    stackId?: string;
    // User_id associated with the creator of the content being acted upon
    creatorId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
  }
>;

export function PAP_Update_StacksDescription(
  properties?: Update_StacksDescription['properties'],
): Update_StacksDescription {
  return <Update_StacksDescription>{
    class: 'stacks',
    action: 'update',
    object: 'stacks_description',
    properties,
  };
}
