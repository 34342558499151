import * as React from "react";
import {
  ThemeProvider,
  type AvailableThemeOverridesStrict,
} from "@dropbox/dig-foundations";
import { IconLayout, useIconSize } from "../icon-layout";
import type { IconLayoutProps } from "../icon-layout/IconLayout";
import classNames from "classnames";
import css from "./StackIcon.module.css";

export interface StackIconProps
  extends Pick<
      IconLayoutProps,
      | "role"
      | "style"
      | "hasBackground"
      | "onClick"
      | "href"
      | "className"
      | "shapeVariant"
    >,
    React.AriaAttributes {
  /**
   * The size of the StackIcon, will also dictate the size of the text or emoji within
   */
  size?: IconLayoutProps["size"];

  /**
   * The theme of the StackIcon
   */
  theme?: AvailableThemeOverridesStrict;

  /**
   * The type of icon to display in the `StackIcon`
   * Since emoji text can render differently based on the browser this will apply some conditional size adjustments to any emoji
   */
  variant: "emoji" | "text" | "icon";

  /**
   * The "icon" to display in a `StackIcon`. This can be a string of text, an emoji, or any other `ReactNode`.
   * Ideally this should only ever be a single character, emoji, or SVG.
   * The component does not attempt to limit or validate this input, it will render whatever is passed in.
   */
  icon: React.ReactNode;

  children?: never;
}

/**
 * Stack icons represent the Dash concept of stacks which are dynamic spaces that contain web based content.
 */
export const StackIcon: React.FC<StackIconProps> = ({
  size: _size,
  theme,
  variant,
  icon,
  className,
  onClick,
  hasBackground = true,
  style,
  ...rest
}) => {
  const size = useIconSize({ size: _size });
  const hasEmoji = variant === "emoji";

  const cls = classNames(className, css["root"], {
    [css["root-variant-emoji"]]: hasEmoji,
    [css["root-variant-text"]]: variant === "text",
    [css["root-size-small"]]: size === "small",
    [css["root-size-medium"]]: size === "medium",
    [css["root-size-large"]]: size === "large",
    [css["root-size-xlarge"]]: size === "xlarge",
  });

  // only apply theme overrides if the StackIcon has a background
  // This is because without a background, chances are the text color is no longer passing contrast ratios.
  const overrides = hasBackground && theme ? theme : undefined;

  return (
    <ThemeProvider overrides={overrides}>
      {({ getThemeProps }) => (
        <IconLayout
          hasBorder={hasBackground ? undefined : true}
          hasBackground={hasBackground}
          shouldConstrainContent={hasEmoji ? false : true}
          size={size}
          content={icon}
          backgroundColor={overrides ? "Primary Surface" : "Secondary Surface"}
          textColor="Text Base"
          onClick={onClick}
          {...getThemeProps({ className: cls, style })}
          {...rest}
        />
      )}
    </ThemeProvider>
  );
};
