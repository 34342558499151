import { CalendarLine, PersonCircleLine } from '@dropbox/dig-icons/assets';
import i18n from '@mirage/translations';
import React from 'react';
import styles from './TypeaheadUtils.module.css';

// TODO: Import safeLocaleLowerCase into Mirage for i18n
// This is the location of the logic in Scout:
// import { safeLocaleLowerCase } from "shared/react-intl/i18n";

export class VirtualTrigger {
  position: [number, number];

  constructor() {
    this.position = [0, 0];
  }

  getBoundingClientRect() {
    return {
      height: 0,
      width: 0,
      top: this.position[1],
      right: this.position[0],
      bottom: this.position[1],
      left: this.position[0],
      x: 0,
      y: 0,
      toJSON: () => undefined,
    };
  }
}

export const getTypeaheadMenuPosition = (
  textArea: HTMLTextAreaElement | HTMLInputElement,
) => {
  const { left: inputLeft, top: inputTop } = textArea.getBoundingClientRect();
  const selectionPoint = textArea.selectionStart ?? 0;

  // Create shadow div and duplicate styles to it
  const div = document.createElement('div');
  const inputStyle = getComputedStyle(textArea, '');
  div.style.cssText = inputStyle.cssText;

  // Set the text content up to the selection point
  const textContent = textArea.value.substring(0, selectionPoint);
  div.textContent = textContent;
  div.style.height = 'auto';

  // Create marker element
  const span = document.createElement('span');
  span.textContent = textArea.value.substring(selectionPoint) || '.';
  div.appendChild(span);

  // Append, measure, then remove
  document.body.appendChild(div);
  const { offsetLeft: spanLeft } = span;
  const spanHeight = span.parentElement
    ? span.parentElement.offsetHeight
    : span.offsetHeight;
  document.body.removeChild(div);

  return {
    left: inputLeft + spanLeft - textArea.scrollLeft + 8,
    top: inputTop + spanHeight + 4,
  };
};

/**
 * Highlight matched text in the mentions dropdown.
 *
 * We match by single characters here because that is mostly how the
 * `getSharingMembers` API works. e.g. if you search for "ab", it will
 * match "Anne Beck". We don't have to exact match the API as that could
 * unnecessarily complicate things without much benefit to users.
 */
export const HighlightMatchedText: React.FC<{
  mentionText: string;
  rowText: string;
}> = ({ mentionText, rowText }) => {
  // const mentionTextLC = safeLocaleLowerCase(mentionText);
  const mentionTextLC = mentionText.toLowerCase(); // TODO: Handle i18n
  if (!mentionText) return <>{rowText}</>;

  // Highlight all matched characters.
  // const rowTextLC = safeLocaleLowerCase(rowText);
  const rowTextLC = rowText.toLowerCase(); // TODO: Handle i18n
  const parts: { bold: boolean; text: string }[] = [];
  let bold = false;

  for (let i = 0; i < rowTextLC.length; i++) {
    const thisBold = mentionTextLC.includes(rowTextLC[i]);

    if (i === 0 || thisBold !== bold) {
      bold = thisBold;
      parts.push({ bold, text: rowText[i] });
    } else {
      const lastPart = parts[parts.length - 1];
      lastPart.text += rowText[i];
    }
  }

  // If we have exact matches, then discard all partial matches.
  // This will remove bad matches with jumbled order.
  const hasExactMatch = rowTextLC.includes(mentionTextLC);
  if (hasExactMatch) {
    for (const part of parts) {
      // const lowerCasedText = safeLocaleLowerCase(part.text);
      const lowerCasedText = part.text.toLowerCase(); // TODO: Handle i18n
      if (part.bold && lowerCasedText !== mentionTextLC) {
        part.bold = false;
      }
    }
  }

  // If we have large matches, then discard all small matches.
  // This will remove highlighting for single characters.
  if (!hasExactMatch) {
    const largestMatchLength = parts.reduce(
      (prev, part) => Math.max(prev, part.text.length),
      0,
    );
    if (largestMatchLength > 1) {
      for (const part of parts) {
        if (part.bold && part.text.length !== largestMatchLength) {
          part.bold = false;
        }
      }
    }
  }

  return (
    <>
      {parts.map(({ bold, text }, i) => (
        <span className={bold ? styles.highlight : undefined} key={i}>
          {text}
        </span>
      ))}
    </>
  );
};

/**
 * returns the last index of a given character, only if it has a leading space,
 * or if ii's the first character of the string.
 * this helps to not trigger the mention typeahead if the user types an actual email
 */
export const lastIndexWithLeadingSpace = (
  s: string,
  target: string,
): number => {
  if (s.lastIndexOf(target) === 0) {
    return 0;
  }

  const leadingSpace = s.lastIndexOf(` ${target}`);

  return leadingSpace === -1 ? -1 : leadingSpace + 1;
};

/**
 * Retrieves the last index positions of specific characters (example: `@` or `#`)
 * in a given string up to the current cursor location in a textarea.
 */

export const getCharIndexes = (
  text: string,
  textAreaRef: React.RefObject<HTMLTextAreaElement>,
): { atSign: number; hexSign: number } => {
  const textArea = textAreaRef.current;
  if (!textArea) return { atSign: -1, hexSign: -1 };

  const cursorLocation = textArea.selectionStart;
  const prefix = text.substring(0, cursorLocation);

  return {
    atSign: lastIndexWithLeadingSpace(prefix, MentionMode.CONTACTS),
    hexSign: lastIndexWithLeadingSpace(prefix, MentionMode.EVENTS),
  };
};

/**
 * Retrieves the mention string from a given text string based on the provided atSignLocation and textarea ref.
 */
export const getMentionString = (
  text: string,
  atSignLocation: number,
  textAreaRef: React.RefObject<HTMLTextAreaElement>,
): string => {
  const textArea = textAreaRef.current;
  if (!textArea) return '';

  const cursorLocation = textArea.selectionStart;
  return text.substring(atSignLocation + 1, cursorLocation + 1);
};

export const enum MentionMode {
  CONTACTS = '@',
  EVENTS = '#',
}

export const getDropdownTitle = (mentionMode?: MentionMode): string => {
  switch (mentionMode) {
    case MentionMode.CONTACTS:
      return i18n.t('mention_someone');
    case MentionMode.EVENTS:
      return i18n.t('mention_event');
    default:
      return '';
  }
};

export const getDropdownRowIconSrc = (
  mentionMode: MentionMode,
): React.ComponentType<React.SVGAttributes<SVGElement>> => {
  switch (mentionMode) {
    case MentionMode.CONTACTS:
      return PersonCircleLine;
    case MentionMode.EVENTS:
      return CalendarLine;
    //  `satisfies never` is implicitly present here due to specifying return type
  }
};
