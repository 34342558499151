// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1291
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'rename.dash_link'
// Description: Rename confirmed for dash link
// Owner: content-ingest
export type Rename_DashLink = PAPEventGeneric<
  'stacks',
  'rename',
  'dash_link',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The stack id that represents the stack
    stackId?: string;
    // User_id associated with the creator of the content being acted upon
    creatorId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
  }
>;

export function PAP_Rename_DashLink(
  properties?: Rename_DashLink['properties'],
): Rename_DashLink {
  return <Rename_DashLink>{
    class: 'stacks',
    action: 'rename',
    object: 'dash_link',
    properties,
  };
}
