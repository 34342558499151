// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1854
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';
import type { SortOption } from '../enums/sort_option';
import type { SortOptionType } from '../enums/sort_option_type';

// Event_name: 'sort.sort_option'
// Description: Fires when user change the sort order of dash items within a stack
// Owner: otc
export type Sort_SortOption = PAPEventGeneric<
  'dash',
  'sort',
  'sort_option',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The stack id that represents the stack
    stackId?: string;
    // User_id associated with the creator of the content being acted upon
    creatorId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // sorting option for files and folders on core browse
    sortOption?: SortOption;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // Device id for Replay events so that we can distinguish between logged out users.
    deviceId?: string;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // Whether the user is interacting with their welcome stack
    isWelcomeStack?: boolean;
    // Sort option values for Dash stack sorting
    sortOptionType?: SortOptionType;
  }
>;

export function PAP_Sort_SortOption(
  properties?: Sort_SortOption['properties'],
): Sort_SortOption {
  return <Sort_SortOption>{
    class: 'dash',
    action: 'sort',
    object: 'sort_option',
    properties,
  };
}
