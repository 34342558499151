import { FolderLine } from '@dropbox/dig-icons/assets';
import { getConnectorDisplayNameFromResult } from '@mirage/shared/connectors';
import { getLocalFileTimestamp } from '../util/resultUtil';
import { MetadataItem } from './MetadataItem';
import { TruncatedFilePath } from './TruncatedFilePath';

import type { SearchResult } from '@mirage/service-dbx-api';

type LocalFileMetadataProps = {
  result: SearchResult;
  isOverflowing: boolean;
  isTooltipContent?: boolean;
};

export const LocalFileMetadata = ({
  result,
  isOverflowing,
  isTooltipContent,
}: LocalFileMetadataProps) => {
  const appName = getConnectorDisplayNameFromResult(result);
  const timestamp = getLocalFileTimestamp(result);
  const pathSegments = result?.desktopLocalFileMetadata?.pathSegments || [];

  const metadataTitle = isTooltipContent ? (
    pathSegments.join(' > ')
  ) : (
    <TruncatedFilePath
      isOverflowing={isOverflowing}
      pathSegments={pathSegments}
    />
  );

  return (
    <>
      {appName && <MetadataItem title={appName} />}
      {pathSegments.length > 0 && (
        <MetadataItem title={metadataTitle} iconSrc={FolderLine} />
      )}
      {timestamp && <MetadataItem title={timestamp} />}
    </>
  );
};
