import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetNewStackPageAtom } from './atoms';

export const useForceNavToNewStack = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const setResetNewStackPage = useSetAtom(resetNewStackPageAtom);
  return useCallback(() => {
    if (location.pathname !== '/stacks/new') {
      navigate('/stacks/new');
    } else {
      setResetNewStackPage(true);
      // Just to update the url
      navigate('/stacks/new');
    }
  }, [location.pathname, navigate, setResetNewStackPage]);
};
