import * as React from 'react';

// This is a CommentLine SVG, but with custom height/width and color for dark/light mode
// Take directly from figma: https://www.figma.com/file/BSxM58kqr4kfHBznBHoGn4/Answers?node-id=4992%3A32994&mode=dev
export const CommentLineSvg = ({
  width,
  height,
  isDarkModeEnabled,
}: {
  width: number;
  height: number;
  isDarkModeEnabled: boolean;
}) => {
  if (isDarkModeEnabled) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.7813 4.61475H5.448C5.05017 4.61475 4.66864 4.77278 4.38734 5.05409C4.10603 5.33539 3.948 5.71692 3.948 6.11475V10.7814C3.948 11.1792 4.10603 11.5608 4.38734 11.8421C4.66864 12.1234 5.05017 12.2814 5.448 12.2814H5.61466V13.9427L8.77167 12.2814H12.7813C12.9783 12.2814 13.1734 12.2426 13.3554 12.1672C13.5373 12.0919 13.7027 11.9814 13.842 11.8421C13.9813 11.7028 14.0918 11.5374 14.1672 11.3554C14.2425 11.1734 14.2813 10.9784 14.2813 10.7814V6.11475C14.2813 5.91776 14.2425 5.72271 14.1672 5.54072C14.0918 5.35873 13.9813 5.19337 13.842 5.05409C13.7027 4.9148 13.5373 4.80431 13.3554 4.72893C13.1734 4.65354 12.9783 4.61475 12.7813 4.61475ZM13.2813 10.7814C13.2813 10.914 13.2287 11.0412 13.1349 11.135C13.0411 11.2287 12.9139 11.2814 12.7813 11.2814H8.52433L6.61466 12.2867V11.2814H5.448C5.31539 11.2814 5.18821 11.2287 5.09444 11.135C5.00068 11.0412 4.948 10.914 4.948 10.7814V6.11475C4.948 5.98214 5.00068 5.85496 5.09444 5.76119C5.18821 5.66742 5.31539 5.61475 5.448 5.61475H12.7813C12.9139 5.61475 13.0411 5.66742 13.1349 5.76119C13.2287 5.85496 13.2813 5.98214 13.2813 6.11475V10.7814Z"
          fill="#1A1918"
        />
        <path
          d="M12.7813 4.61475H5.448C5.05017 4.61475 4.66864 4.77278 4.38734 5.05409C4.10603 5.33539 3.948 5.71692 3.948 6.11475V10.7814C3.948 11.1792 4.10603 11.5608 4.38734 11.8421C4.66864 12.1234 5.05017 12.2814 5.448 12.2814H5.61466V13.9427L8.77167 12.2814H12.7813C12.9783 12.2814 13.1734 12.2426 13.3554 12.1672C13.5373 12.0919 13.7027 11.9814 13.842 11.8421C13.9813 11.7028 14.0918 11.5374 14.1672 11.3554C14.2425 11.1734 14.2813 10.9784 14.2813 10.7814V6.11475C14.2813 5.91776 14.2425 5.72271 14.1672 5.54072C14.0918 5.35873 13.9813 5.19337 13.842 5.05409C13.7027 4.9148 13.5373 4.80431 13.3554 4.72893C13.1734 4.65354 12.9783 4.61475 12.7813 4.61475ZM13.2813 10.7814C13.2813 10.914 13.2287 11.0412 13.1349 11.135C13.0411 11.2287 12.9139 11.2814 12.7813 11.2814H8.52433L6.61466 12.2867V11.2814H5.448C5.31539 11.2814 5.18821 11.2287 5.09444 11.135C5.00068 11.0412 4.948 10.914 4.948 10.7814V6.11475C4.948 5.98214 5.00068 5.85496 5.09444 5.76119C5.18821 5.66742 5.31539 5.61475 5.448 5.61475H12.7813C12.9139 5.61475 13.0411 5.66742 13.1349 5.76119C13.2287 5.85496 13.2813 5.98214 13.2813 6.11475V10.7814Z"
          fill="url(#paint0_linear_5098_5160)"
        />
        <path
          d="M10.948 6.94808H7.28133V7.94808H10.948V6.94808Z"
          fill="#1A1918"
        />
        <path
          d="M10.948 6.94808H7.28133V7.94808H10.948V6.94808Z"
          fill="url(#paint1_linear_5098_5160)"
        />
        <path
          d="M9.948 8.94808H7.28133V9.94808H9.948V8.94808Z"
          fill="#1A1918"
        />
        <path
          d="M9.948 8.94808H7.28133V9.94808H9.948V8.94808Z"
          fill="url(#paint2_linear_5098_5160)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_5098_5160"
            x1="9.11466"
            y1="4.61475"
            x2="9.11466"
            y2="13.9427"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6F9EE9" />
            <stop offset="1" stopColor="#B38FEB" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5098_5160"
            x1="9.11466"
            y1="4.61475"
            x2="9.11466"
            y2="13.9427"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6F9EE9" />
            <stop offset="1" stopColor="#B38FEB" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_5098_5160"
            x1="9.11466"
            y1="4.61475"
            x2="9.11466"
            y2="13.9427"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6F9EE9" />
            <stop offset="1" stopColor="#B38FEB" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7813 4.61475H5.448C5.05017 4.61475 4.66864 4.77278 4.38734 5.05409C4.10603 5.33539 3.948 5.71692 3.948 6.11475V10.7814C3.948 11.1792 4.10603 11.5608 4.38734 11.8421C4.66864 12.1234 5.05017 12.2814 5.448 12.2814H5.61466V13.9427L8.77167 12.2814H12.7813C12.9783 12.2814 13.1734 12.2426 13.3554 12.1672C13.5373 12.0919 13.7027 11.9814 13.842 11.8421C13.9813 11.7028 14.0918 11.5374 14.1672 11.3554C14.2425 11.1734 14.2813 10.9784 14.2813 10.7814V6.11475C14.2813 5.91776 14.2425 5.72271 14.1672 5.54072C14.0918 5.35873 13.9813 5.19337 13.842 5.05409C13.7027 4.9148 13.5373 4.80431 13.3554 4.72893C13.1734 4.65354 12.9783 4.61475 12.7813 4.61475ZM13.2813 10.7814C13.2813 10.914 13.2287 11.0412 13.1349 11.135C13.0411 11.2287 12.9139 11.2814 12.7813 11.2814H8.52433L6.61466 12.2867V11.2814H5.448C5.31539 11.2814 5.18821 11.2287 5.09444 11.135C5.00068 11.0412 4.948 10.914 4.948 10.7814V6.11475C4.948 5.98214 5.00068 5.85496 5.09444 5.76119C5.18821 5.66742 5.31539 5.61475 5.448 5.61475H12.7813C12.9139 5.61475 13.0411 5.66742 13.1349 5.76119C13.2287 5.85496 13.2813 5.98214 13.2813 6.11475V10.7814Z"
        fill="#1A1918"
      />
      <path
        d="M12.7813 4.61475H5.448C5.05017 4.61475 4.66864 4.77278 4.38734 5.05409C4.10603 5.33539 3.948 5.71692 3.948 6.11475V10.7814C3.948 11.1792 4.10603 11.5608 4.38734 11.8421C4.66864 12.1234 5.05017 12.2814 5.448 12.2814H5.61466V13.9427L8.77167 12.2814H12.7813C12.9783 12.2814 13.1734 12.2426 13.3554 12.1672C13.5373 12.0919 13.7027 11.9814 13.842 11.8421C13.9813 11.7028 14.0918 11.5374 14.1672 11.3554C14.2425 11.1734 14.2813 10.9784 14.2813 10.7814V6.11475C14.2813 5.91776 14.2425 5.72271 14.1672 5.54072C14.0918 5.35873 13.9813 5.19337 13.842 5.05409C13.7027 4.9148 13.5373 4.80431 13.3554 4.72893C13.1734 4.65354 12.9783 4.61475 12.7813 4.61475ZM13.2813 10.7814C13.2813 10.914 13.2287 11.0412 13.1349 11.135C13.0411 11.2287 12.9139 11.2814 12.7813 11.2814H8.52433L6.61466 12.2867V11.2814H5.448C5.31539 11.2814 5.18821 11.2287 5.09444 11.135C5.00068 11.0412 4.948 10.914 4.948 10.7814V6.11475C4.948 5.98214 5.00068 5.85496 5.09444 5.76119C5.18821 5.66742 5.31539 5.61475 5.448 5.61475H12.7813C12.9139 5.61475 13.0411 5.66742 13.1349 5.76119C13.2287 5.85496 13.2813 5.98214 13.2813 6.11475V10.7814Z"
        fill="url(#paint0_linear_5098_4942)"
      />
      <path
        d="M10.948 6.94808H7.28133V7.94808H10.948V6.94808Z"
        fill="#1A1918"
      />
      <path
        d="M10.948 6.94808H7.28133V7.94808H10.948V6.94808Z"
        fill="url(#paint1_linear_5098_4942)"
      />
      <path d="M9.948 8.94808H7.28133V9.94808H9.948V8.94808Z" fill="#1A1918" />
      <path
        d="M9.948 8.94808H7.28133V9.94808H9.948V8.94808Z"
        fill="url(#paint2_linear_5098_4942)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5098_4942"
          x1="9.11467"
          y1="4.61475"
          x2="9.11467"
          y2="13.9427"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#035BFD" />
          <stop offset="1" stopColor="#D50A78" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5098_4942"
          x1="9.11467"
          y1="4.61475"
          x2="9.11467"
          y2="13.9427"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#035BFD" />
          <stop offset="1" stopColor="#D50A78" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5098_4942"
          x1="9.11467"
          y1="4.61475"
          x2="9.11467"
          y2="13.9427"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#035BFD" />
          <stop offset="1" stopColor="#D50A78" />
        </linearGradient>
      </defs>
    </svg>
  );
};
