import * as React from "react";
import { Box } from "@dropbox/dig-foundations";
import classNames from "classnames";
import style from "./Badge.module.css";
export interface BadgeProps
  extends Omit<React.HTMLAttributes<HTMLSpanElement>, "color"> {
  /**
   * The content of the badge. Ideally an `svg` or `img` element.
   */
  children?: React.ReactNode;
  /**
   * The position of the badge relative to the Layout.
   *
   * @default bottom-right
   */
  position?: "top-right" | "top-left" | "bottom-right" | "bottom-left";

  /**
   * Whether the badge should be styled inside of a rounded corner box.
   * If false, the badge will be displayed without any extra visual styling applied to it.
   *
   * This should be used if you have your own custom needs for a badge and don't want to use the default styling.
   *
   * @default true
   */
  isStyled?: boolean;
}

/**
 * A Subcomponent of IconLayout that displays a badge containing an icon, attached to one of the four corners of the Layout.
 */
export const _Badge: React.FC<BadgeProps> = ({
  children,
  className,
  position = "bottom-right",
  isStyled = true,
  ...rest
}) => {
  return (
    <Box
      as="span"
      position="relative"
      className={classNames(style["root"], className, {
        [style["root-position-top-right"]]: position === "top-right",
        [style["root-position-top-left"]]: position === "top-left",
        [style["root-position-bottom-right"]]: position === "bottom-right",
        [style["root-position-bottom-left"]]: position === "bottom-left",
        [style["not-styled"]]: !isStyled,
        [style["is-styled"]]: isStyled,
      })}
      {...rest}
    >
      <Box
        as="span"
        position="absolute"
        borderRadius={isStyled ? "Circular" : undefined}
        backgroundColor={isStyled ? "Background Base" : undefined}
        className={classNames(style["content"])}
      >
        {children}
      </Box>
    </Box>
  );
};
