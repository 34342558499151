// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3280
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';
import type { StackDetailsTourDismissButton } from '../enums/stack_details_tour_dismiss_button';

// Event_name: 'dismiss.dash_stack_details_tour'
// Description: Event fires when a user dismisses the stack details tour guide
// Owner: otc
export type Dismiss_DashStackDetailsTour = PAPEventGeneric<
  'dash',
  'dismiss',
  'dash_stack_details_tour',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Step index within the stack details tour guide
    stackDetailsTourStep?: number;
    // Type of action that triggered the tooltip dismiss
    stackDetailsTourDismissButton?: StackDetailsTourDismissButton;
  }
>;

export function PAP_Dismiss_DashStackDetailsTour(
  properties?: Dismiss_DashStackDetailsTour['properties'],
): Dismiss_DashStackDetailsTour {
  return <Dismiss_DashStackDetailsTour>{
    class: 'dash',
    action: 'dismiss',
    object: 'dash_stack_details_tour',
    properties,
  };
}
