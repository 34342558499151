export type CustomizeIconSvgName =
  | 'FileHistoryLine'
  | 'CalendarLine'
  | 'CreateStackLine';

export type CustomizableModuleIcon =
  | { type: 'url'; url: string }
  | { type: 'svg'; svgName: CustomizeIconSvgName };

export type CustomizableModule = {
  id: string;
  title: string;
  description: string;
  icon: CustomizableModuleIcon | undefined;
  canHide: boolean;
  isHidden?: boolean; // allow field overrides by not setting it
};

export type CustomizableModuleSettings = {
  customizableModules: CustomizableModule[];
  isCustomizableConnectorsCached: boolean;
};

export const enum DefaultCustomizableModuleId {
  RECENT_CONTENT = 'recentContent',
  CALENDAR = 'calendar',
  NEW_STACK_SUGGESTIONS = 'newStackSuggestions',
}
