import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CheckmarkCircleFill,
  ChevronDownLine,
  ChevronUpLine,
  CircleStandardLine,
} from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { DashGettingStartedChecklistItem } from '@mirage/analytics/events/enums/dash_getting_started_checklist_item';
import { PAP_Select_DashGettingStartedChecklistItemAction } from '@mirage/analytics/events/types/select_dash_getting_started_checklist_item_action';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useCallback } from 'react';
import styles from './ChecklistItem.module.css';
import {
  OnboardingChecklistAction,
  OnboardingChecklistStep,
} from './useOnboardingChecklist';

type ChecklistItemProps = {
  checklistItem: OnboardingChecklistStep;
  open: boolean;
  setOpen: (open: boolean) => void;
  onLearnMore: () => void;
};

export const ChecklistItem = ({
  checklistItem,
  open,
  setOpen,
  onLearnMore,
}: ChecklistItemProps) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { moduleId, title, message, actions, isComplete, infoLink } =
    checklistItem;

  const handleClick = useCallback(() => {
    if (open) return;
    setOpen(!open);
  }, [open, setOpen]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        setOpen(!open);
        break;
    }
  };

  const handleAction = (action: OnboardingChecklistAction) => {
    if (!action.action) return;
    reportPapEvent(
      PAP_Select_DashGettingStartedChecklistItemAction({
        dashGettingStartedChecklistItem:
          moduleId as DashGettingStartedChecklistItem,
        dashGettingStartedChecklistItemAction: action.key,
        actionSurfaceComponent: 'getting_started_checklist',
        featureLine: 'getting_started_checklist',
      }),
    );
    action.action();
  };

  if (!actions) return null;

  return (
    <div className={styles.wrapper}>
      <Box
        className={classnames(styles.container, open && styles.openContainer)}
        display="flex"
        flexDirection="column"
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
      >
        <Box
          className={styles.titleContainer}
          display="flex"
          flexDirection="row"
        >
          <Box className={styles.checkmarkIcon}>
            <UIIcon
              src={isComplete ? CheckmarkCircleFill : CircleStandardLine}
            />
          </Box>
          <Text
            className={styles.title}
            variant="label"
            size="small"
            isBold
            color={isComplete ? 'subtle' : undefined}
          >
            {title}
          </Text>
          <UIIcon
            className={isComplete ? styles.chevronComplete : ''}
            src={open ? ChevronUpLine : ChevronDownLine}
          />
        </Box>
        <Box
          className={classnames(styles.drawer, open && styles.drawerOpen)}
          display="flex"
          flexDirection="column"
        >
          <Text className={styles.message} size="small">
            {message}
          </Text>
          <Box className={styles.actionsContainer}>
            {actions.map((action) => {
              return (
                <Button
                  variant="primary"
                  key={action.label}
                  className={styles.primaryButton}
                  onClick={() => handleAction(action)}
                  size="small"
                  withIconStart={
                    action.iconStart ? (
                      <UIIcon src={action.iconStart} />
                    ) : undefined
                  }
                  withIconEnd={
                    action.markComplete ? (
                      <UIIcon color="#00864C" src={CheckmarkCircleFill} />
                    ) : undefined
                  }
                >
                  {action.label}
                </Button>
              );
            })}
            {infoLink && (
              <Button variant="transparent" onClick={onLearnMore} size="small">
                {i18n.t('get_started_learn_more')}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
