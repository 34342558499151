import { tagged } from '@mirage/service-logging';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';

import type { Observable, Subscription } from 'rxjs';

const logger = tagged('mirage/jobs');
const jobs: Map<string, Subscription> = new Map();

// proactively attempt to timeout and run jobs in the event they take longer
// than multiplier * interval so we do not end up in a state where a hanging job
// prevents future runs from completing
const FAILED_JOB_TIMEOUT_MULTIPLIER = 2;

export function register(
  name: string,
  intervalMs: number,
  runOnStart: boolean,
  callback: () => Promise<unknown> | Observable<unknown>,
  shouldTimeout: boolean = true,
): void {
  const current = jobs.get(name);
  if (current) {
    logger.warn(
      'registering duplicate "%s" job, exising job will be unregistered',
      name,
    );
    unregister(name);
  }

  const subscription = rx
    .merge(rx.interval(intervalMs), runOnStart ? rx.of(1) : rx.EMPTY)
    .pipe(
      // XXX: using exhaustMap here because we want to ignore timer fires if the
      // job has not yet completed
      op.exhaustMap(() => {
        logger.debug('running job "%s"', name);

        const t0 = performance.now();
        let failed = false;

        // optionally, automatically time each job run out after a multiplier
        // against its run interval
        const timeout = shouldTimeout
          ? op.timeout(intervalMs * FAILED_JOB_TIMEOUT_MULTIPLIER)
          : op.tap();

        return rx
          .from(callback())
          .pipe(timeout)
          .pipe(
            op.catchError((e) => {
              logger.info('"%s" caught error', name, e);
              failed = true;
              return rx.EMPTY;
            }),
          )
          .pipe(
            op.finalize(() => {
              const delta = performance.now() - t0;
              logger.debug(
                '"%s" %s after %dms',
                name,
                failed ? 'failed' : 'completed',
                delta,
              );
            }),
          );
      }),
    )
    .subscribe();

  jobs.set(name, subscription);
}

export function unregister(name: string): void {
  const subscription = jobs.get(name);
  if (!subscription) {
    logger.warn('attempting to unregister unknown job "%s"', name);
    return;
  }
  logger.debug('unregistering recurring job "%s"', name);
  subscription.unsubscribe();
  jobs.delete(name);
}
