import { Connector } from '@mirage/service-dbx-api/service';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import _ from 'lodash';
import { useMemo } from 'react';
import { RecentData } from '../service';
import { RecentConnectorContent, RecentContent } from '../types';
import { mockRecentData } from './recents-mock-data';
import {
  useRecentBrowserHistory,
  useRecentEntities,
} from './useRecentSubscriptions';

import type { dash_connectors } from '@dropbox/api-v2-client';

export const useCombinedRecents = (): RecentData<RecentContent> => {
  const browserHistory = useRecentBrowserHistory();
  const recentEntities = useRecentEntities();
  const dataSource = useFeatureFlagValue('dash_2024_08_06_recents_data_source');
  const recentsFallback = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_09_25_force_recents_fallback'),
  );

  // If true, we'll render "mock" data to be used during roadshow demos by Morgan/Umesh
  const shouldUseMockRecentsData = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_10_04_mock_recents_data'),
  );

  const recentData = useMemo(() => {
    const isLoading = browserHistory.isLoading || recentEntities.isLoading;
    const start: RecentContent[] = [];

    if (dataSource && dataSource !== 'CONTROL') {
      if (
        browserHistory.content.length > 0 &&
        (dataSource === 'V1' || dataSource === 'V2') &&
        !recentsFallback
      ) {
        // Use this only. Retrieves data from past 7 days
        return {
          isLoading: browserHistory.isLoading,
          content: browserHistory.content,
        };
      }

      if (recentEntities.content.length > 0) {
        // Use this only
        return {
          isLoading: recentEntities.isLoading,
          content: recentEntities.content,
        };
      }

      return { isLoading, content: start };
    } else {
      const content: RecentContent[] = _.chain(start)
        .concat(recentEntities.content)
        .concat(browserHistory.content)
        .uniqBy('url')
        .orderBy('visit_ms', 'desc')
        .value();

      return { isLoading, content };
    }
  }, [browserHistory, recentEntities, dataSource, recentsFallback]);

  if (shouldUseMockRecentsData) {
    return {
      isLoading: false,
      content: mockRecentData,
    };
  }

  return recentData;
};

export function isValidConnectorForRecentContents(connector: Connector) {
  // We already have a dedicated calendar widget for calendar events.
  return !connector.id_attrs?.type?.toLowerCase()?.includes('calendar');
}

export function filterRecentContents<T extends RecentContent>(
  recentContents: T[],
  connectors: Connector[],
): T[] {
  // Calendar updates are frequent and low value.
  const validConnectors = connectors.filter(isValidConnectorForRecentContents);

  const validConnectorNames = new Set(
    validConnectors.map((connector) => connector.id_attrs?.type ?? ''),
  );

  return (
    recentContents
      // Filter out local files
      .filter((content) => !content.url.startsWith('file://'))
      // Only show data from connectors the user has connected or data
      // from browsing history.
      .filter(
        (content) =>
          ('connectorName' in content.connectorInfo &&
            validConnectorNames.has(content.connectorInfo.connectorName)) ||
          content.connectorInfo.type === 'browser_history',
      )
  );
}

export function useTopContentByConnector(
  connectors: dash_connectors.Connector[],
) {
  const { content, isLoading } = useRecentEntities();

  const topContentByConnector = useMemo(() => {
    const recentConnectorContent = filterRecentContents(content, connectors);
    const MAX_CONNECTORS = 2;

    const topContentByConnector = _.chain(recentConnectorContent)
      .uniqBy('url')
      .uniqBy('title')
      .filter((c) => 'connectorName' in c.connectorInfo)
      .groupBy((c) => (c as RecentConnectorContent).connectorInfo.connectorName)
      .entries()
      .orderBy(([, content]) => content.length, 'desc')
      .take(MAX_CONNECTORS)
      .keyBy(([connectorName]) => connectorName)
      .value();

    return connectors
      .filter(
        (connector) =>
          isValidConnectorForRecentContents(connector) &&
          connector.id_attrs !== undefined &&
          connector.id_attrs.type !== undefined &&
          topContentByConnector[connector.id_attrs.type],
      )
      .map((connector) => {
        const key = connector.id_attrs?.type ?? '';
        return {
          key,
          // Potentially add account name here if multi-account?
          name: connector.branding?.display_name ?? '',
          content: topContentByConnector[key]?.[1] ?? [],
        };
      });
  }, [content, connectors]);

  return { connectors, content, isLoading, topContentByConnector };
}
