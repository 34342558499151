// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2756
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';

// Event_name: 'change.dash_search_query'
// Description: Fires when we detect a query change event and retrieve results from cache for typeahead
// Owner: otc
export type Change_DashSearchQuery = PAPEventGeneric<
  'dash',
  'change',
  'dash_search_query',
  {
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Identifier for a search query
    queryId?: number;
    // The contents of a search query
    queryString?: string;
    // Comma separated list of unique identifiers (UUIDv5) of the search results
    resultUuidList?: string;
    // Identifier for a search query
    searchQueryUuid?: string;
  }
>;

export function PAP_Change_DashSearchQuery(
  properties?: Change_DashSearchQuery['properties'],
): Change_DashSearchQuery {
  return <Change_DashSearchQuery>{
    class: 'dash',
    action: 'change',
    object: 'dash_search_query',
    properties,
  };
}
