// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgEdge({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.05 16.884c-.266.14-.541.263-.823.368a7.957 7.957 0 01-2.804.505c-3.697 0-6.917-2.543-6.917-5.806a2.46 2.46 0 011.284-2.133c-3.344.14-4.203 3.625-4.203 5.666 0 5.77 5.319 6.356 6.465 6.356.618 0 1.55-.18 2.109-.356l.102-.035a10.025 10.025 0 005.203-4.124.313.313 0 00-.416-.44z"
        fill="url(#edge_svg__paint0_linear)"
      />
      <path
        opacity={0.35}
        d="M20.05 16.884c-.266.14-.541.263-.823.368a7.957 7.957 0 01-2.804.505c-3.697 0-6.917-2.543-6.917-5.806a2.46 2.46 0 011.284-2.133c-3.344.14-4.203 3.625-4.203 5.666 0 5.77 5.319 6.356 6.465 6.356.618 0 1.55-.18 2.109-.356l.102-.035a10.025 10.025 0 005.203-4.124.313.313 0 00-.416-.44z"
        fill="url(#edge_svg__paint1_radial)"
      />
      <path
        d="M10.26 20.859c-.698-.433-1.301-1-1.777-1.667a6.306 6.306 0 012.307-9.374c.243-.115.66-.323 1.214-.313a2.527 2.527 0 012.006 1.016c.316.421.49.931.497 1.457 0-.016 1.91-6.217-6.25-6.217-3.428 0-6.248 3.254-6.248 6.109-.014 1.51.31 3.005.946 4.374a9.999 9.999 0 0012.215 5.243 5.9 5.9 0 01-4.904-.625l-.007-.003z"
        fill="url(#edge_svg__paint2_linear)"
      />
      <path
        opacity={0.41}
        d="M10.26 20.859c-.698-.433-1.301-1-1.777-1.667a6.306 6.306 0 012.307-9.374c.243-.115.66-.323 1.214-.313a2.527 2.527 0 012.006 1.016c.316.421.49.931.497 1.457 0-.016 1.91-6.217-6.25-6.217-3.428 0-6.248 3.254-6.248 6.109-.014 1.51.31 3.005.946 4.374a9.999 9.999 0 0012.215 5.243 5.9 5.9 0 01-4.904-.625l-.007-.003z"
        fill="url(#edge_svg__paint3_radial)"
      />
      <path
        d="M13.9 13.628c-.064.082-.258.196-.258.442 0 .204.133.4.368.565 1.124.781 3.241.678 3.247.678a4.652 4.652 0 002.364-.652A4.795 4.795 0 0022 10.531c.02-1.751-.625-2.915-.886-3.43C19.457 3.861 15.884 2 11.999 2A9.999 9.999 0 002 11.858c.038-2.854 2.875-5.16 6.25-5.16.273 0 1.832.027 3.28.787 1.277.67 1.945 1.48 2.41 2.282.483.834.569 1.887.569 2.306 0 .42-.214 1.041-.61 1.555z"
        fill="url(#edge_svg__paint4_radial)"
      />
      <path
        d="M13.9 13.628c-.064.082-.258.196-.258.442 0 .204.133.4.368.565 1.124.781 3.241.678 3.247.678a4.652 4.652 0 002.364-.652A4.795 4.795 0 0022 10.531c.02-1.751-.625-2.915-.886-3.43C19.457 3.861 15.884 2 11.999 2A9.999 9.999 0 002 11.858c.038-2.854 2.875-5.16 6.25-5.16.273 0 1.832.027 3.28.787 1.277.67 1.945 1.48 2.41 2.282.483.834.569 1.887.569 2.306 0 .42-.214 1.041-.61 1.555z"
        fill="url(#edge_svg__paint5_radial)"
      />
      <defs>
        <radialGradient
          id="edge_svg__paint1_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(7.45072 0 0 7.07818 14.281 15.94)"
        >
          <stop offset={0.72} stopOpacity={0} />
          <stop offset={0.95} stopOpacity={0.53} />
          <stop offset={1} />
        </radialGradient>
        <radialGradient
          id="edge_svg__paint3_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(-81.384 13.96 4.403) scale(11.2039 9.05162)"
        >
          <stop offset={0.76} stopOpacity={0} />
          <stop offset={0.95} stopOpacity={0.5} />
          <stop offset={1} />
        </radialGradient>
        <radialGradient
          id="edge_svg__paint4_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-.63252 15.81305 -33.67857 -1.34714 4.02 5.7)"
        >
          <stop stopColor="#35C1F1" />
          <stop offset={0.11} stopColor="#34C1ED" />
          <stop offset={0.23} stopColor="#2FC2DF" />
          <stop offset={0.31} stopColor="#2BC3D2" />
          <stop offset={0.67} stopColor="#36C752" />
        </radialGradient>
        <radialGradient
          id="edge_svg__paint5_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(73.74 5.012 17.859) scale(7.60383 6.18343)"
        >
          <stop stopColor="#66EB6E" />
          <stop offset={1} stopColor="#66EB6E" stopOpacity={0} />
        </radialGradient>
        <linearGradient
          id="edge_svg__paint0_linear"
          x1={6.587}
          y1={15.831}
          x2={20.518}
          y2={15.831}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0C59A4" />
          <stop offset={1} stopColor="#114A8B" />
        </linearGradient>
        <linearGradient
          id="edge_svg__paint2_linear"
          x1={13.931}
          y1={9.787}
          x2={5.23}
          y2={19.265}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B9DE2" />
          <stop offset={0.16} stopColor="#1595DF" />
          <stop offset={0.67} stopColor="#0680D7" />
          <stop offset={1} stopColor="#0078D4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgEdge;
