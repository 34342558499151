import { logPageLoadMilestone } from '@mirage/service-operational-metrics/page-load';
import {
  listPublishedContent,
  subscribeToPublishedContentsUpdates,
} from '@mirage/service-stacks';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import {
  publishedContentsAtom,
  useHydrateCompanyStacksPreviewData,
} from './hooks';

export const isPublishedContentsLoading = atom<boolean>(false);

export function useInitPublishedContentsData() {
  useInitPublishedContents();
}

function useInitPublishedContents() {
  const [publishedContents, setPublishedContents] = useAtom(
    publishedContentsAtom,
  );
  const publishedContentInitialized = useRef(false);

  const setIsPublishedContentsLoading = useSetAtom(isPublishedContentsLoading);
  const { callPreviewStacksForNotCached } =
    useHydrateCompanyStacksPreviewData();

  // Subscribe to updates.
  useEffect(() => {
    let canceled = false;

    logPageLoadMilestone(
      `useInitPublishedContents subscribeToPublishedContentsUpdates`,
    );

    const subscription = subscribeToPublishedContentsUpdates((content) => {
      if (canceled) return;
      setPublishedContents(content);
    });

    return () => {
      canceled = true;
      subscription.unsubscribe();
    };
  }, [setPublishedContents]);

  // Fetch initial data.
  useEffect(() => {
    let canceled = false;

    async function init() {
      setIsPublishedContentsLoading(true);
      logPageLoadMilestone(
        `useInitPublishedContents listPublishedContent start`,
      );
      try {
        const contents = await listPublishedContent(true);
        setIsPublishedContentsLoading(false);
        if (canceled) return;

        logPageLoadMilestone(
          `useInitPublishedContents publishedContentsAtom: num=${contents?.length}`,
        );
        setPublishedContents(contents || []);
        callPreviewStacksForNotCached(contents || []);
        publishedContentInitialized.current = true;
      } catch (e) {
        setIsPublishedContentsLoading(false);
      }
    }

    if (!publishedContentInitialized.current) {
      void init();
    }

    return () => {
      canceled = true;
    };
  }, [
    callPreviewStacksForNotCached,
    setIsPublishedContentsLoading,
    setPublishedContents,
  ]);

  return publishedContents;
}

export function usePublishedContents() {
  return useAtomValue(publishedContentsAtom);
}
