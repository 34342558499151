// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3044
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'delete.dash_link_description'
// Description: Fires when a user deletes a description for a link
// Owner: dash-web
export type Delete_DashLinkDescription = PAPEventGeneric<
  'stacks',
  'delete',
  'dash_link_description',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // The stack id that represents the stack
    stackId?: string;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // marks if the content is shared content
    isShared?: boolean;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // User_id associated with the creator of the content being acted upon
    creatorId?: string;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // Whether the user is interacting with their welcome stack
    isWelcomeStack?: boolean;
  }
>;

export function PAP_Delete_DashLinkDescription(
  properties?: Delete_DashLinkDescription['properties'],
): Delete_DashLinkDescription {
  return <Delete_DashLinkDescription>{
    class: 'stacks',
    action: 'delete',
    object: 'dash_link_description',
    properties,
  };
}
