import { useEffect, useRef, useState } from 'react';

/*
 * This hook calculates the height of the first `lineCount` lines of a div,
 * accounting for variable line heights, as well as padding and margins,
 * but only for the lines that are being counted.
 */

export const useVariableLineHeight = (lineCount: number, content: string) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number>(0);
  const [overflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const div = ref.current;
      const children = Array.from(div.childNodes).filter(
        (node): node is HTMLElement => node.nodeType === Node.ELEMENT_NODE,
      );
      let totalHeight = 0;
      let linesMeasured = 0;

      for (const child of children) {
        const computedStyle = window.getComputedStyle(child);
        const childHeight = child.clientHeight;
        const lineHeight = parseFloat(computedStyle.lineHeight);
        // Extract padding and margin values
        const paddingTop = parseFloat(computedStyle.paddingTop);
        const paddingBottom = parseFloat(computedStyle.paddingBottom);
        const marginTop = parseFloat(computedStyle.marginTop);
        const marginBottom = parseFloat(computedStyle.marginBottom);

        const extraSpacePerLine =
          (paddingTop + paddingBottom + marginTop + marginBottom) /
          Math.max(1, Math.round(childHeight / lineHeight));

        if (!isNaN(lineHeight)) {
          const linesInChild = Math.round(childHeight / lineHeight);
          const linesToAdd = Math.min(lineCount - linesMeasured, linesInChild);

          // Only add padding/margin for the lines we're counting
          totalHeight +=
            lineHeight * linesToAdd + (linesToAdd - 1) * extraSpacePerLine;
          linesMeasured += linesToAdd;

          // Reset overflow state if new body content reduces total height
          if (overflowing && totalHeight < height) {
            setIsOverflowing(false);
          }

          if (linesMeasured >= lineCount) {
            setIsOverflowing(true);
            break;
          }
        }
      }
      setHeight(totalHeight);
    }
  }, [lineCount, content]);

  return { ref, height, overflowing };
};
