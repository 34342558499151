// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2833
import type { PAPEventGeneric } from '../base/event';
import type { FeatureLine } from '../enums/feature_line';

// Event_name: 'cancel.update_stack_emoji'
// Description: Fires when a user clicks away from the emoji picker to cancel it
// Owner: dash-web
export type Cancel_UpdateStackEmoji = PAPEventGeneric<
  'stacks',
  'cancel',
  'update_stack_emoji',
  {
    // Feature Line for dash
    featureLine?: FeatureLine;
    // The stack id that represents the stack
    stackId?: string;
    // marks if the content is shared content
    isShared?: boolean;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
  }
>;

export function PAP_Cancel_UpdateStackEmoji(
  properties?: Cancel_UpdateStackEmoji['properties'],
): Cancel_UpdateStackEmoji {
  return <Cancel_UpdateStackEmoji>{
    class: 'stacks',
    action: 'cancel',
    object: 'update_stack_emoji',
    properties,
  };
}
