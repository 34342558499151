import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { APIv2Callable } from '@mirage/service-dbx-api/service';
import { ONE_MINUTE_IN_MILLIS } from '@mirage/shared/util/constants';
import WithDefaults from '@mirage/storage/with-defaults';
import { EventEmitter } from 'eventemitter3';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import { UrlMetadata } from '../types';
import { getMetadataForUrls } from './api';

import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

const TIME_TO_LIVE = ONE_MINUTE_IN_MILLIS * 5;

export type Service = {
  metadataForUrls(urls: string[]): Observable<{ [key: string]: UrlMetadata }>;
};

export type StoredUrlMetadata = {
  urlMetadata: { [key: string]: UrlMetadata };
};

type DbxApiServiceContract = {
  callApiV2: APIv2Callable;
};

export default function provideUrlMetadataService(
  rawStorage: KVStorage<StoredUrlMetadata>,
  { callApiV2 }: DbxApiServiceContract,
) {
  const adapter = new WithDefaults(rawStorage, {
    urlMetadata: {},
  });
  const emitter = new EventEmitter<{ update: void }>();

  async function refreshNecessaryUrls(urls: string[]) {
    const necessaryUrls: string[] = [];
    const existingMetadataMap = await urlMetadataForUrls(urls);

    for (const url of urls) {
      if (url in existingMetadataMap) {
        const existingMetadata = existingMetadataMap[url];
        // If the metadata is stale, we need to fetch this
        if (Date.now() - existingMetadata.lastFetched > TIME_TO_LIVE) {
          necessaryUrls.push(url);
        }
      } else {
        // If there is no metadata, we need to fetch this
        necessaryUrls.push(url);
      }
    }

    // If we have up to date metadata on all urls, do nothing
    if (necessaryUrls.length === 0) {
      return;
    }

    // Make the api call
    const newMetadata = await getMetadataForUrls(necessaryUrls, callApiV2);
    if (newMetadata === undefined || newMetadata.length === 0) {
      return;
    }

    // Add any new metadata to the map and write it. Then emit an update
    const allPreviousMetadata = await allUrlMetadata();
    let metadataChanged = false;
    for (const metadata of newMetadata) {
      const oldMetadata = allPreviousMetadata[metadata.url];
      const oldMetadataWithoutLastFetched = omit(oldMetadata, 'lastFetched');
      const metadataWithoutLastFetched = omit(metadata, 'lastFetched');

      if (!isEqual(oldMetadataWithoutLastFetched, metadataWithoutLastFetched)) {
        // update full metadata cache with new metadata values
        metadataChanged = true;
      }
      allPreviousMetadata[metadata.url] = metadata;
    }
    await adapter.set('urlMetadata', allPreviousMetadata);
    // only emit update if metadata has changed
    if (metadataChanged) {
      emitter.emit('update');
    }
  }

  async function allUrlMetadata(): Promise<{ [key: string]: UrlMetadata }> {
    return await adapter.get('urlMetadata');
  }

  async function urlMetadataForUrls(
    urls: string[],
  ): Promise<{ [key: string]: UrlMetadata }> {
    const allMetadata = await allUrlMetadata();
    const filteredMetadata: { [key: string]: UrlMetadata } = {};
    for (const url of urls) {
      if (url in allMetadata) {
        filteredMetadata[url] = allMetadata[url];
      }
    }

    return filteredMetadata;
  }

  function metadataForUrls(
    urls: string[],
  ): Observable<{ [key: string]: UrlMetadata }> {
    // Get fresh urls as needed
    refreshNecessaryUrls(urls);

    const changes$ = rx.fromEvent(emitter, 'update') as Observable<void>;

    return rx
      .from(urlMetadataForUrls(urls))
      .pipe(
        op.mergeWith(
          changes$.pipe(op.concatMap(() => urlMetadataForUrls(urls))),
        ),
      );
  }

  services.provide<Service>(
    ServiceId.URL_METADATA,
    {
      metadataForUrls,
    },
    [ServiceId.DBX_API],
  );
}
