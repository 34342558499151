export function reorder<T>(
  items: T[],
  fromIndex: number,
  toIndex: number,
): T[] {
  const result = Array.from(items);
  const [removed] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, removed);
  return result;
}
