// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1851
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';

// Event_name: 'unpin.dash_stack'
// Description: Fires when user unpins a dash stack
// Owner: otc
export type Unpin_DashStack = PAPEventGeneric<
  'stacks',
  'unpin',
  'dash_stack',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The stack id that represents the stack
    stackId?: string;
    // User_id associated with the creator of the content being acted upon
    creatorId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
  }
>;

export function PAP_Unpin_DashStack(
  properties?: Unpin_DashStack['properties'],
): Unpin_DashStack {
  return <Unpin_DashStack>{
    class: 'stacks',
    action: 'unpin',
    object: 'dash_stack',
    properties,
  };
}
