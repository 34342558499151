import { type LogObject } from 'consola';
import { serializeError } from 'serialize-error';
import { vsprintf } from 'sprintf-js';

import type { LogMessage } from '@mirage/service-logging/service';

//
// internal helper api
// (to make sure we don't shoot ourselves in the foot)
//------------------------------------------------------------------------------
// not everything can be sent over ipc, attempt a structured clone like the ipc
// channel will do behind the scenes and see if it fails!
export function serializable(log: LogObject) {
  try {
    structuredClone(log);
  } catch (e) {
    return false;
  }
  return true;
}

export function normalize(log: LogObject): LogMessage {
  const [message, extra] = flatten(log);
  return {
    message,
    extra,
    date: log.date,
    level: log.type,
    label: log.tag,
  };
}

const formatRegExp = /%[scdjifoO%]/g;
const escapedPercent = /%%/g;

// sprintf and some other message concatenation to make reported logs look more
// normal when not using string interpolation with template strings
type Flattened = [string, Array<unknown | object | Array<unknown>>];
export function flatten(log: LogObject): Flattened {
  const [first, ...splat] = log.args;

  // in the event the first entry in the args array is an object, default to
  // serializing via json since we have no good way to manage integrating it
  if (first instanceof Object) return [JSON.stringify(log.args), []];

  // attempt to perform string replacement
  let msg = first;
  const tokens = msg && msg.match && msg.match(formatRegExp);
  if (tokens) {
    const percents = msg.match(escapedPercent);
    const escapes = (percents && percents.length) || 0;
    const args = splat.splice(0, tokens.length - escapes);
    msg = vsprintf(msg, args);
  }

  // if there are remaining splat values after handling string replacement,
  // attempt to concatenate them with the message
  while (splat.length && !(splat[0] instanceof Object)) {
    msg = `${msg} ${splat.splice(0, 1)[0]}`;
  }

  // attempt to keep error information (since it will be lost going over ipc)
  // XXX: i previously had iterated across objects looking for errors, but this
  // is probably _good enough_ without writing a serialization library :|
  const mapped = splat.map((value) => {
    return value instanceof Error ? serializeError(value) : value;
  });

  return [msg, mapped];
}
