import { DASH_APP_DWELL_END_REASONS } from '@mirage/analytics/session/session-utils';
import { tagged } from '@mirage/service-logging';
import { useEffect, useRef } from 'react';
import { dashAppDwellManager } from './sessionManagers';

const logger = tagged('StartPageFocusStateLoader');

export const StartPageFocusStateLoader: React.FC = () => {
  const hasLoggedLoad = useRef(false);

  const logStartSession = () => {
    dashAppDwellManager.extendOrCreateSession();
  };

  const logVisibilityHidden = () => {
    if (document.hidden) {
      logger.debug('StartPageFocusStateLoader', 'visibility change');
      dashAppDwellManager.endSession(DASH_APP_DWELL_END_REASONS.LOST_FOCUS);
    }
  };

  const logBlur = () => {
    logger.debug('StartPageFocusStateLoader', 'blur');
    dashAppDwellManager.endSession(DASH_APP_DWELL_END_REASONS.LOST_FOCUS);
  };

  const logUnload = () => {
    logger.debug('StartPageFocusStateLoader', 'unload');
    dashAppDwellManager.endSession(DASH_APP_DWELL_END_REASONS.EXIT_PAGE);
  };

  useEffect(() => {
    logger.debug('StartPageFocusStateLoader', 'onload');
    // Make sure we only log the load event once
    if (!hasLoggedLoad.current) {
      logStartSession();
      hasLoggedLoad.current = true;
    }

    window.addEventListener('focus', logStartSession);
    window.addEventListener('visibilitychange', logVisibilityHidden);
    window.addEventListener('blur', logBlur);
    window.addEventListener('beforeunload', logUnload);
    return () => {
      logger.debug('StartPageFocusStateLoader', 'cleanup');
      window.removeEventListener('focus', logStartSession);
      window.removeEventListener('visibilitychange', logVisibilityHidden);
      window.removeEventListener('blur', logBlur);
      window.removeEventListener('beforeunload', logUnload);
    };
  }, []);

  return null;
};
