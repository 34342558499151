import * as React from "react";
import { atoms } from "@dropbox/dig-foundations";
import classNames from "classnames";
import css from "./Label.module.css";
import { useResizeObserver } from "@react-hookz/web";
import { Tooltip, type ControlProps } from "@dropbox/dig-components/tooltips";

type PolymorphicProps<E extends React.ElementType> = React.PropsWithChildren<
  React.ComponentPropsWithoutRef<E> & {
    as?: E;
  }
>;

export type LabelProps<T extends React.ElementType> = PolymorphicProps<T> & {
  /**
   * The content of the label, although this takes a `React.ReactNode`, it is recommended, if possible, to only pass a `string`, or otherwise unstyled `span` to this prop.
   * If you need to make this element an anchor or button, please use the `as` prop on this component instead.
   * This is because the `children` prop can end up in a `Tooltip.Control` component.
   */
  children: React.ReactNode;
  className?: string;
  /**
   * Props to pass to the tooltip control component.
   * This is a partial because the default props account for the `triggerRef`, `placement`, and `open` props.
   */
  tooltipControlProps?: Partial<ControlProps>;
  /**
   * Will be called when the label detects that its text content is overflowing its container.
   */
  onOverflow?: ({ target }: { target: HTMLElement }) => void;
};

const focusStyling = atoms({
  outline: "none",
  color: { active: "Text Base" },
});

/**
 * The `Label` of the `Metadata.Item` component.
 * Please use this for any text content within the `Metadata.Item` component.
 */
export const _Label = <T extends React.ElementType = "span">({
  children,
  className,
  as,
  tooltipControlProps,
  onOverflow,
  ...props
}: LabelProps<T>) => {
  const ref = React.useRef<HTMLSpanElement>(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  useResizeObserver(ref, (entry) => {
    const { target } = entry;

    if (target instanceof HTMLElement) {
      setIsOverflowing(target.offsetWidth < target.scrollWidth);
      if (onOverflow) onOverflow({ target });
    }
  });
  const handleIsHovered = () => {
    if (!isOverflowing) {
      return;
    }
    setIsFocused(true);
  };
  const handleIsNotHovered = () => {
    if (!isOverflowing) {
      return;
    }
    setIsFocused(false);
  };

  const PolymorphicComponent = as || "span";
  const isInteractive = as === "a" || as === "button";

  return (
    <>
      {isOverflowing && (
        <Tooltip.Control
          triggerRef={ref}
          placement="top"
          isPortaled={true}
          open={isFocused}
          auto={true}
          shouldReturnFocus={false}
          openDelay={250}
          {...tooltipControlProps}
        >
          {children}
        </Tooltip.Control>
      )}
      <PolymorphicComponent
        {...props}
        onPointerEnter={() => {
          handleIsHovered();
          if (props.onPointerEnter) props.onPointerEnter();
        }}
        onPointerLeave={() => {
          handleIsNotHovered();
          if (props.onPointerLeave) props.onPointerLeave();
        }}
        onFocus={() => {
          handleIsHovered();
          if (props.onFocus) props.onFocus();
        }}
        onBlur={() => {
          handleIsNotHovered();
          if (props.onBlur) props.onBlur();
        }}
        className={classNames(css.labelRoot, className, {
          [css.isInteractive]: isInteractive,
          [focusStyling]: isInteractive,
        })}
      >
        <span ref={ref} className={css.overflowEllipsis}>
          {children}
        </span>
      </PolymorphicComponent>
    </>
  );
};
