import { Chip } from '@dropbox/dig-components/chip';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  StarFill,
  StarLine,
  ThumbsDownFill,
  ThumbsDownLine,
  ThumbsUpFill,
  ThumbsUpLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Select_DashSearchResultLabels } from '@mirage/analytics/events/types/select_dash_search_result_labels';
import { showSnackbar } from '@mirage/shared/snackbar';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { useState } from 'react';
import styles from './ResultAnnotations.module.css';

import type { ChipVariant } from '@dropbox/dig-components/chip';
import type { SearchResult } from '@mirage/service-dbx-api';

export enum AnnotationScore {
  ThumbsDown = 0,
  ThumbsUp = 1,
  Best = 2,
}

type ResultAnnotationsProps = {
  resultPosition: number;
  result: SearchResult;
  query: string;
};

export const feedbackConfig = [
  {
    title: 'Perfect',
    defaultIcon: StarLine,
    selectedIcon: StarFill,
    type: AnnotationScore.Best,
    tooltipTitle: 'Exactly what I was looking for',
  },
  {
    title: 'Relevant',
    defaultIcon: ThumbsUpLine,
    selectedIcon: ThumbsUpFill,
    type: AnnotationScore.ThumbsUp,
    tooltipTitle:
      'Somewhat relevant and should appear in search results or answer',
  },
  {
    title: 'Irrelevant',
    defaultIcon: ThumbsDownLine,
    selectedIcon: ThumbsDownFill,
    type: AnnotationScore.ThumbsDown,
    tooltipTitle: "Unrelated and shouldn't appear in search results or answer",
  },
];

export const ResultAnnotations = ({
  resultPosition,
  result,
  query,
}: ResultAnnotationsProps) => {
  const { reportPapEvent, searchSessionManager, searchAttemptSessionManager } =
    useMirageAnalyticsContext();
  const { uuid: resultUuid, searchRequestId } = result;
  const [selectedScore, setSelectedScore] = useState<
    AnnotationScore | undefined
  >(undefined);

  const handleSelect = (feedbackScore: AnnotationScore) => {
    if (feedbackScore === selectedScore) {
      return;
    }

    setSelectedScore(feedbackScore);
    reportPapEvent(
      PAP_Select_DashSearchResultLabels({
        queryString: query,
        resultUuid,
        feedbackScore,
        resultPosition,
        searchRequestId,
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        searchAttemptId: searchAttemptSessionManager.getSessionIdOrUndefined(),
      }),
    );

    showSnackbar({
      title:
        'Annotation submitted! Thanks for helping us improve search results.',
    });
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={styles.container}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Text size="small" isBold className={styles.text}>
        Feedback:
      </Text>
      {feedbackConfig.map((item) => (
        <FeedbackChip
          key={item.type}
          item={item}
          selectedScore={selectedScore}
          handleSelect={handleSelect}
        />
      ))}
    </div>
  );
};

type FeedbackChipProps = {
  item: (typeof feedbackConfig)[0];
  selectedScore: AnnotationScore | undefined;
  handleSelect: (score: AnnotationScore) => void;
  variant?: ChipVariant;
};

export function FeedbackChip({
  item,
  selectedScore,
  handleSelect,
  variant = 'attention',
}: FeedbackChipProps) {
  return (
    <DigTooltip title={item.tooltipTitle} key={item.type}>
      <Chip
        variant={variant}
        onClick={() => {
          handleSelect(item.type);
        }}
        isSelected={selectedScore === item.type}
        type="button"
      >
        <Chip.IconAccessory>
          <UIIcon
            size="small"
            src={
              selectedScore === item.type ? item.selectedIcon : item.defaultIcon
            }
          />
        </Chip.IconAccessory>
        <Chip.Content>{item.title}</Chip.Content>
      </Chip>
    </DigTooltip>
  );
}
