import { useMediaQuery } from '@react-hookz/web';
import { useEffect, useState } from 'react';
import useSettings from '../useSettings';
import { Theme } from './types';

export function useTheme() {
  const isDarkOS = useMediaQuery('(prefers-color-scheme: dark)') ?? false;
  const [isDarkMode, setIsDarkMode] = useState(isDarkOS);
  const { settings } = useSettings(['enableDarkMode']);

  useEffect(() => {
    if (settings) {
      const theme = settings.enableDarkMode;
      switch (theme) {
        case Theme.Auto:
          setIsDarkMode(!!isDarkOS);
          break;
        case Theme.Dark:
          setIsDarkMode(true);
          break;
        case Theme.Light:
          setIsDarkMode(false);
          break;
      }
    }
  }, [settings, isDarkOS]);

  return isDarkMode;
}
