import {
  dash,
  ml_capabilities,
} from '@dropbox/api-v2-client/types/dropbox_types';
import { FeatureLine } from '@mirage/analytics/events/enums/feature_line';
import { PAP_Interact_SearchCall } from '@mirage/analytics/events/types/interact_search_call';
import { callAnswersAPI } from '@mirage/mosaics/ComposeAssistant/data/llm/tools/search-tool-apis';
import { ToolCallArgs } from '@mirage/mosaics/ComposeAssistant/data/llm/tools/tools-types';
import {
  QuestionAndAnswerResponse,
  QuestionAndAnswerSource,
} from '@mirage/shared/answers/multi-answer';
import { ComposeSource } from '@mirage/shared/compose/compose-session';
import i18n from '@mirage/translations';
import { reportEvent } from '@mirage/webapp/analytics/logging';
import * as Runtype from 'runtypes';

const SearchDashArgs = Runtype.Record({
  keyword_queries: Runtype.Array(Runtype.String),
  question: Runtype.String,
});
type SearchDashArgs = Runtype.Static<typeof SearchDashArgs>;

export async function handleSearchDashCall({
  toolCallID,
  argumentsString,
  responseMessage,
  onResponse,
}: ToolCallArgs): Promise<ml_capabilities.ChatMessage[]> {
  onResponse({
    type: 'update_progress',
    progressString: i18n.t('assistant_progress_message_searching_dash'),
  });
  const { question } = parseSearchDashArgs(argumentsString);
  onResponse({
    type: 'dash_searched',
    queries: [question],
  });

  // this ignores the `keyword_queries` for now, as the answers API doesn't do mult-question search
  const answers = await callAnswersAPI(question);
  const answersPromptString =
    getAnswersPromptString(answers) || 'Unable to find relevant results';
  const sources = getAnswersSources(answers);
  if (answers.length > 0) {
    onResponse({
      type: 'analyzed_search_results',
      rawPromptText: answersPromptString,
      sources,
    });
    reportEvent(
      PAP_Interact_SearchCall({
        eventState: 'success',
        actionSurface: 'compose_page',
        dashActionSurface: 'compose_page',
        featureLine: 'assist' as FeatureLine,
        actionSurfaceComponent: 'compose_chat_pane',
        queryString: question,
        queryLength: question.length,
        searchResultCount: sources.length,
        resultUuidList: sources
          .map((source) =>
            'searchResult' in source ? source?.searchResult?.uuid : '',
          )
          .join(','),
      }),
    );
  } else {
    reportEvent(
      PAP_Interact_SearchCall({
        actionSurface: 'compose_page',
        dashActionSurface: 'compose_page',
        featureLine: 'assist' as FeatureLine,
        eventState: 'failed',
        actionSurfaceComponent: 'compose_chat_pane',
        queryString: question,
        queryLength: question.length,
        searchResultCount: sources.length,
        resultUuidList: sources
          .map((source) =>
            'searchResult' in source ? source?.searchResult?.uuid : '',
          )
          .join(','),
      }),
    );
  }
  onResponse({
    type: 'update_progress',
    progressString: i18n.t('assistant_progress_message_summarizing_findings'),
  });

  // return pending tool result to get Assistant to product a response using the extracted texts
  const toolResponseMessage: ml_capabilities.ChatMessage = {
    '.tag': 'tool_message',
    tool_call_id: toolCallID,
    content: {
      text: `Response to search_dash call:
  ${answersPromptString}`,
    },
  };

  return [responseMessage, toolResponseMessage];
}

/*
// TODO:(rich) this is left as reference, remove this once we have ability to update progress from Answers API
export async function handleSearchDashCallV0({
  toolCallID,
  argumentsString,
  responseMessage,
  onResponse,
}: ToolCallArgs): Promise<ml_capabilities.ChatMessage[]> {
  const { keyword_queries, question } = parseSearchDashArgs(argumentsString);

  const searchResults = await runMultiSearch(keyword_queries);
  const searchResultContents = (
    await Promise.all(searchResults.map((result) => getContent(result.uuid!)))
  ).filter((contentResult) => contentResult.type === 'success');

  onResponse({
    type: 'dash_searched',
    queries: keyword_queries,
  });
  let numExtracted = 0;
  const updateExtractionProgress = () =>
    onResponse({
      type: 'update_progress',
      progressString: i18n.t('assistant_progress_message_analyzing_content', {
        totalCount: searchResultContents.length,
        doneCount: numExtracted,
      }),
    });
  updateExtractionProgress();

  // extract relevant texts from the fetched contents
  const sources: SourceAndContent[] = [];
  searchResultContents.forEach((contentResult, i) => {
    if (contentResult.type === 'success') {
      sources.push({
        title: searchResults[i].title || '',
        url: searchResults[i].url || '',
        content: contentResult.content,
        serachResult: searchResults[i],
      });
    }
  });
  const sourcesWithRelevantTexts = (
    await Promise.all(
      sources.map(async (source) => {
        const relevantTextResults = await extractRelevantText(
          source.content,
          question,
        );
        numExtracted++;
        updateExtractionProgress();
        return {
          source,
          relevantTextResults,
        };
      }),
    )
  ).filter(
    (sourceWithRelevantText) =>
      sourceWithRelevantText.relevantTextResults.length > 0,
  );

  // done analyzing contents for relevant texts, update message history + progress
  const sourcePromptString = getSourcesAndExtractedTextPromptString(
    sourcesWithRelevantTexts,
  );
  onResponse({
    type: 'analyzed_search_results',
    rawPromptText: sourcePromptString,
    sources: sourcesWithRelevantTexts.map((sourceWithRelevantText) => ({
      type: 'dash_search_result',
      searchResult: sourceWithRelevantText.source.serachResult,
    })),
  });
  onResponse({
    type: 'update_progress',
    progressString: i18n.t('assistant_progress_message_summarizing_findings'),
  });

  // return pending tool result to get Assistant to product a response using the extracted texts
  const toolResponseMessage: ml_capabilities.ChatMessage = {
    '.tag': 'tool_message',
    tool_call_id: toolCallID,
    content: {
      text: `Response to search_dash call:
  ${sourcePromptString}`,
    },
  };

  return [responseMessage, toolResponseMessage];
}
*/

export function parseSearchDashArgs(argumentsString: string): SearchDashArgs {
  return SearchDashArgs.check(JSON.parse(argumentsString));
}

function getAnswersPromptString(
  answers: QuestionAndAnswerResponse[],
): string | undefined {
  if (answers.length === 0) {
    return undefined;
  }
  const sourcePromptStrings: string[] = [];
  for (const answer of answers) {
    sourcePromptStrings.push(`
Question: ${answer.question}
# START OF ANSWER:
${answer.answer}
# END OF ANSWER
Sources:
${answer.sources
  .map((source) => `Title: ${source.title}\n    URL: ${source.url}`)
  .join('\n')}
`);
  }
  return sourcePromptStrings.join('\n\n');
}

function getAnswersSources(answers: QuestionAndAnswerResponse[]) {
  const sources: ComposeSource[] = [];
  for (const answer of answers) {
    for (const source of answer.sources) {
      const searchResult = transformAnswerSource(source);
      if (searchResult) {
        sources.push({
          type: 'dash_search_result',
          searchResult,
        });
      }
    }
  }
  return sources;
}

// TODO:(rich) the type doesn't quite match up with search results.. may need to either add
// that as a source type we support; or figure out a better way to convert them
function transformAnswerSource(
  source: QuestionAndAnswerSource,
): dash.query_result_unionSearchResult | undefined {
  if (!source.uuid) {
    return undefined;
  }
  return {
    '.tag': 'search_result',
    uuid: source.uuid,
    title: source.title,
    url: source.url,
    record_type: {
      '.tag': 'unknown_record_type',
    },
    connector_info: {
      connector_id: source.connectorInfo?.connector_id,
      connector_name: source.connectorInfo?.connector_name,
      connector_type: {
        '.tag': 'unknown_provider',
      },
    },
  };
}
