import i18n from '@mirage/translations';
import { getTimeAgoString } from './time';
import { urlToDomainName, urlToService } from './urlRulesUtil';

export const getStackItemMetadata = (item: {
  url?: string;
  last_modified_time_utc_sec?: number;
}): string[] | undefined => {
  return item.last_modified_time_utc_sec
    ? [
        item.url ? urlToService(item.url) || urlToDomainName(item.url) : '',
        i18n.t('stack_item_updated_ago', {
          timeAgo: getTimeAgoString(
            item.last_modified_time_utc_sec * 1000,
            false,
            true,
          ),
        }),
      ]
    : undefined;
};
