import throttle from 'lodash/throttle';
import React from 'react';

import type { TypeaheadResult } from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';

/**
 * This is a scrollTo hook specific to typeahead. Since the overlay is portaled we have to
 * hardcode the height (getBoundingClientRect) returns height of 0
 *
 * Can be reporposed as a general scrollToListItem hook by updating "isOverBottom" calc
 */

export function useScrollToTypeaheadItem(
  selectedIdx: number,
  listItems: Array<TypeaheadResult>,
) {
  const [itemRefs, setItemRefs] = React.useState(
    listItems.map((_item) => React.createRef<HTMLLIElement>()),
  );

  React.useEffect(() => {
    setItemRefs(listItems.map((_item) => React.createRef<HTMLLIElement>()));
  }, [listItems]);

  const listContainerRef = React.useRef(null);

  const listRect = useRect(listContainerRef);

  function scrollToItem(index: number) {
    if (index === -1) return;
    const item = itemRefs[index];

    if (!item || !item.current || !listContainerRef.current) {
      return;
    }
    const itemRect = item.current && item.current.getBoundingClientRect();
    // check to see if the item is already entirely within the parent viewport
    // in this case the scrolling operation is a noop
    const isUnderTop = itemRect.top >= listRect.y;
    // The Overlay is portaled so height needs to be hardcoded to the portled overlay height
    const isOverBottom = itemRect.bottom <= 348;
    if (isUnderTop && isOverBottom) {
      return;
    }

    if (item.current) item.current.scrollIntoView({ block: 'nearest' });
  }

  React.useEffect(() => {
    scrollToItem(selectedIdx);
  }, [selectedIdx]);

  return {
    itemRefs,
    listContainerRef,
  };
}

const placeholderLocation = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
};

function useRect(ref: React.RefObject<HTMLElement>) {
  const [rect, setRect] = React.useState(getRect(ref ? ref.current : null));

  const handleUpdate = () => {
    if (ref.current) {
      // Update client rect
      setRect(getRect(ref.current));
    }
  };

  React.useEffect(() => {
    const handleUpdateThrottled = throttle(() => {
      handleUpdate();
    }, 200);

    handleUpdateThrottled();
    document.addEventListener('scroll', handleUpdateThrottled);

    return () => {
      document.removeEventListener('scroll', handleUpdateThrottled);
    };
  }, [ref.current]);
  return rect;
}

function getRect(element: HTMLElement | null) {
  if (!element) {
    return placeholderLocation;
  }
  return element.getBoundingClientRect();
}
