import {
  getCachedOrListConnectors,
  listConnectors,
} from '@mirage/service-connectors';
import { isConnectorVisible } from '@mirage/service-connectors/utils';
import { callApiV2 } from '@mirage/service-dbx-api';

import type { dash_connectors } from '@dropbox/api-v2-client';

export async function fetchConnectorsWithVisibilityFilters() {
  const connectors = await listConnectors();
  return connectors.filter(isConnectorVisible);
}

export async function getCachedOrFetchConnectorsWithVisibilityFilters() {
  const connectors = await getCachedOrListConnectors();
  return connectors.filter(isConnectorVisible);
}

export async function connectionEnabled(
  connection_id?: string,
  connector_platform?: dash_connectors.ConnectorPlatform,
  connector_type?: string,
) {
  return await callApiV2('dashConnectorMetadataConnectionEnabled', {
    connection_id,
    connector_platform,
    connector_type,
  });
}

export async function removeConnection(
  connection_id: string,
  connector_platform: dash_connectors.ConnectorPlatform,
  connector_type: string,
) {
  return await callApiV2('dashConnectorMetadataRemoveConnection', {
    connection_id,
    connector_platform,
    connector_type,
  });
}
