import { IconButton } from '@dropbox/dig-components/buttons';
import styled from '@emotion/styled';

export const ActionIconButton = styled(IconButton)<{ isMobileSize?: boolean }>`
  ${({ isMobileSize }) =>
    isMobileSize
      ? `color: var(--dig-color__text__base);`
      : `color: var(--dig-color__text__subtle);`}
  border-radius: 4px;
`;
