import { dash_async } from '@dropbox/api-v2-client';
import { FullPageRedirect } from '@mirage/mosaics/FullPageRedirect';
import { RedirectVariant } from '@mirage/mosaics/FullPageRedirect/types';
import { tagged } from '@mirage/service-logging';
import { getUserEligibilityWithRetries } from '@mirage/shared/eligibility';
import { NetworkErrorModal } from '@mirage/shared/network-error/NetworkErrorModal';
import { useModal } from '@mirage/shared/util/showModal';
import i18n from '@mirage/translations';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { HELP_CENTER_ELIGIBILITY_ARTICLE } from './IneligibleModal';

const logger = tagged('useEligibilityCheck');

export const eligibleCheckDoneForPublicAccountIdAtom = atom<string | undefined>(
  undefined,
);

export const isUserIneligibleAtom = atom<boolean>(false);

export function useEligibilityCheck(
  onLogout: (str: string) => void,
  publicAccountId: string | undefined,
  onRestartDash?: () => void,
) {
  const { showModal, hideModal } = useModal();
  const checkDonePublicAccountId = useAtomValue(
    eligibleCheckDoneForPublicAccountIdAtom,
  );
  const setIsUserIneligible = useSetAtom(isUserIneligibleAtom);
  const [forceRetry, setForceRetry] = useState(false);

  const updateWithCheckResult = useUpdateWithCheckResult(
    onLogout,
    publicAccountId,
  );

  useEffect(() => {
    if (publicAccountId === checkDonePublicAccountId) {
      return;
    }

    // Use an array to pass a dynamic boolean value into a function.
    const canceled: [boolean] = [false];

    // Forced dependency to retry the eligibility check.
    forceRetry;

    const checkEligibility = async () => {
      const result = await getUserEligibilityWithRetries(canceled);

      if (canceled[0] || result === 'canceled') {
        logger.debug(`Eligibility check canceled due to component unmount`);
        return;
      }

      if (result === 'access_token_invalid') {
        onLogout(HELP_CENTER_ELIGIBILITY_ARTICLE);
        return;
      }

      if (result === 'network_error') {
        showModal(
          <NetworkErrorModal
            retry={() => {
              setForceRetry((v) => !v);
              hideModal();
            }}
            onSecondaryButtonClick={onRestartDash}
            secondaryButtonText={
              onRestartDash ? i18n.t('restart_dash') : undefined
            }
          />,
        );
        return;
      }

      updateWithCheckResult(result);
    };

    if (publicAccountId) {
      checkEligibility().catch((e) => {
        logger.warn('eligibility check failed', e);
        showModal(
          <NetworkErrorModal
            retry={() => {
              setForceRetry((v) => !v);
              hideModal();
            }}
            onSecondaryButtonClick={onRestartDash}
            secondaryButtonText={
              onRestartDash ? i18n.t('restart_dash') : undefined
            }
          />,
        );
      });
    } else {
      logger.debug(`Reset eligibility as user is not logged in`);
      setIsUserIneligible(false);
    }

    return () => {
      canceled[0] = true;
    };
  }, [
    publicAccountId,
    onLogout,
    showModal,
    setIsUserIneligible,
    forceRetry,
    checkDonePublicAccountId,
    hideModal,
    updateWithCheckResult,
  ]);
}

export function useUpdateWithCheckResult(
  onLogout: (str: string) => void,
  publicAccountId: string | undefined,
) {
  const { showModal, hideModal } = useModal();
  const setCheckDonePublicAccountId = useSetAtom(
    eligibleCheckDoneForPublicAccountIdAtom,
  );
  const setIsUserIneligible = useSetAtom(isUserIneligibleAtom);

  return useCallback(
    (result: dash_async.ShouldRedirectIneligibleUserResponse) => {
      const element = (
        <FullPageRedirect
          variant={result.dash_redirect_variant as RedirectVariant}
        />
      );

      if (result.dash_redirect_variant) {
        logger.log(`Show ineligible modal: ${result.dash_redirect_variant}`);
        setIsUserIneligible(true);
        showModal(element);
        setCheckDonePublicAccountId(publicAccountId);
      } else {
        logger.debug(`User is eligible to use Dash`);
        setIsUserIneligible(false);
      }

      // Ensure that this is updated after setIsUserIneligible is called, otherwise
      // we might hit a state where eligibleCheckDoneForAccount === true and
      // isUserIneligible === false even though isUserIneligible should be true
      setCheckDonePublicAccountId(publicAccountId);
    },
    [
      hideModal,
      onLogout,
      publicAccountId,
      setCheckDonePublicAccountId,
      setIsUserIneligible,
      showModal,
    ],
  );
}
