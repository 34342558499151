// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2519
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'click.dash_avatar_icon'
// Description: Fires when a user clicks the avatar icon to access user settings
// Owner: dash-web
export type Click_DashAvatarIcon = PAPEventGeneric<
  'dash',
  'click',
  'dash_avatar_icon',
  never
>;

export function PAP_Click_DashAvatarIcon(
  properties?: Click_DashAvatarIcon['properties'],
): Click_DashAvatarIcon {
  return <Click_DashAvatarIcon>{
    class: 'dash',
    action: 'click',
    object: 'dash_avatar_icon',
    properties,
  };
}
