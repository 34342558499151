import useSettings from './useSettings';

export function useStackItemCollapseSummarySettings() {
  const { settings, set: setSettings } = useSettings([
    'stackItemByIdCollapseSummary',
  ]);
  const stackItemCollapseSummaries =
    settings?.stackItemByIdCollapseSummary ?? {};

  const setCollapseSummary = (apiFileId: string, state?: boolean) => {
    const updatedStackItemCollapseSummaries = {
      ...stackItemCollapseSummaries,
    };

    if (state !== undefined) {
      updatedStackItemCollapseSummaries[apiFileId] = state;
    } else {
      delete updatedStackItemCollapseSummaries[apiFileId];
    }

    setSettings(
      'stackItemByIdCollapseSummary',
      updatedStackItemCollapseSummaries,
    );
  };

  const isSummaryCollapsed = (apiFileId: string | undefined) => {
    if (apiFileId === undefined) {
      return true;
    }

    return typeof stackItemCollapseSummaries[apiFileId] === 'boolean'
      ? stackItemCollapseSummaries[apiFileId]
      : true;
  };

  return {
    isSummaryCollapsed,
    setCollapseSummary,
    isReady: settings !== null,
  };
}
