import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { getCombineAsyncRequestsFunc } from '@mirage/shared/util/combine-async-requests';

import type { dash, users } from '@dropbox/api-v2-client';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';

export type Service = {
  auditLogUserOpenedStack(
    stackName: string,
    timestampMs: number,
  ): Promise<void>;
  auditFirstLoginDesktop(timestampMs: number): Promise<void>;
  auditFirstLoginWeb(timestampMs: number): Promise<void>;
};

interface AuthServiceContract {
  getCurrentAccount: () => Promise<users.FullAccount | undefined>;
}

interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

export default function auditLoggingService(
  authService: AuthServiceContract,
  dbxApiService: DbxApiServiceContract,
) {
  async function auditLogUserOpenedStack(
    stackName: string,
    timestampMs: number,
  ) {
    await writeAuditLog({
      event: {
        ['.tag']: 'dash_opened_stack_details',
        ['stack_name']: stackName,
      },
      ['ts_utc_ms']: timestampMs,
    });
  }

  async function auditFirstLoginDesktop(timestampMs: number) {
    await writeAuditLog({
      event: {
        ['.tag']: 'dash_first_launched_desktop_details',
      },
      ['ts_utc_ms']: timestampMs,
    });
  }

  async function auditFirstLoginWeb(timestampMs: number) {
    await writeAuditLog({
      event: {
        ['.tag']: 'dash_first_launched_web_details',
      },
      ['ts_utc_ms']: timestampMs,
    });
  }

  const writeAuditLog = getCombineAsyncRequestsFunc(
    async (logParams: dash.TeamAuditLogArgs) => {
      const account = await authService.getCurrentAccount();
      if (account?.team) {
        dbxApiService.callApiV2('dashTeamAuditLog', logParams);
      }
    },
  );

  return services.provide<Service>(
    ServiceId.AUDIT_LOGGING,
    {
      auditLogUserOpenedStack,
      auditFirstLoginDesktop,
      auditFirstLoginWeb,
    },
    [ServiceId.DASH_AUTH, ServiceId.DBX_API],
  );
}
