import {
  AGGREGATED_PAGE_MODULE,
  MetricPageName,
} from '@mirage/service-operational-metrics/module/constants';
import { useRecordModuleLatency } from '@mirage/service-operational-metrics/module/module';
import {
  getStacksDataCachedTags,
  useStacksDataCachedTags,
} from '@mirage/service-operational-metrics/module/tags';
import {
  areStacksComplete,
  subscribeToStacksCompleteUpdates,
} from '@mirage/service-stacks';
import { useEffect, useState } from 'react';

export function useAllStacksPerformanceTracking() {
  const [stacksComplete, setStacksComplete] = useState(false);

  const tags = useStacksDataCachedTags();

  useEffect(() => {
    areStacksComplete().then((complete) => {
      setStacksComplete(complete);
    });

    const subscription = subscribeToStacksCompleteUpdates(() => {
      setStacksComplete(true);
    });
    return () => subscription.unsubscribe();
  }, []);

  const { markModuleLoadComplete } = useRecordModuleLatency(
    MetricPageName.ALL_STACKS,
    AGGREGATED_PAGE_MODULE, // there is only one module on this page, so just log it directly
    tags || getStacksDataCachedTags,
  );

  useEffect(() => {
    // stacksComplete: Stacks loading must be complete before we mark the
    //   page load complete.
    if (stacksComplete) {
      markModuleLoadComplete();
    }
  }, [markModuleLoadComplete, stacksComplete]);
}
