import { callApiV2 } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { urlToContentID } from '@mirage/shared/util/urlRulesUtil';

const logger = tagged('fetchMetadata');
const CONTENT_ID_PREFIX_DROPBOX_PAPER = 'paper:scl';
const CONTENT_ID_PREFIX_GOOGLE_DOC = 'gdoc:';

export async function getLinkContent(url: string) {
  const contentID = urlToContentID(url, false);
  if (contentID.startsWith(CONTENT_ID_PREFIX_DROPBOX_PAPER)) {
    return getLinkContentPaper(url);
  } else if (contentID.startsWith(CONTENT_ID_PREFIX_GOOGLE_DOC)) {
    return getLinkContentGdoc(
      contentID.substring(CONTENT_ID_PREFIX_GOOGLE_DOC.length),
      url,
    );
  }
  return undefined;
}

async function getLinkContentGdoc(docID: string, url: string) {
  logger.info('fetching gdoc content from docId', docID, 'url', url);
  const connectors = await callApiV2('janusConnectionsListConnectors', {});
  // find the connector with the name 'Google Drive'
  const gdriveConnectorId = connectors['safe_connectors']?.find(
    (c) => c.name === 'Google Drive',
  )?.id;
  if (!gdriveConnectorId) {
    throw new Error('Google Drive connector not found');
  }
  const activeUserConnections = await callApiV2(
    'janusConnectionsListUserConnections',
    {},
  );
  // find the connection with the Google Drive connector
  const gdriveUserConnectionId = activeUserConnections[
    'safe_connections'
  ]?.find((c) => c.configuration_id === gdriveConnectorId)?.connection_id;
  if (!gdriveUserConnectionId) {
    throw new Error('Google Drive user connection not found');
  }
  const endpoint_path_metadata = `/files/${docID}`;
  const googleDocMetadata = await callApiV2('janusProxyProxy', {
    method: 'GET',
    connection_id: gdriveUserConnectionId,
    endpoint_path: endpoint_path_metadata,
    headers: {
      'Accept-Encoding': '*/*',
    },
  });
  const parsedMetadata = JSON.parse(atob(googleDocMetadata['body'] ?? ''));
  const title = parsedMetadata.name;
  const endpoint_path_body = `/files/${docID}/export?mimeType=text%2Fplain`;
  const googleDocBodyContent = await callApiV2('janusProxyProxy', {
    method: 'GET',
    connection_id: gdriveUserConnectionId,
    endpoint_path: endpoint_path_body,
    headers: {
      'Accept-Encoding': '*/*',
    },
  });
  const googleDocBodyText = googleDocBodyContent['body']
    ? atob(googleDocBodyContent['body'])
    : undefined;

  if (!googleDocBodyText) {
    throw new Error('Google Doc body not found');
  }

  return { title: title, text: googleDocBodyText, url };
}

async function getLinkContentPaper(url: string) {
  logger.info('fetching paper content from url', url);
  const resp = await callApiV2('paperCloudDocsDownload', {
    export_format: { '.tag': 'markdown' },
    url,
  });
  const fileBlob: Blob = (resp as unknown as { fileBlob: Blob }).fileBlob;
  if (!fileBlob) {
    throw new Error('Failed to fetch paper content');
  }
  const body = await readBlobText(fileBlob);
  if (!body) {
    throw new Error('Failed to read paper content');
  }
  return { title: resp.title, text: await readBlobText(fileBlob), url };
}

function readBlobText(blob: Blob): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.readAsText(blob);
  });
}
