// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3250
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'shown.activity_feed'
// Description: Impression event for when the dash activity feed is shown.
// Owner: dash-web
export type Shown_ActivityFeed = PAPEventGeneric<
  'dash',
  'shown',
  'activity_feed',
  never
>;

export function PAP_Shown_ActivityFeed(
  properties?: Shown_ActivityFeed['properties'],
): Shown_ActivityFeed {
  return <Shown_ActivityFeed>{
    class: 'dash',
    action: 'shown',
    object: 'activity_feed',
    properties,
  };
}
