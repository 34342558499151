// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgQuip({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.53 12.004c0 .423-.35.774-.773.774H6.236a.778.778 0 01-.773-.774c0-.423.35-.773.773-.773h11.536c.423 0 .758.335.758.773zm-3.077 4.232H8.54a.766.766 0 11-.044-1.532H15.467c.423 0 .773.35.773.773 0 .423-.35.759-.787.759zM8.54 7.773h6.927c.423 0 .773.35.773.773 0 .423-.35.773-.773.773H8.54a.778.778 0 01-.773-.773c0-.423.35-.773.773-.773zm9.246-1.941a8.358 8.358 0 00-5.79-2.291c-.175 0-.364 0-.54.014a8.456 8.456 0 00-7.903 7.91c-.146 2.363.656 4.61 2.275 6.332a8.413 8.413 0 006.168 2.67h8.46v-8.463a8.314 8.314 0 00-2.67-6.172z"
        fill="#F27557"
      />
      <path
        d="M20.454 20.468h-8.458a8.382 8.382 0 01-6.17-2.67c-1.618-1.722-2.42-3.97-2.274-6.333a8.456 8.456 0 017.904-7.91c.19-.014.364-.014.554-.014 2.159 0 4.23.817 5.79 2.291a8.368 8.368 0 012.669 6.173v8.463h-.015zm-1.619-15.76a9.826 9.826 0 00-7.481-2.684c-5.017.306-9.027 4.333-9.334 9.338a9.926 9.926 0 002.698 7.486A9.984 9.984 0 0011.996 22h9.231c.423 0 .773-.35.773-.773V11.99a10.09 10.09 0 00-3.165-7.282z"
        fill="#C34027"
      />
      <path
        d="M15.451 14.69H8.54a.778.778 0 00-.773.773c0 .423.35.773.773.773h6.927c.423 0 .773-.35.773-.773a.791.791 0 00-.788-.774zM8.54 9.304h6.927c.423 0 .773-.35.773-.774a.778.778 0 00-.773-.773H8.54a.778.778 0 00-.773.773c0 .424.35.774.773.774zM6.235 11.23a.766.766 0 000 1.532H17.77c.423 0 .773-.35.773-.773a.778.778 0 00-.773-.774l-11.535.015z"
        fill="#FEFEFE"
      />
    </svg>
  );
}

export default SvgQuip;
