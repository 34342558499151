import { _Metadata } from "./Metadata";
import { _Item } from "./Item";
import { _Avatar } from "./Avatar";
import { _UIIcon } from "./UIIcon";
import { _ArbitraryIcon } from "./Icon";
import { _Label } from "./Label";
export type { AvatarProps } from "./Avatar";
export type { MetadataProps } from "./Metadata";
export type { ItemProps } from "./Item";
export type { LabelProps } from "./Label";
export type { UIIconProps } from "./UIIcon";
export type { ArbitraryIconProps } from "./Icon";
export { MetadataContext, type MetadataContextProps } from "./context";

export const Metadata = Object.assign(_Metadata, {
  Item: _Item,
  Avatar: _Avatar,
  UIIcon: _UIIcon,
  ArbitraryIcon: _ArbitraryIcon,
  Label: _Label,
});
