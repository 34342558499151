// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1485
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'start.dash_login'
// Description: This event is logged as:
// - "event_state=start" when: the signIn/signUp screen is first shown during the Dash desktop onboarding flow
// - "event_state=success" when: the user continues with the setup flow (click on the SignIn or Signup button)
// - "event_state=failed" when: if something goes wrong during signIn/signUp
// - "event_state=canceled" when: the user does NOT continue with setup (eg. use "Cmd + Q" to quit the app)
// Owner: otc-growth
export type Start_DashLogin = PAPEventGeneric<
  'onboard',
  'start',
  'dash_login',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Client generated id ("installId" for now) that's used in the Dash desktop onboarding flow
    dashOnboardingSessionId?: string;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
  }
>;

export function PAP_Start_DashLogin(
  properties?: Start_DashLogin['properties'],
): Start_DashLogin {
  return <Start_DashLogin>{
    class: 'onboard',
    action: 'start',
    object: 'dash_login',
    properties,
  };
}
