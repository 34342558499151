import { Button } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { FormLabel, FormRow } from '@dropbox/dig-components/form_row';
import { Modal } from '@dropbox/dig-components/modal';
import { TextArea } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { AnnotationScore } from '@mirage/search/SearchResults/ResultRow/ResultAnnotations';
import { QuestionAndAnswerSource } from '@mirage/shared/answers/multi-answer';
import { FeedbackOptions } from '@mirage/shared/types';
import { useEffect, useReducer } from 'react';
import { AddURLForm, useAddResultURLs } from '../AddResultURLs';
import styles from './AnswersAnnotationModal.module.css';
import { SourceAnnotations } from './SourceAnnotations/SourceAnnotations';
import {
  actionTypes,
  convertStateToAnswersPapEvents,
  formReducer,
  initialState,
} from './util/util';

import type { ReportPapEventFn } from '@mirage/analytics/AnalyticsProvider';
import type { DashAnswersFeedbackTag } from '@mirage/analytics/events/enums/dash_answers_feedback_tag';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { AnswerResponse } from '@mirage/service-dbx-api/service/answers';
import type { ChangeEvent, FormEvent } from 'react';

export type AnnotationAnswer = Pick<
  AnswerResponse,
  'answer' | 'searchRequestId' | 'answerId' | 'conversationId'
> & {
  sources: Array<SearchResult | QuestionAndAnswerSource>;
  question?: string;
};

type DetailedFeedbackOptions = {
  title: string;
  value: DashAnswersFeedbackTag;
};

const feedbackConfig: DetailedFeedbackOptions[] = [
  {
    title: 'Not factually correct (hallucination)',
    value: 'incorrect_answer',
  },
  {
    title: 'Missing key details',
    value: 'key_details_missing',
  },
  {
    title: 'Did not answer question',
    value: 'irrelevant_answer',
  },
  {
    title: 'Answer was too verbose',
    value: 'verbose',
  },
  {
    title: 'Unhelpful generated question',
    value: 'bad_generated_question',
  },
];

////
// Form component
////
type AnswersAnnotationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  reportPAPEvent?: ReportPapEventFn;
  query: string;
  answer?: AnnotationAnswer;
  dashAnswerFeedback?: FeedbackOptions;
  searchSessionId?: string;
  searchAttemptId?: string;
  conversationId?: string;
  onSourceClick?: (url: string) => void;
};

export const AnswersAnnotationModal = ({
  isOpen,
  onClose,
  query,
  answer,
  dashAnswerFeedback,
  reportPAPEvent,
  searchAttemptId,
  searchSessionId,
  conversationId,
  onSourceClick,
}: AnswersAnnotationModalProps) => {
  const [formState, dispatch] = useReducer(formReducer, initialState);
  const urlProps = useAddResultURLs();

  useEffect(() => {
    if (answer?.answer && isOpen) {
      dispatch({ type: actionTypes.PREFERRED_ANSWER, payload: answer.answer });
    }
  }, [answer?.answer, isOpen]);

  const handlePreferredAnswer = (e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch({ type: actionTypes.PREFERRED_ANSWER, payload: e.target.value });
  };

  const handleSelectIncorrectReason = (payload: string) => {
    dispatch({ type: actionTypes.INCORRECT_REASON, payload });
  };

  const handleSourceFeedback = (resultUuid: string, score: AnnotationScore) => {
    dispatch({
      type: actionTypes.SOURCE_FEEDBACK,
      payload: { [resultUuid]: score },
    });
  };

  const handleSourceClick = (
    source: SearchResult | QuestionAndAnswerSource,
  ) => {
    if (source.url && onSourceClick) {
      onSourceClick(source.url);
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const answersPapEvents = convertStateToAnswersPapEvents(
      formState,
      urlProps.pendingURLs,
      query,
      dashAnswerFeedback,
      answer,
      searchSessionId,
      searchAttemptId,
      conversationId,
    );

    answersPapEvents.forEach((event) => {
      reportPAPEvent?.(event);
    });
    handleClose();
  };

  const handleClose = () => {
    dispatch({ type: actionTypes.RESET_FORM });
    urlProps.setPendingURLs(['']);
    onClose();
  };

  return (
    <Modal open={isOpen} isCentered>
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          <Text size="xlarge">Answer Feedback</Text>
        </Modal.Header>
        <Modal.Body>
          <FormRow variant="control">
            <div className={styles.feedbackChipContainer}>
              {feedbackConfig.map((item) => (
                <Chip
                  key={item.value}
                  isSelected={formState.incorrectReason === item.value}
                  type="button"
                  onClick={() => handleSelectIncorrectReason(item.value)}
                  className={styles.feedbackChip}
                >
                  <Chip.Content>{item.title}</Chip.Content>
                </Chip>
              ))}
            </div>
          </FormRow>
          <SourceAnnotations
            sources={answer?.sources || []}
            scores={formState.sourceFeedback}
            handleSelect={handleSourceFeedback}
            handleSourceClick={handleSourceClick}
          />
          <FormRow>
            <div className={styles.divider} />
          </FormRow>
          <FormRow>Additional Details (optional)</FormRow>
          <FormRow>
            <FormLabel subtext="(Optional)">
              What sources would you have preferred to see?
            </FormLabel>
            <AddURLForm {...urlProps} />
          </FormRow>
          <FormRow>
            <FormLabel subtext="(Optional)">
              What answer would you have preferred to see?
            </FormLabel>
            <TextArea
              onChange={handlePreferredAnswer}
              aria-label="Preferred Answer"
              value={formState.preferredAnswer}
            />
          </FormRow>
        </Modal.Body>
        <Modal.Footer>
          <FormRow>
            <Button variant="opacity" onClick={handleClose} type="button">
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </FormRow>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
