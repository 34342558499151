// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2568
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'select.stack_section'
// Description: Fires when a user clicks a stack section under Sections to navigate to it
// Owner: dash-web
export type Select_StackSection = PAPEventGeneric<
  'stacks',
  'select',
  'stack_section',
  {
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
  }
>;

export function PAP_Select_StackSection(
  properties?: Select_StackSection['properties'],
): Select_StackSection {
  return <Select_StackSection>{
    class: 'stacks',
    action: 'select',
    object: 'stack_section',
    properties,
  };
}
