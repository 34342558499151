import { useEffect } from 'react';
import { ChecklistItem } from './ChecklistItem';
import { OnboardingChecklistStep } from './useOnboardingChecklist';

type ChecklistProps = {
  checklistItems: OnboardingChecklistStep[];
  openId: string;
  openLearnMore: (uri: string) => void;
  setOpenId: (moduleId: string) => void;
};

export const Checklist = ({
  checklistItems,
  openId,
  openLearnMore,
  setOpenId,
}: ChecklistProps) => {
  // Open the first non-complete checklist item if none are open
  useEffect(() => {
    let startIndex = 0;
    if (!openId && checklistItems?.length) {
      const incompleteIndex = checklistItems.findIndex((c) => !c.isComplete);
      if (incompleteIndex >= 0) {
        startIndex = incompleteIndex;
      }
      setOpenId(checklistItems[startIndex].moduleId);
    }
  }, [checklistItems, openId, setOpenId]);

  const handleLearnMore = (checklistItem: OnboardingChecklistStep) => {
    const { moduleId } = checklistItem;
    if (!moduleId) return;
    openLearnMore(moduleId);
  };

  return (
    <>
      {checklistItems.map((checklistItem) => (
        <ChecklistItem
          key={checklistItem.moduleId}
          open={openId === checklistItem.moduleId}
          setOpen={() => setOpenId(checklistItem.moduleId)}
          onLearnMore={() => handleLearnMore(checklistItem)}
          checklistItem={checklistItem}
        />
      ))}
    </>
  );
};
