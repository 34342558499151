import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { fileTypeScore } from './components/file-type';
import { frequentlyBrowserViewedScore } from './components/frequently-browser-viewed';
import { frequentlyClickedScore } from './components/frequently-clicked';
import { lastBrowserViewedScore } from './components/last-browser-viewed';
import { lastClickedScore } from './components/last-clicked';
import { titleMatchScore } from './components/title-match';
import { getScore as getSuggestedQueryScore } from './suggested-query-score';
import { getWeights } from './weights';

/**
 * Pinned in our case doesn't necessarily mean the result is shown at the top
 * of results, it just means that the result has some kind of manual scoring or
 * rearranging that happens in search/index.ts.
 *
 * The "scoring shenanagins" with pinned results happen in search/index.ts
 * around the `$grouped` Observable.
 */
type PinnedResultType =
  | typeahead.ResultType.SuggestedQuery
  | typeahead.ResultType.MathCalculation
  | typeahead.ResultType.SearchFilter
  | typeahead.ResultType.URLShortcut;
const PINNED_RESULT_TYPES = new Set<PinnedResultType>([
  typeahead.ResultType.SuggestedQuery,
  typeahead.ResultType.MathCalculation,
  typeahead.ResultType.SearchFilter,
  typeahead.ResultType.URLShortcut,
]);
const PINNED_SCORE_FNS: Record<PinnedResultType, () => number> = {
  // SuggestedQuery results gets `score` pinned to a Growthbook-configured
  // value, so it's still dynamically ranked, just with a set value, i.e. 0.70
  // or 0.01 depending on Growthbook.
  [typeahead.ResultType.SuggestedQuery]: () => getSuggestedQueryScore(),
  // These other results get `score` pinned to `Infinity` and we trust
  // higher-level search logic will rearrange them as needed.
  [typeahead.ResultType.MathCalculation]: () => Number.POSITIVE_INFINITY,
  [typeahead.ResultType.SearchFilter]: () => Number.POSITIVE_INFINITY,
  [typeahead.ResultType.URLShortcut]: () => Number.POSITIVE_INFINITY,
};

/**
 * Take a `query` (string) and return a function that can take a `TaggedResult`,
 * score it, and give back a `ScoredResult`.
 *
 * Most types of records use proportional scoring, but some records
 * (SuggestedQuery, MathCalculation, SearchFilter) get faux scores because
 * they're "pinned" results with special logic in the front-end for where
 * they're displayed. "Pinned" in our case just means there is some manual
 * logic to place them in the typeahead results, they're not necessarily always
 * first.
 */
export function score(query: string, config: typeahead.Config) {
  return function layered(
    result: typeahead.TaggedResult,
  ): typeahead.ScoredResult {
    const isPinnedResultType = PINNED_RESULT_TYPES.has(
      result.type as PinnedResultType,
    );
    if (isPinnedResultType) {
      const pinnedScoreFn = PINNED_SCORE_FNS[result.type as PinnedResultType];
      return {
        ...result,
        score: pinnedScoreFn(),
        scoringNotes: {
          pinned: true,
          scoringComponents: nulledScoringComponents(),
        },
      };
    }

    const configuredWeights = { ...getWeights() };
    const unweightedScores: typeahead.UnweightedScores = {
      // TODO: Allow `titleMatchScore` to return null on it's own accord,
      // similar to `fileTpeScore`
      titleMatchScore:
        query === '' ? null : titleMatchScore(query, result, config),
      lastClickedScore: lastClickedScore(result, config),
      frequentlyClickedScore: frequentlyClickedScore(result, config),
      lastBrowserViewedScore: lastBrowserViewedScore(result, config),
      frequentlyBrowserViewedScore: frequentlyBrowserViewedScore(
        result,
        config,
      ),
      fileTypeScore: fileTypeScore(result, config),
    };
    const scoringComponentsWithoutWeightedScore =
      buildScoringComponentsWithoutWeightedScore(
        unweightedScores,
        configuredWeights,
      );
    const { finalScore, scoringComponents } = applyWeightedScoring(
      scoringComponentsWithoutWeightedScore,
    );

    return {
      ...result,
      score: finalScore,
      scoringNotes: {
        pinned: false,
        scoringComponents,
      },
    };
  };
}

/**
 * When scoring is said and done, for each record we want a nice
 * `ScoringCompoennts` object for debugging and analytics, but we haven't
 * done the math for the weighted scoring yet. Take
 * `scoringComponentsWithoutWeightedScore`, do the math, and output `finalScore`
 * (number) and `scoringComponents` (ScoringComponents)
 *
 * This is the same math as our previous `proportion` function, but has a lot
 * more note-taking for debugging and analytics. That's why we have
 * `scoringNotes` and the `ScoringComponents` type.
 */
function applyWeightedScoring(
  scoringComponentsWithoutWeightedScore: ScoringComponentsWithoutWeightedScore,
): {
  finalScore: number;
  scoringComponents: typeahead.ScoringComponents;
} {
  // First iteration: We need to understand the sum of applied weights so we
  // can proportionally apply them in the next step.
  let sumOfAppliedWeights = 0;
  for (const key of typeahead.SCORING_COMPONENT_KEYS) {
    const scoringComponentWithoutWeightedScore =
      scoringComponentsWithoutWeightedScore[key];
    if (scoringComponentWithoutWeightedScore.appliedWeight !== null) {
      sumOfAppliedWeights += scoringComponentWithoutWeightedScore.appliedWeight;
    }
  }

  // Second iteration: Apply proportional scoring, keep track of how it nets
  // out for each scoring component, and keep track of final score
  let finalScore = 0;
  const scoringComponents: Partial<typeahead.ScoringComponents> = {};
  for (const key of typeahead.SCORING_COMPONENT_KEYS) {
    const scoringComponentWithoutWeightedScore =
      scoringComponentsWithoutWeightedScore[key];

    const { unweightedScore, appliedWeight } =
      scoringComponentWithoutWeightedScore;

    let weightedScore: number | null;
    if (unweightedScore === null || appliedWeight === null) {
      weightedScore = null;
    } else {
      weightedScore = unweightedScore * (appliedWeight / sumOfAppliedWeights);
      finalScore += weightedScore;
    }

    const scoringComponent: typeahead.ScoringComponent = {
      ...scoringComponentWithoutWeightedScore,
      weightedScore,
    };
    scoringComponents[key] = scoringComponent;
  }

  return {
    finalScore,
    scoringComponents: scoringComponents as typeahead.ScoringComponents,
  };
}

/**
 * Helpers for `ScoredComponents`
 *
 * `ScoringCompoennts` keeps track of the weights, scores, and the application
 * of scoring to assist with debugging and analytics. We want to construct the
 * `ScoringComponents` to organize our logic, but won't have done the math for
 * `weightedScore` yet.
 */

type ScoringComponentWithoutWeightedScore = Omit<
  typeahead.ScoringComponent,
  'weightedScore'
>;
type ScoringComponentsWithoutWeightedScore = {
  [key in typeahead.ScoringComponentKey]: ScoringComponentWithoutWeightedScore;
};

function buildScoringComponentsWithoutWeightedScore(
  unweightedScores: typeahead.UnweightedScores,
  configuredWeights: typeahead.Weights,
): ScoringComponentsWithoutWeightedScore {
  const scoringComponents: {
    [key: string]: ScoringComponentWithoutWeightedScore;
  } = {};

  for (const key of typeahead.SCORING_COMPONENT_KEYS) {
    const unweightedScore = unweightedScores[key];
    const configuredWeight = configuredWeights[key];
    scoringComponents[key] = buildScoringComponentWithoutWeightedScore(
      unweightedScore,
      configuredWeight,
    );
  }

  return scoringComponents as ScoringComponentsWithoutWeightedScore;
}

function buildScoringComponentWithoutWeightedScore(
  unweightedScore: number | null,
  configuredWeight: number,
): ScoringComponentWithoutWeightedScore {
  let appliedWeight: number | null;
  if (unweightedScore === null) {
    appliedWeight = null;
  } else {
    appliedWeight = configuredWeight;
  }

  return {
    unweightedScore,
    // omitted: `weightedScore`, it's calculated relative to other components
    configuredWeight,
    appliedWeight,
  };
}

/**
 * Helpers for "pinned" results (special case)
 */

function nulledScoringComponents(): typeahead.ScoringComponents {
  const scoringComponents: Partial<typeahead.ScoringComponents> = {};
  for (const key of typeahead.SCORING_COMPONENT_KEYS) {
    scoringComponents[key] = {
      unweightedScore: null,
      weightedScore: null,
      configuredWeight: 0,
      appliedWeight: null,
    };
  }
  return scoringComponents as typeahead.ScoringComponents;
}
