import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import {
  CardHeaderType,
  TwoColumnGridCard,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import styles from './EmptyStateCard.module.css';

type EmptyStateCardProps = {
  message: string;
  actionText: string;
  onClick: () => void;
  children: React.ReactNode;
};

export const EmptyStateCard = ({
  children,
  message,
  actionText,
  onClick,
}: EmptyStateCardProps) => {
  return (
    <TwoColumnGridCard
      settingId="empty-state"
      cardTypeProps={{
        cardType: CardHeaderType.CUSTOM,
        customHeader: <></>,
      }}
      showHeader={false}
      className={styles.container}
      isAlwaysCollapsed={false}
      showDividerLine={false}
    >
      <Box className={styles.contentContainer}>
        <Box
          className={styles.messageContainer}
          display="flex"
          flexDirection="column"
        >
          {children}
          <Text>{message}</Text>
        </Box>
        <Button
          className={styles.actionButton}
          variant="primary"
          onClick={onClick}
        >
          {actionText}
        </Button>
      </Box>
    </TwoColumnGridCard>
  );
};
