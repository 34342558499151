import { isFreshLogin } from '@mirage/service-auth';
import { areStacksComplete } from '@mirage/service-stacks';
import { useEffect, useState } from 'react';
import { Tags } from '../service';

/**
 * Simple util to return the metrics tags with the fresh login info. This value
 * could change during app use so should not be cached (i.e. not using a react
 * hook). E.g. when a user navigates to a different page, the value of
 * `freshLogin` will be set to false.
 */
export async function getFreshLoginTags(): Promise<Tags> {
  const freshLogin = await isFreshLogin();
  return { fresh_login: freshLogin };
}

export function useFreshLoginTags(): Tags | undefined {
  const [tags, setTags] = useState<Tags>();

  useEffect(() => {
    getFreshLoginTags().then((tags) => {
      setTags(tags);
    });
  }, []);

  return tags;
}

export async function getStacksDataCachedTags(): Promise<Tags> {
  const cached = await areStacksComplete();

  // If the stacks data were not already cached, then the page load time
  // is expected to be longer. We expect that most of the time, `cached`
  // would be true, so the page would be fast.
  return { cached };
}

/**
 * Simple util to return the metrics tags with the stacks caching info.
 * The value of `cached` should be fetched early and retained because once the
 * stacks data is loaded, the `cached` will become true. So we cannot do this
 * on-demand when the value is needed.
 */
export function useStacksDataCachedTags(): Tags | undefined {
  const [tags, setTags] = useState<Tags>();

  useEffect(() => {
    getStacksDataCachedTags().then((tags) => {
      setTags(tags);
    });
  }, []);

  return tags;
}
