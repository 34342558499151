import { ONE_DAY_IN_SECONDS } from '@mirage/shared/util/constants';
import { LargeKvStore } from '@mirage/storage/large-kv-store/interface';

import type { dash_async } from '@dropbox/api-v2-client';

// Store the result of the check locally.
const STORE_KEY = 'DashEligibility';

const ELIGIBLE_RESPONSE: dash_async.ShouldRedirectIneligibleUserResponse = {
  message: '',
  new_url: '',
};

/** Hack to manually whitelist a user by writing to cache directly. */
export async function tempSkipEligibilityCheck(store: LargeKvStore) {
  await store.set(
    STORE_KEY,
    JSON.stringify(ELIGIBLE_RESPONSE),
    // Note that this hack will only last for one day.
    ONE_DAY_IN_SECONDS,
  );
}

/** Remove the hacked cache key. */
export async function resetEligibilityCache(store: LargeKvStore) {
  await store.delete(STORE_KEY);
}

/** Check if the user is eligible according to the cache. */
export async function isUserEligibleByCache(store: LargeKvStore) {
  const cached = await store.get(STORE_KEY);
  if (!cached) return false;

  const response: dash_async.ShouldRedirectIneligibleUserResponse =
    JSON.parse(cached);
  return !response?.new_url;
}

export async function getEligibilityFromCache(
  store: LargeKvStore,
): Promise<dash_async.ShouldRedirectIneligibleUserResponse | undefined> {
  const cached = await store.get(STORE_KEY);
  return cached ? JSON.parse(cached) : undefined;
}
