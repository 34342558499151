import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import {
  SizeLimitedArray,
  SizeLimitedMap,
} from '@mirage/shared/util/size-limited-collections';
import { Observable, Subject } from 'rxjs';

export interface Tags {
  [name: string]: string | number | boolean;
}

export interface Metric {
  ns: string;
  name: string;
}

export interface MetricSink {
  counter(metric: Metric, value: number, tags?: Tags): Promise<void>;
  stats(metric: Metric, value: number, tags?: Tags): Promise<void>;
}

export type MetricData = {
  metric: Metric;
  value: number;
  tags?: Tags;
};

export type DisplayStatData = { metricName: string; valueMs: number };

export type DisplayStats = { count?: number; stats?: number[] };

export type DisplayStatsByName = { [metricName: string]: DisplayStats };

export type Service = ReturnType<typeof metrics>;

// Trim at the size when the stats start to look "messy".
const MAX_DISPLAY_STATS = 500;
const MAX_STATS_PER_ROW = 20;

export default function metrics(sink: MetricSink) {
  let displayStatsByName = new SizeLimitedMap<string, DisplayStats>(
    MAX_DISPLAY_STATS,
  );

  const statsByNameSubject = new Subject<DisplayStatsByName>();
  statsByNameSubject.next(getDisplayStats()); // initial value

  function getMetricDisplayName(metric: Metric) {
    return metric.ns + '/' + metric.name;
  }

  function getDisplayStats(): DisplayStatsByName {
    // Make serializable. Map can be serialized in Chrome, but not in Edge.
    return Object.fromEntries(displayStatsByName.entries());
  }

  function getOrCreateDisplayStat(metricName: string) {
    let stat = displayStatsByName.get(metricName);
    if (!stat) {
      stat = {};
      displayStatsByName.set(metricName, stat);
    }
    return stat;
  }

  /**
   * These stats are for display only, so feel free to add whatever you want
   * to see in the UI.
   */
  function addDisplayStat(metricName: string, valueMs: number) {
    const obj = getOrCreateDisplayStat(metricName);

    const stats =
      obj.stats || (obj.stats = new SizeLimitedArray(MAX_STATS_PER_ROW));
    stats.push(valueMs);

    statsByNameSubject.next(getDisplayStats());
  }

  function displayStatsByNameObservable(): Observable<DisplayStatsByName> {
    return statsByNameSubject.asObservable();
  }

  function clearDisplayStats(): void {
    displayStatsByName = new SizeLimitedMap<string, DisplayStats>(
      MAX_DISPLAY_STATS,
    );
    statsByNameSubject.next(getDisplayStats());
  }

  // Auto-update `displayStatsByName`.
  const sink2 = {
    ...sink,

    counter: (metric: Metric, value: number, tags?: Tags) => {
      sink.counter(metric, value, tags);

      const name = getMetricDisplayName(metric);
      const obj = getOrCreateDisplayStat(name);
      obj.count = Number(obj.count ?? 0) + value;

      statsByNameSubject.next(getDisplayStats());
    },

    stats: (metric: Metric, value: number, tags?: Tags) => {
      sink.stats(metric, value, tags);

      const name = getMetricDisplayName(metric);
      addDisplayStat(name, value);
    },
  };

  // Batched apis.
  function countersBatch(batch: MetricData[]) {
    batch.forEach((b) => sink2.counter(b.metric, b.value, b.tags));
  }

  function statsBatch(batch: MetricData[]) {
    batch.forEach((b) => sink2.stats(b.metric, b.value, b.tags));
  }

  function batchAddDisplayStat(batch: DisplayStatData[]) {
    batch.forEach((b) => addDisplayStat(b.metricName, b.valueMs));
  }

  return services.provide(
    ServiceId.OPERATIONAL_METRICS,
    {
      ...sink2,
      countersBatch,
      statsBatch,
      getDisplayStats,
      addDisplayStat,
      batchAddDisplayStat,
      clearDisplayStats,
      displayStatsByNameObservable,
    },
    [],
  );
}
