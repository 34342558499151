// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3114
import type { PAPEventGeneric } from '../base/event';
import type { BrowserName } from '../enums/browser_name';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';
import type { PageName } from '../enums/page_name';

// Event_name: 'shown.dash_jtbd_onboarding'
// Description: Shown event for Dash JTBD Onboarding Flow
// Owner: otc-growth
export type Shown_DashJtbdOnboarding = PAPEventGeneric<
  'dash',
  'shown',
  'dash_jtbd_onboarding',
  {
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // This indicates the user's current browser
    browserName?: BrowserName;
    // Describes on which page the action took place
    pageName?: PageName;
  }
>;

export function PAP_Shown_DashJtbdOnboarding(
  properties?: Shown_DashJtbdOnboarding['properties'],
): Shown_DashJtbdOnboarding {
  return <Shown_DashJtbdOnboarding>{
    class: 'dash',
    action: 'shown',
    object: 'dash_jtbd_onboarding',
    properties,
  };
}
