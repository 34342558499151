// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2547
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'shown.suggested_auto_stack'
// Description: Fires when a suggested auto stack is seen by a user
// Owner: dash-web
export type Shown_SuggestedAutoStack = PAPEventGeneric<
  'stacks',
  'shown',
  'suggested_auto_stack',
  {
    // Generated random request id for tracking suggestions in Dash
    dashRequestId?: string;
    // A hashed integer for unique predictions recommended in Dash
    predictionIdHash?: number;
  }
>;

export function PAP_Shown_SuggestedAutoStack(
  properties?: Shown_SuggestedAutoStack['properties'],
): Shown_SuggestedAutoStack {
  return <Shown_SuggestedAutoStack>{
    class: 'stacks',
    action: 'shown',
    object: 'suggested_auto_stack',
    properties,
  };
}
