import { Text } from '@dropbox/dig-components/typography';
import { Stack } from '@dropbox/dig-foundations';
import { BoxEmptySpot } from '@dropbox/dig-illustrations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Shown_DashEmptyStateComponent } from '@mirage/analytics/events/types/shown_dash_empty_state_component';
import { useWelcomeStacks } from '@mirage/stacks/hooks';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useEffect, useRef } from 'react';
import { NoMoreActivityItems } from '../ActivityList/NoMoreActivityItems';
import styles from './ActivityListEmptyState.module.css';
import { WelcomeStackActivity } from './WelcomeStackActivity';

export const ActivityListEmptyState = () => {
  const loggedExposure = useRef(false);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const welcomeStacks = useWelcomeStacks();

  useEffect(() => {
    if (!loggedExposure.current) {
      loggedExposure.current = true;
      reportPapEvent(
        PAP_Shown_DashEmptyStateComponent({
          dashActionSurface: 'start_page',
          actionSurfaceComponent: 'activity_feed',
        }),
      );
    }
  }, [reportPapEvent]);

  if (welcomeStacks.length > 0) {
    return (
      <Stack gap="Micro Small">
        <WelcomeStackActivity stack={welcomeStacks[0]} />
        <NoMoreActivityItems />
      </Stack>
    );
  }

  return <EmptyStateCard />;
};

const EmptyStateCard = () => {
  return (
    <div className={classnames(styles.container)}>
      <div className={styles.assetContainer}>
        <div className={styles.iconContainer}>
          <BoxEmptySpot />
        </div>
      </div>
      <div className={styles.textContentContainer}>
        <div>
          <Text className={styles.message} size="medium" tagName="p">
            {i18n.t('activity_feed_empty_state_text')}
          </Text>
        </div>
      </div>
    </div>
  );
};
