// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1117
import type { PAPEventGeneric } from '../base/event';
import type { FeatureLine } from '../enums/feature_line';

// Event_name: 'click.join_meeting'
// Description: Fires when a user clicks on the Join meeting button
// Owner: otc-startpage
export type Click_JoinMeeting = PAPEventGeneric<
  'meetings',
  'click',
  'join_meeting',
  {
    // Meeting IDs that are uuidv4s as generated by neo4j. Used to tie together Dash meetings and related content.
    meetingId?: string;
    featureLine?: FeatureLine;
  }
>;

export function PAP_Click_JoinMeeting(
  properties?: Click_JoinMeeting['properties'],
): Click_JoinMeeting {
  return <Click_JoinMeeting>{
    class: 'meetings',
    action: 'click',
    object: 'join_meeting',
    properties,
  };
}
