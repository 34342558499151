// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3488
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashStackFilterName } from '../enums/dash_stack_filter_name';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { StartPageAction } from '../enums/start_page_action';

// Event_name: 'select.start_page_action'
// Description: event that captures the general activities on start page
// Owner: dash-web
export type Select_StartPageAction = PAPEventGeneric<
  'dash',
  'select',
  'start_page_action',
  {
    // event property to capture general actions on start page
    startPageAction?: StartPageAction;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Boolean event property that is set to true for Dash for Business (dfb) users.
    isDfbUserV2?: boolean;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // filters for stacks
    dashStackFilterName?: DashStackFilterName;
  }
>;

export function PAP_Select_StartPageAction(
  properties?: Select_StartPageAction['properties'],
): Select_StartPageAction {
  return <Select_StartPageAction>{
    class: 'dash',
    action: 'select',
    object: 'start_page_action',
    properties,
  };
}
