// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgAtriumHq({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.667 2C2.747 2 2 2.746 2 3.667v16.666C2 21.253 2.746 22 3.667 22h16.666c.92 0 1.667-.746 1.667-1.667V3.667C22 2.747 21.254 2 20.333 2H3.667zm12.916 2.5c-.69 0-1.25.56-1.25 1.25v12.5a1.25 1.25 0 002.5 0V5.75c0-.69-.56-1.25-1.25-1.25zm-5.416 4.583a1.25 1.25 0 112.5 0v9.167a1.25 1.25 0 01-2.5 0V9.083zm-2.917 1.25c-.69 0-1.25.56-1.25 1.25v6.667a1.25 1.25 0 102.5 0v-6.667c0-.69-.56-1.25-1.25-1.25z"
        fill="#DE722E"
      />
    </svg>
  );
}

export default SvgAtriumHq;
