import isEqual from 'lodash/isEqual';

/**
 * Simple closure function to check if a given value has changed. This is
 * useful for optimizing any kind of events emission like this:
 * ```
 *   const emitter = new Emitter<{ color: Color[] }>();
 *   const colorChanged = getValueChangedFunc<Color>();
 *   ...
 *   if (colorChanged('red')) emitter.emit('color', 'red');
 * ```
 *
 * Note: All events emission should use this to optimize the app performance.
 *
 * Example usage:
 * ```
 *   const colorChanged = getValueChangedFunc<Color>(); // init with undefined
 *
 *   if (colorChanged('red')) doSomething() // will run
 *   if (colorChanged('red')) doSomething() // will skip
 *
 *   if (colorChanged('blue')) doSomething() // will run
 *   if (colorChanged('blue')) doSomething() // will skip
 * ```
 *
 * @param initialValue Defaults to `undefined` if not given.
 * @returns True if changed, false if no change.
 */
export function getValueChangedFunc<T>(initialValue?: T) {
  return (newValue: T) => {
    if (isEqual(initialValue, newValue)) {
      return false;
    }

    initialValue = newValue; // assume no need to clone
    return true;
  };
}
