import { darwinSymbols } from './darwin';
import { win32Symbols } from './win32';

export type KeyboardModifierSet = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export function getKeyboardModifierSets(isDarwin: boolean) {
  const symbols = {
    commandOrControl: isDarwin ? darwinSymbols.command : win32Symbols.ctrl,
    escape: 'Esc',
    controlOrAlt: isDarwin ? darwinSymbols.control : win32Symbols.alt,
    ...(isDarwin ? darwinSymbols : win32Symbols),
  };

  return {
    symbols,
  };
}
