// @generated by bzl gen
// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/ml_capabilities/api_v2.proto (package ml_capabilities, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { BytesValue, Empty, Message, proto3 } from '@bufbuild/protobuf';
import {
  CapabilitiesConfig,
  ChatDialog,
  ChatResult,
  Config,
  EmbeddingResult,
  FileId,
  ImageGenerationResult,
  ModelInfo,
  TextResult,
} from './common_pb';
import { CapabilityNotAllowedError } from './capability_check_api_v2_pb';

/**
 * Error type being returned in error response
 *
 * @generated from enum ml_capabilities.ErrorType
 */
export enum ErrorType {
  /**
   * @generated from enum value: UNKNOWN_ERROR = 0;
   */
  UNKNOWN_ERROR = 0,

  /**
   * There was an internal error processing the request.
   *
   * @generated from enum value: INTERNAL_ERROR = 1;
   */
  INTERNAL_ERROR = 1,

  /**
   * @generated from enum value: INVALID_ARGUMENT = 2;
   */
  INVALID_ARGUMENT = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ErrorType)
proto3.util.setEnumType(ErrorType, 'ml_capabilities.ErrorType', [
  { no: 0, name: 'UNKNOWN_ERROR' },
  { no: 1, name: 'INTERNAL_ERROR' },
  { no: 2, name: 'INVALID_ARGUMENT' },
]);

/**
 * List Model
 *
 * @generated from message ml_capabilities.ModelDeployment
 */
export class ModelDeployment extends Message<ModelDeployment> {
  /**
   * @generated from field: string name = 1;
   */
  name = '';

  /**
   * @generated from field: string status = 2;
   */
  status = '';

  /**
   * @generated from field: int32 total_replicas = 3;
   */
  totalReplicas = 0;

  /**
   * @generated from field: string model_type = 4;
   */
  modelType = '';

  /**
   * value of the metadata map will be converted to a string since Stone doesn't support google.protobuf.Any
   *
   * @generated from field: map<string, string> metadata = 5;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<ModelDeployment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.ModelDeployment';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'status', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 3,
      name: 'total_replicas',
      kind: 'scalar',
      T: 5 /* ScalarType.INT32 */,
    },
    { no: 4, name: 'model_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 5,
      name: 'metadata',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ModelDeployment {
    return new ModelDeployment().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ModelDeployment {
    return new ModelDeployment().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ModelDeployment {
    return new ModelDeployment().fromJsonString(jsonString, options);
  }

  static equals(
    a: ModelDeployment | PlainMessage<ModelDeployment> | undefined,
    b: ModelDeployment | PlainMessage<ModelDeployment> | undefined,
  ): boolean {
    return proto3.util.equals(ModelDeployment, a, b);
  }
}

/**
 * List all available models and their info.
 *
 * @generated from message ml_capabilities.ListModelRequest
 */
export class ListModelRequest extends Message<ListModelRequest> {
  /**
   * Optional.
   *
   * @generated from field: repeated string model_names = 1;
   */
  modelNames: string[] = [];

  /**
   * Optional. Return models filtered by model_type.
   * ie. 'llm', 'text-to-text', 'text-to-image', etc.
   *
   * @generated from field: repeated string model_types = 2;
   */
  modelTypes: string[] = [];

  /**
   * Optional. Return models filtered by supported capabilities
   * ie 'text/summary', 'image/description', etc.
   *
   * @generated from field: repeated string capabilities = 3;
   */
  capabilities: string[] = [];

  constructor(data?: PartialMessage<ListModelRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.ListModelRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'model_names',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 2,
      name: 'model_types',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 3,
      name: 'capabilities',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ListModelRequest {
    return new ListModelRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ListModelRequest {
    return new ListModelRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ListModelRequest {
    return new ListModelRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListModelRequest | PlainMessage<ListModelRequest> | undefined,
    b: ListModelRequest | PlainMessage<ListModelRequest> | undefined,
  ): boolean {
    return proto3.util.equals(ListModelRequest, a, b);
  }
}

/**
 * @generated from message ml_capabilities.ListModelResponse
 */
export class ListModelResponse extends Message<ListModelResponse> {
  /**
   * @generated from field: repeated ml_capabilities.ModelDeployment models = 1;
   */
  models: ModelDeployment[] = [];

  constructor(data?: PartialMessage<ListModelResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.ListModelResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'models',
      kind: 'message',
      T: ModelDeployment,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ListModelResponse {
    return new ListModelResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ListModelResponse {
    return new ListModelResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ListModelResponse {
    return new ListModelResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListModelResponse | PlainMessage<ListModelResponse> | undefined,
    b: ListModelResponse | PlainMessage<ListModelResponse> | undefined,
  ): boolean {
    return proto3.util.equals(ListModelResponse, a, b);
  }
}

/**
 * @generated from message ml_capabilities.ListModelError
 */
export class ListModelError extends Message<ListModelError> {
  /**
   * @generated from field: string err = 1;
   */
  err = '';

  /**
   * @generated from field: ml_capabilities.ErrorType type = 2;
   */
  type = ErrorType.UNKNOWN_ERROR;

  constructor(data?: PartialMessage<ListModelError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.ListModelError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'err', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'type', kind: 'enum', T: proto3.getEnumType(ErrorType) },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ListModelError {
    return new ListModelError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ListModelError {
    return new ListModelError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ListModelError {
    return new ListModelError().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListModelError | PlainMessage<ListModelError> | undefined,
    b: ListModelError | PlainMessage<ListModelError> | undefined,
  ): boolean {
    return proto3.util.equals(ListModelError, a, b);
  }
}

/**
 * CreateTextSummaryRequest object
 *
 * @generated from message ml_capabilities.CreateTextSummaryRequest
 */
export class CreateTextSummaryRequest extends Message<CreateTextSummaryRequest> {
  /**
   * Details of requested model.
   * Model name is required. Model source will default to 'kserve'.
   *
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * Optional if `input_texts` is defined.
   *
   * @generated from field: repeated ml_capabilities.FileId file_ids = 2;
   */
  fileIds: FileId[] = [];

  /**
   * Optional if `file_ids` is defined. Raw text input.
   *
   * @generated from field: repeated string input_texts = 3;
   */
  inputTexts: string[] = [];

  /**
   * Optional Dropbox specific config options.
   *
   * @generated from field: ml_capabilities.CapabilitiesConfig capabilities_config = 4;
   */
  capabilitiesConfig?: CapabilitiesConfig;

  /**
   * Optional. Model specific input parameters.
   * Example of `model_params` for OpenAI:
   * https://platform.openai.com/docs/api-reference/completions/create
   *
   * @generated from field: map<string, ml_capabilities.Config> model_params = 5;
   */
  modelParams: { [key: string]: Config } = {};

  constructor(data?: PartialMessage<CreateTextSummaryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateTextSummaryRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    { no: 2, name: 'file_ids', kind: 'message', T: FileId, repeated: true },
    {
      no: 3,
      name: 'input_texts',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 4,
      name: 'capabilities_config',
      kind: 'message',
      T: CapabilitiesConfig,
    },
    {
      no: 5,
      name: 'model_params',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Config },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateTextSummaryRequest {
    return new CreateTextSummaryRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateTextSummaryRequest {
    return new CreateTextSummaryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateTextSummaryRequest {
    return new CreateTextSummaryRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | CreateTextSummaryRequest
      | PlainMessage<CreateTextSummaryRequest>
      | undefined,
    b:
      | CreateTextSummaryRequest
      | PlainMessage<CreateTextSummaryRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateTextSummaryRequest, a, b);
  }
}

/**
 * @generated from message ml_capabilities.CreateTextSummaryResponse
 */
export class CreateTextSummaryResponse extends Message<CreateTextSummaryResponse> {
  /**
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * Index based lookup of summary results
   *
   * @generated from field: repeated ml_capabilities.TextResult summaries = 2;
   */
  summaries: TextResult[] = [];

  constructor(data?: PartialMessage<CreateTextSummaryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateTextSummaryResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    {
      no: 2,
      name: 'summaries',
      kind: 'message',
      T: TextResult,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateTextSummaryResponse {
    return new CreateTextSummaryResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateTextSummaryResponse {
    return new CreateTextSummaryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateTextSummaryResponse {
    return new CreateTextSummaryResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | CreateTextSummaryResponse
      | PlainMessage<CreateTextSummaryResponse>
      | undefined,
    b:
      | CreateTextSummaryResponse
      | PlainMessage<CreateTextSummaryResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateTextSummaryResponse, a, b);
  }
}

/**
 * CreateTextCompletionRequest object
 *
 * @generated from message ml_capabilities.CreateTextCompletionRequest
 */
export class CreateTextCompletionRequest extends Message<CreateTextCompletionRequest> {
  /**
   * Details of requested model.
   * Model name is required. Model source will default to 'kserve'.
   *
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * Required. A set of prompts to generate text for.
   *
   * @generated from field: repeated string prompts = 2;
   */
  prompts: string[] = [];

  /**
   * Optional Dropbox specific config options.
   *
   * @generated from field: ml_capabilities.CapabilitiesConfig capabilities_config = 3;
   */
  capabilitiesConfig?: CapabilitiesConfig;

  /**
   * Optional. Model specific input parameters.
   * Example of `model_params` for OpenAI:
   * https://platform.openai.com/docs/api-reference/completions/create
   *
   * @generated from field: map<string, ml_capabilities.Config> model_params = 4;
   */
  modelParams: { [key: string]: Config } = {};

  constructor(data?: PartialMessage<CreateTextCompletionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateTextCompletionRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    {
      no: 2,
      name: 'prompts',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 3,
      name: 'capabilities_config',
      kind: 'message',
      T: CapabilitiesConfig,
    },
    {
      no: 4,
      name: 'model_params',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Config },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateTextCompletionRequest {
    return new CreateTextCompletionRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateTextCompletionRequest {
    return new CreateTextCompletionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateTextCompletionRequest {
    return new CreateTextCompletionRequest().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateTextCompletionRequest
      | PlainMessage<CreateTextCompletionRequest>
      | undefined,
    b:
      | CreateTextCompletionRequest
      | PlainMessage<CreateTextCompletionRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateTextCompletionRequest, a, b);
  }
}

/**
 * @generated from message ml_capabilities.CreateTextCompletionResponse
 */
export class CreateTextCompletionResponse extends Message<CreateTextCompletionResponse> {
  /**
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * Index based lookup of generated text
   *
   * @generated from field: repeated ml_capabilities.TextResult generated_texts = 2;
   */
  generatedTexts: TextResult[] = [];

  constructor(data?: PartialMessage<CreateTextCompletionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateTextCompletionResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    {
      no: 2,
      name: 'generated_texts',
      kind: 'message',
      T: TextResult,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateTextCompletionResponse {
    return new CreateTextCompletionResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateTextCompletionResponse {
    return new CreateTextCompletionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateTextCompletionResponse {
    return new CreateTextCompletionResponse().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateTextCompletionResponse
      | PlainMessage<CreateTextCompletionResponse>
      | undefined,
    b:
      | CreateTextCompletionResponse
      | PlainMessage<CreateTextCompletionResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateTextCompletionResponse, a, b);
  }
}

/**
 * CreateTextEmbeddingRequest object
 *
 * @generated from message ml_capabilities.CreateTextEmbeddingRequest
 */
export class CreateTextEmbeddingRequest extends Message<CreateTextEmbeddingRequest> {
  /**
   * Details of requested model
   * Model name is required. Model source will default to 'kserve'.
   *
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * Optional if text is defined.
   *
   * @generated from field: repeated ml_capabilities.FileId file_ids = 2;
   */
  fileIds: FileId[] = [];

  /**
   * Optional if `file_ids` is defined. Raw text input.
   *
   * @generated from field: repeated string input_texts = 3;
   */
  inputTexts: string[] = [];

  /**
   * Optional Dropbox specific config options.
   *
   * @generated from field: ml_capabilities.CapabilitiesConfig capabilities_config = 4;
   */
  capabilitiesConfig?: CapabilitiesConfig;

  /**
   * Optional. Model specific input parameters.
   * Example of `model_params` for OpenAI:
   * https://platform.openai.com/docs/api-reference/completions/create
   *
   * @generated from field: map<string, ml_capabilities.Config> model_params = 5;
   */
  modelParams: { [key: string]: Config } = {};

  constructor(data?: PartialMessage<CreateTextEmbeddingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateTextEmbeddingRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    { no: 2, name: 'file_ids', kind: 'message', T: FileId, repeated: true },
    {
      no: 3,
      name: 'input_texts',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 4,
      name: 'capabilities_config',
      kind: 'message',
      T: CapabilitiesConfig,
    },
    {
      no: 5,
      name: 'model_params',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Config },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateTextEmbeddingRequest {
    return new CreateTextEmbeddingRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateTextEmbeddingRequest {
    return new CreateTextEmbeddingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateTextEmbeddingRequest {
    return new CreateTextEmbeddingRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | CreateTextEmbeddingRequest
      | PlainMessage<CreateTextEmbeddingRequest>
      | undefined,
    b:
      | CreateTextEmbeddingRequest
      | PlainMessage<CreateTextEmbeddingRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateTextEmbeddingRequest, a, b);
  }
}

/**
 * @generated from message ml_capabilities.CreateTextEmbeddingResponse
 */
export class CreateTextEmbeddingResponse extends Message<CreateTextEmbeddingResponse> {
  /**
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * Index based lookup of embedding results
   *
   * @generated from field: repeated ml_capabilities.EmbeddingResult results = 2;
   */
  results: EmbeddingResult[] = [];

  constructor(data?: PartialMessage<CreateTextEmbeddingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateTextEmbeddingResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    {
      no: 2,
      name: 'results',
      kind: 'message',
      T: EmbeddingResult,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateTextEmbeddingResponse {
    return new CreateTextEmbeddingResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateTextEmbeddingResponse {
    return new CreateTextEmbeddingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateTextEmbeddingResponse {
    return new CreateTextEmbeddingResponse().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateTextEmbeddingResponse
      | PlainMessage<CreateTextEmbeddingResponse>
      | undefined,
    b:
      | CreateTextEmbeddingResponse
      | PlainMessage<CreateTextEmbeddingResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateTextEmbeddingResponse, a, b);
  }
}

/**
 * CreateImageDescriptionRequest object
 *
 * @generated from message ml_capabilities.CreateImageDescriptionRequest
 */
export class CreateImageDescriptionRequest extends Message<CreateImageDescriptionRequest> {
  /**
   * Details of requested model.
   * Model name is required. Model source will default to 'kserve'.
   *
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * Optional. Questions to ask based on the image passed in
   *
   * @generated from field: repeated string prompts = 2;
   */
  prompts: string[] = [];

  /**
   * Optional if image_bytes is defined.
   *
   * @generated from field: ml_capabilities.FileId file_id = 3;
   */
  fileId?: FileId;

  /**
   * Optional if file_id is defined. Raw image input.
   *
   * @generated from field: google.protobuf.BytesValue image_bytes = 4;
   */
  imageBytes?: Uint8Array;

  /**
   * Optional Dropbox specific config options.
   *
   * @generated from field: ml_capabilities.CapabilitiesConfig capabilities_config = 5;
   */
  capabilitiesConfig?: CapabilitiesConfig;

  /**
   * Optional. Model specific input parameters.
   * Example of `model_params` for OpenAI:
   * https://platform.openai.com/docs/api-reference/completions/create
   *
   * @generated from field: map<string, ml_capabilities.Config> model_params = 6;
   */
  modelParams: { [key: string]: Config } = {};

  constructor(data?: PartialMessage<CreateImageDescriptionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateImageDescriptionRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    {
      no: 2,
      name: 'prompts',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    { no: 3, name: 'file_id', kind: 'message', T: FileId },
    { no: 4, name: 'image_bytes', kind: 'message', T: BytesValue },
    {
      no: 5,
      name: 'capabilities_config',
      kind: 'message',
      T: CapabilitiesConfig,
    },
    {
      no: 6,
      name: 'model_params',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Config },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateImageDescriptionRequest {
    return new CreateImageDescriptionRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateImageDescriptionRequest {
    return new CreateImageDescriptionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateImageDescriptionRequest {
    return new CreateImageDescriptionRequest().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateImageDescriptionRequest
      | PlainMessage<CreateImageDescriptionRequest>
      | undefined,
    b:
      | CreateImageDescriptionRequest
      | PlainMessage<CreateImageDescriptionRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateImageDescriptionRequest, a, b);
  }
}

/**
 * @generated from message ml_capabilities.CreateImageDescriptionResponse
 */
export class CreateImageDescriptionResponse extends Message<CreateImageDescriptionResponse> {
  /**
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * @generated from field: repeated string generated_text = 2;
   */
  generatedText: string[] = [];

  constructor(data?: PartialMessage<CreateImageDescriptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateImageDescriptionResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    {
      no: 2,
      name: 'generated_text',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateImageDescriptionResponse {
    return new CreateImageDescriptionResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateImageDescriptionResponse {
    return new CreateImageDescriptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateImageDescriptionResponse {
    return new CreateImageDescriptionResponse().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateImageDescriptionResponse
      | PlainMessage<CreateImageDescriptionResponse>
      | undefined,
    b:
      | CreateImageDescriptionResponse
      | PlainMessage<CreateImageDescriptionResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateImageDescriptionResponse, a, b);
  }
}

/**
 * CreateQuestionAnswerRequest object
 *
 * @generated from message ml_capabilities.CreateQuestionAnswerRequest
 */
export class CreateQuestionAnswerRequest extends Message<CreateQuestionAnswerRequest> {
  /**
   * Details of requested model.
   * Model name is required. Model source will default to 'kserve'.
   *
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * Required. Include previous questions & answers to provide more context to the model.
   *
   * @generated from field: string question = 2;
   */
  question = '';

  /**
   * Optional. If provided, the answer may source context from the file.
   *
   * @generated from field: repeated ml_capabilities.FileId file_ids = 3;
   */
  fileIds: FileId[] = [];

  /**
   * Dropbox specific config options
   *
   * @generated from field: ml_capabilities.CapabilitiesConfig capabilities_config = 4;
   */
  capabilitiesConfig?: CapabilitiesConfig;

  /**
   * Optional. Model specific input parameters.
   * Example of `model_params` for OpenAI:
   * https://platform.openai.com/docs/api-reference/completions/create
   *
   * @generated from field: map<string, ml_capabilities.Config> model_params = 5;
   */
  modelParams: { [key: string]: Config } = {};

  constructor(data?: PartialMessage<CreateQuestionAnswerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateQuestionAnswerRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    { no: 2, name: 'question', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'file_ids', kind: 'message', T: FileId, repeated: true },
    {
      no: 4,
      name: 'capabilities_config',
      kind: 'message',
      T: CapabilitiesConfig,
    },
    {
      no: 5,
      name: 'model_params',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Config },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateQuestionAnswerRequest {
    return new CreateQuestionAnswerRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateQuestionAnswerRequest {
    return new CreateQuestionAnswerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateQuestionAnswerRequest {
    return new CreateQuestionAnswerRequest().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateQuestionAnswerRequest
      | PlainMessage<CreateQuestionAnswerRequest>
      | undefined,
    b:
      | CreateQuestionAnswerRequest
      | PlainMessage<CreateQuestionAnswerRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateQuestionAnswerRequest, a, b);
  }
}

/**
 * @generated from message ml_capabilities.CreateQuestionAnswerResponse
 */
export class CreateQuestionAnswerResponse extends Message<CreateQuestionAnswerResponse> {
  /**
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * @generated from field: string answer = 2;
   */
  answer = '';

  constructor(data?: PartialMessage<CreateQuestionAnswerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateQuestionAnswerResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    { no: 2, name: 'answer', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateQuestionAnswerResponse {
    return new CreateQuestionAnswerResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateQuestionAnswerResponse {
    return new CreateQuestionAnswerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateQuestionAnswerResponse {
    return new CreateQuestionAnswerResponse().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateQuestionAnswerResponse
      | PlainMessage<CreateQuestionAnswerResponse>
      | undefined,
    b:
      | CreateQuestionAnswerResponse
      | PlainMessage<CreateQuestionAnswerResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateQuestionAnswerResponse, a, b);
  }
}

/**
 * CreateTextTranslationRequest object
 *
 * @generated from message ml_capabilities.CreateTextTranslationRequest
 */
export class CreateTextTranslationRequest extends Message<CreateTextTranslationRequest> {
  /**
   * Details of requested model.
   * Model name is required. Model source will default to 'kserve'.
   *
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * Required. Specify the language to translate from.
   *
   * @generated from field: string src_lang = 2;
   */
  srcLang = '';

  /**
   * Reauired. Specify the language(s) to translate to.
   *
   * @generated from field: repeated string dest_langs = 3;
   */
  destLangs: string[] = [];

  /**
   * Optional if `input_texts` is defined.
   *
   * @generated from field: repeated ml_capabilities.FileId file_ids = 4;
   */
  fileIds: FileId[] = [];

  /**
   * Optional if `file_ids` is defined. Raw text input.
   *
   * @generated from field: repeated string input_texts = 5;
   */
  inputTexts: string[] = [];

  /**
   * Optional Dropbox specific config options.
   *
   * @generated from field: ml_capabilities.CapabilitiesConfig capabilities_config = 6;
   */
  capabilitiesConfig?: CapabilitiesConfig;

  /**
   * Optional. Model specific input parameters.
   * Example of `model_params` for OpenAI:
   * https://platform.openai.com/docs/api-reference/completions/create
   *
   * @generated from field: map<string, ml_capabilities.Config> model_params = 7;
   */
  modelParams: { [key: string]: Config } = {};

  constructor(data?: PartialMessage<CreateTextTranslationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateTextTranslationRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    { no: 2, name: 'src_lang', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 3,
      name: 'dest_langs',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    { no: 4, name: 'file_ids', kind: 'message', T: FileId, repeated: true },
    {
      no: 5,
      name: 'input_texts',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 6,
      name: 'capabilities_config',
      kind: 'message',
      T: CapabilitiesConfig,
    },
    {
      no: 7,
      name: 'model_params',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Config },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateTextTranslationRequest {
    return new CreateTextTranslationRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateTextTranslationRequest {
    return new CreateTextTranslationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateTextTranslationRequest {
    return new CreateTextTranslationRequest().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateTextTranslationRequest
      | PlainMessage<CreateTextTranslationRequest>
      | undefined,
    b:
      | CreateTextTranslationRequest
      | PlainMessage<CreateTextTranslationRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateTextTranslationRequest, a, b);
  }
}

/**
 * @generated from message ml_capabilities.CreateTextTranslationResponse
 */
export class CreateTextTranslationResponse extends Message<CreateTextTranslationResponse> {
  /**
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * Index based lookup translation of result
   *
   * @generated from field: repeated ml_capabilities.TextResult translations = 2;
   */
  translations: TextResult[] = [];

  constructor(data?: PartialMessage<CreateTextTranslationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateTextTranslationResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    {
      no: 2,
      name: 'translations',
      kind: 'message',
      T: TextResult,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateTextTranslationResponse {
    return new CreateTextTranslationResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateTextTranslationResponse {
    return new CreateTextTranslationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateTextTranslationResponse {
    return new CreateTextTranslationResponse().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateTextTranslationResponse
      | PlainMessage<CreateTextTranslationResponse>
      | undefined,
    b:
      | CreateTextTranslationResponse
      | PlainMessage<CreateTextTranslationResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateTextTranslationResponse, a, b);
  }
}

/**
 * CreateChatCompletionRequest object
 *
 * @generated from message ml_capabilities.CreateChatCompletionRequest
 */
export class CreateChatCompletionRequest extends Message<CreateChatCompletionRequest> {
  /**
   * Details of requested model.
   * Model name is required. Model source will default to 'kserve'.
   *
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * Required. A sequence of dialogs to generate responses for.
   *
   * @generated from field: repeated ml_capabilities.ChatDialog dialogs = 2;
   */
  dialogs: ChatDialog[] = [];

  /**
   * Optional Dropbox specific config options.
   *
   * @generated from field: ml_capabilities.CapabilitiesConfig capabilities_config = 3;
   */
  capabilitiesConfig?: CapabilitiesConfig;

  /**
   * Optional. Model specific input parameters.
   * Example of `model_params` for OpenAI:
   * https://platform.openai.com/docs/api-reference/chat/create
   *
   * @generated from field: map<string, ml_capabilities.Config> model_params = 4;
   */
  modelParams: { [key: string]: Config } = {};

  constructor(data?: PartialMessage<CreateChatCompletionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateChatCompletionRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    { no: 2, name: 'dialogs', kind: 'message', T: ChatDialog, repeated: true },
    {
      no: 3,
      name: 'capabilities_config',
      kind: 'message',
      T: CapabilitiesConfig,
    },
    {
      no: 4,
      name: 'model_params',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Config },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateChatCompletionRequest {
    return new CreateChatCompletionRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateChatCompletionRequest {
    return new CreateChatCompletionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateChatCompletionRequest {
    return new CreateChatCompletionRequest().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateChatCompletionRequest
      | PlainMessage<CreateChatCompletionRequest>
      | undefined,
    b:
      | CreateChatCompletionRequest
      | PlainMessage<CreateChatCompletionRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateChatCompletionRequest, a, b);
  }
}

/**
 * @generated from message ml_capabilities.CreateChatCompletionResponse
 */
export class CreateChatCompletionResponse extends Message<CreateChatCompletionResponse> {
  /**
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * @generated from field: repeated ml_capabilities.ChatResult results = 2;
   */
  results: ChatResult[] = [];

  constructor(data?: PartialMessage<CreateChatCompletionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateChatCompletionResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    { no: 2, name: 'results', kind: 'message', T: ChatResult, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateChatCompletionResponse {
    return new CreateChatCompletionResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateChatCompletionResponse {
    return new CreateChatCompletionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateChatCompletionResponse {
    return new CreateChatCompletionResponse().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateChatCompletionResponse
      | PlainMessage<CreateChatCompletionResponse>
      | undefined,
    b:
      | CreateChatCompletionResponse
      | PlainMessage<CreateChatCompletionResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateChatCompletionResponse, a, b);
  }
}

/**
 * CreateImageEmbeddingRequest object
 *
 * @generated from message ml_capabilities.CreateImageEmbeddingRequest
 */
export class CreateImageEmbeddingRequest extends Message<CreateImageEmbeddingRequest> {
  /**
   * Details of requested model.
   * Model name is required. Model source will default to 'kserve'.
   *
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * Optional if image_bytes is defined.
   *
   * @generated from field: ml_capabilities.FileId file_id = 2;
   */
  fileId?: FileId;

  /**
   * Optional if file_id is defined. Raw image input.
   *
   * @generated from field: google.protobuf.BytesValue image_bytes = 3;
   */
  imageBytes?: Uint8Array;

  /**
   * Optional Dropbox specific config options.
   *
   * @generated from field: ml_capabilities.CapabilitiesConfig capabilities_config = 4;
   */
  capabilitiesConfig?: CapabilitiesConfig;

  /**
   * Optional. Model specific input parameters.
   * Example of `model_params` for OpenAI:
   * https://platform.openai.com/docs/api-reference/completions/create
   *
   * @generated from field: map<string, ml_capabilities.Config> model_params = 5;
   */
  modelParams: { [key: string]: Config } = {};

  constructor(data?: PartialMessage<CreateImageEmbeddingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateImageEmbeddingRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    { no: 2, name: 'file_id', kind: 'message', T: FileId },
    { no: 3, name: 'image_bytes', kind: 'message', T: BytesValue },
    {
      no: 4,
      name: 'capabilities_config',
      kind: 'message',
      T: CapabilitiesConfig,
    },
    {
      no: 5,
      name: 'model_params',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Config },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateImageEmbeddingRequest {
    return new CreateImageEmbeddingRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateImageEmbeddingRequest {
    return new CreateImageEmbeddingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateImageEmbeddingRequest {
    return new CreateImageEmbeddingRequest().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateImageEmbeddingRequest
      | PlainMessage<CreateImageEmbeddingRequest>
      | undefined,
    b:
      | CreateImageEmbeddingRequest
      | PlainMessage<CreateImageEmbeddingRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateImageEmbeddingRequest, a, b);
  }
}

/**
 * @generated from message ml_capabilities.CreateImageEmbeddingResponse
 */
export class CreateImageEmbeddingResponse extends Message<CreateImageEmbeddingResponse> {
  /**
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * @generated from field: ml_capabilities.EmbeddingResult result = 2;
   */
  result?: EmbeddingResult;

  constructor(data?: PartialMessage<CreateImageEmbeddingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateImageEmbeddingResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    { no: 2, name: 'result', kind: 'message', T: EmbeddingResult },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateImageEmbeddingResponse {
    return new CreateImageEmbeddingResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateImageEmbeddingResponse {
    return new CreateImageEmbeddingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateImageEmbeddingResponse {
    return new CreateImageEmbeddingResponse().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateImageEmbeddingResponse
      | PlainMessage<CreateImageEmbeddingResponse>
      | undefined,
    b:
      | CreateImageEmbeddingResponse
      | PlainMessage<CreateImageEmbeddingResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateImageEmbeddingResponse, a, b);
  }
}

/**
 * CreateImageGenerationRequest object
 *
 * @generated from message ml_capabilities.CreateImageGenerationRequest
 */
export class CreateImageGenerationRequest extends Message<CreateImageGenerationRequest> {
  /**
   * Details of requested model.
   * Model name is required. Model source will default to 'kserve'.
   *
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * Required. Prompt to generate image from.
   *
   * @generated from field: string prompt = 2;
   */
  prompt = '';

  /**
   * Optional Dropbox specific config options.
   *
   * @generated from field: ml_capabilities.CapabilitiesConfig capabilities_config = 3;
   */
  capabilitiesConfig?: CapabilitiesConfig;

  /**
   * Optional. Model specific input parameters.
   * Example of `model_params` for OpenAI:
   * https://platform.openai.com/docs/api-reference/images/create
   *
   * @generated from field: map<string, ml_capabilities.Config> model_params = 4;
   */
  modelParams: { [key: string]: Config } = {};

  constructor(data?: PartialMessage<CreateImageGenerationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateImageGenerationRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    { no: 2, name: 'prompt', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 3,
      name: 'capabilities_config',
      kind: 'message',
      T: CapabilitiesConfig,
    },
    {
      no: 4,
      name: 'model_params',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Config },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateImageGenerationRequest {
    return new CreateImageGenerationRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateImageGenerationRequest {
    return new CreateImageGenerationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateImageGenerationRequest {
    return new CreateImageGenerationRequest().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateImageGenerationRequest
      | PlainMessage<CreateImageGenerationRequest>
      | undefined,
    b:
      | CreateImageGenerationRequest
      | PlainMessage<CreateImageGenerationRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateImageGenerationRequest, a, b);
  }
}

/**
 * @generated from message ml_capabilities.CreateImageGenerationResponse
 */
export class CreateImageGenerationResponse extends Message<CreateImageGenerationResponse> {
  /**
   * @generated from field: ml_capabilities.ModelInfo model_info = 1;
   */
  modelInfo?: ModelInfo;

  /**
   * Index based lookup of image generation results
   * If `model_source` is 'OpenAI', the results will be a list of image urls (field `urls`).
   * If `model_source` is 'HuggingFace', the results will be a list of image bytes (field `image_bytes`).
   *
   * @generated from field: repeated ml_capabilities.ImageGenerationResult results = 2;
   */
  results: ImageGenerationResult[] = [];

  constructor(data?: PartialMessage<CreateImageGenerationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CreateImageGenerationResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_info', kind: 'message', T: ModelInfo },
    {
      no: 2,
      name: 'results',
      kind: 'message',
      T: ImageGenerationResult,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateImageGenerationResponse {
    return new CreateImageGenerationResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateImageGenerationResponse {
    return new CreateImageGenerationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateImageGenerationResponse {
    return new CreateImageGenerationResponse().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateImageGenerationResponse
      | PlainMessage<CreateImageGenerationResponse>
      | undefined,
    b:
      | CreateImageGenerationResponse
      | PlainMessage<CreateImageGenerationResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateImageGenerationResponse, a, b);
  }
}

/**
 * Error type being returned in error response
 *
 * @generated from message ml_capabilities.CapabilitiesError
 */
export class CapabilitiesError extends Message<CapabilitiesError> {
  /**
   * @generated from oneof ml_capabilities.CapabilitiesError.err
   */
  err:
    | {
        /**
         * @generated from field: ml_capabilities_types.CapabilityNotAllowedError capability_not_allowed_error = 1;
         */
        value: CapabilityNotAllowedError;
        case: 'capabilityNotAllowedError';
      }
    | {
        /**
         * Indicates that a required argument was not provided.
         *
         * @generated from field: google.protobuf.Empty missing_required_args = 2;
         */
        value: Empty;
        case: 'missingRequiredArgs';
      }
    | {
        /**
         * Indicates that an argument was provided that is not allowed.
         *
         * @generated from field: google.protobuf.Empty invalid_args = 3;
         */
        value: Empty;
        case: 'invalidArgs';
      }
    | {
        /**
         * Indicates that the user is not authorized to access the requested resource.
         *
         * @generated from field: google.protobuf.Empty unauthorized = 4;
         */
        value: Empty;
        case: 'unauthorized';
      }
    | {
        /**
         * Indicates that the request was not processed due to an internal error.
         *
         * @generated from field: google.protobuf.Empty internal_failure = 5;
         */
        value: Empty;
        case: 'internalFailure';
      }
    | {
        /**
         * Indicates that there was an error getting the files.
         *
         * @generated from field: google.protobuf.Empty failed_to_get_files = 6;
         */
        value: Empty;
        case: 'failedToGetFiles';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CapabilitiesError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'ml_capabilities.CapabilitiesError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'capability_not_allowed_error',
      kind: 'message',
      T: CapabilityNotAllowedError,
      oneof: 'err',
    },
    {
      no: 2,
      name: 'missing_required_args',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    { no: 3, name: 'invalid_args', kind: 'message', T: Empty, oneof: 'err' },
    { no: 4, name: 'unauthorized', kind: 'message', T: Empty, oneof: 'err' },
    {
      no: 5,
      name: 'internal_failure',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    {
      no: 6,
      name: 'failed_to_get_files',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CapabilitiesError {
    return new CapabilitiesError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CapabilitiesError {
    return new CapabilitiesError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CapabilitiesError {
    return new CapabilitiesError().fromJsonString(jsonString, options);
  }

  static equals(
    a: CapabilitiesError | PlainMessage<CapabilitiesError> | undefined,
    b: CapabilitiesError | PlainMessage<CapabilitiesError> | undefined,
  ): boolean {
    return proto3.util.equals(CapabilitiesError, a, b);
  }
}
