import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type { stacks } from '@dropbox/api-v2-client';
import type { Service } from '@mirage/service-stack-suggestions/service';
import type { ContentSuggestionItem } from '@mirage/service-stack-suggestions/service/api';

const service = services.get<Service>(ServiceId.STACK_SUGGESTIONS);

export const autoStackSuggestions = service.autoStackSuggestions;

export const dismissStackSuggestion = async function dismissStackSuggestion(
  stack: stacks.AutoStackSuggestion,
): Promise<void> {
  await rx.firstValueFrom(service.dismissStackSuggestion(stack));
};

export async function refreshStackSuggestions() {
  await rx.firstValueFrom(service.refreshStackSuggestions());
}

export async function getContentSuggestions(
  stack: stacks.Stack | string,
  items: ContentSuggestionItem[],
  scoreThreshold: number = 0.01,
  maxItems: number = 20,
) {
  return rx.firstValueFrom(
    service.getContentSuggestions(stack, items, scoreThreshold, maxItems),
  );
}
