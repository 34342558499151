// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3122
import type { PAPEventGeneric } from '../base/event';
import type { BrowserName } from '../enums/browser_name';
import type { DashSurface } from '../enums/dash_surface';
import type { Environment } from '../enums/environment';
import type { JtbdAnswer } from '../enums/jtbd_answer';
import type { OperatingSystem } from '../enums/operating_system';
import type { PageName } from '../enums/page_name';
import type { QuestionType } from '../enums/question_type';

// Event_name: 'select.dash_jtbd_onboarding'
// Description: Select event for Dash JTBD Onboarding Flow
// Owner: otc-growth
export type Select_DashJtbdOnboarding = PAPEventGeneric<
  'dash',
  'select',
  'dash_jtbd_onboarding',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // This indicates the user's current browser
    browserName?: BrowserName;
    // Describes on which page the action took place
    pageName?: PageName;
    // The type of question that will be asked in user onboarding
    questionType?: QuestionType;
    // Answers for Dash JTBD onboarding
    jtbdAnswer?: JtbdAnswer;
  }
>;

export function PAP_Select_DashJtbdOnboarding(
  properties?: Select_DashJtbdOnboarding['properties'],
): Select_DashJtbdOnboarding {
  return <Select_DashJtbdOnboarding>{
    class: 'dash',
    action: 'select',
    object: 'dash_jtbd_onboarding',
    properties,
  };
}
