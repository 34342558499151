/**
 * Quickly parse markdown content for unstyled title + markdown body.
 * Note that this is not precise and may not work for certain markdown formatting in the title.
 * Largely intended for cases like showing a placeholder title in UI or suggesting a file name.
 */
export function parseMarkdownContent(content: string) {
  const parts = content.split('\n');
  // strip any leading '#' characters
  const title = (parts[0] || '').replace(/^#+\s*/, '');
  const body = parts.slice(1).join('\n').trim();
  return { title, body };
}
