import {
  AvailableThemeOverridesStrict,
  defineStrictThemeOverrides,
} from "@dropbox/dig-foundations";

export enum ThemeFamilyKey {
  Ocean = "Ocean",
  Crimson = "Crimson",
  Azalea = "Azalea",
  Rust = "Rust",
  Canopy = "Canopy",
  Navy = "Navy",
  Plum = "Plum",
  Gold = "Gold",
  Zen = "Zen",
  Sunset = "Sunset",
  Pink = "Pink",
  Tangerine = "Tangerine",
  Lime = "Lime",
  Cloud = "Cloud",
  Orchid = "Orchid",
  VividVargas = "VividVargas",
}

export type ThemeVariant = "base" | "subtle";

export type ThemeFamily = Record<ThemeVariant, AvailableThemeOverridesStrict>;

export function familyFor(key: ThemeFamilyKey): ThemeFamily {
  switch (key) {
    case ThemeFamilyKey.Ocean:
      return { base: oceanTheme, subtle: oceanSubtleTheme };
    case ThemeFamilyKey.Crimson:
      return { base: crimsonTheme, subtle: crimsonSubtleTheme };
    case ThemeFamilyKey.Azalea:
      return { base: azaleaTheme, subtle: azaleaSubtleTheme };
    case ThemeFamilyKey.Rust:
      return { base: rustTheme, subtle: rustSubtleTheme };
    case ThemeFamilyKey.Canopy:
      return { base: canopyTheme, subtle: canopySubtleTheme };
    case ThemeFamilyKey.Navy:
      return { base: navyTheme, subtle: navySubtleTheme };
    case ThemeFamilyKey.Plum:
      return { base: plumTheme, subtle: plumSubtleTheme };
    case ThemeFamilyKey.Gold:
      return { base: goldTheme, subtle: goldSubtleTheme };
    case ThemeFamilyKey.Zen:
      return { base: zenTheme, subtle: zenSubtleTheme };
    case ThemeFamilyKey.Sunset:
      return { base: sunsetTheme, subtle: sunsetSubtleTheme };
    case ThemeFamilyKey.Pink:
      return { base: pinkTheme, subtle: pinkSubtleTheme };
    case ThemeFamilyKey.Tangerine:
      return { base: tangerineTheme, subtle: tangerineSubtleTheme };
    case ThemeFamilyKey.Lime:
      return { base: limeTheme, subtle: limeSubtleTheme };
    case ThemeFamilyKey.Cloud:
      return { base: cloudTheme, subtle: cloudSubtleTheme };
    case ThemeFamilyKey.Orchid:
      return { base: orchidTheme, subtle: orchidSubtleTheme };
    case ThemeFamilyKey.VividVargas:
      return { base: vividVargasTheme, subtle: vividVargasSubtleTheme };
  }
}

export const dashTheme = defineStrictThemeOverrides({
  colors: {
    "Background Subtle": {
      bright: "#F7F6F5",
      dark: "#242321",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

export const dashBlueTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Dropbox Blue 100",
      dark: "Foundation Dropbox Blue 800",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Dropbox Blue 150",
      dark: "Foundation Dropbox Blue 750",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Dropbox Blue 200",
      dark: "Foundation Dropbox Blue 700",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.7)",
      dark: "rgba(255, 255, 255, 0.8)",
    },
    "Border Base": {
      bright: "rgba(0, 0, 0, 0.3)",
      dark: "rgba(255, 255, 255, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(0, 0, 0, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(0, 0, 0, 0.53)",
      dark: "rgba(255, 255, 255, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(0, 0, 0, 0.07)",
      dark: "rgba(255, 255, 255, 0.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(0, 0, 0, 0.1)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(0, 0, 0, 0.14)",
      dark: "rgba(255, 255, 255, 0.20)",
    },
  },
});

// const yellowTheme = defineStrictThemeOverrides({
//   colors: {
//     "Primary Surface": {
//       bright: "Foundation Vivid Vargas 100",
//       dark: "Foundation Vivid Vargas 800",
//     },
//     "Primary Surface - State 1": {
//       bright: "Foundation Vivid Vargas 150",
//       dark: "Foundation Vivid Vargas 700",
//     },
//     "Primary Surface - State 2": {
//       bright: "Foundation Vivid Vargas 200",
//       dark: "Foundation Vivid Vargas 600",
//     },
//     "Text Subtle": {
//       bright: "rgba(0, 0, 0, 0.7)",
//       dark: "rgba(255, 255, 255, 0.65)",
//     },
//     "Border Base": {
//       bright: "rgba(0, 0, 0, 0.3)",
//       dark: "rgba(255, 255, 255, 0.3)",
//     },
//     "Border Subtle": {
//       bright: "rgba(0, 0, 0, 0.15)",
//       dark: "rgba(255, 255, 255, 0.15)",
//     },
//     "Border Bold": {
//       bright: "rgba(0, 0, 0, 0.53)",
//       dark: "rgba(255, 255, 255, 0.53)",
//     },
//     "Opacity Surface": {
//       bright: "rgba(0, 0, 0, 0.07)",
//       dark: "rgba(255, 255, 255, 0.07)",
//     },
//     "Opacity Surface - State 1": {
//       bright: "rgba(0, 0, 0, 0.1)",
//       dark: "rgba(255, 255, 255, 0.1)",
//     },
//     "Opacity Surface - State 2": {
//       bright: "rgba(0, 0, 0, 0.14)",
//       dark: "rgba(255, 255, 255, 0.14)",
//     },
//   },
// });

const oceanTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Ocean 750",
      dark: "Foundation Ocean 750",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Ocean 550",
      dark: "Foundation Ocean 550",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Ocean 500",
      dark: "Foundation Ocean 500",
    },
    "Text Base": {
      bright: "Foundation Zen 100",
      dark: "Foundation Zen 100",
    },
    "Text Subtle": {
      bright: "Foundation Zen 200",
      dark: "Foundation Zen 200",
    },
    "Border Base": {
      bright: "rgba(255, 255, 255, 0.3)",
      dark: "rgba(255, 255, 255, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(255, 255, 255, 0.53)",
      dark: "rgba(255, 255, 255, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(255, 255, 255, 0.1)",
      dark: "rgba(255, 255, 255, 0.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(255, 255, 255, 0.20)",
      dark: "rgba(255, 255, 255, 0.20)",
    },
  },
});

const oceanSubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Ocean 50",
      dark: "Foundation Ocean 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

const crimsonTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Crimson 850",
      dark: "Foundation Crimson 850",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Crimson 700",
      dark: "Foundation Crimson 700",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Crimson 650",
      dark: "Foundation Crimson 650",
    },
    "Text Base": {
      bright: "Foundation Sunset 150",
      dark: "Foundation Sunset 150",
    },
    "Text Subtle": {
      bright: "Foundation Sunset 350",
      dark: "Foundation Sunset 350",
    },
    "Border Base": {
      bright: "rgba(255, 255, 255, 0.3)",
      dark: "rgba(255, 255, 255, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(255, 255, 255, 0.53)",
      dark: "rgba(255, 255, 255, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(255, 255, 255, 0.1)",
      dark: "rgba(255, 255, 255, 0.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(255, 255, 255, 0.20)",
      dark: "rgba(255, 255, 255, 0.20)",
    },
  },
});

const crimsonSubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Crimson 50",
      dark: "Foundation Crimson 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

const azaleaTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Azalea 750",
      dark: "Foundation Azalea 750",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Azalea 550",
      dark: "Foundation Azalea 550",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Azalea 500",
      dark: "Foundation Azalea 500",
    },
    "Text Base": {
      bright: "Foundation Pink 100",
      dark: "Foundation Pink 100",
    },
    "Text Subtle": {
      bright: "Foundation Pink 200",
      dark: "Foundation Pink 200",
    },
    "Border Base": {
      bright: "rgba(255, 255, 255, 0.3)",
      dark: "rgba(255, 255, 255, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(255, 255, 255, 0.53)",
      dark: "rgba(255, 255, 255, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(255, 255, 255, 0.1)",
      dark: "rgba(255, 255, 255, 0.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(255, 255, 255, 0.20)",
      dark: "rgba(255, 255, 255, 0.20)",
    },
  },
});

const azaleaSubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Azalea 50",
      dark: "Foundation Azalea 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

const rustTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Rust 750",
      dark: "Foundation Rust 750",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Rust 550",
      dark: "Foundation Rust 550",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Rust 500",
      dark: "Foundation Rust 500",
    },
    "Text Base": {
      bright: "Foundation Tangerine 100",
      dark: "Foundation Tangerine 100",
    },
    "Text Subtle": {
      bright: "Foundation Tangerine 200",
      dark: "Foundation Tangerine 200",
    },
    "Border Base": {
      bright: "rgba(255, 255, 255, 0.3)",
      dark: "rgba(255, 255, 255, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(255, 255, 255, 0.53)",
      dark: "rgba(255, 255, 255, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(255, 255, 255, 0.1)",
      dark: "rgba(255, 255, 255, 0.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(255, 255, 255, 0.20)",
      dark: "rgba(255, 255, 255, 0.20)",
    },
  },
});

const rustSubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Rust 50",
      dark: "Foundation Rust 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

const canopyTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Canopy 750",
      dark: "Foundation Canopy 750",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Canopy 700",
      dark: "Foundation Canopy 700",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Canopy 650",
      dark: "Foundation Canopy 650",
    },
    "Text Base": {
      bright: "Foundation Lime 100",
      dark: "Foundation Lime 100",
    },
    "Text Subtle": {
      bright: "Foundation Lime 200",
      dark: "Foundation Lime 200",
    },
    "Border Base": {
      bright: "rgba(255, 255, 255, 0.3)",
      dark: "rgba(255, 255, 255, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(255, 255, 255, 0.53)",
      dark: "rgba(255, 255, 255, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(255, 255, 255, 0.1)",
      dark: "rgba(255, 255, 255, 0.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(255, 255, 255, 0.20)",
      dark: "rgba(255, 255, 255, 0.20)",
    },
  },
});

const canopySubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Canopy 50",
      dark: "Foundation Canopy 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

const navyTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Navy 750",
      dark: "Foundation Navy 750",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Navy 700",
      dark: "Foundation Navy 700",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Navy 650",
      dark: "Foundation Navy 650",
    },
    "Text Base": {
      bright: "Foundation Cloud 100",
      dark: "Foundation Cloud 100",
    },
    "Text Subtle": {
      bright: "Foundation Cloud 200",
      dark: "Foundation Cloud 200",
    },
    "Border Base": {
      bright: "rgba(255, 255, 255, 0.3)",
      dark: "rgba(255, 255, 255, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(255, 255, 255, 0.53)",
      dark: "rgba(255, 255, 255, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(255, 255, 255, 0.1)",
      dark: "rgba(255, 255, 255, 0.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(255, 255, 255, 0.20)",
      dark: "rgba(255, 255, 255, 0.20)",
    },
  },
});

const navySubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Navy 50",
      dark: "Foundation Navy 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

const plumTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Plum 750",
      dark: "Foundation Plum 750",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Plum 650",
      dark: "Foundation Plum 650",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Plum 600",
      dark: "Foundation Plum 600",
    },
    "Text Base": {
      bright: "Foundation Orchid 100",
      dark: "Foundation Orchid 100",
    },
    "Text Subtle": {
      bright: "Foundation Orchid 200",
      dark: "Foundation Orchid 200",
    },
    "Border Base": {
      bright: "rgba(255, 255, 255, 0.3)",
      dark: "rgba(255, 255, 255, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(255, 255, 255, 0.53)",
      dark: "rgba(255, 255, 255, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(255, 255, 255, 0.1)",
      dark: "rgba(255, 255, 255, 0.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(255, 255, 255, 0.20)",
      dark: "rgba(255, 255, 255, 0.20)",
    },
  },
});

const plumSubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Plum 50",
      dark: "Foundation Plum 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

const goldTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Gold 750",
      dark: "Foundation Gold 750",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Gold 700",
      dark: "Foundation Gold 700",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Gold 650",
      dark: "Foundation Gold 650",
    },
    "Text Base": {
      bright: "Foundation Vivid Vargas 100",
      dark: "Foundation Vivid Vargas 100",
    },
    "Text Subtle": {
      bright: "Foundation Vivid Vargas 200",
      dark: "Foundation Vivid Vargas 200",
    },
    "Border Base": {
      bright: "rgba(255, 255, 255, 0.3)",
      dark: "rgba(255, 255, 255, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(255, 255, 255, 0.53)",
      dark: "rgba(255, 255, 255, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(255, 255, 255, 0.1)",
      dark: "rgba(255, 255, 255, 0.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(255, 255, 255, 0.15)",
      dark: "rgba(255, 255, 255, 0.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(255, 255, 255, 0.20)",
      dark: "rgba(255, 255, 255, 0.20)",
    },
  },
});

const goldSubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Gold 50",
      dark: "Foundation Gold 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

const zenTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Zen 150",
      dark: "Foundation Zen 150",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Zen 200",
      dark: "Foundation Zen 200",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Zen 250",
      dark: "Foundation Zen 250",
    },
    "Text Base": {
      bright: "Foundation Ocean 950",
      dark: "Foundation Ocean 950",
    },
    "Text Subtle": {
      bright: "Foundation Ocean 700",
      dark: "Foundation Ocean 700",
    },
    "Border Base": {
      bright: "rgba(0, 0, 0, 0.3)",
      dark: "rgba(0, 0, 0, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(0, 0, 0, 0.15)",
      dark: "rgba(0, 0, 0, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(0, 0, 0, 0.53)",
      dark: "rgba(0, 0, 0, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(0, 0, 0, 0.07)",
      dark: "rgba(0, 0, 0, 0.07)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(0, 0, 0, 0.1)",
      dark: "rgba(0, 0, 0, 0.1)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(0, 0, 0, 0.14)",
      dark: "rgba(0, 0, 0, 0.14)",
    },
  },
});

const zenSubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Zen 50",
      dark: "Foundation Zen 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

const sunsetTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Sunset 350",
      dark: "Foundation Sunset 350",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Sunset 400",
      dark: "Foundation Sunset 400",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Sunset 450",
      dark: "Foundation Sunset 450",
    },
    "Text Base": {
      bright: "Foundation Crimson 1000",
      dark: "Foundation Crimson 1000",
    },
    "Text Subtle": {
      bright: "Foundation Crimson 900",
      dark: "Foundation Crimson 900",
    },
    "Border Base": {
      bright: "rgba(0, 0, 0, 0.3)",
      dark: "rgba(0, 0, 0, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(0, 0, 0, 0.15)",
      dark: "rgba(0, 0, 0, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(0, 0, 0, 0.53)",
      dark: "rgba(0, 0, 0, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(0, 0, 0, 0.07)",
      dark: "rgba(0, 0, 0, 0.07)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(0, 0, 0, 0.1)",
      dark: "rgba(0, 0, 0, 0.1)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(0, 0, 0, 0.14)",
      dark: "rgba(0, 0, 0, 0.14)",
    },
  },
});

const sunsetSubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Sunset 50",
      dark: "Foundation Sunset 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

const pinkTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Pink 150",
      dark: "Foundation Pink 150",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Pink 200",
      dark: "Foundation Pink 200",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Pink 250",
      dark: "Foundation Pink 250",
    },
    "Text Base": {
      bright: "Foundation Azalea 900",
      dark: "Foundation Azalea 900",
    },
    "Text Subtle": {
      bright: "Foundation Azalea 750",
      dark: "Foundation Azalea 750",
    },
    "Border Base": {
      bright: "rgba(0, 0, 0, 0.3)",
      dark: "rgba(0, 0, 0, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(0, 0, 0, 0.15)",
      dark: "rgba(0, 0, 0, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(0, 0, 0, 0.53)",
      dark: "rgba(0, 0, 0, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(0, 0, 0, 0.07)",
      dark: "rgba(0, 0, 0, 0.07)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(0, 0, 0, 0.1)",
      dark: "rgba(0, 0, 0, 0.1)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(0, 0, 0, 0.14)",
      dark: "rgba(0, 0, 0, 0.14)",
    },
  },
});

const pinkSubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Pink 50",
      dark: "Foundation Pink 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

const tangerineTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Tangerine 200",
      dark: "Foundation Tangerine 200",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Tangerine 250",
      dark: "Foundation Tangerine 250",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Tangerine 300",
      dark: "Foundation Tangerine 300",
    },
    "Text Base": {
      bright: "Foundation Rust 950",
      dark: "Foundation Rust 950",
    },
    "Text Subtle": {
      bright: "Foundation Rust 800",
      dark: "Foundation Rust 800",
    },
    "Border Base": {
      bright: "rgba(0, 0, 0, 0.3)",
      dark: "rgba(0, 0, 0, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(0, 0, 0, 0.15)",
      dark: "rgba(0, 0, 0, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(0, 0, 0, 0.53)",
      dark: "rgba(0, 0, 0, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(0, 0, 0, 0.07)",
      dark: "rgba(0, 0, 0, 0.07)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(0, 0, 0, 0.1)",
      dark: "rgba(0, 0, 0, 0.1)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(0, 0, 0, 0.14)",
      dark: "rgba(0, 0, 0, 0.14)",
    },
  },
});

const tangerineSubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Tangerine 50",
      dark: "Foundation Tangerine 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

const limeTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Lime 150",
      dark: "Foundation Lime 150",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Lime 200",
      dark: "Foundation Lime 200",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Lime 250",
      dark: "Foundation Lime 250",
    },
    "Text Base": {
      bright: "Foundation Canopy 900",
      dark: "Foundation Canopy 900",
    },
    "Text Subtle": {
      bright: "Foundation Canopy 700",
      dark: "Foundation Canopy 700",
    },
    "Border Base": {
      bright: "rgba(0, 0, 0, 0.3)",
      dark: "rgba(0, 0, 0, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(0, 0, 0, 0.15)",
      dark: "rgba(0, 0, 0, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(0, 0, 0, 0.53)",
      dark: "rgba(0, 0, 0, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(0, 0, 0, 0.07)",
      dark: "rgba(0, 0, 0, 0.07)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(0, 0, 0, 0.1)",
      dark: "rgba(0, 0, 0, 0.1)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(0, 0, 0, 0.14)",
      dark: "rgba(0, 0, 0, 0.14)",
    },
  },
});

const limeSubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Lime 50",
      dark: "Foundation Lime 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

const cloudTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Cloud 150",
      dark: "Foundation Cloud 150",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Cloud 200",
      dark: "Foundation Cloud 200",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Cloud 250",
      dark: "Foundation Cloud 250",
    },
    "Text Base": {
      bright: "Foundation Navy 950",
      dark: "Foundation Navy 950",
    },
    "Text Subtle": {
      bright: "Foundation Navy 750",
      dark: "Foundation Navy 750",
    },
    "Border Base": {
      bright: "rgba(0, 0, 0, 0.3)",
      dark: "rgba(0, 0, 0, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(0, 0, 0, 0.15)",
      dark: "rgba(0, 0, 0, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(0, 0, 0, 0.53)",
      dark: "rgba(0, 0, 0, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(0, 0, 0, 0.07)",
      dark: "rgba(0, 0, 0, 0.07)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(0, 0, 0, 0.1)",
      dark: "rgba(0, 0, 0, 0.1)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(0, 0, 0, 0.14)",
      dark: "rgba(0, 0, 0, 0.14)",
    },
  },
});

const cloudSubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Cloud 50",
      dark: "Foundation Cloud 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

const orchidTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Orchid 150",
      dark: "Foundation Orchid 150",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Orchid 200",
      dark: "Foundation Orchid 200",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Orchid 250",
      dark: "Foundation Orchid 250",
    },
    "Text Base": {
      bright: "Foundation Plum 900",
      dark: "Foundation Plum 900",
    },
    "Text Subtle": {
      bright: "Foundation Plum 700",
      dark: "Foundation Plum 700",
    },
    "Border Base": {
      bright: "rgba(0, 0, 0, 0.3)",
      dark: "rgba(0, 0, 0, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(0, 0, 0, 0.15)",
      dark: "rgba(0, 0, 0, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(0, 0, 0, 0.53)",
      dark: "rgba(0, 0, 0, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(0, 0, 0, 0.07)",
      dark: "rgba(0, 0, 0, 0.07)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(0, 0, 0, 0.1)",
      dark: "rgba(0, 0, 0, 0.1)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(0, 0, 0, 0.14)",
      dark: "rgba(0, 0, 0, 0.14)",
    },
  },
});

const orchidSubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Orchid 50",
      dark: "Foundation Orchid 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});

const vividVargasTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Vivid Vargas 150",
      dark: "Foundation Vivid Vargas 150",
    },
    "Primary Surface - State 1": {
      bright: "Foundation Vivid Vargas 200",
      dark: "Foundation Vivid Vargas 200",
    },
    "Primary Surface - State 2": {
      bright: "Foundation Vivid Vargas 250",
      dark: "Foundation Vivid Vargas 250",
    },
    "Text Base": {
      bright: "Foundation Gold 900",
      dark: "Foundation Gold 900",
    },
    "Text Subtle": {
      bright: "Foundation Gold 700",
      dark: "Foundation Gold 700",
    },
    "Border Base": {
      bright: "rgba(0, 0, 0, 0.3)",
      dark: "rgba(0, 0, 0, 0.3)",
    },
    "Border Subtle": {
      bright: "rgba(0, 0, 0, 0.15)",
      dark: "rgba(0, 0, 0, 0.15)",
    },
    "Border Bold": {
      bright: "rgba(0, 0, 0, 0.53)",
      dark: "rgba(0, 0, 0, 0.53)",
    },
    "Opacity Surface": {
      bright: "rgba(0, 0, 0, 0.07)",
      dark: "rgba(0, 0, 0, 0.07)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(0, 0, 0, 0.1)",
      dark: "rgba(0, 0, 0, 0.1)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(0, 0, 0, 0.14)",
      dark: "rgba(0, 0, 0, 0.14)",
    },
  },
});

const vividVargasSubtleTheme = defineStrictThemeOverrides({
  colors: {
    "Primary Surface": {
      bright: "Foundation Vivid Vargas 50",
      dark: "Foundation Vivid Vargas 950",
    },
    "Text Subtle": {
      bright: "rgba(0, 0, 0, 0.58)",
      dark: "rgba(255, 255, 255, 0.67)",
    },
    "Opacity Surface": {
      bright: "rgba(138, 119, 92, .1)",
      dark: "rgba(255,255,255,.1)",
    },
    "Opacity Surface - State 1": {
      bright: "rgba(138, 119, 92, .17)",
      dark: "rgba(255,255,255,.15)",
    },
    "Opacity Surface - State 2": {
      bright: "rgba(138, 119, 92, .2)",
      dark: "rgba(255,255,255,.20)",
    },
  },
});
