import React from 'react';

export const useScrollDetector = (
  scrollContainerRef?: React.RefObject<HTMLDivElement>,
) => {
  const [hasScrolled, setHasScrolled] = React.useState(false);
  const [hasReachedBottom, setHasReachedBottom] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef && scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollContainerRef.current;

        // Check if we've scrolled at all
        if (scrollContainerRef.current.scrollTop > 0) {
          setHasScrolled(true);
        } else {
          setHasScrolled(false);
        }

        // Check if we've reached the bottom of the scroll container
        if (scrollTop + clientHeight >= scrollHeight) {
          setHasReachedBottom(true);
        } else {
          setHasReachedBottom(false);
        }
      }
    };

    if (scrollContainerRef && scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainerRef && scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollContainerRef?.current]);

  return { hasScrolled, hasReachedBottom };
};
