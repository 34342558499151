import { Button } from '@dropbox/dig-components/buttons';
import { Box } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Collapse_DashCard } from '@mirage/analytics/events/types/collapse_dash_card';
import { PAP_Expand_DashCard } from '@mirage/analytics/events/types/expand_dash_card';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { PAP_Shown_TabPanel } from '@mirage/analytics/events/types/shown_tab_panel';
import { TileCardGrid } from '@mirage/dash-component-library/components/TileCard';
import { NoContentPlaceholder } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/NoContentPlaceholder';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { openURL } from '@mirage/service-platform-actions';
import {
  filterRecentContents,
  useCombinedRecents,
} from '@mirage/service-recent-content/hooks';
import { RecentContent } from '@mirage/service-recent-content/types';
import { useMetadataForUrls } from '@mirage/service-url-metadata/hooks';
import { privacyModeEnabledAtom } from '@mirage/shared/atoms/privacyMode';
import { Blur } from '@mirage/shared/blur/Blur';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import { useDynamicExpandableContent } from '@mirage/stacks/FullScreenStack/useDynamicExpandableContent';
import i18n from '@mirage/translations';
import { usePrevious } from '@react-hookz/web';
import { useAtomValue } from 'jotai/index';
import * as React from 'react';
import { forwardRef, Ref, useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { RecentCard } from '../../RecentsModule';
import styles from './RecentsTabPanel.module.css';

const LOADING_TILE_COUNT = 8;
const EMPTY_ARRAY: RecentContent[] = [];

export const RecentsTabPanel = ({
  headerButtonSlot,
  isActive,
}: {
  isActive: boolean;
  headerButtonSlot: Element | null;
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const privacyModeEnabled = useAtomValue(privacyModeEnabledAtom);
  const { content, isLoading } = useCombinedRecents();
  const { connectors } = useConnectors();
  const recentContent = useMemo(() => {
    return isLoading ? EMPTY_ARRAY : filterRecentContents(content, connectors);
  }, [connectors, content, isLoading]);

  const { expanded, showMore, setExpanded, wrapperRef, numVisible } =
    useDynamicExpandableContent(`div.${styles.selectorRecentsCard}`, isActive);

  const handleToggleButton = useCallback(() => {
    if (expanded) {
      reportPapEvent(
        PAP_Collapse_DashCard({
          featureLine: 'recents',
          actionSurfaceComponent: 'tab_panel',
        }),
      );
    } else {
      reportPapEvent(
        PAP_Expand_DashCard({
          featureLine: 'recents',
          actionSurfaceComponent: 'tab_panel',
        }),
      );
    }
    setExpanded((prev) => !prev);
  }, [expanded, setExpanded, reportPapEvent]);

  const withAccessory: React.ReactNode[] = [];
  if (showMore) {
    withAccessory.push(
      <Button
        variant="transparent"
        key="recents-toggle"
        onClick={handleToggleButton}
        size="small"
      >
        {expanded ? i18n.t('show_less_answer') : i18n.t('show_more_answer')}
      </Button>,
    );
  }

  const previousIsActive = usePrevious(isActive);
  useEffect(() => {
    // Let's only log when we go from not active -> active
    if (!previousIsActive && isActive) {
      reportPapEvent(
        PAP_Shown_TabPanel({
          startPageAction: 'click_recents_tab',
          isEmpty: recentContent.length === 0,
          featureLine: 'recents',
          actionSurfaceComponent: 'tab_panel',
        }),
      );
    }
  }, [isActive, reportPapEvent, recentContent, isLoading]);

  return (
    <Box
      style={{
        display: isActive ? 'block' : 'none',
        position: 'relative',
      }}
    >
      {headerButtonSlot && createPortal(withAccessory, headerButtonSlot)}
      <Blur isBlurred={privacyModeEnabled} blurAmount="high">
        <RecentsTileCardGrid
          expanded={expanded}
          recentCardContent={recentContent}
          ref={wrapperRef}
          isLoading={isLoading}
          numVisible={numVisible}
        />
      </Blur>
    </Box>
  );
};

const RecentsTileCardGrid = forwardRef(
  (
    {
      expanded,
      recentCardContent,
      isLoading,
      numVisible,
    }: {
      expanded: boolean;
      isLoading: boolean;
      recentCardContent: RecentContent[];
      numVisible: number;
    },
    ref?: Ref<HTMLDivElement>,
  ) => {
    const recentContentUrls = recentCardContent.map((c) => c.url);
    const urlMetadata = useMetadataForUrls(recentContentUrls) || {};
    const { reportPapEvent } = useMirageAnalyticsContext();
    const handleOnClick = useCallback(
      (content: RecentContent) => {
        if (content.url) {
          openURL(content.url);
          reportPapEvent(
            PAP_Open_DashLink({
              featureLine: 'recents',
              actionSurfaceComponent: 'recents',
              dashConnectorId:
                'connectorName' in content.connectorInfo
                  ? content.connectorInfo.connectorName
                  : undefined,
            }),
          );
        }
      },
      [reportPapEvent],
    );

    const showNoRecents = !isLoading && recentCardContent.length === 0;
    if (showNoRecents) {
      return <NoContentPlaceholder message={i18n.t('no_recents_yet')} />;
    }

    return (
      <TileCardGrid
        expanded={expanded}
        ref={ref}
        isLoading={isLoading}
        tileCardClassName={styles.selectorRecentsCard}
        loadingTileCount={LOADING_TILE_COUNT}
        numVisible={numVisible}
      >
        {recentCardContent.map((content, i) => (
          <RecentCard
            content={content}
            selectorClassname={styles.selectorRecentsCard}
            key={content.uuid}
            hidden={i >= numVisible}
            iconSrc={
              urlMetadata[content.url]?.faviconUrl ||
              faviconSrcForSrcUrl(content.url)
            }
            handleOnClick={handleOnClick}
          />
        ))}
      </TileCardGrid>
    );
  },
);
RecentsTileCardGrid.displayName = 'RecentsTileCardGrid';
