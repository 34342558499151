import { dash } from '@dropbox/api-v2-client/types/dropbox_types';
import { Recommendation } from '@mirage/shared/search/recommendation';

export interface ComposeSession {
  dataId: string; // Server public id
  id: string; // Client private id
  messagesHistory: ComposeAssistantConversationMessage[];
  sources: ComposeSource[];
  artifacts: ComposeArtifact[];
  lastUpdated?: number;
}

export type ComposeAssistantConversationMessage =
  | ComposeAssistantConversationMessageMessage
  | ComposeAssistantConversationMessageInstruction;

export interface ComposeAssistantConversationMessageMessage {
  type: 'message';
  role: 'user' | 'assistant' | 'system';
  text: string;
  ts: number;
  referencingSources?: ComposeSource[];
  rawPromptText?: string; // if specified, use this text for LLM prompt
  ignoreMessageForPrompt?: boolean; // if true, ignore this message for LLM prompt
  actionContext?: ComposeAssistantConversationMessageActionContext;
  followUpSuggestions?: string[];
}

export type ComposeAssistantConversationMessageActionStyle =
  | 'reading'
  | 'done'
  | 'error'
  | 'searching';

export type ComposeAssistantConversationMessageActionContext =
  | {
      type: 'reading';
    }
  | {
      type: 'done';
    }
  | {
      type: 'error';
    }
  | {
      type: 'dash_search';
      queries: string[];
    }
  | {
      type: 'dash_search_analyze';
    }
  | {
      type: 'compose_selection_edit';
      selectedText: string;
      actionType?: 'rewrite' | 'make-longer' | 'make-shorter'; // undefined if it is a custom prompt
    };

export interface ComposeAssistantConversationMessageInstruction {
  type: 'instruction';
  role: 'assistant';
  title: string;
  subtitle?: string;
}

export type ComposeSource =
  | ComposeSourceDashSearchResult
  | ComposeSourceRecommendation;

export interface ComposeSourceDashSearchResult {
  type: 'dash_search_result';
  searchResult: dash.query_result_unionSearchResult;
}

export interface ComposeSourceRecommendation {
  type: 'recommendation';
  recommendation: Recommendation;
}

export function getSourceUUID(source: ComposeSource): string | undefined {
  switch (source.type) {
    case 'dash_search_result':
      return source.searchResult.uuid;
    case 'recommendation':
      return source.recommendation.uuid;
  }
}

export type ComposeArtifact = ComposeArtifactMarkdownDraft;

export interface ComposeArtifactMarkdownDraft {
  id: string;
  type: 'markdown_draft';
  markdownContent: string;
  draftConfig: ComposeAssistantDraftConfig;
}

export function getFirstMarkdownArtifact(
  artifacts: ComposeArtifact[],
): ComposeArtifactMarkdownDraft | undefined {
  return artifacts.find((a) => a.type === 'markdown_draft');
}

export interface ComposeAssistantDraftConfig {
  contentType?: ContentTypeOption;
  contentDescription?: string;
  voiceID?: string;
}
export const CONTENT_TYPES = [
  'email',
  'message',
  'report',
  'proposal',
  'social_media_post',
  'meeting_agenda',
] as const;
export type ContentTypeOption = (typeof CONTENT_TYPES)[number];

export const PRE_CONFIGURED_VOICE_IDS = [
  'preset_informative',
  'preset_persuasive',
  'preset_instructional',
  'preset_narrative',
  'preset_informal',
  'preset_analytical',
] as const;
export type PreConfiguredVoiceID = (typeof PRE_CONFIGURED_VOICE_IDS)[number];

export const DEFAULT_PRECONFIGURED_VOICE_ID: PreConfiguredVoiceID =
  PRE_CONFIGURED_VOICE_IDS[0];

export function isPreConfiguredVoiceID(
  voiceID: string,
): voiceID is PreConfiguredVoiceID {
  return PRE_CONFIGURED_VOICE_IDS.includes(voiceID as PreConfiguredVoiceID);
}

export type InputContext = {
  type: 'selection';
  selectedText: string;
};
