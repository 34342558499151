import i18n from '@mirage/translations';

export const getTimeElapsed = (timestampSec: number): string => {
  const nowSec = Date.now() / 1000; // convert to seconds
  const timeElapsedSec = nowSec - timestampSec;

  const convertToDaysNum = 60 * 60 * 24;
  const days = Math.floor(timeElapsedSec / convertToDaysNum);
  if (days > 0) {
    return i18n.t('updated_days_ago', {
      count: days,
    });
  }

  const hours = Math.floor(timeElapsedSec / (60 * 60));
  if (hours > 0) {
    return i18n.t('updated_hours_ago', {
      count: hours,
    });
  }

  const minutes = Math.floor(timeElapsedSec / 60);
  if (minutes > 0) {
    return i18n.t('updated_minutes_ago', {
      count: minutes,
    });
  }

  return i18n.t('updated_less_than_one_minute');
};
