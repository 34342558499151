import { ButtonProps } from '@dropbox/dig-components/buttons';
import {
  fancyShortcut,
  interleaveKeySetWithChar,
  isDarwinSymbol,
  splitShortcutString,
} from '@mirage/shared/hotkeys';
import classnames from 'classnames';
import KeyCap, { KeyCapCasing } from './KeyCap';
import styles from './PrettyShortcuts.module.css';

type KeycapContainerProps = ButtonProps & {
  editable: boolean;
};

type Props = Readonly<{
  shortcut: string | string[];
  isDarwin: boolean;
  handleToggle?: () => void;
  editable?: boolean;
  casingOverride?: KeyCapCasing;
  separateKeys?: boolean;
}>;

export function PrettyShortcuts({
  shortcut,
  isDarwin,
  handleToggle,
  editable = false,
  casingOverride,
  separateKeys = false,
}: Props) {
  if (!shortcut) return null;
  // default casing for Windows vs. Mac for branding consistency purposes
  // Mac examples: Command, Enter, Option, etc.
  // Windows examples: Ctrl, Alt, Enter, etc.
  const casing = casingOverride || 'capitalize';

  const shortcutArray = Array.isArray(shortcut)
    ? shortcut
    : splitShortcutString(shortcut);

  const shortcutParts = fancyShortcut(isDarwin, shortcutArray);
  const interleavedShortcutParts = interleaveKeySetWithChar(shortcutParts, '+');
  const keys = isDarwin ? shortcutParts : interleavedShortcutParts;
  return (
    <KeycapContainer
      editable={editable}
      variant="borderless"
      className={styles.privateButtonOverride}
      onClick={handleToggle}
      aria-label={interleavedShortcutParts.join(' ')}
    >
      <div
        className={classnames(styles.keycapContainer, {
          [styles.combinedKeys]: !separateKeys,
        })}
      >
        {keys.map((key) => (
          <KeyCap
            key={key}
            symbol={isDarwinSymbol(key)}
            onClick={handleToggle}
            casing={casing}
            separateKeys={separateKeys}
          >
            {key}
          </KeyCap>
        ))}
      </div>
    </KeycapContainer>
  );
}

const KeycapContainer = ({
  editable,
  className,
  children,
}: KeycapContainerProps) => {
  if (editable) {
    return <div className={className}>{children}</div>;
  }
  return <>{children}</>;
};
