import delayw from '@mirage/service-typeahead-search/service/utils/wrappers/delay';
import latency from '@mirage/service-typeahead-search/service/utils/wrappers/latency';
import instrument from '@mirage/service-typeahead-search/service/utils/wrappers/source-instrumentation';
import * as op from 'rxjs/operators';

import type { typeahead } from '@mirage/service-typeahead-search/service/types';

export function wrapped(
  name: string,
  source: typeahead.TaggedSearch,
  delay: number = 0,
): typeahead.TaggedSearch {
  let internal = latency(source);
  internal = instrument(name, internal);
  internal = addSourceAttribution(internal, name);
  if (delay !== 0) internal = delayw(internal, delay);
  return internal;
}

function addSourceAttribution(
  source: typeahead.TaggedSearch,
  name: string,
): typeahead.TaggedSearch {
  return (...args) =>
    source(...args).pipe(
      op.map((item: typeahead.TaggedResult) => {
        return {
          ...item,
          metadata: {
            ...item.metadata,
            source: name,
          },
        } as typeahead.TaggedResult;
      }),
    );
}
