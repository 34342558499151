// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2337
import type { PAPEventGeneric } from '../base/event';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';
import type { SearchResultPageSource } from '../enums/search_result_page_source';

// Event_name: 'open.dash_search_result_page'
// Description: Fires when user clicks “Search Dash” from start page search result list to open the search result page
// Owner: otc
export type Open_DashSearchResultPage = PAPEventGeneric<
  'dash',
  'open',
  'dash_search_result_page',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // Represents the source of where the user is being routed from when the search result page is opened.
    searchResultPageSource?: SearchResultPageSource;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The surface in which an action was taken on Dash.
    dashActionSurface?: DashActionSurface;
    // Identifier for a search query
    searchQueryUuid?: string;
  }
>;

export function PAP_Open_DashSearchResultPage(
  properties?: Open_DashSearchResultPage['properties'],
): Open_DashSearchResultPage {
  return <Open_DashSearchResultPage>{
    class: 'dash',
    action: 'open',
    object: 'dash_search_result_page',
    properties,
  };
}
