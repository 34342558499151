// Copied from https://sourcegraph.pp.dropbox.com/server/-/blob/js/pap-events/base/event.ts

import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { DashActionSurface } from '@mirage/analytics/events/enums/dash_action_surface';
import { DashSurface } from '@mirage/analytics/events/enums/dash_surface';
import { FeatureLine } from '@mirage/analytics/events/enums/feature_line';
import { OperatingSystem } from '@mirage/analytics/events/enums/operating_system';

import type { DashAnnotationMode } from '@mirage/analytics/events/enums/dash_annotation_mode';

export type PAPEventState =
  | 'start'
  | 'success'
  | 'failed'
  | 'canceled'
  | 'rejected';

export type PAPEventDefaultProperties = {
  dashSurface?: DashSurface; // custom added here, not in rSERVER's event.ts
  actionSurface?: string;
  actionSurfaceComponent?: ActionSurfaceComponent;
  startTimeMs?: number;
  eventState?: PAPEventState;
  dashActionSurface?: DashActionSurface;
  featureLine?: FeatureLine;
  dashSessionId?: string;
  buildVersion?: string;
  dashAnnotationMode?: DashAnnotationMode;
  operatingSystem?: OperatingSystem;
  isDfbUser?: boolean;
};

export type PAPEvent = {
  class: string;
  action: string;
  object: string;
  properties?: PAPEventDefaultProperties;
};

export type PAPEventGeneric<C, A, O, P> = {
  class: C;
  action: A;
  object: O;
  properties?: [P] extends [never] ? PAPEventDefaultProperties : P;
} & PAPEvent;

export const enum PAPActionSurface {
  STACKS_PAGE = 'stacks_page',
  START_PAGE = 'start_page',
  STACK_DETAILS_PAGE = 'stack_details_page',
  ASK_PAGE = 'ask_page',
}

export const enum DashFeatures {
  CREATION_SUGGESTIONS = 'creation_suggestions',
}
