import { getCachedConnectorConnections } from '@mirage/service-connectors';
import * as primitives from '@mirage/service-typeahead-search/service/primitives';
import { TypeaheadCache } from '@mirage/service-typeahead-search/service/typeahead-cache';
import { SourceId } from '@mirage/service-typeahead-search/service/types';
import * as wrappers from '@mirage/service-typeahead-search/service/utils/wrappers';
import { filterableContentTypes } from '@mirage/shared/content-type/content-types';
import {
  contentTypeToSearchFilter,
  extractTrailingSearchFilter,
  SearchFilter,
  SearchFilterType,
  toFilterBinding,
} from '@mirage/shared/search/search-filters';
import Sentry from '@mirage/shared/sentry';
import { isDefined } from '@mirage/shared/util/tiny-utils';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';

import type { ConnectorConnection } from '@mirage/service-dbx-api';
import type { typeahead } from '@mirage/service-typeahead-search/service/types';
import type { ConnectorFilter } from '@mirage/shared/search/search-filters';
import type { Observable } from 'rxjs';

export const search = wrappers.wrapped(SourceId.SearchFilter, raw);

export function raw(
  query: string,
  _config: typeahead.Config,
  _cache: TypeaheadCache,
): Observable<typeahead.TaggedResult> {
  return rx
    .from(_internalSearch(query))
    .pipe(op.mergeMap((result) => result))
    .pipe(op.map((result) => primitives.searchFilter(result.id, result)));
}

// exported for testing only
// Consider moving this method else if the need arises
export function connectionToSearchFilter(connection: ConnectorConnection) {
  if (
    !connection?.connector?.id_attrs?.type ||
    !connection?.connector?.branding?.display_name
  ) {
    return;
  }
  return {
    id: connection?.connector?.id_attrs?.type,
    type: SearchFilterType.Connector,
    parameters: {
      connectorId: connection.connector.id_attrs?.type,
      displayName: connection.connector.branding?.display_name,
      iconLightSrc: connection.connector.branding?.icon_src,
      iconDarkSrc: connection.connector.branding?.icon_dark_src,
    },
  } as ConnectorFilter;
}

export async function _internalSearch(query: string): Promise<SearchFilter[]> {
  try {
    const { filterType, query: filterQuery } =
      extractTrailingSearchFilter(query);

    if (!filterType) {
      return [];
    }

    switch (filterType) {
      case SearchFilterType.Connector: {
        const connections = await getCachedConnectorConnections();
        return connections
          .map((connection) => connectionToSearchFilter(connection)!)
          .filter((filter) => {
            return (
              isDefined(filter) &&
              filter!.parameters.displayName
                .toLowerCase()
                .includes(filterQuery) &&
              !query
                .toLowerCase()
                .includes(toFilterBinding('in', filter!.id.toLowerCase()))
            );
          })
          .sort((a, b) =>
            a!.parameters.displayName.localeCompare(b!.parameters.displayName),
          );
      }
      case SearchFilterType.ContentType:
        return filterableContentTypes
          .map((contentType) => contentTypeToSearchFilter(contentType)!)
          .filter(
            (filter) =>
              isDefined(filter) &&
              filter.parameters.label.toLowerCase().includes(filterQuery) &&
              !query
                .toLowerCase()
                .includes(toFilterBinding('type', filter!.id.toLowerCase())),
          )
          .sort((a, b) =>
            a!.parameters.label.localeCompare(b!.parameters.label),
          );
      case SearchFilterType.LastUpdated:
      case SearchFilterType.Person:
      default:
        return [];
    }
  } catch (e) {
    Sentry.captureException(e);
    return [];
  }
}
