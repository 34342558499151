import { PAP_Select_DashAnswersResultLabels } from '@mirage/analytics/events/types/select_dash_answers_result_labels';
import { PAP_Select_DashAnswersSourceLabels } from '@mirage/analytics/events/types/select_dash_answers_source_labels';
import { AnnotationScore } from '@mirage/search/SearchResults/ResultRow/ResultAnnotations';
import { QuestionAndAnswerResponse } from '@mirage/shared/answers/multi-answer';
import { FeedbackOptions } from '@mirage/shared/types';

import type { AnnotationAnswer } from '../AnswersAnnotationModal';
import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { DashAnswersFeedbackTag } from '@mirage/analytics/events/enums/dash_answers_feedback_tag';
import type { FeatureLine } from '@mirage/analytics/events/enums/feature_line';
import type { AnswerResponse } from '@mirage/service-dbx-api/service/answers';

////
// Form State Reducer
////
export interface FormState {
  incorrectReason: string;
  preferredAnswer: string;
  sourceFeedback: Record<string, AnnotationScore>;
}

export const actionTypes = {
  INCORRECT_REASON: 'INCORRECT_REASON',
  PREFERRED_ANSWER: 'PREFERRED_ANSWER',
  RESET_FORM: 'RESET_FORM',
  SOURCE_FEEDBACK: 'SOURCE_FEEDBACK',
} as const;

type BaseActionTypes = 'INCORRECT_REASON' | 'PREFERRED_ANSWER';
type Action =
  | { type: BaseActionTypes; payload: string }
  | { type: 'RESET_FORM' }
  | { type: 'SOURCE_FEEDBACK'; payload: Record<string, AnnotationScore> };

export const initialState: FormState = {
  incorrectReason: '',
  preferredAnswer: '',
  sourceFeedback: {},
};

export function formReducer(state: FormState, action: Action): FormState {
  switch (action.type) {
    case actionTypes.INCORRECT_REASON:
      return { ...state, incorrectReason: action.payload };
    case actionTypes.PREFERRED_ANSWER:
      return { ...state, preferredAnswer: action.payload };
    case actionTypes.RESET_FORM:
      return initialState;
    case actionTypes.SOURCE_FEEDBACK:
      return {
        ...state,
        sourceFeedback: {
          ...state.sourceFeedback,
          ...action.payload,
        },
      };
    default:
      action satisfies never;
      return state;
  }
}

export function convertAnswerRespToAnnotation(
  answer: AnswerResponse | QuestionAndAnswerResponse,
): AnnotationAnswer {
  if (isQuestionAnswerResponse(answer)) {
    return {
      answer: answer.answer,
      sources: answer.sources,
      conversationId: answer.conversationId,
      answerId: answer.answerId,
      question: answer.question,
    };
  }
  return answer;
}

// type guard
function isQuestionAnswerResponse(
  resp: AnswerResponse | QuestionAndAnswerResponse,
): resp is QuestionAndAnswerResponse {
  return 'position' in resp;
}

/////
// Helper functions
////
export function convertStateToAnswersPapEvents(
  state: FormState,
  urls: string[],
  query: string,
  dashAnswerFeedback?: FeedbackOptions,
  answer?: AnnotationAnswer,
  conversationId?: string,
  searchSessionId?: string,
  searchAttemptId?: string,
) {
  const events: Array<PAPEvent> = [];
  if (!answer) return events;

  const baseProperties = {
    searchRequestId: answer.searchRequestId,
    queryString: query,
    answerString: answer.answer,
    searchSessionId,
    searchAttemptId,
    conversationId,
    featureLine: 'multi-answers' as FeatureLine,
    answerId: answer.answerId,
  };

  // fire event for general feedback if any of the fields are filled out
  if (dashAnswerFeedback || state.incorrectReason || state.preferredAnswer) {
    events.push(
      PAP_Select_DashAnswersResultLabels({
        ...baseProperties,
        dashAnswerFeedback,
        dashAnswersFeedbackTag: state.incorrectReason as DashAnswersFeedbackTag,
        dashAnswerPreferred: state.preferredAnswer,
        generatedQueryString: answer?.question,
      }),
    );
  }

  // fire event for each "golden" url provided
  urls.filter(isValidUrl).forEach((url) => {
    events.push(
      PAP_Select_DashAnswersSourceLabels({
        ...baseProperties,
        goldenResult: url,
      }),
    );
  });

  // fire event for each source feedback provided
  Object.entries(state.sourceFeedback).forEach(([resultUuid, score]) => {
    events.push(
      PAP_Select_DashAnswersSourceLabels({
        ...baseProperties,
        resultUuid,
        feedbackScore: score,
      }),
    );
  });
  return events;
}

export function isValidUrl(url: string) {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
}
