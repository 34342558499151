import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import i18n from '@mirage/translations';

export const NetworkErrorModal: React.FC<{
  retry: () => void;
  secondaryButtonText?: string;
  onSecondaryButtonClick?: () => void;
}> = ({ retry, secondaryButtonText, onSecondaryButtonClick }) => {
  const renderSecondaryButton = () => {
    if (!secondaryButtonText || !onSecondaryButtonClick) return null;
    return (
      <Button variant="outline" onClick={onSecondaryButtonClick}>
        {secondaryButtonText}
      </Button>
    );
  };

  return (
    <Modal open={true}>
      <Modal.Header hasBottomSpacing="title-small">
        <Modal.Title>{i18n.t('network_error_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text>{i18n.t('network_error_message')}</Text>
      </Modal.Body>
      <Modal.Footer>
        {renderSecondaryButton()}
        <Button variant="primary" onClick={retry}>
          {i18n.t('logging_in_try_again')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
