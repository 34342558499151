// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3178
import type { PAPEventGeneric } from '../base/event';
import type { DashAnswerFeedback } from '../enums/dash_answer_feedback';
import type { DashAnswersFeedbackTag } from '../enums/dash_answers_feedback_tag';
import type { DashSurface } from '../enums/dash_surface';
import type { FeatureLine } from '../enums/feature_line';

// Event_name: 'select.dash_answers_result_labels'
// Description: event firest when user selects annotation labels for Dash Answers
// Owner: otc-ml
export type Select_DashAnswersResultLabels = PAPEventGeneric<
  'dash',
  'select',
  'dash_answers_result_labels',
  {
    // unique id for a /dash/search query. Used by the ML team to get feedback on search result ranking
    searchRequestId?: string;
    // Unique identifier (UUIDv5 ) of the answer
    answerId?: string;
    // Unique identifier (UUIDv5 ) of the conversation
    conversationId?: string;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // The contents of a search query
    queryString?: string;
    // The LLM-generated answer string returned from dash answers (Q&A) service.
    // This is L0 data, and will be copied to restricted_otc hive table according to this TDD: https://www.dropbox.com/scl/fi/0r38gxrobgr6yvrft4yzu/OTCUS-Machine-Learning-Restricted-Logging-via-PAP-TDD.paper?dl=0&rlkey=7yvsba510fyeufi8cjea9juqk
    answerString?: string;
    // Feedback on the answer provided by dash answer service. Positive, negative, etc.
    dashAnswerFeedback?: DashAnswerFeedback;
    // The tag associated with Dash answers feedback. Users will choose from pre-selected tags like 'Incorrect sources', 'Irrelevant answer', etc.
    dashAnswersFeedbackTag?: DashAnswersFeedbackTag;
    // The result most desired by the user.
    goldenResult?: string;
    // The answer the user would have liked to see
    // This is L0 data, and will be copied to restricted_otc hive table according to this TDD: https://www.dropbox.com/scl/fi/0r38gxrobgr6yvrft4yzu/OTCUS-Machine-Learning-Restricted-Logging-via-PAP-TDD.paper?dl=0&rlkey=7yvsba510fyeufi8cjea9juqk
    dashAnswerPreferred?: string;
    // The query string generated by context engine for suggested questions
    // This is L0 data, and will be copied to restricted_otc hive table according to this TDD: https://www.dropbox.com/scl/fi/0r38gxrobgr6yvrft4yzu/OTCUS-Machine-Learning-Restricted-Logging-via-PAP-TDD.paper?dl=0&rlkey=7yvsba510fyeufi8cjea9juqk
    generatedQueryString?: string;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
  }
>;

export function PAP_Select_DashAnswersResultLabels(
  properties?: Select_DashAnswersResultLabels['properties'],
): Select_DashAnswersResultLabels {
  return <Select_DashAnswersResultLabels>{
    class: 'dash',
    action: 'select',
    object: 'dash_answers_result_labels',
    properties,
  };
}
