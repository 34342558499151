import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import i18n from '@mirage/translations';

export const ArchiveConfirmationModal = ({
  isArchived,
  isOpen,
  onConfirm,
  onCancel,
}: {
  isArchived: boolean;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <Modal
      open={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={onCancel}
      data-testid="ArchiveConfirmationModal"
    >
      <Modal.Header hasBottomSpacing="title-standard">
        <Modal.Title>
          {i18n.t(
            isArchived
              ? 'confirm_unarchive_stack_title'
              : 'confirm_archive_stack_title',
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {i18n.t(
          isArchived
            ? 'confirm_unarchive_stack_body'
            : 'confirm_archive_stack_body',
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="opacity" onClick={onCancel}>
          {i18n.t('cancel')}
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {i18n.t(isArchived ? 'unarchive_stack' : 'archive_stack')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
