import { ServiceId } from '@mirage/discovery/id';
import * as service from '@mirage/discovery/services';

import type { Service } from '@mirage/service-keymap-actions/service';

const keymapActions = service.getp<Service>(ServiceId.KEYMAP_ACTIONS);

export default keymapActions;

export function checkForUpdates(): Promise<void> {
  return keymapActions.checkForUpdates();
}
