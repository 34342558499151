// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgSlack({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.77 20.077a1.923 1.923 0 101.922-1.923H12.77v1.923zm0-5.385c0 1.063.86 1.923 1.923 1.923h5.384a1.923 1.923 0 100-3.846h-5.384a1.923 1.923 0 00-1.923 1.923z"
        fill="#ECB22E"
      />
      <path
        d="M3.923 12.77a1.923 1.923 0 101.923 1.922V12.77H3.923zm5.385 0a1.923 1.923 0 00-1.923 1.922v5.385a1.923 1.923 0 103.846 0v-5.385a1.923 1.923 0 00-1.923-1.923z"
        fill="#E01E5A"
      />
      <path
        d="M20.077 11.23a1.923 1.923 0 10-1.923-1.922v1.923h1.923zm-5.385 0a1.923 1.923 0 001.923-1.923V3.923a1.923 1.923 0 00-3.846 0v5.384c0 1.063.861 1.924 1.923 1.924z"
        fill="#2EB67D"
      />
      <path
        d="M11.23 3.923a1.923 1.923 0 10-1.922 1.923h1.922V3.923zm0 5.385c0-1.062-.86-1.923-1.923-1.923H3.923a1.923 1.923 0 100 3.846h5.384a1.923 1.923 0 001.923-1.923z"
        fill="#36C5F0"
      />
    </svg>
  );
}

export default SvgSlack;
