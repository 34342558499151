import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { InfoLine } from '@dropbox/dig-icons/assets';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import i18n from '@mirage/translations';
import { memo, useCallback, useState } from 'react';
import changelogJson from '../../changelog.json';
import styles from './ChangelogButton.module.css';
import { ChangelogModal } from './ChangelogModal';

export const ChangelogButton = memo(() => {
  const [isChangelogModalOpen, setIsChangelogModalOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsChangelogModalOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsChangelogModalOpen(false);
  }, []);

  return (
    <>
      <DigTooltip title={i18n.t('assistant_changelog')}>
        <div
          className={styles.ChangelogButtonRow}
          onClick={handleClick}
          onKeyDown={onKeyDownCommitFn(handleClick)}
          tabIndex={0}
          role="button"
        >
          <div className={styles.ChangelogButtonContainer}>
            <div className={styles.ChangelogButtonIcon}>
              <UIIcon src={InfoLine} size="small" />
            </div>
            <Text
              size="medium"
              tagName="div"
              className={styles.ChangelogButtonTitle}
              color="inherit"
            >
              {i18n.t('compose_assistant_title') + ` v${changelogJson.version}`}
            </Text>
          </div>
        </div>
      </DigTooltip>
      {isChangelogModalOpen && (
        <ChangelogModal
          changelogJson={changelogJson}
          onRequestClose={handleClose}
        />
      )}
    </>
  );
});
ChangelogButton.displayName = 'ChangelogButton';
