/**
 * Creates an async function which will combine all incoming calls into one
 * and return the result of the async operation. i.e. this is about the
 * opposite of lodash's debounce, but not exactly.
 */
// `any` is necessary for overloaded functions.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncFunction = (...args: any[]) => Promise<any>;
export function getCombineAsyncRequestsFunc<T extends AsyncFunction>(
  func: T,
): T {
  // Store all pending promises keyed by the `args` json.
  const pendingPromiseMap = new Map<string, Promise<ReturnType<T>>>();

  // When there are no args given, the value of `args` is [].
  async function combined(...args: unknown[]) {
    let cleanUp = false;
    const key = JSON.stringify(args);
    let promise = pendingPromiseMap.get(key);

    if (!promise) {
      promise = func(...args);
      pendingPromiseMap.set(key, promise);
      cleanUp = true;
    }

    try {
      return await promise;
    } finally {
      if (cleanUp) {
        pendingPromiseMap.delete(key);
      }
    }
  }

  return combined as T;
}
