import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { Service } from '@mirage/service-feedback/service';
import { firstValueFrom } from 'rxjs';

import type { SurveyAttributes, SurveyEvent } from './types';

const service = services.get<Service>(ServiceId.FEEDBACK);

export async function updateAttributes(
  newAttributes: SurveyAttributes,
): Promise<void> {
  await firstValueFrom(service.updateAttributes(newAttributes));
}

export async function publishEvent(
  event: SurveyEvent,
  eventAttributes?: SurveyAttributes,
): Promise<void> {
  await firstValueFrom(service.publishEvent(event, eventAttributes));
}

export function startReportSprigAttributesJob(): Promise<void> {
  return firstValueFrom(service.startReportSprigAttributesJob());
}

export function cancelReportSprigAttributesJob(): Promise<void> {
  return firstValueFrom(service.cancelReportSprigAttributesJob());
}

// Observable that emits whenever a survey is presented
export const listenForSurveyPresented = service.listenForSurveyPresented;
// Observable that emits whenever a survey is closed
export const listenForSurveyClosed = service.listenForSurveyClosed;

// These are only meant to be consumed by the sprig client, do not use them directly
// Emits a sprig publish event to be published by the sprig client
export const _listenForSprigPublishEvent = service._listenForSprigPublishEvent;
// Emits sprig attributes to be published by the sprig client
export const _listenForSprigAttributes = service._listenForSprigAttributes;
// Sprig client should call this whenever a survey is presented, this is forwarded to listenForSurveyPresented
export function _publishSurveyPresented(): Promise<void> {
  return firstValueFrom(service._publishSurveyPresented());
}
// Sprig client should call this whenever a survey is closed, this is forwarded to listenForSurveyClosed
export function _publishSurveyClosed(): Promise<void> {
  return firstValueFrom(service._publishSurveyClosed());
}
