import { Skeleton } from '@dropbox/dig-components/skeleton';
import { Box, Stack } from '@dropbox/dig-foundations';

const SingleSkeleton = () => (
  <div>
    <Box display="flex">
      <Box marginTop="4" marginRight="16">
        <Skeleton.Avatar size="large" />
      </Box>
      <Box width="stretch">
        <Stack>
          <Skeleton.Text width={330} />
          <Skeleton.Text width={230} />
        </Stack>
      </Box>
    </Box>
  </div>
);

export const Skeletons = ({ length }: { length: number }) => (
  <Stack display="flex" gap="16" data-testid={`${length}-skeletons`}>
    {Array.from({ length }, (_, i) => (
      <SingleSkeleton key={i} />
    ))}
  </Stack>
);
