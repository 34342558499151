import { Modal } from '@dropbox/dig-components/modal';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { VoicesPane } from '@mirage/mosaics/ComposeAssistant/components/voices/VoicesPane';
import { DashThemeProvider } from '@mirage/service-settings/theming/DashThemeProvider';
import { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import { memo } from 'react';
import styles from './VoiceSettingsModal.module.css';

interface VoiceSettingsModalProps {
  voices: ComposeVoice[];
  saveVoice: (voice: ComposeVoice) => Promise<void>;
  onDeleteVoice: (voiceId: string) => void;
  initialVoiceID?: string;
  onRequestClose: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
}
export const VoiceSettingsModal = memo(
  ({
    voices,
    saveVoice,
    onDeleteVoice,
    initialVoiceID,
    onRequestClose,
    logComposeEvent,
  }: VoiceSettingsModalProps) => {
    return (
      <Modal
        open
        width={692}
        isCentered
        withCloseButton="close"
        className={styles.ModalWindow}
        onRequestClose={onRequestClose}
      >
        <DashThemeProvider>
          <Modal.Body className={styles.SettingsModalBody}>
            <div className={styles.SettingsModalContent}>
              <VoicesPane
                voices={voices}
                saveVoice={saveVoice}
                onDeleteVoice={onDeleteVoice}
                initialVoiceID={initialVoiceID}
                onDone={onRequestClose}
                logComposeEvent={logComposeEvent}
              />
            </div>
          </Modal.Body>
        </DashThemeProvider>
      </Modal>
    );
  },
);
VoiceSettingsModal.displayName = 'VoiceSettingsModal';
