/**
 * Makes a nice exponential decay score based on the number of days
 */
export const exponentialDecayScore = (
  days: number,
  weights: {
    maxScore: number;
    minScore: number;
    exp: number;
  },
) => {
  return (
    weights.maxScore * Math.exp(weights.exp * Math.abs(days)) + weights.minScore
  );
};
