import { sleepMs } from './tiny-utils';

/** Minimalistic exponential backoff helper for retries. */
export class Backoff {
  public millis = 0;

  public constructor(
    private readonly initialDelayMs = 1000, // 1 second
    private readonly maxDelayMillis = 300_000, // 5 minutes
  ) {
    this.reset();
  }

  public stepUp(): void {
    this.millis = Math.min(
      Math.floor(this.millis * (1.5 + Math.random())),
      this.maxDelayMillis,
    );
  }

  /** Please make sure to call this with `await`. */
  public async sleep() {
    this.stepUp();
    await sleepMs(this.millis);
  }

  public reset(): void {
    this.millis = this.initialDelayMs;
  }

  public toString(): string {
    return `${(this.millis / 1000).toLocaleString(undefined, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    })}s`;
  }
}
