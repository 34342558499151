import classnames from 'classnames';
import React from 'react';
import styles from './Blur.module.css';

interface BlurProps {
  isBlurred: boolean;
  children: React.ReactNode;
  blurAmount?: 'low' | 'medium' | 'high';
  className?: string;
}

export const Blur: React.FC<BlurProps> = ({
  isBlurred,
  children,
  blurAmount = 'medium',
  className,
}) => {
  return (
    <div
      className={classnames(styles.blurContainer, className, {
        [styles[`blurred-${blurAmount}`]]: isBlurred,
        [styles.noSelect]: isBlurred,
      })}
    >
      {children}
      {isBlurred && <div className={styles.interactionBlocker} />}
    </div>
  );
};
