import type { dash_connectors } from '@dropbox/api-v2-client';

/*
 * Filter out connectors that are not enabled by the user's admin.
 * This will be set to true for all non-team users.
 */
export function isConnectorVisible(
  connector: dash_connectors.Connector,
): boolean {
  return Boolean(connector.details?.supports_user_auth);
}
