// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgSuperhuman({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={12} cy={12} r={10} fill="#0B0B0B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.986 6.076a.365.365 0 01.445 0l4.853 3.771a.338.338 0 01.133.267.338.338 0 01-.133.267l-2.057 1.599 2.06 1.641c.083.066.13.164.13.267-.001.103-.05.2-.133.265l-4.853 3.771a.365.365 0 01-.445 0l-4.854-3.771A.339.339 0 017 13.886c0-.104.049-.202.132-.267L9.215 12l-2.083-1.619A.339.339 0 017 10.114c0-.104.049-.202.132-.267l4.854-3.771zM9.782 12.44l-1.86 1.446 4.286 3.331 3.846-2.988h-3.846a.363.363 0 01-.222-.076L9.782 12.44zm6.291 1.103h-2.857l1.446-1.124 1.411 1.124zm-3.865-.097l-4.287-3.332 4.287-3.331 4.287 3.331-4.287 3.332z"
        fill="url(#superhuman_svg__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="superhuman_svg__paint0_linear"
          x1={17.653}
          y1={11.714}
          x2={7.237}
          y2={11.714}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56C8E2" />
          <stop offset={0.5} stopColor="#C1C1E1" />
          <stop offset={1} stopColor="#F9E6F0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgSuperhuman;
