import * as op from 'rxjs/operators';

import type { typeahead } from '@mirage/service-typeahead-search/service/types';

export default function latency(
  wrapping: typeahead.TaggedSearch,
): typeahead.TaggedSearch {
  return (...args) => {
    const t0 = performance.now();
    return wrapping(...args).pipe(
      op.map((result) => {
        return {
          ...result,
          metadata: {
            ...result.metadata,
            latency: performance.now() - t0,
          },
        } as typeahead.TaggedResult;
      }),
    );
  };
}
