// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1371
import type { PAPEventGeneric } from "../base/event";
import type { DashSurface } from "../enums/dash_surface";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashSearchResultType } from "../enums/dash_search_result_type";
import type { SearchResultSource } from "../enums/search_result_source";
import type { FeatureLine } from "../enums/feature_line";
import type { DashSurfaceBuild } from "../enums/dash_surface_build";
import type { TypeaheadResultType } from "../enums/typeahead_result_type";

// Event_name: 'copy.dash_search'
// Description: When a user copies a single dash search result. Not to be confused with copy.dash_search_results (https://pap.pp.dropbox.com/#/events/1462), which is when the user copies *all* search results.
// Owner: otc-growth
export type Copy_DashSearch = PAPEventGeneric<
  'dash',
  'copy',
  'dash_search',
  {
    // The contents of a search query
    queryString?: string;
    // What rank an item is in
    resultRank?: number;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash connector an event is associated with, e.g. Trello.
    dashConnectorId?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // Number of results returned to user
    resultCount?: number;
    // The type of a Dash search result
    dashSearchResultType?: DashSearchResultType;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // Distinguishes between various data sources when performing a search in Dash
    searchResultSource?: SearchResultSource;
    // The ID of the Dash session that was in progress when the result was shown. This is a randomly generated UUID string.
    dashSessionId?: string;
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // The ID of the Search Attempt that was in progress while the result was shown. This is a randomly-generated UUID string.
    searchAttemptId?: string;
    // Unique identifier (UUIDv5 ) of the search result
    resultUuid?: string;
    // unique id for a /dash/search query. Used by the ML team to get feedback on search result ranking
    searchRequestId?: string;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // Identifier for a search query
    // Identifier is a v4 uuid that is generated on the fly during a search.
    searchQueryUuid?: string;
    // Type of result being acted upon within typeahead field
    typeaheadResultType?: TypeaheadResultType;
    // Set to true when the search option is selected from typeahead search
    isTypeahead?: boolean;
    // The zero-index returned position of search result in a list of search results with the SERP CTA removed from the list
    resultPositionNoCta?: number;
    // In Dash typeahead, the unweighted score of the Title Match Score scoring component
    typeaheadScoringTitleMatchScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the Title Match Score scoring component
    typeaheadScoringTitleMatchScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the Title Match Score scoring component
    typeaheadScoringTitleMatchScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the Title Match Score scoring component
    typeaheadScoringTitleMatchScoreAppliedWeight?: number;
    // In Dash typeahead, the unweighted score of the Last Clicked Score scoring component
    typeaheadScoringLastClickedScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the Last Clicked Score scoring component
    typeaheadScoringLastClickedScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the Last Clicked Score scoring component
    typeaheadScoringLastClickedScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the Last Clicked Score scoring component
    typeaheadScoringLastClickedScoreAppliedWeight?: number;
    // In Dash typeahead, the unweighted score of the Frequently Clicked Score scoring component
    typeaheadScoringFrequentlyClickedScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the Frequently Clicked Score scoring component
    typeaheadScoringFrequentlyClickedScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the Frequently Clicked Score scoring component
    typeaheadScoringFrequentlyClickedScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the Frequently Clicked Score scoring component
    typeaheadScoringFrequentlyClickedScoreAppliedWeight?: number;
    // In Dash typeahead, the unweighted score of the Last Browser Viewed Score scoring component
    typeaheadScoringLastBrowserViewedScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the Last Browser Viewed Score scoring component
    typeaheadScoringLastBrowserViewedScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the Last Browser Viewed Score scoring component
    typeaheadScoringLastBrowserViewedScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the Last Browser Viewed Score scoring component
    typeaheadScoringLastBrowserViewedScoreAppliedWeight?: number;
    // In Dash typeahead, the unweighted score of the Frequently Browser Viewed Score scoring component
    typeaheadScoringFrequentlyBrowserViewedScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the Frequently Browser Viewed Score scoring component
    typeaheadScoringFrequentlyBrowserViewedScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the Frequently Browser Viewed Score scoring component
    typeaheadScoringFrequentlyBrowserViewedScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the Frequently Browser Viewed Score scoring component
    typeaheadScoringFrequentlyBrowserViewedScoreAppliedWeight?: number;
    // In Dash typeahead, the unweighted score of the File Type Score scoring component
    typeaheadScoringFileTypeScoreUnweightedScore?: number;
    // In Dash typeahead, the weighted score of the File Type Score scoring component
    typeaheadScoringFileTypeScoreWeightedScore?: number;
    // In Dash typeahead, the configured weight of the File Type Score scoring component
    typeaheadScoringFileTypeScoreConfiguredWeight?: number;
    // In Dash typeahead, the applied weight of the File Type Score scoring component
    typeaheadScoringFileTypeScoreAppliedWeight?: number;
    // Whether or not the result in typeahead was "pinned" to a specific position (not necessarily p1)
    typeaheadScoringPinned?: boolean;
  }
>;

export function PAP_Copy_DashSearch(properties?: Copy_DashSearch['properties']): Copy_DashSearch {
  return <Copy_DashSearch>{
    class: 'dash',
    action: 'copy',
    object: 'dash_search',
    properties,
  };
}
