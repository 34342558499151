import { dash_feed } from '@dropbox/api-v2-client/types';
import { DashActivityAction } from '@mirage/analytics/events/enums/dash_activity_action';
import { DashObjectType } from '@mirage/analytics/events/enums/dash_object_type';

type CommonActivityProps = {
  dashActivityAction?: DashActivityAction;
  dashObjectType?: DashObjectType;
  dashObjectId?: string;
  actorUserId?: number;
  dashConnectorId?: string;
  itemPosition?: number;
};

export const activityDerivePAPProps = (
  activity?: dash_feed.ActivityItem,
  position?: number,
): CommonActivityProps => {
  if (!activity) {
    return {};
  }

  return {
    dashActivityAction: toAction(activity),
    dashObjectType: toObjectType(activity),
    dashObjectId: toObjectId(activity),
    actorUserId: toActorUserId(activity),
    dashConnectorId: toConnectorId(activity),
    itemPosition: position,
  };
};

const toAction = (activity: dash_feed.ActivityItem) => {
  return activity.action?.object_action?.['.tag'] || 'other';
};

const toObjectType = (activity: dash_feed.ActivityItem) => {
  const type = activity.object?.details?.object_details?.['.tag'];
  switch (type) {
    case 'full_stack':
      return 'stack';
    default:
      return type;
  }
};

const toObjectId = (activity: dash_feed.ActivityItem) => {
  const type = activity.object?.details?.object_details?.['.tag'];
  switch (type) {
    case 'full_stack':
      return activity.object?.details?.object_details?.namespace_id;
    case 'document':
      return activity.object?.details?.object_details?.uuid;
  }
};

const toActorUserId = (activity: dash_feed.ActivityItem) => {
  return activity.actor?.user_id;
};

const toConnectorId = (activity: dash_feed.ActivityItem) => {
  const type = activity.object?.details?.object_details?.['.tag'];
  switch (type) {
    case 'document':
      return activity.object?.details?.object_details?.connector_type?.['.tag'];
  }
};
