import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type {
  GrowthContinuousOnboardingType,
  OnboardingChecklistItem,
  Service,
} from '@mirage/service-onboarding-checklist/service';

export const service = services.get<Service>(ServiceId.ONBOARDING_CHECKLIST);

export function markChecklistItemComplete(
  milestoneIds: string[],
): Promise<boolean> {
  return rx.firstValueFrom(service.markChecklistItemComplete(milestoneIds));
}

export function getOnboardingChecklist(
  surface: GrowthContinuousOnboardingType,
): Promise<OnboardingChecklistItem[] | undefined> {
  return rx.firstValueFrom(service.getOnboardingChecklist(surface));
}
