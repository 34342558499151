import { useWindowSize } from '@react-hookz/web';
import { BreakpointClass, useBreakpointClass } from './breakpoints';

export function useIsMobileSize() {
  return useBreakpointClass() === BreakpointClass.XSMALL;
}

// For use by sidebar and sidebar-related components only.
export function useIsMobileSizeForSidebar() {
  return useWindowSize().width < 800;
}
