import { dash_feed, stacks } from '@dropbox/api-v2-client';
import { Text, TextProps } from '@dropbox/dig-components/typography';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { PAP_View_DashStack } from '@mirage/analytics/events/types/view_dash_stack';
import { Link } from '@mirage/link/Link';
import { activityDerivePAPProps } from '@mirage/service-feed/util/analytics';
import { stackDerivePAPProps } from '@mirage/service-stacks';

type ActivityLinkMetadata = {
  stack?: stacks.Stack;
  activity?: dash_feed.ActivityItem;
  position?: number;
};

type ActivityLinkProps = {
  href: string;
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  color?: TextProps<any>['color'];
  isBold?: boolean;
  metadata?: ActivityLinkMetadata;
};

// A link that handles opening URLs safely in the activity feed
export const ActivityLink = ({
  href,
  children,
  isBold = true,
  color = 'inherit',
  metadata,
}: ActivityLinkProps) => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  return (
    <Text color={color} isBold={isBold}>
      <Link
        variant="monochromatic"
        href={href}
        onOpen={() => {
          reportPapEvent(
            PAP_Open_DashLink({
              ...activityDerivePAPProps(metadata?.activity, metadata?.position),
              actionSurfaceComponent: 'activity_feed',
              featureLine: 'activity_feed',
            }),
          );

          if (metadata?.stack) {
            reportPapEvent(
              PAP_View_DashStack({
                ...stackDerivePAPProps(metadata.stack),
                itemPosition: metadata.position,
                actionSurfaceComponent: 'activity_feed',
                featureLine: 'stacks',
              }),
            );
          }
        }}
      >
        {children}
      </Link>
    </Text>
  );
};
