import { dash_connectors } from '@dropbox/api-v2-client';
import { tagged } from '@mirage/service-logging';
import {
  useRemoveConnection,
  useSetConnection,
} from '@mirage/shared/atoms/connections';
import { showSnackbar } from '@mirage/shared/snackbar';
import { UIConnection } from '@mirage/shared/types';
import i18n from '@mirage/translations';
import * as connectorMetadataService from '../../utils/connectorMetadataService';

const logger = tagged('useDisconnectConnection');

export const useDisconnectConnection = () => {
  const setConnection = useSetConnection();
  const removeConnection = useRemoveConnection();

  return (connection: UIConnection) => {
    return disconnectConnection(connection, setConnection, removeConnection);
  };
};

export async function disconnectConnection(
  connection: UIConnection,
  setConnection: ReturnType<typeof useSetConnection>,
  removeConnection: ReturnType<typeof useRemoveConnection>,
  maxRetries = 3,
): Promise<{ success: boolean }> {
  const id = connection.id_attributes?.id;
  const platform = connection.id_attributes?.connector?.platform;
  const type = connection.id_attributes?.connector?.type;

  try {
    if (!id || !platform || !type) {
      throw new Error(
        `Missing id, platform, or type: ${id}, ${platform}, ${type}`,
      );
    }

    setConnection(id, { ...connection, loading: true });
    await removeConnectionWithRetry(id, platform, type, maxRetries);
    setConnection(id, { ...connection, loading: false });

    removeConnection(id);
    showSnackbar({
      title: i18n.t('connectors_settings_disconnected_from_service', {
        appName: connection.branding?.display_name,
      }),
    });
    return { success: true };
  } catch (e) {
    logger.error(e);
    if (id) setConnection(id, { ...connection, loading: false });
    showSnackbar({
      title: i18n.t('connectors_settings_error_disconnecting', {
        appName: connection.branding?.display_name,
      }),
    });
    return { success: false };
  }
}

export const removeConnectionWithRetry = async (
  connectionId: string,
  platform: dash_connectors.ConnectorPlatform,
  type: string,
  maxRetries = 3,
) => {
  for (let attempt = 0; attempt <= maxRetries; attempt++) {
    try {
      await connectorMetadataService.removeConnection(
        connectionId,
        platform,
        type,
      );
      break;
    } catch (e) {
      if (attempt == maxRetries) {
        throw e;
      }
      // Exponential backoff: 1s, 3s, 7s...
      const timeout = 1000 * (Math.pow(2, attempt + 1) - 1);
      await new Promise<void>((resolve) => setTimeout(resolve, timeout));
      // Notify the user that the operation is taking longer than expected
      showSnackbar({ title: i18n.t('connector_settings_delay_notice') });
    }
  }
};
