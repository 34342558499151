import { transformDashResultToMirage } from '@mirage/service-dbx-api/service/utils';
import { CALENDAR_CONNECTORS } from '@mirage/shared/connectors/entityTypes';
import {
  getContentTypeFilters,
  getPersonFilter,
  isConnectorFilter,
  isLastUpdatedFilter,
  LastUpdatedFilter,
  SearchFilter,
} from '@mirage/shared/search/search-filters';

import type { APIv2CallableWithHeaders, Connection } from '..';
import type { dash } from '@dropbox/api-v2-client';
import type { SearchResult } from '@mirage/service-dbx-api/service/search';

export async function upstreamForConnection(
  api: APIv2CallableWithHeaders,
  query: string,
  connection: Connection,
  filters: SearchFilter[] = [],
): Promise<SearchResult[]> {
  if (!connection?.id_attributes?.id) {
    return [];
  }

  const hasContentTypeFilters = getContentTypeFilters(filters).length > 0;
  const hasPersonFilter = getPersonFilter(filters) !== undefined;

  // TEMPORARY:
  // Until intel supports upstream search for content type / people filter
  if (hasContentTypeFilters || hasPersonFilter) {
    return [];
  }

  const tfilters: Array<dash.QueryFilter> = filters
    .filter((filter): filter is LastUpdatedFilter =>
      isLastUpdatedFilter(filter),
    )
    .map(({ parameters }) => ({
      filter: {
        '.tag': 'time_range_filter',
        start_datetime: new Date(parameters.start).toISOString(),
        end_datetime: new Date(parameters.end).toISOString(),
      },
    }));

  const response = await api('dcsUpstream', {
    query,
    connection_id: connection?.id_attributes?.id,
    filters: tfilters,
  });

  const { headers, result } = response;

  if (result.results === undefined) {
    return [];
  }
  const analyticsTraceId = headers.get('x-dropbox-request-id') || '';

  const mirageSearchResults = (result.results || [])
    .map((dsr) => transformDashResultToMirage(dsr, undefined, analyticsTraceId))
    .filter((r): r is SearchResult => Boolean(r));

  // HACK: If user doesnt have calendar app filter enabled, we should filter out events.
  // This is a temporary client-side solution to meet August 2024 deadline.
  // For october 2024 and beyond, search relevance will instead downrank events
  // https://jira.dropboxer.net/browse/OTCKA-461
  const hasCalendarAppFilter = filters.some(
    (filter) =>
      isConnectorFilter(filter) && CALENDAR_CONNECTORS.includes(filter.id),
  );
  if (!hasCalendarAppFilter) {
    return mirageSearchResults.filter(
      (result) => result.recordType['.tag'] !== 'event',
    );
  }

  return mirageSearchResults;
}
