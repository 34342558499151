export const ONE_SECOND_IN_MILLIS = 1_000;
export const ONE_MINUTE_IN_MILLIS = 60_000;
export const ONE_HOUR_IN_MILLIS = 3_600_000;
export const ONE_HOUR_IN_MINUTES = 60;
export const ONE_DAY_IN_MILLIS = 86_400_000;
export const ONE_DAY_IN_MINUTES = 1440;

export const ONE_DAY_IN_SECONDS = 86_400;

export const EMOJI_CLEARED_SENTINEL_VALUE = 'emoji_cleared';

export const SHADOW_DOM_TAG_NAME = 'save-to-dropbox-container';

// Stacks title max input length
export const MAX_INPUT_LENGTH = 180;
export const MAX_STACK_NAME_LENGTH = 180;
export const MAX_STACK_DESCRIPTION_LENGTH = 4000;
export const MAX_STACK_ITEM_URL_LENGTH = 2000;

export const MAX_STACK_SECTION_COUNT = 30;
export const MAX_STACK_SECTION_TITLE_LENGTH = 50;

export const MAX_STACK_ITEM_DESCRIPTION_LENGTH = 4000;

export enum KeyCodes {
  arrowUp = 'ArrowUp',
  arrowDown = 'ArrowDown',
  arrowLeft = 'ArrowLeft',
  arrowRight = 'ArrowRight',
  escape = 'Escape',
  enter = 'Enter',
  tab = 'Tab',
  space = ' ',
  backspace = 'Backspace',
  p = 'p',
  n = 'n',
}

export const MIRAGE_PORT_NAME = 'mirage';
