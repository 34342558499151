import { PopulatedComponentConfig } from './types';

/**
 * Default values for the Mirage configuration.
 * Each App can override these values by providing a different configuration to
 * the `ComponentConfig` component.
 */
export const defaultComponentConfig: PopulatedComponentConfig = {
  includeDragbar: true,
  analyticsLogsOpen: false,
  toggleAnalyticsLogs: () => {},
  includeAIPanel: false,
  showGenericExtensionUpsell: false,
  homeOnEscape: false,
};
