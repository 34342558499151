import { GuaranteedKVStorage, KVStorage } from '@mirage/storage';
import pqueue from 'p-queue';

export default class WithDefaults<Shape extends object>
  implements GuaranteedKVStorage<Shape>
{
  storage: KVStorage<Shape>;
  queue: pqueue = new pqueue({ concurrency: 1 });

  constructor(storage: KVStorage<Shape>, defaults: Shape) {
    this.storage = storage;
    this.queue.add(async () => {
      for (const [key, value] of Object.entries(defaults)) {
        // early abort if this key has been set
        const existingValue = await this.storage.get(key as keyof Shape);
        if (existingValue !== undefined) continue;

        await this.storage.set(
          key as keyof Shape,
          value as unknown as Shape[keyof Shape],
        );
      }
    });
  }

  async get<K extends keyof Shape>(key: K): Promise<Shape[K]> {
    return this.queue.add(() => this.storage.get(key)) as Promise<Shape[K]>;
  }

  set<K extends keyof Shape>(key: K, value: Shape[K]): Promise<void> {
    return this.queue.add(() => this.storage.set(key, value));
  }

  async getAll(): Promise<Shape> {
    return this.queue.add(() => this.storage.getAll()) as Promise<Shape>;
  }

  async clear(): Promise<void> {
    await this.queue.add(() => this.storage.clear());
  }
}
