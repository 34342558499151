// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgGoogleSheets({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 7v13.712c0 .711-.565 1.288-1.26 1.288H5.76c-.696 0-1.26-.577-1.26-1.288V3.288C4.5 2.577 5.064 2 5.76 2h8.74l5 5z"
        fill="#00AC47"
      />
      <path
        d="M14.95 10.333h-5.9a.381.381 0 00-.383.38v5.075c0 .21.171.379.383.379h5.9c.212 0 .383-.17.383-.379v-5.076a.381.381 0 00-.383-.379zm-5.517 3.554h2.184v1.377H9.433v-1.377zm2.95 0h2.184v1.377h-2.184v-1.377zm2.184-1.073h-2.184v-1.533h2.184v1.533zm-2.95-1.533v1.533H9.433v-1.533h2.184z"
        fill="#F1F1F1"
      />
      <path d="M19.5 7h-5V2l5 5z" fill="#00832D" />
    </svg>
  );
}

export default SvgGoogleSheets;
