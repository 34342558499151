import { PersonObject } from '../search/search-filters';

import type { users } from '@dropbox/api-v2-client';

export const enum IsDropboxerMode {
  WorkAccountOnly,
  WorkOrPersonalAccount,
}

export function getAbbreviatedFullName(givenName?: string, surname?: string) {
  if (givenName) {
    if (surname) {
      return `${givenName} ${surname.charAt(0).toLocaleUpperCase()}.`;
    } else {
      return givenName;
    }
  }
  return '';
}

export function getInitials(givenName?: string, surname?: string) {
  return `${givenName ? givenName.charAt(0).toLocaleUpperCase() : ''}${
    surname ? surname.charAt(0).toLocaleUpperCase() : ''
  }`;
}

export function getInitialsFromDisplayName(displayName: string): string {
  const names = displayName
    .trim()
    .split(/\s+/)
    .map((name) => name.replace(/[^\p{L}]/gu, ''));

  if (names.length === 0) {
    return '';
  }

  // Get the first letter of the first word
  const firstInitial = names[0][0] || '';

  // Get the first letter of the last word, only if there is more than one word
  const lastInitial = names.length > 1 ? names[names.length - 1][0] : '';

  // Combine and convert to uppercase
  return (firstInitial + lastInitial).toUpperCase();
}

export function getInitialsFromPerson(person?: PersonObject): string {
  // If display name exists, grab initials from that
  // Otherwise, grab the first letter of the email
  return (
    getInitialsFromDisplayName(person?.displayName || '') ||
    person?.email?.charAt(0)?.toUpperCase() ||
    ''
  );
}

export function getPersonTitle(person?: PersonObject) {
  return person?.displayName || person?.email || '';
}

export function isDropboxerEmail(email: string) {
  return Boolean(email.endsWith('@dropbox.com'));
}

function isTestAccountEmail(email: string) {
  return Boolean(email.endsWith('@dbx51.com'));
}

export function isDropboxerAccount(
  account: users.FullAccount | undefined,
  mode = IsDropboxerMode.WorkOrPersonalAccount,
) {
  if (!account) {
    return undefined;
  }

  // Dropboxer's personal account is true when mode=WorkOrPersonalAccount.
  if (
    mode === IsDropboxerMode.WorkOrPersonalAccount &&
    account.team?.id === 'dbtid:AAClREzwomnhuCpPK6axEJMQInDiteg4OSg'
  ) {
    return true;
  }

  return isDropboxerEmail(account.email);
}

export function isTestAccount(account: users.FullAccount | undefined) {
  if (!account) {
    return undefined;
  }

  return isTestAccountEmail(account.email);
}
