const jsStart = performance.now();

import * as React from "react";
// eslint-disable-next-line react/no-deprecated
import { render } from "react-dom";

import { Modal } from "@dropbox/dig-components/modal";
import { registerSpinnerEscapeFunction } from "@mirage/mosaics/FullPageSpinner/useReportingIfVisibleForTooLong";
import { namespace } from "@mirage/service-operational-metrics";
import { logPageLoadMilestone } from "@mirage/service-operational-metrics/page-load";
import { nonNil } from "@mirage/shared/util/tiny-utils";
import { endAllSessions } from "@mirage/webapp/analytics/sessionManagers/sessionManagersCallbacks";
import * as sentry from "@mirage/webapp/helpers/sentry";
import { reportWebVitals } from "@mirage/webapp/performance/reportWebVitals";
import { webVitalsVortexReporter } from "@mirage/webapp/performance/vortexMetrics";

import { App } from "./App";
import { startMirageServices } from "./Services";
import { initMeticulous } from "./meticulous/meticulous";
import { checkAuthCookieAndRedirect } from "./shared/webCookies";

(async () => {
  await initMeticulous();

  // Used to track effect of bundle size on page load speed.
  logPageLoadMilestone("JS start", jsStart);
  checkAuthCookieAndRedirect();

  const { stats } = namespace("page_latency");
  stats("js_start", jsStart);

  const { logoutServiceImpl } = await startMirageServices();

  // Init Sentry
  sentry.start();

  const root = nonNil(document.getElementById("root"), "#root");

  Modal.setAppElement(root);

  window.addEventListener("beforeunload", endAllSessions);

  render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    root,
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(DebugLogger.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals(webVitalsVortexReporter);

  registerSpinnerEscapeFunction(logoutServiceImpl.logout);
})();
