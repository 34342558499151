import { Text } from '@dropbox/dig-components/typography';
import { useDashTitle } from '@mirage/shared/hooks/DashTitle';
import i18n from '@mirage/translations';
import styles from './NotFound.module.css';

export const NotFound = () => {
  useDashTitle(i18n.t('not_found_page_title'));

  return (
    <div className={styles.container}>
      <Text size="large" className={styles.text}>
        {i18n.t('page_not_found')}
      </Text>
    </div>
  );
};
