// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2554
import type { PAPEventGeneric } from '../base/event';

// Event_name: 'cancel.stacks_name'
// Description: Fires when a user clicks the Cancel button to cancel editing the stacks name
// Owner: dash-web
export type Cancel_StacksName = PAPEventGeneric<
  'stacks',
  'cancel',
  'stacks_name',
  {
    // The stack id that represents the stack
    stackId?: string;
    // marks if the content is shared content
    isShared?: boolean;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
  }
>;

export function PAP_Cancel_StacksName(
  properties?: Cancel_StacksName['properties'],
): Cancel_StacksName {
  return <Cancel_StacksName>{
    class: 'stacks',
    action: 'cancel',
    object: 'stacks_name',
    properties,
  };
}
