export function extractPaperFilenameFromPath(path: string): string {
  if (!path.match('^/(?:[^/]+/)*([^/]+)\\.paper$')) {
    throw new Error(`Path ${path} does not match paper path format.`);
  }
  const lastSlashIdx = path.lastIndexOf('/');
  const paperIdx = path.indexOf('.paper');
  return path.substring(lastSlashIdx + 1, paperIdx);
}

export function fullPaperPath(path: string, filename: string): string {
  return path + '/' + filename + '.paper';
}
