// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/2942
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import type { StackAccessLevel } from '../enums/stack_access_level';
import type { StackPermissions } from '../enums/stack_permissions';
import type { StackSharedUserPermissionType } from '../enums/stack_shared_user_permission_type';

// Event_name: 'change.stack_user_permissions'
// Description: Fires when a user changes the sharing permissions on a stack
// Owner: otc
export type Change_StackUserPermissions = PAPEventGeneric<
  'stacks',
  'change',
  'stack_user_permissions',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // The stack id that represents the stack
    stackId?: string;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The permissions of a user on a stack
    stackPermissions?: StackPermissions;
    // The access level settings for a stack
    stackAccessLevel?: StackAccessLevel;
    // marks if the content is shared content
    isShared?: boolean;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // True if public sharing is allowed, false otherwise
    isPublicSharingAllowed?: boolean;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // unique session id for creating stacks on dash
    createStackSessionId?: string;
    // The user permission type on a stack
    stackSharedUserPermissionType?: StackSharedUserPermissionType;
  }
>;

export function PAP_Change_StackUserPermissions(
  properties?: Change_StackUserPermissions['properties'],
): Change_StackUserPermissions {
  return <Change_StackUserPermissions>{
    class: 'stacks',
    action: 'change',
    object: 'stack_user_permissions',
    properties,
  };
}
