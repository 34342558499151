/* eslint @typescript-eslint/no-explicit-any: 0 */

import type { ServiceId } from '@mirage/discovery/id';

// message typing for node-to-node communication
//------------------------------------------------------------------------------
enum MessageEnum {
  // state management
  Identity = 'identity', // identify self to neighbors and what we know of the world
  AddService = 'add-service', // update local state to associate service to node
  RemoveService = 'remove-service', // update local state to remove service from node
  AddNode = 'add-node', // adds node to local state
  RemoveNode = 'remove-node', // removes node from local state
  Request = 'request', // service coordination request
  Response = 'response', // service coordination response
  Relay = 'relay', // node-to-node messages
}

type $Message<T extends MessageEnum, P extends object | void> = {
  payload: P;
  type: T;
};

export function make<T extends MessageEnum, P extends object | void>(
  type: T,
  payload: P,
): $Message<T, P> {
  return {
    type,
    payload,
  };
}

//
// graph state management messages
//------------------------------------------------------------------------------
export type NodeMap = {
  [key: string]: {
    distance: number;
    services: ServiceId[];
  };
};

type IdentityPayload = {
  id: string;
  nodes: NodeMap;
};

type AddServicePayload = {
  id: string;
  service: ServiceId;
};

type RemoveServicePayload = {
  id: string;
  service: ServiceId;
};

type AddNodePayload = {
  id: string;
  distance: number;
};

type RemoveNodePayload = {
  id: string;
};

export type Identity = $Message<MessageEnum.Identity, IdentityPayload>;
export type AddNode = $Message<MessageEnum.AddNode, AddNodePayload>;
export type RemoveNode = $Message<MessageEnum.RemoveNode, RemoveNodePayload>;
export type AddService = $Message<MessageEnum.AddService, AddServicePayload>;
export type RemoveService = $Message<
  MessageEnum.RemoveService,
  RemoveServicePayload
>;

//
// node-to-node communications
//------------------------------------------------------------------------------
type RequestPayload = {
  id: string;
  source: string;
  destination: string;
  service: ServiceId;
};

type ResponsePayload = {
  id: string;
  source: string;
  destination: string;
  // using undefined as a sigil here if we were unable to get to the destination
  // or the destination does not provide us with that service
  context: string | undefined;
};

export enum OriginEnum {
  Service = 'service',
  Consumer = 'consumer',
}

type RelayPayload = {
  source: string;
  destination: string;
  payload: any; // XXX: should we leave this generic and let the sub-impl type?
  context: string;
  origin: OriginEnum;
};

export type Request = $Message<MessageEnum.Request, RequestPayload>;

export type Response = $Message<MessageEnum.Response, ResponsePayload>;

export type Relay = $Message<MessageEnum.Relay, RelayPayload>;

// amalgamation for general typing
export type Message =
  | AddNode
  | AddService
  | Identity
  | Relay
  | RemoveNode
  | RemoveService
  | Request
  | Response;

//
// message builders
//------------------------------------------------------------------------------
export const builder = {
  identity(id: string, nodes: NodeMap): Identity {
    return make(MessageEnum.Identity, { id, nodes });
  },
  addService(id: string, service: ServiceId): AddService {
    return make(MessageEnum.AddService, { id, service });
  },
  removeService(id: string, service: ServiceId): RemoveService {
    return make(MessageEnum.RemoveService, {
      id,
      service,
    });
  },
  addNode(id: string, distance: number): AddNode {
    return make(MessageEnum.AddNode, { id, distance });
  },
  removeNode(id: string): RemoveNode {
    return make(MessageEnum.RemoveNode, { id });
  },
  request(
    source: string,
    destination: string,
    id: string,
    service: ServiceId,
  ): Request {
    return make(MessageEnum.Request, { id, source, destination, service });
  },
  response(
    source: string,
    destination: string,
    id: string,
    context: string | undefined,
  ): Response {
    return make(MessageEnum.Response, { id, source, destination, context });
  },
  relay(
    source: string,
    destination: string,
    context: string,
    origin: OriginEnum,
    payload: any,
  ): Relay {
    return make(MessageEnum.Relay, {
      source,
      destination,
      context,
      origin,
      payload,
    });
  },
};

//
// type refinement helpers for isolating messages
//------------------------------------------------------------------------------
// XXX: pay attention here, type refinement is going to trust your boolean check
export const is = {
  identity(message: Message): message is Identity {
    return message.type === MessageEnum.Identity;
  },
  addService(message: Message): message is AddService {
    return message.type === MessageEnum.AddService;
  },
  removeService(message: Message): message is RemoveService {
    return message.type === MessageEnum.RemoveService;
  },
  addNode(message: Message): message is AddNode {
    return message.type === MessageEnum.AddNode;
  },
  removeNode(message: Message): message is RemoveNode {
    return message.type === MessageEnum.RemoveNode;
  },
  request(message: Message): message is Request {
    return message.type === MessageEnum.Request;
  },
  response(message: Message): message is Response {
    return message.type === MessageEnum.Response;
  },
  relay(message: Message): message is Relay {
    return message.type === MessageEnum.Relay;
  },
  // message sub-filtering
  context(id: string) {
    return ({ payload }: Relay): boolean => payload.context === id;
  },
  rid(id: string) {
    return ({ payload }: Response): boolean => payload.id === id;
  },
  norigin(origin: OriginEnum) {
    return ({ payload }: Relay): boolean => payload.origin !== origin;
  },
};

export const get = {
  payload({ payload }: Relay) {
    return payload.payload;
  },
};

export const wrap = {
  relay(
    source: string,
    destination: string,
    context: string,
    origin: OriginEnum,
  ) {
    return (payload: any) => {
      return builder.relay(source, destination, context, origin, payload);
    };
  },
};

type Predicate = (message: Message) => boolean;
export function and(...predicates: Predicate[]) {
  return (message: Message) => predicates.every((fn) => fn(message));
}

export function or(...predicates: Predicate[]) {
  return (message: Message) => predicates.some((fn) => fn(message));
}
