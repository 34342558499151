import * as React from "react";
import classNames from "classnames";
import { useTheme, atoms } from "@dropbox/dig-foundations";
import { AspectRatio } from "@dropbox/dig-components/aspect_ratio";
import * as Icons from "../../icons/async-manifest";
const css = atoms({ display: "block" });

export interface LazyArtworkIconProps
  extends React.AriaAttributes,
    React.SVGAttributes<SVGSVGElement> {
  /**
   * The name of the icon to display
   */
  icon: keyof typeof Icons;
  className?: string;
  style?: React.CSSProperties;
  children?: never;
}

/**
 * The `LazyArtworkIcon` component is simply a proxy to the underlying icon component.
 * It will automatically select the correct icon based on the current color mode detected in context.
 * This uses `React.lazy` to load the icon component on demand.
 *
 * Internally it will use an aspect ratio container to ensure the space is reserved for the icon while it loads.
 */
export const LazyArtworkIcon: React.FC<LazyArtworkIconProps> = ({
  icon = "Shortcut",
  className,
  ...rest
}) => {
  const { mode } = useTheme();
  /*eslint import/namespace: ['error', { allowComputed: true }]*/
  const ContentIconArtwork = Icons[icon][mode];

  return (
    <React.Suspense
      fallback={
        // While the icon loads make sure we reserve the space for it.
        // The icons are always square so we can use the aspect ratio component to reserve the space.
        <AspectRatio
          ratio={1}
          className={classNames(css, className)}
          style={{ width: rest.width }}
        >
          <span />
        </AspectRatio>
      }
    >
      <ContentIconArtwork className={classNames(className, css)} {...rest} />
    </React.Suspense>
  );
};
