/**
 * Need garbage-collection only if the jobs count exceed this threshold.
 * Set this to a high number as it is not worth bothering about cleaning up
 * memory when it is too small.
 */
const GC_SIZE_THRESHOLD = 1000;

let garbageCollectionTimer: ReturnType<typeof setInterval> | undefined;

/** List of flush functions to call when window unloads. */
const flushFuncWeakRefs: WeakRef<(flush?: boolean) => void>[] = [];

function garbageCollectWeakRefFlushes() {
  for (let i = flushFuncWeakRefs.length - 1; i >= 0; i--) {
    const ref = flushFuncWeakRefs[i];
    if (!ref.deref()) {
      flushFuncWeakRefs.splice(i, 1);
    }
  }

  updateGarbageCollectionJob();
}

/** Start or stop the GC job based on the current jobs count. */
function updateGarbageCollectionJob() {
  if (flushFuncWeakRefs.length >= GC_SIZE_THRESHOLD) {
    startGarbageCollectionJobIfNeeded();
  } else {
    stopGarbageCollectionJobIfNeeded();
  }
}

function startGarbageCollectionJobIfNeeded() {
  if (garbageCollectionTimer) return;

  garbageCollectionTimer = setInterval(
    () => garbageCollectWeakRefFlushes(),
    // No need to do GC often. But if user keeps the app open for 10 hours,
    // we do want to check for GC once in a while. This number should be high
    // enough to not affect app performance in any way.
    600_000, // 10 minutes
  );
}

function stopGarbageCollectionJobIfNeeded() {
  const timer = garbageCollectionTimer;
  if (!timer) return;
  garbageCollectionTimer = undefined;
  clearInterval(timer);
}

/**
 * Returns a function that automatically batches given tasks by a given time
 * interval, and flushes them per interval. e.g. if the interval is 1000ms,
 * any enqueued item will be flushed within around 1000ms after add.
 */
export function batchedIntervalFunc<T>(
  // The `flush` argument is passed to the underlying function to dictate
  // whether we need to flush immediately or just add to an async queue for
  // processing. If you don't need it, just ignore it.
  callback: (batch: T[], flush?: boolean) => void,
  intervalMs: number,
): (task: T, flush?: boolean) => void {
  let timer: ReturnType<typeof setTimeout> | undefined;
  const queue: T[] = [];

  function doFlush(flush?: boolean) {
    const batch = queue.splice(0);
    callback(batch, flush);
  }

  function addToQueue(task: T, flush?: boolean) {
    queue.push(task);

    if (flush) doFlush(flush);

    if (!timer) {
      timer = setTimeout(() => {
        timer = undefined;
        doFlush();
      }, intervalMs);
    }
  }

  flushFuncWeakRefs.push(new WeakRef((flush?: boolean) => doFlush(flush)));

  updateGarbageCollectionJob();

  return addToQueue;
}

function flushAll() {
  for (const ref of flushFuncWeakRefs) {
    const flush = ref.deref();
    flush?.(true);
  }
}

if (typeof window !== 'undefined' && typeof document !== 'undefined') {
  document.addEventListener('visibilitychange', () => flushAll());
}
