import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { useEffect } from 'react';

const loggedImpressions = new Set<string>();
export const resetImpressionLogging = () => {
  loggedImpressions.clear();
};

/**
 * Logs an impressions via PAP if `impression` is true. Will only log once per unique `id` value per page load.
 *
 * @param impression true if an impression should be logged
 * @param id unique identifier to avoid duplicate logging.
 * @param event PAP event that will be logged
 */
export const useImpressionLogging = (
  impression: boolean,
  id: string,
  event: PAPEvent,
) => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  useEffect(() => {
    if (impression && !loggedImpressions.has(id)) {
      loggedImpressions.add(id);
      reportPapEvent(event);
    }
  }, [impression, id, event, reportPapEvent]);
};
