import { IconButton, IconButtonProps } from '@dropbox/dig-components/buttons';
import { forwardRef, Ref } from 'react';
import {
  BaseButtonWithTooltip,
  CoreTooltipProps,
} from '../util/BaseButtonWithTooltip';

export type { CoreTooltipProps };

// Prevent "Warning: Function components cannot be given refs. Attempts to
// access this ref will fail. Did you mean to use React.forwardRef()?" by
// using `forwardRef`.
export const IconButtonWithTooltip = forwardRef(
  (
    {
      tooltipProps,
      ...props
    }: {
      tooltipProps: CoreTooltipProps;
    } & IconButtonProps,
    ref?: Ref<HTMLDivElement>,
  ) => {
    return (
      <BaseButtonWithTooltip
        Component={IconButton}
        ref={ref}
        {...props}
        tooltipProps={tooltipProps}
      />
    );
  },
);
IconButtonWithTooltip.displayName = 'IconButtonWithTooltip';
