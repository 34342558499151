import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { getIsDfbUser } from '@mirage/shared/eligibility';
import i18n from '@mirage/translations';
import * as React from 'react';
import WelcomeModal from './WelcomeModal';
import WelcomeModalStepper, { WelcomeModalItem } from './WelcomeModalStepper';

const ANSWERS_IMAGE =
  'https://assets.dropbox.com/images/dashweb/welcome-modal-answers.png';
const HOME_PAGE_IMAGE =
  'https://assets.dropbox.com/images/dashweb/welcome-modal-home-page.png';
const STACKS_IMAGE =
  'https://assets.dropbox.com/images/dashweb/welcome-modal-stacks.png';
const WELCOME_IMAGE =
  'https://assets.dropbox.com/images/dashweb/welcome-modal-welcome.png';

const INFO_STEPS: WelcomeModalItem[] = [
  {
    image: WELCOME_IMAGE,
    title: i18n.t('welcome_modal_title_step_0'),
    message: i18n.t('welcome_modal_message_step_0'),
  },
  {
    image: HOME_PAGE_IMAGE,
    title: i18n.t('welcome_modal_title_step_1'),
    message: i18n.t('welcome_modal_message_step_1'),
  },
  {
    image: ANSWERS_IMAGE,
    title: i18n.t('welcome_modal_title_step_2'),
    message: i18n.t('welcome_modal_message_step_2'),
  },
  {
    image: STACKS_IMAGE,
    title: i18n.t('welcome_modal_title_step_3'),
    message: i18n.t('welcome_modal_message_step_3'),
  },
];

// Always opens on its first render
// When dismissed it will never show again
export default function WebWelcomeModalV2({
  size,
}: {
  size: 'small' | 'standard';
}) {
  const [displaySunsetModal, setDisplaySunsetModal] = React.useState<
    boolean | undefined
  >(undefined);
  const sunsetModalEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_10_14_temporary_dfi_sunset_modal'),
  );

  React.useEffect(() => {
    const doSetSunsetModal = async () => {
      const isDfbUser = await getIsDfbUser();
      // Display the welcome modal for DfI users
      setDisplaySunsetModal(!isDfbUser);
    };

    doSetSunsetModal();
  }, []);

  // Don't show anything while data is loading to prevent modal flash
  if (displaySunsetModal === undefined) return null;

  return sunsetModalEnabled && displaySunsetModal ? (
    <WelcomeModal size={size} />
  ) : (
    <WelcomeModalStepper steps={INFO_STEPS} size={size} />
  );
}
