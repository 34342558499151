import { validate as validateUUID } from 'uuid';

import type { PAPEvent } from '@mirage/analytics/events/base/event';

const HACKY_CSV_AS_STRINGS_FIELDS = ['resultUuidList'];
export function findInvalidUUIDFields(event: PAPEvent): string[] {
  const invalidKeys: string[] = [];

  // we store id3p in resultUuid string for this event at ML request, id3p is sometimes not a valid uuid
  if (event.object === 'dash_answers_source_labels') return invalidKeys;

  for (const [key, value] of Object.entries(event.properties || {})) {
    // ignore fields that do not contain "uuid"
    if (!key.toLowerCase().includes('uuid')) continue;

    // ignore non-string fields or empty string fields since we allow optional
    // values and will fail a validateUUID check
    if (typeof value !== 'string' || !value) continue;

    // for non-hacky csv pap fields we can directly validate the value
    if (!HACKY_CSV_AS_STRINGS_FIELDS.includes(key) && validateUUID(value)) {
      continue;
    }

    // for instances where we have a concatenated list of uuids, split the list
    // and validate individual entries
    if (
      HACKY_CSV_AS_STRINGS_FIELDS.includes(key) &&
      value.split(',').every(validateUUID)
    ) {
      continue;
    }

    // since all of these early-exit we have some level of confidence this key
    // contains an invalid uuid and we should mark it as invalid
    invalidKeys.push(key);
  }

  return invalidKeys;
}
