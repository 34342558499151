import * as primitives from '@mirage/service-typeahead-search/service/primitives';
import {
  CacheKey,
  TypeaheadCache,
} from '@mirage/service-typeahead-search/service/typeahead-cache';
import { SourceId } from '@mirage/service-typeahead-search/service/types';
import * as wrappers from '@mirage/service-typeahead-search/service/utils/wrappers';
import { PreviousQuery } from '@mirage/shared/search/cache-result';
import * as rx from 'rxjs';

import type { typeahead } from '@mirage/service-typeahead-search/service/types';
import type { Observable } from 'rxjs';

const SOURCE_RESULT_LIMIT = 10;

export const search = wrappers.wrapped(SourceId.RecentQueries, raw);

export function raw(
  query: string,
  _config: typeahead.Config,
  cache: TypeaheadCache,
): Observable<typeahead.TaggedResult> {
  return rx.defer(() => {
    const all = cache.all(CacheKey.RecentQueries) as PreviousQuery[];

    // take N most recent prior queries if no query provided
    if (query.length === 0) {
      return rx.from(
        all
          .slice(0, SOURCE_RESULT_LIMIT)
          .map((result) => primitives.previousQuery(result.uuid, result)),
      );
    }

    // accumulated results
    const results: PreviousQuery[] = [];

    for (let i = 0; i < all.length; i++) {
      if (results.length === SOURCE_RESULT_LIMIT) break;

      const candidate = all[i];

      // skip exact match since this results in an effective dupe
      // with the "Search Dash..." prompt and wastes typeahead space
      if (candidate.query.toLowerCase() === query.toLowerCase()) {
        continue;
      }

      // exact prefix match
      if (candidate.query.toLowerCase().startsWith(query.toLowerCase())) {
        results.push(candidate);
      }
    }

    return rx.from(
      results.map((candidate) =>
        primitives.previousQuery(candidate.uuid, candidate),
      ),
    );
  });
}
