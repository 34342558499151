import { atom } from 'jotai';

export type SelectedItem = {
  selectionId: string;
};

// Define miscellaneous selection IDs here for components that should be in the keyboard nav flow
export const MISC_SELECTION_IDS = {
  calendarExpandButton: 'calendar_expand_button',
};

export const DATA_ATTRIBUTE_SELECTION_ID = 'data-selection-id';
export const DATA_ATTRIBUTE_SELECTED = 'data-selected';
export const KEYBOARD_NAV_SCROLL_CONTAINER_ID = 'keyboardNavScrollContainerId'; // ID of the scroll container to reset scroll position

export const selectedItemAtom = atom<SelectedItem | undefined>(undefined);
export const userHasNotInputKeyAtom = atom<boolean>(true);

export const focusResultAtIdx = (idx: number = 0) => {
  // Move focus to specific item on page
  const items = getAllSelectableItems();
  const itemToFocus = items[idx];
  itemToFocus?.focus();
};

export const launchSelectedMainContentItem = () => {
  // Launch selected item
  const selectedItem = document.querySelector(
    `[${DATA_ATTRIBUTE_SELECTED}=true]`,
  ) as HTMLDivElement;
  selectedItem?.click();
};

export const focusSearchInput = () => {
  const searchElement = document.getElementById(
    'search-bar',
  ) as HTMLInputElement;
  if (searchElement) {
    searchElement.focus();
    resetMainScrollPosition();
    // Element needs to be focused for select to work.
    // Timeout guarantees select runs properly
    setTimeout(() => {
      searchElement.select();
    }, 0);
  }
};

export const getAllSelectableItems = () => {
  const items: NodeListOf<HTMLElement> = document.querySelectorAll(
    `[${DATA_ATTRIBUTE_SELECTION_ID}]`,
  );
  return items;
};

// Scrolls to the top of the canvas area
const resetMainScrollPosition = () => {
  const scrollContainer = document.getElementById(
    KEYBOARD_NAV_SCROLL_CONTAINER_ID,
  );
  if (scrollContainer) {
    scrollContainer.scrollTop = 0;
  }
};
