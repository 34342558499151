import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { CLICK_KEYS } from '@mirage/shared/search/scoring/clicks';
import { exponentialDecayScore } from './utils';

export function frequentlyClickedScore(
  result: typeahead.TaggedResult,
  _config: typeahead.Config,
): number {
  if (!result?.hits?.history) return 0.0;
  const clicks = result.hits.history;

  const weightDecayParams = {
    maxScore: 0.995,
    minScore: 0.0001,
    exp: -0.15, // a curve that lines up to about .38 at 1 week
  };

  const keys = [
    { key: CLICK_KEYS.LAST_1_HOUR, days: 1 / 24, maxClicks: 1 },
    { key: CLICK_KEYS.LAST_4_HOURS, days: 4 / 24, maxClicks: 3 },
    { key: CLICK_KEYS.LAST_8_HOURS, days: 8 / 24, maxClicks: 5 },
    { key: CLICK_KEYS.LAST_1_DAY, days: 1, maxClicks: 7 },
    { key: CLICK_KEYS.LAST_3_DAYS, days: 3, maxClicks: 9 },
    { key: CLICK_KEYS.LAST_7_DAYS, days: 7, maxClicks: 11 },
  ] as const;

  let rawScore = 0;

  for (const { key, days, maxClicks } of keys) {
    const clicksForPeriod = clicks[key] || 0;
    const normalizedClicks = Math.min(clicksForPeriod, maxClicks) / maxClicks;
    const periodWeight = exponentialDecayScore(days, weightDecayParams);
    const periodScore = normalizedClicks * periodWeight;
    rawScore += periodScore;
  }

  // Normalize the score to be between 0.0 and 1.0
  let maxPossibleScore = 0;
  for (const { days } of keys) {
    maxPossibleScore += exponentialDecayScore(days, weightDecayParams);
  }
  const score = Math.min(rawScore / maxPossibleScore, 1.0);

  return score;
}
