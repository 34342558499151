import { dash_feed, users } from '@dropbox/api-v2-client';
import { LabelGroup } from '@dropbox/dash-component-library';
import { Badge } from '@dropbox/dig-components/badge';
import { Skeleton } from '@dropbox/dig-components/skeleton';
import { Box } from '@dropbox/dig-foundations';
import { ContentRowSkeleton } from '@mirage/dash-component-library/components/ContentRow';
import { ActivityLabel } from './ActivityLabel/ActivityLabel';
import { ActivityObject } from './ActivityObject';
import { ActivityThumbnail } from './ActivityThumbnail';
import styles from './ExpandedActivityListItem.module.css';

type ActivityListItemProps = {
  activity: dash_feed.ActivityItem;
  currentAccount?: users.FullAccount;
  index?: number;
  enableSummary?: boolean;
};

export const ExpandedActivityListItem = ({
  activity,
  currentAccount,
  index,
  enableSummary = false,
}: ActivityListItemProps) => {
  const showBadge = !activity.is_read;
  return (
    <Box paddingY="Micro Medium" paddingLeft="Micro Medium">
      <LabelGroup
        accessoryGap="Micro Medium"
        withStartAccessory={
          <Box position="relative" display="flex" alignItems="center">
            {showBadge && (
              <Badge
                size="xsmall"
                variant="attention"
                className={styles.unreadBadge}
              />
            )}
            <ActivityThumbnail activity={activity} />
          </Box>
        }
        withLabel={
          <Box paddingLeft="Micro Small">
            <ActivityLabel
              currentAccount={currentAccount}
              activity={activity}
            />
          </Box>
        }
      >
        <ActivityObject
          activity={activity}
          index={index}
          enableSummary={enableSummary}
        />
      </LabelGroup>
    </Box>
  );
};

export const ExpandedActivityListItemSkeleton = () => {
  return (
    <Box paddingY="Micro Medium" paddingLeft="Micro Medium">
      <LabelGroup
        accessoryGap="Micro Medium"
        withStartAccessory={
          <Box display="flex" alignItems="center">
            <Skeleton.Avatar size="large" />
          </Box>
        }
        withLabel={
          <Box paddingLeft="Micro Small">
            <Skeleton.Text withBorderRadius="xsmall" width={300} />
          </Box>
        }
      >
        <ContentRowSkeleton paddingSize="medium" startAccessorShape="square" />
      </LabelGroup>
    </Box>
  );
};

ExpandedActivityListItem.Skeleton = ExpandedActivityListItemSkeleton;
