import { Metadata } from '@dropbox/dash-component-library';
import { IconButton } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CloseLine,
  CursorLine,
  MagicWandLine,
  SearchLine,
} from '@dropbox/dig-icons/assets';
import { PAPEvent } from '@mirage/analytics/events/base/event';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Click_MessageAction } from '@mirage/analytics/events/types/click_message_action';
import { Markdown } from '@mirage/conversations/Markdown/Markdown';
import { Link } from '@mirage/link/Link';
import { generateSearchURL } from '@mirage/search/hooks/useQueryParams';
import { transformDashResultToMirage } from '@mirage/service-dbx-api/service/utils';
import { tagged } from '@mirage/service-logging';
import { copyToClipboard, openURL } from '@mirage/service-platform-actions';
import {
  ComposeArtifact,
  ComposeAssistantConversationMessage,
  ComposeAssistantConversationMessageInstruction,
  ComposeAssistantConversationMessageMessage,
  ComposeSource,
  getSourceUUID,
} from '@mirage/shared/compose/compose-session';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { getTimeAgoString } from '@mirage/shared/util/time';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { memo, useCallback, useEffect, useRef } from 'react';
import { ConversationMessageActions } from './ConversationMessageActions';
import { ConversationMessageLoadingText } from './ConversationMessageLoadingText';
import styles from './ConversationMessages.module.css';

const logger = tagged('ConversationMessages');

interface ConversationMessagesProps {
  startingMessageNode?: React.ReactNode;
  messages: ComposeAssistantConversationMessage[];
  isWaitingForResponse: boolean;
  progressString: string | undefined;
  onRemoveSource: (source: ComposeSource) => void;
  isLastMessageFollowUpSuggestions: boolean;
  artifacts: ComposeArtifact[];
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
}
export const ConversationMessages = memo(
  ({
    startingMessageNode,
    messages,
    isWaitingForResponse,
    progressString,
    onRemoveSource,
    artifacts,
    logComposeEvent,
  }: ConversationMessagesProps) => {
    const messagesDivRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (messagesDivRef.current) {
        messagesDivRef.current.scrollTo(0, messagesDivRef.current.scrollHeight);
      }
    }, [messages]);
    const hasDraftArtifact = artifacts.some(
      (artifact) => artifact.type === 'markdown_draft',
    );
    return (
      <div className={styles.ConversationMessagesScroller} ref={messagesDivRef}>
        <div className={styles.ConversationMessages}>
          {startingMessageNode}
          {messages.map((message, i) => (
            <ConversationMessageRow
              key={i}
              message={message}
              onRemoveSource={onRemoveSource}
              isLastMessage={i === messages.length - 1}
              logComposeEvent={logComposeEvent}
            />
          ))}
          {isWaitingForResponse && (
            <div
              className={classNames(
                styles.LoadingMessageRow,
                styles.ConversationMessageRowMessageAnimation,
              )}
            >
              <div
                className={
                  styles.ConversationMessageRowMessageActionIconContainer
                }
              >
                <UIIcon
                  src={MagicWandLine}
                  size="small"
                  className={styles.ConversationMessageRowMessageActionIcon}
                />
              </div>
              <ConversationMessageLoadingText
                text={
                  progressString ||
                  (hasDraftArtifact
                    ? i18n.t('compose_message_waiting_for_response_drafting')
                    : i18n.t('compose_message_waiting_for_response'))
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  },
);
ConversationMessages.displayName = 'ConversationMessages';

interface ConversationMessageRowProps {
  message: ComposeAssistantConversationMessage;
  onRemoveSource: (source: ComposeSource) => void;
  isLastMessage: boolean;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
}
export const ConversationMessageRow = memo(
  ({
    message,
    onRemoveSource,
    isLastMessage,
    logComposeEvent,
  }: ConversationMessageRowProps) => {
    switch (message.type) {
      case 'message':
        return (
          <ConversationMessageRowMessage
            message={message}
            onRemoveSource={onRemoveSource}
            isLastMessage={isLastMessage}
            logComposeEvent={logComposeEvent}
          />
        );
      case 'instruction':
        return <ConversationMessageRowInstruction message={message} />;
      default:
        message satisfies never;
        throw new Error(`Unknown message type: ${message}`);
    }
  },
);
ConversationMessageRow.displayName = 'ConversationMessageRow';

interface ConversationMessageRowMessageProps {
  message: ComposeAssistantConversationMessageMessage;
  onRemoveSource: (source: ComposeSource) => void;
  isLastMessage: boolean;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
}
export const ConversationMessageRowMessage = memo(
  ({
    message,
    onRemoveSource,
    isLastMessage,
    logComposeEvent,
  }: ConversationMessageRowMessageProps) => {
    const showMessageActionButtons = message.actionContext?.type === 'done';
    // TODO:(will) we should update the ComposeAssistantConversationMessageMessage
    // to have a more explicit 'draft' type instead of relying on followUpSuggestions
    const isWriteDraft = !!message?.followUpSuggestions?.length;
    return (
      <>
        <div
          className={classNames(styles.ConversationMessageRow, {
            [styles.ConversationMessageRowUser]: message.role === 'user',
          })}
        >
          <ConversationMessageRowActionAndText
            isLastMessage={isLastMessage}
            message={message}
          />
        </div>
        {showMessageActionButtons && (
          <ConversationMessageActions
            onClickFeedback={(feedback) => {
              logComposeEvent(
                PAP_Click_MessageAction({
                  actionType: feedback,
                  generatedQueryString: message.text,
                  queryLength: message.text.length,
                }),
              );
            }}
            onCopyMessage={
              isWriteDraft
                ? undefined
                : () => {
                    copyToClipboard(message.text);
                    logComposeEvent(
                      PAP_Click_MessageAction({
                        actionType: 'copy',
                        generatedQueryString: message.text,
                        queryLength: message.text.length,
                      }),
                    );
                  }
            }
          />
        )}
        {message.actionContext?.type !== 'dash_search_analyze' &&
          message.referencingSources &&
          message.referencingSources.length > 0 && (
            <div
              className={classNames({
                [styles.ConversationMessageSourcesUser]:
                  message.role === 'user',
              })}
            >
              {message.referencingSources.map((source, i) => (
                <ConversationMessageRowMessageSourceRow
                  key={getSourceUUID(source) || i}
                  source={source}
                  onRemoveSource={onRemoveSource}
                />
              ))}
            </div>
          )}
      </>
    );
  },
);
ConversationMessageRowMessage.displayName = 'ConversationMessageRowMessage';

interface ConversationMessageRowInstructionProps {
  message: ComposeAssistantConversationMessageInstruction;
}
export const ConversationMessageRowInstruction = memo(
  ({ message }: ConversationMessageRowInstructionProps) => {
    return (
      <div className={classNames(styles.ConversationMessageRow)}>
        <div className={styles.ConversationMessageRowMessageActionText}>
          <div
            className={classNames(
              styles.ConversationMessageRowMessageActionIconContainer,
            )}
          >
            <UIIcon
              src={MagicWandLine}
              size="small"
              className={classNames(
                styles.ConversationMessageRowMessageActionIcon,
              )}
            />
          </div>
          <div>
            <Text tagName="div" size="medium">
              {message.title}
            </Text>
            <Text tagName="div" color="subtle">
              {message.subtitle}
            </Text>
          </div>
        </div>
      </div>
    );
  },
);
ConversationMessageRowInstruction.displayName =
  'ConversationMessageRowInstruction';

interface ConversationMessageRowMessageSourceRowProps {
  source: ComposeSource;
  onRemoveSource: (source: ComposeSource) => void;
}
export const ConversationMessageRowMessageSourceRow = memo(
  ({ source, onRemoveSource }: ConversationMessageRowMessageSourceRowProps) => {
    const mirageResult =
      source.type === 'dash_search_result'
        ? transformDashResultToMirage(source.searchResult)
        : source.recommendation;
    if (!mirageResult) {
      return null;
    }

    const handleRemoveSource = (e: React.MouseEvent) => {
      e.stopPropagation();
      onRemoveSource(source);
    };

    return (
      <div
        className={styles.ConversationMessageSourceRow}
        role="button"
        tabIndex={0}
      >
        <FileContentIcon content={mirageResult!} size="large" />
        <div className={styles.ConversationMessageSourceInfo}>
          <Text size="small" className={styles.ConversationMessageSourceTitle}>
            <Link
              className={styles.ConversationMessageTitle}
              variant="monochromatic"
              href={mirageResult.url || ''}
              target="_blank"
              rel="noreferrer"
              hasNoUnderline
            >
              <Truncate lines={1}>{mirageResult.title}</Truncate>
            </Link>
          </Text>
          <Text
            size="small"
            className={styles.ConversationMessageSourceMetadata}
            color="subtle"
          >
            {mirageResult.providerUpdateAtMs && (
              <Metadata.Item>
                <Metadata.Label>
                  {i18n.t('updated_ago', {
                    timeAgo: getTimeAgoString(mirageResult.providerUpdateAtMs),
                  })}
                </Metadata.Label>
              </Metadata.Item>
            )}
          </Text>
        </div>
        <DigTooltip
          title={i18n.t('compose_source_row_remove_button_label')}
          placement="top"
        >
          <IconButton
            variant="transparent"
            className={styles.ComposeSourceActionButton}
            size="small"
            shape="standard"
            onClick={handleRemoveSource}
          >
            <UIIcon src={CloseLine} />
          </IconButton>
        </DigTooltip>
      </div>
    );
  },
);
ConversationMessageRowMessageSourceRow.displayName =
  'ConversationMessageRowMessageSourceRow';

interface ConversationMessageRowActionAndTextProps {
  message: ComposeAssistantConversationMessageMessage;
  isLastMessage: boolean;
}
export const ConversationMessageRowActionAndText = memo(
  ({ message, isLastMessage }: ConversationMessageRowActionAndTextProps) => {
    const handleMarkdownContainerClicks = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLElement;
        if (target.tagName === 'A') {
          e.preventDefault();
          openURL((target as HTMLAnchorElement).href);
        }
      },
      [],
    );
    const body =
      message.role === 'user' ? (
        <div className={styles.ConversationMessageRowPlainText}>
          {message.text}
        </div>
      ) : (
        // disabling eslint rule because this is not actually acting as a link
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          onClick={handleMarkdownContainerClicks}
          className={styles.ConversationMessageRowMarkdown}
        >
          <Markdown body={message.text} />
        </div>
      );

    // TODO:(rich) better general representation of sub-messages
    // certain action types have specific layouts, e.g. dash seach includes a starting message + submessage regarding the queries
    if (message.actionContext) {
      switch (message.actionContext?.type) {
        case 'dash_search':
          return (
            <>
              <div className={styles.ConversationMessageRowMessageActionText}>
                <div
                  className={classNames(
                    styles.ConversationMessageRowMessageActionIconContainer,
                  )}
                >
                  <UIIcon
                    src={MagicWandLine}
                    size="small"
                    className={classNames(
                      styles.ConversationMessageRowMessageActionIcon,
                    )}
                  />
                </div>
                <div>
                  <Text size="medium" color="subtle">
                    {message.text}
                  </Text>
                </div>
              </div>
              <div className={styles.ConversationMessageRowMessageActionText}>
                <div
                  className={classNames(
                    styles.ConversationMessageRowMessageActionIconEmptyContainer,
                  )}
                />
                <div>
                  <Text
                    size="small"
                    className={classNames(
                      styles.ConversationMessageRowSubMessageTitle,
                      styles.ConversationMessageRowSubMessageTitleSearch,
                    )}
                    tagName="div"
                  >
                    {i18n.t('assistant_message_searched')}:
                  </Text>
                  <ConversationMessageActionContextView message={message} />
                </div>
              </div>
            </>
          );
        case 'dash_search_analyze':
          return (
            <div className={styles.ConversationMessageRowMessageActionText}>
              <div
                className={classNames(
                  styles.ConversationMessageRowMessageActionIconEmptyContainer,
                )}
              />
              <div>
                <Text
                  size="small"
                  className={styles.ConversationMessageRowSubMessageTitle}
                  tagName="div"
                >
                  {message.text}:
                </Text>
                <ConversationMessageActionContextView message={message} />
              </div>
            </div>
          );
        case 'done':
        case 'error':
        case 'reading':
        case 'compose_selection_edit':
          // these types don't have a specific layout, fall through to use normal message layout
          break;
        default:
          message.actionContext satisfies never;
          throw new Error(
            `Unknown action context type: ${message.actionContext}`,
          );
      }
    }

    return (
      <div
        className={classNames(styles.ConversationMessageRowMessageActionText, {
          [styles.ConversationMessageRowMessageAnimation]:
            message.role !== 'user',
        })}
      >
        {message.role !== 'user' && (
          <div
            className={classNames(
              styles.ConversationMessageRowMessageActionIconContainer,
              {
                [styles.ConversationMessageRowMessageActionIconContainerDone]:
                  message.actionContext?.type === 'done' && isLastMessage,
              },
            )}
          >
            <UIIcon
              src={MagicWandLine}
              size="small"
              className={classNames(
                styles.ConversationMessageRowMessageActionIcon,
                {
                  [styles.ConversationMessageRowMessageActionIconDone]:
                    message.actionContext?.type === 'done' && isLastMessage,
                },
              )}
            />
          </div>
        )}
        <div>
          <Text size="medium">{body}</Text>
          <ConversationMessageActionContextView message={message} />
        </div>
      </div>
    );
  },
);
ConversationMessageRowActionAndText.displayName =
  'ConversationMessageRowActionAndText';

interface ConversationMessageActionContextViewProps {
  message: ComposeAssistantConversationMessageMessage;
}
export const ConversationMessageActionContextView = memo(
  ({ message }: ConversationMessageActionContextViewProps) => {
    if (!message.actionContext) {
      return null;
    }
    switch (message.actionContext?.type) {
      case 'dash_search': {
        return (
          <div>
            {message.actionContext.queries.map((query, i) => (
              <SearchQueryChip key={i} query={query} />
            ))}
          </div>
        );
      }
      case 'dash_search_analyze':
        return (
          <div>
            {message.referencingSources?.map((source, i) => (
              <ComposeMessageSourceRowChip key={i} source={source} />
            ))}
          </div>
        );
      case 'compose_selection_edit':
        return (
          <div className={styles.ComposeSelectionEditContext}>
            <UIIcon
              src={CursorLine}
              size="small"
              className={styles.ComposeSelectionEditContextIcon}
            />
            <Text size="small" color="subtle" variant="label">
              “{message.actionContext.selectedText}”
            </Text>
          </div>
        );
      case 'done':
      case 'error':
      case 'reading':
        return null;
      default:
        message.actionContext satisfies never;
        throw new Error(
          `Unknown action context type: ${message.actionContext}`,
        );
    }
  },
);
ConversationMessageActionContextView.displayName =
  'ConversationMessageActionContextView';

interface ComposeMessageSourceRowChipProps {
  source: ComposeSource;
}
export const ComposeMessageSourceRowChip = memo(
  ({ source }: ComposeMessageSourceRowChipProps) => {
    const mirageResult =
      source.type === 'dash_search_result'
        ? transformDashResultToMirage(source.searchResult)
        : source.recommendation;
    if (!mirageResult) {
      return null;
    }
    const handleClick = () => {
      const url = mirageResult.url;
      if (url) {
        openURL(url);
      } else {
        logger.error('No URL found for source', source);
      }
    };

    return (
      <Chip
        variant="standard"
        type="button"
        size="medium"
        className={styles.SearchResultChip}
        onClick={handleClick}
      >
        <Chip.IconAccessory>
          <div className={styles.SearchResultChipIcon}>
            <FileContentIcon
              content={mirageResult!}
              size="small"
              hasBackground={false}
            />
          </div>
        </Chip.IconAccessory>
        <Chip.Content className={styles.ChipContent}>
          <Truncate lines={1}>{mirageResult.title}</Truncate>
        </Chip.Content>
      </Chip>
    );
  },
);
ComposeMessageSourceRowChip.displayName = 'ComposeMessageSourceRowChip';

interface SearchQueryChipProps {
  query: string;
}
export const SearchQueryChip = memo(({ query }: SearchQueryChipProps) => {
  const handleClick = () => {
    const urlString = generateSearchURL(query);
    const url = new URL(urlString, document.baseURI);
    openURL(url.toString());
  };
  return (
    <Chip
      variant="standard"
      type="button"
      size="medium"
      className={styles.SearchQueryChip}
      onClick={handleClick}
    >
      <Chip.IconAccessory>
        <UIIcon size="small" src={SearchLine} />
      </Chip.IconAccessory>
      <Chip.Content className={styles.ChipContent}>{query}</Chip.Content>
    </Chip>
  );
});
SearchQueryChip.displayName = 'SearchQueryChip';
