// this file generated by cmde-icons
import * as React from 'react';

import type { IconProps } from './IconProps';

function SvgHelloSign({ size = 24 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15.795L3 4h18l-9 11.795zM8.424 6.622l3.567 4.676 3.565-4.676H8.425zM5.713 17.175H18.28v2.61H5.713v-2.61z"
        fill="#00B1E5"
      />
    </svg>
  );
}

export default SvgHelloSign;
