import { atom, useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { useScrollDetector } from './ScrollDetector';

export const pageScrolledAtom = atom<boolean>(false);

export function usePageScrollListener(
  scrolledComponentRef?: React.RefObject<HTMLDivElement>,
) {
  const { hasScrolled } = useScrollDetector(scrolledComponentRef);
  const [pageScrolled, setPageScrolled] = useAtom(pageScrolledAtom);

  useEffect(() => {
    if (scrolledComponentRef && scrolledComponentRef.current) {
      setPageScrolled(hasScrolled);
    }
    return () => {
      setPageScrolled(false);
    };
  }, [hasScrolled, scrolledComponentRef]);

  return { pageScrolled, setPageScrolled };
}
