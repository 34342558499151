import { stacks } from '@dropbox/api-v2-client';
import { Avatar } from '@dropbox/dig-components/avatar';
import { Button } from '@dropbox/dig-components/buttons';
import { Table } from '@dropbox/dig-components/table';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { DashStackIcon } from '@mirage/dash-component-library/components/DashStackIcon';
import { EMOJI_CLEARED_SENTINEL_VALUE } from '@mirage/shared/util/constants';
import { getInitialsFromName } from '@mirage/shared/util/tiny-utils';
import { iconSrcForAccessLevel } from '@mirage/stacks/Helpers/Utils';
import {
  accessLevelTitleForAccessLevel,
  coerceStackAccessLevel,
} from '@mirage/stacks/ShareModal/Utils';
import i18n from '@mirage/translations';
import { format } from 'date-fns-tz';
import React, { useCallback } from 'react';
import {
  Column,
  Row,
  TableState,
  useFlexLayout,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import styles from './PublishedContentPage.module.css';

type PublishedContentListTableProps = {
  content: stacks.PublishedContent[];
  onSelectStack: (content: stacks.Stack) => void;
  onClickUnpublish: (content: stacks.Stack) => void;
};

export default function PublishedContentListTable({
  content,
  onSelectStack,
  onClickUnpublish,
}: PublishedContentListTableProps) {
  const { columns } = usePublishContentColumns(onClickUnpublish);

  const data = React.useMemo(() => content, [content]);

  const tableInstance = useTable<stacks.PublishedContent>(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'date',
            desc: false,
          },
        ],
      } as Partial<TableState<stacks.PublishedContent>>, // Extending with sortBy,
    },
    useSortBy,
    useRowSelect,
    useFlexLayout,
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Table
      {...getTableProps()}
      spacing="xlarge"
      hasDividers
      verticalAlign="center"
      className={styles.publishedListTable}
    >
      <Table.Header>
        {headerGroups.map((headerGroup) => {
          const { key, ...rest } = headerGroup.getHeaderGroupProps();
          return (
            <Table.Row key={key} {...rest}>
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {headerGroup.headers.map((column: any) => {
                const { key, ...rest } = column.getHeaderProps();
                return (
                  <Table.HeaderCell
                    key={key}
                    {...rest}
                    {...column.getHeaderProps({
                      ...column.getSortByToggleProps(),
                      title: 'Sortby (A-Z)',
                    })}
                    sortDirection={
                      column.id !== 'content' &&
                      column.id !== 'publisher' &&
                      column.id !== 'date'
                        ? undefined
                        : column.isSortedDesc === undefined
                        ? 'none'
                        : column.isSortedDesc
                        ? 'desc'
                        : 'asc'
                    }
                  >
                    <Text variant="label" isBold color="subtle">
                      {column.render('Header')}
                    </Text>
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          );
        })}
      </Table.Header>
      <Table.Body {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const { key, ...rest } = row.getRowProps({});
          return (
            <Table.Row
              tabIndex={0}
              key={key}
              isSelectable
              onClick={() => {
                if (row.original['.tag'] === 'stack') {
                  onSelectStack(row.original);
                }
              }}
              {...rest}
            >
              {row.cells.map((cell) => {
                const { key, ...rest } = cell.getCellProps();
                return (
                  <Table.Cell key={key} {...rest}>
                    {cell.render('Cell')}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

export const usePublishContentColumns = (
  onClickUnPublish: (content: stacks.Stack) => void,
) => {
  const extractStack = useCallback((content: stacks.PublishedContent) => {
    return content['.tag'] === 'stack' ? (content as stacks.Stack) : undefined;
  }, []);
  const titleAccessor = useCallback(
    (row: stacks.PublishedContent) =>
      row['.tag'] === 'stack' ? row.stack_data?.name?.toLowerCase() : '',
    [],
  );
  const publisherAccessor = useCallback(
    (row: stacks.PublishedContent) =>
      row['.tag'] === 'stack'
        ? row.publish_data?.actor?.display_name?.toLowerCase()
        : '',
    [],
  );
  const dateAccessor = useCallback(
    (row: stacks.PublishedContent) =>
      row['.tag'] === 'stack' ? row.publish_data?.ts_ms : '',
    [],
  );
  const columns: Column<stacks.PublishedContent>[] = React.useMemo(() => {
    const DEFAULT_COLUMNS = [
      {
        id: 'index',
        Header: '',
        width: '50',
        canResize: false,
        Cell: ({ row }: { row: Row<stacks.PublishedContent> }) => {
          return <div>{row.index + 1}</div>;
        },
      },
      {
        id: 'content',
        Header: i18n.t('content'),
        canResize: true,
        flexGrow: 1,
        accessor: titleAccessor,
        Cell: ({ row }: { row: Row<stacks.PublishedContent> }) => {
          const stack = extractStack(row.original);
          if (!stack) {
            return null;
          }
          const emoji = stack.stack_data?.emoji || EMOJI_CLEARED_SENTINEL_VALUE;
          const colorIndex = stack.stack_data?.color_index;
          return (
            <div className={styles.publishListContentTitle}>
              <div>
                <DashStackIcon
                  emoji={emoji}
                  colorIndex={colorIndex}
                  size="small"
                />
              </div>
              <Truncate>{stack.stack_data?.name}</Truncate>
            </div>
          );
        },
      },
      {
        id: 'type',
        width: '70',
        Header: i18n.t('content_type_filter_title'),
        Cell: () => {
          return <div>{i18n.t('stack_result_subtext_title')}</div>;
        },
      },
      {
        id: 'permissions',
        Header: i18n.t('permissions'),
        width: '70',
        Cell: ({ row }: { row: Row<stacks.PublishedContent> }) => {
          const stack = extractStack(row.original);
          if (!stack) {
            return null;
          }
          const stackAccessLevel = coerceStackAccessLevel(
            stack.publish_data,
            stack.sharing_data?.shared_link?.access_level,
          );

          return (
            <div className={styles.publishListContentPermission}>
              <UIIcon src={iconSrcForAccessLevel(stackAccessLevel)} />
              <Text>
                {accessLevelTitleForAccessLevel(
                  stack.publish_data,
                  stack.sharing_data?.shared_link?.access_level,
                  stack.sharing_data?.members?.length ?? 0,
                )}
              </Text>
            </div>
          );
        },
      },
      {
        id: 'publisher',
        Header: i18n.t('publisher'),
        width: '100',
        accessor: publisherAccessor,
        Cell: ({ row }: { row: Row<stacks.PublishedContent> }) => {
          const stack = extractStack(row.original);
          if (!stack) {
            return null;
          }

          const publishedActor = stack.publish_data?.actor;
          const ownerInitials = publishedActor
            ? getInitialsFromName(publishedActor.display_name || '')
            : undefined;
          return (
            <div className={styles.publishListContentTitle}>
              <Avatar
                src={
                  publishedActor ? publishedActor.profile_photo_url : undefined
                }
                size="small"
              >
                {ownerInitials}
              </Avatar>
              <Text>
                {publishedActor
                  ? publishedActor.display_name
                  : i18n.t('nobody')}
              </Text>
            </div>
          );
        },
      },
      {
        id: 'date',
        Header: i18n.t('date'),
        width: '100',
        accessor: dateAccessor,
        Cell: ({ row }: { row: Row<stacks.PublishedContent> }) => {
          const stack = extractStack(row.original);
          if (!stack) {
            return null;
          }

          const createdAtMs = stack.publish_data?.ts_ms || 0;
          return <Text>{format(createdAtMs, 'LLLL do')}</Text>;
        },
      },
      {
        id: 'action',
        Header: '',
        width: '100',
        canResize: false,
        Cell: ({ row }: { row: Row<stacks.PublishedContent> }) => {
          return (
            <div>
              <Button
                variant="transparent"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (row.original['.tag'] === 'stack') {
                    onClickUnPublish(row.original);
                  }
                }}
              >
                {i18n.t('unpublish')}
              </Button>
            </div>
          );
        },
      },
    ];
    return DEFAULT_COLUMNS;
  }, []);

  return { columns };
};
