import { IDBEntry, Redactor } from "./types";

export const DEFAULT_REDACTED_URL = "https://redacted.example";

export const isMeticulousEnabled = (): boolean => {
  return Boolean(window?.DASH_ENABLE_METICULOUS);
};

export const redactIfJson = <T>(
  json: string,
  transformation: (value: T) => T,
): string => {
  let parsed: T;
  try {
    parsed = JSON.parse(json ?? "");
  } catch {
    return json;
  }

  const transformed = transformation(parsed);
  return JSON.stringify(transformed);
};

export const redactIndexedDBEntry = <T>(
  entry: IDBEntry,
  targetKey: string,
  redactor: Redactor<T>,
): IDBEntry => {
  if (entry.key === targetKey) {
    if (typeof entry.value !== "string") {
      console.warn(`Unexpected non-string value for ${targetKey} in IndexedDB`);
      return entry;
    }

    return {
      ...entry,
      value: redactIfJson<T>(entry.value, redactor),
    };
  }
  return entry;
};
