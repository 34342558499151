import { Text, Title } from '@dropbox/dig-components/typography';
import { useTheme } from '@dropbox/dig-foundations';
import { DashSpinner } from '@mirage/shared/dash-spinner';
import i18n from '@mirage/translations';
import { CommentLineSvg } from './Display/CommentLineSvg';
import styles from './QuestionSuggestions.module.css';
import { useQuestionSuggestions } from './useQuestionSuggestions';

type SuggestionQuestionsProps = {
  onSelectSuggestion: (text: string) => void;
};

export const QuestionSuggestions = ({
  onSelectSuggestion,
}: SuggestionQuestionsProps) => {
  const { suggestions, isLoading } = useQuestionSuggestions();

  return (
    <>
      <Title className={styles.suggestionTitle}>{i18n.t('get_started')}</Title>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <DashSpinner size={60} />
        </div>
      ) : (
        <div className={styles.suggestions}>
          {suggestions.map((suggestion, index) => (
            <Suggestion
              key={index}
              text={suggestion.display_value}
              onSelectSuggestion={onSelectSuggestion}
            />
          ))}
        </div>
      )}
    </>
  );
};

const Suggestion = ({
  text,
  onSelectSuggestion,
}: {
  text?: string;
  onSelectSuggestion: (text: string) => void;
}) => {
  const theme = useTheme();

  if (!text) {
    return null;
  }

  return (
    <button
      aria-label={text}
      className={styles.suggestion}
      onClick={() => onSelectSuggestion(text)}
    >
      <div className={styles.suggestionIcon}>
        <CommentLineSvg
          width={16}
          height={16}
          isDarkModeEnabled={theme.mode === 'dark'}
        />
      </div>
      <Text>{text}</Text>
    </button>
  );
};
